const { useState } = require("react")
const { Modal, Button } = require("react-bootstrap")
const { default: OrdenesListado } = require("./listado")
const { FaBox } = require("react-icons/fa")

const ModalListadoOrdenes = (props) => {
    const {
        condicion,
        title
    } = props
    const [ showModal, setShowModal ] = useState(false)
    const condicionBuquedaOrdenes = condicion ? condicion : {}
    const titulo = title ? title : 'DETALLES'

    const handleClose = () => setShowModal(false)

    return <div>
        <Button className='shadow-sm' variant="light" size="sm" style={{ fontWeight: "bold", fontSize:11 }} onClick={() => setShowModal(true)}>{titulo}</Button>
        <Modal show={showModal} size="xl" onHide={() => handleClose()} centered >
            <Modal.Header closeButton>
                <Modal.Title>Órdenes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OrdenesListado upWhenClick={false} showSearch={false} showAddButton={false} showFilter={false} showExporter={false} condicion_default={condicionBuquedaOrdenes} />
            </Modal.Body>
        </Modal>
    </div>
}

export default ModalListadoOrdenes