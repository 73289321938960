import React from 'react'
import { Row, Col, Container, Form, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { url_images } from '../../lib/global/data'
import "../Login/login.css"
import { urlapi } from '../../lib/backend/data'

const ResetContrasena = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('id');
    
    console.log(token, "soy el token");
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');

    const handleChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const handleChangeConfirmPassword = (e) => {
        setConfirmPassword(e.target.value);
    }

    const handleSubmit = () => {
        if (password !== confirmPassword) {
            setError('Las contraseñas no coinciden');
            return;
        }


        setLoading(true);

        return fetch(`${urlapi}/auth/reset-password`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                accept: 'application/json',
                'Access-Control-Allow-Origin': '*'
            },
            body: JSON.stringify({
                newPass: password,
                resetLink: token
            }),
        })
        .then(response => response.json())
        .then(data => {
            if(!data){
                toast.error('Error al enviar el correo')
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
            } else if(data.data){
                toast.success('Contraseña reseteada exitosamente')
                setTimeout(() => {
                    window.location.href = '/';
                }, 1000)
            }
            return setLoading(false);
        })
        .catch(error => {
            setError('Ocurrió un error al resetear la contraseña');
            return setLoading(false);
        })
    }

    return (
        <div className='login-form text-primary'>
            <Row className="justify-content-md-center">
                <Col md={12} className="centerv row justify-content-center border px-5 py-4 shadow">
                    <Row className='row justify-content-center'>
                        <img alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
                    </Row>

                    <Container className="text-center">
                        <p className='my-3' style={{ fontWeight: 700, fontSize: 15 }}>Transformamos la experiencia en software</p>
                        <h1 style={{fontWeight:900}}>¡Ya estamos por terminar!</h1>
                        <Row className="d-block my-3 justify-content-md-center">

                            <h5 style={{fontWeight:700}}>Ingresa tu nueva contraseña</h5>
                            <Row className="justify-content-md-center">
                                <Form.Group className='w-50'>
                                    <Form.Control className='shadow-sm' type="password" name="password" placeholder="Nueva Contraseña" value={password} onChange={handleChangePassword} />
                                </Form.Group>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Form.Group className='w-50'>
                                    <Form.Control className='shadow-sm' type="password" name="confirmPassword" placeholder="Confirmar nueva Contraseña" value={confirmPassword} onChange={handleChangeConfirmPassword} />
                                </Form.Group>
                            </Row>
                            <Row className="justify-content-md-center">
                                <Form.Group className='w-50'>
                                    {loading ? <Spinner animation="border" /> : <Button className='shadow-sm' onClick={handleSubmit} block variant="primary"><b>ENVIAR</b></Button>}
                                    {error ? <p className="text-danger">{error}</p> : false}
                                </Form.Group>
                            </Row>
                            {/* <h6 className='my-4' style={{fontSize:13}}><Link to={`/login`}><i className="fa-solid fa-arrow-left"></i> Volver a Inicio de sesión</Link> </h6> */}

                        </Row>

                    </Container>
                </Col>
            </Row>
        </div>
    )
}

    




export default ResetContrasena;