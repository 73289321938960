import React, { useRef, useState } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger, Row, Col } from "react-bootstrap";
import { PDFDocument } from 'pdf-lib';
import Draggable from 'react-draggable';
import { SlCursorMove } from "react-icons/sl";
import { FaFileDownload } from 'react-icons/fa';

const IncrustarFirma = (props) => {
  const { firma_ejemplo } = props
  const [pdfFile, setPdfFile] = useState(null);
  const [pdfBytes, setPdfBytes] = useState(null); 
  const [signature, setSignature] = useState(firma_ejemplo ? firma_ejemplo : null); 
  const [signaturePosition, setSignaturePosition] = useState({ x: 0, y: 0 }); 
  const [firma, setFirma] = useState({})
  const pdfRef = useRef(null); 

  // Cargar archivo PDF
  const handlePDFUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      setPdfBytes(event.target.result);
      setPdfFile(URL.createObjectURL(file));
    };
    reader.readAsArrayBuffer(file);
    setFirma(prev => {
      let actual = {...prev}
      actual.url_pdf = file.name
      return {...{}, ...actual}
    })

  };

  // Función para guardar la firma en el PDF
  const handleSaveSignatureToPDF = async () => {
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const page = pdfDoc.getPages()[0];
    const { width, height } = page.getSize();

    // Escalar la posición de la firma según el tamaño del PDF
    const signatureX = (signaturePosition.x / pdfRef.current.offsetWidth) * width;
    const signatureY = height - ((signaturePosition.y / pdfRef.current.offsetHeight) * height) - 50; // Ajuste para que no se salga

    // Cargar la firma como imagen
    const signatureImage = await pdfDoc.embedPng(signature);
    const signatureImageDims = signatureImage.scale(0.5); // Escalar la firma

    // Dibujar la firma en el PDF en la posición especificada
    page.drawImage(signatureImage, {
      x: signatureX,
      y: signatureY,
      width: 130,
      height: 60,
    });

    // Guardar el PDF modificado
    const pdfBytesModified = await pdfDoc.save();
    download(pdfBytesModified, 'documento_con_firma.pdf', 'application/pdf');
  };

  // Descargar el archivo modificado
  const download = (data, filename, type) => {
    const blob = new Blob([data], { type });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  // Actualizar la posición de la firma cuando se mueva
  const handleDrag = (e, ui) => {
    const { x, y } = ui;
    setSignaturePosition({ x, y });
    setFirma(prev => {
      let actual = {...prev}
      actual.x = x
      actual.y = y
      return {...{}, ...actual}
    })
  };

  const handleChangeEtiqueta = (e) => {
    const { value } = e.target
    setFirma(prev => {
      let actual = {...prev}
      actual.etiqueta = value
      return {...{}, ...actual}
    })
}

const guardarParametrosFirma = () => {
  if(props.onChangeFirma) props.onChangeFirma(firma)
}

  return (
    <Row className="mt-0" >
      <Col md={5}  className='m-3'>
        <div>
        <label >Documento PDF</label>
        <input type="file" accept="application/pdf"    className="form-control mb-3 " onChange={handlePDFUpload} />
        </div>
        <div>
        <label >Nombre de la etiqueta "Identificador del archivo"</label>
        <input type="text" name="etiqueta" className='form-control mb-2' placeholder='Ingrese nombre de la etiqueta' onChange={(e) => handleChangeEtiqueta(e)}/>
        </div>
        <div>
        <p className='mt-3'><SlCursorMove /> Visualiza la firma que esta en el documento y arrastrala con el cursor hasta donde deseas colocarla.</p>
        </div>
        <div>
        {signature && pdfFile && (
            <Button className="mt-3" onClick={handleSaveSignatureToPDF}> <FaFileDownload /> Descargar PDF</Button>  
      )}
       </div>
       <div>
        {signature && pdfFile && (          
            <Button className="mt-4" onClick={guardarParametrosFirma}> Guardar Cambios</Button>
      )}      
        </div>
      </Col>

      {pdfFile && (
        <Col md={6} style={{ position: 'relative', width: '100%', height: '680px'}} ref={pdfRef} >
          <iframe
            src={`${pdfFile}#toolbar=0`}
            style={{  width: '100%',
              height: '673px',
              border: 'none', 
            padding: 0}}
            title="pdf-viewer"
          />
          {signature && (
            <Draggable
              defaultPosition={{ x: 0, y: 0 }}
              onDrag={handleDrag}
            >
              <img
                src={signature}
                alt="Firma"
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%', 
                  button: '0%', 
                  right: '0%', 
                  width: '100px',
                  height: '60px',
                  cursor: 'move',
                  zIndex: 10,
                }}
              />
            </Draggable>
          )}
        </Col>
      )}

     
    </Row>
  );
};

export default IncrustarFirma;
