import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto, fechaATextoSimpleCorto } from "../../../lib/helpers/helpers"
import { FiMapPin } from "react-icons/fi"
import { BsEnvelopeCheck } from "react-icons/bs"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <h6 style={{ fontSize:10, fontWeight:'lighter' }} className="mb-2">Creado <b>{ fechaATextoSimpleCorto(proveedor.createdAt)}</b></h6>
    <h6 style={{ fontSize: 17 }} className="mb-1">{proveedor.nombre} {proveedor.apellidos ? proveedor.apellidos : ""}</h6>
    <h6 style={{ fontSize: 12 }} className="mb-1"> {proveedor.email ? <div><BsEnvelopeCheck /> {proveedor.email}</div> : "Sin datos"}</h6>
    <h6 style={{ fontSize: 14 }} className="mb-1"><b>Cliente: {proveedor.cliente ? proveedor.cliente : "Sin datos"}</b> </h6>
</Card>
}

export default BoxList