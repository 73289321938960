import React, { useEffect, useState } from 'react';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Chart from "react-google-charts";
import { estilo_last_mile } from '../../lib/global/styles';
import { FaCalendar, FaRegFileExcel } from 'react-icons/fa';
import { segmentarCalendarioOrdenes } from '../../lib/helpers/conductores';
import { useDispatch, useSelector } from 'react-redux';
import { obtenerFechaHoraZonaHorariaLocal } from '../../lib/helpers/dates';
import { urlapi } from '../../lib/backend/data';
import { cerrarSesion } from '../../redux/actions/session';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { dateToYMDFormat, formatYMD } from '../../lib/helpers/helpers';

const CalendarioMensualOrdenes = (props) => {
    const {
        color,
        titulo
    } = props
    const [ ordenes, setOrdenes ] = useState( props.datos ? props.datos : false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const token = session.tokenSession
    const height = props.height ? props.height : '300px'
    const colors = props.colors ? props.colors : ['#007bff']

    const descargarReporteExcel = async () => {
      if(!props.datos) return false
      if(!Array.isArray(props.datos)) return false
      if(props.datos.length < 1) return false
      const condicion_busqueda = { _id: { $in: props.datos.map(o => o._id) } }
      setLoadingExcel(true)
      return fetch(`${urlapi}/reporte/ordenes`,{
          method:'POST',
          body: JSON.stringify({
              condicion: condicion_busqueda,
              incluir_estados: false
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.blob()
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          const fecha = obtenerFechaHoraZonaHorariaLocal()
          a.download = `reporte-${fecha}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();
          return setLoadingExcel(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingExcel(false)
      })
  }
    
    useEffect(() => {
        const procesar = segmentarCalendarioOrdenes(props.datos)
        setOrdenes(procesar)
    }, [ props.datos ])
    
    if(!ordenes) return false
      if(!ordenes.dias) return <div className='p-5'>
      <Row className="justify-content-md-center">
          <Col md={6} className="text-center">
          <h4>No hay datos suficientes para visualizar esta sección</h4>
          </Col>
      </Row>
      </div>

        const cantidad_columnas = 100 / ordenes.dias.length
        const color_highlight = color ? color : estilo_last_mile.color_primary
        let valorMaximo = 0
        for (let i = 0; i < ordenes.dias.length; i++) {
            if (ordenes.dias[i].cantidad > valorMaximo) {
              valorMaximo = ordenes.dias[i].cantidad;
            }
          }

          const porcentajeMaximo = (valorMaximo / valorMaximo) * 100;

          
    const seleccionarDia = (data) => {
        if(props.onClick) props.onClick(data)
    }
      return <div className='mt-2'>
      <p className="" style={{ fontWeight: "bold", fontSize: 12 }}><FaCalendar /> El siguiente gráfico muestra las órdenes agrupadas según el día de creación</p>
      <Button size="sm" variant='success' disabled={loadingExcel} className='mb-2' onClick={() => descargarReporteExcel()} ><FaRegFileExcel style={estilo_last_mile.icon} /> DESCARGAR REPORTE EXCEL</Button>
      
      <div className='border border-top-0 p-3' style={{ backgroundColor: 'rgba(27, 54, 101, 0.1)'}}>
        {
            ordenes.dias.map(fecha => {

                // let primera_fecha = fecha.datos.length > 0 ? DateTime.fromISO(fecha.datos[0].createdAt) : false
                // let vencen_hoy = 0

                // if(primera_fecha){
                //   vencen_hoy = fecha.datos.filter(o => {
                //     if(!o.fecha_max_entrega) return false
                //     if(!o.createdAt) return false
                //     const dia = DateTime.fromISO(o.createdAt)
                //     const vence = dateToYMDFormat(o.fecha_max_entrega)
                //     const hoy = dateToYMDFormat(primera_fecha.set({ day: fecha.dia }).toString())
                //     console.log({ hoy, dia: fecha.dia, vence, max_entrega: o.fecha_max_entrega, createdAt: o.createdAt })
                //     return vence === hoy
                //   }).length
                // }

                const porcentaje = ((fecha.cantidad / valorMaximo) * 100).toFixed(0)
                const porcentajeDelMaximo = (porcentaje / porcentajeMaximo) * 100;
                const barra = <div className='hover' style={{ borderTopLeftRadius:0, borderTopRightRadius: 0, height: porcentajeDelMaximo > 0 ? Math.max(porcentajeDelMaximo, 25) : 100, backgroundColor: porcentajeDelMaximo > 0 ? color_highlight : "#e0e0e0", width: "60%", margin: '0 auto' }}></div>

                return <div style={{ width: `${cantidad_columnas}%`, display: "inline-block" }} className='text-center' onClick={() => seleccionarDia(fecha)}>
                  <p style={{ fontWeight: 700, fontSize: 9, color: "white", marginBottom: -20 }}>{fecha.cantidad}</p>
                  {
                    fecha.cantidad ? <OverlayTrigger
                    placement={'top'}
                    overlay={
                      <Tooltip>{fecha.cantidad} {titulo}</Tooltip>
                    }
                    >
                      {barra}
                    </OverlayTrigger> : barra
                  }                    
                    <p className='m-1' style={{ fontSize:12, fontWeight: 900, color: '#1B3665'  }}>{fecha.dia}</p>
                </div>
            })
        }
      </div>
      </div>
}

export default CalendarioMensualOrdenes