import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import CargandoTabla from "./cargando"
import { calcularPorcentaje, fechaUTCATexto } from "../../lib/helpers/helpers"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { nombreDNIPorPais, stringByTipoDetection, stringByTipoNotification } from "../../lib/helpers/data/internationa"
import { Button, Card, Col, Form, Alert, Table, Tooltip, Pagination, Spinner, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import { cerrarSesion } from "../../redux/actions/session"
import RegistrosVacios from "../../subComponents/general/registros_vacios"
import { AiFillAlert } from "react-icons/ai"
import LoadingNotifications from "../../subComponents/general/loadingAnimations/loadingPushNotifications"

const ListadoAlertas = (props)=> {
    const {
        condicion_default,
        tableSize,
        hideSearch,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [filtros, setFiltros] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'

    const obtenerDatos = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/geodata/alertas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        const cond = condicion_default ? condicion_default : condicion_busqueda
        obtenerDatos(1, cond)
    }, [ condicion_default ])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerDatos(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div>
          <Pagination size='sm'>
              {
                  Array.from(Array(cantidad).keys()).map(number => {
                      const active = pagina === (number+1) ? true : false
                      return <Pagination.Item key={number+1} active={active} onClick={()=> handleChangePagina(number+1,active)} >{number+1}</Pagination.Item>
                  })
              }
          </Pagination>
      </div>
    }

    const seleccionarRegistro = data => {
        if(props.onSelect) props.onSelect(data)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b onClick={() => seleccionarRegistro(data)}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</b>
            default:
                return <Link to={`${rutas.drivers.slug}/${data._id}`}>{data.nombres} {data.apellido_p ? data.apellido_p : ''}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerDatos(1, filtro.condicion)
    }

    const header = () => {
        if(hideHeader === true) return false
        return <Row gutter={15}>
            <Col md={6}>
                <Card className="mb-3 p-2">
                    <p level={3} style={{ fontSize: 12 }} className="m-0">Total de datos filtrados</p>
                    <h3 className="m-0">{total}</h3>
                </Card>
            </Col>
            {
                filtros.map((filtro,i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card size="small" className="mb-3">
                        <p level={3} style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        {
                            filtro.help_text ? <Tooltip title={filtro.help_text}>
                           {/* <QuestionCircleOutlined style={{ marginLeft: 5 }} /> */}
                          </Tooltip> : false
                        }
                        </p>
                        <Row>
                            <Col xs={12}><h3 className="m-0">{filtro.cantidad}</h3></Col>
                            <Col xs={12} style={{ textAlign: "right", marginTop:8 }}> <Spinner size={35} type="circle" percent={porcentaje} /></Col>
                        </Row>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const colorByTypeNotification = (type) => {
        switch (type) {
            case "info":
                return "#1566ce"
            case "warning":
                return "#f5631c"
            case "error":
                return "#b10b0b"
            default:
                return "gray"
        }
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };


      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda) return toast.error('Escribe alguna palabra clave');
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerDatos(1, condicion);
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <Form onSubmit={handleSubmit}>
        <Row gutter={15}>
            <Col md={14}>
                <label className="form-control-label d-block">Nombre</label>
                    <input autoFocus defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Nombre, apellido, email, teléfono o ${nombreDNIPorPais(pais)}`} />
            </Col>
            <Col md={4}>
                    <Button onClick={handleSubmit} style={{ width: "100%"}}> BUSCAR</Button>
            </Col>
        </Row>
               
                </Form>
      }

      const vistaSimple = () => {

        return <div>
            {paginacion(data.pagina, total)}
            {
                conductores.map(noti => {
                    return <Card className="mb-3 p-2" key={noti._id}>
                        <span style={{ fontSize: 10 }}>{fechaUTCATexto(noti.createdAt)}</span>
                        <p className="text-white mb-1"><b style={{ width: "fit-content", fontSize: 10, padding: "4px 15px", background: "gray" }}>TIPO DE ALERTA</b><b style={{ width: "fit-content", fontSize: 10, padding: "4px 15px", backgroundColor: colorByTypeNotification(noti.tipo_notificacion) }}><AiFillAlert /> {stringByTipoNotification(noti.tipo_notificacion)}</b></p>
                        <p className="mt-0 mb-0">{noti.mensaje}</p>
                    </Card>
                })
            }
        </div>
      }

      const listadoRegistros = () => {
        if(conductores.length < 1) return <RegistrosVacios />
        return <div>
            <Table size={tableSize ? tableSize : "middle"} >
                <thead>
                    <tr>
                        <td>Mensaje</td>
                        <td>Tipo</td>
                        <td>Tipo de detección</td>
                        <td>Fecha de creación</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        conductores.map((data,i) => {
                            return <tr key={`con-${i}`}>
                                <td>{data.mensaje}</td>
                                <td>{stringByTipoNotification(data.tipo_notificacion)}</td>
                                <td>{stringByTipoDetection(data.tipo_deteccion)}</td>
                                <td>{fechaUTCATexto(data.createdAt)}</td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
      }
    const mostrarRegistros = () => {
        if(loadingConductores) return <LoadingNotifications />
        if(typeView === "simple") return vistaSimple()
        
        return <div>

            {header()}
            
            {paginacion(data.pagina, total)}
            {listadoRegistros()}
        </div>
    }

    return <div>
        {mostrarRegistros()}
    </div>
}
export default ListadoAlertas