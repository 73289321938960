import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Spinner, Card, OverlayTrigger, Tooltip, Table, Navbar, Nav } from 'react-bootstrap'
import data, { interfaz } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from '../box-list';
import CargandoDetalleFull from '../cargando_detalle_full';
import DetailFull from '../detalle-full';
import { Link, useParams } from 'react-router-dom';
import { virtual_tour_settings } from '../../../lib/global/data';
import Joyride from 'react-joyride';
import BuscadorVehiculos from '../../conductores/buscador';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import { addMoreDays, formatDateHoy } from '../../../lib/helpers/dates';
import { useQuery } from '../../../lib/helpers/router';
import BoxListV2 from '../box/box-list-v2';
import { cerrarSesion } from '../../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import { fechaATextoSimpleCorto } from '../../../lib/helpers/helpers';
import { rutas } from '../../../lib/routes/routes';
import { FaFileExcel, FaPlusCircle } from 'react-icons/fa';

const RutasListadoV2 = (props) => {
    const {
        typeView
    } = props
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [ equipos, setEquipos ] = useState([])
    const [ estados, setEstados ] = useState([])
    const [ loadingEsquemaEstados, setloadingEsquemaEstados ] = useState(true)
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState(false)
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ rutaBusqueda, setRutaBusqueda ] = useState('')
    const dispatch = useDispatch()
    let query = useQuery();

    const obtenerOrdenes = async (page, query)=>{
        setLoadingEquipos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/rutas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    const obtenerRuta = async (id) => {
        if(typeView === "funcion"){
            if(props.onSelect) props.onSelect(id)
            return 
        }
        if(idSeleccionado === id) return false
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        window.scrollTo({top: 0, behavior: 'smooth'});
        return fetch(`${data.urlapi}/rutas/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setRutaSeleccionada(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerOrdenes(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const onChangeConductor = (e) => {
        setConductor(e)
    }

    const filtrar = () => {
        const desde = selectionRange.startDate
        const hasta = addMoreDays(selectionRange.endDate,1)
        console.log({ start: desde, end: hasta })
        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) } }
            if(conductor) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: new Date(hasta.getTime()-1) } }
            if(conductor) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'fecha-despacho'){
            const condicion = { 
                entrega_desde: { $lte: new Date( formatDateHoy(selectionRange.startDate)) }, 
                entrega_hasta: { $gte: new Date(hasta.getTime()-1) }, 
            }
            if(conductor) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'todos'){
            const condicion = {  }
            if(conductor) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        }
    }

    const handleChangeFechaBusqueda = (e) => {
        const { value } = e.target
        return setTipoFechaBusqueda(value)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'  value={tipoFechaBusqueda} onChange={handleChangeFechaBusqueda} >
                        <option value="creacion">Creación</option>
                        <option value="actualizacion">Actualización</option>
                        <option value="fecha-despacho">Fecha despacho</option>
                        <option value="todos">Cualquiera</option>
                    </select>
                </Col>
                <Col xs={12} className="mb-3">
                    <BuscadorVehiculos isMulti={true} token={token} onChange={(data) => onChangeConductor(data)} />
                </Col>
                {
                    tipoFechaBusqueda !== "todos" ? <Col xs={12} className="mb-2">
                    <DateRangePicker
                        locale={es}
                        showSelectionPreview={true}
                        editableDateInputs={true}
                        onChange={item => cambiarRangoFecha(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        months={1}
                    />
                    </Col> : false
                }
                <Col md={12}>
                    <Button size="sm" variant="success" onClick={()=>filtrar()}>FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerOrdenes(1, false)
        obtenerEsquemaEstados()
        const id_ruta_url = query.get("id")
        if(id_ruta_url){
            obtenerRuta(id_ruta_url)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const obtenerEsquemaEstados = () => {
      return fetch(`${data.urlapi}/ordenes/suite-config-estados`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
      .then(pros => pros.json())
      .then(data => { 
          console.log(data)
            if(!data){
            toast.error('Sin datos')
            return setRemoviendo(false)
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return setRemoviendo(false)
            } else if(Array.isArray(data) !== false){
                // if(data.length < 1) toast.warn('No hay esquema de estados y subestados')
                setEstados(data)
            }
            return setloadingEsquemaEstados(false)
      })
      .catch(error => {
          toast.error('No pudimos cargar la información')
          setloadingEsquemaEstados(false)
      })
    }

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingEquipos===true) return cargandoScreen()
        if(loadingEsquemaEstados===true) return cargandoScreen()
    
        const clickRuta = (id) => {
            if(id) return obtenerRuta(id)
            return false
        }
    
        const mostrarVehiculo = (driver) => {
            if(!driver) return false
            return <div>
                <p className="mb-0" style={{ fontSize:12 }}><b>{driver.modelo}</b> {driver.ano} /{driver.patente.toUpperCase()}</p>
            </div>
        }
        
        return <div>
            { /** <Joyride styles={{options:virtual_tour_settings.estilo}} continuous={true} showProgress={true} steps={virtual_tour_settings.pasos_rutas} locale={virtual_tour_settings.locale} /> */ }
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            <div className='table-responsive mb-2 shadow'>
            <Table bordered hover className='mb-0 sticky-top '>
                <thead style={{backgroundColor: 'lightgray'}}>
                    <tr>
                        <th>Fecha creación</th>
                        <th>Número de ruta</th>
                        <th>Rango entrega</th>
                        <th >Finalizada</th>
                        <th>Órdenes</th>
                        <th>Vehículo/ Patente</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.length > 0 ? registros.map(orden => {
                            let seleccionado = false
                            if(idSeleccionado === orden._id) seleccionado = true
                            return <>              
                                <tr bg={ seleccionado === true ? 'primary' : 'white' } className={`hover shadow-sm p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => clickRuta(orden._id)}>              
                                    <td style={{ fontSize: '0.8rem' }}>{fechaATextoSimpleCorto(orden.createdAt)}</td>
                                    <td style={{ fontSize: '0.8rem' }}>RUTA {orden.id}</td>
                                    <td style={{ fontSize: '0.8rem' }}>
                                        {fechaATextoSimpleCorto(orden.entrega_desde)} - {fechaATextoSimpleCorto(orden.entrega_hasta)}
                                    </td>
                                    <td className='text-center' style={{ fontSize: '0.8rem' }}>{orden.finalizada ? "SI" : "NO"}</td>
                                    <td className='text-center' style={{ fontSize: '0.8rem' }}>{orden.pedidos}</td>
                                    <td style={{ fontSize: '0.8rem' }}>{mostrarVehiculo(orden.vehiculo)}</td>
                                </tr>
                            </> 
                        }) : 
                        <tr>
                            <td colSpan="6">
                                <div>
                                    <h5>Nada por aquí</h5>
                                    <p>No hay registros en esta sección</p>
                                </div>
                            </td>
                        </tr>
                    }
                </tbody>
            </Table>
        </div>
        </div>



    }

    const restaurarRegistro = async (id,status) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recursos/equipos/status?id=${id}&status=${status}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/equipos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }
    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recursos/equipos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/equipos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleSubmitRuta = (e) => {
        e.preventDefault()
        if(!rutaBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerOrdenes(1, condicion)
        }
        const condicion = { id: parseInt(rutaBusqueda), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const handleSubmitPatente = (e) => {
        e.preventDefault()
        if(!patente) return toast.error('Selecciona una patente')
        const condicion = { patente: patente.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const handleSubmitNumeroInterno = (e) => {
        e.preventDefault()
        if(!numeroInterno) return toast.error('Selecciona una patente')
        const condicion = { numero_interno: numeroInterno.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerOrdenes(1, {})
    }

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const borrarFiltros = () => {
        setPatente('')
        setNumeroInterno('')
        setRutaBusqueda('')
        setCondicionBusqueda({})
        setPagina(1)
        return obtenerOrdenes(1, {})
    }

    const handleChangeRuta = (e) => {
        const { value } = e.target
        return setRutaBusqueda(value)
    }
 
    return <div className='text-primary'>
        {modalFiltro()}
        <Col>
        <Row className='align-items-center'>
            <Col md={4}>
            <h4 style={{fontWeight:900}}>Rutas 
                {/* <OverlayTrigger
                    placement={'top'}
                    overlay={
                        <Tooltip >Crear nueva</Tooltip>
                    }
                    >
                    <Link to="/rutas/nuevo" className='mr-2 delpa-nueva-ruta'><i className="fa-solid fa-circle-plus"></i></Link>
                    </OverlayTrigger>
                <OverlayTrigger
                    placement={'top'}
                    overlay={
                        <Tooltip>Importar Excel</Tooltip>
                    }
                    >
                    <Link to="/rutas/importar" className='delpa-importar-ruta'><i className="fas fa-file-import"></i></Link>
                </OverlayTrigger>
               */}
              </h4>
            </Col>
            <Col md={8} className="">
              <div>
                <Navbar bg="light" variant="light" className='mb-2' style={{fontSize:12}}>
                    {/* <Navbar.Brand >Órdenes</Navbar.Brand> */}
                    <Nav className="mr-auto">
                    <Nav.Link ><Link to={`/${rutas.ordenes_crear.slug}`}><FaPlusCircle /> CREAR NUEVO</Link> </Nav.Link>
                    <Nav.Link ><Link to={`/${rutas.rutas_importar_excel.slug}`}><FaFileExcel /> IMPORTAR EXCEL</Link> </Nav.Link>
                    <Nav.Link ><Link to={`/${rutas.rutas_importar_avanzado.slug}`}><FaFileExcel /> IMPORTADOR AVANZADO</Link> </Nav.Link>
                    </Nav>
                    {/* <Form inline>
                    <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                    <Button variant="outline-info">Search</Button>
                    </Form> */}
                </Navbar>
                </div>
            </Col>
            </Row>
            <Row className='align-items-center'>
                <Col md={6}>
                    <form onSubmit={handleSubmitRuta}>
                        <input className='mb-3 form-control delpa-buscador-ruta shadow-sm' placeholder='BUSCAR POR NÚMERO RUTA' onChange={handleChangeRuta} />
                    </form>
                </Col>
                <Col md={3} className="text-right">
                    <Button variant="outline-primary" 
                    style={{fontSize:12}}
                    className='delpa-filtro w-100 shadow-sm mb-3' 
                    onClick={()=>abrirFiltro()}>FILTRAR
                    </Button>
                </Col>
                <Col md={3} className="text-right">
                    <Button variant="outline-danger" 
                    style={{fontSize:12}}
                    className='delpa-filtro w-100 shadow-sm mb-3' 
                    onClick={()=>borrarFiltros()}>BORRAR FILTROS
                    </Button>
                </Col>
            </Row>
                {tabla_registros(equipos)}            
        </Col>
    </div>

}

export default RutasListadoV2