import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Spinner, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { virtual_tour_settings } from '../../lib/global/data';
import Joyride from 'react-joyride';
import BuscadorVehiculos from '../conductores/buscador';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import { addMoreDays, formatDateHoy } from '../../lib/helpers/dates';
import { useQuery } from '../../lib/helpers/router';
import BoxListV2 from './box/box-list-v2';
import { DateTime } from 'luxon';
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import LoadingRoutes from '../general/loadingAnimations/loadingRoutes';
import HelperModal from '../general/helperModal';

const RutasListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [ equipos, setEquipos ] = useState([])
    const [ estados, setEstados ] = useState([])
    const [ loadingEsquemaEstados, setloadingEsquemaEstados ] = useState(true)
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const location = useLocation()
    const navigate = useNavigate();
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const [ estadoRutaFiltro, setEstadoRutaFiltro ] = useState('')
    const [ tipoAsignacionFiltro, setTipoAsignacionFiltro ] = useState('')
    const [ rutaSeleccionada, setRutaSeleccionada ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState(false)
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ rutaBusqueda, setRutaBusqueda ] = useState('')
    const [ openedFirst, setOpenedFirst ] = useState(false)

    
    const dispatch = useDispatch()
    let query = useQuery();

    const replaceKeyByParam = (key, reverse) => {
        let llaves = {
            "id": "id",
            "createdAt": "fecha",
            "updatedAt": "fecha",
            "finalizada": "estado",
            "id_conductor": "conductor",
        }

        if(reverse === true){
            for( const llave in llaves ){
                if(llaves[llave] === key) return llave
            }
        }

        return llaves[key] ? llaves[key] : false
    }


    const procesarParametros = (parametros) => {

        let params = []
        Object.keys(parametros).forEach(key => {

            const llave = replaceKeyByParam(key)

            if(llave){
                if(key === "id"){

                    params.push({ key: "id", value: parametros[key] })

                } else if(key === "finalizada"){
                    params.push({ key: "estado", value: parametros[key] === true ? "finalizada" : "en-curso" })
                } else if( key === "id_conductor"){
                    let ids = []
                    if(parametros[key]) if(typeof parametros[key] === "object") if(parametros[key]["$in"]) if(Array.isArray(parametros[key]['$in'])) if(parametros[key]['$in'].length > 0){
                        parametros[key]['$in'].map(id => {
                            ids.push(id)
                        })
                    }
                    params.push({ key: "conductor", value: ids.join("|") })

                } else if( key === "createdAt"){
                    params.push({ key: "datetype", value : key })
                    params.push({ key: "from", value: parametros[key]['$gte'] })
                    params.push({ key: "to", value : parametros[key]['$lte'] })

                } else if( key === "updatedAt"){
                    params.push({ key: "datetype", value : key })
                    params.push({ key: "from", value: parametros[key]['$gte'] })
                    params.push({ key: "to", value : parametros[key]['$lte'] })

                }
            }
        })

        if(query.get('page')) params.push({ key: "page", value: query.get('page') })
        if(query.get('id')) params.push({ key: "id", value: query.get('id') })

        console.log('params', params)
        const queryParams = new URLSearchParams();
        for( const campo of params ){
            if(campo.value) queryParams.set(campo.key, campo.value);
        }
        navigate({
            pathname: location.pathname,
            search: `?${queryParams.toString()}`,
        });
    }


    const obtenerOrdenes = async (page, query)=>{
        setLoadingEquipos(true)
        // if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda

        if(query) if(Object.keys(query).length > 0) procesarParametros(condicion)
        
        return fetch(`${data.urlapi}/rutas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                if(res.datos.length > 0) if(!openedFirst){
                    setOpenedFirst(true)
                    obtenerRuta(res.datos[0]._id)
                }
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    const obtenerOrdenesUrl = async (page, query)=>{
        console.log("entro")
        setLoadingEquipos(true)
        // if(query) if(trash === true) query.status = 'trash'
        const currentUrl = new URL(window.location.href);    
        const search_url = currentUrl.search
        let condicion = query ? query : condicion_busqueda

          // Parsear la cadena de consulta (query string) a un objeto
          const queryParams = new URLSearchParams(search_url);
          console.log("queryParams", queryParams )
          const tiposervicio = queryParams.get("tiposervicio");
        //   const filtro_zona = queryParams.get("filtro_zona");
          const fecha = queryParams.get("fecha");
          const desde = queryParams.get("desde");
          const hasta = queryParams.get("hasta"); 
          const estado = queryParams.get("estado");
          const conductor = queryParams.get("conductor") 

          if(conductor) condicion.id_conductor = {$in: [conductor]}
          if(estado === "todas") console.log("seleccionaste todas")
          if(estado === "en-curso") condicion.finalizada = false
          if(estado === "finalizada") condicion.finalizada = true
          if(fecha === "creacion") condicion.createdAt = {$gte: desde,$lte: hasta}
          if(fecha === "actualizacion") condicion.updatedAt = {$gte: desde,$lte: hasta}
          if(fecha === "fecha-despacho") condicion.$or = [{entrega_desde: {$gte: desde, $lte: hasta}}, {entrega_hasta: {$gte: desde,$lte: hasta}}, {entrega_desde: {$lte: desde}, entrega_hasta: {$gte: hasta}} ]
  
          // Crear un objeto con las propiedades deseadas
          let requestBody = {
        //   filtro_zona
          };

        return fetch(`${data.urlapi}/rutas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    const navegar = (key, value) => {
        const queryParams = query
        queryParams.set(key, value);
        navigate({
            pathname: location.pathname,
            search: `?${queryParams.toString()}`,
        });
    }

    const obtenerRuta = async (id) => {
        if(idSeleccionado === id) return false

        navegar('id', id)

        // const currentUrl = new URL(window.location.href);
        // if(!currentUrl.search.id) {
        // currentUrl.searchParams.set('id', id);
        // window.history.pushState({}, '', currentUrl.toString())}


        setLoadingDetalle(true)
        setIdSeleccionado(id)
        window.scrollTo({top: 0, behavior: 'smooth'});
        return fetch(`${data.urlapi}/rutas/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setRutaSeleccionada(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        navegar('page', page)
        obtenerOrdenes(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const onChangeConductor = (e) => {
        // const conductor = e[0]?._id
        // const currentUrl = new URL(window.location.href);
        // currentUrl.searchParams.set('conductor', conductor);
        // window.history.pushState({}, '', currentUrl.toString())
        setConductor(e)
    }

    const obtenerRangos = (start, end) => {
        const fecha1 = formatDateHoy(start)
        const fecha2 = formatDateHoy(end)
        let desde = new Date(fecha1)
        let hasta = addMoreDays(fecha2,1)
        if(fecha1 === fecha2){
            desde = DateTime.fromISO(fecha1).startOf("day").toUTC().toISO({ includeOffset: false })
            hasta = DateTime.fromISO(fecha1).endOf("day").toUTC().toISO({ includeOffset: false })
        } else {
            desde = DateTime.fromISO(fecha1).startOf("day").toUTC().toISO({ includeOffset: false })
            hasta = DateTime.fromISO(fecha2).endOf("day").toUTC().toISO({ includeOffset: false })
        }
        return { desde: `${desde}+00:00`, hasta: `${hasta}+00:00` }
    }

    const filtrar = () => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        const fechaDesde = desde;
        console.log("desde", fechaDesde)
        // const currentUrl = new URL(window.location.href);
        // currentUrl.searchParams.set('desde', fechaDesde);
        // window.history.pushState({}, '', currentUrl.toString())
        const fechaHasta = hasta;
        console.log("hasta", fechaHasta)
        // currentUrl.searchParams.set('hasta', fechaHasta);
        // window.history.pushState({}, '', currentUrl.toString())
        // Elimina el parámetro 'id'
        // currentUrl.searchParams.delete('id');

        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: hasta } }
            if(conductor) if(Array.isArray(conductor)) if(conductor.length > 0) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            if(estadoRutaFiltro) condicion.finalizada = estadoRutaFiltro === "finalizada" ? true : false
            if(tipoAsignacionFiltro) condicion.tipo_asignacion = tipoAsignacionFiltro

            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: hasta } }
            if(conductor) if(Array.isArray(conductor)) if(conductor.length > 0) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            if(estadoRutaFiltro) condicion.finalizada = estadoRutaFiltro === "finalizada" ? true : false
            if(tipoAsignacionFiltro) condicion.tipo_asignacion = tipoAsignacionFiltro

            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'fecha-despacho'){
            const condicion = { $or:[
                { entrega_desde: { $gte: desde, $lte: hasta } },
                { entrega_hasta: { $lte: hasta, $gte: desde } },
                { entrega_hasta: { $gte: hasta }, entrega_desde: { $lte: desde } },
            ]
            }
            if(conductor) if(Array.isArray(conductor)) if(conductor.length > 0) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            if(estadoRutaFiltro) condicion.finalizada = estadoRutaFiltro === "finalizada" ? true : false
            if(tipoAsignacionFiltro) condicion.tipo_asignacion = tipoAsignacionFiltro

            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'todos'){
            const condicion = {  }
            if(conductor) if(Array.isArray(conductor)) if(conductor.length > 0) condicion.id_conductor = { $in: conductor.map(c => c._id) }
            if(estadoRutaFiltro) condicion.finalizada = estadoRutaFiltro === "finalizada" ? true : false
            if(tipoAsignacionFiltro) condicion.tipo_asignacion = tipoAsignacionFiltro

            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        }
    }

    const handleChangeFechaBusqueda = (e) => {
        const { value } = e.target
        const newFecha = value;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('fecha', newFecha);
        // Elimina el parámetro 'id'
        currentUrl.searchParams.delete('id');
        window.history.pushState({}, '', currentUrl.toString())
        return setTipoFechaBusqueda(value)
    }

    const handleChangeEstadoRutaFiltro = (e) => {
        return setEstadoRutaFiltro(e.target.value)
    }
    
    const handleChangeTipoRutaFiltro = (e) => {
        const valor = e.target.value
        const val = valor === "vehiculo" ? { $in: ["vehiculo",null,""] } : valor
        return setTipoAsignacionFiltro(val)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal className='text-primary' show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header className='pb-0' closeButton>
          <Modal.Title style={{fontWeight:900}} ><i className="fa-solid fa-filter"></i> Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label' style={{fontWeight:700}}>Fecha</label>
                    <select name="tipo_fecha" className='form-control shadow-sm'  value={tipoFechaBusqueda} onChange={handleChangeFechaBusqueda} >
                        <option value="creacion">Creación</option>
                        <option value="actualizacion">Actualización</option>
                        <option value="fecha-despacho">Fecha despacho</option>
                        <option value="todos">Cualquiera</option>
                    </select>
                </Col>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label' style={{fontWeight:700}}>Estado de la ruta</label>
                    <select name="tipo_fecha" className='form-control shadow-sm'  value={estadoRutaFiltro} onChange={handleChangeEstadoRutaFiltro} >
                        <option value="">Todas</option>
                        <option value="en-curso">En curso</option>
                        <option value="finalizada">Finalizada</option>
                    </select>
                </Col>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label' style={{fontWeight:700}}>Tipo de asignación</label>
                    <select name="tipo_asignacion" className='form-control shadow-sm'  value={typeof tipoAsignacionFiltro === "object" ? "vehiculo" : tipoAsignacionFiltro} onChange={handleChangeTipoRutaFiltro} >
                        <option value="">Todas</option>
                        <option value="vehiculo">Propias</option>
                        <option value="proveedor">Gestionadas por proveedores</option>
                    </select>
                </Col>
                <Col xs={12} className="mb-3">
                    {/* {JSON.stringify(conductor)} */}
                    <BuscadorVehiculos defaultValue={conductor} isMulti={true} token={token} onChange={(data) => onChangeConductor(data)} />
                </Col>
                {
                    tipoFechaBusqueda !== "todos" ? <Col xs={12} className="mb-2">
                    <DateRangePicker
                        locale={es}
                        showSelectionPreview={true}
                        editableDateInputs={true}
                        onChange={item => cambiarRangoFecha(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        months={1}
                    />
                    </Col> : false
                }
                <Col md={12} className='text-right'>
                    <Button size="sm" variant="primary" className='w-25 shadow-sm' onClick={()=>filtrar()}><b>FILTRAR</b></Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    const findFirstQuery = () => {
        let final_query = {}
        const condition = query
        if(condition.size){
            for( const field of condition.keys() ){

                if(field === "estado") {
                    final_query.finalizada = condition.get(field) === "finalizada" ? true : false
                } else if(field === "conductor"){
                    final_query.id_conductor = { $in: condition.get(field).split("|") }
                } else if( field === "datetype"){
                    const tipo = condition.get(field)
                    const desde = condition.get("from")
                    const hasta = condition.get("to")
                    final_query[tipo] = { $gte: desde, $lte: hasta }
                }
            }
            return final_query
        }
        return false
    }

    useEffect(() => {

        const first_query = findFirstQuery()

        obtenerOrdenes(1, first_query)
        obtenerEsquemaEstados()
        const id_ruta_url = query.get("id")
        if(id_ruta_url){
            obtenerRuta(id_ruta_url)
        }
}, []) // eslint-disable-line react-hooks/exhaustive-deps

    const obtenerEsquemaEstados = () => {
      return fetch(`${data.urlapi}/ordenes/suite-config-estados`,{
        method:'GET',
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
      .then(pros => pros.json())
      .then(data => { 
          console.log(data)
            if(!data){
            toast.error('Sin datos')
            return setRemoviendo(false)
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return setRemoviendo(false)
            } else if(Array.isArray(data) !== false){
                setEstados(data)
            }
            return setloadingEsquemaEstados(false)
      })
      .catch(error => {
          toast.error('No pudimos cargar la información')
          setloadingEsquemaEstados(false)
      })
    }

    const cargandoScreen = () => {
        return <LoadingRoutes />
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingEquipos===true) return cargandoScreen()
        if(loadingEsquemaEstados===true) return cargandoScreen()

        return <div>
             {/* <Joyride styles={{options:virtual_tour_settings.estilo}} continuous={true} showProgress={true} steps={virtual_tour_settings.pasos_rutas} locale={virtual_tour_settings.locale} /> */}
            <h6>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h6>
            {paginacion(data.pagina, total)}
            <div className='delpa-rutas'>
                {
                        registros.length > 0 ? registros.map(orden => {
                            let seleccionado = false
                            if(idSeleccionado === orden._id) seleccionado = true
                            return <div key={orden._id} className="mb-3">
                                <BoxList ruta={orden} seleccionado={seleccionado} onClickRuta={(id) => obtenerRuta(id)} />
                            </div>
                        }) : <div>
                                <h5>Nada por aquí</h5>
                                <p>No hay registros en esta sección</p>
                            </div>
                    }
            </div>
        </div>
    }

    const restaurarRegistro = async (id,status) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recursos/equipos/status?id=${id}&status=${status}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/equipos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }
    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recursos/equipos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/equipos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleSubmitRuta = (e) => {
        e.preventDefault()
        if(!rutaBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerOrdenes(1, condicion)
        }
        const condicion = { id: parseInt(rutaBusqueda), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const handleSubmitPatente = (e) => {
        e.preventDefault()
        if(!patente) return toast.error('Selecciona una patente')
        const condicion = { patente: patente.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const handleSubmitNumeroInterno = (e) => {
        e.preventDefault()
        if(!numeroInterno) return toast.error('Selecciona una patente')
        const condicion = { numero_interno: numeroInterno.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerOrdenes(1, {})
    }

    const abrirFiltro = () => {
        // const currentUrl = new URL(window.location.href)
        // if(currentUrl.search) borrarFiltrosUrl()
        return setOpenModal(true)
    }

    const handleChangeRuta = (e) => {
        const { value } = e.target
        return setRutaBusqueda(value)
    }

    const borrarFiltrosUrl = () => {
        obtenerOrdenes(1, {})
        const queryParams = new URLSearchParams();
        navigate({
            pathname: location.pathname,
            search: `?${queryParams.toString()}`,
        });
        setPagina(1)
        setTipoFechaBusqueda('todos')
        setEstadoRutaFiltro('')
        setConductor(false)
        return false
    }
 
    return <div className='text-primary'>
        {modalFiltro()}
        <Row>
            <Col md={3} style={{ height: "90vh", overflow: "scroll" }}>
            <h4 style={{fontWeight:900}}>Rutas <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Crear nueva</Tooltip>
                            }
                            >
                            <Link to="/rutas/nuevo" className='mr-2 delpa-nueva-ruta'><i className="fa-solid fa-circle-plus"></i></Link>
                          </OverlayTrigger>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Importador Excel</Tooltip>
                            }
                            >
                            <Link to="/rutas/importar" className='mr-2 delpa-importar-ruta'><i className="fas fa-file-import"></i></Link>
                        </OverlayTrigger>
                        <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Importador avanzado </Tooltip>
                            }
                            >
                            <Link to="/rutas/importar-avanzado" className='delpa-importar-ruta'><i className="fas fa-file-import"></i></Link>
                        </OverlayTrigger>
              
              </h4>
              <Card className='shadow-sm p-3 mb-2'>
                    {/* <Card className='shadow-sm p-2'> */}
                <Row className='align-items-center'>
                    <Col md={9}>
                        <form onSubmit={handleSubmitRuta}>
                        <input className='form-control delpa-buscador-ruta shadow-sm' placeholder='BUSCAR POR # RUTA' onChange={handleChangeRuta} />
                        </form>
                    </Col>
                    <Col md={3}>
                    <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Filtrar</Tooltip>
                            }
                            >
                        <Button size='sm' variant="primary" className='delpa-filtro shadow-sm w-100' onClick={()=>abrirFiltro()}>
                        <i className="fa-solid fa-filter"></i>
                            {/* <b>FILTRAR</b> */}
                            </Button> 
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className='align-items-center mt-2'>
                    <Col md={9}>
                        
                    </Col>
                    <Col md={9}>
                        <span>Borrar filtros</span>
                    </Col>
                    <Col md={3}>
                    <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Elimina los filtros</Tooltip>
                            }
                            >
                        <Button size='sm' variant="primary" className='delpa-filtro shadow-sm w-100' onClick={()=>borrarFiltrosUrl()}><i className="fa-solid fa-trash"></i>
                            {/* <b>FILTRAR</b> */}
                            </Button> 
                        </OverlayTrigger>
                    </Col>
                </Row>
                    </Card>
                <Card className="shadow-sm p-2">
                {tabla_registros(equipos)}
                </Card>
            </Col>
            <Col md={9}>
            <HelperModal tipo="rutas" style="modal" />
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull ruta={rutaSeleccionada} token={token} estados={estados} />
                    }
            </Col>
        </Row>
    </div>

}

export default RutasListado