import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import { toast } from "react-toastify"
import { Badge, Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { useEffect, useState } from "react"

const CamposPersonalizadosSelectores = (props) => {
    const {
        defaultSchema
    } = props
    const [ camposPersonalizados, setCamposPersonalizados ] = useState([])
    const [ loadingCamposPersonalizados, setLoadingCamposPersonalizados ] = useState(true)
    const [ showModalAlertas, setShowModalAlertas ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerCamposPersonalizados()
    }, [])

    const obtenerCamposPersonalizados = async () => {
        return fetch(`${urlapi}/ordenes/campos-personalizados`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setCamposPersonalizados(res)
            }
            return setLoadingCamposPersonalizados(false)
        })
        .catch(error => {
            setLoadingCamposPersonalizados(false)
            return toast.error(error.message)
        })
    }

    const handleCloseModal = () => {
        setShowModalAlertas(false)
      }

    const handleChange = (e, name) => {
        const { value } = e.target
        if(props.onChange) props.onChange(value ? value.toString().toLowerCase() : null, name)
    } 

    const mostrarCamposPersonalizados = () => {
        if(loadingCamposPersonalizados === true) return <div><Spinner animation='border' /></div>
        if(camposPersonalizados.length < 1) return false
        return <div>
            {/* <Modal width={"80%"}  show={showModalAlertas} onHide={() => handleCloseModal()}>
            <div className="p-3">
                <h6 className="mb-0" style={{ fontWeight: "bold" }}>Campos personalizados</h6>
                <p className="mb-0">Puedes agregar cualquier de estos campos personalizados a tu archivo excel</p>
                <hr className='hr' />
            </div>
            </Modal> */}
            {/* <h5>Campos personalizados</h5> */}
            <Row>
                {
                    camposPersonalizados.filter(e => e.type === "select").map(campo => {
                        const schema = defaultSchema ? defaultSchema : {}
                        const opciones = Array.isArray(campo.options) ? campo.options : []
                        const slug      = campo.slug.toString().toLowerCase()
                        return <Col xs={12} className="mb-3" key={campo._id} >
                            <label className="form-control-label d-block" >{campo.nombre}</label>
                            <select className="form-control" name={campo.slug} defaultValue={schema[slug]} onChange={(e) => handleChange(e, campo.slug)}>
                                <option value="">Seleccione</option>
                                {
                                    opciones.map(opcion => {
                                        const val = opcion.value.toString().toLowerCase()
                                        return <option key={`${val}-${campo.slug}`} value={val}>{val}</option>
                                    })
                                }
                            </select>
                        </Col>

})
                }
            </Row>
            {/* <Card className="p-2 text-center hover" onClick={() => setShowModalAlertas(true)} ><h6 className="p-0 m-0 uppercase">CAMPOS PERSONALIZADOS</h6></Card> */}
        </div>
    }

    return <div>
        {mostrarCamposPersonalizados()}
    </div>

}

export default CamposPersonalizadosSelectores