import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { cerrarSesion } from '../../redux/actions/session';
import { Badge, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes';

const BuscadorAvanzadoOrdenes = (props) => {
    const [sugerencias, setSugerencias] = useState([]);
    const [ loading, setLoading ] = useState(false)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ showBox, setShowBox ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const token = session.tokenSession

    const obtenerDatos = async (valor) => {
        setLoading(true)
        return fetch(`${urlapi}/ordenes/advance-search`,{
            method:'POST',
            body: JSON.stringify({
                valor
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setShowBox(true)
                setSugerencias([...[], ...res])
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })

    }

    const buscarRegistros = (val) => {
        if (!val){
            return false
        }
        return obtenerDatos(val);
      };

    const busquedaAutomaticamente = useCallback(debounce((data) => buscarRegistros(data), 1000), []);


    const handleChangeCampo = (e) => {
        const { value } = e.target
        busquedaAutomaticamente(value)
        return setCampoBusqueda(value);
      };

      const mostrarEstadoReferencia = () => {
        if (loading === true) return <p style={{ position: 'absolute', right: 40, bottom: 13, fontSize: 10 }}><Spinner size="sm" animation='border' /></p>
        return false
    }

    const mostrarResultados = () => {
        if(!showBox) return false
        if(sugerencias.length === 0) return false
        return <div className="shadow p-2" style={{ position: 'absolute', width: '100%', zIndex: 999, background: '#fff', maxHeight: 200, overflowY: 'scroll' }}>
            {sugerencias.map((item, index) => {
                let direccion = ''
                let geo_datos = ''
                if(item.orden) if(typeof item.orden === "object") if(item.orden.billing) if(typeof item.orden.billing === "object") if(item.orden.billing.address_1) direccion = item.orden.billing.address_1
                if(item.orden) if(typeof item.orden === "object") if(item.orden.geo_datos) if(typeof item.orden.geo_datos === "object"){
                    if(item.orden.geo_datos.level1) geo_datos = item.orden.geo_datos.level1
                    if(item.orden.geo_datos.level2) geo_datos = `${geo_datos} ${item.orden.geo_datos.level2}`
                    if(item.orden.geo_datos.level3) geo_datos = `${geo_datos} ${item.orden.geo_datos.level3}`
                    if(item.orden.geo_datos.pais) geo_datos = `${geo_datos} ${item.orden.geo_datos.pais}`
                    if(geo_datos) direccion = geo_datos
                }

                return <div key={index} style={{ cursor: 'pointer' }} >
                    <a target='_blank' href={`/${rutas.ordenes.slug}/${item._id}`} >
                    <div>
                    <h6 className='mb-0 bold'>{item.pedido}</h6>
                    {direccion ? <p className='mb-0' style={{ fontSize: 12, color: "gray" }}>{direccion}</p> : false}
                    {item.meta.map((meta, index) => <Badge style={{ background: "#ddd" }} key={index} className="mr-1" ><b style={{ fontWeight: "bold", textTransform: "uppercase" }}>{meta.key}</b> {meta.value}</Badge> )}
                    { item.ruta ? <p className='mb-0' style={{ fontSize: 12, color: "gray" }}>RUTA {item.ruta?.id}</p> : false }
                    </div>
                    </a>
                    {(index + 1) !== sugerencias.length ? <hr className='mb-2' /> : false}
                </div>
            })}
        </div>
    }

    const handleFocus = () => {
        setShowBox(true)
    }
    const handleBlur = () => {
        setTimeout(() => {
            setShowBox(false)
        }, 350);
    }

    return <div style={{ height: "100%" }}>
        {mostrarEstadoReferencia()}
        <input style={{ height: "56px !important", width: "100%", padding: "4px 10px", borderRadius: 5, border: "1px solid #d8d8d8" }} onFocus={handleFocus} onBlur={handleBlur} placeholder="Escribe cualquier información de tu orden" onChange={handleChangeCampo} />
        {mostrarResultados()}
    </div>
}

export default BuscadorAvanzadoOrdenes;