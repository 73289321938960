import React from "react";
import { useState, useContext } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger, Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { url_images } from "../../lib/global/data";
import { rutas } from "../../lib/routes/routes";
import { estilo_last_mile } from "../../lib/global/styles";
import { FaArrowRight } from "react-icons/fa";
import { data_landing } from "../landing/data";
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { BiSolidHelpCircle } from "react-icons/bi";

const LISTA_MODULOS = data_landing.map(e => {
    return e
})


const HelperModal = (props) => {
    const {
        tipo,
        style
    } = props

    const [isOpen, setIsOpen] = useState(false);
    const route = useLocation();

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    if(!tipo) return false
    const i = LISTA_MODULOS.findIndex(mo => mo.slug === tipo)
    if(i < 0) return false

    const info = LISTA_MODULOS[i]

    if(style === "modal") return <div>
        <div style={{ textAlign: "right" }}><Button size="sm" variant="link" onClick={() => setIsOpen(true)}><BiSolidHelpCircle /> Más Información</Button></div>
        <Modal show={isOpen} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="text-center">
        <div style={{ maxWidth: "80%", margin: "0 auto" }}>
        { info.image ? <img src={info.image} style={{ maxWidth: 200, marginTop: -40 }} /> : false }
        <Row className='justify-content-center'>
                        <Col md={12} xs={12}>
                        <h1 className="masthead-heading mb-0" style={{ fontWeight: 900, color: estilo_last_mile.color_primary, fontSize: 20 }}>{info.title}</h1>
                        <h2 className="masthead-subheading mb-3" style={{ fontWeight: 400, fontSize: 15 }}>{info.text}</h2>
                        {
                            info.links ? <Row className='justify-content-center'>
                                {
                                    info.links.map((item,it) => {
                                        const styleIcon = { size: 30, color: estilo_last_mile.color_primary }
                                        const Icono = item.icon ? item.icon : false
                                        return <Col md={2} key={`item-${it}`} className='mb-3'>
                                                <h5 style={{ fontSize: 12 }} className='mb-0 mt-2'><TbCircleArrowRightFilled /> {item.title}</h5>
                                        </Col>
                                    })
                                }
                            </Row> : false
                        }
                        </Col>
                    </Row>
        </div>
        </Modal.Body>
        
      </Modal>
    </div>

    if(style === "simple") return <div>
    <div style={{ maxWidth: "80%", margin: "0 auto" }}>
    { info.image ? <img src={info.image} style={{ maxWidth: 200, marginTop: -40 }} /> : false }
    <Row className='justify-content-center'>
                    <Col md={12} xs={12}>
                    <h1 className="masthead-heading mb-0" style={{ fontWeight: 900, color: estilo_last_mile.color_primary, fontSize: 20 }}>{info.title}</h1>
                    <h2 className="masthead-subheading mb-3" style={{ fontWeight: 400, fontSize: 15 }}>{info.text}</h2>
                    {
                        info.links ? <Row className='justify-content-center'>
                            {
                                info.links.map((item,it) => {
                                    const styleIcon = { size: 30, color: estilo_last_mile.color_primary }
                                    const Icono = item.icon ? item.icon : false
                                    return <Col md={2} key={`item-${it}`} className='mb-3'>
                                            <h5 style={{ fontSize: 12 }} className='mb-0 mt-2'><TbCircleArrowRightFilled /> {item.title}</h5>
                                    </Col>
                                })
                            }
                        </Row> : false
                    }
                    </Col>
                </Row>
    </div>
    
    </div>


    if(style === "nodata") return <div className="text-center">
    <div style={{ maxWidth: "70%", margin: "0 auto", textAlign: "center" }}>
    <img src={`${url_images}/animations/6842611.gif`} style={{ maxWidth: 200, marginBottom: -20, marginTop: -20 }} />
    <Row className='justify-content-center'>
                    <Col md={12} xs={12}>
                    <h1 className="masthead-heading mb-0" style={{ fontWeight: 900, color: estilo_last_mile.color_primary, fontSize: 20 }}><i className="fa-solid fa-triangle-exclamation text-warning"></i> No hay datos que mostrar</h1>
                    <h2 className="masthead-subheading mb-3" style={{ fontWeight: 400, fontSize: 15 }}>Rellena los campos necesarios para poder mostrarte la información que solicitas.</h2>
                    </Col>
                </Row>
            </div>
        </div>



    if(style === "nada") return <div className="text-center">
    <img src={`${url_images}/animations/6342141.gif`} style={{ maxWidth: 150}} />
    <Row className='justify-content-center'>
                    <Col md={12} xs={12}>
                    <h1 className="masthead-heading mb-0" style={{ fontWeight: 900, color: estilo_last_mile.color_primary, fontSize: 20 }}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Nada que mostrar</h1>
                    <h2 className="masthead-subheading mb-3" style={{ fontWeight: 400, fontSize: 15 }}>No hay registros en esta sección.</h2>
                    </Col>
                </Row>
            </div>
};

export default HelperModal