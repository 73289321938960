const stringByStatusRule = (status) => {
    const tipos = {
        "pending": "Pendiente",
        "fulfilled": "Cumplido",
        "unfulfilled": "Incumplimiento"
    }
    return tipos[status] ? tipos[status] : "Sin estado"
}

module.exports ={
    stringByStatusRule
}