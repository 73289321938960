import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { marketplaces, urlapi } from "../../lib/backend/data"
import { toast } from "react-toastify"
import { Card, Col, Row, Spinner } from "react-bootstrap"
import SinDatos from "../general/sin_registros"
import { FaCheckCircle, FaClock } from "react-icons/fa"

const IntegracionesProducto = (props) => {
    const {
        id
    } = props
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [ error, setError ] = useState('')
    const [ data, setData ] = useState(false)
    const [ loading, setLoading ] = useState(true)

    const consultarDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/productos/extra-data?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){

                } else if(pros.errorMessage){
                    setError(pros.errorMessage)
                } else if(pros._id){
                setData(pros)
              }
              return setLoading(false)
          })
          .catch(async error => {
                console.log(error.message)
                toast.error('No se pudo consultar la información.')
                return setLoading(false)
          })
    }


    useEffect(() => {
        consultarDatos()
    }, [])

    const showDataByMarketplace = (market) => {
        switch (market.name) {
            case "woocommerce":
                return <div>
                    <Row >
                        <Col md={4}>
                            <Card className="p-3">
                            <h5 className="mb-0"><b>ID sincronizado</b></h5>
                            <h5 className="mb-0">{data.id_woocommerce ? <div className="text-success"><FaCheckCircle /> LISTO</div> : <div><FaClock /> PENDIENTE</div> }</h5>
                            <p className="mb-0">El ID es necesario para sincronizar precios e inventarios</p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            case "jumpseller":
                return <div>
                    <Row >
                        <Col md={4}>
                            <Card className="p-3">
                            <h5 className="mb-0"><b>ID sincronizado</b></h5>
                            <h5 className="mb-0">{data.id_jumpseller ? <div className="text-success"><FaCheckCircle /> LISTO</div> : <div><FaClock /> PENDIENTE</div> }</h5>
                            <p className="mb-0">El ID es necesario para sincronizar precios e inventarios</p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            case "magento":
                return <div>
                    <Row >
                        <Col md={4}>
                            <Card className="p-3">
                            <h5 className="mb-0"><b>ID sincronizado</b></h5>
                            <h5 className="mb-0">{data.id_magento ? <div className="text-success"><FaCheckCircle /> LISTO</div> : <div><FaClock /> PENDIENTE</div> }</h5>
                            <p className="mb-0">El ID es necesario para sincronizar precios e inventarios</p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            case "prestashop":
                return <div>
                    <Row >
                        <Col md={4}>
                            <Card className="p-3">
                            <h5 className="mb-0"><b>ID sincronizado</b></h5>
                            <h5 className="mb-0">{data.prestashop ? <div className="text-success"><FaCheckCircle /> LISTO</div> : <div><FaClock /> PENDIENTE</div> }</h5>
                            <p className="mb-0">El ID es necesario para sincronizar precios e inventarios</p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            case "shopify":
                return <div>
                    <Row >
                        <Col md={4}>
                            <Card className="p-3">
                            <h5 className="mb-0"><b>ID sincronizado</b></h5>
                            <h5 className="mb-0">{data.id_shopify ? <div className="text-success"><FaCheckCircle /> LISTO</div> : <div><FaClock /> PENDIENTE</div> }</h5>
                            <p className="mb-0">El ID es necesario para sincronizar precios e inventarios</p>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="p-3">
                            <h5 className="mb-0"><b>ID de variante</b></h5>
                            <h5 className="mb-0">{data.id_shopify_variant ? <div className="text-success"><FaCheckCircle /> LISTO</div> : <div><FaClock /> PENDIENTE</div> }</h5>
                            <p className="mb-0">El ID es necesario para sincronizar precios e inventarios</p>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="p-3">
                            <h5 className="mb-0"><b>ID de localización de inventario</b></h5>
                            <h5 className="mb-0">{data.shopify_inventory_item_id ? <div className="text-success"><FaCheckCircle /> LISTO</div> : <div><FaClock /> PENDIENTE</div> }</h5>
                            <p className="mb-0">El ID es necesario para sincronizar inventarios</p>
                            </Card>
                        </Col>
                    </Row>
                </div>
            default:
                <div>
                    <h5>Pendiente de configurar</h5>
                </div>
        }
    }

    if(!id) return false
    if(loading) return <Spinner animation="border" variant="primary" />
    if(error) return <div className="text-danger"><b>{error}</b></div>
    if(!data) return <SinDatos />

    return <div>
        <h4>Integraciones</h4>
        <p>En esta sección podrás visualizar el estado del producto para saber si está listo para ser sincronizado, la mayoría de tus productos debe pasar por un proceso de sincronización de datos esenciales con cada aplicación destino, una vez finalizado verás aquí el estado de cada uno</p>
        
        {
            marketplaces.map((market, index) => {
                return <Card key={market.logo} className="p-3 mb-3">
                    <img style={{ width: 160 }} src={market.logo} />
                    <div className="mt-3">{showDataByMarketplace(market)}</div>
                </Card>
            })
        }
    </div>
}

export default IntegracionesProducto