import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Breadcrumb, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import 'moment/locale/es';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import Select from 'react-select'
import { host } from '../../lib/global/data'
import GeocercaDrawer from '../../subComponents/geocercas/drawer'
import withRouter from '../RoutesApp/wrapped_component'
import { confirmAlert } from 'react-confirm-alert'
import { rutas } from '../../lib/routes/routes'
import { cerrarSesion } from '../../redux/actions/session'
import CamposPersonalizadosRelacionador from '../../subComponents/general/campos_personalizados_relacionador'
import { BiSolidError } from 'react-icons/bi'
import { FaCheckCircle, FaRegClock } from 'react-icons/fa'
import { estilo_last_mile } from '../../lib/global/styles'
import RastreoCargaVehiculo from '../../subComponents/conductores/rastreo_carga'
import EtiquetasRelaciones from '../../subComponents/etiquetas-relaciones/etiquetas_relaciones'
import SelectorCapacidadesEspeciales from '../../subComponents/capacidades_especiales/selector.tsx'
import { FaTruck } from 'react-icons/fa'
import { IoIosHelpCircle } from 'react-icons/io'
import TiposServicioSelectorPersonalizado from '../../subComponents/ordenes/tipos_servicio/selector_personalizado.js'

class VehiculosEditar extends Component {
    constructor(props){
        super(props)
        this.state = {
            id: this.props.params.id,
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            nuevoconductor: {
                activo: true
            },
            creandoVehiculo: true,
            loadingProveedores: true,
            proveedores: [],
            geocercas: [],
            defaultValue: [],
            validarTelefono: false,
            openModal: false,
            value: ""
        }
        this.handleChangeVehiculo = this.handleChangeVehiculo.bind(this)
        this.handleChangeCapacidades = this.handleChangeCapacidades.bind(this)
        this.handleChangeChecked = this.handleChangeChecked.bind(this)
        this.handleChangeProveedor = this.handleChangeProveedor.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.limpiarInput = this.limpiarInput.bind(this)
    }

    componentDidMount(){
        const { id } = this.state
        if(!id) return this.setState({ loadingUsuario: true })
        this.getVehiculo(id)
        this.obtenerProveedores({})
    }

    handleChangeProveedor(e){
        const { nuevoconductor } = this.state 
        nuevoconductor.id_proveedor = e.value
        return this.setState(nuevoconductor)
    }

    async obtenerProveedores(condicion){
        const { user } = this.state
        const loading = 'loadingProveedores'
        return fetch(`${data.urlapi}/proveedores/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            } else if(Array.isArray(res) !== false){
                let proveedores = res.map(p => ({ value: p._id, label: p.titulo }))
                proveedores.unshift({ value:'', label: "Ninguno" })
                this.setState({ proveedores })
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return this.setState({ [loading]: false })
        })
    }

    async getVehiculo(id){
        const { user, defaultValue } = this.state
        let loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return this.setState({ [loading]: false })
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return this.setState({ [loading]: false })
            }
            console.log(res)
            if(Array.isArray(res.capacidades_especiales)){
                let capacidades = res.capacidades_especiales.map(e => ({ value: e._id, label: e.valor }))
                this.setState({ defaultValue: capacidades})
            }
            const validar = this.validarPhone(res.phone)
            if(validar){
                    this.setState({validarTelefono: true })
                  }else{ 
                    this.setState({validarTelefono: false})}  
            return this.setState({ nuevoconductor: res, [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
        })
    }

    handleChangeCapacidades(data){
        const { nuevoconductor } = this.state
        nuevoconductor.capacidades_especiales = data
        console.log(data)
        console.log(nuevoconductor)
        return this.setState({ nuevoconductor })
       }

    abrirModal(){
        this.setState({openModal: true})
    }

    handleClose(){
        this.setState({openModal: false})
    }

    validarPhone = (str) => {
        const { nuevoconductor} = this.state
        nuevoconductor.phone = str
        this.setState({nuevoconductor})
        const regex = /^\+\d{11,}$/;
        return regex.test(str); // Retorna true si cumple, false si no
      };
    
      limpiarInput = (inputValue) => {
        if (inputValue.charAt(0) !== '+') {
            this.setState({ value: '' }); 
            return; 
          }
        let cleanedValue = inputValue.replace(/[^0-9]/g, ''); 
        // Si el primer carácter es '+', lo mantenemos, si no, lo añadimos
        if (inputValue.charAt(0) === '+') {
          cleanedValue = '+' + cleanedValue; // Agrega el '+' al inicio
        }
        this.setState({ value: cleanedValue });
        const validar = this.validarPhone(cleanedValue)
                        if(validar){
                                this.setState({validarTelefono: true })
                              }else{ 
                                this.setState({validarTelefono: false})}      
      };
    
     handleChange(e){
            this.limpiarInput(e.target.value);
        }   


    handleChangeVehiculo(e){
        const { name, value } = e.target
        const { nuevoconductor } = this.state    
        if(name === 'rut'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === 'frecuencytracking'){
            nuevoconductor[name] = value.replace(/\D/g, "")
        } else if(name === "phone"){
            const valor = value ? value : "+"
            nuevoconductor[name] = valor
        } else {
            nuevoconductor[name] = value
        }
        console.log("nuevo_c", nuevoconductor)
        return this.setState({ nuevoconductor })
    }

    handleChangeChecked(checked){
        return this.setState({ show_password: checked })
    }

    editarVehiculo(){
        const { nuevoconductor, user, validarTelefono } = this.state
        if(!validarTelefono) return  toast.error("Verifique que el telefono este bien escrito", this.state.toaststyle)

        const requeridos = [
            { value:'nombres', label: 'Nombres' },
            { value:'apellidos', label: 'Apellidos' },
            { value:'patente', label: 'Patente' },
            { value:'phone', label: 'Teléfono' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoconductor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`) 
       

        const loading = 'creandoVehiculo'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/conductores`,{
            method:'PUT',
            body: JSON.stringify( nuevoconductor ),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){ 
                toast.success('Guardado exitosamente')
                setTimeout(() => {
                    return window.location.reload()
                }, 500);
            }
            this.setState({ [loading]: false })
        })
        .catch(error => {
            this.setState({ [loading]: false })
            toast.error("Error al realizar esta operación, intente nuevamente", this.state.toaststyle)
        })
    }

    sinResultados() {
        return <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img src={`${host}/images/pedidos.png`} style={{ width: "50%" }} />
        <h4>No hay datos suficientes para visualizar esta sección</h4>
        </Col>
        </Row>
    }

    accionesGeocerca(){
        const { geocercas } = this.state
        if(geocercas.length < 1) return false

        return <div>
            <Button variant="success" size="sm" className='mb-3 shadow-sm'><b>GUARDAR CAMBIOS</b></Button>
        </div>
    }

    mostrarGeocercas(p){
        const { geocercas } = this.state
        
        return <div>
            <h6>{geocercas.length} Geocercas</h6>
            {this.accionesGeocerca()}
            {
                geocercas.map((geo,igeo) => {
                    return <div key={`geo-${igeo}`}>
                        <Row>
                        <Col md={6} className='mb-3'>
                            <label className='form-control-label'>Título</label>
                            <input name="titulo" className='form-control'/>
                        </Col >
                        <Col md={6} className='mb-3'>
                            <label className='form-control-label'>Tipo</label>
                            <select className="form-control" name="status" >
                                <option value=''>Seleccione</option>
                                <option value='disponible'>Zona disponibilidad</option>
                                <option value='restringido'>Zona restringida</option>
                            </select>
                        </Col >
                        </Row>
                        </div>
                })
            }
        </div>
    }

    onPolygonComplete(p){
        const { geocercas } = this.state
        geocercas.unshift(p)
        return this.setState({geocercas})
    }

    modalTelefono(){
        const { openModal } = this.state
        return <Modal show={openModal} size="lg"  onHide={() => this.handleClose()}
        centered
        >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
            <Row>
            <Col xs={12} className="p-1 text-center centrador">
                   <h5>Debes ingresar el telefono de la siquiente manera:</h5>
              </Col>
                <Col xs={12} className="p-4 text-center centrador">
                <h3><b>+56912345678</b></h3>            
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }


    formularioVehiculo(){
        const { nuevoconductor, creandoVehiculo, proveedores, loadingProveedores, defaultValue, validarTelefono, value } = this.state
        if(creandoVehiculo === true) return <Spinner animation='border' />
        if(Object.keys(nuevoconductor).length < 2) return this.sinResultados()


            const items = nuevoconductor.capabilities ? nuevoconductor.capabilities.map(e => e._id) : []
        let default_proveedor = false
        const i = proveedores.findIndex(p => p.value === nuevoconductor.id_proveedor)
        if(i > -1) default_proveedor = proveedores[i]
        return <div>
            <Row>
            <Col md={12}>
                <h5 style={{fontWeight:700}}><i className="fa-solid fa-id-card"></i> Datos del conductor</h5>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Conductor Nombres</label>
                <input name="nombres" className='form-control shadow-sm' value={nuevoconductor.nombres}  onChange={this.handleChangeVehiculo}/>
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Conductor Apellidos</label>
                <input name="apellidos" className='form-control shadow-sm' value={nuevoconductor.apellidos} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Conductor Rut</label>
                <input name="rut" className='form-control shadow-sm' value={nuevoconductor.rut} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mbd-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Contraseña APP</label>
                <input name="password" className='form-control shadow-sm' value={nuevoconductor.password} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={4} className='mb-4'>
                <label className='form-control-label' style={{fontWeight:700}}>Móvil Whatsapp</label>
                <input
                                                    placeholder="Ingresa el número"
                                                    className='form-control shadow-sm'
                                                    type="tel"
                                                    name="phone"
                                                    value={nuevoconductor.phone}
                                                    onChange={this.handleChangeVehiculo} />
                                            {/* { validarTelefono ? <FaCheckCircle style={{color: '#07bd31', fontSize: '30px'}} />: <BiSolidError style={{color: '#ae0b10', fontSize: '30px'}} /> } */}
            </Col>
            <Col xs={12}>
            <h5 style={{fontWeight:700}}><FaRegClock /> Horario de trabajo</h5>
            <Row>
                <Col md={2} className='mb-3'>
                    <label className='form-control-label'>Desde</label>
                    <input name="horario_desde" type="time" className='form-control shadow-sm' value={nuevoconductor.horario_desde} onChange={this.handleChangeVehiculo} />
                </Col >
                <Col md={2} className='mb-3'>
                    <label className='form-control-label'>Hasta</label>
                    <input name="horario_hasta" type="time" className='form-control shadow-sm' value={nuevoconductor.horario_hasta} onChange={this.handleChangeVehiculo} />
                </Col >
            </Row>
            </Col>
            

            <Col md={12}>
                <h5 style={{fontWeight:700}}><i className="fa-solid fa-car"></i> Datos del vehículo</h5>
                <label className="form-control-label d-block">Capacidades especiales que posee este vehículo</label>
                <TiposServicioSelectorPersonalizado full_payload={true} defaultValue={items} isMulti={true} condicion_default={{ tipo: "logistica", sub_tipo: "capacidad-especial" }} onChangeValue={(val) => {
                    const { nuevoconductor } = this.state
                    nuevoconductor.capabilities = val
                    this.setState({ nuevoconductor })
                }} />

            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Modelo Vehículo</label>
                <input name="modelo" className='form-control shadow-sm' value={nuevoconductor.modelo} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label'style={{fontWeight:700}}>Año Vehículo</label>
                <input name="ano" type="number" className='form-control shadow-sm' value={nuevoconductor.ano} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={2} className='mb-3'>
                <label className='form-control-label' style={{fontWeight:700}}>Patente Vehículo</label>
                <input name="patente" className='form-control shadow-sm' value={nuevoconductor.patente} onChange={this.handleChangeVehiculo} />
            </Col >
            <Col md={4} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Categoría</label>
                <Select
                                    isLoading={loadingProveedores} 
                                    loadingMessage={()=>'Cargando información'}
                                    placeholder="Selecciona una opción"
                                    noOptionsMessage={()=>'Sin opciones'}
                                    onChange={this.handleChangeProveedor}
                                    defaultValue={default_proveedor}
                                    options={proveedores}
                                    className='shadow-sm'
                                />
            </Col>
            
            <Col md={2} className='mb-4'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Estado</label>
                <select className="form-control shadow-sm" name="status" value={nuevoconductor.status} onChange={this.handleChangeVehiculo}>
                    <option value='active'>Activo</option>
                    <option value='inactive'>Inactivo</option>
                </select>
            </Col>

            <Col md={12}>
                <h5 style={{fontWeight:700}}><i className="fa-solid fa-gas-pump"></i> Rendimiento</h5>
            </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Combustible</label>
                <select className="form-control shadow-sm" name="status" value={nuevoconductor.status} onChange={this.handleChangeVehiculo}>
                    <option value=''>Seleccione</option>
                    <option value='gasolina'>Gasolina</option>
                    <option value='diesel'>Diesel</option>
                    <option value='electrico'>Eléctrico</option>
                    <option value='gas'>Gas</option>
                </select>
            </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Unidad de medida combustible</label>
                <select className="form-control shadow-sm" name="unidad_combustible" value={nuevoconductor.unidad_combustible} onChange={this.handleChangeVehiculo}>
                    <option value=''>Seleccione</option>
                    <option value='litro'>Litro</option>
                    <option value='porcentaje'>Porcentaje {"(Bateria)"}</option>
                    <option value='metros_cubicos'>Metros cúbicos</option>
                    <option value='gas'>Gas</option>
                </select>
            </Col>
            <Col md={3} className='mb-3'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Múltiplo por u.m. combustible</label>
                <input name="multiplo_um_combustible" className='form-control shadow-sm' type="number" value={nuevoconductor.multiplo_um_combustible} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={3} className='mb-4'>
                <label className='form-control-label d-block' style={{fontWeight:700}}>Kilómetros por u.m. combustible</label>
                <input name="peso" className='form-control shadow-sm' type="number" value={nuevoconductor.peso} onChange={this.handleChangeVehiculo} />
            </Col>
            {/* <Col md={12} className='mb-3'>
                <h3>Capacidad de carga / traslado por ruta</h3>
                <p>Esta capacidad está determinada para el modelo de planes delimitados por capacidad de carga, en vez de por ejemplo: por tiempo en ruta.</p>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Peso en Kg.</label>
                <input name="peso" className='form-control' type="number" value={nuevoconductor.peso} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Cantidad de puntos.</label>
                <input name="cap_puntos" className='form-control' type="number" value={nuevoconductor.cap_puntos} onChange={this.handleChangeVehiculo} />
            </Col>

            <Col md={12} className='mb-3'>
                <h3>Horario de trabajo</h3>
                <p>Este horario está determinado para que al crear planes, si seleccionas la opción de respetar horarios individuales de trabajo, en caso de tener; se limina la ruta.</p>
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Desde.</label>
                <input name="horario_desde" className='form-control' type="time" value={nuevoconductor.horario_desde} onChange={this.handleChangeVehiculo} />
            </Col>
            <Col md={2} className='mb-3'>
                <label className='form-control-label d-block'>Hasta.</label>
                <input name="horario_hasta" className='form-control' type="time" value={nuevoconductor.horario_hasta} onChange={this.handleChangeVehiculo} />
            </Col> */}

            </Row>
                <div className='d-flex justify-content-end align-items-center'>
                    <Button variant='danger' className='text-white shadow-sm mr-2' size='sm' onClick={() => this.solicitarEliminar()}><b>ELIMINAR</b></Button>
                    <Button className='shadow-sm' size="sm" variant='success' onClick={()=>this.editarVehiculo()}><b>GUARDAR CAMBIOS</b></Button>
                </div>            
            <hr />
            <h3 style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}>Campos personalizados</h3>
            <CamposPersonalizadosRelacionador id_target={nuevoconductor._id} condicion_default={{ module: "vehiculo" }}  />
            <hr />
            <Row>
                <Col md={8}><RastreoCargaVehiculo condicion_default={{ id_target: nuevoconductor._id }} id_target={nuevoconductor._id} /> </Col>
                <Col md={4}><EtiquetasRelaciones id_target={nuevoconductor._id} tipo_destino="vehiculo" tipo_origen="etiquetas"/></Col>
            </Row>
            
        </div>
    }

    botonSeleccionar(id){
        const { planes_activos } = this.state
        const i = planes_activos.findIndex(plan => plan._id === id)
        if(i > -1) return <Button size="sm" variant="outline-secondary" >PLAN ACTIVO</Button>

        return <Button size="sm" variant="outline-primary" ><Link to={`pay-plan-${id}`} >SELECCIONAR</Link></Button>
    }

    async confirmarEliminado(){
        const { user } = this.state
        const loading = 'creandoVehiculo'
        this.setState({ [loading]: false })
        return fetch(`${data.urlapi}/conductores?id=${this.state.id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${this.props.miusuario?.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return this.props.cerrarSesion()
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = `/${rutas.vehiculos.slug}`
                }, 1000);
            }
            return this.setState({ [loading]: false })
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return this.setState({ [loading]: false })
        })
    }

    solicitarEliminar() {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Estás a punto de eliminar este recurso, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => this.confirmarEliminado()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }
    
    render(){
        return(
            <>
            <div className="fluid text-primary">
                <Breadcrumb>
        <Breadcrumb.Item href="#"><Link to="/vehiculos">Vehículos</Link> </Breadcrumb.Item>
        <Breadcrumb.Item active>Editar</Breadcrumb.Item>
        </Breadcrumb>
        <Row>
            <Col md={6}><h4 style={{fontWeight:700}}><i className="fa-solid fa-pen-to-square"></i> Editar vehículo </h4></Col>
            {/* <Col md={6} className='text-right'><Button variant='ghost' className='text-danger' size='sm' onClick={() => this.solicitarEliminar()}>ELIMINAR</Button> </Col> */}
        </Row>
        <Card >
            <Card.Body>
            {this.formularioVehiculo()}
            </Card.Body>
        </Card>
        </div>
            {this.modalTelefono()}
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VehiculosEditar));