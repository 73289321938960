import React from 'react'

import MapaZonasAlertasMantenedor from '../Mapas/alertas/mapa_alertas'

const GeoAlertasMapa = (props) => {
  const {
    resize
  } = props
  
  return <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
        <MapaZonasAlertasMantenedor resize={resize} />
    </div>

}

export default GeoAlertasMapa