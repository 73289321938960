import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Spinner, Form, Badge, Accordion, ButtonGroup } from 'react-bootstrap'
import data, { configs3 } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import S3FileUpload from "react-s3/lib/ReactS3"
import { fechaATexto } from '../../../lib/helpers/helpers';
import { AiOutlineDrag } from 'react-icons/ai';
import { useDrag } from 'react-dnd'
import { BiPaste } from 'react-icons/bi';
import { homologarDatosEsquema } from '../../../lib/helpers/apisless';
import Header from '../../../components/Header';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../redux/actions/session';

const FuncionesCrear = (props) => {
    const [ usuarios, setUsuarios ] = useState([{ value:'all_access', label:'Todos los permisos'}])
    const [ conductores, setConductores ] = useState([])
    const [ loadingEsquema, setLoadingEsquema ] = useState(false)
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ creando, setCreando ] = useState(false)
    const [ esquema, setEsquema ] = useState(false)
    const [ funcion, setFuncion ] = useState({})
    const [ esquemaDestinoEjemplo, setEsquemaDestinoEjemplo ] = useState(false)
    const [ esquemaDestino, setEsquemaDestino ] = useState(false)
    const [ loadingUsuarios, setLoadingUsuarios ] = useState(true)
    const [ nuevaKey, setNuevaKey ] = useState({})
    const [ widgetSeleccionado, setWidgetSeleccionado ] = useState({})
    const [ openModal, setOpenModal ] = useState(false)
    const [ idActualizando, setIdActualizando ] = useState(false)
    const [ openModalKC, setOpenModalKC ] = useState(false)
    const [ keyCreada, setKeyCreada ] = useState(false)
    const [ jsonInputValue, setJSONInputValue  ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ campoSeleccionado, setCampoSeleccionado ] = useState(false)
    const [ campoIterable, setCampoIterable ] = useState(false)
    const [ pagina, setPagina ] = useState(1)
    const [ subiendoLogo, setSubiendoLogo ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const dispatch = useDispatch()

    const handleChangeImagen = (e) => {
  
        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
  
        setSubiendoLogo(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            return this.setState({ subiendofoto: false })
        }
  
        nuevaKey.logo = data.location
        setNuevaKey({...{}, ...nuevaKey})
        return setSubiendoLogo(false)
        })
        .catch(err => {
            setSubiendoLogo(false)
            return toast.error('No pudimos cargar tu imagen')
        })
      }

    const crearNuevaKey = async () => {
        const requeridos = [
            { value: "nombre", label: "Nombre" },
            { value: "titulo", label: "Título" },
            { value: "descripcion", label: "Descripción" },
            { value: "ayuda", label: "Texto de ayuda" },
            { value: "sin_datos_mensaje", label: "Mensaje cuando no hay datos" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevaKey[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setCreando(true)
        return fetch(`${data.urlapi}/integraciones/widget`,{
            method:'POST',
            body: JSON.stringify(nuevaKey),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            setCreando(false)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(res._id){
                setKeyCreada(res)
                conductores.unshift(res)
                setOpenModal(false)
                setConductores(prev => [...[], ...conductores])
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            setCreando(false)
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })

    }

    

    const procesarEsquema = async ()=>{
        if(!nuevaKey.esquema) return toast.error("Selecciona un esquema o insertalo en la sección de esquema personalizado")
        setLoadingEsquema(true)
        return fetch(`${data.urlapi}/integraciones/funciones/esquema`,{
            method:'POST',
            body: JSON.stringify({
                tipo: nuevaKey.esquema
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setEsquema(res)
            }
            return setLoadingEsquema(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEsquema(false)
        })
    }

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/integraciones/funciones/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerConductores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
        setOpenModalKC(false)
    }

    const procesarCampo = (campo) => {
        if(Array.isArray(campo) !== false) return "array"
        const tipo_de_campo = typeof campo
        return tipo_de_campo
    }

    const homologar = (ruta_campo, iterable) => {
        const ruta_campo_ = `*${ruta_campo}*`
        toast.info("Ahora selecciona el idioma inglé")
        setCampoSeleccionado(ruta_campo_)
        console.log({ iterable })
        setCampoIterable(iterable)
    }

    const mostrarEjemploPorValorFinal = (tipo_campo, value, llaves, iterable) => {

        const campo = llaves.join('.')

        if(value === null) return <p>NULO</p>
        if(tipo_campo === "array"){
            return false
        } else if( tipo_campo === "object"){
            return false
        } else if( tipo_campo === "boolean"){
            return <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip>
                ARRASTRA ESTE CAMPO Y ENLAZALO AL DESTINO
              </Tooltip>
            }
            ><p className='mb-0 hover' style={{ fontSize: 12 }} onClick={() => homologar(campo, iterable) } ><AiOutlineDrag /> Ejemplo <b style={{ color: campoSeleccionado === `*${campo}*` ? "blue" : "#adadad" }}>VERDADERO O FALSO</b></p></OverlayTrigger>
        } else {
            return <OverlayTrigger
            placement={'top'}
            overlay={
              <Tooltip>
                ARRASTRA ESTE CAMPO Y ENLAZALO AL DESTINO
              </Tooltip>
            }
            ><div>
            <p className='mb-0 hover' style={{ fontSize: 12 }} onClick={() =>  homologar(campo, iterable)} ><AiOutlineDrag /> Ejemplo <b style={{ color: campoSeleccionado === `*${campo}*` ? "blue" : "#adadad" }}>{value}</b></p>
            { iterable === true ? `ITERABLE ${JSON.stringify(value)} · ${campo}` : "NO ITERABLE" }
            </div></OverlayTrigger>
        }
    }

    const getValueFromNestedObjectTestArray = (obj, path) => {
        const keys = path.split('.'); // Dividir el string en partes usando el punto como separador
        let value = obj;
        let hay_array = false
        for (const key of keys) {
            if(Array.isArray(value[key]) !== false) hay_array = true
          if (value && value.hasOwnProperty(key)) {
            value = value[key]; // Avanzar al siguiente nivel del objeto
          } else {
            return hay_array; // Si la clave no existe en algún punto, devuelve undefined
          }
        }
        return hay_array
    }

    const getValueFromNestedObject = (obj, path) => {
        const keys = path.split('.'); // Dividir el string en partes usando el punto como separador
        let value = obj;
      
        for (const key of keys) {
            
          if (value && value.hasOwnProperty(key)) {
            value = value[key]; // Avanzar al siguiente nivel del objeto
          } else {
            return undefined; // Si la clave no existe en algún punto, devuelve undefined
          }
        }
      
        return value;
    }

    const setValueInNestedObject = (target_schema, path, newValue) => {
        const keys = path.split('.');
        let value = target_schema;
    
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            let tipo_campo = typeof value[key];
    
            if (!value.hasOwnProperty(key)) {
                if (Array.isArray(value)) {
                    // Si el objeto actual es un arreglo
                    tipo_campo = "array";
                    value.forEach(item => {
                        if (!item.hasOwnProperty(key)) {
                            item[key] = {}; // Crear un objeto vacío en cada elemento del arreglo
                        }
                        value = item[key]; // Avanzar al siguiente nivel del objeto
                    });
                } else if (typeof value[key] !== 'object') {
                    // Si el valor no es un objeto (ni arreglo ni objeto)
                    value[key] = {}; // Crear un objeto vacío si la clave no existe o no es un objeto
                } else {
                    value = value[key]; // Avanzar al siguiente nivel del objeto
                }
            } else {
                value = value[key]; // Avanzar al siguiente nivel del objeto
            }
        }
    
        // Actualizar el valor en el objeto/arreglo final
        const lastKey = keys[keys.length - 1];
        if (Array.isArray(value)) {
            value.forEach(item => {
                item[lastKey] = newValue;
            });
        } else {
            value[lastKey] = newValue;
        }
    
        return target_schema; // Retornar el objeto modificado
}

    const pegarValor = (ruta) => {
        /*
        try {
            const vall = getValueFromNestedObjectTestArray(esquema, campoSeleccionado.replace('{','').replace('}',''))
            return console.log({vall})
        } catch (error) {
            return console.log(error.message)
        }
        */

        const nuevo = setValueInNestedObject(esquemaDestino, ruta, campoSeleccionado)
        return setTimeout(() => {
            setEsquemaDestino(nuevo)
            const jsonnn = JSON.parse( JSON.stringify( nuevo ))
            console.log(nuevo, jsonnn)
            setCampoIterable(false)
            return setCampoSeleccionado(false)
        }, 300);
    }

    const handleChangeFieldChecked = (e) => {
        const value = e.target.checked
        const ruta = e.target.getAttribute('ruta')
        const nuevo = setValueInNestedObject(esquemaDestino, ruta, value)
        if(typeof esquemaDestino === "object"){
            return setEsquemaDestino({...{}, ...nuevo})
        } else if(Array.isArray(esquemaDestino) !== false){
            return setEsquemaDestino([...[], ...esquemaDestino])
        }
    }
    const handleChangeField = (e) => {
        const { value } = e.target
        const ruta = e.target.getAttribute('ruta')
        const nuevo = setValueInNestedObject(esquemaDestino, ruta, value)
        console.log({nuevo})
        if(typeof esquemaDestino === "object"){
            return setEsquemaDestino({...{}, ...nuevo})
        } else if(Array.isArray(esquemaDestino) !== false){
            return setEsquemaDestino([...[], ...esquemaDestino])
        }
    }

    const mostrarAccionDestino = (iterable, liberar) => {
        if(!campoSeleccionado) return false 
        if(campoIterable === true){
            if(!iterable) return false
            return liberar
        }
        return liberar
    }

    const mostrarEjemploPorValorFinalDestino = (tipo_campo, value, llaves, iterable) => {
        try {

            const campo = llaves.join('.')

            const valor_ejemplo = getValueFromNestedObject(esquemaDestinoEjemplo, campo)
            const valor_actual = getValueFromNestedObject(esquemaDestino, campo)
            const objetoPegar = <p className='d-inline hover' onClick={() => pegarValor(campo)}><BiPaste /> </p>
            const objetoPegarTitulo = <h6 className='mt-2 hover' onClick={() => pegarValor(campo)}>PEGA AQUÍ <BiPaste /></h6>

            if(iterable === true){
                // ES UN ARREGLO. DE STRING PROBABLEMENTE Y POR ESO LLEGÓ AQUI
                return <div>
                    <p className='mb-0 ' style={{ fontSize: 12 }} ><AiOutlineDrag /> Ejemplo <b>{value ? value.toString() : ""}</b> </p>
                    {campoIterable === true ? objetoPegarTitulo : false }
                    {valor_actual}
                </div>
            }

            if(value === null) return <p>NULO</p>

            if(tipo_campo === "array"){
                return typeof value
            } else if( tipo_campo === "object"){
                return typeof value
            } else if( tipo_campo === "boolean"){
                return <div>
                    <OverlayTrigger
                placement={'top'}
                overlay={
                <Tooltip>
                    ARRASTRA ESTE CAMPO Y ENLAZALO AL DESTINO
                </Tooltip>
                }
                ><p className='mb-0' style={{ fontSize: 12 }} ><AiOutlineDrag /> Ejemplo<b >VERDADERO o FALSO {iterable.toString()}</b></p></OverlayTrigger>
                <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="3">
                    <Form.Check type="switch" defaultChecked={valor_ejemplo === true ? true : false} ruta={campo} label="Verdadero o falso" onChange={handleChangeFieldChecked} />
                </Form.Group>

                </div>
                } else {

                    return <div>
                    <p className='mb-0 ' style={{ fontSize: 12 }} ><AiOutlineDrag /> Ejemplo <b>{valor_ejemplo ? valor_ejemplo.toString() : ""}</b> </p>
                    <input className='form-control' placeholder='Escribe un valor, puede ser sintaxis JSON' value={ valor_ejemplo === valor_actual ? '' : valor_actual  } ruta={campo} onChange={handleChangeField} />
                    { campoSeleccionado ?  
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                        <Tooltip>
                            Enlazar el campo {campoSeleccionado.split('.').pop().replace("*","")} del esquema origen 
                        </Tooltip>
                        }
                        ><div style={{ position: "absolute", right: 32, marginTop: "-32px" }} >
                            {mostrarAccionDestino(iterable, objetoPegar)}
                        </div></OverlayTrigger>
                    : false}
                    
                </div>        
            }
            
        } catch (error) {
            return <div>ERROR CRÍTICO AL PROCESAR EL ESQUEMA. Detalles: {error.message}</div>
        }
    }
    
    const procesarEsquemaVisualizacionDestino = (campo, key, llaves_anteriores, iterable) => {
        const tipo_campo = procesarCampo(campo)
        let llaves = llaves_anteriores
        if(campo === null){
            return <div>
            {mostrarEjemploPorValorFinalDestino(tipo_campo, campo, llaves, false)}
            <Card className='p-3 mt-1'><h6 style={{ color: "gray" }} className='mb-0'><AiOutlineDrag /> {llaves.join('.')}</h6></Card>
            </div>
        }
        if(typeof key !== "undefined" ) llaves.push(key)
        if( tipo_campo === "array" ){
            if(campo.length > 0){

                let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                
                return <div>
                    {procesarEsquemaVisualizacionDestino(campo[0], undefined, nuevas_llaves, true)}
                </div>
            }
            
        } else if( tipo_campo === "object"){
            return <div>
                {
                    Object.keys(campo).map((d,pos) => {
                        
                        let es_iterable = iterable === true ? true : false

                        let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                        const tipo_anidado = procesarCampo(campo[d])
                        return <div>
                             <Badge variant="light">{tipo_anidado}</Badge> {d}
                             <div className='ml-4'>
                            {procesarEsquemaVisualizacionDestino(campo[d], d, nuevas_llaves, es_iterable)}
                            </div>
                            { Object.keys(campo).length === (pos+1) ? false : <hr className='hr' /> }
                            </div>
                    })
                }
            </div>
        } else {
            
            return <div>
            {mostrarEjemploPorValorFinalDestino(tipo_campo, campo, llaves, iterable)}
            </div>
        }
    }

    const procesarEsquemaVisualizacion = (campo, key, llaves_anteriores, iterable) => {
        const tipo_campo = procesarCampo(campo)
        let llaves = llaves_anteriores
        if(typeof key !== "undefined" ) llaves.push(key)

        if(campo === null){
            return <div>
            {mostrarEjemploPorValorFinal(tipo_campo, campo, [], iterable)}
            </div>
        }
        
        if( tipo_campo === "array" ){

            if(campo.length > 0){

                let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                
                return <div>
                    {procesarEsquemaVisualizacion(campo[0], undefined, nuevas_llaves, true )}
                </div>
            }
        } else if( tipo_campo === "object"){

            
            return <div>
                {
                    Object.keys(campo).map((d,pos) => {

                        let es_iterable = iterable === true ? true : false
                        
                        let nuevas_llaves = JSON.parse( JSON.stringify(llaves_anteriores))
                        const tipo_anidado = procesarCampo(campo[d])
                        return <div>
                             <Badge variant="light">{tipo_anidado}</Badge> {d}
                             <div className='ml-4'>
                            {procesarEsquemaVisualizacion(campo[d], d, nuevas_llaves, es_iterable)}
                            { Object.keys(campo).length === (pos+1) ? false : <hr className='hr' /> }
                            </div>
                            </div>
                    })
                }
            </div>
        } else {
            
            return <div>
                {mostrarEjemploPorValorFinal(tipo_campo, campo, llaves, iterable)}
            </div>
        }
    }

    const mostrarEsquemaDestino = () => {
        if(!esquemaDestino) return false

        return <div>
            <Accordion defaultActiveKey={''} className="mb-3">
                    {
                        Object.keys(esquemaDestino).map((field,i) => {
                            let keys = [  ]
                            const eventKey = `${field}-${i+1}`
                            const tipo_campo = procesarCampo(esquemaDestino[field])
                            return <Card key={field} style={{ textTransform: "uppercase" }} >
                                <Accordion.Toggle as={Card.Header} style={{ padding: '8px 10px', backgroundColor:'white' }} className='hover' eventKey={eventKey}>
                                <Badge variant="light">{tipo_campo ? tipo_campo : 'DESCONOCIDO'}</Badge> {field}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={eventKey}>
                                <Card.Body>
                                    {procesarEsquemaVisualizacionDestino(esquemaDestino[field], field, keys)}
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        })
                    }
                    </Accordion>
        </div>
    }

    const mostrarMantenedorEsquema = () => {
        if(!esquema) return false
        if( typeof esquema !== "object" ) return false
        return <div>
                    <Accordion defaultActiveKey={''} className="mb-3">
                    {
                        Object.keys(esquema).map((field,i) => {
                            let keys = [  ]
                            const eventKey = `${field}-${i+1}`
                            const tipo_campo = procesarCampo(esquema[field])
                            return <Card key={field} style={{ textTransform: "uppercase" }}>
                                <Accordion.Toggle as={Card.Header} style={{ padding: '8px 10px', backgroundColor:'white' }} eventKey={eventKey}>
                                <Badge variant="light">{tipo_campo ? tipo_campo : 'DESCONOCIDO'}</Badge> {field}
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey={eventKey}>
                                <Card.Body>
                                    {procesarEsquemaVisualizacion(esquema[field], field, keys, false)}
                                </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        })
                    }
                    </Accordion>
        </div>
    }

    const handleChangeEsquemaDestino = (e) => {
        const { value } = e.target
        setJSONInputValue(value)
        try {
            if(!value) return false
            const texto = JSON.parse( value )
            const texto_ejemplo = JSON.parse( value )
            setEsquemaDestino({...{}, ...texto})
            setEsquemaDestinoEjemplo({...{}, ...texto_ejemplo})
        } catch (error) {
            toast.error(`Error en el cuerpo del payload ingresado: ${error.message}`)
        }

    }

    const probarFuncion = () => {
        console.log({ esquemaDestino })
        const nuevo_objeto = homologarDatosEsquema(esquema, esquemaDestino)
        return console.log(nuevo_objeto)
    }

    const handleChangeNuevaKey = (e) => {
        const { name, value } = e.target
        nuevaKey[name] = value
        return setNuevaKey(nuevaKey)
    }

    const onChangeUsuario = (e) => {
        if(!e.value) return
        nuevaKey.id_user = e.value
        return setNuevaKey(nuevaKey)
    }

    const handleChangeFieldFuncion = (e) => {
        const value = e.target.checked
        funcion.body_is_array = value
        return setFuncion({...{}, ...funcion})
    }

    const modalFiltro = () => {
        
        return <Modal show={openModal} size="xl" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            
        </Modal.Body>
        
      </Modal>
    }

    const copiarTexto = (id) => {
        toast.info("Copiado")
        return navigator.clipboard.writeText(id)
    }
    
    const modalKC = () => {


        const url = `https://ultimamilla.delpasystem.com/widget/${widgetSeleccionado._id}?timestamp=`
        const texto = `<div id="lastmile-delpa-widget-container"></div>
        <script type="text/javascript">
            const timestamp = new Date().toLocaleTimeString()
            const url = "${url}" + timestamp
            const delpa_id_iframe = document.getElementById("lastmile-delpa-widget-container")
            if(delpa_id_iframe){
                delpa_id_iframe.innerHTML = '<iframe name="lastmile-delpa-widget" id="lastmile-delpa-widget" frameBorder="0" width="100%" height="600px" src="' + url + '" />'
            } 
        </script>`
        
        return <Modal show={openModalKC} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Body>
            <h2 className='mb-0'>Este es el código de tu widget</h2>
            <p>Copia este código y pégalo en tu sitio web.</p>
            <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip>Has click para copiar</Tooltip>
          }
        >
            
            <div>
            <code >
            <p onClick={() => copiarTexto(texto)}>{texto}</p>
            </code>
            </div>
            </OverlayTrigger>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const confirmarEliminado = async (id) => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/integraciones/apikeys?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(res._id){
                toast.success('Eliminada exitosamente')
                return setTimeout(() => {
                    window.location = '/configuracion'
                }, 1000);
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const solicitarEliminarKey = (id) => {
            return confirmAlert({
                title: `¿Estás seguro?`,
                message: `Estás a punto de eliminar este registro, esta acción no se puede deshacer`,
                buttons: [
                  {
                    label: 'CONFIRMAR',
                    onClick: () => confirmarEliminado(id)
                  },
                  {
                    label: 'CANCELAR',
                    onClick: () => false
                  }
                ]
              })
    }

    const actualizarApiKey = async (objeto) => {
        return fetch(`${data.urlapi}/integraciones/widget`,{
            method:'PUT',
            body: JSON.stringify(objeto),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setIdActualizando(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setIdActualizando(false)
            } else if(res._id){
                toast.success("Actualizado exitosamente")
            }
            return setIdActualizando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setIdActualizando(false)
        })
    }

    const handleChangeSelect = (e,_id) => {
        setIdActualizando(_id)
        return actualizarApiKey({
            _id,
            id_user: e.value
        })
    }

    const cambiarStatus = ( _id, active ) => {
        setIdActualizando(_id)
        const i = conductores.findIndex(u => u._id === _id)
        conductores[i].active = active
        setConductores(prev => [...[], ...conductores])
        return actualizarApiKey({
            _id,
            active
        })
    }

    const verCodigo = (info) => {
        setWidgetSeleccionado(info)
        setOpenModalKC(true)
    }

    const tabla_registros = (registros) => {
        if(loadingConductores===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            {
                        registros.map(usuario => {

                            let item_default = false
                            const i_default = usuarios.findIndex(u => u.value === usuario.id_user)
                            if(i_default > -1 ){
                                item_default = { value: usuarios[i_default].value, label: usuarios[i_default].label }
                            }

                            return <Card key={`user-${usuario._id}`} className="p-3 mb-3">
                                <Row>
                                    <Col md={4}>
                                        <span style={{ fontSize: 12 }}>Creada: { fechaATexto(usuario.createdAt) }</span>
                                        <h4>{usuario.titulo}</h4>
                                        <p>{usuario.descripcion ? usuario.descripcion : "Sin descripción"}</p>
                                            { idActualizando === usuario._id ? <Spinner animation="border" /> : false }
                                    <h6 className='hover' style={{ marginTop: 14 }} onClick={() => cambiarStatus(usuario._id, usuario.active === true ? false : true )}>{ usuario.active === true ? 
                                    <i className="fas text-success fa-check-square"></i> :
                                    <i className="far fa-square"></i> } Activo</h6>

                                    <Button onClick={() => verCodigo(usuario)} size='sm' variant="outline-primary" >VER CÓDIGO</Button>
                                    </Col>
                                    <Col md={4}>
                                        
                                    
                                    </Col>
                                    <Col md={4} className="text-right">
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Eliminar</Tooltip>
                                            }
                                            >
                                        <Button variant="danger" onClick={() => solicitarEliminarKey(usuario._id)} ><i className="far fa-trash-alt"></i></Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                            </Card>
                        })
            }
        </div>
    }

    return <div className="fluid">
        <Row>
            <Col md={12}>
            <h4>Crear Función</h4>
            <Row>
                <Col xs={4} className="mb-3">
                    <label className='d-block form-control-label'>Nombre</label>
                    <input className='form-control' name="nombre" onChange={handleChangeNuevaKey} />
                </Col>
                <Col xs={8} className="mb-3">
                    <label className='d-block form-control-label'>Descripción</label>
                    <input className='form-control' name="descripcion" onChange={handleChangeNuevaKey} />
                </Col>
                        <Col md={12} className='mb-3'>
                        <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="3">
                            <Form.Check type="switch" defaultChecked={funcion.body_is_array === true ? true : false } label="Encapsular cuerpo en un arreglo" onChange={handleChangeFieldFuncion} />
                        </Form.Group>
                        </Col>
                <Col xs={6} className="mb-2">
                            <label className='d-block form-control-label'>Esquema de origen predefinido</label>
                    <Row>
                        <Col xs={7}>
                            <select className='form-control w-100' name="esquema" onChange={handleChangeNuevaKey}>
                                <option value="">Selecciona un esquema de origen predefinito</option>
                                <option value="estado">Estado de carga</option>
                                <option value="orden">Orden</option>
                            </select>
                        </Col>
                        <Col xs={5}>
                            { loadingEsquema ? <Spinner animation='border' /> : <Button className='w-100' onClick={() => procesarEsquema()} >PROCESAR ESQUEMA</Button>}
                        </Col>
                    </Row>
                </Col>
                <Col xs={6} className="mb-2">
                    <label className='d-block form-control-label'>Esquema Destino { esquemaDestino ? <Button className='p-0' variant="text" style={{ fontSize: 11, fontWeight: "bold", color: "#b7b7b7" }} onClick={() => setEsquemaDestino(false)}>REINICIAR</Button> : false }</label>
                    <input className='form-control' value={jsonInputValue} disabled={ !esquemaDestino ? false : true } placeholder='pega el esquema del destino' onChange={handleChangeEsquemaDestino} />
                </Col>
                <Col md={12}>
                    <Row>
                        <Col md={6}>
                            {mostrarMantenedorEsquema()}
                        </Col>
                        <Col md={6}>
                            {mostrarEsquemaDestino()}
                        </Col>
                    </Row>
                </Col>
                <Col md={12}>
                    <ButtonGroup>
                    <Button onClick={() => probarFuncion()} >PROBAR FUNCIÓN</Button>
                    {JSON.stringify(esquemaDestino)}
                    { creando === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={() => crearNuevaKey()}>CREAR</Button> }
                    </ButtonGroup>
                </Col>
            </Row>
            </Col>
        </Row>
      </div>

}

export default FuncionesCrear