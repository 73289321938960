import { useEffect, useRef, useState } from "react"
import { urlapi } from "../../../lib/backend/data"
import Select from 'react-select';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SelectorDestinatariosContactos = (props) => {
    const {
        extraOptions,
        rut_proveedor,
        codigo_proveedor,
        defaultValue
    } = props
    const opciones_default = extraOptions ? extraOptions : []
    const [ estados, setEstados ] = useState([])
    const [ estadosCarga, setEstadosCarga ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const isMulti = typeof props.isMulti !== "undefined" ? true : false
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const titulo = props.titulo ? props.titulo : 'Campo'
    const full_payload = typeof props.full_payload !== "undefined" ? true : false
    const selectRef = useRef(null)

    const handleChangeSelectEstado = (e) => {
        if(isMulti === true){
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            if(props.onChange) props.onChange(opciones)
            return setFiltroEstado(opciones)
        } else {
            let data = e
            if(full_payload === true){
                const i = estadosCarga.findIndex(es => es._id === e.value)
                if(i > -1) data = estadosCarga[i]
            }
            if(props.onChange) props.onChange(data)
            return e
        }
    }

    const obtenerEstados = () => {
        let url_consulta = `${urlapi}/destinatarios/contactos`
        if(rut_proveedor){
            url_consulta = `${url_consulta}?rut=${rut_proveedor}`
        } else if(codigo_proveedor){
            url_consulta = `${url_consulta}?codigo=${codigo_proveedor}`
        }
        return fetch(url_consulta,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){

            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data) !== false){
                setEstadosCarga(data)
                const items = [...opciones_default, ...data.map(e => ({ value: e._id, label: `${e.nombre} ${e.apellidos ? e.apellidos : ""}` }))]
                setEstados([...[], ...items])

                setTimeout(() => {
                    if(defaultValue){
                        if(selectRef.current){
                            if(Array.isArray(defaultValue)){
                                // const buscar_codigos = data[modulo].filter(e => defaultValue.includes(e._id)).map(e => e.codigo_estado)
                                // const valores = todas_los_estados.filter(e => buscar_codigos.includes(e.value))

                                // selectRef.current?.setValue(valores)
                            } else {
                                const pos = items.findIndex(e => e.value === defaultValue)
                                if(pos > -1){
                                    const value_search = items[pos]
                                    selectRef.current?.setValue(value_search)
                                }
                            }

                        }
                    }
                }, 100);

            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    useEffect(() => {
        obtenerEstados()
    }, [])

    return <div className="mb-3">
        <label className='d-block form-control-label'>{titulo}</label>
                    <Select 
                        ref={selectRef}
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectEstado}
                        isLoading={loadingEstados}
                        loadingMessage={() => "Cargando datos"}
                        isMulti={isMulti}
                        options={estados}
                        defaultValue={defaultValue ? defaultValue : null}
                        placeholder="Selecciona un contacto"
                        noOptionsMessage={()=>'Sin opciones'}
                        />
    </div>
}

export default SelectorDestinatariosContactos