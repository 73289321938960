import { Row, Col } from 'react-bootstrap'
import HelperModal from './helperModal'

const RegistrosVacios = (props) => {
    const {
        titulo,
        descripcion
    } = props
    return <div>
            <Row className="justify-content-md-center">
                <Col md={12} className="text-center">
                <hr/>
                <HelperModal tipo='facturacion' style='nada'/>
                <hr/>
                </Col>
            </Row>
        </div>
}

export default RegistrosVacios