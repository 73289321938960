import { Button, Card, Col, Modal, Row, Badge } from "react-bootstrap"
import { useEffect, useState } from "react"
import { maps_key, urlapi } from "../../lib/backend/data"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { procesarGeoDatosGeocode } from "../../lib/helpers/maps"
import { optiones_estricto_tiempo } from "./data"
import { MdOutlinePlace, MdPlace } from "react-icons/md"
import { estilo_moving_truck } from "../../lib/estilo_sitio"
import { useDispatch, useSelector } from "react-redux"
import Select from 'react-select'
import { cerrarSesion } from "../../redux/actions/session"
import { estilo_last_mile } from "../../lib/global/styles"
import TiposServicioSelectorPersonalizado from "../ordenes/tipos_servicio/selector_personalizado"

const DetallePunto = (props) => {
    const {
        data,
        origin
    } = props
    const [ show, setShow ] = useState(false)
    const [ punto, setPunto ] = useState(data)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const longitud_string = 40

    useEffect(() => {
        setPunto(data)
    }, [ data ])
    
    const actualizarPunto = async (pay) => {
        return fetch(`${urlapi}/viajes/punto`,{
            method:'POST',
            body: JSON.stringify(pay),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => res)
        .catch(error =>  error)
    }


    const showModal = () => {
        setShow(true);
    };

    const handleOk = () => {
        setShow(false);
    };

    const handleCancel = () => {
        setShow(false);
    };


    const handleChangeSelect = (val, key) => {
        return setPunto(prev => {
            let actual = {...prev}
            actual[key] = val
            if(props.onChange) props.onChange(actual)
            return actual
        })
    }
    
    const handleChangeSelectSpecial = (val, key) => {
        return setPunto(prev => {
            let actual = {...prev}
            actual[key] = val
            if(props.onChange) props.onChange(actual)
            return actual
        })
    }

    const procesarGeoParada = (place) => {
        if(!place) return false
        return setPunto(prev => {
            let actual = {...prev}
            const geo_data = procesarGeoDatosGeocode(place)

            actual.start_point      = {
                                        type: "Point",
                                        coordinates: [
                                            geo_data.lng, geo_data.lat
                                        ]
                                    }
            actual.location         = {
                                        latitude:geo_data.lat,
                                        longitude:geo_data.lng
                                    }
            actual.description      = place.formatted_address
            actual.direccion      = place.formatted_address
            actual.geo_data         = geo_data
            actual.details          = place
            // actualizarPunto(actual)
            if(props.onChange) props.onChange(actual)
            return actual
        })
                                
                            
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setPunto(prev => {
            let actual = {...prev}
                actual[name] = value
                if(props.onChange) props.onChange(actual)
                return actual
        })
    }

    const eliminarPunto = () => {
        if(props.onDelete) props.onDelete(punto._id)
        handleCancel()
    }

    let error = 0
    if(typeof punto !== "object") error++
    if(!punto.location){
        error++
    } else {
        const longitude = punto.location.longitude
        const latitude = punto.location.latitude
        if(typeof longitude !== "number") error++
        if(typeof latitude !== "number") error++
    }

    
    return <div>
        <Modal show={show} onHide={handleCancel} className="p-3" >
            <Modal.Body>
        {/* <Badge color="blue" className="mb-3">{punto.tipo === "entrega" ? "ENTREGA" : "RECOGIDA"}</Badge> */}
        { punto.nombre ? <h5 className="mt-0 hover mb-0" style={{ fontWeight: "bold" }}>{punto.nombre}</h5> : false }

        <Button size="sm" variant="outline-danger" className="mb-3" onClick={() => eliminarPunto()} >ELIMINAR</Button>
        <label className="form-control-label d-block"><MdPlace /> Dirección</label>
        <ReactGoogleAutocomplete
                    className="form-control"
                    defaultValue={punto.description}
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                    }}                    
                    apiKey={maps_key}
                    style={{ marginBottom: 20 }}
                    onPlaceSelected={(place) => procesarGeoParada(place)}
                    />
            {/* <Card size="small" className="p-3 mb-3">
                <p className="mb-0 mt-0" >Lugar de recogida</p>
                <p className="mb-0 mt-0" style={{ fontWeight: "bold" }} >{origin ? `${origin.titulo ? `${origin.titulo} ` : ''}${origin?.direccion}` : "No has definido el punto de partida de la ruta"}</p>
            </Card> */}
                <Row>
                <Col span={24}>
                        <h4 className="mt-0" ><b style={{ color: estilo_last_mile.color_primary }} >ENTREGA</b> Ventana horaria personalizada </h4>
                    <Row gutter={15}>
                        <Col md={4} className="mb-3">
                            <label className="form-control-label">Desde</label>
                            <input type="time" className="form-control" value={data.dropoff_earliest} name="dropoff_earliest"  onChange={handleChange} />
                        </Col>
                        <Col md={4} className="mb-3">
                        <label className="form-control-label">Hasta</label>
                                <input type="time" className="form-control" value={data.dropoff_latest} name="dropoff_latest" onChange={handleChange} />
                        
                        </Col>
                        <Col md={4} className="mb-3">
                            <label className="form-control-label">Tiempo (Min)</label>
                                <input type="number" className="form-control" name="duration_dropoff" defaultValue={punto.duration_dropoff} onChange={handleChange} />
                            
                        </Col>
                        <Col md={4} className="mb-3">
                            <label className="form-control-label">Peso de la carga</label>
                                <input type="number" className="form-control" name="peso" defaultValue={punto.peso} onChange={handleChange} />
                            
                        </Col>
                        <Col md={4} className="mb-3">
                            <label className="form-control-label">Cantidad de bultos</label>
                                <input type="number" className="form-control" name="bultos" defaultValue={punto.bultos} onChange={handleChange} />
                            
                        </Col>
                    </Row>
                </Col>
                
                </Row>
                    {/* <SelectorGeneral mode="multiple" module="categorias" value={data.capabilities ? data.capabilities.map(e => e._id) : []} titulo="Capacidades requeridas para atender este punto" condicion_default={{ tipo: "capacidades" }} onChange={(val) => handleChangeSelectSpecial(val, "capabilities")} /> */}
                    <label className="form-control-label d-block">Capacidades requeridas para gestionar este punto</label>
                    <TiposServicioSelectorPersonalizado defaultValue={punto.capabilities ? punto.capabilities.map(e => e._id) : []} isMulti={true} full_payload={true} condicion_default={{ tipo: "logistica", sub_tipo: "capacidad-especial" }} onChangeValue={(val) => handleChangeSelectSpecial(val, "capabilities")} />
                    {/* <p>Es estricto el tiempo de llegada en relación a la duración de la parada?"</p>
                    <Select defaultValue={punto.type} options={optiones_estricto_tiempo} onChange={(val) => handleChangeSelect(val,'type')} /> */}
                <Button size="sm" className="w-100 mt-3" variant="success" onClick={() => handleOk()}>GUARDAR CAMBIOS</Button>
      
                </Modal.Body>
                </Modal>
        <div id={`parada-${data._id}`} onClick={() => showModal()}>
        {/* <Badge style={{ marginTop: 5 }} color={punto.tipo === "entrega" ? "blue" : "orange"}> {punto.tipo === "entrega" ? "ENTREGA" : "RECOGIDA"}</Badge> */}
            { punto.nombre ? <h6 className="mt-0 hover mb-0" style={{ fontWeight: "bold" }}>{punto.nombre?.substring(0, longitud_string) }{punto.nombre?.length >= longitud_string ? "..." : ""}</h6> : false }
            <p className="mt-0 hover mb-0"><MdPlace /> {punto.description?.substring(0, longitud_string) }{punto.description?.length >= longitud_string ? "..." : ""}</p>
            { error > 0 ? <Badge style={{ marginTop: 5 }} color="red">COORDENADAS INVÁLIDAS</Badge> : false }
            <div className="d-block">
            { punto.capabilities ? punto.capabilities.map(item => <Badge variant="dark" className="mb-1">{item.valor}</Badge> ) : false }
            </div>
        </div>
    </div>
}

export default DetallePunto