import { useState } from "react"
import RangosListado from "./list"
import { Button, Col, Row, Spinner } from "react-bootstrap"
import SelectorServicios from "../../../servicios/selector"
import { toast } from "react-toastify"
import { urlapi } from "../../../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../../../redux/actions/session"
import FormularioRangosCliente from "./formulario_rangos"

const RangosCliente = (props) => {
    const {
        condicion_default,
        rut_cliente
    } = props
    const default_text = 'CREAR'
    const [ tipoVisualizacion, setTipoVisualizacion ] = useState('listado')
    const [ servicio, setServicio ] = useState(false)
    const [ tituloButton, setTituloButton ] = useState(default_text)
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const onChangeTipoServicio = (e) => {
        setServicio(e)
    }

    const obtenerRegistros = async ()=>{
        return fetch(`${urlapi}/pricing/rangos-cliente/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion: { id_tipo_servicio: servicio._id, propietario: rut_cliente },
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                if(res.length > 0){
                    const posicion_servicio = res.findIndex(se => se._id === servicio._id)
                    if(posicion_servicio > -1 ){
                        if(res[posicion_servicio].rangos.length > 0){
                            toast.warning(`Ya existen rangos para ${servicio.nombre}, para editarlos debes eliminarlos, se borrarán automáticamente todas las tarifas asociadas`)
                            return false
                        }
                    }
                    return true
                } else {
                    return true
                }
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    const activarCreacion = async () => {
        if(!servicio) return toast.error("Debes seleccionar un servicio")
        setLoading(true)
        setTituloButton("Verificando...")
        const permitir = await obtenerRegistros()
        setTituloButton(default_text)
        if(!permitir) return setLoading(false)
        setTipoVisualizacion('crear')
        return setLoading(false)
    }

    const showByTipoVisualizacion = () => {
        switch (tipoVisualizacion) {
            case "crear":
                return <div className="mt-2">
                    <FormularioRangosCliente servicio={servicio} rut_cliente={rut_cliente} />
                </div>
            case "listado":
                return <div className="mt-2">
                    <Row>
                        <Col md={6}>
                        <SelectorServicios rut_proveedor={rut_cliente} full_payload={true} onChange={(data => onChangeTipoServicio(data))} titulo="Tipo de servicio"  />
                        </Col>
                        <Col md={4}>
                            <label className="form-control-label d-block">Click para crear</label>
                            <Button disabled={loading} onClick={() => activarCreacion()} >{loading ? <Spinner style={{ width: 15, height: 15 }} animation="border" /> : false } {tituloButton}</Button>
                        </Col>
                    </Row>
                    <RangosListado rut_cliente={rut_cliente} condicion_default={condicion_default} />
                </div>
            default:
                return <RangosListado />
        }
    }

    return <div>
        {showByTipoVisualizacion()}
    </div>
}

export default RangosCliente