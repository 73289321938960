import { Card, Form, Tab, Tabs } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import ProveedoresTransporteListado from './listado'
import { useState } from 'react'
import HelperModal from '../general/helperModal'

const ProveedoresTransporte = (props) => {
  const session = useSelector(state => state.miusuario)
  const [ condicionDefault, setCondicionDefault ] = useState({ proveedor: true })
      
  const handleCheck = (e, name) => {
    const valor = e.target.checked
    return setCondicionDefault(prev => ({...prev, ...{} }))
    }

        return <div className="fluid">
                <Row>
                        <Col><h4 style={{fontWeight:700}} className='text-primary'>Proveedores de transporte</h4></Col>
                        <Col className='text-right'><HelperModal tipo="proveedores" style="modal" /></Col>
                </Row>
                    <Row>
                        <Col md={2}>
                                <h5>Filtrar</h5>


                                <hr className='hr' />
                                <h6>Servicios</h6>
                                <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="1">
                                        <Form.Check type="switch" label="Última milla" onChange={(e) => handleCheck(e,"grafico_vehiculo_proveedor")}  />
                                </Form.Group>
                                <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="2">
                                        <Form.Check type="switch" label="Logística inversa" onChange={(e) => handleCheck(e,"grafico_vehiculo_proveedor")}  />
                                </Form.Group>
                                <Form.Group className="mb-0" style={{ fontSize: 14 }} controlId="3">
                                        <Form.Check type="switch" label="Fulfillment" onChange={(e) => handleCheck(e,"grafico_vehiculo_proveedor")}  />
                                </Form.Group>
                        </Col>
                        <Col md={10}>
                        <Tabs defaultActiveKey="misproveedores">
                          <Tab eventKey="misproveedores" title="Mis proveedores" className='pt-4'>
                                  <ProveedoresTransporteListado condicion_default={condicionDefault}  />
                          </Tab>
                          <Tab eventKey="proveedores" title="Proveedores de transporte" className='pt-4'>
                                  <ProveedoresTransporteListado condicion_default={condicionDefault} excludeConected={true} />
                          </Tab>
                        </Tabs>
                        </Col>
                    </Row>
            </div>
}

export default ProveedoresTransporte