import 'moment/locale/es';
import { Accordion, Button, Card, Col, Container, Dropdown, Modal, Nav, Navbar, Row, Spinner } from 'react-bootstrap'
import { useEffect, useState } from "react";
import { fechaATexto } from "../../lib/helpers/helpers";
import SearchLocationInput from "../google_maps/searchLocationInput";
import { maps_key, urlapi } from "../../lib/backend/data";
import { toast } from "react-toastify";
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch } from 'react-redux';


const DetailFull = (props) => {
    let [ ruta, setRuta] = useState(props.ruta ? props.ruta : false)
    let [marcadores,setMarcadores] = useState([])
    const token = props.token ? props.token : false
    const [ modalParadas, setModalParadas ] = useState(false)
    const [ loadingOptimizacion, setLoadingOptimizacion] = useState(false)
    const [ paradasAgregar, setParadasAgregar] = useState([
      { direccion: "", destinatario: "" }
    ])
    const dispatch = useDispatch()

    useEffect(() => {
      if(ruta){
        if(ruta.puntos){
          if(Array.isArray(ruta.puntos) !== false){
            if(ruta.puntos.length > 0){
              setMarcadores(ruta.puntos)
            }
          }
        }
      }
    }, [])

    const [ loadingParadasAgregar, setLoadingParadasAgregar ] = useState(false)

    const abrirModalParadas = () => {
      setModalParadas(true)
    }

    const handleClose = () => {
      setModalParadas(false)
    }

    const agregarParada = () => {
      return setParadasAgregar(prev => [...prev, ...[{ direccion: "", destinatario: "" }]])
    }

    const handleChangeDireccionParada = (e) => {
      const { name, value } = e.target
      const pos = e.target.getAttribute("pos")
      if(!paradasAgregar[pos]) return 
      paradasAgregar[pos][name] = value
      return setParadasAgregar(prev => [...[], ...paradasAgregar])
    }

    const crearParada = async (parada) => {
      parada.id_plan = ruta._id
      return fetch(`${urlapi}/planes/paradas`,{
        method:'POST',
        body: JSON.stringify(parada),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => res)
    .catch(error => false)
    }

    const confirmarParadas = async () => {
        const pendientes = paradasAgregar.filter(pa => !pa.geo_data)
        if(pendientes.length > 0) return toast.error("Hay paradas sin datos coordenadas")
        setLoadingParadasAgregar(true)
        setModalParadas(false)

        let nuevas_paradas = []
        for( const parada of paradasAgregar ){
          const nueva_parada = await crearParada(parada)
          if(!nueva_parada) continue
          if(!nueva_parada._id) continue
          nuevas_paradas.push(nueva_parada)
        }
        setParadasAgregar(prev => [...[], ...[]])
        setMarcadores(prev => [...marcadores, ...nuevas_paradas])
        return setLoadingParadasAgregar(false)
    }

    const eliminarParada = (pos) => {
      const nuevos = paradasAgregar.splice(pos,1)
      return setParadasAgregar(prev => [...[], ...nuevos])
    }

    const mostrarModalParadas = () => {
      return <Modal show={modalParadas ? true : false } onHide={()=>handleClose()} centered 
        backdrop="static"
        size="xl"
        keyboard={false}
      >
          <Modal.Header closeButton>
            <Modal.Title>PARADAS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Button size="sm" variant="dark" className="mb-3 mr-3" onClick={() => agregarParada()}>AGREGAR PARADA</Button>
                <Button size="sm" variant="success" className="mb-3 mr-3" onClick={() => confirmarParadas()}>CONFIMRAR</Button>
              </Col>
              <Col md={6}>
                <h5 className="mb-3">Sube un archivo excel <Button size="sm" variant="outline-primary" className="mt-0">DESCARGAR MODELO</Button></h5>
                
                <input type="file" className="form-control" />
              </Col>
            </Row>            
            <hr/>
            
              {
                paradasAgregar.map((d,i) => {
                  return <Row key={`d-${i}`} className="mb-1">
                    <Col md={2}>
                      <input style={{ display: "inline-block"}} className="form-control" name="destinatario" placeholder="Destinatario" pos={i} onChange={handleChangeDireccionParada} />
                      <span style={{ display: "inline-block", fontSize: 12 }} onClick={() => eliminarParada(i)} className="text-danger hover" ><i className="fas fa-minus-circle"></i> ELIMINAR</span>
                    </Col>
                    <Col md={10}>
                    <SearchLocationInput
                      maps_key={maps_key}
                      onChange={(place) => {
                          if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          const geo_data = {
                              lat, 
                              lng,
                              direccion: place.formatted_address,
                              region,
                              comuna,
                              status: true
                          }
                          paradasAgregar[i].geo_data = geo_data
                          paradasAgregar[i].direccion = place.formatted_address
                          return setParadasAgregar(prev => [...[], ...paradasAgregar])
                      }} />  
                      {d.geo_data ? <i class="fas text-success fa-check-circle"></i> : <i class="far fa-clock"></i> }
                    </Col>
                    {
                      paradasAgregar.length === (i+1) ? false : <Col md={12}><hr /></Col>
                    }
                    
                  </Row>
                })
              }
              
          </Modal.Body>
        </Modal>
    }

    if(!ruta) return <div className='p-5'>
    <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
          <img alt="pedidos" src="images/pedidos.png" style={{ width: "50%" }} />
        <h4>Selecciona un plan para visualizarlo</h4>
        </Col>
    </Row>
    </div>

    const eliminarMarcador = (pos) => {
      marcadores.splice(pos,1)
      console.log({marcadores,pos})
      return setMarcadores(prev => [...[], ...marcadores])
    }

    const optimizarPlan = (id_plan) => {
      setLoadingOptimizacion(true)
      return fetch(`${urlapi}/planes/route/execute`,{
        method:'POST',
        body: JSON.stringify({
          id_plan
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
      if(!res){
        toast.error("Sin respuesta")
      } else if(res.errorMessage){
        toast.error(res.errorMessage)
      } else if(Array.isArray(res) !== false){
        if(res.length === marcadores.length){
          res.map((p,ip) => {
            const i = marcadores.findIndex(m => m._id === p.name)
              if(i > -1){
                marcadores[i].posicion = ip+1
              }
              return false
          })
          const ordenar = marcadores.sort((a,b) => a.posicion - b.posicion)
          setMarcadores(prev => [...[], ...ordenar])
        } else {
          toast.warn("longitudes de pedidos es diferente")
        }
      }
      setLoadingOptimizacion(false)
    })
    .catch(error => {
      setLoadingOptimizacion(false)
    })
    }

    return <div style={{ height: "90vh", overflowY: "scroll", overflowX: "hidden" }}>
      {mostrarModalParadas()}
        <Row>
            <Col md={4}>
              <Card bg="primary" className="p-3 mb-3 text-white">
                <p style={{ fontSize: 11 }} className="mb-0"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
                <Row>
                  <Col md={9}><h3 className="mt-2">Plan {ruta.id} </h3></Col>
                  <Col md={3} className="text-right">
                  <Dropdown>
                    <Dropdown.Toggle  id="dropdown-basic"></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item >Editar</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  </Col>
                </Row>
                <hr className="hr" />
                <Row>
                  <Col md={4}>
                    <h5 className="mb-0">0</h5>
                    <p style={{ fontSize: 12 }} className="mb-0">Sin asignar</p>
                  </Col>
                  <Col md={4}>
                    <h5 className="mb-0">0m</h5>
                    <p style={{ fontSize: 12 }} className="mb-0">Duración</p>
                  </Col>
                  <Col md={4}>
                    <h5 className="mb-0">0 Km.</h5>
                    <p style={{ fontSize: 12 }} className="mb-0">Distancia</p>
                  </Col>
                </Row>
                <hr className="hr" />
                { loadingOptimizacion ? <Spinner animation="border" /> : <Button variant="light" size="sm" className="mb-3" onClick={() => optimizarPlan(ruta._id)}>OPTIMIZAR PLAN</Button> }
                
                <Button variant="outline-light" size="sm" className="mb-3">CREAR RUTAS</Button>
              </Card>
              <Accordion defaultActiveKey="paradas">
                        <Card className="p-0">
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={`paradas`}>
                            <div className="ml-3 pb-0">
                            <h5 className="mb-0">Paradas </h5>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`paradas`}>
                            <Card.Body>
                              
                              { loadingParadasAgregar ? <Spinner animation="border" /> : <Button style={{ fontSize: 11 }} size="sm" variant="dark" className="mb-3" onClick={() => abrirModalParadas()}>AÑADIR PARADAS</Button> }
                              
                              {
                                marcadores.map((marker,im) => {
                                  return <Row key={`marker-${im}`}>
                                    <Col xs={12}>
                                      <p className="mb-0" style={{ fontWeight: 400 }}>{ marker.posicion ? <span className="text-primary">{marker.posicion}</span> : false } {marker.direccion}</p>
                                      <span style={{ display: "inline-block", fontSize: 12 }} onClick={() => eliminarMarcador(im)} className="text-danger hover" ><i className="fas fa-minus-circle"></i> ELIMINAR</span>
                                    </Col>
                                  </Row>
                                })
                              }
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card className="p-0">
                            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={`vehiculos`}>
                            <div className="pl-3 pb-0">
                            <h5 className="mb-0">Vehículos</h5>
                            </div>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={`vehiculos`}>
                            <Card.Body>
                                <Button style={{ fontSize: 11 }} size="sm" variant="dark">AÑADIR VEHÍCULOS</Button>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                </Accordion>

            </Col>
            <Col md={8}>

            <Navbar bg="light" expand="lg">
              <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    <Nav.Link href="#home">Visión General</Nav.Link>
                    <Nav.Link href="#link">Vehículos</Nav.Link>
                    <Nav.Link href="#link">Paradas</Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar> 
            
            </Col>
        </Row>
    </div>
}

export default DetailFull