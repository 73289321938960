import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { mapbox_token, urlapi } from '../../../../lib/backend/data';
import mapboxGlDraw from '@mapbox/mapbox-gl-draw';
import { obtenerCentroMapaPorPais } from '../../../../lib/helpers/data/internationa';
import { useDispatch, useSelector } from 'react-redux';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import * as turf from '@turf/turf';
import { calcularPuntoCentral, procesarCoordenadasParaCentroMapa } from '../../../../lib/helpers/maps/geocercas';
import { Button, ButtonGroup, Card, Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { cerrarSesion } from '../../../../redux/actions/session';
import SelectorZonaAvanzado from '../../selector_zona_avanzado';
import { BiDotsVerticalRounded, BiTargetLock } from 'react-icons/bi';
import { FiMapPin } from 'react-icons/fi';
import { BsFillTrashFill } from 'react-icons/bs';
import IngresoDirecciones from '../../../Zonas/direcciones/ingresa_direcciones';
import { recuperarCoordenadas } from '../../../../lib/helpers/geo';
import polyline from '@mapbox/polyline';

mapboxgl.accessToken = mapbox_token
const dropdownStyles = {
  '&::after': {
    display: 'none', // Esta regla oculta el indicador de flecha
  },
};

const MapaDibujoPoligono = (props) => {
  const {
    default_geojson,
    height,
    resize,
    geometries,
    redzone
  } = props
  const pais = useSelector(state => state.pais)
  const initialViewport = obtenerCentroMapaPorPais(pais)
  const [viewport, setViewport] = useState(initialViewport);
  const session = useSelector(state => state.miusuario)
  const initialViewState = obtenerCentroMapaPorPais(pais)
  const [geometrias, setGeometrias] = useState(geometries ? geometries : []);
  const [viewState, setViewState] = useState(initialViewState);
  const [ loadingGeocerca, setLoadingGeocerca ] = useState(false)
  const [geocercaSeleccionada, setGeocercaSeleccionada] = useState(false);
  const [ focusOnMap, setFocusOnMap ] = useState(false)
  const dispatch = useDispatch()
  const [ coleccion, setColeccion ] = useState( default_geojson ? default_geojson :{
    type: 'FeatureCollection',
    features: []
  })
    const mapRef = useRef(null);
    const drawRef = useRef(null);
    const controls = []
    const geocercas = useRef([])

    const handleGeocoderResult = (event) => {
      if (event.result && event.result.geometry && event.result.geometry.coordinates) {
        const [lng, lat] = event.result.geometry.coordinates;
        setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
      }
    };

    const ajustarMapaAGeocercas = (datos_procesar) => {

      let data = datos_procesar ? datos_procesar : coleccion.features
      const instancia_geocercas = JSON.parse( JSON.stringify( data ))
      const coordinates = instancia_geocercas.reduce((acc, geofence) => {
        const geofenceCoords = recuperarCoordenadas(geofence.geometry.coordinates)
        return [...acc, ...geofenceCoords];
      }, []);
      
      console.log({ coordenadas_finales: coordinates })
      if (coordinates.length > 0) {
        
          const points = coordinates.map(marker => turf.point([marker[0], marker[1]]));
          const collection = turf.featureCollection(points);
          const bounds = turf.bbox(collection);
          console.log({ bounds, collection })
          const newViewport = {
              ...viewState,
              latitude: (bounds[1] + bounds[3]) / 2,
              longitude: (bounds[0] + bounds[2]) / 2
          };

          const options = {
            padding: 30 // Ajusta el valor de padding según tus necesidades
          };

          setViewState(newViewport);
          mapRef.current?.fitBounds(bounds, options);
    }
    }
  
    const handleMapLoad = () => {

        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Busca una dirección",
            countries: pais
          });
          
          // Manejar el evento de resultado de búsqueda
          geocoder.on('result', handleGeocoderResult);
    
          let instancia_creacion = {
            displayControlsDefault: false,
            defaultMode: 'draw_polygon',
            controls: {
              polygon: true, // Habilitar dibujo de polígonos
              trash: true, // Habilitar la herramienta de borrado
            }
          }
          if(default_geojson) instancia_creacion.data = default_geojson
          const dibujo = new mapboxGlDraw(instancia_creacion);
    
          drawRef.current = dibujo; // Guardar la referencia del objeto draw en el ref
  
          
        if(!controls.includes('geocode')) mapRef.current?.addControl(geocoder, "top-right"); // Agregar el controlador de búsqueda en la esquina superior derecha
        controls.push('geocode')

        if(!controls.includes('draw')) mapRef.current?.addControl(dibujo, 'top-left'); // Agregar la herramienta de dibujo en la esquina superior izquierda
        controls.push('draw')

        // Asegurarse de que el mapa esté completamente cargado antes de acceder a drawRef.current
        mapRef.current?.on('draw.create', handleDraw); // Evento al crear un polígono
        mapRef.current?.on('draw.update', handleDraw); // Evento al actualizar un polígono
        mapRef.current?.on('draw.delete', handleDraw); // Evento al borrar un polígono
        mapRef.current?.resize();

    }

    const flattenArray = (arr) => {
      return arr.reduce((result, current) => {
        if (Array.isArray(current[0])) {
          result.push(...flattenArray(current));
        } else {
          result.push(current);
        }
        return result;
      }, []);
    };
    

    useEffect(() => {
      console.log({ resize })
      if(mapRef) if(mapRef.current) mapRef.current.resize()
      // ajustarMapaAGeocercas()
    }, [ resize ] )

    const onAgregarZona = (data) => {

      const buscar = geocercas.current.findIndex(geo => geo.id_geocerca === data._id)
        if(buscar > -1) return toast.error("Esta geocerca ya fue agregada")
          const nueva = {
              id_geocerca: data._id,
              titulo: data.titulo,
              type: "Feature",
              properties: data.properties,
              geometry: {
                type: data.region.type,
                coordinates: data.region.coordinates
              }
            }
            console.log(nueva)
            const nuevo_arreglo = [...geocercas.current, ...[nueva]]
            
            if (drawRef.current) {
              const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
              const geocercas_juntas = [...data.features, ...nuevo_arreglo]
              console.log({geocercas_juntas})
              const nueva_collection = {
                  features: geocercas_juntas
              }
              
              if(props.onChange) props.onChange(nueva_collection)
            }

              geocercas.current = nuevo_arreglo
              setColeccion((prevCollection) => ({
                ...prevCollection,
                features: nuevo_arreglo,
              }))
        }

    const handleDraw = () => {
      if (drawRef.current) {
        const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
        if(props.onChange) props.onChange(data)
      }
    };

    const centrarMapa = (centro, max_zoom) => {
      if(!centro) return false
      if(!centro.longitude || !centro.latitude) return toast.info("Geocerca multi polígono está en desarrollo")
      if(isNaN(centro.longitude) || isNaN(centro.latitude)) return toast.info("Geocerca multi polígono está en desarrollo")
      const nuevo_zoom = initialViewState.zoom + (max_zoom ? max_zoom : 0)
      const nuevo_centro = { center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000}
      mapRef.current?.flyTo(nuevo_centro);
      setTimeout(() => {
        setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
      }, 2010);
    }

    const resetMapView = () => {
      if(!focusOnMap) return false
      return <Button size="small" onClick={() => {
        setFocusOnMap(false)
        ajustarMapaAGeocercas()
      }}>VOLVER A MAPA COMPLETO</Button>
    }

    const handleChangeGeocerca = (e, id) => {
      const { value } = e.target
      const pos = e.target.getAttribute('pos')
      geocercas.current[pos].titulo = value

      const nueva_collection = {
        features: geocercas.current
    }
    
    if(props.onChange) props.onChange(nueva_collection)      
    }

    const eliminarGeocerca = (i) => {
      const instancia_geocerca = JSON.parse( JSON.stringify(geocercas.current))
      instancia_geocerca.splice(i,1)
      const nueva_collection = {
        features: instancia_geocerca
      }


      const instancia_coleccion = JSON.parse( JSON.stringify( coleccion ))
      instancia_coleccion.features = [...[], ...instancia_geocerca]
      geocercas.current = instancia_geocerca
      setColeccion(prev => ({...{}, ...instancia_coleccion }))
      if(props.onChange) props.onChange(nueva_collection) 
    }

    const mostrarGeocercas = () => {
      const iconoTarget = <BiTargetLock />
      if(geocercas.current.length < 1 ) return <div className='mb-3 mt-3'><h6 className='mb-0 mt-0' level={4}>Sin puntos de control cargados {iconoTarget}</h6></div>

      return <div>
        <div className='mb-3 mt-3'><h6 className='mb-0 mt-0' level={4}>{geocercas.current.length} puntos de control cargados {iconoTarget}</h6></div>
        {accionesGeocercas()}
        {resetMapView()}
          {
            geocercas.current.map((geo,pos) => {

              const coords = procesarCoordenadasParaCentroMapa(geo.geometry.coordinates)
              if(coords.length < 1) return false
              const centro = calcularPuntoCentral(coords)
              
              return <div key={geo._id} className='mb-2'>
              <Card className='p-2'>
              <Row className='align-items-center'>
                <Col md={10}>
                  <span className='d-block' style={{ fontSize:10, color: "gray" }}>Nombre</span>
                  <input style={{ border: "none", width: "100%" }} mb={3} placeholder="Nombre" name="titulo" pos={pos} value={ geo.titulo ? geo.titulo : `GEO ${pos+1}` } onChange={(e) => handleChangeGeocerca(e,geo._id)} /></Col>
                <Col md={2} className='p-0'>
                <Dropdown style={dropdownStyles}>
                <Dropdown.Toggle variant="white" style={{ padding:0, lineHeight: 0 }} >{ geo.deleting ? <Spinner animation='border' size="sm" /> : <BiDotsVerticalRounded size={25} />}</Dropdown.Toggle>
                <Dropdown.Menu style={{ fontSize: 14, fontWeight: 400, color: '#1B3665' }}>
                    <Dropdown.Item eventKey="2" onClick={() => centrarMapa(centro)}><FiMapPin /> VER EN MAPA</Dropdown.Item>
                    <Dropdown.Item onClick={() => eliminarGeocerca(geo._id)} disabled={geo.deleting === true ? true : false} eventKey="1"><BsFillTrashFill /> {geo.deleting === true ? "ESPERA UN MOMENTO..." : "ELIMINAR"}</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
                </Col>
              </Row>
                </Card>
                </div>
            })
          }
      </div>
    }

    const fetchZone = async () => {
      if(!geocercaSeleccionada) return false
      setLoadingGeocerca(true)
      const url = `${urlapi}/geodata/zonas/geocerca?id=${geocercaSeleccionada._id}`
      return fetch(url, {
        method: "GET",
        headers: {
            'Content-type': "application/json",
            'Authorization': `Bearer: ${session.tokenSession}`,
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(async res => {
        if(!res){
            toast.error("Sin datos obtenidos")
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
        } else if(res._id){
            onAgregarZona(res)
        }
        return setLoadingGeocerca(false)
    })
    .catch(error => {
        toast.error("No se pudo efectuar la operación")
        return setLoadingGeocerca(false)
    })
    }

    const onSelectRoute = (data) => {
      const { coordenadas, route, puntos } = data
      ajustarMapaAGeocercas(coordenadas)

      const nuevo_arreglo = [...geocercas.current, ...coordenadas]
              
              if (drawRef.current) {
                const data = drawRef.current.getAll(); // Obtener todas las geometrías dibujadas
                const geocercas_juntas = [...data.features, ...nuevo_arreglo]
                console.log({geocercas_juntas})
                const nueva_collection = {
                    features: geocercas_juntas
                }
                
                if(props.onChange) props.onChange(nueva_collection)
              }
  
                geocercas.current = nuevo_arreglo
                setColeccion((prevCollection) => ({
                  ...prevCollection,
                  features: nuevo_arreglo,
                }))
        
    }
    
    const eliminarTodasLasGeocercas = () => {
      geocercas.current = []
      return setColeccion((prevCollection) => ({
        ...prevCollection,
        features: [],
      }))
    }

    const accionesGeocercas = () => {
      if(!geocercas) return false
      if(!geocercas.current) return false

      return <div>
        <Button size="sm" variant='light' className='w-100 mb-3' onClick={() => eliminarTodasLasGeocercas()} ><BsFillTrashFill /> LIMPIAR GEOCERCAS</Button>
      </div>
    }
  
    return (<div style={{ height: '100vh', width: '100%' }}>
        <Map
              ref={mapRef}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              style={{width: "100%", height: height ? height : 500}}
              mapStyle="mapbox://styles/mapbox/streets-v9"
              mapboxAccessToken={mapbox_token}
              onLoad={handleMapLoad}
            >
              {
                  geometrias.map((geometry,ir) => {

                      const decodedCoords = polyline.decode(geometry.geometry)
                      
                      return <Source
                          id={`route-${ir}`}
                          type='geojson'
                          data={{
                              type: 'Feature',
                              properties: {},
                              geometry: {
                                  type: "LineString",
                                  coordinates: decodedCoords.map(co => [co[1], co[0]])
                              }
                            }}
                      >
                          <Layer
                          id={`route-layer-${ir}`}
                          type="line"
                          layout={{
                              'line-join': 'round',
                              'line-cap': 'round',
                            }}                
                          paint={{
                              'line-color': 'black',
                              'line-width': 4,
                              'line-opacity': 0.75
                          }}
                      />
                      </Source>
                  })
              }
                  <Source
                          key={"ajsfkn"}
                          id={"geocerca._id"}
                          type="geojson"
                          data={coleccion}
                          >
                              
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                              }
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="fill"
                                paint={{
                                    'fill-color': 'black',
                                    'fill-opacity': 0.5
                                  }}                      
                                />
                              }
                                  {
                                    redzone === true ? <Layer
                                    id="geocercas-layer"
                                    type="fill"
                                    paint={{
                                        'fill-color': 'red',
                                        'fill-opacity': 0.2
                                      }}                      
                                    /> : false
                                  }
                          </Source>
            </Map>
      </div>);
  };
  

export default MapaDibujoPoligono