import { useState } from 'react';
import { Row, Col, Button, Modal, Card } from 'react-bootstrap';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';
import { FaChevronDown, FaChevronUp, FaCode } from 'react-icons/fa';

const FunctionAdder = () => {
  const [functionsList, setFunctionsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentFunctionIndex, setCurrentFunctionIndex] = useState(null);
  const [ show, setShow ] = useState(false);
  const [customFunction, setCustomFunction] = useState('');

  const addFunction = () => {
    setFunctionsList([...functionsList, { type: "", code: "" }]);
  };

  const removeFunction = (index) => {
    setFunctionsList(functionsList.filter((_, i) => i !== index));
  };

  const handleFunctionChange = (index, value) => {
    if (value === 'custom') {
      setCurrentFunctionIndex(index);
      setCustomFunction('const customFunction = (val) => val;'); // Función inicial
      setShowModal(true);
    } else {
      setFunctionsList((prev) => 
        prev.map((func, i) => i === index ? { ...func, type: value, code: "" } : func)
      );
    }
  };

  const handleSaveCustomFunction = () => {
    setFunctionsList((prev) => 
      prev.map((func, i) => 
        i === currentFunctionIndex ? { ...func, type: 'custom', code: customFunction } : func
      )
    );
    setShowModal(false);
    setCustomFunction('');
  };

  const handleEditCustomFunction = (index) => {
    setCurrentFunctionIndex(index);
    setCustomFunction(functionsList[index].code);
    setShowModal(true);
  };

  const mostrarFormulario = () => {
    if(!show) return null
    return (
        <>
          <Row className="mb-3">
            <Col>
              <Button variant="primary" onClick={addFunction}>AGREGAR FUNCIÓN</Button>
            </Col>
          </Row>
          {functionsList.length > 0 && (
            functionsList.map((func, index) => (
              <Card className='shadow p-3 mb-3'>
                <h6><FaCode /> <b>FUNCIÓN</b></h6>
                <Row key={index} className="mb-3 align-items-center">
                <Col xs={8}>
                <label className='form-control-label d-block'>Selecciona el tipo de función</label>
                  <select
                    className='form-control'
                    value={func.type}
                    onChange={(e) => handleFunctionChange(index, e.target.value)}
                  >
                    <option value="">Selecciona una función</option>
                    <option value="toString">Convertir a String</option>
                    <option value="toInteger">Convertir a Entero</option>
                    <option value="toDecimal">Convertir a Decimal</option>
                    <option value="trimSpaces">Eliminar Espacios Vacíos</option>
                    <option value="toLowerCase">Convertir a Minúsculas</option>
                    <option value="toUpperCase">Convertir a Mayúsculas</option>
                    <option value="capitalizeFirst">Capitalizar Primera Letra</option>
                    <option value="replaceSpecialCharacters">Reemplazar Caracteres Especiales</option>
                    <option value="custom">ESCRIBIR FUNCIÓN</option>
                  </select>
                </Col>
                <Col xs={4} className="text-end">
                <label className='form-control-label d-block'>Click para remover</label>
    
                  <Button
                    variant="danger"
                    className='w-100'
                    onClick={() => removeFunction(index)}
                  >
                    REMOVER
                  </Button>
                </Col>
                {func.type === 'custom' && (
                    <Col xs={12} className='text-left mt-1'>
                        <Button
                        variant="link"
                        size="sm"
                        className="w-100"
                        onClick={() => handleEditCustomFunction(index)}
                        >
                        Editar Función
                        </Button>
                    </Col>
                  )}
              </Row>
              </Card>
            ))
          )}
    
          {/* Modal para escribir la función personalizada */}
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Escribir Función Personalizada</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Editor
                value={customFunction}
                onValueChange={(code) => setCustomFunction(code)}
                highlight={(code) => highlight(code, languages.js, 'javascript')}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 14,
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancelar
              </Button>
              <Button variant="primary" onClick={handleSaveCustomFunction}>
                Guardar Función
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      );

}
  return <div>
          <h6 className='mt-3 hover' onClick={() => setShow(!show)}>{ show ? <FaChevronUp /> : <FaChevronDown /> } <b>{ show ? "OCULTAR FUNCIONES" : "VER FUNCIONES" }</b></h6>
          {mostrarFormulario()}

  </div>
};

export default FunctionAdder;
