import { Spinner } from "react-bootstrap";

const CargandoGeneral = () => {

    return <div>
        <div>
            <Spinner animation="border" />
            <h5 className='mb-0'>Espere un momento...</h5>
        </div>
    </div>
}

export default CargandoGeneral;