import { Button, Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import ListadoAlertas from "../../GeoAlertas/listado"

const ModalAlertasZona = (props) => {
    const {
        show,
        condicion_default
    } = props
    const [ showModal, setShowModal ] = useState(false)

    const handleCancel = () => {
        setShowModal(false)
    }

    return <div>
        <Button size="small" style={{ width: "100%" }}  onClick={() => setShowModal(true)}>ALERTAS</Button>
        <Modal width={"80%"} show={showModal} onHide={() => handleCancel()}>
            <ListadoAlertas condicion_default={condicion_default} />
      </Modal>
    </div>
}

export default ModalAlertasZona