import { useEffect, useState } from "react"
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import data from '../../lib/backend/data'
import S3FileUpload from 'react-s3';
import { formatDate } from "../../lib/helpers/dates";
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";

const EmitirEstadoModal = (props) => {
    const {
        propietario
    } = props
    const [ openModal, setOpenModal ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ estados, setEstados ] = useState([])
    const [ subEstados, setSubEstados ] = useState([])
    const [ emitirestado, setEmitirEstado ] = useState(false)
    const [ subiendofoto, setSubiendofoto] = useState(false)
    const [ actualizando, setActualizando] = useState(false)
    const [ emitirsubestado, setSubEstado ] = useState(false)
    const token = props.token ? props.token : false
    const pedido = props.pedido ? props.pedido : false
    const configs3 = {
        dirName: "movil/",
        bucketName: "urrapp",
        region: "us-east-1",
        accessKeyId: 'AKIAW3FR56LCTXHZCW5T',
        secretAccessKey: '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',
    }
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerEsquemaEstados()
    },[])

    const estadoEmitido = (data) => {
        if(props.onStatusEmited) props.onStatusEmited(data)
        return setOpenModal(false)
    }

    const obtenerEsquemaEstados = async () => {
        let url = `${data.urlapi}/ordenes/config-estados`
        if(propietario) url = `${url}?rut=${propietario}`
        return fetch(url,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setEstados(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            setLoading(false)
            return toast.error(error.message)
        })
    }

    if(!pedido) return false

    const openModalEstado = () => {
        setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const cambiarStatusDespacho = (emitirestado) => {
        return setEmitirEstado(emitirestado)
    }

    const handleChangeImagen = (e) => {
        const i = e.target.getAttribute('posicion')
        if(e.target.files.length < 1) return toast.error('Sin archivos encontrados')
        const archivo = e.target.files[0]
  
        setSubiendofoto(true)
        return S3FileUpload.uploadFile(archivo, configs3)
        .then(data => {
        if(!data.location) {
            toast.error('No pudimos cargar tu imagen')
            setSubiendofoto(false)
        }
        emitirsubestado.acciones[i].valores.push(({
          tipo: emitirsubestado.acciones[i].titulo,
          url: data.location,
          fechacreacion: formatDate(new Date())
      }))
        setSubEstado(emitirsubestado)
        return setSubiendofoto(false)
        })
        .catch(err => {
            setSubiendofoto(false)
            return toast.error('No pudimos cargar tu imagen')
        })
    }

    const onChangeAccionText = (e) => {
        const { value } = e.target
        const i = e.target.getAttribute('posicion');
        emitirsubestado.acciones[i].valor = value
        return setSubEstado(emitirsubestado)
    }

    const showComponentByAccion = (accion,i) => {

        if(accion.tipo_accion === 'campo-texto'){

            return <div className='mb-2 text-primary'>
                    <label className="form-control-label d-block">{accion.titulo} {accion.required === true ? '* Obligatorio' : false} </label>
                    <input className='form-control shadow-sm' posicion={i} onChange={onChangeAccionText}  />
                </div>

        } else if(accion.tipo_accion === 'carga-imagenes'){

            return <div className='mb-2 text-primary'>
                    <label className="form-control-label d-block">{accion.titulo} {accion.required === true ? '* Obligatorio' : false}</label>
                    <input  
                    type="file" 
                    name="file" 
                    id="file" 
                    className="form-control shadow-sm"
                    posicion={i}
                    onChange={handleChangeImagen} 
                    placeholder="Archivo de imagen" 
                    />
                    {
                            accion.valores.map((foto,i) => {
                                return <img className='mb-3 mr-3' key={foto.url} style={{ height: 70,}} src={foto.url} />
                            })
                    }
                </div>
        } else if(accion.tipo_accion === 'selector'){
            const items = accion.opciones.map(val => ({ label: val, value: val }))
            return <div className='mb-2'>
                    <label className="form-control-label d-block text-primary">{accion.titulo} {accion.required === true ? '* Obligatorio' : false}</label>
                    <select className="form-control shadow-sm" posicion={i} onChange={onChangeAccionText}>
                      <option value="">Seleccione</option>
                      {items.map((item,i) => <option key={`item-${i}`} value={item.val}>{item.label}</option> )}
                    </select>
                    </div>
        }
        return false

    }

    const confirmarEstado = async () => {
        // VERIFICAR CAMPOS DE FORMULARIO
        const verificar_formulario_completo = emitirsubestado.acciones.map(accion => {
            if(accion.tipo_accion === 'campo-texto' || accion.tipo_accion === 'selector' ){
                if(accion.required === true){
                    return accion.valor ? false : accion.titulo
                } else {
                    return false
                }
            } else {
                // IMAGENES
                if(accion.required === true){
                    return accion.valores.length > 0 ? false : accion.titulo
                } else {
                    return false
                }
            }
            return false
        })
  
        const campos_faltantes = verificar_formulario_completo.filter(camp => camp !== false ? camp : false)
        if(campos_faltantes.length > 0) return toast.error(`Los siguientes campos son obligatorios: ${campos_faltantes.join(', ')}`)
  
        let lng = '-33.458531'
        let lat = '-70.653753'
  
        const payload = {
            idpedido: pedido._id,
            lat, 
            lng, 
            idestado: emitirestado._id, 
            estado: emitirestado.titulo, 
            codigo_estado: emitirestado.codigo_estado,
            canal: 'sistema-web',
            tipo_creador: 'usuario-sistema',
            id_creador: '', 
            notificar_integraciones: emitirsubestado.notificar_integraciones ? emitirsubestado.notificar_integraciones : [],
            id_subestado: emitirsubestado._id, 
            subestado: emitirsubestado.titulo,
            codigo_subestado: emitirsubestado.codigo_estado,
            estado_color: emitirestado.color,
            publico: emitirsubestado.publico === true ? true : false,
            notificar_planel_flota: emitirsubestado.notificar_planel_flota === true ? true : false,
            finalizar: emitirsubestado.finalizar === true ? true : false,
            subestado_color: emitirsubestado.color,
            metadatos: emitirsubestado.acciones,
            whatsapp: emitirsubestado.whatsapp,
            email: emitirsubestado.email
            }
        setActualizando(true)
        return fetch(`${data.urlapi}/flota/emitir-estado`, {
              method: 'POST',
              body: JSON.stringify(payload),
              headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
                }
            })
            .then(datos => datos.json())
            .then(pros => {
                console.log(pros)
                if(!pros){
                    toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                    return setActualizando(false)
                } else if(pros.errorMessage){
                    toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                    return setActualizando(false)
                } else if(pros._id){
                    toast.success(`Operación Exitosa, Hemos confirmado la información exitosamente`)
                    estadoEmitido(pros)
                }
                setEmitirEstado(false)
                setSubEstado(false)
                return setActualizando(false)
            })
            .catch(error => {
                toast.error('Error inesperado','Lo sentimos, no es posible cargar fotos en este momento, intenta de nuevo entrar aquí')
                return setActualizando(false)
            })
    }
    
    const mostrarSubEstados = (subestados) => {
        if(subestados.length < 1) return <h6 className='text-danger' style={{fontWeight:700}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> No hay sub estados disponibles para web</h6>

        if(!emitirsubestado) return subestados.map((subestado,i) => {
            return <Button key={`sub-${i}`} style={{ border: 'none', backgroundColor: subestado.color, color:'white' }} className="mr-2"  onClick={() => setSubEstado(subestado)} >{subestado.titulo}</Button>
        })
        
        return <div>
            { emitirsubestado.acciones.map((accion,i) => {
                    return <div key={`accion-${i}`}>
                        {showComponentByAccion(accion,i)}
                    </div>
                }) }
            { actualizando === true || subiendofoto === true ? <Spinner animation="border" /> : <div className="d-flex justify-content-end">
                <Button onClick={() => confirmarEstado()} className='shadow-sm' variant="primary" size="sm" ><b>CONFIRMAR ESTADO</b></Button></div> }
        </div>
    }

    const subFormularioEstado = () => {
        if(!emitirestado) return <div >
            <h6 className="text-primary" style={{fontWeight:700}}>Selecciona un estado:</h6>
          { estados.map((estado,i) => <Button onClick={() => cambiarStatusDespacho(estado)} style={{ border: 'none', backgroundColor: estado.color, color:'white' }} className="mr-2" key={`boton-estado-${estado._id}`}>{estado.titulo}</Button>) }
        </div>

        if(emitirestado) return <div>
          <Button size="sm" variant="secondary" onClick={()=>{
              setEmitirEstado(false)
              return setSubEstado(false)
          }}><b><i className="fa-solid fa-angles-left"></i> Atrás</b></Button>
          { emitirsubestado ? <div className="mt-3">
            <Card className="p-2 mb-3 text-white" bg="secondary" >
            <div>
            <p className="mb-0">Estás a punto de emitir el siguiente subestado:</p>
            <h4 style={{fontWeight:700}}><i className="fas fa-circle" style={{ color: emitirsubestado.color }}></i> {emitirsubestado.titulo}</h4>
            </div>
            </Card>
            <p className="text-primary" style={{fontWeight:700}}><b><i className="far fa-check-circle"></i> Completa las siguientes tareas</b></p>
        </div> : <h6 className="mt-3 text-primary" style={{fontWeight:700}}>Selecciona una opción:</h6> }
          {mostrarSubEstados(emitirestado.subestados)}
        </div>

      }

    const mostrarFormulario = () => {
        if(loading === true) return <div>
            <Spinner animation="border" />
            <h6>Cargando información...</h6>
        </div>

        return <div>
            {subFormularioEstado()}
        </div>
    }

    return <div>
        <Button size="sm" variant="primary" className="shadow-sm mb-3" style={{ fontSize: 11 }} onClick={()=>openModalEstado()} ><b>EMITIR NUEVO ESTADO</b></Button>
        <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary" style={{fontWeight:700}}>Emitir nuevo estado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {mostrarFormulario()}
        </Modal.Body>
        
      </Modal>
    </div>
}

export default EmitirEstadoModal