import React, { useState } from "react";
import { Button, Card, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../Login/login.css"
import { modos_sistema, url_images } from "../../lib/global/data";
import { validateEmail } from "../../lib/helpers/helpers";
import { maps_key, urlapi } from "../../lib/backend/data";
import ReactGoogleAutocomplete from "react-google-autocomplete";



const Register = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [user, setUser] = useState({
        nombre: "",
        apellido: "",
        telefono: "",
        pais_code: "",
        email: "",
        password: "",
        empresa: "",
        logotipo: null,
        rut: "",
        razon_social: "",
        direccion: "",
        flujo: "",
    });

    // const [direccion, setDireccion] = useState("");
    const [step, setStep] = useState(1);
    const [selectedOption, setSelectedOption] = useState("");
    const navigate = useNavigate();



    const userRegister = async (user) => {
        try {
            let response = await fetch(`${urlapi}/auth/userRegister`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(user),
            });
            let data = await response.json();
            if (response.ok) {
                toast.success("Se registró el usuario");
                return true;
            } else {
                toast.error(data.message);
            }
            // return data;
        } catch (error) {
            toast.error("Error al registrar el usuario");
            console.log(error);
        }
        return false;
    };

    const handleChange = (e) => {
        //MANEJA LOS CAMBIOS EN LOS CAMPOS DEL FORM REGISTER
        if (e.target.name === "direccion") {
            setUser({
                ...user,
                [e.target.name]: e.target.value
            });
        } else {
            setUser({
                ...user,
                [e.target.name]: e.target.file === 'file' ? e.target.files[0] : e.target.value
            });
        }
    };

    const handleSelection = (e) => {
        setSelectedOption(e.target.value);
        setUser({ ...user, flujo: e.target.value });
    };

    const handleLogoChange = (e) => {
        const file = e.target.files[0];
        setUser({ ...user, logotipo: file });
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrev = () => {
        setStep(step - 1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const requeridos = [
            { value: 'nombre', label: 'Nombre' },
            { value: 'apellido', label: 'Apellido' },
            { value: 'email', label: 'Email' },
            { value: 'password', label: 'Password' },
            { value: 'empresa', label: 'Empresa' },
            { value: 'rut', label: 'Rut de la Empresa' },
            { value: 'razon_social', label: 'Razón Social' },
            { value: 'direccion', label: 'Dirección' },
            { value: 'flujo', label: 'Tipo' }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if (!user[campo.value]) faltantes.push(campo.label)
            return true
        })
        if (faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        if (validateEmail(user.email) !== true) return toast.error('Email inválido')
        setIsSubmitting(true);
        const formData = new FormData();

        formData.append("nombre", user.nombre);
        formData.append("apellido", user.apellido);
        formData.append("telefono", user.telefono);
        formData.append("pais_code", user.pais_code);
        formData.append("email", user.email);
        formData.append("password", user.password);
        formData.append("empresa", user.empresa);
        formData.append("logotipo", user.logotipo);
        formData.append("rut", user.rut);
        formData.append("razon_social", user.razon_social);
        formData.append("direccion", user.direccion);
        formData.append("flujo", user.flujo);

        const payload_data = {
            nombre: user.nombre,
            apellido: user.apellido,
            telefono: user.telefono,
            pais_code: user.pais_code,
            email: user.email,
            password: user.password,
            empresa: user.empresa,
            logotipo: user.logotipo,
            rut: user.rut,
            razon_social: user.razon_social,
            direccion: user.direccion,
            flujo: user.flujo,
        }

        const response = await userRegister(payload_data);
        console.log(payload_data);

        if (response) {
            navigate("/login");
        }
    };


    return (
        <div className="login-form text-primary">
            <Col md={8} className="centerv row justify-content-center border px-5 py-4 shadow">
                <Row className="d-flex align-items-start">
                    <Link to={`/login`} style={{ fontSize: 25, position: 'absolute', left: 30, top: 15 }}>
                        <i className="fa-solid fa-arrow-left" data-toggle="tooltip" title="Salir"></i>
                    </Link>
                </Row>
                <Row className='row justify-content-center'>
                    <img alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
                </Row>

                <Container className="text-center">
                    <p className='my-3' style={{ fontWeight: 700, fontSize: 15 }}>Transformamos la experiencia en software</p>
                    {/* <p className="m-0" style={{ fontWeight: 900, fontSize: 32 }}>¡BIENVENIDO!</p> */}
                    <p style={{ fontWeight: 900, fontSize: 30 }}>Registro de Usuario</p>
                </Container>
                <div className="container py-3 px-4">
                    <Form className="needs-validation" onSubmit={handleSubmit}>
                        {step === 1 && (
                            <>
                                <h5 style={{ fontWeight: 900 }}>
                                    <i className="fa-solid fa-user-plus"></i> Datos personales
                                </h5>
                                <Row>
                                    <Form.Group
                                        className="mb-3 col-md-6"
                                        controlId="formBasicNombre"
                                    >
                                        <Form.Label style={{ fontWeight: 700 }}>Nombre</Form.Label>
                                        <Form.Control
                                            className="shadow-sm"
                                            type="text"
                                            name="nombre"
                                            value={user.nombre}
                                            onChange={handleChange}
                                            placeholder="Ingrese su nombre"
                                            required
                                        />
  
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3 col-md-6"
                                        controlId="formBasicApellido"
                                    >
                                        <Form.Label style={{ fontWeight: 700 }}>
                                            Apellido
                                        </Form.Label>
                                        <Form.Control
                                            className="shadow-sm"
                                            type="text"
                                            name="apellido"
                                            value={user.apellido}
                                            onChange={handleChange}
                                            placeholder="Ingrese su apellido"
                                            required
                                        />

                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3 col-md-4"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label style={{ fontWeight: 700 }}>Email</Form.Label>
                                        <Form.Control
                                            className="shadow-sm"
                                            type="email"
                                            name="email"
                                            value={user.email}
                                            onChange={handleChange}
                                            placeholder="nombre@ejemplo.com"
                                            required
                                        />

                                    </Form.Group>
                                    <div className="col-md-4">
                                        <Form.Label style={{ fontWeight: 700 }}>
                                            Teléfono
                                        </Form.Label>

                                        <div className='row'>
                                            <div className='col-md-4 pl-2 pr-1 '>
                                                <select className='form-control shadow-sm' defaultValue="" name="pais_code" value={user.pais_code} onChange={handleChange}>
                                                    <option value="">Código Pais</option>
                                                    <option data-countrycode="CL" value="56">Chile (+56)</option>
                                                    <option data-countrycode="BR" value="55">Brazil (+55)</option>
                                                    <optgroup label="Otros países">
                                                        <option data-countrycode="DZ" value="213">Algeria (+213)</option>
                                                        <option data-countrycode="AD" value="376">Andorra (+376)</option>
                                                        <option data-countrycode="AO" value="244">Angola (+244)</option>
                                                        <option data-countrycode="AI" value="1264">Anguilla (+1264)</option>
                                                        <option data-countrycode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                                                        <option data-countrycode="AR" value="54">Argentina (+54)</option>
                                                        <option data-countrycode="AM" value="374">Armenia (+374)</option>
                                                        <option data-countrycode="AW" value="297">Aruba (+297)</option>
                                                        <option data-countrycode="AU" value="61">Australia (+61)</option>
                                                        <option data-countrycode="AT" value="43">Austria (+43)</option>
                                                        <option data-countrycode="AZ" value="994">Azerbaijan (+994)</option>
                                                        <option data-countrycode="BS" value="1242">Bahamas (+1242)</option>
                                                        <option data-countrycode="BH" value="973">Bahrain (+973)</option>
                                                        <option data-countrycode="BD" value="880">Bangladesh (+880)</option>
                                                        <option data-countrycode="BB" value="1246">Barbados (+1246)</option>
                                                        <option data-countrycode="BY" value="375">Belarus (+375)</option>
                                                        <option data-countrycode="BE" value="32">Belgium (+32)</option>
                                                        <option data-countrycode="BZ" value="501">Belize (+501)</option>
                                                        <option data-countrycode="BJ" value="229">Benin (+229)</option>
                                                        <option data-countrycode="BM" value="1441">Bermuda (+1441)</option>
                                                        <option data-countrycode="BT" value="975">Bhutan (+975)</option>
                                                        <option data-countrycode="BO" value="591">Bolivia (+591)</option>
                                                        <option data-countrycode="BA" value="387">Bosnia Herzegovina (+387)</option>
                                                        <option data-countrycode="BW" value="267">Botswana (+267)</option>
                                                        <option data-countrycode="BN" value="673">Brunei (+673)</option>
                                                        <option data-countrycode="BG" value="359">Bulgaria (+359)</option>
                                                        <option data-countrycode="BF" value="226">Burkina Faso (+226)</option>
                                                        <option data-countrycode="BI" value="257">Burundi (+257)</option>
                                                        <option data-countrycode="KH" value="855">Cambodia (+855)</option>
                                                        <option data-countrycode="CM" value="237">Cameroon (+237)</option>
                                                        <option data-countrycode="CA" value="1">Canada (+1)</option>
                                                        <option data-countrycode="CV" value="238">Cape Verde Islands (+238)</option>
                                                        <option data-countrycode="KY" value="1345">Cayman Islands (+1345)</option>
                                                        <option data-countrycode="CF" value="236">Central African Republic (+236)</option>
                                                        <option data-countrycode="CN" value="86">China (+86)</option>
                                                        <option data-countrycode="CO" value="57">Colombia (+57)</option>
                                                        <option data-countrycode="KM" value="269">Comoros (+269)</option>
                                                        <option data-countrycode="CG" value="242">Congo (+242)</option>
                                                        <option data-countrycode="CK" value="682">Cook Islands (+682)</option>
                                                        <option data-countrycode="CR" value="506">Costa Rica (+506)</option>
                                                        <option data-countrycode="HR" value="385">Croatia (+385)</option>
                                                        <option data-countrycode="CU" value="53">Cuba (+53)</option>
                                                        <option data-countrycode="CY" value="90392">Cyprus North (+90392)</option>
                                                        <option data-countrycode="CY" value="357">Cyprus South (+357)</option>
                                                        <option data-countrycode="CZ" value="42">Czech Republic (+42)</option>
                                                        <option data-countrycode="DK" value="45">Denmark (+45)</option>
                                                        <option data-countrycode="DJ" value="253">Djibouti (+253)</option>
                                                        <option data-countrycode="DM" value="1809">Dominica (+1809)</option>
                                                        <option data-countrycode="DO" value="1809">Dominican Republic (+1809)</option>
                                                        <option data-countrycode="EC" value="593">Ecuador (+593)</option>
                                                        <option data-countrycode="EG" value="20">Egypt (+20)</option>
                                                        <option data-countrycode="SV" value="503">El Salvador (+503)</option>
                                                        <option data-countrycode="GQ" value="240">Equatorial Guinea (+240)</option>
                                                        <option data-countrycode="ER" value="291">Eritrea (+291)</option>
                                                        <option data-countrycode="EE" value="372">Estonia (+372)</option>
                                                        <option data-countrycode="ET" value="251">Ethiopia (+251)</option>
                                                        <option data-countrycode="FK" value="500">Falkland Islands (+500)</option>
                                                        <option data-countrycode="FO" value="298">Faroe Islands (+298)</option>
                                                        <option data-countrycode="FJ" value="679">Fiji (+679)</option>
                                                        <option data-countrycode="FI" value="358">Finland (+358)</option>
                                                        <option data-countrycode="FR" value="33">France (+33)</option>
                                                        <option data-countrycode="GF" value="594">French Guiana (+594)</option>
                                                        <option data-countrycode="PF" value="689">French Polynesia (+689)</option>
                                                        <option data-countrycode="GA" value="241">Gabon (+241)</option>
                                                        <option data-countrycode="GM" value="220">Gambia (+220)</option>
                                                        <option data-countrycode="GE" value="7880">Georgia (+7880)</option>
                                                        <option data-countrycode="DE" value="49">Germany (+49)</option>
                                                        <option data-countrycode="GH" value="233">Ghana (+233)</option>
                                                        <option data-countrycode="GI" value="350">Gibraltar (+350)</option>
                                                        <option data-countrycode="GR" value="30">Greece (+30)</option>
                                                        <option data-countrycode="GL" value="299">Greenland (+299)</option>
                                                        <option data-countrycode="GD" value="1473">Grenada (+1473)</option>
                                                        <option data-countrycode="GP" value="590">Guadeloupe (+590)</option>
                                                        <option data-countrycode="GU" value="671">Guam (+671)</option>
                                                        <option data-countrycode="GT" value="502">Guatemala (+502)</option>
                                                        <option data-countrycode="GN" value="224">Guinea (+224)</option>
                                                        <option data-countrycode="GW" value="245">Guinea - Bissau (+245)</option>
                                                        <option data-countrycode="GY" value="592">Guyana (+592)</option>
                                                        <option data-countrycode="HT" value="509">Haiti (+509)</option>
                                                        <option data-countrycode="HN" value="504">Honduras (+504)</option>
                                                        <option data-countrycode="HK" value="852">Hong Kong (+852)</option>
                                                        <option data-countrycode="HU" value="36">Hungary (+36)</option>
                                                        <option data-countrycode="IS" value="354">Iceland (+354)</option>
                                                        <option data-countrycode="IN" value="91">India (+91)</option>
                                                        <option data-countrycode="ID" value="62">Indonesia (+62)</option>
                                                        <option data-countrycode="IR" value="98">Iran (+98)</option>
                                                        <option data-countrycode="IQ" value="964">Iraq (+964)</option>
                                                        <option data-countrycode="IE" value="353">Ireland (+353)</option>
                                                        <option data-countrycode="IL" value="972">Israel (+972)</option>
                                                        <option data-countrycode="IT" value="39">Italy (+39)</option>
                                                        <option data-countrycode="JM" value="1876">Jamaica (+1876)</option>
                                                        <option data-countrycode="JP" value="81">Japan (+81)</option>
                                                        <option data-countrycode="JO" value="962">Jordan (+962)</option>
                                                        <option data-countrycode="KZ" value="7">Kazakhstan (+7)</option>
                                                        <option data-countrycode="KE" value="254">Kenya (+254)</option>
                                                        <option data-countrycode="KI" value="686">Kiribati (+686)</option>
                                                        <option data-countrycode="KP" value="850">Korea North (+850)</option>
                                                        <option data-countrycode="KR" value="82">Korea South (+82)</option>
                                                        <option data-countrycode="KW" value="965">Kuwait (+965)</option>
                                                        <option data-countrycode="KG" value="996">Kyrgyzstan (+996)</option>
                                                        <option data-countrycode="LA" value="856">Laos (+856)</option>
                                                        <option data-countrycode="LV" value="371">Latvia (+371)</option>
                                                        <option data-countrycode="LB" value="961">Lebanon (+961)</option>
                                                        <option data-countrycode="LS" value="266">Lesotho (+266)</option>
                                                        <option data-countrycode="LR" value="231">Liberia (+231)</option>
                                                        <option data-countrycode="LY" value="218">Libya (+218)</option>
                                                        <option data-countrycode="LI" value="417">Liechtenstein (+417)</option>
                                                        <option data-countrycode="LT" value="370">Lithuania (+370)</option>
                                                        <option data-countrycode="LU" value="352">Luxembourg (+352)</option>
                                                        <option data-countrycode="MO" value="853">Macao (+853)</option>
                                                        <option data-countrycode="MK" value="389">Macedonia (+389)</option>
                                                        <option data-countrycode="MG" value="261">Madagascar (+261)</option>
                                                        <option data-countrycode="MW" value="265">Malawi (+265)</option>
                                                        <option data-countrycode="MY" value="60">Malaysia (+60)</option>
                                                        <option data-countrycode="MV" value="960">Maldives (+960)</option>
                                                        <option data-countrycode="ML" value="223">Mali (+223)</option>
                                                        <option data-countrycode="MT" value="356">Malta (+356)</option>
                                                        <option data-countrycode="MH" value="692">Marshall Islands (+692)</option>
                                                        <option data-countrycode="MQ" value="596">Martinique (+596)</option>
                                                        <option data-countrycode="MR" value="222">Mauritania (+222)</option>
                                                        <option data-countrycode="YT" value="269">Mayotte (+269)</option>
                                                        <option data-countrycode="MX" value="52">Mexico (+52)</option>
                                                        <option data-countrycode="FM" value="691">Micronesia (+691)</option>
                                                        <option data-countrycode="MD" value="373">Moldova (+373)</option>
                                                        <option data-countrycode="MC" value="377">Monaco (+377)</option>
                                                        <option data-countrycode="MN" value="976">Mongolia (+976)</option>
                                                        <option data-countrycode="MS" value="1664">Montserrat (+1664)</option>
                                                        <option data-countrycode="MA" value="212">Morocco (+212)</option>
                                                        <option data-countrycode="MZ" value="258">Mozambique (+258)</option>
                                                        <option data-countrycode="MN" value="95">Myanmar (+95)</option>
                                                        <option data-countrycode="NA" value="264">Namibia (+264)</option>
                                                        <option data-countrycode="NR" value="674">Nauru (+674)</option>
                                                        <option data-countrycode="NP" value="977">Nepal (+977)</option>
                                                        <option data-countrycode="NL" value="31">Netherlands (+31)</option>
                                                        <option data-countrycode="NC" value="687">New Caledonia (+687)</option>
                                                        <option data-countrycode="NZ" value="64">New Zealand (+64)</option>
                                                        <option data-countrycode="NI" value="505">Nicaragua (+505)</option>
                                                        <option data-countrycode="NE" value="227">Niger (+227)</option>
                                                        <option data-countrycode="NG" value="234">Nigeria (+234)</option>
                                                        <option data-countrycode="NU" value="683">Niue (+683)</option>
                                                        <option data-countrycode="NF" value="672">Norfolk Islands (+672)</option>
                                                        <option data-countrycode="NP" value="670">Northern Marianas (+670)</option>
                                                        <option data-countrycode="NO" value="47">Norway (+47)</option>
                                                        <option data-countrycode="OM" value="968">Oman (+968)</option>
                                                        <option data-countrycode="PW" value="680">Palau (+680)</option>
                                                        <option data-countrycode="PA" value="507">Panama (+507)</option>
                                                        <option data-countrycode="PG" value="675">Papua New Guinea (+675)</option>
                                                        <option data-countrycode="PY" value="595">Paraguay (+595)</option>
                                                        <option data-countrycode="PE" value="51">Peru (+51)</option>
                                                        <option data-countrycode="PH" value="63">Philippines (+63)</option>
                                                        <option data-countrycode="PL" value="48">Poland (+48)</option>
                                                        <option data-countrycode="PT" value="351">Portugal (+351)</option>
                                                        <option data-countrycode="PR" value="1787">Puerto Rico (+1787)</option>
                                                        <option data-countrycode="QA" value="974">Qatar (+974)</option>
                                                        <option data-countrycode="RE" value="262">Reunion (+262)</option>
                                                        <option data-countrycode="RO" value="40">Romania (+40)</option>
                                                        <option data-countrycode="RU" value="7">Russia (+7)</option>
                                                        <option data-countrycode="RW" value="250">Rwanda (+250)</option>
                                                        <option data-countrycode="SM" value="378">San Marino (+378)</option>
                                                        <option data-countrycode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                                                        <option data-countrycode="SA" value="966">Saudi Arabia (+966)</option>
                                                        <option data-countrycode="SN" value="221">Senegal (+221)</option>
                                                        <option data-countrycode="CS" value="381">Serbia (+381)</option>
                                                        <option data-countrycode="SC" value="248">Seychelles (+248)</option>
                                                        <option data-countrycode="SL" value="232">Sierra Leone (+232)</option>
                                                        <option data-countrycode="SG" value="65">Singapore (+65)</option>
                                                        <option data-countrycode="SK" value="421">Slovak Republic (+421)</option>
                                                        <option data-countrycode="SI" value="386">Slovenia (+386)</option>
                                                        <option data-countrycode="SB" value="677">Solomon Islands (+677)</option>
                                                        <option data-countrycode="SO" value="252">Somalia (+252)</option>
                                                        <option data-countrycode="ZA" value="27">South Africa (+27)</option>
                                                        <option data-countrycode="ES" value="34">Spain (+34)</option>
                                                        <option data-countrycode="LK" value="94">Sri Lanka (+94)</option>
                                                        <option data-countrycode="SH" value="290">St. Helena (+290)</option>
                                                        <option data-countrycode="KN" value="1869">St. Kitts (+1869)</option>
                                                        <option data-countrycode="SC" value="1758">St. Lucia (+1758)</option>
                                                        <option data-countrycode="SD" value="249">Sudan (+249)</option>
                                                        <option data-countrycode="SR" value="597">Suriname (+597)</option>
                                                        <option data-countrycode="SZ" value="268">Swaziland (+268)</option>
                                                        <option data-countrycode="SE" value="46">Sweden (+46)</option>
                                                        <option data-countrycode="CH" value="41">Switzerland (+41)</option>
                                                        <option data-countrycode="SI" value="963">Syria (+963)</option>
                                                        <option data-countrycode="TW" value="886">Taiwan (+886)</option>
                                                        <option data-countrycode="TJ" value="7">Tajikstan (+7)</option>
                                                        <option data-countrycode="TH" value="66">Thailand (+66)</option>
                                                        <option data-countrycode="TG" value="228">Togo (+228)</option>
                                                        <option data-countrycode="TO" value="676">Tonga (+676)</option>
                                                        <option data-countrycode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                                                        <option data-countrycode="TN" value="216">Tunisia (+216)</option>
                                                        <option data-countrycode="TR" value="90">Turkey (+90)</option>
                                                        <option data-countrycode="TM" value="7">Turkmenistan (+7)</option>
                                                        <option data-countrycode="TM" value="993">Turkmenistan (+993)</option>
                                                        <option data-countrycode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                                                        <option data-countrycode="TV" value="688">Tuvalu (+688)</option>
                                                        <option data-countrycode="UG" value="256">Uganda (+256)</option>
                                                        <option data-countrycode="UA" value="380">Ukraine (+380)</option>
                                                        <option data-countrycode="AE" value="971">United Arab Emirates (+971)</option>
                                                        <option data-countrycode="UY" value="598">Uruguay (+598)</option>
                                                        <option data-countrycode="UZ" value="7">Uzbekistan (+7)</option>
                                                        <option data-countrycode="VU" value="678">Vanuatu (+678)</option>
                                                        <option data-countrycode="VA" value="379">Vatican City (+379)</option>
                                                        <option data-countrycode="VE" value="58">Venezuela (+58)</option>
                                                        <option data-countrycode="VN" value="84">Vietnam (+84)</option>
                                                        <option data-countrycode="VG" value="84">Virgin Islands - British (+1284)</option>
                                                        <option data-countrycode="VI" value="84">Virgin Islands - US (+1340)</option>
                                                        <option data-countrycode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                                                        <option data-countrycode="YE" value="969">Yemen (North)(+969)</option>
                                                        <option data-countrycode="YE" value="967">Yemen (South)(+967)</option>
                                                        <option data-countrycode="ZM" value="260">Zambia (+260)</option>
                                                        <option data-countrycode="ZW" value="263">Zimbabwe (+263)</option>
                                                    </optgroup>
                                                </select>
                                            </div>
                                            <div className='col-md-8 p-0 '>
                                                <input
                                                    placeholder="Ingresa el número"
                                                    className='form-control mb-3 shadow-sm'
                                                    type="tel"
                                                    name="telefono"
                                                    value={user.telefono}
                                                    onChange={handleChange} />
                                            </div>
                                        </div>
                                    </div>
                                    <Form.Group
                                        className="mb-3 col-md-4"
                                        controlId="formBasicPassword"
                                    >
                                        <Form.Label style={{ fontWeight: 700 }}>
                                            Password
                                        </Form.Label>
                                        <Form.Control
                                            className="shadow-sm"
                                            type="password"
                                            name="password"
                                            value={user.password}
                                            onChange={handleChange}
                                            placeholder="...entre 8 y 20 caracteres..."
                                            required
                                        />

                                    </Form.Group>
                                </Row>
                                <div className="d-flex m-3 justify-content-end">
                                    <Button
                                        onClick={handleNext}
                                        className="shadow-sm"
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <b>Siguiente <i className="fa-solid fa-angles-right"></i></b>
                                    </Button>
                                </div>
                            </>
                        )}

                        {step === 2 && (
                            <>
                                <h5 style={{ fontWeight: 900 }}>
                                    <i className="fa-solid fa-building-user"></i> Datos de la Empresa
                                </h5>
                                <Row>
                                    <Col md={4}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmpresa"
                                    >
                                        <Form.Label style={{ fontWeight: 700 }}>
                                            Nombre de la empresa
                                        </Form.Label>
                                        <Form.Control
                                            className="shadow-sm"
                                            type="text"
                                            name="empresa"
                                            value={user.empresa}
                                            onChange={handleChange}
                                            placeholder="Ingrese el nombre de la empresa"
                                            required
                                        />

                                    </Form.Group>
                                    </Col>
                                    {/* <Form.Group controlId="formFileSm" className="mb-3 col-md-4">
                                        <Form.Label htmlFor='logotipo' style={{ fontWeight: 700 }}>
                                            Logo de la empresa
                                        </Form.Label>
                                        <Form.Control
                                            disabled
                                            id="logotipo"
                                            type="file"
                                            name="logotipo"
                                            size="sm"
                                            className="border text-muted shadow-sm mt-2"
                                            style={{ fontSize: 13 }}
                                            value={user.logotipo}
                                            onChange={handleLogoChange}
                                        />
                                    </Form.Group> */}
                                    <Col md={4}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicRutEmpresa"
                                    >
                                        <Form.Label style={{ fontWeight: 700 }}>
                                            RUT de la empresa
                                        </Form.Label>
                                        <Form.Control
                                            className="shadow-sm"
                                            type="text"
                                            name="rut"
                                            value={user.rut}
                                            onChange={handleChange}
                                            placeholder="Ingrese el RUT de la empresa"
                                            required
                                        />
                                    </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicRazonSocial"
                                    >
                                        <Form.Label style={{ fontWeight: 700 }}>
                                            Razón social
                                        </Form.Label>
                                        <Form.Control
                                            className="shadow-sm"
                                            type="text"
                                            name="razon_social"
                                            value={user.razon_social}
                                            onChange={handleChange}
                                            placeholder="Ingrese la razón social de la empresa"
                                            required
                                        />
                                    </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                    <Form.Group
                                        controlId="formBasicDireccion">
                                        <Form.Label style={{ fontWeight: 700 }}>Dirección de la empresa</Form.Label>
                                        <ReactGoogleAutocomplete
                                            className="form-control"
                                            value={user.direccion}
                                            onChange={handleChange}
                                            onClick={() => { 
                                                setUser({ ...user, direccion: user.direccion })
                                            }}
                                            name="direccion"
                                            options={{
                                                types: ["address"],
                                                // cokmponentRestrictions: { country: pais },
                                            }}
                                            apiKey={maps_key}
                                            onPlaceSelected={(place) => {
                                                if (!place) return false
                                                const lat = place.geometry.location.lat()
                                                const lng = place.geometry.location.lng()

                                                let region = ''
                                                const buscar_region = place.address_components.findIndex(comp => {
                                                    return comp.types.includes('administrative_area_level_1')
                                                })
                                                if (buscar_region > -1) region = place.address_components[buscar_region].long_name

                                                let comuna = ''
                                                const buscar_comuna = place.address_components.findIndex(comp => {
                                                    return comp.types.includes('administrative_area_level_2')
                                                })
                                                if (buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                                                const geo_data = {
                                                    lat,
                                                    lng,
                                                    direccion: place.formatted_address,
                                                    region,
                                                    comuna,
                                                    status: true
                                                }
                                                
                                                return setUser(prev => {
                                                    const actual = {...prev}
                                                    actual.direccion = place.formatted_address
                                                    actual.geodata = place
                                                    actual.location = {
                                                        type: "Point",
                                                        coordinates: [
                                                            geo_data.lng, geo_data.lat
                                                        ]
                                                    }
                                                    return actual
                                                })
                                            }}
                                        />
                                    </Form.Group>
                                    </Col>
                                </Row>
                                <div className="d-flex m-3 justify-content-between">
                                    <Button
                                        onClick={handlePrev}
                                        className="shadow-sm"
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <b><i className="fa-solid fa-angles-left"></i> Atrás</b>
                                    </Button>
                                    <Button
                                        onClick={handleNext}
                                        className="shadow-sm"
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <b>Siguiente <i className="fa-solid fa-angles-right"></i></b>
                                    </Button>
                                </div>
                            </>
                        )}

                        {step === 3 && (
                            <>
                                <h5 style={{ fontWeight: 900 }}>
                                    <i className="fa-solid fa-clipboard-question"></i> ¿Que tipo de empresa es?
                                </h5>
                                <Form className="py-3">
                                    <Form.Check
                                        type="radio"
                                        id="option-1"
                                        label="Tengo una flota y quiero administrar cargas."
                                        value={modos_sistema.proveedor.slug}
                                        checked={selectedOption === modos_sistema.proveedor.slug}
                                        onChange={handleSelection}
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="option-2"
                                        label="Tengo una empresa y no tengo flota."
                                        value={modos_sistema.cliente.slug}
                                        checked={selectedOption === modos_sistema.cliente.slug}
                                        onChange={handleSelection}
                                    />
                                    <Form.Check
                                        type="radio"
                                        id="option-3"
                                        label="Quiero usar proveedores de transporte para enviar mis pedidos."
                                        value={modos_sistema.courriers.slug}
                                        checked={selectedOption === modos_sistema.courriers.slug}
                                        onChange={handleSelection}
                                    />
                                </Form>
                                <div className="d-flex m-3 justify-content-between">
                                    <Button
                                        onClick={handlePrev}
                                        className="shadow-sm"
                                        variant="secondary"
                                        size="sm"
                                    >
                                        <b><i className="fa-solid fa-angles-left"></i> Atrás</b>
                                    </Button>
                                    <Button
                                        className="shadow-sm w-25"
                                        variant="success"
                                        type="submit"
                                        size="sm"
                                    >
                                        {isSubmitting ? (
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        ) : (

                                            <b>Registrarse</b>
                                        )}
                                    </Button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </Col>
        </div>
    );
};

export default Register;
