import React, { useEffect, useState } from 'react';
import { Modal, Button, ButtonGroup } from 'react-bootstrap';
import { FaCheck, FaTimes, FaClock } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { urlapi } from '../../lib/backend/data';

const ModalInvitacionFacturacion = () => {
  const [show, setShow] = useState(false);
  const session = useSelector(state => state.miusuario)
  const [ loadingEdit, setLoadingEdit ] = useState(false)
  const [ invitacion, setInvitacion ] = useState(false)
  const [ loading, setLoading ] = useState(true)

  const buscarDatos = async () => {
    return fetch(`${urlapi}/cuentas-facturacion/auth`, {
      method: 'GET',
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${session?.tokenSession}`
      }
    })
    .then(pros => pros.json())
    .then(async pros => {
        if(!pros){
        } else if(pros.errorMessage){
        } else if(pros._id){
            setInvitacion(pros)
            setShow(true)
        }
        return setLoading(false)
    })
    .catch(error => {
          return setLoading(false)
    })

  }

  const statuses = {
    accepted: 'Aceptada',
    rejected: 'Rechazada',
    pending: 'Pendiente de respuesta',
  }

  const acciones = () => {
    return <div style={{ width: "100%" }}>
      <h4><b>{statuses[invitacion.status] || "Sin información" }</b></h4>
      { invitacion.status === "accepted" ? <p className='text-success'>Has aceptado la invitación, ya puedes comenzar a utilizar la cuenta, ¡Éxitos!</p> : false }
      <ButtonGroup style={{ width: "100%" }}>
          {["pending"].includes(invitacion.status) ? <Button onClick={() => performarAccion('accepted')} disabled={loadingEdit} variant="success" style={{ display: 'flex', alignItems: 'center' }}><FaCheck style={{ marginRight: '5px' }}  /> ACEPTAR</Button> : false}
          {["pending"].includes(invitacion.status) ? <Button onClick={() => performarAccion('rejected')} disabled={loadingEdit} variant="danger" style={{ display: 'flex', alignItems: 'center' }}><FaTimes style={{ marginRight: '5px' }}  /> RECHAZAR</Button> : false}
          {["pending"].includes(invitacion.status) ? <Button onClick={() => performarAccion('check-later')} disabled={loadingEdit} variant="secondary" style={{ display: 'flex', alignItems: 'center' }}><FaClock style={{ marginRight: '5px' }}  /> REVISAR LUEGO</Button> : false}
          </ButtonGroup>
    </div>
  }
  const performarAccion = async (status) => {
    setLoadingEdit(true)
    return fetch(`${urlapi}/cuentas-facturacion/receiver`, {
      method: 'PUT',
      body: JSON.stringify({
        _id: invitacion._id,
        status
      }),
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${session?.tokenSession}`
      }
    })
    .then(pros => pros.json())
    .then(async pros => {
        if(!pros){
        } else if(pros.errorMessage){
        } else if(pros._id){
          if(["check-later", "rejected"].includes(status)) setShow(false)
            setInvitacion(pros)
        }
        return setLoadingEdit(false)
    })
    .catch(error => {
          return setLoadingEdit(false)
    })

  }

  useEffect(() => {
    buscarDatos()
  }, [])

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  let items = []
  if(Array.isArray(invitacion?.items)) items = invitacion.items 

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invitación de Facturación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <strong style={{fontWeight:700}}>{invitacion?.empresa?.razon_social}</strong> TE HA COMPARTIDO RECURSOS A TRAVÉS DE LA CUENTA DE FACTURACIÓN <strong style={{fontWeight:700}}>{invitacion?.id}</strong>
          </p>
          <p>
            Aceptala para que puedas acceder a las funciones que te ha compartido.
          </p>
          <p style={{fontWeight:900}}>
            No pagas nada.
          </p>
          <p style={{ marginBottom: '20px', backgroundColor: '#e9ecef', padding: '10px', borderRadius: '5px' }}>
          Las cuentas de facturación son útiles para las empresas que forman parte de nuestro sistema de administración de flotas y cargas. Les permiten compartir recursos y funciones dentro del sistema contigo, podrás acceder a estas funciones dentro de nuestro sistema sin costo para ti, la cuenta de facturación rastreará estos recursos y los cobrará a {invitacion?.empresa?.razon_social}.
          </p>
          <p style={{fontWeight:900}} className='mb-0'><b>Recursos asociados</b></p>
          {items.length === 0 ? <p className='mb-0'>No especificado</p> : false}
          <ul>
            {
              items.map((item,i) => {
                return <li ley={`item-${i}`}>{item.name}</li>
              })
            }
          </ul>
        </Modal.Body>
        <Modal.Footer>
          {acciones()}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalInvitacionFacturacion;