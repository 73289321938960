import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, Navbar, Nav } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import LoadingFlota from '../general/loadingAnimations/loadingFlota';
import { FaFileExcel, FaHashtag, FaPlusCircle } from 'react-icons/fa';
import { rutas } from '../../lib/routes/routes';
import { Link } from 'react-router-dom';

const ConductoresListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const session = useSelector(state => state.miusuario)
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState(false)
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    const dispatch = useDispatch()

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/conductores/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    const onClickConductor = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        return fetch(`${data.urlapi}/conductores?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setVehiculoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerConductores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <LoadingFlota />
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingConductores===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5 className='mb-0' style={{fontWeight:900}}><i className="fa-solid fa-triangle-exclamation"></i> Nada por aquí</h5>
            <p style={{fontSize:12}}>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            {
                        registros.map(orden => {
                            let seleccionado = false
                            if(idSeleccionado === orden._id) seleccionado = true
                            return <div key={orden._id} className="mb-3">
                                <BoxList conductor={orden} seleccionado={seleccionado} onClickConductor={(id) => onClickConductor(id)} />
                            </div>
                        })
                    }
        </div>
    }

    const restaurarRegistro = async (id,status) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/conductores/status?id=${id}&status=${status}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = conductores.findIndex(e => e._id === id)
                conductores.splice(i,1)
                setConductores(prev => conductores)
                setTimeout(() => {
                    window.location = '/conductores'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }
    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/conductores?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = conductores.findIndex(e => e._id === id)
                conductores.splice(i,1)
                setConductores(prev => conductores)
                setTimeout(() => {
                    window.location = '/conductores'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleChangeBuscador = (e) => {
        const { name, value } = e.target
        switch (name) {
            case 'patente':
                return setPatente(value)
            case 'numero':
                return setNumeroInterno(value)
            default:
                break;
        }
    }

    const handleSubmitPatente = (e) => {
        e.preventDefault()
        if(!patente) return toast.error('Selecciona una patente')
        const condicion = { patente: patente.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerConductores(1, condicion)
    }

    const handleSubmitNumeroInterno = (e) => {
        e.preventDefault()
        if(!numeroInterno) return toast.error('Selecciona una patente')
        const condicion = { numero_interno: numeroInterno.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerConductores(1, condicion)
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerConductores(1, {})
    }

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleChangePedido = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const handleSubmitReferencia = (e) => {
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerConductores(1, condicion)
        }
        const condicion = { $text: { $search: parametroBusqueda }, status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerConductores(1, condicion)
    }

    const show_menu_top = <div>
        <Navbar bg="light" variant="light" className='mb-3'>
              {/* <Navbar.Brand >Órdenes</Navbar.Brand> */}
              <Nav className="mr-auto">
                <Nav.Link ><Link to={`/${rutas.vehiculos_nuevo.slug}`}><FaPlusCircle /> CREAR NUEVO</Link> </Nav.Link>
                <Nav.Link ><Link to={`/${rutas.proveedores.slug}`}><FaHashtag /> CATEGORÍAS</Link> </Nav.Link>
                <Nav.Link ><Link to={`/${rutas.vehiculos_importar_excel.slug}`}><FaFileExcel /> IMPORTAR EXCEL</Link> </Nav.Link>
              </Nav>
              {/* <Form inline>
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-info">Search</Button>
              </Form> */}
            </Navbar>
    </div>
 
    return <div>
        {show_menu_top}
        {modalFiltro()}
        <Row>
            <Col md={3} style={{height: '100vh', overflowY: 'auto'}}>
                <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmitReferencia}>
                        <input className='mb-3 form-control' placeholder='BUSCAR POR PALABRAS CLAVE' onChange={handleChangePedido} />
                        </form>
                    </Col>
                </Row>
                {tabla_registros(conductores)}
            </Col>
            <Col md={9} style={{height: '100vh', overflowY: 'auto'}}>
                <Card className='p-3'>
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull token={token} vehiculo={vehiculoSeleccionado} />
                    }
                </Card>
            </Col>
        </Row>
    </div>

}

export default ConductoresListado