import { useEffect, useState } from "react"
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Table, Tabs, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../../lib/backend/data"
import { cerrarSesion } from "../../../../redux/actions/session"
import * as XLSX from 'xlsx'
import { extraerNumeros, formatoMoneda, sortDynamic } from "../../../../lib/helpers/main"
import VisualizarRangos from "../rangos/visualizar_rangos"
import { BiSolidChevronRight, BiSolidSend } from "react-icons/bi"
import { BsFillCheckCircleFill, BsTrash } from "react-icons/bs"
import PantallaError from "../../../general/pantalla_error"
import CargandoFlotante from "../../../general/cargando_flotante"
import { sliceIntoChunks } from "../../../../lib/helpers/helpers"
import { useParams } from "react-router-dom"
import PantallaCargando from "../../../general/pantalla_cargando"
import { rutas } from "../../../../lib/routes/routes"
import { confirmAlert } from "react-confirm-alert"
import Cotizador from "../cotizador"
import ErrorInterfaz from "../../../general/error"
import MediosBox from "../../../general/mediosbox"

const DetalleLoteTarifa = (props) => {
    const {
        rut_cliente
    } = props
    const [ loadingUpload, setLoadingUpload ] = useState(false)
    const [ rangos, setRangos ] = useState([])
    const [ disablePesoDesde, setDisablePesoDesde ] = useState(false)
    const [ peso_desde, setPesoDesde ] = useState(0)
    const [ peso_hasta, setPesoHasta ] = useState('')
    const [ servicio, setServicio ] = useState(false)
    const [ lote, setLote ] = useState({})
    const [ tarifadinamica, setTarifaDinamica ] = useState(false)
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ zonas, setZonas ] = useState([])
    const [ loadingPrincipal, setLoadingPrincipal ] = useState(true)
    const [ resize, setResize ] = useState(0)
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ procesados, setProcesados ] = useState(0)
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const [ errores, setErrores ] = useState([])
    const [ tarifasCargadas, setTarifasCargadas ] = useState([])
    const [ loadingGeoData, setLoadingGeoData ] = useState(true)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const reset_array = [...[], ...[]]

    const { id } = useParams()

    useEffect(() => {
        listadoInicial({},1)
    }, [])

    const listadoInicial = async (condicion, page) => {
        return fetch(`${urlapi}/geodata/zonas/list/select`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
                'country': pais
            }
        })
        .then(res => {
            obtenerDatos()
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setZonas(res)
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    } 
    
    const handleChangeLote = (e) => {
        const { name, value } = e.target
        lote[name] = value
        return setLote(lote)
    }

    const handleInputChange = (event) => {

        const target = event.target
        const name = target.name
        let hojas = []
        setErrores(reset_array)
        setTarifasCargadas(reset_array)
        if (name === 'file') {

            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos', )
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos', )
            if(hojas[0].length < 1) return toast.error('No se encontraron datos', )
            
            let problemas = []
            let tarifas = []
            
            // const rangos_ordenados = rangos.sort(sortDynamic('titulo', 'asc'))
            
            
            const rangos_ordenados = rangos.sort((a, b) => {
                const valor_a = parseInt(extraerNumeros(a.titulo))
                const valor_b = parseInt(extraerNumeros(b.titulo))
                if (valor_a < valor_b) {
                    return -1;
                }
                if (valor_a > valor_b) {
                    return 1;
                }
                return 0;
            })
            
            hojas[0].map((row,irow) => {

                if(!row.destino){
                    problemas.push(`Fila ${(irow+1)} sin destino, se descarta`)
                    return false
                }

                let tarifa_base = false
                let pesobase = false

                const i_destino = zonas.findIndex(z => z.titulo.toLowerCase() === row.destino.toLowerCase() )
                if(i_destino < 0){
                    problemas.push(`Fila ${(irow+1)} con destino inválido, ${row.destino}`)
                    return false
                }

                rangos_ordenados.map(rango => {
                    const precio = parseFloat(row[rango.titulo])
                    if(isNaN(precio) !== true){
                    let nueva_tarifa = {
                        // id_geocerca_origen: "1", SE GRABA LUEGO PARA PODER CAMBIARLO LUEGO DE LA SUBIDA
                        id_geocerca_destino: zonas[i_destino]._id,
                        tipo: 'simple',
                        tarifa: precio,
                        tarifabase: false,
                        peso_desde: rango.peso_desde,
                        peso_hasta: rango.peso_hasta,
                        rutcliente: rango.rut
                    }

                    if(tarifadinamica===rango.titulo){
                        const numero = rango.titulo.replace(/\D/g, "")
                        const titulo_rango_anterior = `rango${parseInt(numero)-1}`
                        tarifa_base = row[titulo_rango_anterior]
                        const buscar_rango = rangos_ordenados.findIndex(ra => ra.titulo === titulo_rango_anterior)
                        if(buscar_rango > -1){
                            pesobase = rangos_ordenados[buscar_rango].peso_hasta
                        }
                    }

                    if(tarifa_base !== false){
                        nueva_tarifa.tarifabase = parseFloat(tarifa_base)
                        nueva_tarifa.tipo = 'dinamico'
                        nueva_tarifa.pesobase = pesobase
                    }

                    tarifas.push(nueva_tarifa)
                    }
                })

            })
            
            if(problemas.length > 0){
                setErrores([...[], ...problemas])
                return toast.error(`Se detectaron problemas en tu archivo`, ) // SI HAY ERRORES DETENER
            }


            return setTarifasCargadas([...[], ...tarifas])
          }
        }
      }

    const onChangeServicio = async (e) => {
        setErrores(reset_array)
        setTarifasCargadas(reset_array)
        setLoadingUpload(true)
        setServicio(e)
        return fetch(`${urlapi}/pricing/rangos?id=${e._id}&rut=${rut_cliente}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setRangos(res)
            }
            return setLoadingUpload(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingUpload(false)
        })
    } 

    const handleChangePesoDesde = (e) => {
        const { value } = e.target
        const numero = value.replace(/[^\d]/g, "");
        return setPesoDesde(parseFloat(numero))
    }
    const handleChangePesoHasta = (e) => {
        const { value } = e.target
        const numero = value.replace(/[^\d]/g, "");
        return setPesoHasta(parseFloat(numero))
    }

    const agregarRango = () => {
        if(peso_desde===0 && peso_hasta === 0) return toast.error(`Valores de rango inválidos`)
        if(peso_desde===peso_hasta) return toast.error(`Valores de rango inválidos`)

        const titulo = `rango${rangos.length+1}`

        const buscar_repetido = rangos.filter(ra => ra.peso_desde === peso_desde && ra.peso_hasta === peso_hasta-1)

        if(buscar_repetido.length > 0) return toast.error(`Este rango que intentas agregar está repetido`)
        setDisablePesoDesde(true)
        rangos.push({
            titulo,
            peso_desde,
            peso_hasta: peso_hasta-1,
            tipo:'simple',
            propietario_cliente: rut_cliente,
            pais_code: pais
        })

        setPesoDesde(peso_hasta)
        setPesoHasta(peso_hasta)
        return setRangos(prev => [...[], ...rangos])
    }

    const obtenerDatos = async () => {
        if(!id) return setLoadingPrincipal(false)
        return fetch(`${urlapi}/pricing/lotes/detalles?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setLote(res)
//                if(res.geocercas) if( Array.isArray(res.geocercas) !== false ) setZonas(res.geocercas)
                if(res.tarifas) if( Array.isArray(res.tarifas) !== false ) setTarifasCargadas(res.tarifas)
                if(res.rangos) if( Array.isArray(res.rangos) !== false ) setRangos(res.rangos)
            }
            return setLoadingPrincipal(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingPrincipal(false)
        })
    }

    const guardarRangosPendientes = async () => {
        if(!servicio) return toast.error("Selecciona el tipo de servicio")
        const rangos_preparados = JSON.parse( JSON.stringify(rangos) ).map(ra => ({...ra, id_tipo_servicio: servicio._id}))
        setLoadingUpload(true)
        return fetch(`${urlapi}/pricing/lotes`,{
            method:'POST',
            body:JSON.stringify({
                rangos: rangos_preparados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                if(res.length === rangos_preparados.length){
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                    return toast.success("Creado exitosamente")
                }
            }
            return setLoadingUpload(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingUpload(false)
        })
    }
    
    const crearLote = async () => {
        let nuevo_lote = JSON.parse( JSON.stringify(lote) )
        nuevo_lote.id_servicio = servicio._id
        nuevo_lote.rut_cliente = rut_cliente
        return fetch(`${urlapi}/pricing/lotes`,{
            method:'POST',
            body:JSON.stringify(nuevo_lote),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                return res._id
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    const subirDatos = async (id_lote, datos) => {
        const progreso = procesados + datos.length

        return fetch(`${urlapi}/pricing/lotes/tarifas`,{
            method:'POST',
            body: JSON.stringify({
                id_lote,
                create: datos
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return toast.error('Sin datos')
            } else if(res.errorMessage){
                return toast.error(res.errorMessage)
            }
            if(Array.isArray(res.invalidos) !== false){
                for( const error of res.invalidos ){
                        errores.push(error)
                }
                setErrores(prev => [...[], ...errores])
            }
            return setProcesados(progreso)
        })
        .catch(error => {
            toast.error(error.message)
            return setProcesados(progreso)
        })
    }

    const guardarTarifas = async () => {
        if(tarifasCargadas.length < 1) return toast.error("No se encontraron tarifas")
        
        const tarifas_preparadas = JSON.parse( JSON.stringify(tarifasCargadas) ).map(tar => {
            tar.rutcliente = rut_cliente
            return tar
        })
        
        setMensajeLoading('Creando lote de tarifas')
        setLoadingMaster(true)
        setLoadingOperaciones(tarifas_preparadas.length)
        setOperacionesListas(0)
        let total_procesadas = 0
        
        const nuevo_lote = await crearLote()
        if(!nuevo_lote){
            return toast.error("No se pudo crear el lote")
        }
        setMensajeLoading('Creando tarifas')

            const dividir = sliceIntoChunks(tarifas_preparadas, 50)
            for( const array of dividir ){
                await subirDatos(nuevo_lote, array)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
            }
        
        setLoadingMaster(false)
        toast.success("Progreso finalizado")
        
        return setTimeout(() => {
            window.location = `/${rutas.pricing_lotes.slug}/${nuevo_lote}`
        }, 1500);
    }

    const onChangeOrigen = e => {
        lote.id_geocerca_origen = e._id
        return setLote(lote)
    }

    const reiniciarRangos = () => {
        setPesoDesde(0)
        setPesoHasta('')
        setDisablePesoDesde(false)
        return setRangos([...[], ...[]])
    }

    const handleChangeRangoDinamico = e => {
        const { value } = e.target
        setErrores(reset_array)
        setTarifasCargadas(reset_array)
        return setTarifaDinamica(value)
    } 

    const ifRangosSinGuardar = () => {
        if(loadingUpload) return <Spinner animation="border" />

          const pendientes_de_guardar = rangos.filter(ra => !ra._id)
          if(pendientes_de_guardar.length > 0) return <div>
                <Button size="sm" variant="outline-danger" className="mr-3" onClick={() => reiniciarRangos()}>BORRAR TODOS</Button>
              { loadingUpload ? <Spinner animation="border" /> : <Button size="sm" variant="success " onClick={()=>guardarRangosPendientes()}>GUARDAR RANGOS</Button> }
          </div>
    }

    const removerTarifasCargadas = () => {
        setErrores(reset_array)
        return setTarifasCargadas(reset_array)
    }

    const handleClose = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleClose} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const procesarDatos = (datos) => {
        let sector = []
        for(const reg of datos ){
            const i = sector.findIndex(se => se._id === reg.id_geocerca_destino)
            if(i > -1){
                sector[i].tarifas.push(reg)
            } else {
                const pos_zona = zonas.findIndex(zon => zon._id === reg.id_geocerca_destino)
                if(pos_zona > -1 ) sector.push({...zonas[pos_zona], tarifas: [ reg ] })
            }
        }
        return sector
    }

    const segmentarPorZonaAdministrativaPorPais = (datos) => {
        switch (pais) {
            case "CL":

                let region_iteracion = []

                const instancia_zonas = JSON.parse( JSON.stringify( zonas ) )

                for( const tar of datos ){
                    let codregion = false

                    if(tar.properties) if(typeof tar.properties === "object") if(tar.properties.codregion) codregion = tar.properties.codregion

                    if( codregion !== false ){
                        const i_region = instancia_zonas.findIndex(zo => {
                            if(zo.local_name !== "region") return false
                            if(!zo.properties) return false
                            if(typeof zo.properties !== "object") return false
                            return zo.properties.codregion === codregion
                        })
                        if(i_region > -1){
                            
                            //continue
                            const i_region_iteracion = region_iteracion.findIndex(re => re._id === instancia_zonas[i_region]._id)
                            if(i_region_iteracion > -1){
    
                                region_iteracion[i_region_iteracion].zonas.push(tar)
    
                            } else {
                                region_iteracion.push({...zonas[i_region], zonas: [ tar ] })
                            }
                        } else {
                            console.log({ tar, i_region, codregion }, instancia_zonas.length)
                        }
                    } else {
                        console.log("no tiene region", tar, instancia_zonas.length)
                    }
                }

                return region_iteracion
            default:
                return []
        }
    }

    const mostrarUM = () => {
        if(lote.porkilovolumen === true ) return "Kg/vol"
        if(lote.porvolumen === true ) return "M3"
        return "KG"
    }

    const mostrarTarifasCargadas = () => {
        try {
        const datos =  procesarDatos(tarifasCargadas)
        const segmentar = segmentarPorZonaAdministrativaPorPais(datos)
        const rangos_ordenados = rangos.sort((a, b) => {
            const valor_a = parseInt(extraerNumeros(a.titulo))
            const valor_b = parseInt(extraerNumeros(b.titulo))
            if (valor_a < valor_b) {
                return -1;
            }
            if (valor_a > valor_b) {
                return 1;
            }
            return 0;
        })

        return <div >
            <div className="p-3"></div>
            <h5 style={{fontWeight:700}}>{tarifasCargadas.length} Tarifas cargadas</h5>
            {
                segmentar.map((region,irr) => {
                    return <div key={region._id} className="mt-2">
                        <h4>{region.titulo}</h4>
                        <Card className="p-0 border-0">
                        <Table striped bordered className="mb-0">
                            <thead>
                                <tr>
                                    <th>DESTINO</th>
                                    {
                                        rangos_ordenados.map((rango,irango) => {
                                            const desde = (rango.peso_desde/1000).toFixed(0)
                                            const hasta = (rango.peso_hasta/1000).toFixed(0)
                                            let unidad = mostrarUM()
                                            let estilo_um = { fontSize: 10, color: "black" }
                                            return <th style={{ textTransform: "uppercase" }} key={`rang-${irango}`}>
                                                <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                          <Tooltip >Peso volumétrico</Tooltip>
                                                        }
                                                        ><div><b style={{ fontWeight: "lighter", color: "gray" }}>{desde} <b style={estilo_um} >{unidad}</b> a {hasta}<b style={estilo_um} > {unidad}</b></b></div></OverlayTrigger>
                                            </th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    region.zonas.map((comuna) => {
                                        return <tr key={`re-${irr}-${comuna._id}`} className="p-2">
                                            <th style={{ textTransform: "uppercase" }}>{comuna.titulo}</th>
                                            {
                                                rangos_ordenados.map((rango,irango) => {
                                                    let valor = ''
                                                    const i_tarifa = tarifasCargadas.findIndex(tar => tar.id_geocerca_destino === comuna._id && tar.peso_desde === rango.peso_desde && tar.peso_hasta === rango.peso_hasta)
                                                    if(i_tarifa > -1) valor =  tarifasCargadas[i_tarifa]
                                                    return <th key={`rang-${irango}`}>{valor.tarifabase ? <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                          <Tooltip >Tarifa base de {formatoMoneda(valor.tarifabase)} más {formatoMoneda(valor.tarifa)} por kilovolumen adicional</Tooltip>
                                                        }
                                                        ><b style={{ color: "#c8c7c7", border: "1px solid #c8c7c7", padding: "2px 3px", fontSize:12, borderRadius:3, marginRight: 5 }}>{formatoMoneda(valor.tarifabase)}</b></OverlayTrigger> : false } {formatoMoneda(valor.tarifa)}</th>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        </Card>
                    </div>
                })
            }
        </div>
            
        } catch (error) {
            return <PantallaError mensaje={error.message} />
        }
    }

    const confirmarEliminado = async (id) => {
        setLoadingPrincipal(true)
        return fetch(`${urlapi}/pricing/lotes?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Eliminado")
                return setTimeout(() => {
                    window.location = `/${rutas.clientes.slug}`
                }, 1000);
            }
            return setLoadingPrincipal(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingPrincipal(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleOnSelect = (e) => {
        switch (e) {
            case "cotizar":
                setResize(resize + 1)
                break;
            default:
                break;
        }
    }

    const mostrarServicio = () => {
        try {
            return <Col xs={12} className="mb-2">
                <Card className="p-3">
                <label className="form-control-label d-block mb-0">Servicio</label>
                <h4 className="mb-1">{lote.detalles_servicio.nombre}</h4>
                <span style={{ fontSize:11, color: "gray" }}>ESTE VALOR NO SE PUEDE MODIFICAR</span>
                </Card>
            </Col>
        } catch (error) {
            return <Col xs={12} className="mb-2">
                <Card className="p-3">
                <label className="form-control-label d-block mb-0">Servicio</label>
                <ErrorInterfaz />
                </Card>
            </Col>
        }
    }

    const mostrarCargaAcciones = () => {

        if(tarifasCargadas.length > 0){
            return <div>
                <Button disabled={loadingMaster} size="sm" variant="outline-danger" className="mr-3" onClick={() => solicitarEliminar(id)}><BsTrash /> ELIMINAR LOTE</Button>
                <Button disabled={loadingMaster} size="sm" variant="success" onClick={() => guardarTarifas()}><BsFillCheckCircleFill /> GUARDAR CAMBIOS</Button>
                <div className="mb-3"></div>
                <Tabs defaultActiveKey="tabla" onSelect={handleOnSelect}>
                    <Tab eventKey="tabla" title="TARIFARIO" >
                    {mostrarTarifasCargadas()}
                    </Tab>
                    <Tab eventKey="cotizar" title="COTIZAR">
                            <h4 className="mt-3">Cotizar</h4>
                            <Cotizador geo_zonas={zonas} resize={resize} id_lote={id} />
                    </Tab>
                    <Tab eventKey="archivos" title="ARCHIVOS">
                            <div className="mt-3"><MediosBox id_destino={id} /></div>
                    </Tab>
                </Tabs>
                
            </div>
        }

        return <div>
                    <label className="form-control-label d-block">Subir archivo excel</label>
                    {/*<input  
                            type="file" 
                            name="file" 
                            id="file" 
                            className="form-control"
                            onChange={handleInputChange} 
                            placeholder="Archivo de excel" 
                    />*/}
                    {mostrarErrores()}
        </div>
    }

    if(loadingPrincipal) return <PantallaCargando />

    return <div>
        <h4>Resumen de tarifario</h4>
        <Row>
            {mostrarServicio()}
            <Col xs={8} className="mb-2">
                <label className="form-control-label d-block">Título del lote</label>
                <input className="form-control" name="nombre" defaultValue={lote.nombre} onChange={handleChangeLote} />
            </Col>
            <Col xs={4} className="mb-2">
                <label className="form-control-label d-block">Fecha de cacucidad (Opcional))</label>
                <input className="form-control" type="date" name="fecha_caducidad" onChange={handleChangeLote} />
            </Col>
            <Col md={12} className="mb-2">
            <Row><Col md={12} className="mb-3"><VisualizarRangos rangos={rangos} /></Col></Row>
            {mostrarCargaAcciones()}
            </Col>
        </Row>
        <CargandoFlotante visualizar={loadingMaster} mensaje={mensajeLoading} procesando={loadingOperaciones} procesado={operacionesListas}  />
    </div>
}

export default DetalleLoteTarifa