import { Button, Card, Modal } from "react-bootstrap"
import { useState } from "react"
import { FaBox } from "react-icons/fa"
import Select from 'react-select'

const ModalAgregarOpciones = (props) => {
    const {
        typeView,
        title
    } = props
    const titulo = title ? title : "ASIGNAR ÓRDENES"
    const [ showModal, setShowModal ] = useState(false)
    const [ manifiesto, setManifiesto ] = useState(null)
    const [ selected, setSelected ] = useState(null)
    const options = [
        { value: 'array_position', label: 'Capturar primer objeto de un array' },
        { value: 'obtener_clave', label : "Capturar registro dentro de una propiedad del objeto" }
    ]

    const handleClose = () => {
        return setShowModal(false)
    }
    

    const agregar = () => {
        if(props.onSelect) props.onSelect(selected)
        setShowModal(false)
    }

    const seleccionar = (e) => {
        setSelected(e)
    }

    const handleChange = (e) => seleccionar(e) 

    return <div>          
    <Button variant="light" size="sm" onClick={() => setShowModal(true)}>{titulo}</Button>            
    <Modal show={showModal} size="lg" onHide={handleClose} centered >
        <Modal.Header closeButton>
        <Modal.Title><b>Funciones en cola</b></Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-0'>
            <Select placeholder="Seleccionar la función" isClearable options={options} onChange={handleChange} />
            {
                selected ? <Button variant="primary" size="sm" className="w-100 mt-3" onClick={() => agregar()} >AGREGAR</Button> : false
            }
        </Modal.Body>
    </Modal>
</div>
}

export default ModalAgregarOpciones