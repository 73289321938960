import { useEffect, useRef, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import Select from 'react-select';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SelectorZonasEmpresa = (props) => {
    const {
        extraOptions,
        rut_proveedor,
        codigo_proveedor,
        defaultValue
    } = props
    const opciones_default = extraOptions ? extraOptions : []
    const [ estados, setEstados ] = useState([])
    const [ estadosCarga, setEstadosCarga ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const isMulti = typeof props.isMulti !== "undefined" ? true : false
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const titulo = props.titulo ? props.titulo : 'Campo'
    const full_payload = typeof props.full_payload !== "undefined" ? true : false
    const refSelect = useRef(null)

    const handleChangeSelectEstado = (e) => {
        if(isMulti === true){
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            if(props.onChange) props.onChange(opciones)
            return setFiltroEstado(opciones)
        } else {
            let data = e
            if(full_payload === true){
                const i = estadosCarga.findIndex(es => es._id === e.value)
                if(i > -1) data = estadosCarga[i]
            }
            if(props.onChange) props.onChange(data)
            return e
        }
    }

    const obtenerEstados = () => {
        let url_consulta = `${urlapi}/geodata/zonas/empresa/list/select`
        if(rut_proveedor){
            url_consulta = `${url_consulta}?rut=${rut_proveedor}`
        } else if(codigo_proveedor){
            url_consulta = `${url_consulta}?codigo=${codigo_proveedor}`
        }
        return fetch(url_consulta,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){

            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data) !== false){
                setEstadosCarga(data)
                const items = [...opciones_default, ...data.map(e => ({ value: e._id, label: e.titulo }))]
                setEstados([...[], ...items])
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    useEffect(() => {
        obtenerEstados()
    }, [])

    let default_value = ""
    if(defaultValue){
        const i = estados.findIndex(e => e.value === defaultValue)
        if(i > -1 ){
            if(refSelect){
                console.log(refSelect.current)
                refSelect.current.setValue(estados[i])
            }
        }
    }

    return <div className="mb-3">
        <label className='d-block form-control-label' style={{fontWeight:700}}>{titulo}</label>
                    <Select 
                        ref={refSelect}
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectEstado}
                        isLoading={loadingEstados}
                        loadingMessage={() => "Cargando datos"}
                        isMulti={isMulti}
                        options={estados}
                        defaultValue={default_value}
                        placeholder="Selecciona un valor"
                        noOptionsMessage={()=>'Sin opciones'}
                        className="shadow-sm"
                        />
    </div>
}

export default SelectorZonasEmpresa