import { useEffect, useState } from 'react'
import { Row, Col, Button, Card, Spinner, Modal, Pagination, Form, Table, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../lib/helpers/dates';
import { esquema_productos } from '../../lib/esquemas';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import { cerrarSesion } from '../../redux/actions/session';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { AiOutlineDelete } from 'react-icons/ai';
import { AiFillPlusCircle } from "react-icons/ai";
import { Ir } from 'react-flags-select';
import { fechaATexto } from '../../lib/helpers/helpers';
import { url_images } from '../../lib/global/data';

const ModalOrdenesEditar = (props) => {
    const {
        isMulti,
        title
    } = props
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const [proveedor, setProveedor ] = useState(props.proveedor ? props.proveedor : false)
    const dispatch = useDispatch()
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema_productos)
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ procesados, setProcesados ] = useState(0)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ conductorSeleccionado, setConductorSeleccinoado ] = useState(false)
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ duplicados, setDuplicados] = useState([])
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ showModal, setShowModal ] = useState(false)
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const session = useSelector(state => state.miusuario)
    const [ omitirEstados, setOmitirEstados ] = useState(false)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const [ pedidos, setPedidos ] = useState([])
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    const trash = props.trash ? props.trash : false
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const token = session.tokenSession
    const [seleccionado, setSeleccionado ] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [loadingSeleccionados, setLoadingSeleccionados] = useState(false)
    const [seleccionados, setSeleccionados] = useState([])
    const titulo = title ? title : "AGREGAR PEDIDOS"
    


    const obtenerProveedores = async (page, query)=>{
        setLoadingModal(true)
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log("RES:", res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setPedidos(res.datos)
                setTotal(res.total)
            }
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
    }

    useEffect(() => {
        obtenerProveedores(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitPalabra = (e) => {
       
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { $text: { $search: parametroBusqueda }}
        setCondicionBusqueda(condicion)
        setPagina(1)
        setLoadingModal(false)
        return obtenerProveedores(1, condicion)
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const handleClose = () => {
        return setShowModal(false)
    }

    const agregarPedidosARecoleccion = (seleccionados) => {
        if(props.onSelect) props.onSelect(seleccionados)
        setSeleccionados([])
        return setShowModal(false)
        console.log("Ordenes:", seleccionados)
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/recolecciones/pedido`,{
            method:'POST',
            body: JSON.stringify(seleccionados),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                setLoadingCreacion(false)
                return  setShowModal(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                setLoadingCreacion(false)
                return  setShowModal(false)
            } else if(res){
                 if(props.onClickOrdenRecoleccion) props.onClickOrdenRecoleccion(seleccionados)
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
                setSeleccionados([])
            }
            toast.success(`Agregado exitosamente`)
        
            setLoadingCreacion(false)
            return  setShowModal(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingCreacion(false)
            return  setShowModal(false)
        })
    }
    

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }


    const anadirPedidos = (p, ir) => {
        if(isMulti === false) {
            setShowModal(false)
            if(props.onSelect) props.onSelect(p)
            return
        }
        if(setSeleccionado) setSeleccionado(false)
        setLoadingSeleccionados(true)
        const { pedido, _id } = p
        const existe = seleccionados.filter(p => p.pedido === pedido);
        console.log("existe", existe)
      console.log("seleccionados", seleccionados)
      if(existe.length > 0){
       return toast.error("Ya seleccionaste este pedido")
    } else {       
        setLoadingSeleccionados(false)       
            return setSeleccionados(prev => {
                let actual = prev
                actual.push({id_pedido: _id, pedido: pedido, id_recoleccion: proveedor._id})
                return ([...actual])
            })
        }  
        
    }

    
    const eliminar = (ir) => {  
        return setSeleccionados(prev => {
            let actual = prev
            actual.splice(ir, 1)
            return ([...actual])
        })
    }

    
    const mostrarSeleccionados = () => {
        if(seleccionados.length < 1) return <div>
            <p style={{ fontSize: 16, fontWeight: 'bold' }} className='text-danger text-center m-0'><i className="fa-solid fa-triangle-exclamation text-warning"></i> No has seleccionado ningun pedido</p>
            <p style={{ fontSize: 12 }} className='text-primary text-center m-0'>Haz click en <i className="fa-solid fa-circle-plus"></i> para agregar pedidos</p>
            <div className="text-center m-0">
                {/* <img src="File selection.gif" alt="File Selection" /> */}
                <img src={`${url_images}/animations/File selection.gif`} alt="Order Selection" className='img-fluid w-75' />
            </div>
        </div>       
        return <div className='text-primary'>
             <h6 className='text-primary' style={{fontWeight:700}}>Pedidos Seleccionados: <b className='text-success'>{seleccionados.length}</b></h6> 
                 {
                  seleccionados.map((p, ir) => {                    
                 return      <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 mb-1 shadow-sm ${seleccionado === true ? 'text-white' : 'text-dark'}`}>
                         <div >
                         <tr key={p._id} style={{display: 'flex', justifyContent: 'space-between', paddingBottom: 0 }}>
                         <th>
                         <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom:0 }} className='text-success'><i className="fa-solid fa-circle-check"></i> has Seleccionado el pedido <b>{p.pedido}</b></p>
                         </th>
                         <th>
                         <AiOutlineDelete style={{fontSize: 32, fontWeight: 'bold'}} size={18} onClick={()=>eliminar(ir)} />
                         </th>
                         </tr>
                         </div>
                     </Card>
                       })
                     } 
                <hr />           
             <label className='form-control-label d-block' style={{fontWeight:700}}>Fecha de inicio</label>
             <input className='form-control mb-3 shadow-sm' type="date" />

             <label className='form-control-label d-block' style={{fontWeight:700}}>Hora de inicio</label>
             <input className='form-control mb-3 shadow-sm' type="time" />
             
                <hr />
             <div style={{display: 'flex', justifyContent: 'space-between'}}>
                {
                loadingCreacion ? <Spinner animation='border' /> :
                <Button size='sm mb-3' md={12} variant='light' className='w-100 shadow-sm' onClick={() => agregarPedidosARecoleccion(seleccionados)}> <BiSolidChevronRight size={20} />  <b> Agregar pedidos seleccionados  </b></Button> }
            </div>
        </div>
       
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const paginacion = (ciclo, total, registros) => {

        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Row>
                <Col xs={12}><h5 className='m-1' style={{ fontSize:13 }}>{(pedidos.length * pagina) - pedidos.length + 1} - {pedidos.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5></Col>
                <Col xs={12}>
                <Pagination size='sm' className='mb-3' style={{ overflowY: 'hidden' }}>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
                </Col>
            </Row>
        </div>
    }

    const showEstadoEntregaHTML = (estadoentrega, estado_entrega, sub_estado_entrega) => {
        if (estado_entrega) {
          if (typeof estado_entrega === 'object') {
            if (estado_entrega.titulo) {
              return <div>
                <span className="spanstatus mr-2" style={{ backgroundColor: estado_entrega.color ? estado_entrega.color : '#babbbb', padding: "1px 10px", textTransform: "uppercase", borderRadius: 5, color: "white" }}>{estado_entrega.titulo}</span>
                {mostrarSubEstadoHTML(sub_estado_entrega)}
              </div>
            }
          }
        }
        return <span className="spanstatus" style={{ backgroundColor: statusColor(estadoentrega) }}>{statusString(estadoentrega)}</span>
      }

    const mostrarSubEstadoHTML = (sub_estado_entrega) => {
    if (sub_estado_entrega) {
        if (typeof sub_estado_entrega === 'object') {
        if (sub_estado_entrega.titulo) {
            return <span className="spanstatus mr-2" style={{ textTransform: "uppercase", backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2', padding: "1px 10px", borderRadius: 5, color: "white" }}>{sub_estado_entrega.titulo}</span>
        }
        }
    }
    return false
    }

    const statusString = (status) => {

    let cadena = "";

    if (status) {
        if (status === 1) {
        cadena = "Preparado";
        } else if (status === 2) {
        cadena = "Leído en el móvil";
        } else if (status === 3) {
        cadena = "Retirado en origen";
        } else if (status === 4) {
        cadena = "En reparto";
        } else if (status === 5) {
        cadena = "Entregado";
        } else if (status === 6) {
        cadena = "Entrega Confirmada";
        } else if (status === 7) {
        cadena = "Entrega Confirmada";
        } else if (status === 9) {
        cadena = "Retiro de bodega";
        } else if (status === 18) {
        cadena = "En CD Delpa";
        } else if (status === 13) {
        cadena = "No entregado";
        } else {
        cadena = "Sin datos";
        }
    } else {
        cadena = "Sin datos";
    }
    return cadena;
    }

    const statusColor = (level) => {

    let color = "#dbdbde";

    if (level === 1) {
        color = "#cad004";
    } else if (level === 2) {
        color = "#d09d04";
    } else if (level === 3) {
        color = "#d03e04";
    } else if (level === 4) {
        color = "#5e72e4";
    } else if (level === 5) {
        color = "#64b612";
    } else if (level === 6) {
        color = "#64b612";
    } else if (level === 7) {
        color = "#64b612";
    } else if (level === 9) {
        color = "#ff71a6";
    } else if (level === 18) {
        color = "#a31be1";
    } else if (level === 13) {
        color = "#000";
    }

    return color;

    }



    const listadoRecolecciones = () => {
    if(loadingModal) return cargandoScreen()

    const tamano = isMulti === false ? 12 : 6
    return <div>
            <Row>

            <Col md={tamano}>
            <form onSubmit={handleSubmitPalabra}>
                <input className='mb-3 form-control' placeholder='BUSCAR' onChange={handleChangePalabra} />
            </form>
            {/* <h6><b>*Total: {total}</b>, se muestran <b>{pedidos.length}</b> de la página <b>{pagina}</b>.</h6> */}
            <div className='mb-3'>
                {paginacion(data.pagina, total, registros)}
            </div>
            {    
                pedidos.map((p, ir) => {   
                    return  <Card onClick={() => anadirPedidos(p, ir)} bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 mb-3 shadow-sm hover ${seleccionado === true ? 'text-white' : 'text-primary'}`} >
                        <div >
                        <tr key={p._id} style={{display: 'flex', justifyContent: 'space-between'}}>
                        <th>
                            <h6 style={{ fontSize: 17 }} className="mb-1">Pedido # <b>{p.pedido}</b></h6>
                            {showEstadoEntregaHTML(p.estado_entrega, p.estado_entrega, p.sub_estado_entrega)}
                            <p style={{ fontSize: 12 }} className="mb-1">Creado el {fechaATexto(p.createdAt)}</p>
                            <p style={{ fontSize: 12 }} className="mb-1"><b>Tipo de servicio:</b> {p.tipo_servicio}</p>
                            <p style={{ fontSize: 12 }} className="mb-1"><b>Destino:</b> {p.orden.billing.address_1}</p>
                        </th>
                        <th>
                        <AiFillPlusCircle style={{fontSize: 24, marginBottom: 2}} />
                        </th>
                        </tr>
                        </div>
                        </Card>
                })
            }
                    {paginacion(data.pagina, total, registros)}
            </Col>
            { isMulti === false ? false :<Col md={6}>{mostrarSeleccionados()}</Col> }
            
            </Row>
            </div>
    }


    const mostrarRecolecciones = () => {
            return <div>          
        <Button size='sm' md={12} variant='light' onClick={() => setShowModal(true)}> <BiSolidChevronRight size={20} />  <b>{titulo}</b></Button>            
            <Modal show={showModal} size="lg" onHide={handleClose} centered >
                <Modal.Header closeButton>
                <Modal.Title><b className='text-primary'>Pedidos</b></Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-0'>              
                    {listadoRecolecciones()}
                </Modal.Body>
            </Modal>
        </div>
    }


    return <div>
        {mostrarRecolecciones()}
        </div>

}

export default ModalOrdenesEditar