const { url_images } = require("../../../lib/global/data")

const pasos = [
    {
        title: "Abre la aplicación en tu teléfono",
        description: "Ubicate en la pantalla de inicio de sesión de la aplicación, debe verse como esta imagen.",
        img: `${url_images}/instrucciones/AppConductor_header.png`,
        link_download: [{ platform: "android", url: "https://play.google.com/store/apps/details?id=com.delpa.lastmileconductor"}]
    },
    {
        title: "¡CONFIGURAR! Concede acceso a tu ubicación",
        description: "Presiona a continuación en configurar",
        img: `${url_images}/appconductor/2.jpeg`
    },
    {
        title: "Mientras la app esté en uso",
        description: "Primero necesitaremos este permiso para acceder a la ubicación en primer plano, justo después te pediremos acceder a la ubicación en segundo plano",
        img: `${url_images}/appconductor/3.jpeg`
    },
    {
        title: "¡CONFIGURAR! Concede acceso para el rastreo en tiempo real",
        description: "Este es un segundo permiso de ubicación para conceder acceso al dispositivo en todo momento",
        img: `${url_images}/appconductor/4.jpeg`
    },
    {
        title: "Permitir todo el tiempo",
        description: "Este es el último permiso que necesitaremos sobre la ubicación de tu dispositivo",
        img: `${url_images}/appconductor/5.jpeg`
    },
]

module.exports = {
    pasos
}