import { Card } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 shadow ${seleccionado === true ? 'text-white' : 'text-primary'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <h6 style={{ fontWeight:700, textTransform:'uppercase'}} className="mb-1">{proveedor.nombre}</h6>
    <h6 style={{ fontSize: 11 }} className="mb-0">Creado <b>{ fechaATexto(proveedor.createdAt)}</b></h6>
</Card>
}

export default BoxList