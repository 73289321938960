const tituloPorTipoNotificacion = (titulo) => {
    switch (titulo) {
        case "info":
            return "Alerta informativa"
        case "warning":
            return "Alerta de advertencia"
        case "danger":
            return "Alerta crítica"
        default:
            return titulo
    }
}

module.exports = {
    tituloPorTipoNotificacion
}