import { useState, useEffect } from "react"
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip, Card, Badge } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";
import { IoMdCloseCircle } from "react-icons/io";
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import { FaTrashAlt } from "react-icons/fa";
import { GoAlert } from "react-icons/go";

const DetailFull = (props) => {
    const [loading, setLoading] = useState(false)
    const [ loadingAgregar, setLoadingAgregar ] = useState(false)
    const [ loadingEliminar, setLoadingEliminar ] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()
    const [adicional, setAdicional] = useState([])
    const [id, setId] = useState("")
    const [alert, setAlert] = useState(false)
    const default_days = [
        {dia: "lunes", active: true},
        {dia: "martes", active: true},
        {dia: "miercoles", active: true},
        {dia: "jueves", active: true},
        {dia: "viernes", active: true},
        {dia: "sabado", active: false},
        {dia: "domingo", active: false}
    ]
    const [dias, setDias] = useState (default_days)


    const obtenerHorarioLogistica = async ()=>{
        setLoading(true)
        return fetch(`${data.urlapi}/horario/logistica/detail`,{
            method:'POST',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setDias(res.detalles.habitual)
                setAdicional(res.detalles.adicional)
                setId(res._id)
                return setLoading(false)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }



    useEffect(() => {
        obtenerHorarioLogistica(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps



    const crearNuevo = async () => {
        if(adicional.length > 0){
            let buscarVacios = adicional.filter(elem => (!elem.fecha || !elem.accion))
            console.log(buscarVacios)
            if(buscarVacios.length > 0) return toast.error("Debes colocar la fecha y señalar si esta habilitada o deshabilitada, o eliminar las vacias")
        }
        setLoading(true)
        return fetch(`${data.urlapi}/horario/logistica`, {
            method: 'POST',
            body: JSON.stringify({detalles: {habitual: dias, adicional}}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){                   
                    return toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){                   
                     toast.error(pros.errorMessage)
                     return setLoading(false)
              } else if(pros._id){
                  toast.success(`Realizado exitosamente`)
                  setDias(pros.detalles.habitual)
                  setAdicional(pros.detalles.adicional)
                  setId(pros._id)
                  setAlert(false)
                    return setLoading(false)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const eliminar = (p, i) => {
        setDias(prev => {
            let actual = [...prev]
            if(actual[i].active === true){actual[i].active = false}
            return [...[], ...actual]
        })  
        setAlert(true)   
    }

   const eliminarAdicional = (i) => {
    setLoadingEliminar(true)  
    adicional.splice(i, 1)
    setAdicional(prev => [...[], ...adicional])
    setAlert(true) 
    setTimeout(() => {
        setLoadingEliminar(false)          
    }, 1000);       
    }

    const anadirRelacion = (p, i) =>{
        setDias(prev => {
            let actual = [...prev]
            if(actual[i].active === false){actual[i].active = true}
            return [...[], ...actual]
        })  
        setAlert(true)  
    }

    const crearNuevoAdicional = () => { 
      setAlert(true)
      return setAdicional(prev =>{
        let actual = [...prev]
        actual.push({fecha: "", accion: ""})
        return [...[], ...actual]})
    }

    const handleChangeSelect = (key, val, i) => {
        adicional[i][key] = val
        setAdicional(adicional)
        setAlert(true)
    }

    const handleChangeFecha = (key, val, i) => {
        adicional[i][key] = val
        setAdicional(adicional)
        setAlert(true)
    }

    const alerta = () => {
        if(!alert) return false
        return <p style={{color: "red"}}><GoAlert /> No has guardado cambios !</p>
    }

    const listadoDias = () => {
        if(loading) return <div><Spinner animation="border" /> </div>
        return <div className='ml-0' style={{ opacity: loadingAgregar ? 0.4 : 1 }}>
                     {
                        dias.map((p, i) => {                    
                        return  <Badge className='hover mr-3 mb-2' style={{ background: "#ebebeb", padding: '8px 17px'}} >
                            <h6 style={{ fontSize: 13, textTransform: "uppercase" }} className="m-0"><b className='mr-1' defaultValue={p.dia}>{p.dia}</b></h6>
                            {p.active ? <FaCheckCircle style={{ fontSize: 15, marginBottom: 2, color: "green"}} onClick={() => eliminar(p, i)}/> :
                            <FaRegCircle style={{fontSize: 15, marginBottom: 2, color:'black'}} onClick={() => anadirRelacion(p, i)} />}
                            </Badge>
                            })
                    }
                </div>
        }

    const mostrarAdicional = () => {
        if(loadingEliminar) return <div><Spinner animation="border" /> </div>
        return <div>{adicional ? adicional.map((p, i) => {                    
            return  <Badge className='hover mr-3 mt-2 p-2' style={{ background: "#ebebeb", padding: '4px 2px'   }} >
                <div style={{display: 'flex', justifyContent: "space-between"}}>
                 <label className='form-control-label'  style={{ fontSize: 14, marginBottom: 0, paddingTop: 4}}>Fecha </label>
                 <IoMdCloseCircle style={{ fontSize: 22, marginBottom: 6, color: "red"}} onClick={() => eliminarAdicional(i)}/>
                </div>
            <input style={{marginBottom: 8}} name="fecha" type="date" className='form-control' defaultValue={p.fecha} onChange={(e) => handleChangeFecha('fecha', e.target.value, i )} />
            <select name="active" className='form-control' defaultValue={p.accion} onChange={(e) => handleChangeSelect('accion', e.target.value, i )}>
                <option value="">Selecciona</option>
                <option value="habilitar">Habilitar</option>
                <option value="deshabilitar">Deshabilitar</option>
            </select>
                </Badge>
                }) : false}</div>
    }

    const mostrarEliminar = () => {
        if(!id) return false
        return <Button size="sm" variant="danger" style={{marginLeft: "10px"}} disabled={loading} onClick={()=>solicitarEliminar(id)} >ELIMINAR <FaTrashAlt /></Button>
    }

    const formulario = () => {
        if(loading) return <div><Spinner animation="border" /> </div>
        return <div style={{ opacity: loadingAgregar ? 0.4 : 1 }}>
            <Row>
                <Col md={12} >
                    <h4>Horario Logistica</h4>
                    <p>Las empresas podran configurar su horario de forma personalizda agregando o quitando dias laborales en del sistema</p>
                </Col>
                <Col className="mb-3">
                    {listadoDias()}  

                    <Button size="sm" className='mt-3 mb-2' variant="success" onClick={()=>crearNuevoAdicional()} >AÑADIR DIA</Button>
                    <br></br>
                    <p><span style={{color: "red"}}>*</span> Puedes habiltar o deshabilitar dias especificos.</p>

                    {mostrarAdicional()}

                </Col> 

            
                <Col md={12}>
                {alerta()}             
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >GUARDAR CAMBIOS</Button>
                    }

                    {mostrarEliminar()}
                    
                    
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
         setLoading(true)
        return fetch(`${data.urlapi}/horario/logistica?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                     return setLoading(false)
            } else if(res._id){
                setDias(default_days)
                setAdicional([])
                setId("")
                setAlert(false)
                    toast.success("Realizado Exitosamente")
                    return setLoading(false)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    return <div>
        {formulario()}
    </div>
}

export default DetailFull