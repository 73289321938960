import { Card } from "react-bootstrap"

const BoxList = (props) => {
    const conductor = props.conductor ? props.conductor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!conductor) return false

    const clickConductor = (id) => {
        if(props.onClickConductor) return props.onClickConductor(id)
        return false
    }

    return <Card className={`p-3 hover shadow-sm`} style={{backgroundColor: seleccionado === true ? 'rgba(27, 54, 101, 0.8)' : 'white', color: seleccionado === true ? 'white' : 'rgba(27, 54, 101, 1)'}} onClick={() => clickConductor(conductor._id)}>
    {/* { seleccionado === true ? style={backgroundColor: 'blue'} : style={backgroundColor: 'blue'} } */}
    <p style={{ fontWeight: 900, fontSize: 14}} className="mb-0">{conductor.modelo ? conductor.modelo.toUpperCase() : '' } {conductor.ano}</p>
    <p className="mb-0" style={{ fontWeight:700, fontSize: 12 }}>{conductor.patente ? conductor.patente.toUpperCase() : "Sin información"}</p>
    <p style={{ fontWeight:700, textTransform: 'uppercase', fontSize: 12 }} className="mb-0"> {conductor.nombres} {conductor.apellidos}</p>
    </Card>

}

export default BoxList