import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Stepper from "awesome-react-stepper";
import { url_images } from "../../../lib/global/data";
import { pasos } from "./data";
import { IoLogoAndroid } from "react-icons/io";
import { FaAppStoreIos } from "react-icons/fa";

const borderStyle = {
  border: "solid",
  borderColor: "red",
  borderWidth: 5,
};

const InstruccionConexion = () => {
  const [geocerca, setGeocerca] = useState({
    tipos_vehiculo: [],
    conductores: [],
  });
  
  
  const componentesData = (pos, paso) => {
    if(pos === 1) return <div className="shadow-sm">
      <div>
                              { paso.img ? <img src={paso.img} style={{ width: "100%" }} className="img-fluid" alt="instrucciones" /> : false }
                            </div>
                            <div
                              className="d-block bg-white px-2"
                              style={borderStyle}
                            >
                              <p
                                className="py-0 my-0"
                                style={{ fontSize: 10, fontWeight: 700 }}
                              >
                                <img
                                  src={`${url_images}/instrucciones/flag-waving-spain.png`}
                                  style={{ height: 9 }}
                                />
                                <b> Idioma</b>
                              </p>
                              <Form.Label
                                htmlFor="basic-url"
                                className="my-0"
                                style={{ fontSize: 10, fontWeight: 700 }}
                              >
                                Teléfono
                              </Form.Label>
                              <InputGroup size="sm" className="my-0">
                                <Form.Control
                                  readOnly
                                  id="basic-url"
                                  aria-label="Small"
                                  disabled
                                  aria-describedby="inputGroup-sizing-sm"
                                  placeholder="Teléfono sin el +"
                                  className="h-50 bg-white"
                                />
                              </InputGroup>
                              <Form.Label
                                htmlFor="basic-url"
                                className="my-0"
                                style={{ fontSize: 10, fontWeight: 700 }}
                              >
                                Código
                              </Form.Label>
                              <InputGroup size="sm" className="mb-1">
                                <Form.Control
                                  readOnly
                                  id="basic-url"
                                  aria-label="Small"
                                  disabled
                                  aria-describedby="inputGroup-sizing-sm"
                                  placeholder="Código de acceso"
                                  className="h-50 bg-white"
                                />
                              </InputGroup>
                              <Button
                                className="my-1 w-100 p-1"
                                variant="primary"
                                style={{ fontSize: 12 }}
                              >
                                <b>INICIAR SESION</b>
                              </Button>
                            </div>
                            <div>
                              <img
                                src={`${url_images}/instrucciones/AppConductor_tablero.png`}
                                className="img-fluid rounded-end"
                                style={{ width: "100%" }}
                                alt="instrucciones"
                              />
                            </div>
    </div>

    return <div>
      {paso.img ? <img className="shadow-sm" style={{ width: "100%", }} src={paso.img} /> : false }
    </div>
  }


  const contenido = () => {
    return <div>
            <Stepper
              stroke={2}
              strokeColor="#1B3665"
              fillStroke="#1B3665"
              activeColor="#1B3665"
              btnPos="center"
              // activeProgressBorder="2px solid #1B3665"
              continueBtn={<Button className="shadow-sm">Siguiente</Button>}
              backBtn={<Button className="shadow-sm">Regresar</Button>}
              submitBtn
            >
              {
                pasos.map((paso,ip) => {
                  return <div key={`paso-${ip}`}>
                  <Col className="d-flex">
                      <Row className="g-0">
                      <Col md={4} className="text-center p-4">
                            {componentesData((ip+1), paso)}
                        </Col>
                        <Col md={8}>
                          <div style={{ height: "100%", display: "table" }}>
                        <div className="card-body" style={{ verticalAlign: "middle", display: "table-cell" }}>
                          <h1
                            className="text-danger h-100"
                            style={{
                              backgroundColor: "",
                              fontWeight: 900,
                              fontSize: 50,
                            }}
                          >{(ip+1)}</h1>
                            <h5
                              className="card-title"
                              style={{ fontWeight: 900 }}
                            >
                              {paso.title}
                            </h5>
                            <p className="card-text" style={{ fontWeight: 700 }}>{paso.description}</p>
                            {
                              paso.link_download ? paso.link_download.map((ob)=> {
                                return <div>
                                  <a href={ob.url} target="_blank">
                                    <Button style={{ background: "black" }} >{ ob.platform === "android" ? <IoLogoAndroid size={25} style={{ verticalAlign: "middle" }}  /> : <FaAppStoreIos size={25} style={{ verticalAlign: "middle" }}  /> } DESCARGAR</Button>
                                  </a>
                                </div>
                              }) : false
                            }
                          </div>
                          </div>
                        </Col>
                        
                      </Row>
                  </Col>
                </div>
                })
              }
              </Stepper>
    </div>
  }

  return (
    <div>
      <div className='' style={{ display: "flex", minHeight: "100vh", height: "100%", alignItems: "center", justifyContent: "center" }}>
            
      <Row className="justify-content-md-center">
        <Col md={12} className="p-3 text-center"><img style={{ display:"block", margin: "0 auto", maxWidth: 150 }} src={`${url_images}/logoColor.svg`} /></Col>
            <Col md={8} className="centerv row justify-content-center border px-5 py-4 shadow">
      <h4 className="text-primary p-3 d-block" style={{ fontWeight: 900 }}><i className="fa-solid fa-mobile-screen-button"></i> ¿Cómo utilizar el app de conductor?</h4>
     {contenido()}
            </Col>
      </Row>
      </div>
    </div>
  );
};

export default InstruccionConexion;
