import { PiWarningCircleBold } from "react-icons/pi"
import { formatoMoneda } from "../../../lib/helpers/main"
import { Accordion, Card, Col, Modal, Row, Tab, Table, Tabs } from "react-bootstrap"
import { Bar } from "react-chartjs-2"
import { useEffect, useState } from "react"
import { CiMoneyCheck1 } from "react-icons/ci"
import { FaRegFileLines } from "react-icons/fa6"
import BoxOrdenImportacion from "./box_orden"
import { FaChevronDown } from "react-icons/fa"
import { calcularPesoVolumetrico } from "../../../lib/helpers/pedidos/pedidos"

const TablaRegistros = (props) => {
    const {
        datos,
        typeView,
        typeForm
    } = props
    const [ registros, setRegistros ] = useState(datos)
    const [ tipoVista, setTipoVista ] = useState('simple')
    const [ show, setShow ] = useState(false)
    
    useEffect(() => {
        setRegistros(datos)
    }, [ datos ])

    const onChangeOrden = (data, i) => {
        if(props.onChange) props.onChange(data,i)
        setRegistros(prev => {
            let actual = [...prev]
            actual[i] = data
            return [...[], ...actual]
        })
    }
    const handleChangeTipoVista = (e) => {
        return setTipoVista(e.target.value)
    }

    const vistaSimple = () => {
        return <div>
            <Table>
                <thead>
                    <tr>
                        <th>REFERENCIA</th>
                        <th>VEHÍCULO</th>
                        <th>DESTINATARIO</th>
                        <th>DIRECCION</th>
                        <th>ITEMS</th>
                        <th>ORIGEN</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        registros.map((reg,ire) => {
                            return <tr key={`tabla-${ire}`}>
                            <th>{reg.referencia}</th>
                            <th>{reg.vehiculo}</th>
                            <th>{reg.nombre_contacto}</th>
                            <th>{reg.direccion}</th>
                            <th>{reg.productos.length}</th>
                            <th>{reg.origen_direccion_excel}</th>
                        </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }


    const vistaAvanzada = () => {
        return <div>
            <Accordion >
            {
                registros.map((order,io) => {
                    return <Card className="p-0" key={`or-${io}`}>
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`order-${io}`}>
                            <div className="pl-3 pr-3 pb-0 hover">
                                <Row>
                                    <Col><p className='mb-0' style={{ fontSize: 12 }}><FaChevronDown /> ORDEN <b>{order.referencia}</b></p></Col>
                                    <Col><p className='mb-0' style={{ fontSize: 12 }}>PRODUCTOS <b style={{ fontWeight: "bold" }}>{order.productos.length}</b>  </p></Col>
                                    <Col><p className='mb-0' style={{ fontSize: 12 }}>VOL M3 <b style={{ fontWeight: "bold" }}>{(calcularPesoVolumetrico(order.productos).default_vol).toFixed(4)}</b>  </p></Col>
                                    <Col><p className='mb-0' style={{ fontSize: 12 }}>PESO VOL. <b style={{ fontWeight: "bold" }}>{formatoMoneda(calcularPesoVolumetrico(order.productos).default_weight)}</b></p></Col>
                                </Row>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`order-${io}`}>
                            <Card.Body style={{ backgroundColor: '#f1f1f1' }}>
                                <BoxOrdenImportacion order={order} onChange={(data) => onChangeOrden(data,io)} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                        
                })
            }
            </Accordion>
        </div>
    }

    const mostrarSegunTipoVista = () => {
        switch (tipoVista) {
            case "simple":
                return vistaSimple()
            case "avanzado":
                return vistaAvanzada()
            default:
                return false
        }
    }

    const onChangeOrigen = (data) => {
        if(props.onChangeOrigen) props.onChangeOrigen(data)
    }

    if(typeView === "simple") return <div>
        {
            registros.map((order,io) => {
                return <div key={`order-${io}`}>
                    <BoxOrdenImportacion onChangeOrigen={(data) => onChangeOrigen(data)} typeForm={typeForm} order={order} onChange={(data) => onChangeOrden(data,io)} />
                </div>
            })
        }
    </div>

    return <div>
        <Modal show={show} onHide={() => setShow(false)} centered size="lg" >
            <div className="p-3">
                <label className="form-control-label d-block">Tipo de visualización</label>
            <select className="form-control mb-3" onChange={handleChangeTipoVista} value={tipoVista}>
                <option value="simple">Vista Simple</option>
                <option value="avanzado">Vista Avanzada</option>
            </select>
            {mostrarSegunTipoVista()}    
            </div>
         </Modal> 
         <Card className="p-2 text-center text-white hover" bg="success"  onClick={() => setShow(true)} ><h6 className="p-0 m-0 uppercase"><FaRegFileLines /> CARGASTE {registros.length} ÓRDENES, <b className="bold">TOCA PARA VER</b></h6></Card>
    </div>
}

export default TablaRegistros