import { Tab, Tabs } from "react-bootstrap"
import { useSelector } from "react-redux"
import { estilo_last_mile } from "../../lib/global/styles"
import PersonalizarTracking from "./personalizar_tracking"
import PersonalizarEmailEstado from "./personalizar_email_estado"
import PersonalizarEtiqueta from "./personalizar_etiqueta"

const Personalizar = (props) => {
    const {
        filtrarItems
    } = props
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    let defaultActiveKey = 'tracking'
    if(filtrarItems) if(Array.isArray(filtrarItems)) defaultActiveKey = filtrarItems[0]

    const items = [
        {
            name: "tracking",
            component: <Tab eventKey="tracking" title={<div><h6 className="mb-0">Página de tracking</h6></div>} className="mt-3">
                <PersonalizarTracking />
            </Tab>
        },
        {
            name: "email_estado",
            component: <Tab eventKey="email_estado" title={<div><h6 className="mb-0">Email de estado</h6></div>} className="mt-3">
                <PersonalizarEmailEstado />
            </Tab>
        },
        {
            name: "etiqueta",
            component: <Tab eventKey="etiqueta" title={<div><h6 className="mb-0">Etiqueta de bulto</h6></div>} className="mt-3">
                    <PersonalizarEtiqueta />
                </Tab>
        }
    ].filter(e => filtrarItems ? filtrarItems.includes(e.name) : true)

    return <div>
        <h4 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Personalizar</h4>
        <p>Selecciona el módulo que deseas personalizar</p>
        <Tabs defaultActiveKey={defaultActiveKey}>
            {items.map((item, index) => item.component)}
        </Tabs>
    </div>
}

export default Personalizar