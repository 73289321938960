import { useState } from "react"
import { Button, Col, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data, { maps_key } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../../redux/actions/session";
import { nombreDNIPorPais } from "../../../lib/helpers/data/internationa";
import CamposObligatoriosLeyenda from "../../general/campos_obligatorios";
import { PiWarehouseDuotone } from "react-icons/pi";
import AutoComplete from "react-google-autocomplete";
import SelectorDestinatariosContactos from "../destinatarios_contactos/selector";
import CamposPersonalizadosRelacionador from "../../general/campos_personalizados_relacionador";
import SelectorDestinatarios from "../selector";

const DetailFull = (props) => {
    const {
        id_cliente
    } = props
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [clienteSeleccionado, setClienteSeleccionado] = useState("")
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()

    
    const handleChangeCliente = (e) => {
        console.log(e)
        if(e?._id) {
            return setProveedor(prev => {                
                let actual = {...prev}
                actual.id_cliente = e._id
                return actual
            })
            }
        return setClienteSeleccionado(e.value)     
      }

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {

        if(!clienteSeleccionado) return toast.error("Debes seleccionar un cliente")

        const requeridos = [
            { value:'titulo', label: 'Título'},
            { value: "direccion", label: "Dirección" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoading(true)
        if(id_cliente){ nuevoProveedor.id_cliente = id_cliente 
        } else {  nuevoProveedor.id_cliente = clienteSeleccionado }
        return fetch(`${data.urlapi}/destinatarios/bodegas`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/destinatarios/bodegas`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        return setNuevoProveedor(nuevoProveedor)
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nueva bodega <PiWarehouseDuotone /></h4>
                    <p>Puedes administrar las bodegas de tus clientes desde aquí</p>
                    <CamposObligatoriosLeyenda />
                </Col>
                <Col md={12} className="mb-3">
                    <label className="form-control-label d-block">Nombre</label>
                    <input className="form-control" name="titulo" value={nuevoProveedor.titulo} onChange={handleChangeNuevo} />
                </Col>
                <Col md={12} className="mb-3">
                    <label className="form-control-label d-block">Dirección</label>
                    <AutoComplete
                    className="form-control"
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          const geo_data = {
                              lat, 
                              lng,
                              direccion: place.formatted_address,
                              region,
                              comuna,
                              status: true
                          }
                          nuevoProveedor.direccion = place.formatted_address
                          nuevoProveedor.geodata = place
                          nuevoProveedor.location = {
                            type: "Point",
                            coordinates: [
                                geo_data.lng, geo_data.lat
                            ]
                          }
                          
                          return setNuevoProveedor(nuevoProveedor)
                    }}
                    />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Observaciones</label>
                    <input className="form-control" name="observaciones" value={nuevoProveedor.observaciones} onChange={handleChangeNuevo} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Teléfono fijo</label>
                    <input className="form-control" name="telefono" value={nuevoProveedor.telefono} onChange={handleChangeNuevo} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Teléfono móvil</label>
                    <input className="form-control" name="movil" value={nuevoProveedor.movil} onChange={handleChangeNuevo} />
                </Col>
                <Col md={4} className="mb-3">
                <SelectorDestinatarios defaultValue={clienteSeleccionado} onChange={handleChangeCliente} titulo="Cliente" />
                </Col>
                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/destinatarios/bodegas?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleChangeContacto = (e) => {
        const valor = e?.value
        console.log({valor})
        setProveedor(prev => {
            const actual = {...prev}
            actual.contacto_default = valor
            console.log(actual)
            return actual
        
        })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <div>
            <h4 className="mb-0">{proveedor.titulo}</h4>
            {/* <h6>CODIGO {proveedor.codigo}</h6> */}
            {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
            <Row>
                <Col className="mb-3">
            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Esta es la forma en que debes escribirlo en el documento excel de importación o API</Tooltip>
                            }
                            >
                                <h5 style={{ backgroundColor: '#c7c7c7', borderRadius: 5, color: 'white', fontSize: 13, display: 'inline', padding: '3px 5px' }}><b style={{ color:'black' }}>CÓDIGO </b> { proveedor.codigo }</h5>
                          </OverlayTrigger>
        </Col>
        <Col md={12} className="mb-3">
            {/* <h5>CODIGO <b>{proveedor.codigo}</b></h5> */}
            <label className="form-control-label d-block">Nombre o razón social</label>
            <input className="form-control" name="titulo" defaultValue={proveedor.titulo} onChange={handleChange} />
        </Col>
                <Col md={12} className="mb-3">
                    <label className="form-control-label d-block">Dirección</label>
                    <AutoComplete
                    className="form-control"
                    defaultValue={proveedor.direccion}
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          const geo_data = {
                              lat, 
                              lng,
                              direccion: place.formatted_address,
                              region,
                              comuna,
                              status: true
                          }
                          proveedor.direccion = place.formatted_address
                          proveedor.geodata = place
                          proveedor.location = {
                            type: "Point",
                            coordinates: [
                                geo_data.lng, geo_data.lat
                            ]
                          }
                          
                          return setProveedor(proveedor)
                    }}
                    />
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Observaciones</label>
                    <input className="form-control" name="observaciones" value={proveedor.observaciones} onChange={handleChange} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Teléfono fijo</label>
                    <input className="form-control" name="telefono" value={proveedor.telefono} onChange={handleChange} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Teléfono móvil</label>
                    <input className="form-control" name="movil" value={proveedor.movil} onChange={handleChange} />
                </Col>
                <Col md={4} className="mb-3">
                <SelectorDestinatarios defaultValue={proveedor.id_cliente} full_payload={true}  onChange={handleChangeCliente} titulo="Cliente" />
                </Col>
                <Col md={12} className="mb-3">
                    <SelectorDestinatariosContactos defaultValue={proveedor.contacto_default} titulo="Contacto por defecto" onChange={handleChangeContacto} />
                </Col>
        <Col md={12}>
        <CamposPersonalizadosRelacionador id_target={proveedor._id} condicion_default={{ module: "bodega" }}  />
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
        </Row>
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull