import React from 'react';
import FatalErrorMessage from './fatalErrorMessage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      errorMessage: ""
    };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para que la próxima renderización muestre la interfaz de repuesto
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // También puedes registrar el error en un servicio de monitoreo
    console.error("Error capturado por Error Boundary: ", error, errorInfo);
    this.setState({ errorMessage: error.message })
  }

  render() {
    const {  errorMessage } = this.state
    if (this.state.hasError) {
      // Puedes renderizar una interfaz de repuesto para cuando ocurra un error
      return <FatalErrorMessage errorMessage={errorMessage} />
    }

    return this.props.children; 
  }
}

export default ErrorBoundary