import { useState } from "react"
import { Button, Card, Spinner } from "react-bootstrap"
import { urlapi } from "../../lib/backend/data"
import { toast } from "react-toastify"
import { cerrarSesion } from "../../redux/actions/session"
import { useDispatch, useSelector } from "react-redux"
import { FaChevronLeft, FaPlusCircle } from "react-icons/fa"
import { mercado_pago_public_key, url_images } from "../../lib/global/data"
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
initMercadoPago(mercado_pago_public_key);

const AddNewPaymentGateway = (props) => {
    const {
        ammount
    } = props
    const pasarelas = [
        { name: "transbank", description: "Webpay One Click", logo: `${url_images}/payments_methods/oneclick.png` },
        { name: "mercadopago", description: "Mercado Pago", logo: `${url_images}/payments_methods/mercado-pago.png` },
    ]
    const [ tipoVista, setTipoVista ] = useState('default')
    const [ loading, setLoading ] = useState(false)
    const [ mPPeference, setMPPreference ] = useState('')
    const [ gateways, setGateways ] = useState(pasarelas)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const redireccionarUrl = (url, parametros) => {
          // Crear un formulario
        var form = document.createElement("form");
        form.setAttribute("method", "post");
        form.setAttribute("action", url);
        form.setAttribute("target", "_blank");

        // Agregar los parámetros como campos ocultos al formulario
        for (var clave in parametros) {
            if (parametros.hasOwnProperty(clave)) {
            var campoOculto = document.createElement("input");
            campoOculto.setAttribute("type", "hidden");
            campoOculto.setAttribute("name", clave);
            campoOculto.setAttribute("value", parametros[clave]);
            form.appendChild(campoOculto);
            }
        }

        // Agregar el formulario al cuerpo del documento y enviarlo
        document.body.appendChild(form);
        form.submit();
        setTipoVista('default')
    }

    const urlByPaymentGateway = (name, username, email) => {
        const data = {
            transbank: {
                url: `${urlapi}/payments/transbank/oneclick/create?username=${username}&email=${email}`,
                method: "GET"
            },
            mercadopago: {
                url: `${urlapi}/payments/mercadopago/checkoutpro/create`,
                method: "POST",
                body: JSON.stringify({
                    ammount: parseInt(ammount)
                })
            },
        }
        return data[name]
    }

    const runActionByPaymentGateway = (name, data) => {
        switch (name) {
            case "transbank":
                return redireccionarUrl(data.url_webpay, { TBK_TOKEN: data.token })
            case "mercadopago":
                if(data) if(typeof data.id) return setMPPreference(data)
                return 
            default:
                break;
        }
        return 
    }

    const generarConexionPago = async (name) => {

        if(loading) return false
        setLoading(true)

        const config = urlByPaymentGateway(name, session.data.propietario, session.data.email, ammount)

        let settings = {
            method: config.method,
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        }
        if(config.method === "POST") settings.body = config.body
        return fetch(config.url, settings)
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(typeof res === "object"){
                runActionByPaymentGateway(name, res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const showByViewType = () => {
        if(loading) return <Spinner animation="border" variant="primary" />

        if(mPPeference) return <div>
        <Wallet initialization={{ preferenceId: mPPeference.id, redirectMode: 'blank' }} locale="es-CL" customization={{ texts:{ valueProp: 'smart_option'}}} />
        </div>

        switch (tipoVista) {
            case "default":
                return <div>
                    <Button className='mb-3'  variant='light' onClick={() => setTipoVista('form')} block>SELECCIONAR MÉTODO DE PAGO</Button>
                </div>
            case "form":
                return <div>
                    <h6 className="hover" onClick={() => setTipoVista('default')}><FaChevronLeft /> Atrás</h6>
                    {
                        gateways.map((pasarela, index) => {
                            return <Card onClick={() => generarConexionPago(pasarela.name)} key={index} style={{ textTransform: "uppercase" }} className='hover bordered shadow-sm p-3 mb-3'>
                                <h6 className="mb-0"><FaPlusCircle />{pasarela.logo ? <img className="ml-3" style={{ height: 30 }} src={pasarela.logo} /> : pasarela.description }</h6>
                            </Card>
                        })
                    }
                </div>
            default:
                break;
        }
    }

    return <div>
        {showByViewType()}
    </div>
}

export default AddNewPaymentGateway