import { url_images } from "../../lib/global/data";

const PantallaCotizando = (props) => {
    const {
        cantidad,
        total
    } = props
    return <div className="text-center p-3">
        <img className='d-block' src={`${url_images}/animations/Pricing Strategy.gif`} style={{ width: 250, maxWidth: "80%", margin: "0 auto" }} />
        <h4 className="mb-0 mt-0" style={{ fontWeight: "bold" }}>Buscando tarifas actualizadas</h4>
        <p>Esto podría demorar, no cierres la ventana</p>
        { cantidad ? <h5>Cotizando {cantidad} de {total}</h5> : false }
    </div>
}

export default PantallaCotizando;