import { Button, Col, Row } from "react-bootstrap";
import { BsCardChecklist } from "react-icons/bs";
import { Link } from "react-router-dom";
import { url_images } from "../../lib/global/data";
import { IoMdAlert } from "react-icons/io";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { urlapi } from "../../lib/backend/data";

const FatalErrorMessage = (props) => {
    const {
        errorMessage
    } = props
    const session = useSelector(state => state.miusuario)

    const reportarProblema = () => {
        if(!errorMessage) return false
        const currentUrl = window.location.href;
        const hostname = window.location.hostname;
        const pathname = window.location.pathname;
        const protocol = window.location.protocol;
        const port = window.location.port;
        const queryParams = window.location.search;
        const hash = window.location.hash;
        const width = window.innerWidth;
        const height = window.innerHeight;
        const isHttps = window.location.protocol === 'https:';
        const isWindowFocused = document.hasFocus();
        const userAgent = window.navigator.userAgent;
        const historyLength = window.history.length;
        const userLanguage = window.navigator.language;
        const cookies = document.cookie;
        const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        const serviceWorkerSupported = 'serviceWorker' in navigator;

        const error = {
            errorMessage,
            user: null,
            url: {
                currentUrl,
                hostname,
                pathname,
                protocol,
                port,
                queryParams,
                hash
            },
            window: {
                width,
                height
            },
            connection,
            isHttps,
            isWindowFocused,
            userAgent,
            historyLength,
            userLanguage,
            cookies,
            isMobile,
            serviceWorkerSupported
        }

        if(session) if(session?.data) error.user = session.data

        return fetch(`${urlapi}/configuracion/error`,{
            method:'POST',
            body: JSON.stringify(error),
            headers: {
                'Content-Type':'application/json',
            }
        })
        .then(res => res)
        .catch(error => error)

    }

    useEffect(() => {
        reportarProblema()
    }, [ errorMessage ])

    return <div className="p-4 " style={{ height: "100vh", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Row className="justify-content-md-center">
                                <Col md={8} className="text-center">
                                <IoMdAlert size={30} style={{ verticalAlign: "middle" }} /> 
                                    <h2><b>¡Ups! Algo salió mal</b></h2>
                                    {/* <Row>
                                    {
                                        default_proveedores.map((proveedor, index) => {
                                            return <Col xs={2} key={index}>
                                                <img style={{ maxHeight: 100, maxWidth: "100%" }} className="mb-3" src={proveedor.logo} />
                                            </Col>
                                        })
                                    }
                                    </Row> */}
                                    <img src={`${url_images}/animations/Professional movers.gif`} style={{ maxWidth: "80%" }} />
                                    <p>Hubo un error que impide seguir visualizando esta página, intenta recargar o volver al inicio</p>
                                    <a href="/"><Button size="sm">VOLVER AL INICIO</Button></a>
                                </Col>
                            </Row>
        {/* <Link to="/"> <Button>VOLVER AL INICIO</Button></Link> */}
    </div>
}

export default FatalErrorMessage;