import { getDaysInMonth } from "date-fns"
import { DateTime } from "luxon"
import { timezone } from "../backend/data"
import { calcularCompromiso } from "./pedidos/pedidos"

export const procesarDatosGraficosEstadisticaConductor = ordenes => {
    if(!ordenes) return { datos:[], colores:[], encabezados:[] }
    if(Array.isArray(ordenes) !== true) return { datos:[], colores:[], encabezados:[] }
    if(ordenes.length < 1) return { datos:[], colores:[], encabezados:[] }

    const primera_fecha = new Date(ordenes[0].createdAt)
    const dias = getDaysInMonth(new Date())
    const array_dias = Array.from(Array(dias).keys())

    let encabezados = ['Día','Operaciones']
    let colores = []
    let resultados_asignadas = [encabezados]
    let resultados_completadas = [encabezados]
    let resultados_retrasadas = [encabezados]
    for( const day of array_dias ){
        const num_dia = day+1
        const cantidad_asignada = ordenes.filter(o => {
            if(!o.createdAt) return false
            const dia = new Date(o.createdAt)
            const num = dia.getDate()
            return num === num_dia ? true : false
        }).length
        const cantidad_completada = ordenes.filter(o => {
            if(!o.fecha_finalizado) return false
            const dia = new Date(o.fecha_finalizado)
            const num = dia.getDate()
            return num === num_dia ? true : false
        }).length
        const hoy = new Date()
        const cantidad_rechazada = ordenes.filter(o => {
            const dia = new Date(o.fecha_finalizado)
            const num = dia.getDate()
            if(num !== num_dia) return false

            if(!o.fecha_max_entrega) return false
            if(o.finalizado === true){
                if(!o.fecha_finalizado) return false
                if(o.fecha_finalizado > o.fecha_max_entrega) return true
            }
            const fecha = new Date(o.fecha_max_entrega)
            if(fecha < hoy) return true
            return false
        }).length
        let dato = [ num_dia.toString(), cantidad_asignada ]
        let dato_completado = [ num_dia.toString(), cantidad_completada ]
        let dato_rechazado = [ num_dia.toString(), cantidad_rechazada ]
        resultados_asignadas.push(dato)
        resultados_completadas.push(dato_completado)
        resultados_retrasadas.push(dato_rechazado)
    }    
    return { asignadas: resultados_asignadas, completadas: resultados_completadas, rechazadas: resultados_retrasadas }
}

export const procesarDatosGraficosEstadisticaConductorLastMile = ordenes => {
    const data_dias = { dias: [] }
    const default_information = { en_curso: data_dias, asignadas: data_dias, completadas: data_dias, rechazadas: data_dias, _anuladas: data_dias }
    if(!ordenes) return default_information
    if(Array.isArray(ordenes) !== true) return default_information
    if(ordenes.length < 1) return default_information
    const primera_fecha = DateTime.fromISO(ordenes[0].createdAt).toUTC().toJSDate()
    const dias = getDaysInMonth(primera_fecha)
    const array_dias = Array.from(Array(dias).keys())

    let resultados_asignadas            = { dias: [] }
    let resultados_completadas          = { dias: [] }
    let resultados_retrasadas           = { dias: [] }
    let resultados_en_curso             = { dias: [] }
    let resultados_anuladas             = { dias: [] }
    let anuladas                        = ordenes.filter(ord => ord.anulado === true).length
    const { retrasadas } = calcularCompromiso(ordenes)

    for( const day of array_dias ){
        const num_dia = day+1

        const asignadas = ordenes.filter(o => {
            if(!o.createdAt) return false
            const dia = DateTime.fromISO(o.createdAt).day
            const num = dia
            return num === num_dia ? true : false
        })
        
        const _anuladas = ordenes.filter(o => {
            if(!o.createdAt) return false
            const dia = DateTime.fromISO(o.createdAt).day
            const num = dia
            if(o.anulado !== true) return false
            return num === num_dia ? true : false
        })

        const en_curso = ordenes.filter(o => {
            if(o.finalizado === true) return false
            if(o.fecha_finalizado) return false
            if(o.anulado === true) return false
            const dia = DateTime.fromISO(o.createdAt).day
            const num = dia
            return num === num_dia ? true : false
        })

        const completadas = ordenes.filter(o => {
            if(o.anulado === true) return false
            if(!o.fecha_finalizado) return false
            const dia = DateTime.fromISO(o.fecha_finalizado).day
            const num = dia

            return num === num_dia ? true : false
        })

            const rechazadas = retrasadas.filter(o => {
            const dia_creacion                       = DateTime.fromISO(o.createdAt).day
            return dia_creacion === num_dia
        })

        let dato = { dia: num_dia.toString(), cantidad: asignadas.length, datos: asignadas }
        let dato_completado = { dia: num_dia.toString(), cantidad: completadas.length, datos: completadas }
        let dato_rechazado = { dia: num_dia.toString(), cantidad: rechazadas.length, datos: rechazadas }
        let dato_en_curso = { dia: num_dia.toString(), cantidad: en_curso.length, datos: en_curso }
        let dato_anuladas = { dia: num_dia.toString(), cantidad: _anuladas.length, datos: _anuladas }

        resultados_asignadas.dias.push(dato)
        resultados_completadas.dias.push(dato_completado)
        resultados_retrasadas.dias.push(dato_rechazado)
        resultados_en_curso.dias.push(dato_en_curso)
        resultados_anuladas.dias.push(dato_anuladas)
    }    
    return { 
        asignadas: resultados_asignadas, 
        completadas: resultados_completadas, 
        rechazadas: resultados_retrasadas, 
        en_curso: resultados_en_curso,
        _anuladas: resultados_anuladas,
        anuladas, 
    }
}

export const segmentarCalendarioOrdenes = ordenes => {
    const data_dias = { dias: [] }
    const default_information = data_dias
    if(!ordenes) return default_information
    if(Array.isArray(ordenes) !== true) return default_information
    if(ordenes.length < 1) return default_information
    const primera_fecha = DateTime.fromISO(ordenes[0].createdAt).toUTC().toJSDate()
    const dias = getDaysInMonth(primera_fecha)
    const array_dias = Array.from(Array(dias).keys())

    let resultados = { dias: [] }
    
    for( const day of array_dias ){
        const num_dia = day+1

        const segmento = ordenes.filter(o => {
            if(!o.createdAt) return false
            const dia = DateTime.fromISO(o.createdAt).day
            const num = dia
            return num === num_dia ? true : false
        })

        let dato = { dia: num_dia.toString(), cantidad: segmento.length, datos: segmento }

        resultados.dias.push(dato)
    }

    return resultados
}

const calcularPorcentaje = (montoTotal, montoActual) => {
    const porcentaje = (montoActual / montoTotal) * 100;
    return porcentaje;
}
  
export const procesarOTIFParaGraficos = datos => {
    const { asignadas, completadas, rechazadas, anuladas } = datos

    const total_asignadas = asignadas.dias.reduce((prev,next) => {
        const { cantidad } = next
        return cantidad + prev
    }, 0)

    const total_completadas = completadas.dias.reduce((prev,next) => {
        const { cantidad } = next
        return cantidad + prev
    }, 0)
    const total_rechazadas = rechazadas.dias.reduce((prev,next) => {
        const { cantidad } = next
        return cantidad + prev
    }, 0)

    const total_otif = total_asignadas - total_rechazadas

    const porcentaje = calcularPorcentaje(total_asignadas, total_otif)

    const total_anuladas = anuladas

    return {
        otif: porcentaje.toFixed(0),
        total_asignadas,
        total_completadas,
        total_rechazadas,
        total_anuladas
    }
}