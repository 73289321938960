import { useEffect, useState } from "react"
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip, Card, Form } from "react-bootstrap"
import data from '../../lib/backend/data'
import { estilo_last_mile } from "../../lib/global/styles"
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";
import EtiquetasRelaciones from "./etiquetas_relaciones";
import BuscadorUsuarios from "../usuarios/buscador";
import { FaAngleRight, FaRegEnvelope, FaWhatsapp } from "react-icons/fa";
import { MdAlternateEmail } from "react-icons/md";
import { AiFillPlusCircle } from "react-icons/ai";
import Select from 'react-select'
import { BsFillTrashFill } from "react-icons/bs";
import SelectorUsuarios from "../usuarios/selector_usuarios.tsx";
import ColumnasOrdenes from "./columnas.js";
import SelectorEstados from "../estados_carga/selector.js";
import CamposPersonalizadosSelectores from "../general/campos_personalizados_selectores.js";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [loadingEquipos, setLoadingEquipos] = useState(false)
    const [customColumns, setCustomColumns] = useState([])
    const [condicion_busqueda, setCondicionBusqueda] = useState({})
    const [tipoCondicion, setTipoCondicion] = useState('and')
    const dispatch = useDispatch()
    const opcionesAvanzadas = [
        { value: "picking-pendiente", label :"Pickeo pendiente" },
        { value: "picking-iniciado", label :"Pickeo iniciado" },
        { value: "picking-listo", label :"Pickeo listo" },
        // { value: "ruta", label :"Pedido dentro de una ruta" },
        // { value: "no-ruta", label :"Pedido fuera de una ruta" },
        { value: "no-conductor", label :"Sin Conductor asignado" },
        { value: "conductor", label :"Conductor asignado" },
        // { value: "no-proveedor", label :"Sin proveedor de transporte asignado" },
        // { value: "proveedor", label :"Proveedor de transporte asignado" },
        // { value: "no-etiquetas", label :"Etiquetas no impresas" },
        // { value: "etiquetas", label :"Etiquetas impresas" },
        { value: "no-gestionadas", label :"Órdenes sin estado" },
        // { value: "a-tiempo", label :"A tiempo" },
        // { value: "retrasadas", label :"Retrasadas" },
    ]

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        if(!nuevoProveedor.titulo) return toast.error('Debes definir un titulo para el flujo de trabajo')
        if(!nuevoProveedor.tipo) return toast.error('Debes definir un tipo para el flujo de trabajo')
        if(!nuevoProveedor.fases) return toast.error('Debes definir las fases')
        setLoading(true)
        return fetch(`${data.urlapi}/flujos/trabajo`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){                   
                    return toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){                   
                     toast.error(pros.errorMessage)
                     return setLoading(false)
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const obtenerOrdenes = async (page, query, tipo_condicion)=>{
        setLoadingEquipos(true)
        let condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                tipo_condicion: tipo_condicion ? tipo_condicion : tipoCondicion,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.campos_personalizados) !== false){
                setCustomColumns(res.campos_personalizados)
            }
            console.log("res", res)
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    useEffect(() => {
        if(proveedor && proveedor._id){
            obtenerOrdenes(1, { proveedor: proveedor._id })
        }
    }, [proveedor])

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/flujos/trabajo`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        console.log("np", nuevoProveedor)
        return setNuevoProveedor(nuevoProveedor)
    }

    const removerEmail = (i) => {
        nuevoProveedor.fases.splice(i,1)
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor})) 
    }
    const removerEmailProveedor = (i) => {
        proveedor.fases.splice(i,1)
        return setProveedor(prev => ({...{}, ...proveedor})) 
    }

    const handleChangeEmail = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        nuevoProveedor.fases[pos][name] = value
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor}))
    }
    
    const handleChangeChecked = (e) => {
        const { name } = e.target
        const value = e.target.checked
        const pos = parseInt(e.target.getAttribute('pos'))
        nuevoProveedor.fases[pos][name] = value
        return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor}))
    }

    const handleChangeCheckedActual = (e) => {
        const { name } = e.target
        const value = e.target.checked
        const pos = parseInt(e.target.getAttribute('pos'))
        proveedor.fases[pos][name] = value
        return setProveedor(prev => ({...{}, ...proveedor}))
    }

    const handleChangeEmailProveedor = (e) => {
        const { name, value } = e.target
        const pos = parseInt(e.target.getAttribute('pos'))
        proveedor.fases[pos][name] = value
        return setProveedor(prev => ({...{}, ...proveedor}))
    }

   const handleChangeClientes = (data, i) => {
    nuevoProveedor.fases[i].usuarios = data
    console.log(data, i)
    return setNuevoProveedor(prev => ({...{}, ...nuevoProveedor}))
   }

   const handleChangeClientesProveedor = (data, i) => {
    proveedor.fases[i].usuarios = data
    console.log(data, i)
    return setProveedor(prev => ({...{}, ...proveedor}))
   }

    const anadirEmailAdicional = () => {
        if(!nuevoProveedor.fases) nuevoProveedor.fases = []
        nuevoProveedor.fases.unshift({
            titulo: '',
            usuarios: []
        })
        return setNuevoProveedor(prev => {
            let actual = { ...prev }
            if(!actual.fases) actual.fases = []
            actual.fases.push({
                titulo: '',
                usuarios: []
            })
            return actual
        })
    }

    const anadirEmailAdicionalProveedor = () => {
        if(!proveedor.fases) proveedor.fases = []
        proveedor.fases.push({
            titulo: '',
            usuarios: []
        })
        return setProveedor(prev => ({...{}, ...proveedor}))
    }

    const mostrarEmailsAdicionales = () => {
        if(!nuevoProveedor.fases) return false
        return <Row>
            {
                nuevoProveedor.fases.map((field,i) => {

                    let identificador = `fase-${i}`
                    let defaultValue = []

                    if(field.usuarios) if(Array.isArray(field.usuarios)) defaultValue = field.usuarios.map(e => ({ value: e._id, label: `${e.email ? `${e.email} ` : ""}${e.nombres ? `${e.nombres} ` : ""}${e.apellidos ? `${e.apellidos} ` : ""}` }))

                    return <Col md={12}>
                     <Card key={`email-${i}`} className="p-2 mt-3 mr-2" style={{backgroundColor: '#eaf1ec'}}>
                    
                                <label>Título</label>
                                <input placeholder="Título de la fase" className={`form-control mb-3 ${field.valid === false ? 'is-invalid' : ''}`} pos={i}  name="titulo" value={field.titulo} onChange={handleChangeEmail} />
                                <Form.Group key={identificador} className="mb-3" controlId={identificador}>
                                    <Form.Check type="switch" checked={field.allowCustomFields} name="allowCustomFields" pos={i}  label="Ocultar campos personalizados en la tabla" onChange={handleChangeChecked} />
                                </Form.Group>

                                <div className="mb-3"><ColumnasOrdenes key={`esquema-${i}`} defaultSchema={field.esquema} columns={customColumns} onSave={(esquema) => setNuevoProveedor(prev => {
                                    let actual = { ...prev }
                                    actual.fases[i].esquema = esquema
                                    return actual
                                })} screen="ordenes" /></div>
                                <SelectorUsuarios defaultValue={defaultValue} key_identifier="email" full_payload={true} multi={true} label="Selecciona los usuarios" onChange={(data) => handleChangeClientes(data, i)} />
                               <div className='col-md-12'>
                               <label className='form-control-label d-block pt-2'><b className="text-danger hover" onClick={() => removerEmail(i)}><BsFillTrashFill /> REMOVER</b></label>
                               </div>
                               </Card>
                    </Col>
                    
                })
            }
        </Row>
    }

    const condicionesEspeciales = (state, setState ,pos) => {
        let opciones = Array.isArray(state.fases[pos].acciones_especiales) ? state.fases[pos].acciones_especiales : []

        return <div>
            <label className="d-block form-control-label">Condiciones especiales</label>
            <Select isMulti className="mb-3" placeholder="Selecciona una condición que se debe cumplir en la orden" value={opciones} options={opcionesAvanzadas} onChange={(e) => {
                        return setState(prev => {
                            let actual = { ...prev }
                            actual.fases[pos].acciones_especiales = e
                            return actual
                        })
                
            }} />
        </div>
    }

    const onChangeCustomField = (e, name_field, setState, i_fase) => {
        return setState(prev => {
            let actual = { ...prev }
            if(!actual.fases[i_fase].customFields) actual.fases[i_fase].customFields = {}
            actual.fases[i_fase].customFields[name_field] = e
            if(!e) delete actual.fases[i_fase].customFields[name_field]
            return actual
        })
    }

    const mostrarEmailsAdicionalesProveedor = () => {
        if(!proveedor.fases) return false
        console.log(proveedor, 'proveedor')
        return <Row>
            {
                proveedor.fases.map((field,i) => {
                    let esquema = field.customFields ? field.customFields : {}
                    let identificador = `fase-${i}`
                    let defaultValue = []
                    if(field.usuarios) if(Array.isArray(field.usuarios)) defaultValue = field.usuarios.map(e => ({ value: e._id, label: `${e.email ? `${e.email} ` : ""}${e.nombres ? `${e.nombres} ` : ""}${e.apellidos ? `${e.apellidos} ` : ""}` }))
                    let defaultValueEstados = []
                    if(Array.isArray(field.estados)) if(field.estados.length > 0){
                        defaultValueEstados = field.estados.map(e => e._id)
                    }

                    return <Col md={12}>
                     <Card key={`email-${i}`} className="p-2 mt-3 mr-2" style={{backgroundColor: '#eaf1ec'}}>
                    
                                <label>Título</label>
                                <input placeholder="Título de la fase" className={`form-control mb-3 ${field.valid === false ? 'is-invalid' : ''}`} pos={i}  name="titulo" value={field.titulo} onChange={handleChangeEmailProveedor} />


                                <Form.Group key={identificador} className="mb-3" controlId={identificador}>
                                    <Form.Check type="switch" checked={field.allowCustomFields} name="allowCustomFields" pos={i}  label="Ocultar campos personalizados en la tabla" onChange={handleChangeCheckedActual} />
                                </Form.Group>

                                {condicionesEspeciales( proveedor, setProveedor, i)}

                                <div className="mb-3"><CamposPersonalizadosSelectores defaultSchema={esquema} onChange={(e,name_field) => onChangeCustomField(e, name_field, setProveedor, i)} /></div>

                                <SelectorEstados tipo_dato="estados" isMulti={true} defaultValue={defaultValueEstados} full_payload={true} onChange={(data) => setProveedor(prev => {
                                    let actual = { ...prev }
                                    actual.fases[i].estados = data
                                    return actual
                                })} />

                                <div className="mb-3"><ColumnasOrdenes key={`esquema-${i}`} defaultSchema={field.esquema} columns={customColumns} onSave={(esquema) => setProveedor(prev => {
                                    let actual = { ...prev }
                                    actual.fases[i].esquema = esquema
                                    return actual
                                })} screen="ordenes" /></div>

                                <SelectorUsuarios defaultValue={defaultValue} key_identifier="email" full_payload={true} multi={true} label="Selecciona los usuarios" onChange={(data) => handleChangeClientesProveedor(data, i)} />
                               
                               <div className='col-md-12'>
                               <label className='form-control-label d-block pt-2'><b className="text-danger hover" onClick={() => removerEmailProveedor(i)}><BsFillTrashFill /> REMOVER</b></label>
                               </div>
                               </Card>
                    </Col>
                    
                })
            }
        </Row>
    }

    const mostrarAgregadorFases = (setStateFunction) => {
        return <div>
            <label className='form-control-label d-block' onClick={() => anadirEmailAdicional(setStateFunction)}><b className="text-success hover"><AiFillPlusCircle /> AGREGAR FASE</b></label>
            {mostrarEmailsAdicionales()}              
            <hr />
        </div>
    }

    const mostrarAgregadorFasesProveedor = () => {
        return <div>
            <label className='form-control-label d-block' onClick={() => anadirEmailAdicionalProveedor()}><b className="text-success hover"><AiFillPlusCircle /> AGREGAR FASE</b></label>
            {mostrarEmailsAdicionalesProveedor()}              
            <hr />
        </div>
    }


    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo espacio de trabajo</h4>
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Título</label>
                    <input className="form-control" name="titulo" defaultValue={nuevoProveedor.titulo} onChange={handleChangeNuevo} />
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Tipo</label>
                    <select name="tipo" className='form-control' defaultValue={nuevoProveedor?.tipo} onChange={handleChangeNuevo}>
                        <option value="">Selecciona</option>
                        <option value="ordenes">Ordenes</option>
                    </select>
                </Col>
                <Col md={12}>
                                    <Row>
                                        <Col>
                                            <h6 className="mb-2"><b>Fases</b></h6>
                                            {mostrarAgregadorFases(setNuevoProveedor)}
                                        </Col>
                                    </Row>
                </Col>

                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/flujos/trabajo?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
                    toast.success("Realizado Exitosamente")
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <Row>
        <Col md={12} className="mb-3">
            <h4 className="mb-0">{proveedor.name}</h4>
            {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
        </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Título</label>
                    <input className="form-control" name="titulo" defaultValue={proveedor.titulo} onChange={handleChange} />
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Tipo</label>
                    <select name="tipo" className='form-control' defaultValue={proveedor?.tipo} onChange={handleChange}>
                        <option value="">Selecciona</option>
                        <option value="ordenes">Ordenes</option>
                    </select>
                </Col>
                <Col md={12}>
             
                                    
                                    <Row>
                                        <Col>
                                            <h6 className="mb-2"><b>Fases</b></h6>
                                            {mostrarAgregadorFasesProveedor()}
                                        </Col>
                                    </Row>
                                    
            
                </Col>

        <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
                  <EtiquetasRelaciones id_target={proveedor._id} tipo_destino="vehiculo" tipo_origen="etiquetas"/>
        </Col>
    </Row>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull