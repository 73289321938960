import { Form, Table } from "react-bootstrap"
import { getDireccion, getNombreDestinatario, obtenerGeoValor } from "../../../lib/helpers/pedidos/pedidos"

const TablaOrdenesStatic = (props) => {
    const {
        ordenes,
        displaySelection,
        typeView
    } = props
    const pedidos = ordenes ? ordenes : []
    if(Array.isArray(pedidos) !== true) return <h6>No hay items</h6>
    if(pedidos.length < 1) return <h6>No hay items</h6>

    const estilo = { textTransform: "uppercase", fontSize:9, fontWeight:'normal', padding: 6, color:'white' }

    const obtenerEstadoEntregaHTML = (orden) => {
        let titulo_estado           = ''
                let titulo_sub_estado       = ''
                let color_estado            = '#bebebe'
                let color_subestado            = '#bebebe'

                if(orden.estado_entrega){
                    if(typeof orden.estado_entrega === 'object'){
                        if(orden.estado_entrega.titulo) titulo_estado = orden.estado_entrega.titulo
                        if(orden.estado_entrega.color) color_estado = orden.estado_entrega.color
                    }
                }
                
                if(orden.sub_estado_entrega){
                    if(typeof orden.sub_estado_entrega === 'object'){
                        if(orden.sub_estado_entrega.titulo) titulo_sub_estado = orden.sub_estado_entrega.titulo
                        if(orden.sub_estado_entrega.color) color_subestado = orden.sub_estado_entrega.color
                    }
                }

                return <p className="mb-2"><b style={{...estilo, backgroundColor: color_estado }}>{titulo_sub_estado}</b><b style={{...estilo, backgroundColor: color_subestado }}>{titulo_sub_estado}</b></p>
    }

    const marcarCheckBoxes = (e) => {
        const value = e.target.checked
        const list = document.querySelectorAll('.delpa-checkpedido input[type="checkbox"]');
            list.forEach((checkbox) => {
                checkbox.checked = value;
            })
        if(props.onChangeAll) props.onChangeAll({value})
        
    }
    
    const verificarCheckBoxes = (e) => {
        const { name } = e.target
        const value = e.target.checked
        console.log(name, value)
        if(props.onChangeSingular) props.onChangeSingular({name,value})
    }

    const vistaSimple = () => {

        return <div>
            <Table size="sm">
            <thead>
                <tr>
                    {displaySelection === true ? <th>
                        <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    onChange={marcarCheckBoxes}
                                                />
                    </th> : false }
                    <th>Referencia</th>
                    <th>Estado</th>
                    <th>Destinatario</th>
                    <th>Zona 1</th>
                    <th>Zona 2</th>
                </tr>
            </thead>
            <tbody>
                {
                    pedidos.map((orden,ir) => {
                        return <tr key={orden._id}>
                            {displaySelection === true ? <th>
                                <Form.Check
                                                    inline
                                                    name={orden._id}
                                                    type="checkbox"
                                                    className={`delpa-checkpedido ruta-${ir}`}
                                                    id={orden._id}
                                                    defaultChecked={orden.seleccionado === true}
                                                    onChange={verificarCheckBoxes}
                                                />
                            </th> : false }
                            <th>{orden.pedido}</th>
                            <th>{obtenerEstadoEntregaHTML(orden)}</th>
                            <th>{getNombreDestinatario(orden)}</th>
                            <th>{obtenerGeoValor(orden,"level1")}</th>
                            <th>{obtenerGeoValor(orden,"level2")}</th>
                        </tr>
                    })
                }
            </tbody>
        </Table>
        </div>
    }

    if(typeof typeView !== "undefined"){
        if(typeView === "simple") return vistaSimple()
    }

    return <div className="table-responsive mt-3">
        <h5>{pedidos.length} Órdenes</h5>
        <Table>
            <thead>
                <tr>
                    {displaySelection === true ? <th>
                        <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    onChange={marcarCheckBoxes}
                                                />
                    </th> : false }
                    <th>Referencia</th>
                    <th>Destinatario</th>
                    <th>Dirección</th>
                </tr>
            </thead>
            <tbody>
                {
                    pedidos.map((orden,ir) => {
                        return <tr key={orden._id}>
                            {displaySelection === true ? <th>
                                <Form.Check
                                                    inline
                                                    name={orden._id}
                                                    type="checkbox"
                                                    className={`delpa-checkpedido ruta-${ir}`}
                                                    id={orden._id}
                                                    defaultChecked={orden.seleccionado === true}
                                                    onChange={verificarCheckBoxes}
                                                />
                            </th> : false }
                            <th>{orden.pedido}</th>
                            <th>{getNombreDestinatario(orden)}</th>
                            <th>{getDireccion(orden)}</th>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    </div>
}

export default TablaOrdenesStatic