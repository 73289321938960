import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Popup, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Pin from '../../subComponents/mapas/pin';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { BsFillStopCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { FaCircle } from 'react-icons/fa';
import socket from '../../lib/websockets';
import { url_images } from '../../lib/global/data';
import { Badge, Button } from 'react-bootstrap';
import Moment from 'react-moment';
import { FaTruckFront } from 'react-icons/fa6';

const MapaRutaAsignacion = (props) => {
    const [selectedMarker, setSelectedMarker] = useState(null);

    const {
        height,
        markers,
        pinType,
        resize,
        typeView,
        id_conductor
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion = useSelector(state => state.miusuario)
    const [intervalo, setIntervalo] = useState(false)
    const [ loadingLocation, setLoadingLocation ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const pais = useSelector(state => state.pais)
    const [ coleccion_ordenes, setColeccionOrdenes ] = useState({ type: 'FeatureCollection', features: []})
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const centrarMapa = (centro, max_zoom) => {
        const nuevo_zoom = defaultViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

      const mostrarCondutor = () => {
        if(!conductor) return false
        centrarMapa({ latitude: conductor.lat, longitude: conductor.lng }, 6)
      }

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        if(puntos.length === 1){
            return centrarMapa({ latitude: puntos[0].latitude, longitude: puntos[0].longitude }, 6)
          }
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 130 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

    const armarGeoDatos = () => {
        const puntos = markers.map(ord => {
            return {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: [
                    ord.lng,
                    ord.lat
                ]
              }
            }
          }).filter(p => p)

          const newFeatures = {
            type: 'FeatureCollection',
            features: puntos
          }
          setColeccionOrdenes({ ...{}, ...newFeatures })
    }

    const refrescarListado = async (id) => {
    
        return fetch(`${urlapi}/conductores/location?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(res._id){
                setConductor(res)
            }
            return setLoadingLocation(false)
        })
        .catch(error => {
            return setLoadingLocation(false)
        })
    }    

      useEffect(() => {
            setMarcadores(markers)
            if(mapRef) if(mapRef.current) mapRef.current.resize()
            setTimeout(() => {
                ajustarCentro(markers.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
            }, 500)
            armarGeoDatos()

            let id_intervalo = ''
            if(id_conductor){
                refrescarListado(id_conductor)
                id_intervalo = setInterval(() => {
                    refrescarListado(id_conductor)
                }, 4000);
                setIntervalo(id_intervalo)
            }
            const identificador_2 = 'conexion_conductor'

            socket.on(identificador_2, datos => {
                if(id_conductor) refrescarListado(id_conductor)
            })
            return () => {
                clearInterval(id_intervalo)
                socket?.off(identificador_2)
            }
      }, [ resize, markers ])


    const showPin = (color) => {
        const tipo = typeof pinType !== "undefined" ? pinType : false
        switch (tipo) {
            case "uber":
                return <FaCircle color={color} size={5} />
            default:
                return <Pin color={color} />
        }
    }

    if(typeView === "heatmap") {
        return <div>
            <Map
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{width: "100%", height: height ? height : 600}}
                dragPan={false}
                scrollZoom={false}
                mapStyle="mapbox://styles/mapbox/dark-v9"
                mapboxAccessToken={mapbox_token}
            >
                <Source
                    key={"ajsfkn2"}
                    id={"geocerca._id2"}
                    type="geojson"
                    data={coleccion_ordenes}
                >
                    <Layer
                    id="heatmap-layer"
                    type="heatmap"
                    paint={{
                        'heatmap-weight': 1,
                        'heatmap-intensity': 0.5,
                        'heatmap-radius': 20,
                        'heatmap-color': [
                        'interpolate',
                        ['linear'],
                        ['heatmap-density'],
                        0,
                        'rgba(0, 0, 255, 0)',
                        0.2,
                        'royalblue',
                        0.4,
                        'cyan',
                        0.6,
                        'lime',
                        0.8,
                        'yellow',
                        1,
                        'red',
                        ],
                        'heatmap-opacity': 0.8,
                    }}
                    />

                </Source>
            </Map>
        </div>
    }

    return <div>
        <Map
            ref={mapRef}
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            style={{width: "100%", height: height ? height : 600}}
            scrollZoom={false}
            mapStyle="mapbox://styles/mapbox/light-v9"
            // mapStyle="mapbox://styles/mapbox/streets-v9"
            mapboxAccessToken={mapbox_token}
        >
            <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                <div style={{
                        position: 'absolute',
                        top: 10,  // Ajusta según la posición deseada
                        right: 10,
                        zIndex: 1 // Asegura que esté sobre el mapa
                    }}>
                        {
                            conductor ? <Button size="sm" variant="primary" onClick={() => mostrarCondutor()}><FaTruckFront /> MOSTRAR VEHÍCULO</Button> : false
                        }
                        
                    </div>
            {
                conductor ? <Marker
                    key={conductor._id}
                    longitude={conductor.lng}
                    latitude={conductor.lat}
                >
                <div>
                <div className='text-center'>
                    <img src={`${url_images}/truck-icon.png`} style={{ height: 50 }} />
                    <div><Badge variant="dark" className='mb-0' style={{ textTransform: "uppercase" }} >{conductor.patente}</Badge></div>
                    <div><Badge variant="light" className='mb-0'> <Moment locale="ES" fromNow>{conductor.updatedAt}</Moment></Badge></div>
                </div>
                </div>
            </Marker> : false
            }
            {
            marcadores.map(marker => {
                let color = ''

                if(!marker) return false
                if(marker.detalles?.estado_entrega?.color) color = marker.detalles?.estado_entrega?.color
                return <Marker
                key={marker._id}
                longitude={marker.lng}
                latitude={marker.lat}
                >
                    <div onClick={e => {
                        e.preventDefault();
                        setSelectedMarker(marker);
                    }}>
                        {showPin(color)}
                    </div>
                </Marker>
            })
            }

            {selectedMarker ? (
                <Popup
                latitude={selectedMarker.lat}
                longitude={selectedMarker.lng}
                closeButton={true}
                closeOnClick={false}
                onClose={() => setSelectedMarker(null)}
                anchor="right"
                className='p-1' 
                >
                    <div className='pt-2'>
                        <Link to={`/ordenes/${selectedMarker._id}`}><b>{selectedMarker.ref}</b></Link>
                    </div>
                </Popup>
            ) : null}
        </Map>
    </div>
}

export default MapaRutaAsignacion