import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';

const IntegracionesCarritoListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ crearNuevo, setCrearNuevo ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    const dispatch = useDispatch()

    const obtenerProveedores = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/integraciones/carritos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setProveedores(res.datos)
                setTotal(res.total)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const onClickProveedor = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        setCrearNuevo(false)
        return fetch(`${data.urlapi}/integraciones/carritos/detail?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setVehiculoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerProveedores(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            {
                        registros.map(orden => {
                            let seleccionado = false
                            if(idSeleccionado === orden._id) seleccionado = true
                            return <div key={orden._id} className="mb-3">
                                <BoxList proveedor={orden} seleccionado={seleccionado} onClickProveedor={(id) => onClickProveedor(id)} />
                            </div>
                        })
                    }
        </div>
    }

    const handleSubmitPalabra = (e) => {
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { name: parametroBusqueda }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProveedores(1, condicion)
    }

    const onProveedorGuardado = (data) => {
        console.log(data)
        const i = proveedores.findIndex(a => a._id === data._id)
        if(i < 0) return false
        proveedores[i] = data
        return setProveedores(prev => [...[], ...proveedores])
    }

    const crearProveedor = () => {
        setLoadingDetalle(true)
            setIdSeleccionado(false)
            setVehiculoSeleccionado(false)
            setTimeout(() => {
                setLoadingDetalle(false)
            }, 100);
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const onProveedorNuevo = (data) => {
        proveedores.unshift(data)
        crearProveedor()
        setTotal(total + 1)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const onFieldDeleted = (id) => {
        const i = proveedores.findIndex(c => c._id === id)
        if(i < 0) return false
        proveedores.splice(i,1)
        setVehiculoSeleccionado(false)
        setTotal(total - 1)
        return setProveedores(prev => [...[], ...proveedores])
    }
 
    return <div>
        <Row>
            <Col md={3}>
            <h4 style={{ fontSize: 23 }}>Configuraciones <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Crear nuevo</Tooltip>
                            }
                            >
                            <i className="fa-solid text-primary hover fa-circle-plus" onClick={()=>crearProveedor()} ></i>
                          </OverlayTrigger>
              
              </h4>
                <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmitPalabra}>
                        <input className='mb-3 form-control' placeholder='BUSCAR POR PALABRAS CLAVE' onChange={handleChangePalabra} />
                        </form>
                    </Col>
                </Row>
                {tabla_registros(proveedores)}
            </Col>
            <Col md={9}>
                <Card className='p-3'>
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull 
                        onFieldDeleted={(id) => onFieldDeleted(id)}
                        crear={crearNuevo} 
                        onProveedorNuevo={(data) => onProveedorNuevo(data)} 
                        token={token} 
                        proveedor={vehiculoSeleccionado} 
                        onProveedorGuardado={(data) => onProveedorGuardado(data)} 
                        />
                    }
                </Card>
            </Col>
        </Row>
    </div>

}

export default IntegracionesCarritoListado