import Skeleton from "react-loading-skeleton"

const CargandoTabla = () => {
    return <div className="mt-3">
        <Skeleton height={50} className="mb-2" />
        <Skeleton height={50} className="mb-2" />
        <Skeleton height={50} className="mb-2" />
    </div>
}

export default CargandoTabla