import { useState } from "react"
import { fechaATextoSimple, fechaATextoSimpleCorto } from "../../lib/helpers/helpers"
import { Button, Card, Modal } from "react-bootstrap"
import { DateRangePicker } from "react-date-range"
import { es } from 'date-fns/locale'
import { MdDateRange } from "react-icons/md"

const SelectorRangoFechas = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const {
        titulo_label,
        default_range_dates,
        typeView
    } = props
    const [ showModal ,setShowModal ] = useState(false)
    const [ selectionRange, setSelectionRange ] = useState(default_range_dates ? default_range_dates : initialDate)
    const titulo = titulo_label ? titulo_label : "Fecha de consulta"
    const cambiarRangoFecha = (item) => {
        if(props.onChange) props.onChange(item)
        return setSelectionRange(item.selection)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const viewByTypeView = () => {
        switch (typeView) {
            case "small":
                return <div>
                    <label className='form-control-label d-block text-primary' style={{fontWeight:700}}>{titulo}</label>
                    <Card className='p-2 text-primary hover shadow-sm' onClick={() => setShowModal(true)}><p style={{ fontSize: 11 }} className='mb-0 '><MdDateRange size={15} /> {fechaATextoSimpleCorto(selectionRange.startDate)} · {fechaATextoSimpleCorto(selectionRange.endDate)}</p></Card>
                </div>
            default:
                return <div>
                    <label className='form-control-label d-block text-primary' style={{fontWeight:700}}>{titulo}</label>
                    <Card className='p-2 text-primary hover shadow-sm' onClick={() => setShowModal(true)}><p className='mb-0 '><MdDateRange size={15} /> {fechaATextoSimple(selectionRange.startDate)} - {fechaATextoSimple(selectionRange.endDate)}</p></Card>
                </div>
        }
    }

    return <div>
        <Modal show={showModal} size="xl" onHide={()=>handleClose()} centered >
            <Modal.Header closeButton>
                <Modal.Title className="text-primary" style={{fontWeight:700}}>Selecciona un rango de fechas</Modal.Title>
            </Modal.Header>
                                            <Modal.Body>
                                            <DateRangePicker
                                                    color="#da6720"
                                                    locale={es}
                                                    showSelectionPreview={false}
                                                    editableDateInputs={true}
                                                    onChange={item => cambiarRangoFecha(item)}
                                                    moveRangeOnFirstSelection={false}
                                                    ranges={[selectionRange]}
                                                    direction="horizontal"
                                                    scroll={{ enabled: true }}
                                                    months={2}
                                                />
                                            </Modal.Body>
                                            <div className="d-flex justify-content-end">
                                                <Button className="shadow-sm m-3" onClick={() => handleClose()}><b>CONFIRMAR</b></Button>
                                            </div>
                                        </Modal>
        
        {viewByTypeView()}
    </div>
}

export default SelectorRangoFechas