import React, { Component, useState} from 'react'
import { useSelector } from 'react-redux'
import { Card, Nav, Tab, Tabs } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import CamposPersonalizadosListado from '../../subComponents/campos-personalizados/list'
import CamposPersonalizadosGeneralListado from '../../subComponents/campos-personalizados-general/list'
import ExcelFormatosListado from '../../subComponents/excel-formats/listado'
import TiposServicioListado from '../../subComponents/ordenes/tipos_servicio/list'
import ListadoCanales from '../../subComponents/canales/listado'
import ServiciosListado from '../../subComponents/servicios/list'
import Personalizar from '../../subComponents/personalizar/personalizar'
import { useQuery } from '../../lib/helpers/router'
import EtiquetasListado from '../../subComponents/etiquetas-relaciones/list'
import HorarioLogisticaListado from '../../subComponents/horario-logistica/list'
import ConfiguracionTorreControl from '../../subComponents/estados_carga/configuracion_torre_control'
import ConfiguracionNotificaciones from '../../subComponents/notificaciones/configuracion_notificaciones'
import FormatosListado from '../../subComponents/formatos/list'
import MediosBox from '../../subComponents/general/mediosbox'
import LineaTiempo from '../LineaTiempo'
import FlujosTrabajoListado from '../../subComponents/flujos-trabajo/list'
import PedidosYaConfiguracion from '../../subComponents/personalizar/pedidos_ya'
import RappiConfiguracion from '../../subComponents/personalizar/rappi'
import { FaGear } from 'react-icons/fa6'
import Automatizaciones from '../../subComponents/automatizacion/list'
import WalmartConfiguracion from '../../subComponents/personalizar/walmart'
import RipleyConfiguracion from '../../subComponents/personalizar/ripley'
import ParisConfiguracion from '../../subComponents/personalizar/paris'
import CuentasFacturacionListado from '../../subComponents/cuentas-facturacion/list'
import PickingConfiguracion from '../../subComponents/personalizar/picking'

const Configuracion = () => {
  const user = useSelector(state => state.miusuario)
  const [selectedTab, setSelectedTab] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  let default_key = 'campos-personalizados'
  if(query.get("tab")) default_key = query.get("tab")
  
  const ifactive = (status) => {
    switch(status){
        case true:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i style={{ color: '#00b23d'}} className="fas fa-check-circle"></i> ACTIVO</p>
        default:
            return <p className="nomargin" style={{ fontSize: 12 }} ><i className="far fa-clock"></i> INACTIVO</p>
    }
}
    
  const misDatos = () => {
      return <Row className="bienvenida">
          <Col xs={12} className="mt-3"> <h5>Mis datos</h5>  </Col>
          <Col md={3}>
              <Card >
                  <Card.Body>
                      <p className="text-primary nomargin" >Estado del usuario</p>
                      <p className="nomargin" style={{ fontSize: 12 }} >{ifactive(user.data.activo) }</p>
                  </Card.Body>
              </Card>
          </Col>
          <Col md={3}>
              <Card >
                  <Card.Body>
                      <p className="text-primary nomargin" >Email</p>
                      <p className="nomargin" style={{ fontSize: 12 }} >{user.data.email }</p>
                  </Card.Body>
              </Card>
          </Col>
      </Row>
  }

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
          pathname: location.pathname,
          search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }


        return(
              <div className="fluid">
          <h2 className="text-primary mb-3" style={{fontWeight:700}}><FaGear /> Configuración general</h2>
          <Tab.Container id="left-tabs-example" defaultActiveKey={default_key}>
        <Row>
          <Col sm={3}>
          <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
              <Nav.Item><Nav.Link eventKey="campos-personalizados">Campos personalizados de órdenes</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="campos-personalizados-general">Campos personalizados</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="automatizacion">Automatizaciones</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="tipos-servicio">Tipos de servicio</Nav.Link></Nav.Item>
              {/* <Nav.Item><Nav.Link eventKey="capacidades-especiales">Capacidades especiales</Nav.Link></Nav.Item> */}
              <Nav.Item><Nav.Link eventKey="servicios">Servicios</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="canales">Canales</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="personalizar">Personalizar</Nav.Link></Nav.Item>
              {/* <Nav.Item><Nav.Link eventKey="formatos-excel">Formatos excel</Nav.Link></Nav.Item> */}
              <Nav.Item><Nav.Link eventKey="library">Bodega digital</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="tags">Identificadores</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="cuentas-facturacion">Cuentas de facturación</Nav.Link></Nav.Item>
              {/* <Nav.Item><Nav.Link eventKey="carritos">Carritos</Nav.Link></Nav.Item> */}
              {/* <Nav.Item><Nav.Link eventKey="horario-logistica">Horario Logistica</Nav.Link></Nav.Item> */}
              <Nav.Item><Nav.Link eventKey="torre-control">Torre de control</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="estpacios-trabajo">Espacios de trabajo</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="pedidos-ya">Pedidos Ya</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="rappi">Uber</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="walmart">Walmart</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="ripley">Ripley</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="paris-cencosud">Paris</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="picking">Picking</Nav.Link></Nav.Item>
            </Nav> 
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="campos-personalizados" >
                <CamposPersonalizadosListado token={user.tokenSession} condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="campos-personalizados-general" >
                <CamposPersonalizadosGeneralListado token={user.tokenSession} condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="automatizacion" >
                <Automatizaciones token={user.tokenSession} condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="personalizar" >
              <Personalizar filtrarItems={["etiqueta"]} />
              </Tab.Pane>
              <Tab.Pane eventKey="canales" >
              <ListadoCanales />
              </Tab.Pane>
              <Tab.Pane eventKey="cuentas-facturacion" >
              <CuentasFacturacionListado />
              </Tab.Pane>
              <Tab.Pane eventKey="tipos-servicio" >
                <TiposServicioListado description={"Los tipos de servicio te ayudan a categorizár el servicio que ofreciste en cada orden de transporte"} title="Tipos de servicio" token={user.tokenSession} condicion_default={{ tipo: "logistica", sub_tipo: "tipos-servicio" }} />
              </Tab.Pane>
              <Tab.Pane eventKey="capacidades-especiales" >
                <TiposServicioListado description={"Las capacidades especiales se utilizan al momento de planificar rutas, las asignas a un vehículo y las órdenes para determinar que vehículo puede llevar cuales órdenes"} title="Capacidades especiales" token={user.tokenSession} condicion_default={{ tipo: "logistica", sub_tipo: "capacidad-especial" }} />
              </Tab.Pane>
              <Tab.Pane eventKey="servicios" >
                <ServiciosListado condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="formatos-excel" >
                <ExcelFormatosListado token={user.tokenSession} condicion_default={{}} />
              </Tab.Pane>
              <Tab.Pane eventKey="tracking-page" >
                <Personalizar />
              </Tab.Pane>
              <Tab.Pane eventKey="library" >
                <MediosBox />
              </Tab.Pane>
              <Tab.Pane eventKey="tags" >
                <EtiquetasListado />
              </Tab.Pane>
              <Tab.Pane eventKey="horario-logistica" >
                <HorarioLogisticaListado />
              </Tab.Pane>
              <Tab.Pane eventKey="torre-control" >
                <ConfiguracionTorreControl />
              </Tab.Pane>
              <Tab.Pane eventKey="notificaciones" >
                <ConfiguracionNotificaciones />
              </Tab.Pane>
              <Tab.Pane eventKey="formatos" >
                <FormatosListado />
              </Tab.Pane>
              <Tab.Pane eventKey="linea-tiempo" >
                <LineaTiempo />
              </Tab.Pane>
              <Tab.Pane eventKey="estpacios-trabajo" >
                <FlujosTrabajoListado />
              </Tab.Pane>
              <Tab.Pane eventKey="picking" >
                <PickingConfiguracion />
              </Tab.Pane>
              <Tab.Pane eventKey="pedidos-ya" ><PedidosYaConfiguracion /></Tab.Pane>
              <Tab.Pane eventKey="rappi" ><RappiConfiguracion /></Tab.Pane>
              <Tab.Pane eventKey="walmart" ><WalmartConfiguracion /></Tab.Pane>
              <Tab.Pane eventKey="ripley" ><RipleyConfiguracion /></Tab.Pane>
              <Tab.Pane eventKey="paris-cencosud" ><ParisConfiguracion /></Tab.Pane>
            </Tab.Content>
          </Col>
          </Row>
        </Tab.Container>
      </div>
    )
    }

export default Configuracion