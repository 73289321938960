import { Card, Tab, Tabs } from "react-bootstrap";
import ListadoAlertas from "./listado";

const Alertas = (props) => {

        return <div>
            <h4 className="mb-2 mt-0">Alertas</h4>
            <Tabs defaultActiveKey="1" >
              <Tab eventKey="1" title="Informativas" className="pt-2"><ListadoAlertas condicion_default={{ tipo_notificacion: "info" }} /></Tab>
              <Tab eventKey="2" title="Advertencias" className="pt-2"><ListadoAlertas condicion_default={{ tipo_notificacion: "warning" }} /></Tab>
              <Tab eventKey="3" title="Problemas" className="pt-2"><ListadoAlertas condicion_default={{ tipo_notificacion: "error" }} /></Tab>
            </Tabs>
        </div>
    
}

export default Alertas