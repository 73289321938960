import Map, { Layer, Marker, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import Pin from '../../subComponents/mapas/pin';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import { BsBoxArrowInUpRight, BsFillSignStopLightsFill, BsFillStopCircleFill } from 'react-icons/bs';
import { estilo_last_mile } from '../../lib/global/styles';
import { duracionTiempoString, fechaUTCATiempo } from '../../lib/helpers/helpers';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DateTime } from 'luxon';
import polyline from '@mapbox/polyline';

const MapaInformeTracking = (props) => {
    const {
        height,
        markers,
        poligonos,
        paradas,
        rutas,
        pinType
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const centrarMapa = (centro, max_zoom) => {
        const nuevo_zoom = viewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

    const ajustarCentro = (markers) => {
        console.log(markers)
        if(markers.length < 1) return false
        if(markers.length === 1){
            return centrarMapa({ latitude: markers.coordinates[1], longitude: markers.coordinates[0] }, 6)
          }
        setZoomInit(true)
        const points = markers.map(marker => turf.point([marker[0], marker[1]]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

    const handleLoadMap = () => {
        let coordinates = []

        poligonos.map(pol => {
            if(!pol.geometry) return false
            if(typeof pol.geometry !== "object") return false
            if(!pol.geometry.coordinates) return false
            if(Array.isArray(pol.geometry.coordinates) !== true) return false
            coordinates = [...coordinates, ...pol.geometry.coordinates]
        })
        console.log({ coordinates })
        ajustarCentro(coordinates)
    }

      const refrescarListado = async ()=>{
        console.log("se dispara")
        return fetch(`${urlapi}/conductores/vehicles-actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    const showPin = () => {
        const tipo = typeof pinType !== "undefined" ? pinType : false
        switch (tipo) {
            case "uber":
                return <BsFillStopCircleFill size={15} />
            default:
                return <Pin />
        }
    }

    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 600}}
    pitch={25}
    onLoad={handleLoadMap}
    mapStyle="mapbox://styles/mapbox/light-v9"
    // mapStyle="mapbox://styles/mapbox/streets-v9"
    mapboxAccessToken={mapbox_token}
  >
               {
                    paradas.map((marker,pos) => {
                        const inicio = DateTime.fromISO(marker.desde)
                        const fin = DateTime.fromISO(marker.hasta)
                        let diferencia = "Parada"
                        const diferencia_minutos = fin.diff(inicio, "minutes")
                        if(diferencia_minutos.values){
                            if(typeof diferencia_minutos.values === "object"){
                                if(diferencia_minutos.values.minutes) diferencia = `Parada de ${duracionTiempoString(diferencia_minutos.values.minutes)}`
                            }
                        }

                        const content = (<div>
                            <BsFillSignStopLightsFill size={30} />
                            <p className='mb-0'>{`Desde ${fechaUTCATiempo(marker.desde)}`}</p>
                            <p className='mb-0'>{`Hasta ${fechaUTCATiempo(marker.hasta)}`}</p>
                            <a className='text-white' href={`https://www.google.com/maps?q=${marker.coordinates[1]},${marker.coordinates[0]}`} target='_blank'><BsBoxArrowInUpRight /> VER EN GOOGLE</a>
                        </div>)
                        return <Marker
                                key={marker._id}
                                longitude={marker.coordinates[0]}
                                latitude={marker.coordinates[1]}
                            >
                                <div>
                                <OverlayTrigger
            key={'right'}
            trigger="click"
            overlay={
                <Tooltip id={'right'}>{content}</Tooltip>
            }>
                <BsFillStopCircleFill size={20} />
            </OverlayTrigger>
                                </div>
                        </Marker>
                    })
                }
               {
                    poligonos.map( (poligono,i) => {
                        return <Source key={`pol-${i}`} type="geojson" data={poligono}>
                                <Layer
                                id={`pol-${i}`}
                                type="line"
                                source="my-data"
                                layout={{
                                    'line-join': 'round',
                                    'line-cap': 'round',
                                }}
                                paint={{
                                    'line-color': estilo_last_mile.color_secondary,
                                    'line-width': 4,
                                }}
                                />
                            </Source>
                    })
               }
               {
                  rutas.map((geometry,ir) => {

                      const decodedCoords = polyline.decode(geometry.geometry)
                      
                      return <Source
                          id={`route-${ir}`}
                          type='geojson'
                          data={{
                              type: 'Feature',
                              properties: {},
                              geometry: {
                                  type: "LineString",
                                  coordinates: decodedCoords.map(co => [co[1], co[0]])
                              }
                            }}
                      >
                          <Layer
                          id={`route-layer-${ir}`}
                          type="line"
                          layout={{
                              'line-join': 'round',
                              'line-cap': 'round',
                            }}                
                          paint={{
                              'line-color': estilo_last_mile.color_primary,
                              'line-width': 4,
                              'line-opacity': 0.75
                          }}
                      />
                      </Source>
                  })
              }
    {
        marcadores.map(marker => {
            const content = (
                <div>
                    <p>{marker.fecha_}</p>
                </div>
            )
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                <div>
                    {showPin()}
                </div>
            </Marker>
        })
    }
  </Map>
}

export default MapaInformeTracking