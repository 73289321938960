import { Button, Card, Col, Form, Modal, Row, Spinner } from "react-bootstrap"
import { useEffect, useState } from "react"
import { FaEye } from "react-icons/fa"
import Tracking from '../../components/Tracking';
import { urlapi } from "../../lib/backend/data";
import { useSelector } from "react-redux";

const PreviewTrackingWeb = (props) => {
    const {
        estructuraActual,
        components
    } = props
    const gris = '#e5e5e5'
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [ id_local, setIdLocal ] = useState("")
    const [ loading, setLoading ] = useState(false)

    const getOrders = async ()=>{
        setLoading(true)
        return fetch(`${urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion: {},
                tipo_condicion: "",
                filtro_zona: false,
                pagina: 1,
                gestionadas: false,
                filtros_especiales: {}
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(res => res.json())
        .then(res => {
            if(Array.isArray(res?.datos) !== false){
                if(res.datos.length > 0){
                    setIdLocal(res.datos[0]._id)
                }
            }
            console.log("res", res)
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    useEffect(() => {
        getOrders()
    }, [])


    const showModal = () => {
        setIsModalOpen(true);
    }

    const handleCancel = () => {
        setIsModalOpen(false);
    }
    
    const opcionesImagen = (type, campo) => {
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].allowPhoto === true){
                    return <div className="text-center w-100 mb-3">
                        { estructuraActual.modulos[i].imagen ? <img style={{ margin: "0 auto", height: 80 }} src={estructuraActual.modulos[i].imagen} /> : false }
                    </div>
                }
            }
        }
    }

    const mostrarBoton = (type, campo) => {
        if(type === "modulo"){
            let activo = false
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            let onlyRead = false
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                onlyRead    = estructuraActual.modulos[i].onlyRead === true
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }

            if(!activo) return false
            return <div className="text-center">
                        {opcionesImagen(type, campo)}
                    <Row>
                        <Col>{components[campo]}</Col>
                        <Col xs={12}>
                            <p>{extra}</p>
                        </Col>
                    </Row>
                </div>
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            let onlyRead = false
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                onlyRead = estructuraActual.campos[i_].onlyRead === true
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Group key={`${type}-${campo}`} className="mb-3" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" disabled={onlyRead} checked={activo} label={titulo} />
                </Form.Group>
            </div>
        }
    }

    return <div>
        <Button size="sm" className="mb-3" variant="light" onClick={showModal}><FaEye /> PREVISUALIZAR</Button>

        <Modal size="xl" show={isModalOpen} open={() => showModal()} onHide={handleCancel}>
        <Modal.Header closeButton>
                <Modal.Title>Previsualizar web de tracking</Modal.Title>
        </Modal.Header>
        <Card >
            {loading ? <Spinner animation="border" /> : false }
            {id_local ? <Tracking id_local={id_local} /> : false }
        </Card>
        </Modal>
    </div>
}

export default PreviewTrackingWeb