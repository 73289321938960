import { useState } from "react"
import { Alert, Badge, Button, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import data, { url_front_produccion } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import { module_, statuses_invitacion } from "./data";
import { FaSearch, FaUnlockAlt } from "react-icons/fa";
import { estilo_last_mile } from "../../lib/global/styles";
import { DateTime } from "luxon";
import { agregarCero } from "../../lib/helpers/helpers";
import AuditarConsumoAno from "./auditarConsumoAno";

const AuditarConsumo = (props) => {
    const {
        modulo,
        action,
        account_id,
        hideYear
    } = props
    const [ loading, setLoading] = useState(false)
    const [ operacion, setOperacion ] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const [ ano, setAno ] = useState(DateTime.now().year)
    const [ mes, setMes ] = useState(agregarCero(DateTime.now().month))
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()
    
    const consultar = async () => {
        if(!mes || !ano) return toast.error("Debes seleccionar un mes y un año")
        setLoading(true)
        return fetch(`${data.urlapi}/${module_}/audit`, {
            method: 'POST',
            body: JSON.stringify({
                modulo,
                action,
                account_id,
                mes,
                ano
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){                   
                    return toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){                   
                     toast.error(pros.errorMessage)
                     return setLoading(false)
              } else if(typeof pros.operaciones !== "undefined"){
                setOperacion(pros)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const mostrarDatos = () => {
        if(loading) return <div className="mt-3"><Spinner animation="border" variant="primary" /></div>

        return <div className="mt-3">
            <Card className="shadow p-3" >
            <h5 className="mb-0"><b>Operaciones realizadas</b></h5>
            <h3 className="mb-0">{operacion.operaciones ? operacion.operaciones : 0}</h3>
            </Card>
        </div>
    }

    const abrirModal = () => {
        consultar()
        setShowModal(true)
    }

    const View = () => {
        return <div>
            <Modal show={showModal} size="xl"  onHide={() => setShowModal(false)}
        centered
        >
        <Modal.Header closeButton>
            <h4>Auditar consumo</h4>
        </Modal.Header>
        <Modal.Body>
            <Badge style={{ textTransform: "uppercase" }} className="mb-3" variant="dark" >{modulo}</Badge>
            <Badge style={{ textTransform: "uppercase" }} className="mb-3" variant="success" >{action}</Badge>
            <h4><b>Auditar por mes</b></h4>
            <Row>
                <Col>
                <select className='form-control shadow-sm' value={mes} onChange={(e) => setMes(e.target.value)} >
                <option value="01">Enero</option>
                <option value="02">Febrero</option>
                <option value="03">Marzo</option>
                <option value="04">Abril</option>
                <option value="05">Mayo</option>
                <option value="06">Junio</option>
                <option value="07">Julio</option>
                <option value="08">Agosto</option>
                <option value="09">Septiembre</option>
                <option value="10">Octubre</option>
                <option value="11">Noviembre</option>
                <option value="12">Diciembre</option>
              </select>
              </Col>
                <Col>
                <input className="form-control" type="number" value={ano} onChange={(e) => setAno(e.target.value)} />
                </Col>
                <Col><Button className="w-100" variant="light" onClick={() => consultar()} disabled={loading}>CONSULTAR CONSUMO</Button> </Col>
            </Row>
            {mostrarDatos()}
            { hideYear === true ? false : <div className="mt-3"><hr/><AuditarConsumoAno modulo={modulo} action={action} account_id={account_id} /></div> }
        </Modal.Body>
        
      </Modal>

        <Button size="sm" variant="light" onClick={() => abrirModal()}  ><FaSearch style={estilo_last_mile.icon} /> AUDITAR CONSUMO</Button>
        </div>
        
}

    return <div>
        {View()}
    </div>
}

export default AuditarConsumo