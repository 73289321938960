import React from "react";
import { useState, useContext } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger, Row, Col, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { url_images } from "../../lib/global/data";
import { rutas } from "../../lib/routes/routes";
import { estilo_last_mile } from "../../lib/global/styles";
import { FaArrowRight } from "react-icons/fa";
import { data_landing } from "../landing/data";
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { BiSolidHelpCircle } from "react-icons/bi";
import { AiOutlineCode } from "react-icons/ai";
import { MdOutlineWebhook, MdSyncAlt } from "react-icons/md";
import { PiFileHtmlDuotone } from "react-icons/pi";
import { BsCodeSlash, BsPlugin } from "react-icons/bs";


const tabs = [
    {
        slug: "apikeys",
        title: "API Keys",
        image: `${url_images}/animations/Web development.gif`,
        text: "Las API Key son llaves de conexión que sirven para utilizar nuestra API, con ella podrás conectar otros sistemas y mejorar la eficiencia en tus procesos",
        steps: [],
    },
    {
        slug: "webhooks",
        title: "Webhooks",
        image: `${url_images}/animations/Robotic process automation.gif`,
        text: "Conoce los beneficios del tiempo real con esta tecnología que te permitirá automatizar tus procesos en tiempo real, imagina que se pueda generar un ticket de soporte en tu CRM cuando el conductor notifique que hay un problema con una carga.",
        steps: [],
    },
    {
        slug: "widgets",
        title: "Widgets",
        image: `${url_images}/animations/courier-tracking.gif`,
        text: "Con esta util herramienta podrás incrustar un tracking personalizado en tu sitio web, de esa forma tus clientes podrán consultar el estado de sus entregas",
        steps: [
            // {
            //     step: "03",
            //     title: "Integra un tracking personalizado",
            //     stepImg: `${url_images}/animations/orders.gif`,
            //     text: "Integra un sistema de seguimiento personalizado en tu sitio web para ofrecer una experiencia de rastreo transparente y confiable"
            // },
        ],
    },
    {
        slug: "functions",
        title: "Funciones",
        image: `${url_images}/animations/modules/Female Web Developer.gif`,
        text: "Imagina que debes conectar el API de Last Mile al API de Hubspot (CRM), Este espera la información de una manera que no es la misma de Last Mile, con las funciones podrás transformar los esquemas para hacerlos conversar sin escribir una línea de código",
        steps: [],
    },
    {
        slug: "apiless",
        title: "Endpoints",
        image: `${url_images}/animations/App development.gif`,
        text: "Desarrollamos un poderoso sistema que te permitirá conectar LastMile con tus sistemas sin necesidad de programar una sola línea de código, conócelo",
        steps: [ ],
    },
    {
        slug: "plugins",
        title: "Plugins",
        image: `${url_images}/animations/modules/Market Analysis.gif`,
        text: "Con nuestros plugins de conexión a plataformas líderes en comercio electrónico podrás automatizar tu logística, recibe la información de tus ventas en tiempo real en Last Mile",
        steps: [],
    },
    {
        slug: "importar",
        title: "¿Cómo importar rutas?",
        // image: `${url_images}/animations/modules/Market Analysis.gif`,
        video: "https://tremus.cl/wp-content/uploads/2023/10/Untitled-Video.mp4",
        text: "Para importar rutas por excel solo deberás contar con una plantilla de excel con los datos, incluso puedes configurar tu plantilla para que Last Mile entienda tu formato",
        steps: [],
    },
    {
        slug: "cabeceras",
        title: "Valida que solo tu utilizes este punto de conexión",
        image: `${url_images}/animations/3630439.gif`,
        // video: "https://tremus.cl/wp-content/uploads/2023/10/Untitled-Video.mp4",
        text: "Protege tus puntos de conexión",
        steps: [],
    },
    {
        slug: "eventos_sqs",
        title: "Cada evento válido que es recibido por el punto de enlace se registra en esta sección",
        image: `${url_images}/animations/8048987.gif`,
        // video: "https://tremus.cl/wp-content/uploads/2023/10/Untitled-Video.mp4",
        text: "Accede a un histórico de como se registra la información",
        steps: [],
    },
]

const LISTA_MODULOS = tabs.map(e => {
    return e
})

const HelperIntegraciones = (props) => {
    const {
        tipo,
        align,
        style
    } = props

    const [isOpen, setIsOpen] = useState(false);
    const route = useLocation();

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const mostrarContenido = (info) => {
        return <div>
            { info.image ? <img src={info.image} style={{ maxWidth: 250, marginBottom: -20, marginTop: -40 }} /> : false }
                <Row className='justify-content-center'>
                    <Col md={12} xs={12}>
                        <h1 className="masthead-heading mb-0" style={{ fontWeight: 900, color: estilo_last_mile.color_primary, fontSize: 20 }}>{info.title}</h1>
                        <h2 className="masthead-subheading mb-3" style={{ fontWeight: 400, fontSize: 15, lineHeight: 2 }}>{info.text}</h2>
                    </Col>
                </Row>
                { info.video ? <video controls style={{ maxWidth: "100%", height: 400 }}  >
                <source src={info.video} type="video/mp4" />
                </video> : false }
                {
                    info.steps ? <Row className="mb-3 text-primary">
                        {info.steps.map((box, i) => {
                            return <>
                            <Col key={`item-${i}`} >
                                <div className="border rounded shadow-sm text-center h-100">
                                    <h4 className='my-0 pt-1 text-danger' style={{fontWeight:900, fontSize:20}}>{box.step}</h4>
                                    <h4 className='my-0' style={{fontWeight:700, fontSize:18}}>{box.title}</h4>
                                    <img src={box.stepImg} style={{maxWidth:150}}/>
                                    <p className='px-1' style={{fontSize:12}}>{box.text}</p>
                                </div>
                            </Col>
                            </>
                        })}
                    </Row> : false
                }
        </div>
    }

    if (!tipo) return false
    const i = LISTA_MODULOS.findIndex(mo => mo.slug === tipo)
    if (i < 0) return false

    const info = LISTA_MODULOS[i]

    if (style === "modal") return <div>
        <div style={{ textAlign: align ? align :"right" }}><Button size="sm" variant="link" onClick={() => setIsOpen(true)}><BiSolidHelpCircle /> Más Información</Button></div>
        <Modal show={isOpen} size="lg" onHide={() => handleClose()}
            centered
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className="text-center">
            {mostrarContenido(info)}
            </Modal.Body>
        </Modal>
    </div>

    return <div>{mostrarContenido(info)}</div>

};

export default HelperIntegraciones