import { useEffect, useRef, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import Select from 'react-select';
import ReactSelect from 'react-select'
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SelectorRegla = (props) => {
    const {
        propietario,
        defaultValue,
        disabled,
        tipo_dato
    } = props
    const initial_params = [
        { titulo: "Ecommerce" },
        
    ]
    const [ reglas, setReglas ] = useState(initial_params)
    const [ estadosCarga, setEstadosCarga ] = useState([])
    const [ loadingReglas, setLoadingReglas ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const isMulti = typeof props.isMulti !== "undefined" ? true : false
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const full_payload = typeof props.full_payload !== "undefined" ? true : false
    const modulo = tipo_dato === "subestados" ? "subestados" : "estados"
    const selectRef = useRef(null)

    const handleChangeSelectEstado = (e) => {
        if(isMulti === true){
            let opciones = []
            if(e && Array.isArray(e)){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            if(props.onChange) props.onChange(opciones)
            return setFiltroEstado(opciones)
        } else {
            let data = e
            if(props.onChange) props.onChange(data)
            return e
        }
    }

    useEffect(() => {
        obtenerReglas()
    }, [])

    const obtenerReglas = async () => {
        setLoadingReglas(true)
        const url = `${urlapi}/reglas/list/select`;
        let options = []
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                condicion: {}
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error("Sin datos")
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
            } else if(Array.isArray(pros) !== false){
                setReglas(pros)
            }
            setLoadingReglas(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingReglas(false)
        })
    }


    return <div className="mb-3 text-primary">
                <label className='form-control-label d-block text-primary' style={{fontWeight:700}}>Selecciona una regla de negocio</label>
                        <ReactSelect
                            style={{ marginBottom: 10 }}
                            onChange={handleChangeSelectEstado}
                            options={reglas.map(e => ({ value: e._id, label: e.titulo }))}
                            isLoading={loadingReglas}
                            placeholder="Filtrar regla..."
                          />
    </div>
}

export default SelectorRegla