import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { colores, mapbox_token, urlapi } from '../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import * as turf from '@turf/turf';
import { RiMapPinTimeFill } from 'react-icons/ri'
import { colores_diversos } from '../../lib/global/data';
import { obtenerCentroMapaPorPais } from '../../lib/helpers/data/internationa';
import polyline from '@mapbox/polyline';
import { estilo_last_mile } from '../../lib/global/styles';

const MapaRutaPlanificada = (props) => {
    const {
        rutas,
        height,
        resize,
        markers
    } = props
    const [ marcadores, setMarcadores ] = useState([])
    const sesion = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    const ajustarCentro = (puntos) => {
        if(zoomInit) return false
        if(puntos.length < 1) return false
        setZoomInit(true)
        const points = puntos.map(marker => turf.point([marker.longitude, marker.latitude]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current?.fitBounds(bounds, options);
    }

    const procesarPuntos = () => {
        const puntos = []
        rutas.map((ruta,i) => {


            if(ruta.pedidos){
                for( const e of ruta.pedidos ){
                    if(!e.location) return false
                    if(typeof e.location !== "object") return false
                    if(!e.location.coordinates) return false
                    if(Array.isArray(e.location.coordinates) !== true) return false
                    if(e.location.coordinates.length < 2) return false
                    const lat = e.location.coordinates[1]
                    const lng = e.location.coordinates[0]

                    puntos.push({
                        _id: e._id,
                        pos: i,
                        lat,
                        lng
                    })
                }
            }

        })
        setMarcadores(puntos)
        return puntos
    }

      useEffect(() => {

        const puntos = procesarPuntos()
        if(mapRef) if(mapRef.current) mapRef.current.resize()

        setTimeout(() => {

            let points = []

            rutas.map(ruta => {
                if(!ruta.stops) return false
                if(!Array.isArray(ruta.stops)) return false
                return ruta.stops.map((stop,ir) => {
                    if(!stop?.location_metadata?.snapped_coordinate) return false
                    if(!Array.isArray(stop?.location_metadata?.snapped_coordinate)) return false
                    const lat = stop.location_metadata.snapped_coordinate[1]
                    const lng = stop.location_metadata.snapped_coordinate[0]
                    points.push({ latitude: lat, longitude: lng })
                })
            })
            ajustarCentro(points)
        }, 500);
        
      }, [ rutas, resize ])

      const refrescarListado = async ()=>{
        console.log("se dispara")
        return fetch(`${urlapi}/conductores/vehicles-actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return res.json() //dispatch(cerrarSesio())
            return res.json()
        })
        .then(res => {
            if(!res){
                return false
            } else if(res.errorMessage){
                return false
            } else if(Array.isArray(res) !== false){
                ajustarCentro(res.map(marker => ({ latitude: marker.lat, longitude: marker.lng })))
                setMarcadores(prev => [...[], ...res])
            }
            return false
        })
        .catch(error => {
            return false
        })
    }

    return <Map
    ref={mapRef}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: 400}}
    pitch={25}
    mapStyle="mapbox://styles/mapbox/light-v9"
    mapboxAccessToken={mapbox_token}
  >
    <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
    {
        rutas.map(ruta => {
            if(!ruta.stops) return false
            if(!Array.isArray(ruta.stops)) return false
            return ruta.stops.map((stop,ir) => {
                if(!stop?.location_metadata?.snapped_coordinate) return false
                if(!Array.isArray(stop?.location_metadata?.snapped_coordinate)) return false
                const lat = stop.location_metadata.snapped_coordinate[1]
                const lng = stop.location_metadata.snapped_coordinate[0]

                let first = ir === 0
                let last = ir === (ruta.stops.length - 1)
                let colorByPosition = first ? { background: estilo_last_mile.color_primary, color: "white" } : last ? { background: estilo_last_mile.color_secondary, color: "white" } : { background: "white", color: "black" }
                return <Marker
                key={`mark-${ir}`}
                longitude={lng}
                latitude={lat}
            >
            <div className='mt-0 mb-0' style={{ display: "inline-flex", borderRadius: "50%", margin:0, padding: 5, backgroundColor: colorByPosition.background, fontWeight: "bold", border: `1px solid ${estilo_last_mile.color_primary}` }}><h3 style={{ margin: 0, fontSize: 12, color: colorByPosition.color, }} level={5} >{(ir+1)}</h3></div>
        </Marker>
            })
        })
    }
    {
        rutas.map(ruta => {
            if(!ruta.geometrias) return false
            if(!Array.isArray(ruta.geometrias)) return false
            return ruta.geometrias.map((geometry,ir) => {

                const decodedCoords = polyline.decode(geometry)
                
                return <Source
                    id={`route-${ir}`}
                    type='geojson'
                    data={{
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates: decodedCoords.map(co => [co[1], co[0]])
                        }
                      }}
                >
                    <Layer
                    id={`route-layer-${ir}`}
                    type="line"
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                      }}                
                    paint={{
                        'line-color': estilo_last_mile.color_primary,
                        'line-width': 2.5,
                        'line-opacity': 1
                    }}
                />
                </Source>
            })
        })
    }
  </Map>
}

export default MapaRutaPlanificada