import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Header from '../Header'
import { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import { cerrarSesion } from '../../redux/actions/session'
import ListaPreciosListado from '../../subComponents/bodega/listaPrecios/list'

class ListaPrecios extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user))
        }
    }
    render(){
        const { user } = this.state
        return <ListaPreciosListado token={user.tokenSession} condicion_default={{}} />
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListaPrecios);