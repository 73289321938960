import { useState } from "react"
import { Button, Modal } from "react-bootstrap"
import { createTheme, Step, StepLabel, Stepper, ThemeProvider } from "@mui/material";
import { IoMdHelpCircle } from "react-icons/io";
const theme = createTheme({ })

const ModalFasesTrabajo = (props) => {
    const {
        fases
    } = props
    const [ showModal, setShowModal ] = useState(false)

    const handleCancel = () => setShowModal(false)


    if(!fases) return null
    if(!Array.isArray(fases)) return null

    return <div style={{ display: "inline" }}>
        <span style={{ fontSize: 12 }} className="hover" onClick={() => setShowModal(true)}><b style={{ fontWeight: "bold" }}>Más información</b> <IoMdHelpCircle /></span>
        <Modal
            title="Esta este es un ejemplo de tu línea de tiempo" 
            show={showModal} 
            // onOk={handleOk} 
            onHide={handleCancel}
            // confirmLoading={loading}
        >
            <div className="p-3">
                <h3><b>Las fases de trabajo</b></h3>
                <p>Forman parte del flujo de trabajo designado como tu responsabilidad, las fases son las etapas que deberás ejecutar a medida que cumples tus funciones.</p>
            <ThemeProvider theme={theme}>
            <Stepper activeStep={fases} alternativeLabel>
                    {
                        fases.map((hito, index) => {
                            return <Step md={4} key={`hito-${(index+1)}`}>
                            <StepLabel><div>
                            <h6 style={{ textTransform: "uppercase" }}><b>{(index+1)} {hito.titulo}</b></h6>
                            </div></StepLabel>
                        </Step>
                        })
                    }
            </Stepper>
            </ThemeProvider>
            </div>
            {/* <Steps
                current={lineaTiempoSeleccionada?.hitos.length - 1}
                style={{ marginTop: 20 }}
                items={lineaTiempoSeleccionada?.hitos.map( (estado,i) => {
                    return {
                        title: estado.titulo,
                        description: fechaUTCATexto(DateTime.now().plus({ minutes: (i+10) }).toUTC().toISO()),
                        // icon: <FiBox />
                    }
                })}
            /> */}
    </Modal>
    </div>
}

export default ModalFasesTrabajo;