import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { urlapi } from "../../lib/backend/data";
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { TbRoute } from "react-icons/tb";
import RutasListadoV2 from "../rutas/list/list-v2";
import { cerrarSesion } from "../../redux/actions/session";

const AsignarRutaMasivo = (props) => {
    const {
        ids_orders,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [dataResponse, setDataResponse] = useState(null);
    const [ loading, setLoading] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()

    const asignarRuta = async (id_ruta) => {
        if(!id_ruta) return
        const url = `${urlapi}/rutas/asignar-ordenes`
        setLoading(true)
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify({
                id_ruta,
                ids_orders
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                } else if (res.success) {
                    toast.success('Realizado exitosamente')
                    if(props.onFinish) props.onFinish()
                }
                return setLoading(false)
            })
            .catch(error => {
                toast.error(error.message)
                return setLoading(false)
            })
    }
    
    const onSelect = (data) => {
        console.log(data)
        setShowModal(false)
        asignarRuta(data)
    }

    if(!ids_orders) return false;
    if(!Array.isArray(ids_orders)) return false;
    if(ids_orders.length < 1) return false;
    
    

    return (
        <div style={{ display: "inline-block" }}>
            {/* ... */}
            <Button variant="outline-secondary" 
                disabled={loading}
                className="shadow-sm mb-2 mr-2 mt-0 py-1" 
                onClick={() => { 
                    setShowModal(true);
                }}><TbRoute /> <b>{ loading ? "MOVIENDO..." : "MOVER A RUTA"}</b></Button>
    
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton className="pb-0">
                    <Modal.Title className="text-primary text-center"><TbRoute /> Asignación de rutas
                    <p className="text-muted">Seleccionaste {ids_orders.length} órdenes</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-auto border-top border">
                        {/* { loadingEstados ? <Spinner animation="border" /> : <Button variant='sm' className="btn btn-success shadow-sm mt-0 mb-2 mr-4" onClick={handlePrint}><TbRoute /> <b>MOVER A RUTA</b></Button>} */}
                    <RutasListadoV2 typeView="funcion" onSelect={(data) => onSelect(data)} />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AsignarRutaMasivo;