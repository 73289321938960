import { useState } from "react"
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import * as XLSX from 'xlsx'
import { esquema_formato_base, esquema_ordenes_requeridas } from "../../lib/esquemas";
import Select from 'react-select';
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ columnasCargadas, setColumnasCargadas ] = useState([])
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()
    const destinos_formatos = [
        {
            nombre: "importacion-ruta",
            columnas: esquema_ordenes_requeridas
        },
        {
            nombre: "importacion-formato-base",
            columnas: esquema_formato_base
        },
    ]

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const handleInputChangeExcelEdicion = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        setColumnasCargadas(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER
                        
            let columnas_detectadas = []
            hojas[0].map((row,irow) => {
                Object.keys(row).filter(e => e).map(name => {
                    const i = columnas_detectadas.findIndex(nombre => nombre === name)
                    if(i < 0) columnas_detectadas.push(name)
                })
            })
            console.log({columnas_detectadas})
            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }

            const iteradas = columnas_detectadas.map(nombre => ({ key: nombre, target: "" }))

            setLoadingExcel(false)
            proveedor.columnas = iteradas
            return setProveedor(prev => ({...{}, ...proveedor }))
          }
        }
    }
    const handleInputChangeExcel = (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        setColumnasCargadas(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER
                        
            let columnas_detectadas = []
            hojas[0].map((row,irow) => {
                Object.keys(row).filter(e => e).map(name => {
                    const i = columnas_detectadas.findIndex(nombre => nombre === name)
                    if(i < 0) columnas_detectadas.push(name)
                })
            })
            console.log({columnas_detectadas})
            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }

            const iteradas = columnas_detectadas.map(nombre => ({ key: nombre, target: "" }))

            setLoadingExcel(false)
            return setColumnasCargadas(iteradas)
          }
        }
    }
    const crearNuevo = async () => {
        if(!nuevoProveedor.modulo_destino) return toast.error('Selecciona un formato destino')
        const i_destino = destinos_formatos.findIndex(d => d.nombre === nuevoProveedor.modulo_destino)
        if(i_destino < 0) return toast.error('Selecciona un formato destino')
        let nombres_requeridos = destinos_formatos[i_destino].columnas.map(p => p)
        for( const col of columnasCargadas ){
            const { target } = col
            if(!target) continue
            const pos = nombres_requeridos.findIndex(p => p === target)
            if(pos > -1) nombres_requeridos.splice(pos,1)
        }
        // if(nombres_requeridos.length > 0) return toast.error(`Falta definir valores de: ${nombres_requeridos.join(', ')}`)

        setLoading(true)

        return fetch(`${data.urlapi}/excel`, {
            method: 'POST',
            body: JSON.stringify({
                titulo: nuevoProveedor.titulo,
                modulo_destino: nuevoProveedor.modulo_destino,
                columnas: columnasCargadas.filter(c => c.target)
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor')
                    return setLoading(false)
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage)
                    return setLoading(false)
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
            console.log(error.message)
                toast.error('No se pudo realizar esta operación')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {

        const i_destino = destinos_formatos.findIndex(d => d.nombre === proveedor.modulo_destino)
        let nombres_requeridos = destinos_formatos[i_destino].columnas.map(p => p)
        for( const col of proveedor.columnas ){
            const { target } = col
            if(!target) continue
            const pos = nombres_requeridos.findIndex(p => p === target)
            if(pos > -1) nombres_requeridos.splice(pos,1)
        }
        // if(nombres_requeridos.length > 0) return toast.error(`Falta definir valores de: ${nombres_requeridos.join(', ')}`)


        setLoading(true)
        return fetch(`${data.urlapi}/excel`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor')
                return setLoading(false)
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return setLoading(false)
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        return setNuevoProveedor(nuevoProveedor)
    }

    const handleChangeCol = (e) => {
        const { value } = e.target
        const pos = e.target.getAttribute('pos')
        const numero_posicion = parseInt(pos)
        columnasCargadas[numero_posicion].target = value
        console.log(columnasCargadas)
        return setColumnasCargadas(prev => [...[], ...columnasCargadas])
    }
    
    const handleChangeColEdicion = (e) => {
        const { value } = e.target
        const pos = e.target.getAttribute('pos')
        const numero_posicion = parseInt(pos)
        proveedor.columnas[numero_posicion].target = value
        console.log(proveedor)
        return setProveedor(prev => ({...{}, ...proveedor}))
    }

    const concatenarColumna = (i,edit) => {
        if(edit === true){
            if(!proveedor.columnas[i].concatenar) proveedor.columnas[i].concatenar = []
            proveedor.columnas[i].concatenar.push({
                texto: "",
                columna: ""
            })
            return setProveedor(prev => ({...{}, ...proveedor}))
        } else {
            if(!columnasCargadas[i].concatenar) columnasCargadas[i].concatenar = []
            columnasCargadas[i].concatenar.push({
                texto: "",
                columna: ""
            })
            return setColumnasCargadas(prev => [...[], ...columnasCargadas])
        }
    }
    const showModalColumna = (i,edit) => {
        if(edit === true){
            proveedor.columnas[i].show_modal = true
            return setProveedor(prev => ({...{}, ...proveedor}))
        } else {
            columnasCargadas[i].show_modal = true
            return setColumnasCargadas(prev => [...[], ...columnasCargadas])
        }
    }
    
    const handleClose = (edit) => {
        if(edit === true){
            const nuevas_col = proveedor.columnas.map(o => {
                o.show_modal = false
                return o
            })
            proveedor.columnas = nuevas_col
            return setProveedor(prev => ({...{}, ...proveedor}))
        } else {
            const nuevas_columnas = columnasCargadas.map(o => {
                o.show_modal = false
                return o
            })
            return setColumnasCargadas(prev => [...[], ...nuevas_columnas])
        }
    }

    const handleChangeConcat = (e,posicion_columna, posicion_concatenacion,edit) => {
        if(edit === true){
            proveedor.columnas[posicion_columna].concatenar[posicion_concatenacion].columna = e.value
            return setProveedor(prev => ({...{}, ...proveedor}))
        } else {
            columnasCargadas[posicion_columna].concatenar[posicion_concatenacion].columna = e.value
            return setColumnasCargadas(prev => [...[], ...columnasCargadas])
        }
    }
    
    const removerConcat = (posicion_columna, posicion_concatenacion,edit) => {
        if(edit === true){
            proveedor.columnas[posicion_columna].concatenar.splice(posicion_concatenacion,1)
            return setProveedor(prev => ({...{}, ...proveedor}))
        } else {
            columnasCargadas[posicion_columna].concatenar.splice(posicion_concatenacion,1)
            return setColumnasCargadas(prev => [...[], ...columnasCargadas])
        }
    }

    const handleChangeTexto = (e, posicion_columna, posicion_concatenacion,edit) => {
        if(edit === true){
            proveedor.columnas[posicion_columna].concatenar[posicion_concatenacion].texto = e.target.value
            return setProveedor(prev => ({...{}, ...proveedor}))
        } else {
            columnasCargadas[posicion_columna].concatenar[posicion_concatenacion].texto = e.target.value
            return setColumnasCargadas(prev => [...[], ...columnasCargadas])
        }
    }

    const boxConcatenacion = (row,i,edit) => {

        

        return <Modal show={row.show_modal} onHide={()=> handleClose(edit)} centered 
      backdrop="static"
      keyboard={false}
      >
          <Modal.Header closeButton >
            <Modal.Title>{row.key} a {row.target ? row.target : "no seleccionado"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Button variant="outline-primary" size="sm" onClick={() => concatenarColumna(i,edit)}>CONCATENAR COLUMNA</Button>
            {
                row.concatenar.map((co,ico) => {
                    let opcion_default = false
                    let valores = []
                    if(edit === true){
                        valores = proveedor.columnas.map(col => ({ value: col.key, label: col.key }))
                        if(co.columna){
                            const i_default = valores.findIndex(o => o.value === co.columna)
                            if(i_default < 0){
                                const nuevo = { value: co.columna, label: co.columna }
                                valores.push(nuevo)
                                opcion_default = nuevo
                            } else {
                                opcion_default = valores[i_default]
                            }

                        }
                    } else {
                        valores = columnasCargadas.map(col => ({ value: col.key, label: col.key }))
                        if(co.columna){
                            const i_default = valores.findIndex(o => o.value === co.columna)
                            if(i_default < 0){
                                const nuevo = { value: co.columna, label: co.columna }
                                valores.push(nuevo)
                                opcion_default = nuevo
                            } else {
                                opcion_default = valores[i_default]
                            }

                        }
                    }
                    return <div key={`con-${i}-${ico}`} className="mb-2">
                        <h4>{ico+1}</h4>
                        <Row>
                            <Col md={8}>
                                <label className="form-control-label">Texto antes de concatenar {"(opcional)"}</label>
                                <input className="form-control" value={co.texto} placeholder="Escribe un texto antes de la concatenación" onChange={(e) => handleChangeTexto(e,i,ico,edit)} />
                            </Col>
                            <Col md={4}>
                                <Button size="sm" variant="link" className="text-danger" onClick={() => removerConcat(i,ico,edit)}><i className="fas fa-trash"></i> REMOVER</Button>
                            </Col>
                        </Row>
                        

                        <label className="form-control-label">Columna a concatenar</label>
                        <Select  
                        placeholder="Buscar formato excel precargado..."
                        defaultValue={opcion_default}
                        options={valores}
                        noOptionsMessage={()=>"Sin opciones"}
                        loadingMessage={()=>"Cargando información..."}
                        onChange={(e) => handleChangeConcat(e,i,ico,edit)}
                        />
                    </div>
                })
            }
                
          </Modal.Body>
        </Modal>
    }

    const mostrarConcatenaciones = (row,i,edit) => {
        if(!row.concatenar) return <Button variant="link" size="sm" onClick={() => concatenarColumna(i,edit)}>CONCATENAR</Button>
        return <div>
            <Button size="sm" variant="link" onClick={() => showModalColumna(i,edit)}>{row.concatenar.length} CONCATENACIONES</Button>
            {boxConcatenacion(row,i,edit)}
        </div>
    }
    const mostrarInformacionCargada = () => {
        if(columnasCargadas.length < 1) return false
        if(!nuevoProveedor.modulo_destino) return false

        const i_destino = destinos_formatos.findIndex(d => d.nombre === nuevoProveedor.modulo_destino)
        if(i_destino < 0) return false

        return <Col md={12}>
            <Card className="p-3">
                <Row className="mb-3">
                    <Col>Estas son las columnas detectadas en tu archivo excel</Col>
                    <Col>Estas son las columnas que el sistema espera, asigna cada una de tus columnas a estas</Col>
                </Row>
                {
                    columnasCargadas.map((col,icol) => {
                        
                        let seleccionado = col.target
                        const opciones = seleccionado ? destinos_formatos[i_destino].columnas.filter(op => {
                            let escogidas = columnasCargadas.filter(e => e.target).map(e => e.target)
                            return !escogidas.includes(op) || op === seleccionado
                        }) : destinos_formatos[i_destino].columnas.filter(op => {
                            let escogidas2 = columnasCargadas.filter(e => e.target).map(e => e.target)
                            return !escogidas2.includes(op)
                        })
                                            
                        return <Row key={`col-${icol}`} className="mb-3">
                            <Col>
                            <span className="mb-0">{col.key}</span>
                            </Col>
                            <Col>
                                <select className="form-control" pos={icol} value={seleccionado} onChange={handleChangeCol}>
                                    <option value="">Selecciona</option>
                                    {
                                        opciones.map((row,ir) => {
                                            return <option value={row}>{row}</option>
                                        })
                                    }
                                </select>
                                {mostrarConcatenaciones(col,icol)}
                            </Col>
                        </Row>
                    })
                }
            
        </Card>
        </Col>
    }
    
    const mostrarInformacionCargadaEdicion = () => {
        if(!proveedor.modulo_destino) return false
        if(proveedor.columnas.length < 1) return false

        const i_destino = destinos_formatos.findIndex(d => d.nombre === proveedor.modulo_destino)
        if(i_destino < 0) return false

        return <Col md={12}>
            <Card className="p-3">
                <Row className="mb-3">
                    <Col>Estas son las columnas detectadas en tu archivo excel</Col>
                    <Col>Estas son las columnas que el sistema espera, asigna cada una de tus columnas a estas</Col>
                </Row>
                {
                    proveedor.columnas.map((col,icol) => {
                        
                        let seleccionado = col.target
                        const opciones = seleccionado ? destinos_formatos[i_destino].columnas.filter(op => {
                            let escogidas = proveedor.columnas.filter(e => e.target).map(e => e.target)
                            return !escogidas.includes(op) || op === seleccionado
                        }) : destinos_formatos[i_destino].columnas.filter(op => {
                            let escogidas2 = proveedor.columnas.filter(e => e.target).map(e => e.target)
                            return !escogidas2.includes(op)
                        })
                                            
                        return <Row key={`col-${icol}`} className="mb-3">
                            <Col>
                            <span className="mb-0">{col.key}</span>
                            </Col>
                            <Col>
                                <select className="form-control" pos={icol} value={seleccionado} onChange={handleChangeColEdicion}>
                                    <option value="">Selecciona</option>
                                    {
                                        opciones.map((row,ir) => {
                                            return <option value={row}>{row}</option>
                                        })
                                    }
                                </select>
                                {mostrarConcatenaciones(col,icol,true)}
                            </Col>
                        </Row>
                    })
                }
            
        </Card>
        </Col>
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo formato excel</h4>
                    <p>Puedes cargar tus formatos excel propios, asociarlos a las importaciones de archivo disponibles y así ahorrar tiempo al cargar tus archivos excel.</p>
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Título</label>
                    <input className="form-control" name="titulo" value={nuevoProveedor.titulo} onChange={handleChangeNuevo} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Formato excel destino</label>
                    <select className="form-control" name="modulo_destino" value={nuevoProveedor.modulo_destino} onChange={handleChangeNuevo} >
                        <option value="">Selecciona</option>
                        <option value="importacion-ruta">Importación de rutas</option>
                        <option value="importacion-formato-base">Importación Formato Base</option>
                    </select>
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Archivo excel ejemplo</label>
                    <input
                        type="file" 
                        name="file" 
                        id="file" 
                        className="form-control mb-3"
                        onChange={handleInputChangeExcel} 
                        placeholder="Archivo de excel" 
                    />
                </Col>
                {mostrarInformacionCargada()}
                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/excel?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <Row>
        <Col md={12} className="mb-3">
            <h4 className="mb-0">{proveedor.nombre}</h4>
            {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
        </Col>
        <Col md={4} className="mb-3">
            <label className="form-control-label d-block">Título</label>
            <input className="form-control" name="titulo" defaultValue={proveedor.titulo} onChange={handleChange} />
        </Col>
        <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Formato excel destino</label>
                    <select className="form-control" name="modulo_destino" value={proveedor.modulo_destino} onChange={handleChange} >
                        <option value="">Selecciona</option>
                        <option value="importacion-ruta">Importación de rutas</option>
                        <option value="importacion-formato-base">Importación Formato Base</option>
                    </select>
                </Col>
        <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Archivo excel ejemplo</label>
                    <input
                        type="file" 
                        name="file" 
                        id="file" 
                        className="form-control mb-3"
                        onChange={handleInputChangeExcelEdicion} 
                        placeholder="Archivo de excel" 
                    />
        </Col>
        {mostrarInformacionCargadaEdicion()}
        <Col md={12} className="mt-3">
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
    </Row>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull