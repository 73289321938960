import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto, fechaATextoSimpleCorto } from "../../../lib/helpers/helpers"
import { FiMapPin } from "react-icons/fi"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 shadow hover ${seleccionado === true ? 'text-white' : 'text-primary'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <div className="d-flex justify-content-between">
        <h5 style={{ fontWeight:900 }} className="mb-1 text-uppercase"> {proveedor.titulo}</h5><i className="fa-solid fa-route"></i>
    </div>
    <p style={{fontSize:12}} className="mb-2">Creado <b>{ fechaATextoSimpleCorto(proveedor.createdAt)}</b></p>
    <p style={{ fontWeight:700 }} className="mb-1"><FiMapPin /> {proveedor.direccion ? proveedor.direccion : "Sin datos"}</p>
</Card>
}

export default BoxList