const { DateTime } = require("luxon")
const { useLocation } = require("react-router-dom")
const { configuracion_horas, configuracion_fechas, formatDateHoy } = require("./dates")
const { timezone, urlapi } = require("../backend/data")
const configuracion_fechas_cortas = { month: 'short', day: 'numeric', year: 'numeric' }

const control = {}
const formatearMinutos = (value) => {
  if(!isNaN(parseInt(value))){
    if(parseInt(value) < 10) return `0${value}`
  }
  return value
}

control.formatYMD = (fecha) => {
  let date = false
  if(fecha){
      if(typeof fecha === "string"){
          date = fecha
      } else {
          date = fecha.toISOString()
      }
  } else {
      date = DateTime.now().setZone(timezone).toJSDate().toISOString()
  }
  const fecha_sin_ofset = date.substring(0,23)
  const parseado = DateTime.fromISO(fecha_sin_ofset).toFormat("yyyy-MM-dd")
  return parseado
}

control.dateToYMDFormat = (fecha) => {
  if(fecha){
      if(typeof fecha === "string"){
          return DateTime.fromISO(fecha).toFormat("yyyy-MM-dd")
      } else {
          return DateTime.fromJSDate(fecha).toFormat("yyyy-MM-dd")
      }
  } else {
      return DateTime.now().toFormat("yyyy-MM-dd")
  }
}

control.duracionTiempoString = (minutos) => {
  const hours = Math.floor(minutos / 60);
  const remainingMinutes = minutos % 60;
  if(!hours) return `${remainingMinutes.toFixed(0)} Mins.`

  return `${hours} Hrs. ${remainingMinutes.toFixed(0)} Mins.`
}

const agregarCero = (value) => {
  if(value < 10) return `0${value}`
  return value
}

control.agregarCero = agregarCero

control.fechaUTCATiempo = (fecha, lang) => {
  if(!fecha) return ''
  const date = new Date(fecha)
  const date2 = DateTime.fromISO(fecha)
  return `${date2.hour}:${agregarCero(date2.minute)} Hrs.`
}

control.fechaUTCATexto = (fecha, lang) => {
  if(!fecha) return ''
  const date = new Date(fecha)
  const date2 = DateTime.fromISO(fecha)
  return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${date2.hour}:${formatearMinutos(date2.minute)} Hrs.`
}

control.fechaATexto = (fecha, lang) => {
    if(!fecha) return ''
    const date = new Date(fecha)
//    const date2 = DateTime.fromISO(fecha.substring(0,23))
    const date2 = DateTime.fromISO(fecha).setZone(timezone)
    return `${date.toLocaleDateString('es-ES', configuracion_fechas )} · ${date2.hour}:${formatearMinutos(date2.minute)} Hrs.`
}

control.fechaATextoSimple = (fecha, lang) => {
    if(!fecha) return ''
    if(typeof fecha !== "string") fecha = DateTime.fromJSDate(fecha).toISO()
    const date2 = DateTime.fromISO(fecha.substring(0,23)).toJSDate()
    const date = new Date(date2)
    return `${date2.toLocaleDateString('es-ES', configuracion_fechas )}`
}

control.fechaATextoSimpleCorto = (fecha, lang) => {
    if(!fecha) return ''
    if(typeof fecha !== "string") fecha = DateTime.fromJSDate(fecha).toISO()
    const date2 = DateTime.fromISO(fecha.substring(0,23)).toJSDate()
    const date = new Date(date2)
    return `${date2.toLocaleDateString('es-ES', configuracion_fechas_cortas )}`
}

control.validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

control.ordenarProductos = (registros) => {
  const nuevos = registros.sort((a,b)=> {
    const posicion = typeof a.orden !== 'undefined' ? a.orden : 99999999
    return posicion > b.orden ? 1 : -1
  })
  return nuevos
}

control.limpiarRut = (rut) => {
    if(!rut) return rut
    const dato = rut.toString().replace('.','')
    if(!dato) return rut
    const dato2 = dato.replace('.','')
    if(!dato2) return dato
    const dato3 = dato2.replace('.','')
    if(!dato3) return dato2
    return dato3
}

control.checkForDuplicatesEmails = (email,lista) => {
    const buscar = lista.filter(user => user.email === email)
    if(buscar.length > 0) return true
    return false
}

control.sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

control.corregirTelefono = (num) => {

    if(!num){
      return false
    }

    const digito = num.toString().replace(/\s/g, '')

    const longitud = digito.length

    if(longitud === 0){
      return false
    }
    const numero = digito.replace('+56', '56')
    if(longitud === 9){
      return `56${numero}`
    } else if(longitud === 8){
      return `569${numero}`
    }

    return numero

}

control.calcularPorcentaje = (montoTotal, montoActual) => {
  const porcentaje = (montoActual / montoTotal) * 100;
  return porcentaje;
}

control.urlByPaymentGateway = (name, username, email, ammount) => {
  const data = {
      transbank: {
          url: `${urlapi}/payments/transbank/oneclick/create?username=${username}&email=${email}`,
          method: "GET"
      },
      mercadopago: {
          url: `${urlapi}/payments/mercadopago/checkoutpro/create`,
          method: "POST",
          body: JSON.stringify({
              ammount: parseInt(ammount)
          })
      },
  }
  return data[name]
}

control.calcularPorcentajeSobreCantidad = (montoTotal, montoActual) => {
  const porcentaje = (montoActual / montoTotal) * 100;
  return !isNaN(porcentaje) ? porcentaje : 0
}


module.exports = control