import { Row, Col } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { url_images } from '../../lib/global/data'

const PantallaCargando = () => {
    return <div className='text-center'>
        <img src={`${url_images}/animations/integraciones.gif`} style={{ maxWidth: 200, marginBottom: -20, marginTop: -20 }} />
        <h5 className='mb-0' style={{ fontWeight: "bold" }}>Cargando información...</h5>
    </div>
}

export default PantallaCargando