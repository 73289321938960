import { useEffect, useState } from "react"
import { Button, Col, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import data from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch } from "react-redux";
import { cerrarSesion } from "../../../redux/actions/session";
import { fechaATexto } from "../../../lib/helpers/helpers";

const DetailFull = (props) => {
    const {
        condicion_default,
        description
    } = props
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState(condicion_default ? condicion_default : { tipo:"logistica", sub_tipo: "tipos-servicio" })
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [ showModal, setShowModal ] = useState(true)
    const dispatch = useDispatch()


    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/whatsapp-plantillas`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/whatsapp-plantillas`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        return setNuevoProveedor(prev => {
            let actual = { ...prev }
            actual[name] = value
            if(name === "mensaje") {
                actual.variables = detectarVariables(value)
            }
            return actual
        })
    }

    const detectarVariables = (str) => {
        const regex = /\{\{(.*?)\}\}/g;
        const matches = [];
        let match;
        
        // Iterar sobre cada coincidencia encontrada
        while ((match = regex.exec(str)) !== null) {
            matches.push({
                codigo: match[0],
                slug: ""
            }); // Agregar el match al array
        }

        return matches
    }

    const handleChangeVariable = (e,i) => {
        const { name, value } = e.target
        return setNuevoProveedor(prev => {
            let actual = { ...prev }
            actual.variables[i][name] = value
            return actual
        })
    }

    const mostrarParametros = () => {
        let matches = [];
        if(nuevoProveedor.variables) matches = nuevoProveedor.variables
        if(matches.length < 1) return false
        return <div>
            {matches.length > 0 ? <h3>Parámetros dinámicos</h3> : false}
            {
                matches.map((item, index) => {
                    return <div key={`p-${index}`} className="mb-3">
                        <Row>
                            <Col>
                            <label className="form-control-label d-block">Parámetro {item.codigo}</label>
                            <select key={`${index}-${nuevoProveedor.mensaje}`} className="form-control" value={item.slug} name="slug" onChange={(e) => handleChangeVariable(e, index)} placeholder={`Parámetro dinámico ${item.codigo}`}>
                                <option value="">Selecciona</option>
                                <option value="pedido">Referencia</option>
                                <option value="cliente">Nombre cliente</option>
                                <option value="direccion">Dirección destino</option>
                                <option value="tracking">Número de tracking</option>
                                <option value="fecha">Fecha de entrega</option>
                                <option value="telefono">Teléfono de contacto</option>
                                <option value="email">Correo de contacto</option>
                                <option value="comentario">Comentario</option>
                                <option value="nombre">Nombre del producto</option>
                            </select>
                            
                            </Col>
                            <Col>
                            <label className="form-control-label d-block">Escribe un texto de ejemplo</label>
                            <input key={`${index}-${nuevoProveedor.mensaje}`} className="form-control" name="example" value={item.example} onChange={(e) => handleChangeVariable(e, index)} />
                            </Col>
                        </Row>
                        </div>
                } )
            }
        </div>
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo</h4>
                    { description ? <p>{description}</p> : false }
                    <p>Usa llaves dobles para indicar donde planeas utilizar contenido dinámico, ejemplo: </p>
                    <p><b style={{ fontWeight: "bold" }}>Hola José, El número de tracking para tu orden es 424241</b></p>
                    <p>este sería así</p>
                    <p><b style={{ fontWeight: "bold" }}>Hola {"{{1}}"}, El número de tracking para tu orden es {"{{2}}"}</b> </p>
                </Col>
                
                <Col className="mb-3">
                    <label className="form-control-label d-block">Idioma</label>
                    <select className="form-control mb-3" name="idioma" onChange={handleChangeNuevo} >
                        <option value="es">Español</option>
                    </select>

                    <label className="form-control-label d-block">Mensaje</label>
                    <textarea className="form-control" style={{ minHeight: 200 }} name="mensaje" value={nuevoProveedor.mensaje} onChange={handleChangeNuevo} />
                </Col>
                <Col md={12}>
                {mostrarParametros()}
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/configuracion?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()

        const mensajes = proveedor.mensaje ? proveedor.mensaje.toString() : ""

        return <Row>
        <Col md={12} className="mb-3">
        {/* <h6 style={{ fontSize: 13 }} className="mb-1">{mensajes.substring(0,40)}{mensajes.length > 40 ? "..." : ""}</h6> */}
        {/* <h6 style={{ fontSize:13, fontWeight:'lighter' }} className="mb-0">{ fechaATexto(proveedor.createdAt)}</h6> */}
            {/* {removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button> } */}
        </Col>
        <Col md={12} className="mb-3">
                <label className="form-control-label d-block">Idioma</label>
                    <select className="form-control mb-3" name="idioma" onChange={handleChangeNuevo} >
                        <option value="es">Español</option>
                    </select>

            <label className="form-control-label d-block">Mensaje</label>
            <p>{proveedor.mensaje}</p>
        </Col>
        {/* <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col> */}
    </Row>
    }

    const cerrarModal = () => {
        if(props.onCloseModal) props.onCloseModal()
        return setShowModal(false)
    }
    return <div>
        <Modal show={showModal} onHide={()=>cerrarModal()}>
        <Modal.Header closeButton />
        <div className="p-3">{View()}</div>
        </Modal>
    </div>
}

export default DetailFull