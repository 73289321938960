import { useState } from "react"
import { Card, Col, Form, Row } from "react-bootstrap"
import { formatDateHoyFromDate } from "../../lib/helpers/dates"

const RecoleccionRapida = (props) => {
    const [ show, setShow ] = useState(false)
    const [ recoleccion, setRecoleccion ] = useState({})

    const handleCheck = (e) => {
        console.log(e.target.checked)
        setShow(e.target.checked)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setRecoleccion(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...actual}
        })
    }

    const mostrarFormularioCompleto = () => {
        if(!show) return false

        return <div>
            <Row>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Fecha *</label>
                    <input className="form-control" type="date" name="fecha" onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Hora desde *</label>
                    <input className="form-control" type="time" name="hora_desde"  onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Hora hasta *</label>
                    <input className="form-control" type="time" name="hora_hasta" onChange={handleChange} />
                </Col>
            </Row>
        </div>
    }
    return <div>
        <Card className="shadow-sm p-3 mb-3">
        <h4>Agendar la recolección de estas órdenes</h4>
        <p className="mb-0">Puedes realizar este paso luego</p>
        <Form.Group className="mb-3" controlId={`activar`}>
        <Form.Check type="switch" label={"Agendar ahora"} onChange={handleCheck} />
        </Form.Group>
        {mostrarFormularioCompleto()}
        </Card>
    </div>
}

export default RecoleccionRapida