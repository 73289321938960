import React, { Component, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { userService } from '../../services/user.service'
import { Row, Col, Container, Form, Button, InputGroup, FormControl } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { validateEmail } from '../../lib/helpers/helpers'
import { toast } from 'react-toastify'
import { Link, useParams } from 'react-router-dom'
import guardarUsuario from '../../redux/actions/guardarUsuario'
import { inicializarEsquema, reiniciarEsquema } from '../../redux/actions/esquemaTablas'
import { cambiarPais } from '../../redux/actions/pais'
import "../Login/login.css"
import { url_images } from '../../lib/global/data'
import { cambiarEsquemaModular } from '../../redux/actions/cambiarEsquema'
import { cambiarModo } from '../../redux/actions/cambiarModo'
import { urlapi } from '../../lib/backend/data'
import { useQuery } from '../../lib/helpers/router'

const LoginExternal = (props) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorLogin, setErrorLogin] = useState('')
    const [loading, setLoading] = useState(true)
    const dispatch = useDispatch()
    const query = useQuery()
    

    useEffect(() => {
        conectar()
    }, [])

    const conectar = async () => {
        const authorizacioncode = query.get('authorizacioncode')
        if(!authorizacioncode){
            setErrorLogin("Error en la autorización, debe proveer un código de autorización")
            return setLoading(false)
        }
        const url = `${urlapi}/auth/external/login?authorizacioncode=${authorizacioncode}`
        return fetch(url, {
          method: "GET",
          headers: {
              'Content-type': "application/json",
          }
      })
      .then(res => res.json())
      .then(async res => {
          if(!res){
              setErrorLogin("Sin datos obtenidos")
          } else if(res.errorMessage){
            setErrorLogin(res.errorMessage)
          } else if(res.tokenSession){
            const user = res
            dispatch(guardarUsuario(false))
            dispatch(reiniciarEsquema())
            
            if (user.configuracion_interfaz) {
                if (typeof user.configuracion_interfaz === "object") {
                    if (user.configuracion_interfaz.detalles) {
                        if (typeof user.configuracion_interfaz.detalles === "object") {
                            dispatch(inicializarEsquema(user.configuracion_interfaz.detalles))
                        }
                    }
                }
            }
            if (user.interfaz_modular) {
                if (typeof user.interfaz_modular === "object") {
                    if (user.interfaz_modular.detalles) {
                        if (typeof user.interfaz_modular.detalles === "object") {
                            dispatch(cambiarEsquemaModular(user.interfaz_modular.detalles))
                        }
                    }
                }
            }
            if (user.data.empresa) if (typeof user.data.empresa === "object") if (user.data.empresa.pais_code) {
                const empresa = user.data.empresa
                dispatch(cambiarPais(empresa.pais_code))
                if(empresa.interface_config) if(typeof empresa.interface_config === "object"){
                    const config_interface = empresa.interface_config
                    if(config_interface.mode) dispatch(cambiarModo(config_interface.mode))
                }
            }
            dispatch(guardarUsuario(user))
            return window.location = "/"
          }
          return setLoading(false)
      })
      .catch(error => {
            setErrorLogin("No se pudo efectuar la operación")
          return setLoading(false)
      })
    }

    const handleSubmit = (e) => {
        if (e) e.preventDefault()
        if (!username) return toast.error("Escribe tu email")
        if (!password) return toast.error("Escribe tu contraseña")
        if (validateEmail(username) !== true) return toast.error('Email inválido')

        setLoading(true)
        userService.login(username, password)
            .then(
                user => {
                    if (!user) {
                        setErrorLogin("Email o Contraseña erroneas")
                        setLoading(false)
                    } else {
                        if (user.configuracion_interfaz) {
                            if (typeof user.configuracion_interfaz === "object") {
                                if (user.configuracion_interfaz.detalles) {
                                    if (typeof user.configuracion_interfaz.detalles === "object") {
                                        dispatch(inicializarEsquema(user.configuracion_interfaz.detalles))
                                    }
                                }
                            }
                        }
                        if (user.interfaz_modular) {
                            if (typeof user.interfaz_modular === "object") {
                                if (user.interfaz_modular.detalles) {
                                    if (typeof user.interfaz_modular.detalles === "object") {
                                        dispatch(cambiarEsquemaModular(user.interfaz_modular.detalles))
                                    }
                                }
                            }
                        }
                        if (user.data.empresa) if (typeof user.data.empresa === "object") if (user.data.empresa.pais_code) {
                            const empresa = user.data.empresa
                            dispatch(cambiarPais(empresa.pais_code))
                            if(empresa.interface_config) if(typeof empresa.interface_config === "object"){
                                const config_interface = empresa.interface_config
                                if(config_interface.mode) dispatch(cambiarModo(config_interface.mode))
                            }
                        }
                        dispatch(guardarUsuario(user))
                        return window.location = "/"
                    }
                },
                error => setLoading(false)
            );
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "username") {
            return setUsername(value)
        } else if (name === "password") {
            return setPassword(value)
        }
    }

    return <div className='login-form'>
        <Row className="justify-content-md-center">
            <Col md={6} className="centerv row justify-content-center border px-5 py-4 shadow">
                <Row className='row justify-content-center'>
                    <img alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
                </Row>

                <Container className="text-center">
                    <p className='my-3' style={{ fontWeight: 700, fontSize: 15 }}>Transformamos la experiencia en software</p>
                    {/* <p className="m-0" style={{ fontWeight: 900, fontSize: 32 }}>¡BIENVENIDO!</p> */}
                    {/* <p style={{ fontWeight: 900, fontSize: 18 }}>Iniciar Sesión</p> */}
                </Container>

                <form className="container text-center pt-3 pb-3" onSubmit={handleSubmit}>
                    {/* <InputGroup className="input mb-4" style={{ fontWeight: 400, fontSize: 18 }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="iconBck">
                                <img alt="emailIcon" src={`${url_images}/icono_email.png`} style={{ width: 24 }} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            id="emailFormInput"
                            type="email"
                            name="username"
                            placeholder="Ingresar Email"
                            className='shadow'
                            onChange={handleChange} />
                    </InputGroup> */}

                    {/* <InputGroup className="input mb-4" style={{ fontWeight: 400, fontSize: 18 }}>
                        <InputGroup.Prepend>
                            <InputGroup.Text className="iconBck">
                                <img alt="passwordIcon" src={`${url_images}/icono_Candado.png`} style={{ width: 24 }} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            id="FormInpasswordput"
                            type="password"
                            name="password"
                            placeholder="Ingresar Contraseña"
                            className='shadow'
                            onChange={handleChange} />
                    </InputGroup> */}

                    {loading ? <div>
                        <Spinner animation="border" />
                        <h6 className='mt-3'>Estamos validando su información, espere un momento...</h6>
                    </div> :
                        false }
                    {errorLogin ? <p className="text-danger">{errorLogin}</p> : false}
                </form>
                {/* <Container className="text-right">
                    <p className='mb-5 mt-3' style={{ fontWeight: 400, fontSize: 15 }}>
                        <Link to={`/olvide-password`}>¿Olvidaste tu contraseña?</Link>
                        <br />
                        <Link to={`/registro`}>¿Aún no tienes cuenta? Registrarme</Link>
                    </p>
                </Container> */}
                <div className="d-flex align-items-center px-4">
                    {/* <Form.Check className="p-0" id="checkbox" type="checkbox" /> */}
                    <p className="m-0 px-0" style={{ fontWeight: 700, fontSize: 12 }}>
                        <Link to={'/terminos-condiciones'}>Términos y Condiciones</Link> | <Link to={'/politicas-privacidad'}>Política de privacidad</Link>
                    </p>
                </div>
            </Col>
        </Row>
    </div>
}

export default LoginExternal
