import HorarioLogisticaListado from "../../subComponents/horario-logistica/list"

const HorarioLogistica = () => {
    return <div className="fluid">
        <HorarioLogisticaListado />
        
    </div>
}

export default HorarioLogistica
