import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Spinner, Table } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import ModalOrdenesEditar from '../recolecciones/modal_ordenes_editar';
import { set } from 'date-fns';

const RastreoCargaVehiculo = (props) => {
    const {
        id_target
    } = props
    const dispatch = useDispatch()
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingCreando, setLoadingCreando ] = useState(false)
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ openModal, setOpenModal ] = useState(false)
    const [ idEliminando, setIdEliminando ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false

    const obtenerProveedores = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/conductores/cargas-rastreo/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setProveedores(res.datos)
                setTotal(res.total)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const crearRelaciones = async (ids)=>{
        setLoadingCreando(true)
        
        return fetch(`${data.urlapi}/conductores/cargas-rastreo`,{
            method:'POST',
            body: JSON.stringify({
                ids: ids.map(e => ({ id_target, id_orden: e.id_pedido, })),
                id_target
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.resultados) !== false){
                if(res.resultados.length > 0) obtenerProveedores(pagina)
            }
            return setLoadingCreando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingCreando(false)
        })
    }

    const eliminarRelacion = async (id)=>{
        setIdEliminando(id)
        
        return fetch(`${data.urlapi}/conductores/cargas-rastreo?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Eliminado exitosamente')
                setProveedores(prev => {
                    let actual = [...prev]
                    actual = actual.filter(p => p._id !== id)
                    return actual
                })
            }
            return setIdEliminando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setIdEliminando(false)
        })
    }


    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerProveedores(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()
        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            <Table bordered hover responsive>
                <thead>
                    <tr>
                        <td>ORDEN</td>
                        <td>DIRECCIÓN ORIGEN</td>
                        <td>PAIS ORIGEN</td>
                        <td>ESTADO ORIGEN</td>
                        <td>CIUDAD ORIGEN</td>
                        <td>LOCALIDAD ORIGEN</td>
                        <td>DIRECCIÓN DESTINO</td>
                        <td>PAIS DESTINO</td>
                        <td>ESTADO DESTINO</td>
                        <td>CIUDAD DESTINO</td>
                        <td>LOCALIDAD DESTINO</td>
                        <td></td>
                    </tr>
                </thead>
            {
                        registros.map(reg => {
                            const orden = reg.orden

                            let titulo_estado           = ''
                            let titulo_sub_estado       = ''
                            let color_estado            = '#bebebe'

                            if(orden.estado_entrega){
                                if(typeof orden.estado_entrega === 'object'){
                                    if(orden.estado_entrega.titulo) titulo_estado = orden.estado_entrega.titulo
                                    if(orden.estado_entrega.color) color_estado = orden.estado_entrega.color
                                }
                            }
                        
                            if(orden.sub_estado_entrega){
                                if(typeof orden.sub_estado_entrega === 'object'){
                                    if(orden.sub_estado_entrega.titulo) titulo_sub_estado = orden.sub_estado_entrega.titulo
                                }
                            }

                            let string_estado = `${titulo_estado} ${titulo_sub_estado}`
                            if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"
                            
                            return <tr key={orden._id} className="mb-3">
                            <td>
                            {reg.orden?.pedido}
                                <p style={{ fontSize:9, fontWeight:'normal', borderRadius:2, backgroundColor: color_estado, padding: 6, color:'white' }} className="mb-2">{string_estado.toUpperCase()}</p></td>
                            <td>{reg.orden?.orden?.geo_datos_origen?.address_1}</td>
                            <td>{reg.orden?.orden?.geo_datos_origen?.pais}</td>
                            <td>{reg.orden?.orden?.geo_datos_origen?.level1}</td>
                            <td>{reg.orden?.orden?.geo_datos_origen?.level2}</td>
                            <td>{reg.orden?.orden?.geo_datos_origen?.level3}</td>
                            <td>{reg.orden?.orden?.billing?.address_1}</td>
                            <td>{reg.orden?.orden?.geo_datos?.pais}</td>
                            <td>{reg.orden?.orden?.geo_datos?.level1}</td>
                            <td>{reg.orden?.orden?.geo_datos?.level2}</td>
                            <td>{reg.orden?.orden?.geo_datos?.level3}</td>
                            <td>
                                { !idEliminando ? <Button size="sm" variant="outline-danger" onClick={() => eliminarRelacion(reg._id)}>ELIMINAR</Button> : false }
                                { idEliminando === reg._id ? <Spinner animation='border'/> : false}
                                </td>
                            </tr>
                        })
            }
            </Table>
        </div>
    }

    
 
    return <div>
        {modalFiltro()}
        <Row>
            <Col md={12}>
            <h4 style={{ fontSize: 23 }}>Programar tránsito de cargas para rastreo automático</h4>
            <ModalOrdenesEditar onSelect={(data) => crearRelaciones(data)} />
            {loadingCreando ? <Spinner animation="border" /> : false}
            
                {/* <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmitPalabra}>
                        <input className='mb-3 form-control' placeholder='BUSCAR' onChange={handleChangePalabra} />
                        </form>
                    </Col>
                </Row> */}
                {tabla_registros(proveedores)}
            </Col>
        </Row>
    </div>

}

export default RastreoCargaVehiculo