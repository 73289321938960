import { Badge, Card } from "react-bootstrap"
import { fechaATexto } from "../../../lib/helpers/helpers"
import { FaRegNoteSticky } from "react-icons/fa6"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const stringByStatus = (status) => {
        const statuses = {
            pending: "Pendiente de aprobación",
            approved: "Aprobado",
            rejected: "Rechazado por whatsapp"
        }
        return statuses[status] ? statuses[status] : "Pendiente"
    }

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    const maxima = 300
    const mensajes = proveedor.mensaje ? proveedor.mensaje.toString() : ""

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <div>
        <p className="mb-2"><Badge variant="success" className=" mr-1" style={{ textTransform: "uppercase" }}>ESTADO DE WHATSAPP</Badge><Badge variant="dark" className="" style={{ textTransform: "uppercase" }}>{stringByStatus(proveedor.status_whatsapp)}</Badge></p>
        <h6 style={{ fontSize: 13 }} className="mb-1"><FaRegNoteSticky /> {mensajes.substring(0,maxima)}{mensajes.length > maxima ? "..." : ""}</h6>
        <h6 style={{ fontSize:13, fontWeight:'lighter', color: "gray" }} className="mb-0">{ fechaATexto(proveedor.createdAt)}</h6>
    </div>
</Card>
}

export default BoxList