import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { urlapi } from '../../lib/backend/data'
import { useSelector } from 'react-redux'
import { rutas } from '../../lib/routes/routes'
import { Spinner } from 'react-bootstrap'

const CrearCanal = (props) => {
    const [ canal, setCanal ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const session = useSelector(state => state.miusuario)

    const handleChange = e => {
        const { name, value } = e.target
        canal[name] = value
        return setCanal(canal)
    }

    const crearCanal = async () => {
        if(!canal.titulo) return toast.error("Título es requerido")
        if(!canal.descripcion) return toast.error("Descripción es requerido")
        setLoading(true)
        return fetch(`${urlapi}/bodega/canal`,{
            method: "POST",
            body: JSON.stringify(canal),
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${session.tokenSession}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Creado exitosamente")
                setCanal({})
                setTimeout(() => {
                    window.location.reload()
                }, 2000);
            }
            return false
        })
        .catch(error => {
            toast.error(error.message)
            setLoading(false)
        })
    }

    return <div>
        <label className='form-control-label d-block'>Título</label>
        <input className='form-control' name="titulo" defaultValue={canal.titulo} onChange={handleChange}/>

        <label className='form-control-label d-block'>Descripción</label>
        <input className='form-control mb-3' name="descripcion" defaultValue={canal.descripcion} onChange={handleChange}/>

        { loading ? <Spinner animation='border' /> : <button className='btn btn-sm btn-success w-100' onClick={() => crearCanal()} >CREAR</button> }

    </div>
}

export default CrearCanal