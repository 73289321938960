import React from "react";
import { useState, useContext } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger, Row, Col, Card } from "react-bootstrap";
import { estilo_last_mile } from "../../lib/global/styles";
import { TbCircleArrowRightFilled } from "react-icons/tb";
import { BiSolidHelpCircle } from "react-icons/bi";


const ModalWrapper = (props) => {
    const {
        component,
        title,
        label
    } = props
    const titulo = label ? label : "MÁS INFORMACIÓN"
    const titulo_modal = title ? title : "Configuración"

    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };
    return <div>
        <div>
            <Card className="p-2 text-center hover" onClick={() => setIsOpen(true)}><h6 className="p-0 m-0 uppercase">{titulo}</h6></Card>
        </div>
        <Modal show={isOpen} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
        <h4 className="mb-0 bold">{titulo_modal}</h4>
        </Modal.Header>
        <Modal.Body className="text-center">
        <div style={{ width: "100%" }}>
        {component}
        </div>
        </Modal.Body>
        
      </Modal>
    </div>
};

export default ModalWrapper