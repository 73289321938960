import { Card, Col, Row } from "react-bootstrap"
import { interfaz } from "../../../lib/backend/data"
import { fechaATexto, fechaATextoSimple } from "../../../lib/helpers/helpers"

const BoxListV2 = (props) => {
    const ruta = props.ruta ? props.ruta : false
    const seleccionado = props.seleccionado ? props.seleccionado : false
    if(!ruta) return false

    const clickRuta = (id) => {
        if(props.onClickRuta) return props.onClickRuta(id)
        return false
    }

    const mostrarVehiculo = (driver) => {
        if(!driver) return false
        return <div>
            <p className="mb-0" style={{ fontWeight: 'lighter' }}><b>{driver.modelo}</b> {driver.ano}</p>
            <p className="mb-0" style={{ fontWeight: 'lighter' }}>PATENTE: <b>{driver.patente.toUpperCase()}</b></p>
        </div>
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`hover shadow-sm p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => clickRuta(ruta._id)}>
        <Row>
            <Col xs={2}><p className="mb-0" style={{ fontSize: interfaz.tamano_letra_tabla }}>{fechaATexto(ruta.createdAt)}</p></Col>
            <Col xs={2}><p className="mb-0" style={{ fontSize: interfaz.tamano_letra_tabla }}>RUTA {ruta.id}</p></Col>
            <Col xs={2}>
                <p className="mb-0" style={{ fontSize: interfaz.tamano_letra_tabla }}>Desde <br/><b>{fechaATextoSimple(ruta.entrega_desde)}</b></p>
                <p className="mb-0" style={{ fontSize: interfaz.tamano_letra_tabla }}>Hasta <br/><b>{fechaATextoSimple(ruta.entrega_hasta)}</b></p>
            </Col>
            <Col xs={2}><p className="mb-0" style={{ fontSize: interfaz.tamano_letra_tabla }}>{ruta.finalizada ? "SI" : "NO"}</p></Col>
            <Col xs={2}><p className="mb-0" style={{ fontSize: interfaz.tamano_letra_tabla }}>{ruta.pedidos}</p></Col>
            <Col xs={2}>{mostrarVehiculo(ruta.vehiculo)}</Col>
        </Row>
</Card>
}

export default BoxListV2