import { Badge, Card, Col, Row } from "react-bootstrap"
import { fechaATexto, fechaATextoSimpleCorto } from "../../../lib/helpers/helpers"
import { FiMapPin } from "react-icons/fi"
import { LuTicket } from "react-icons/lu"
import { status, types } from "./data"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false
    const tipos = {}
    types.map(t => tipos[t.value] = t.label)
    const string_length = 25

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 shadow hover ${seleccionado === true ? 'text-white' : 'text-primary'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <div className="">
        <h5 style={{ fontWeight:900 }} className="mb-1 text-uppercase"><LuTicket /> TICKET {proveedor.id}</h5>
        <p className="mb-0 text-secondary" >{proveedor.titulo.substring(0,string_length)} {proveedor.titulo.length >= string_length ? "..." : ""}</p>
    </div>
    <Row>
        <Col><p style={{fontSize:10}} className="mb-2">Creado<br/> <b>{ fechaATextoSimpleCorto(proveedor.createdAt)}</b></p></Col>
        <Col><p style={{fontSize:10}} className="mb-2">Actualizado<br/> <b>{ fechaATextoSimpleCorto(proveedor.updatedAt)}</b></p></Col>
    </Row>
    <Badge variant="secondary" style={{ textTransform: "uppercase" }} className="mb-2">{tipos[proveedor.type]}</Badge>
    <Badge variant={proveedor.open ? "success" : "dark"} style={{ textTransform: "uppercase" }} className="mb-2">{proveedor.open ? "ABIERTO" : "CERRADO"}</Badge>
    <p className="mb-0" style={{ fontSize: 11 }}>{status[proveedor.status]}</p>
</Card>
}

export default BoxList