import { Card, Col, Row } from "react-bootstrap"
import { fechaATexto, fechaATextoSimpleCorto } from "../../../lib/helpers/helpers"
import { FiMapPin } from "react-icons/fi"
import { BsEnvelopeCheck } from "react-icons/bs"
import { FaRegListAlt } from "react-icons/fa"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <h6 style={{ fontSize:10, fontWeight:'lighter' }} className="mb-2">Creado <b>{ fechaATextoSimpleCorto(proveedor.createdAt)}</b></h6>
    <h6 style={{ fontSize: 17 }} className="mb-1"><b>ENVÍO {proveedor.id}</b></h6>
    { proveedor?.lista ? <p style={{ fontSize: 11 }} className="mb-1"><FaRegListAlt /> {proveedor.lista?.label}</p> : false }
</Card>
}

export default BoxList