import React, { useState } from "react";
import { Button, Card, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
// import localStorage from "redux-persist/es/storage";

const { useDispatch, useSelector } = require("react-redux")

const MantenedorEsquema = () => {
    const esquema_default = useSelector(state => state.esquema_actual)
    const [esquema, setEsquema] = useState(esquema_default)
    console.log(esquema_default)

    const handleInputChange = (event, propiedad) => {
        setEsquema(prev => {

            let actual = {
                ...prev,
                [propiedad]: {
                    ...prev[propiedad],
                    alias: event.target.value,
                },
            }

            return {...{}, ...actual}
        });
    };

    const handleCheckChange = (event, propiedad) => {
        setEsquema({
            ...esquema,
            [propiedad]: {
                ...esquema[propiedad],
                active: event.target.checked
            }
        });
    };

    //Solución de persistencia del lado del cliente, los datos guardados solo estarán disponibles en el navegador 
    //Hacer una solicitud a un servidor para guardar los cambios.
    const guardarCambios = () => {
        setEsquema(esquema)
        // localStorage.setItem('persist:root', JSON.stringify({ esquema_actual: esquema }))
        console.log(esquema, "nuevo")
    };

    return (
        <div className="text-primary">
            <h4 style={{ fontWeight: 900 }}>
                <i className="fa-solid fa-sitemap"></i> Configuración de Esquema
            </h4>
            <p>
                <i className="fa-solid fa-triangle-exclamation text-warning"></i> Aqui
                podrás personalizar el menú y los submenús correspondientes que se
                muestran en el esquema de tu interfaz de usuario.
            </p>

            <Card className="shadow-sm py-2 px-3">
                <Row>
                    {Object.keys(esquema).map(propiedad => (
                        <Col sm={12} md={4} key={propiedad}>
                            <Form.Group controlId={`formEsquema-${propiedad}`}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Form.Label
                                        className="text-primary text-capitalize"
                                        style={{ fontWeight: 700 }}
                                    >
                                        {propiedad}
                                    </Form.Label>
                                    <OverlayTrigger
                                        placement={'top'}
                                        overlay={
                                        <Tooltip>Muestra u oculta este módulo de la barra lateral</Tooltip>
                                        }
                                        >        
                                    <div>
                                    <Form.Check
                                        type="switch"
                                        id={`switch-${propiedad}`}
                                        checked={esquema[propiedad].active}
                                        onChange={e => handleCheckChange(e, propiedad)}
                                    />
                                    </div>
                                    </OverlayTrigger>
                                </div>
                                <Form.Control
                                    className="shadow-sm mr-3"
                                    type="text"
                                    placeholder="Nuevo nombre para este módulo"
                                    value={esquema[propiedad].alias}
                                    onChange={e => handleInputChange(e, propiedad)}
                                />

                            </Form.Group>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col>
                        <Button variant="primary" className="shadow-sm rounded mb-3" onClick={guardarCambios}>
                            <i className="fa-solid fa-save"></i><b> GUARDAR CAMBIOS</b>
                        </Button>
                    </Col>
                </Row>
            </Card>
        </div>
    );
};


export default MantenedorEsquema;
