import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import { toast } from "react-toastify"
import { Badge, Button, Card, Modal, Spinner } from "react-bootstrap"
import { useEffect, useState } from "react"

const CamposPersonalizados = (props) => {
    const [ camposPersonalizados, setCamposPersonalizados ] = useState([])
    const [ loadingCamposPersonalizados, setLoadingCamposPersonalizados ] = useState(true)
    const [ showModalAlertas, setShowModalAlertas ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerCamposPersonalizados()
    }, [])

    const obtenerCamposPersonalizados = async () => {
        return fetch(`${urlapi}/ordenes/campos-personalizados`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setCamposPersonalizados(res)
            }
            return setLoadingCamposPersonalizados(false)
        })
        .catch(error => {
            setLoadingCamposPersonalizados(false)
            return toast.error(error.message)
        })
    }

    const handleCloseModal = () => {
        setShowModalAlertas(false)
      }

    const mostrarCamposPersonalizados = () => {
        if(loadingCamposPersonalizados === true) return <div><Spinner animation='border' /></div>
        if(camposPersonalizados.length < 1) return false
        return <div>
            <Modal width={"80%"}  show={showModalAlertas} onHide={() => handleCloseModal()}>
            <div className="p-3">
                <h6 className="mb-0" style={{ fontWeight: "bold" }}>Campos personalizados</h6>
                <p className="mb-0">Puedes agregar cualquier de estos campos personalizados a tu archivo excel</p>
                <hr className='hr' />
                {
                    camposPersonalizados.map(campo => {
                        return <Badge variant="dark" className="m-1" style={{ fontSize: 11, textTransform: "uppercase" }}>{campo.slug}</Badge>
                    })
                }
            </div>
            </Modal>
            <Card className="p-2 text-center hover" onClick={() => setShowModalAlertas(true)} ><h6 className="p-0 m-0 uppercase">CAMPOS PERSONALIZADOS</h6></Card>
        </div>
    }

    return <div>
        {mostrarCamposPersonalizados()}
    </div>

}

export default CamposPersonalizados