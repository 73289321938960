import { Card, Col, Row } from 'react-bootstrap'
import Header from '../Header/index.js';
import 'react-toastify/dist/ReactToastify.css'
import { useDispatch, useSelector } from 'react-redux'
// import Structure from '../Structure'
import "../Home/home.css"
import { Link } from 'react-router-dom';
import { rutas } from '../../lib/routes/routes.js';
import SortableBox from '../../subComponents/general/sortable.js';
import { LuLineChart, LuTowerControl } from 'react-icons/lu';
import { FaBoxOpen, FaChartLine, FaTruck } from 'react-icons/fa';
import { FaHandHoldingDollar, FaMagnifyingGlassChart } from 'react-icons/fa6';
import { HiOutlineTicket } from 'react-icons/hi';
import { RiRouteLine } from 'react-icons/ri';
import { HiOutlineClipboardDocumentList } from 'react-icons/hi2';
import { PiWebhooksLogoBold } from 'react-icons/pi';
import { MdOutlineManageAccounts } from 'react-icons/md';
import { TbAlertSquareRounded } from 'react-icons/tb';
import { useEffect, useState } from 'react';
import ModalInvitacionFacturacion from '../../subComponents/facturacion/modal_invitacion.js';

const Home = (props) => {
  const session = useSelector(state => state.miusuario)
  const [ texto, setTexto ] = useState('')
  const pais = useSelector(state => state.pais)
  const dispatch = useDispatch()
  const modoUsuario = useSelector(state => state.modo)
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 576);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 576);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const itemBoxProveedores = [
    {
      titulo: "Torre Control Envío",
      descripcion: "Monitorea y controla el OTIF (On-Time-In-Full) de todos y cada uno de los envíos mensuales...",
      itemIcon: <LuTowerControl style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "control"
    },
    {
      titulo: "OTIF Proveedores",
      descripcion: "Mide y gestiona el desempeño OTIF de cada uno de tus proveedores o flota propia...",
      itemIcon: <FaMagnifyingGlassChart style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "proveedores"
    },
    {
      titulo: "Auditorías Entregas",
      descripcion: "Supervisa y controla en tiempo real el estado de las entregas a tus clientes y la ubicación de los vehículos...",
      itemIcon: <FaBoxOpen style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "entregas"
    },
    {
      titulo: "Ticket Incidencia",
      descripcion: "Mide, registra y controla en tiempo real todos los envíos con incidencias de no entrega conforme...",
      itemIcon: <HiOutlineTicket style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ticket"
    },
    {
      titulo: "Tarifas - Cotizador",
      descripcion: "Cotiza y compara valores de flete online, tiempos de transito y rutas segun proveedores...",
      itemIcon: <FaHandHoldingDollar style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "tarifas"
    },
    {
      titulo: "Flota Propia",
      descripcion: "Gestiona y administra tu propia flota de vehículos como si fueran proveedores de transporte externo...",
      itemIcon: <FaTruck style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "flota"
    },
    {
      titulo: "Optimizador de Ruta",
      descripcion: "Gestiona eficientemente las rutas de distribución para tus envíos a través de tu propia flota de vehículos...",
      itemIcon: <RiRouteLine style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "rutas"
    },
    {
      titulo: "Órdenes - Rutas",
      descripcion: "Ingresa tus envíos y notifica a tus proveedores sobre la asignación de envíos como las exceptivas...",
      itemIcon: <HiOutlineClipboardDocumentList style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ordenes"
    },
    {
      titulo: "Integraciones",
      descripcion: "Integra tus sistemas o tu pagina vía API u otros métodos con nuestra solución de Última Milla...",
      itemIcon: <PiWebhooksLogoBold style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "integraciones"
    },
    {
      titulo: "Configuraciones",
      descripcion: "Personaliza y configura la plataforma según tus necesidades logísticas, incluyendo la configuración...",
      itemIcon: <MdOutlineManageAccounts style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "configuracion"
    },
    {
      titulo: "Notificaciones",
      descripcion: "Personaliza notificaciones urgentes que requieren tu atención a través de WhatsApp y otros medios...",
      itemIcon: <TbAlertSquareRounded style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "notificaciones"
    }
  ]

  const itemBoxCliente = [
    {
      titulo: "Torre Control Envío",
      descripcion: "Monitorea y controla el OTIF (On-Time-In-Full) de todos y cada uno de los envíos mensuales...",
      itemIcon: <LuTowerControl style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "control"
    },
    {
      titulo: "OTIF Proveedores",
      descripcion: "Mide y gestiona el desempeño OTIF de cada uno de tus proveedores o flota propia...",
      itemIcon: <FaMagnifyingGlassChart style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "proveedores"
    },
    {
      titulo: "Auditorías Entregas",
      descripcion: "Supervisa y controla en tiempo real el estado de las entregas a tus clientes y la ubicación de los vehículos...",
      itemIcon: <FaBoxOpen style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "entregas"
    },
    {
      titulo: "Ticket Incidencia",
      descripcion: "Mide, registra y controla en tiempo real todos los envíos con incidencias de no entrega conforme...",
      itemIcon: <HiOutlineTicket style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ticket"
    },
    {
      titulo: "Tarifas - Cotizador",
      descripcion: "Cotiza y compara valores de flete online, tiempos de transito y rutas segun proveedores...",
      itemIcon: <FaHandHoldingDollar style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "tarifas"
    },
    {
      titulo: "Flota Propia",
      descripcion: "Gestiona y administra tu propia flota de vehículos como si fueran proveedores de transporte externo...",
      itemIcon: <FaTruck style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "flota"
    },
    {
      titulo: "Optimizador de Ruta",
      descripcion: "Gestiona eficientemente las rutas de distribución para tus envíos a través de tu propia flota de vehículos...",
      itemIcon: <RiRouteLine style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "rutas"
    },
    {
      titulo: "Órdenes - Rutas",
      descripcion: "Ingresa tus envíos y notifica a tus proveedores sobre la asignación de envíos como las exceptivas...",
      itemIcon: <HiOutlineClipboardDocumentList style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ordenes"
    },
    {
      titulo: "Integraciones",
      descripcion: "Integra tus sistemas o tu pagina vía API u otros métodos con nuestra solución de Última Milla...",
      itemIcon: <PiWebhooksLogoBold style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "integraciones"
    },
    {
      titulo: "Configuraciones",
      descripcion: "Personaliza y configura la plataforma según tus necesidades logísticas, incluyendo la configuración...",
      itemIcon: <MdOutlineManageAccounts style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "configuracion"
    },
    {
      titulo: "Notificaciones",
      descripcion: "Personaliza notificaciones urgentes que requieren tu atención a través de WhatsApp y otros medios...",
      itemIcon: <TbAlertSquareRounded style={{fontSize:40, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "notificaciones"
    }
  ]
  
  const itemBoxCourriers = [
    {
      titulo: "Torre Control Envío",
      descripcion: "Monitorea y controla el OTIF (On-Time-In-Full) de todos y cada uno de los envíos mensuales...",
      itemIcon: <LuTowerControl style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "control"
    },
    {
      titulo: "OTIF Proveedores",
      descripcion: "Mide y gestiona el desempeño OTIF de cada uno de tus proveedores o flota propia...",
      itemIcon: <FaMagnifyingGlassChart style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "proveedores"
    },
    {
      titulo: "Auditorías Entregas",
      descripcion: "Supervisa y controla en tiempo real el estado de las entregas a tus clientes y la ubicación de los vehículos...",
      itemIcon: <FaBoxOpen style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "entregas"
    },
    {
      titulo: "Ticket Incidencia",
      descripcion: "Mide, registra y controla en tiempo real todos los envíos con incidencias de no entrega conforme...",
      itemIcon: <HiOutlineTicket style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ticket"
    },
    {
      titulo: "Tarifas - Cotizador",
      descripcion: "Cotiza y compara valores de flete online, tiempos de transito y rutas segun proveedores...",
      itemIcon: <FaHandHoldingDollar style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "tarifas"
    },
    {
      titulo: "Flota Propia",
      descripcion: "Gestiona y administra tu propia flota de vehículos como si fueran proveedores de transporte externo...",
      itemIcon: <FaTruck style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "flota"
    },
    {
      titulo: "Optimizador de Ruta",
      descripcion: "Gestiona eficientemente las rutas de distribución para tus envíos a través de tu propia flota de vehículos...",
      itemIcon: <RiRouteLine style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "rutas"
    },
    {
      titulo: "Órdenes - Rutas",
      descripcion: "Ingresa tus envíos y notifica a tus proveedores sobre la asignación de envíos como las exceptivas...",
      itemIcon: <HiOutlineClipboardDocumentList style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "ordenes"
    },
    {
      titulo: "Integraciones",
      descripcion: "Integra tus sistemas o tu pagina vía API u otros métodos con nuestra solución de Última Milla...",
      itemIcon: <PiWebhooksLogoBold style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "integraciones"
    },
    {
      titulo: "Configuraciones",
      descripcion: "Personaliza y configura la plataforma según tus necesidades logísticas, incluyendo la configuración...",
      itemIcon: <MdOutlineManageAccounts style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "configuracion"
    },
    {
      titulo: "Notificaciones",
      descripcion: "Personaliza notificaciones urgentes que requieren tu atención a través de WhatsApp y otros medios...",
      itemIcon: <TbAlertSquareRounded style={{fontSize:55, backgroundColor:'#1B3665', borderRadius: '100%', padding:8, color:'white', marginTop:5}}/>,
      slug: "notificaciones"
    }
  ]

  // const render = () => {
  return (
    <>
      <div className='homepage p-4'>
      <Row className='mb-4'>
  <Col xs={12} className='text-right d-block d-md-none p-0'>
    <img
      alt="QuestionIcon"
      src="images/sidebar/Question.svg"
      style={{ height: 20 }} />
  </Col>
  {isSmallScreen ? (
      <>
        <Col xs={12} className='text-center text-md-left'>
          <span style={{ fontWeight: 900, fontSize: 25, verticalAlign: "center", color: '#1B3665' }}>
            ¡BIENVENIDO! 
          </span>
        </Col>
        <Col className='d-md-flex justify-content-md-end text-md-right mt-3 mt-md-0'>
          <img
            alt="QuestionIcon"
            src="images/sidebar/Question.svg"
            style={{ height: 35 }}
            className='d-none d-md-block' />
        </Col>
        </>
      ) : (
        <>
        <Col md={6} className='text-center text-md-left'>
          <span style={{ fontWeight: 900, fontSize: 35, verticalAlign: "middle", color: '#1B3665' }}>
            ¡BIENVENIDO! 
          </span>
        </Col>
        <Col md={6} className='d-md-flex justify-content-md-end text-md-right mt-3 mt-md-0'>
          <img
            alt="QuestionIcon"
            src="images/sidebar/Question.svg"
            style={{ height: 35 }}
            className='d-none d-md-block' />
        </Col>
        </>
      )}
  </Row>

        <Row>

        {modoUsuario === 'proveedor' && (<>
          {itemBoxProveedores.map((box, i) => {
            // return false
            return <Col key={`item-${i}`} md={3}>
              <Link to={`/${rutas.landing.actividad.slug}/${box.slug ? box.slug : ""}`}>
                <Card className='itemBox p-3 mb-3' style={{ height:165 }}>
                  <Row>
                    <Col xs={3}>
                      {box.itemIcon}
                    </Col>
                    <Col xs={9} style={{ paddingLeft: 0, paddingRight: 30 }}>
                      <span style={{ fontWeight: 700, fontSize: 18, color: '#1B3665' }}>
                        {box.titulo}
                      </span>
                      <p className='mt-0' style={{fontSize:13}} >
                        {box.descripcion}
                      </p>
                      <p className='text-right' style={{ fontWeight: 400, fontSize: 11, color: '#1B3665' }}><b>(ver más)</b></p>
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          })}
          </>)}

          {modoUsuario === 'cliente' && (<>
          {itemBoxCliente.map((box, i) => {
            // return false
            return <Col key={`item-${i}`} xs={12} md={3} className='mb-3'>
              <Link to={`/${rutas.landing.actividad.slug}/${box.slug ? box.slug : ""}`}>
              <Card className='itemBox px-3 pt-3 pb-0 mb-3 flex-grow-1' style={{ minHeight: '165px', height: '100%' }}>
      <Row>
        {isSmallScreen ? (
          <>
            <Col xs={12} className="text-center">
              {box.itemIcon}
            </Col>
            <Col xs={12} className="text-center" style={{ paddingLeft: 5, paddingRight: 5 }}>
              <span style={{ fontWeight: 700, fontSize: 18, color: '#1B3665' }}>
                {box.titulo}
              </span>
              <p className='mt-0' style={{ fontSize: 13 }}>
                {box.descripcion}
              </p>
              <p className='text-right mb-0' style={{ fontWeight: 400, fontSize: 11, color: '#1B3665' }}><b>(ver más)</b></p>
            </Col>
          </>
        ) : (
          <>
            <Col md={3}>
              {box.itemIcon}
            </Col>
            <Col md={9} style={{ paddingLeft: 10, paddingRight: 30 }}>
              <span style={{ fontWeight: 700, fontSize: 18, color: '#1B3665' }}>
                {box.titulo}
              </span>
              <p className='mt-0' style={{fontSize:13}} >
                {box.descripcion}
              </p>
              <p className='text-right mb-0' style={{ fontWeight: 400, fontSize: 11, color: '#1B3665' }}><b>(ver más)</b></p>
            </Col>
          </>
        )}
      </Row>
    </Card>
              </Link>
            </Col>
          })}
          </>)}
          
          {modoUsuario === 'courriers' && (<>
          {itemBoxCourriers.map((box, i) => {
            // return false
            return <Col key={`item-${i}`} md={3} className='mb-3'>
              <Link to={`/${rutas.landing.actividad.slug}/${box.slug ? box.slug : ""}`}>
                <Card className='itemBox p-3 mb-3 flex-grow-1' style={{ minHeight: '165px', height: '100%' }}>
                <Row>
                    <Col xs={3}>
                      {box.itemIcon}
                    </Col>
                    <Col xs={9} style={{ paddingLeft: 0, paddingRight: 30 }}>
                      <span style={{ fontWeight: 700, fontSize: 18, color: '#1B3665' }}>
                        {box.titulo}
                      </span>
                      <p className='mt-0' style={{fontSize:13}} >
                        {box.descripcion}
                      </p>
                      <p className='text-right' style={{ fontWeight: 400, fontSize: 11, color: '#1B3665' }}><b>(ver más)</b></p>
                    </Col>
                  </Row>
                </Card>
              </Link>
            </Col>
          })}
          </>)}
        </Row>
        {/* <p className='version text-center' style={{ fontWeight: 400, fontSize: 14, color: '#1B3665', marginTop: 25 }}>VERSIÓN 4.1</p> */}
        <ModalInvitacionFacturacion />
      </div>
    </>
  )
}


export default Home