import { useCallback, useEffect, useState } from "react"
import { Badge, Button, Card, Col, Form, Row, Spinner } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { maps_key, tipos_direccion, urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import { url_images } from "../../lib/global/data"
import { SiTwilio } from "react-icons/si"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import ModalAutomatizaciones from "../automatizacion/modal_seleccion"

const PickingConfiguracion = (props) => {
    const components = {
        resumen: ''
    }
    const estructura = {
        inicio_picking_integraciones: []
    }
    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState({
        detalles: estructura
    })
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'

    useEffect(() => {
        obtenerDatos("picking")
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "congiguracion",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)
                setEstructuraActual(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    
    const agregarIntegracion = (data, setStateFunction, key) => {
        return setStateFunction(prev => {
            let actual = { ...prev }
            if(!actual.detalles[key]) actual.detalles[key] = { integraciones: [] }
            if(!actual.detalles[key].integraciones) actual.detalles[key].integraciones = []
            const i = actual.detalles[key].integraciones.findIndex(e => e._id === data._id)
            if(i < 0) actual.detalles[key].integraciones.push(data)
            actualizar(actual)
            return { ...actual }
        })
    }
    const mostrarIntegraciones = (stateToManage, setStateFunction, key) => {
        if(!stateToManage.detalles[key]) return null
        if(!stateToManage.detalles[key]?.integraciones) return null
        return <div>
            {
                stateToManage.detalles[key].integraciones.map((field,i) => {
                    return <div key={`integra-${field._id}`}>
                        <Card className="mb-3 p-2">
                        <Row>
                            <Col md={8}>
                                <h5 className="mb-0">{field.nombre}</h5>
                                <p className="mb-0"><Badge variant="dark">{field.module}</Badge> </p>
                            </Col>
                            <Col className="text-right">
                                <Button size="sm" variant="outline-danger" className="d-inlineblock" onClick={() => {
                                setStateFunction(prev => {
                                    let actual = { ...prev }
                                    actual.detalles[key].integraciones.splice(i, 1)
                                    return { ...actual }
                                })
                            }}>REMOVER</Button>
                            </Col>
                        </Row>
                        
                        </Card>
                    </div>
                })
            }
            </div>
    }



    if(loading) return <PantallaCargando />

    return <div>
        <Row>
            <Col><p>Configura automatizaciones en el picking</p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spinner size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                {/* <img src={`${url_images}/pedidos-ya.png`} style={{ width: "45%", marginBottom: 10 }} /> */}
                {/* <h3><b>Integra PedidosYa</b></h3> */}
                {/* <h5>Para solicitar pickup y delivery</h5> */}
                {/* <p>Contáctanos para realizar la configuración y puesta en marcha por tí.</p> */}
                <label className="form-control-label d-block">Inicio del picking</label>
                <ModalAutomatizaciones onClick={(data) => agregarIntegracion(data, setEstructuraActual, 'inicio')} />
                {mostrarIntegraciones(estructuraActual, setEstructuraActual, "inicio")}
                
                <label className="form-control-label d-block">Término del picking</label>
                <ModalAutomatizaciones onClick={(data) => agregarIntegracion(data, setEstructuraActual, 'final')} />
                {mostrarIntegraciones(estructuraActual, setEstructuraActual, "final")}
            </Col>
        </Row>
    </div>
}

export default PickingConfiguracion