import { useState } from "react"
import { Button, Card, Col, Row } from "react-bootstrap"
import { FaAngleDown, FaAngleUp, FaFileUpload } from "react-icons/fa"
import { Button as Boton } from '@mui/material';
import { urlapi } from "../../../lib/backend/data";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../../redux/actions/session";
import { IoSend } from "react-icons/io5";
import { toast } from "react-toastify";
import { modulo, submodulo } from "./data";
import CargaImagenes from "../../general/carga_imagenes";
import { IoIosAttach } from "react-icons/io";

const NewMessageTicket = (props) => {
    const {
        id_ticket,
    } = props
    const [ showEditor, setShowEditor ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const default_nuevo = {}
    const [ nuevo, setNuevo ] = useState(default_nuevo)
    const [ archivos, setArchivos ] = useState([])
    const longitud_maxima = 400
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    
    const iconSize = 20

    const handleChange = (e) => {
        const { name, value } = e.target
        return setNuevo(prev => {
            let actual = {...prev}
            actual[name] = value
            return actual
        })
    }

    const handleInputChange = (e) => {
        const target    = e.target
        const file      = target.files[0]
        
    }

    const enviarMensaje = async () => {
        if(!id_ticket) return toast.error('Error al enviar el mensaje')
        if(!nuevo.content) return toast.error('Escribe un mensaje')
        
        const mensaje_escrito = nuevo.content ? nuevo.content : ""
        const longitud = mensaje_escrito.length
        if(longitud > longitud_maxima) return toast.error('El mensaje es muy largo')
        
        setLoading(true)
        nuevo.id_ticket = id_ticket
        nuevo.atachment = archivos
        return fetch(`${urlapi}/ordenes/${submodulo}`,{
            method:'POST',
            body: JSON.stringify(nuevo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Error al enviar el mensaje')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                if(props.onNewMessage) props.onNewMessage(res)
                toast.success('Mensaje enviado')
                setNuevo({...{}, ...default_nuevo})
                setArchivos([])
                setShowEditor(false)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error('Error al enviar el mensaje')
            return setLoading(false)
        })

    }

    const agregarArchivo = (url,name) => {
        return setArchivos(prev => {
            let actual = [...prev]
            actual.unshift({
                url,
                name
            })
            return [...actual]
        })
    }

    const removerArchivo = (i) => {
        setArchivos(prev => {
            let actual  = [...prev]
            actual.splice(i,1)
            return [...actual]
        })
    }

    const mostrarLongitud = (mensaje_escrito) => {
        const longitud = mensaje_escrito.length

        return <div><h6 style={{ fontWeight: "bold" }} className={`${longitud>longitud_maxima?"text-danger":""}`}>{longitud} / {longitud_maxima}</h6></div>
    }

    const showForm = () => {
        if(!showEditor) return false
        const mensaje_escrito = nuevo.content ? nuevo.content : ""
        return <div className="mt-3">
            {mostrarLongitud(mensaje_escrito)}
            <textarea defaultValue={nuevo.content} className="form-control mb-3" style={{ minHeight: 100 }} name="content" placeholder="Escribe tu mensaje" onChange={handleChange} />
            {/* <Boton component="label" color="inherit" variant="contained" startIcon={<FaFileUpload />}>
                SUBIR ARCHIVO ADJUNTO
                <input
                    type="file" 
                    hidden
                    name="file" 
                    id="file" 
                    className="form-control mb-2"
                    onChange={handleInputChange} 
                    placeholder="Archivo de excel" 
                />
                </Boton> */}
                <Row>
                <Col md={6}>
                <CargaImagenes onUploaded={(url,name) => agregarArchivo(url,name)} />
                <div className="mt-3">
                {
                    archivos.map((archivo,i) => {
                        return <div className='d-flex' key={`ar-${archivo.name}-${i}`}>
                            { archivo.url ? <a href={archivo.url} target="_blank" rel="noreferrer"><h5 style={{fontWeight: "bold", color: "blue" }}><IoIosAttach /> {archivo.name}</h5></a> : <h5><IoIosAttach /> {archivo.name}</h5> }
                            <Button className='ml-2 pt-0 px-0 bg-transparent border-0' style={{fontSize:12}} size="sm" onClick={() => removerArchivo(i)} ><i className="fa-solid fa-circle-xmark text-danger"></i></Button>
                        </div>
                    })
                }
                </div>
                </Col>
                <Col md={6}>
                <Button disabled={loading} variant="success" className="w-100" onClick={() => enviarMensaje()}><IoSend /> { loading ? "ENVIANDO..." : "ENVIAR MENSAJE"}</Button>
                </Col>
                </Row>
        </div>
    }

    return <div>
        <Card className="p-3 mb-3">
        <h6 size="sm" className="hover mb-0" style={{ fontWeight: "bold" }} variant="outline-primary" onClick={() => setShowEditor(!showEditor)} >{showEditor ? <FaAngleUp size={iconSize} /> : <FaAngleDown size={iconSize} />} NUEVO MENSAJE</h6>
        {showForm()}
        </Card>
    </div>
}

export default NewMessageTicket