import { Row, Col, Tab, Nav, Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import InformeGeneral from './informe_estilo_suite'
import FacturacionPendiente from './facturacion_pendiente'
import CostosListado from '../../costos-tipos/list'
import SubTipoCostosListado from '../../costos-sub-tipo/list'
import CostoListado from '../../costos/list'
import HelperModal from '../../general/helperModal'
import Saldo from '../../financiero/saldo'
import PaymentMethods from '../../financiero/listado_metodos'
import { useLocation, useNavigate } from 'react-router-dom'
import { useQuery } from '../../../lib/helpers/router'

const FacturacionCliente = (props) => {
    const [ selectedTab, setSelectedTab ] = useState('')
    const location = useLocation()
    const session = useSelector(state => state.miusuario)
    const [ condicionDefault, setCondicionDefault ] = useState({ proveedor: true })
    const navigate = useNavigate();
    const query = useQuery()
    let default_key = 'informe'
    if(query.get("tab")) default_key = query.get("tab")


  const handleCheck = (e, name) => {
    const valor = e.target.checked
    return setCondicionDefault(prev => ({...prev, ...{} }))
    }

    const handleChangeTab = (e) => {
        const newTab = e;
        const queryParams = new URLSearchParams();
        queryParams.set('tab', newTab);
        navigate({
          pathname: location.pathname,
          search: `?${queryParams.toString()}`,
        });
        return setSelectedTab(newTab);
      }

        return <div className="fluid text-primary">
                    <div className='d-flex justify-content-between'>
                    <h4 style={{fontWeight:900}}><i className="fa-solid fa-file-invoice-dollar"></i> Facturación</h4>
                    <HelperModal tipo='facturacion' style='modal'/>
                    </div>
                    <Tab.Container defaultActiveKey={default_key} >
                    <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column" style={{fontWeight:700}} onSelect={handleChangeTab}>
                        <Nav.Item><Nav.Link eventKey="informe">Informe de facturación</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="movimientos_pendientes">Facturación Pendiente</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="payments_methods">Métodos de pago</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="tipo_costos">Categorías de costos</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="subtipo_costos">Sub categorías de costos</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="costos">Costos</Nav.Link></Nav.Item>
                    </Nav>
                    </Col>
                    <Col md={9}>
                    <Card className="shadow-sm p-3">
                    <Tab.Content>
                        <Tab.Pane eventKey="informe"><InformeGeneral /></Tab.Pane>
                        <Tab.Pane eventKey="movimientos_pendientes"><FacturacionPendiente /></Tab.Pane>
                        <Tab.Pane eventKey="payments_methods"><PaymentMethods /></Tab.Pane>
                        <Tab.Pane eventKey="tipo_costos"><CostosListado /></Tab.Pane>
                        <Tab.Pane eventKey="subtipo_costos"><SubTipoCostosListado /></Tab.Pane>
                        <Tab.Pane eventKey="costos"><CostoListado /></Tab.Pane>
                    </Tab.Content>
                    </Card>
                    </Col>
                    </Row>
                    </Tab.Container>
            </div>
}

export default FacturacionCliente