import { Card, Tab, Tabs } from "react-bootstrap"
import Header from "../../../components/Header"
import ListadoEstadosCargaTiempoReal from "./listado_tiempo_real"

const ActividadAuditoria = (props) => {

    return <div>
       <h3 className="mb-3 text-primary" style={{fontWeight:900}}><i className="fa-solid fa-tags"></i> Estados de carga</h3>
              {/* <Tabs defaultActiveKey="general">
              <Tab eventKey="general" title="Eventos generales" className=''>
                <Card className='shadow-sm rounded-0 py-2 px-3 border-top-0'>
                <ListadoEstadosCargaTiempoReal condicion_default={{ notificar_planel_flota: { $nin: [ true ] } }} />
                </Card>
              </Tab>
              <Tab eventKey="auditar" title="Eventos especiales para auditar" className=''>
                <Card className='shadow-sm rounded-0 py-2 px-3 border-top-0'>
                <ListadoEstadosCargaTiempoReal condicion_default={{ notificar_planel_flota: true, auditado: false }} />
                </Card>
              </Tab>
              <Tab eventKey="auditado" title="Eventos especiales auditados" className=''>
                <Card className='shadow-sm rounded-0 py-2 px-3 border-top-0'>
                </Card>
              </Tab>
              </Tabs> */}
                <ListadoEstadosCargaTiempoReal condicion_default={{  }} />
    </div>
}

export default ActividadAuditoria