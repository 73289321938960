import { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { fechaATextoSimple } from '../../lib/helpers/helpers';
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';

const BuscadorRutas = (props) => {
    const {
        labelTitle,
        helpComponent
    } = props
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const titulo = labelTitle ? labelTitle : "Buscar ruta"
    const help = helpComponent ? helpComponent : false
    const dispatch = useDispatch()

    const listadoInicial = async (condicion, page) => {
        return fetch(`${urlapi}/rutas/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            }
            if(Array.isArray(res.datos) !== false){
                setOptions(res.datos.map(empresa => ({ value: empresa._id, label: `RUTA ${empresa.id} · ${fechaATextoSimple(empresa.fecha_despacho)}` })))
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    } 

    useEffect(() => {
        listadoInicial({},1)
    }, [])

    const buscar = async (value) => {
        if(!token) return []
        return fetch(`${urlapi}/rutas/search`,{
            method:'POST',
            body: JSON.stringify({
                value
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return []
            } else if(res.errorMessage){
                return []
            }
            return res.length > 0 ? res.map(empresa => ({ value: empresa._id, label: `RUTA ${empresa.id} · ${fechaATextoSimple(empresa.fecha_despacho)}` })) : []
        })
        .catch(error => {
            toast.error(error.message)
            return false
        })
    }

    const promiseOptions = async (inputValue) => {
        return new Promise( async (resolve) => {
            setTimeout( async () => {
            const busqueda = await buscar(inputValue)
              resolve(busqueda);
            }, 500)
        })
    }

    const handleChange = (e) => {
        if(props.onChangeValue) props.onChangeValue(e)
        return false
    }

    return <div>
        <label className='form-control-label'>{help} {titulo}</label>
                    <AsyncSelect 
                    cacheOptions  
                    isClearable
                    placeholder="Buscar número ruta..."
                    defaultOptions={options}
                    isLoading={loading}
                    noOptionsMessage={()=>"Sin opciones"}
                    loadingMessage={()=>"Cargando información..."}
                    loadOptions={promiseOptions} 
                    onChange={handleChange}
                    className="mb-2 shadow-sm"
                    />
    </div>
}

export default BuscadorRutas