import React, { Component, useState} from 'react'
import { useSelector } from 'react-redux'
import { Card, Nav, Tab, Tabs } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ListadoWhatsapp from '../../subComponents/listas-whatsapp/listadoWhatsapp/list'
import { useQuery } from '../../lib/helpers/router'
import WhatsappPlantillasListado from '../../subComponents/ordenes/whatsapp-plantillas/list'
import WhatsappConfiguracion from '../../subComponents/personalizar/whatsapp'
import ListadoWhatsappMasivo from '../../subComponents/whatsapp-masivo/listadoWhatsapp/list'
import WhatsappBotConfiguracion from '../../subComponents/personalizar/bot_configuracion'
import IntegracionesCarritoListado from '../../subComponents/integraciones-carrito/list'
import { MdPlace, MdWebhook } from 'react-icons/md'
import HelperIntegraciones from '../../subComponents/general/helperIntegraciones'
import WidgetsListado from '../../subComponents/integraciones/widgets/widgets_listado'
import PluginsListado from '../../subComponents/integraciones/plugins/plugins_listado'
import { GrConnect } from 'react-icons/gr'
import { FaBasketShopping } from 'react-icons/fa6'
import { TbPlugConnected } from 'react-icons/tb'
import Personalizar from '../../subComponents/personalizar/personalizar'

const IntegraTuWeb = () => {
  const user = useSelector(state => state.miusuario)
  const [selectedTab, setSelectedTab] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  let default_key = 'plugins'
  if(query.get("tab")) default_key = query.get("tab")
  

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
          pathname: location.pathname,
          search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }


        return(
              <div className="fluid">

        <div >
            <Card className="p-5 text-center mb-3">
            <h1 className="h2 text-primary mb-4" style={{fontWeight:700}}><MdWebhook /> Integra tu sitio web</h1>
            <Row>
                <Col>
                    <TbPlugConnected size={30} />
                    <h4><b>Conecta</b></h4>
                    <p>Has que tus pedidos se integran en nuestro sistema en tiempo real</p>
                </Col>
                <Col>
                    <FaBasketShopping size={30} />
                    <h4><b>Carrito de compras</b></h4>
                    <p>Configura las tarifas como método de envío en tu carrito</p>
                </Col>
                <Col>
                    <MdPlace size={30} />
                    <h4><b>Tracking</b></h4>
                    <p>Configura el tracking de las órdenes en tu web</p>
                </Col>
            </Row>
            </Card>
        </div>


          <Tab.Container id="left-tabs-example" defaultActiveKey={default_key}>
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
              <Nav.Item><Nav.Link eventKey="plugins"><TbPlugConnected /> Conecta tu web</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="envios"><FaBasketShopping /> Carrito de compras</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="widgets"><MdPlace /> Tracking en tu web</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="personaliza"><MdPlace /> Personaliza</Nav.Link></Nav.Item>
            </Nav> 
          </Col>
          <Col sm={9}>
            <Tab.Content>
                <Tab.Pane eventKey="widgets">
                    <HelperIntegraciones tipo="widgets" style="modal" />
                    <WidgetsListado token={user.tokenSession} />
                </Tab.Pane>
              <Tab.Pane eventKey="envios" >
              <IntegracionesCarritoListado />
              </Tab.Pane>
              <Tab.Pane eventKey="plugins">
                    <HelperIntegraciones tipo="plugins" style="modal" />
                    <PluginsListado token={user.tokenSession} />
                </Tab.Pane>
              <Tab.Pane eventKey="personaliza">
                <Personalizar filtrarItems={["tracking"]} />
                </Tab.Pane>

            </Tab.Content>
          </Col>
          </Row>
        </Tab.Container>
      </div>
    )
    }

export default IntegraTuWeb