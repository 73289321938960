import { Card, Col, Row } from "react-bootstrap"

const BoxConductor = (props) => {
    const {
        typeView
    } = props
    const conductor = props.conductor ? props.conductor : false

    if(!conductor) return <div className="text-center">
        <h5 className="text-primary" style={{fontWeight:900}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Sin datos del conductor</h5>
    </div>

    const vistaSimple = () => {
        return <div className="mb-2">
            <h6 className="mb-0" style={{fontWeight:700}}>{conductor.nombres ? conductor.nombres.toUpperCase() : "SIN INFORMACIÓN"}</h6>
            <h6 className="mb-0" style={{fontWeight:700}}>{conductor.modelo} {conductor.ano} </h6>
            <h4 className="mb-0" style={{fontWeight:700}}>{conductor.patente ? conductor.patente.toUpperCase() : "SIN INFORMACIÓN"}</h4>
        </div>
    }

    const vistaEnLinea = () => {
        return <Row>
            <Col md={12} className=" d-flex">
                <div className="d-flex"><h6 className="mb-0" style={{fontWeight:900}}>Nombre:</h6>
                <p className="mb-0 ml-2" style={{fontWeight:700, fontSize:14}}>{conductor.nombres ? conductor.nombres.toUpperCase() : "SIN INFORMACIÓN"}</p></div>
                <div className="d-flex mx-4"><h6 className="mb-0" style={{fontWeight:900}}>Vehículo:</h6>
                <p className="mb-0 ml-2" style={{fontWeight:700, fontSize:14}}>{conductor.modelo} {conductor.ano} </p></div>
                <div className="d-flex"><h6 className="mb-0" style={{fontWeight:900}}>Patente:</h6>
                <p className="mb-0 ml-2" style={{fontWeight:700, fontSize:14}}>{conductor.patente ? conductor.patente.toUpperCase() : "SIN INFORMACIÓN"}</p></div>
            </Col>
        </Row>
    }

    const visualizar = () => {
        if(typeView === "simple") return vistaSimple()
        if (typeView === "enLinea") return vistaEnLinea()

        return <div>
        <h6 className='mb-0' style={{fontWeight:900}}><i className="fa-regular fa-id-card"></i> Detalles del conductor</h6>
        <p className="mb-0" style={{fontWeight:700}}>Nombre:</p>
        <p className="mb-2">{conductor.nombres ? conductor.nombres.toUpperCase() : "SIN INFORMACIÓN"}</p>
        <h6 className='mb-0' style={{fontWeight:900}}><i className="fa-solid fa-truck"></i> Detalles del vehículo</h6>
        <p className="mb-0">{conductor.modelo} {conductor.ano} </p>
        <p className="mb-0">{conductor.patente ? conductor.patente.toUpperCase() : "SIN INFORMACIÓN"}</p>
    </div>
    }


    return <div>
        {visualizar()}
    </div>

}

export default BoxConductor
