import { Button, Card, Form, Nav, Tab, Tabs } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Header from '../../../components/Header'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CargandoDetalleFull from './cargando_detalle_full'
import { toast } from 'react-toastify'
import { urlapi } from '../../../lib/backend/data'
import SinDatos from '../../general/sin_registros'
import { formatearRUTChileno } from '../../../lib/helpers/data/chile'
import { rutas } from '../../../lib/routes/routes'
import { confirmAlert } from 'react-confirm-alert'
import { BiRightArrowAlt } from 'react-icons/bi'
import ProveedoresListadoCliente from './listado_clientes'
import ProveedoresListadoEjecutivosCliente from './listado_ejecutivos'
import { cerrarSesion } from '../../../redux/actions/session'
import LotesTarifas from '../../pricing/tarifas/lotes'
import Rangos from '../../pricing/tarifas/rangos'
import LotesTarifasCliente from '../../pricing/tarifas/lotes_clientes'
import RangosCliente from '../../pricing/tarifas/rangos_clientes'

const ProveedorEnlazadoDetalle = (props) => {
    const [ loadingMaster, setLoadingMaster ] = useState(true)
    const [ proveedor, setProveedor ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ condicionDefault, setCondicionDefault ] = useState({ rut: "760690872" })
    const route = useParams()
    const { id } = route
    const dispatch = useDispatch()

    const obtenerDatos = async ()=>{
        if(!id) return setLoadingMaster(false)
        return fetch(`${urlapi}/empresas/proveedor?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( res._id ){
                setProveedor(res)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const desvincular = async ()=>{
        if(!id) return setLoadingMaster(false)
        setLoadingMaster(true)
        return fetch(`${urlapi}/empresas/proveedor?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( res._id ){
                return window.location = `/${rutas.logistic_providers.slug}`
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    const solicitarDesvincular = () => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas desvincular este proveedor`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => desvincular()
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const render = () => {
        if(loadingMaster) return <CargandoDetalleFull />
        if(!proveedor) return <SinDatos />

        return <div>
            
            <Row>
                <Col md={10}>
                { proveedor.logotipo ? <img src={proveedor.logotipo} style={{ maxHeight: 100, width: 200, maxWidth: "40%",  paddingBottom: 10 }} />  : false  }
                <h4 className='mb-1'>{proveedor.razon_social}</h4>
                <h6>{formatearRUTChileno(proveedor.rut)}</h6>
                </Col>
                <Col md={2}>
                    <Button size='sm' className='w-100' variant='outline-danger' onClick={() => solicitarDesvincular()} >DESVINCULAR</Button>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <h4>Servicios que ofrece </h4>
                    {['última milla','Logística inversa', 'Fullfilment'].map((item,it) => <h6 style={{ fontSize: 14, color:"#adadad", textTransform: "uppercase" }} key={`item-${it}`}><BiRightArrowAlt /> {item}</h6>)}
                </Col>
                <Col md={12}>

                <Tabs defaultActiveKey="lotes_tarifas" className="">
                            <Tab eventKey="lotes_tarifas" title="Lotes de tarifas" className='mt-3'>
                                <LotesTarifasCliente rut_cliente={proveedor.rut} condicion_default={{ propietario: proveedor.rut }} />
                            </Tab>
                            <Tab eventKey="rangos_tarifas" title="Rangos de peso de tarifas" className='mt-3'>
                                <RangosCliente rut_cliente={proveedor.rut} condicion_default={{ propietario: proveedor.rut }} />
                            </Tab>
                </Tabs>

                    {/* <Tabs defaultActiveKey="tarifas" >
                    <Tab eventKey="tarifas" title="Pricing" className='mt-3'>
                        <Tab.Container defaultActiveKey="lotes_tarifas">
                        <Row>
                        <Col sm={3}>

                        

                        <Nav variant="pills" className="flex-column">
                            <Nav.Item><Nav.Link eventKey="lotes_tarifas">Lotes de tarifas</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="rangos_tarifas">Rangos de peso de tarifas</Nav.Link></Nav.Item>
                        </Nav>
                        </Col>
                        <Col md={9}>
                        <Tab.Content>
                        <Tab.Pane eventKey="lotes_tarifas">
                            <LotesTarifasCliente rut_cliente={proveedor.rut} condicion_default={{ propietario: proveedor.rut }} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="rangos_tarifas">
                            <RangosCliente rut_cliente={proveedor.rut} condicion_default={{ propietario: proveedor.rut }} />
                        </Tab.Pane>
                        </Tab.Content>

                        </Col>
                        </Row>
                        </Tab.Container>
                        </Tab>
                        
                    </Tabs> */}
                </Col>
            </Row>
        </div>
    }
            return <div className="fluid">
                <h4>Detalle del Proveedor</h4>
                {render()}
                </div>
    }

export default ProveedorEnlazadoDetalle