import { BsFileEarmarkBarGraph } from "react-icons/bs"
import { estilo_last_mile } from "../../lib/global/styles"
import { calcularPorcentajeSobreCantidad } from "../../lib/helpers/helpers"
import { MdOutlineContentPasteSearch } from "react-icons/md"
import { calcularOrdenRetrasada } from "../../lib/helpers/pedidos/pedidos"

const { useEffect, useState } = require("react")
const { procesarDatosGraficosEstadisticaConductorLastMile } = require("../../lib/helpers/conductores")
const { default: SinDatos } = require("../general/sin_registros")
const { default: GraficosMesLastMile } = require("../graficos/graficos_last_mile")
const { Card, Accordion, Col, Row, ProgressBar, Modal, Tabs, Tab } = require("react-bootstrap")
const { default: OrdenesListado } = require("../ordenes/listado")
const { HiCursorClick } = require("react-icons/hi")
const { FaChevronDown, FaCheckCircle, FaRegClock, FaCircle } = require("react-icons/fa")
const { FaBoxesPacking } = require("react-icons/fa6")
const { formatoMoneda } = require("../../lib/helpers/main")

const GraficoEstadisticaOrdenes = (props) => {
    const {
        data
    } = props
    const [datosGraficosHistoricoV2, setDatosGraficosHistoricosV2] = useState(false)
    const [condicionBuquedaOrdenes, setCondicionBusquedaOrdenes] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const border_color = "1px solid black"

    const mostrarIcono = (color) => {
        return <FaCircle style={{ fontSize: 11, color }} />
    }


    useEffect(() => {
        if(data) if(Array.isArray(data)){
            const graphs_v2 = procesarDatosGraficosEstadisticaConductorLastMile(data)
            setDatosGraficosHistoricosV2(graphs_v2)
        }
    }, [])

    const SegmentarTipoServicio = (props) => {
        const {
            datos
        } = props
        const [tipoServicio, setTipoServicio] = useState('')
        const [ tiposServicio, setTiposServicio ] = useState([])
        const [ showModalOrdenes, setShowModalOrdenes ] = useState(false)
        const [ condicionOrdenes, setCondicionOrdenes ] = useState(false)

        useEffect(() => {
            let tipos = []
            for( const dato of datos ){
                let tipo_servicio = 'Sin tipo de servicio'
                if(dato.tiposervicio) tipo_servicio = dato.tiposervicio
                const i = tipos.findIndex(e => e === tipo_servicio)
                if(i < 0) tipos.push(tipo_servicio)
            }
            if(tipos.length > 0) {
                setTiposServicio(tipos)
                setTipoServicio(tipos[0])
            }
        }, [  ])

        const obtenerOrdenesConsultadas = (ids) => {
            setCondicionOrdenes({ _id: { $in: ids } })
            setShowModalOrdenes(true)
        }

        const ContabilizarEstados = (props) => {
            const {
                datos
            } = props
    
    
            const seleccionarOrdenes = (ids) => {
                if(props.onSelect) props.onSelect(ids)
            }
    
            if(!Array.isArray(datos)) return <SinDatos />
            let estados = []
            for( const dato of datos ){
                let codigo_estado = ''
                let titulo = 'Sin gestión'
                let color = 'gray'
    
                if(dato.estado_entrega) if(typeof dato.estado_entrega === 'object'){
                    codigo_estado = dato.estado_entrega.codigo_estado
                    titulo = dato.estado_entrega.titulo
                    color = dato.estado_entrega.color
                }
    
                    if(!codigo_estado){
                        const i = estados.findIndex(e => e.codigo_estado === 'sin_estado')
                        if(i > -1){
                            estados[i].ordenes.push(dato)
                        } else {
                            estados.push({
                                color: "gray",
                                title: "Sin gestión",
                                codigo_estado: 'sin_estado',
                                // tipo_servicio: dato.tiposervicio,
                                ordenes: [dato]
                            })
                        }
                    } else {
                        const i = estados.findIndex(e => e.codigo_estado === codigo_estado)
                        if(i > -1){
                            estados[i].ordenes.push(dato)
                        } else {
                            estados.push({
                                codigo_estado,
                                title: titulo,
                                color: color,
                                // tipo_servicio: dato.tiposervicio,
                                ordenes: [dato]
                            })
                        }
                    }
                
            }
    
            
                // const tiposServicio = estados.ordenes.map(item => {
                // item.tipoServicio})
            //     const tiposServicioUnicos = [...new Set(tiposServicio)];
            //     let stringServiciosUnicos = tiposServicioUnicos.join(', ');
            
            
            // const string = obtenerTiposServicioUnicos(data);
    
    
            for(let estado of estados){
            const tiposServicio = estado.ordenes.map(item => item.tiposervicio).filter(tipo => tipo)
            const tiposServicioUnicos = [...new Set(tiposServicio)];
            estado.tiposervicio =  tiposServicioUnicos.join(', ');
            }
            
            return <div className="" style={{ background: "transparent" }}>
                {/* <h5>Agrupados por su último estado</h5> */}
                <Row>
                {
                    estados.map((estado, i) => {
                        return <Col md={3} key={`${estado.titulo}`} >
                            <p className="hover" onClick={() => seleccionarOrdenes(estado.ordenes.map(o => o._id))} style={{ borderRadius:4, borderBottom: `5px solid ${estado.color}`, padding: "5px 10px", borderRight: border_color, borderLeft: border_color, borderTop: border_color, fontWeight: "bold", color: "black" }}>  {estado.ordenes.length} {estado.title} {mostrarIcono(estado.color)}</p>
                        </Col>
                    })
                }
                </Row>
            </div>
        }
    

        const selectorTipoServicio = () => {
            if(!tipoServicio) return false
            return <div>
                <label className="form-control-label d-block" style={{ fontWeight: "bold" }}><HiCursorClick style={estilo_last_mile.icon} /> Selecciona el tipo de servicio que deseas visualizar</label>
                <select className="form-control mb-3" style={{ textTransform: "uppercase"}} value={tipoServicio} onChange={e => setTipoServicio(e.target.value)} >
                    {
                        tiposServicio.map((tipo, i) => <option key={`${tipo}-${i}`} value={tipo}>{tipo}</option>)
                    }
                </select>
            </div>
        }

        const informacion = tipoServicio ? datos.filter(d => d.tiposervicio === tipoServicio) : datos

        return <div>
            <Modal show={showModalOrdenes} size="xl" onHide={() => setShowModalOrdenes(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Órdenes relacionadas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrdenesListado upWhenClick={false} showSearch={false} showAddButton={false} showFilter={false} showExporter={false} condicion_default={condicionOrdenes} />
            </Modal.Body>
            </Modal>
            {selectorTipoServicio()}
            <ContabilizarEstados datos={informacion} onSelect={(ids) => obtenerOrdenesConsultadas(ids)} /> 
        </div>
    }

    
    const agruparPorTipoServicio = (datos) => {
        if(!Array.isArray(datos)) return <SinDatos />
        let tiposServicio = []
        for( const dato of datos ){
            let tipoServicio = 'Sin tipo de servicio'
            if(dato.tiposervicio) tipoServicio = dato.tiposervicio
            const i = tiposServicio.findIndex(e => e.titulo === tipoServicio)
            if(i > -1){
                tiposServicio[i].ordenes.push(dato)
            } else {
                tiposServicio.push({
                    titulo: tipoServicio,
                    ordenes: [dato]
                })
            }
        }
        return tiposServicio
    }


    const calcularOtif = (datos, a_tiempo, retrasadas, reintentos, showOtif, tab_selected_default) => {
        const defaul_tab = tab_selected_default ? tab_selected_default : "a_tiempo"

        const a_tiempo_agrupados = agruparPorTipoServicio(a_tiempo)
        const retrasadas_agrupados = agruparPorTipoServicio(retrasadas)
        const reintentos_agrupados = agruparPorTipoServicio(reintentos)

        const contar_reintentos = reintentos.reduce((prev, next) => {
            const reintento = next.intentos - 1
            return prev + reintento
        }, 0)

        const todos = datos
        const todos_agrupados = agruparPorTipoServicio(todos)

        const otif = calcularPorcentajeSobreCantidad(datos.length, a_tiempo.length)

        return <div className="mb-3">
            <Tabs defaultActiveKey={defaul_tab} className="m-auto" >
                <Tab eventKey="a_tiempo" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><FaCheckCircle style={estilo_last_mile.icon} /> {formatoMoneda(a_tiempo.length)} ENTREGA EN TIEMPO</h5>} >
                    <div className="p-3">
                    <Row>
                        {
                            a_tiempo_agrupados.map((tipo, i) => {
                                return <Col key={`tipo-${i}-${tipo.titulo}`} className="mb-3">
                                    <h6 className="mb-0" style={{ ...estilo_last_mile.bold, fontSize: 13, textTransform: "uppercase"}}>{tipo.titulo}</h6>
                                    <h3 className="mb-0">{formatoMoneda(tipo.ordenes.length)}</h3>
                                    </Col>
                            })
                        }
                    </Row>
                    </div>
                </Tab>
                <Tab eventKey="retrasadas" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><FaRegClock style={estilo_last_mile.icon} /> {formatoMoneda(retrasadas.length)} Retrasadas</h5>} >
                    <div className="p-3">
                    <Row>
                        {
                            retrasadas_agrupados.map((tipo, i) => {
                                return <Col key={`tipo-${i}-${tipo.titulo}`}>
                                    <h6 className="mb-0" style={{ ...estilo_last_mile.bold, fontSize: 13, textTransform: "uppercase"}}>{tipo.titulo}</h6>
                                    <h3 className="mb-0">{formatoMoneda(tipo.ordenes.length)}</h3>
                                    </Col>
                            })
                        }
                    </Row>
                    </div>
                </Tab>
                <Tab eventKey="reintentos" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><FaBoxesPacking style={estilo_last_mile.icon} /> {formatoMoneda(contar_reintentos)} Reintentos</h5>} >
                    <div className="p-3">
                        <p className="" style={{ fontWeight: "bold", fontSize: 12 }}>Se muestra la cantidad de órdenes que tuvieron reintentos según su tipo de servicio</p>
                    <Row>
                        {
                            reintentos_agrupados.map((tipo, i) => {
                                return <Col key={`tipo-${i}-${tipo.titulo}`}>
                                    <h6 className="mb-0" style={{ ...estilo_last_mile.bold, fontSize: 13, textTransform: "uppercase"}}>{tipo.titulo}</h6>
                                    <h3 className="mb-0">{formatoMoneda(tipo.ordenes.length)}</h3>
                                    </Col>
                            })
                        }
                    </Row>
                    </div>
                </Tab>
                <Tab eventKey="avanzado" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><MdOutlineContentPasteSearch style={estilo_last_mile.icon} /> Vista detallada</h5>} >
                    <div className="p-3">
                    <SegmentarTipoServicio datos={datos} />
                    </div>
                </Tab>
                { showOtif === true ? <Tab style={{ border: "1px solid #c6c6c6" }} eventKey="otif" title={<h5 style={estilo_last_mile.bold}><BsFileEarmarkBarGraph style={estilo_last_mile.icon} /> OTIF {formatoMoneda(Math.ceil(otif))}%</h5>} >
                        <div className="p-3">
                        <Row>
                        {
                            todos_agrupados.map((tipo, i) => {
                                return <Col key={`tipo-${i}-${tipo.titulo}`} className="mb-3">
                                    <h6 className="mb-0" style={{ ...estilo_last_mile.bold, fontSize: 13, textTransform: "uppercase"}}>{tipo.titulo}</h6>
                                    <h3 className="mb-0">{formatoMoneda(tipo.ordenes.length)}</h3>
                                    </Col>
                            })
                        }
                        </Row>
                        </div>
                </Tab> : false  }
                
            </Tabs>
        </div>
    }

    const seleccionarDia = (data) => {
        const dia = data.dia < 10 ? `${0}${data.dia}` : data.dia

        let ids_query = data.datos.map(o => o._id)
        /*
        if (tipoVisualizacion === "asignadas") {
            for (const info_dia of datosGraficosHistoricoV2.asignadas.dias) {
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia

                if (dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if (tipoVisualizacion === "completadas") {
            for (const info_dia of datosGraficosHistoricoV2.completadas.dias) {
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if (dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if (tipoVisualizacion === "retrasadas") {
            for (const info_dia of datosGraficosHistoricoV2.rechazadas.dias) {
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if (dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...data.datos.map(o => o._id)]
            }
        }
        */
        if (ids_query.length > 0) {
            setCondicionBusquedaOrdenes({ _id: { $in: ids_query } })
            setShowModal(true)
        }
    }


    if(!datosGraficosHistoricoV2) return <SinDatos />

    const ordenes_asignadas                     = datosGraficosHistoricoV2.asignadas.dias.reduce((prev, next) => [...prev, ...next.datos], [])
    const ordenes_en_curso                      = datosGraficosHistoricoV2.en_curso.dias.reduce((prev, next) => [...prev, ...next.datos], [])
    const ordenes_completadas                   = datosGraficosHistoricoV2.completadas.dias.reduce((prev, next) => [...prev, ...next.datos], [])
    const ordenes_retrasadas                    = datosGraficosHistoricoV2.rechazadas.dias.reduce((prev, next) => [...prev, ...next.datos], [])
    const ordenes_anuladas                      = datosGraficosHistoricoV2._anuladas.dias.reduce((prev, next) => [...prev, ...next.datos], [])

    const estilo_titulo_mes                     = { fontWeight: 700, fontSize: 15, color: '#1B3665' }

    const datos_iterar = [
        {
            key: "orden_asignada",
            ordenes: ordenes_asignadas,
            data: datosGraficosHistoricoV2.asignadas,
            titulo: "total general",
            showOtif: true,
            showProgress: true
        },
        {
            key: "en_curso",
            ordenes: ordenes_en_curso,
            data: datosGraficosHistoricoV2.en_curso,
            titulo: "pendientes de entrega",
            showProgress: true
        },
        {
            key: "orden_completa",
            ordenes: ordenes_completadas,
            data: datosGraficosHistoricoV2.completadas,
            titulo: "entrega finalizada",
            showProgress: true
        },
        {
            key: "orden_retrasada",
            ordenes: ordenes_anuladas,
            data: datosGraficosHistoricoV2._anuladas,
            titulo: "anuladas",
            // default_tab: "retrasadas"
        },
    ]

    const calcularCompromiso = (datos) => {
        const a_tiempo = datos.filter(o => !calcularOrdenRetrasada(o))

        const retrasadas = datos.filter(o => calcularOrdenRetrasada(o))
        return {
            a_tiempo,
            retrasadas
        }
    }


    return <div>
<Accordion className="m-auto">
{
                    datos_iterar.map((dato, i) => {

                        const { a_tiempo, retrasadas } = calcularCompromiso(dato.ordenes)
                        const otif_ = calcularPorcentajeSobreCantidad(dato.ordenes.length, a_tiempo.length)
                        const otif_negativa = calcularPorcentajeSobreCantidad(dato.ordenes.length, retrasadas.length)

                        const ordenes_reintentadas = dato.ordenes.filter(o => {
                            const intentos = o.intentos ? o.intentos : 0
                            return intentos > 1
                        })

                        const contar_reintentos = ordenes_reintentadas.reduce((prev, next) => {
                            const reintento = next.intentos - 1
                            return prev + reintento
                        }, 0)

                        const titulo = <h6 style={{...estilo_titulo_mes, textTransform: "uppercase"}}><FaChevronDown style={estilo_last_mile.icon} /> {formatoMoneda(dato.ordenes.length)} {dato.titulo}</h6>
                        const titulo_a_tiempo = <h6 style={{...estilo_last_mile.bold}}><FaCheckCircle style={estilo_last_mile.icon} /> {formatoMoneda(a_tiempo.length)} ENTREGA EN TIEMPO</h6>
                        const titulo_retrasada = <h6 style={{...estilo_last_mile.bold}}><FaRegClock style={estilo_last_mile.icon} /> {formatoMoneda(retrasadas.length)} ENTREGA FUERA DE TIEMPO</h6>
                        const titulo_reintento = <h6 style={{...estilo_last_mile.bold}}><FaBoxesPacking style={estilo_last_mile.icon} /> {formatoMoneda(contar_reintentos)} REINTENTOS</h6>

                        return <Card className="p-0" key={`accordion-torre-control-${i}`}>
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={dato.key}>
                        <div className="pl-3 pr-3 pb-0 hover">
                            {
                                (i+1) === datos_iterar.length ? titulo : <Row>
                                <Col>{titulo}</Col>
                                <Col>
                                    {titulo_a_tiempo}
                                    { dato.showProgress === true ? <b style={{ color: estilo_last_mile.color_primary }}> <ProgressBar size={13} variant="success" label={`${Math.ceil(otif_)}%`} now={Math.ceil(otif_)} /></b> : false  }
                                </Col>
                                <Col>
                                    {titulo_retrasada}
                                    { dato.showProgress === true ? <b style={{ color: estilo_last_mile.color_primary }}> <ProgressBar size={13} variant="danger" label={`${Math.ceil(otif_negativa)}%`} now={Math.ceil(otif_negativa)} /></b> : false  }
                                </Col>
                                <Col>{titulo_reintento}</Col>
                            </Row>
                            }
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={dato.key}>
                        <Card.Body style={{ backgroundColor:'#f1f1f1' }}>
                            <div className="p-2" >
                            {calcularOtif(dato.ordenes, a_tiempo, retrasadas, ordenes_reintentadas, dato.showOtif, dato.default_tab)}
                            {/* <GraficosMesLastMile
                                className='border border-start-0'
                                titulo={"creadas"} 
                                onClick={data => seleccionarDia(data)} 
                                datos={dato.data} height={'200px'} /> */}
                            </div>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    })
                }
                </Accordion>
    </div>
}

export default GraficoEstadisticaOrdenes