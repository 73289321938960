import { useCallback, useEffect, useState } from "react"
import { Accordion, Button, Card, Col, Form, Row, Spinner, Tab, Tabs } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import CargaImagenes from "../general/carga_imagenes"
import TiposServicioSelector from "../ordenes/tipos_servicio/selector"
import { set } from "date-fns"
import { validateEmail } from "../../lib/helpers/helpers"
import { BsFillTrashFill } from "react-icons/bs"
import { AiFillPlusCircle } from "react-icons/ai"
import SelectorEstados from "../estados_carga/selector"
import { modulos_notificaciones } from "./data"
import BuscadorUsuarios from "../usuarios/buscador"
import { FaAngleDown, FaAngleRight, FaRegEnvelope, FaWhatsapp } from "react-icons/fa"
import LineaTiempoNotificaciones from "../../components/LineaTiempoNotificaciones"

const ConfiguracionNotificacionesRecurso = (props) => {
    const {
        id_target
    } = props
    const estructura = {
        tipos_servicio: []
    }

    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState(estructura)
    const [ loading, setLoading ] = useState(false)
    const [ tipoServicioSeleccionado, setTipoServicioSeleccionado ] = useState(null)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'
    const sub_tipo = "estados-carga"

    useEffect(() => {
        obtenerDatos(sub_tipo)
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar/recurso`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "notificaciones",
                sub_tipo: modulo,
                valor: id_target,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)

                // for( const modulo of estructura.modulos){
                //     const i = res.detalles.modulos.findIndex(mod => mod.slug === modulo.slug)
                //     if(i > -1){
                //         let crear_modulo = res.detalles.modulos[i]
                //         Object.keys(modulo).forEach(key => {
                //             if(typeof crear_modulo[key] === "undefined") crear_modulo[key] = modulo[key]
                //         })
                //         console.log(crear_modulo)
                //         res.detalles.modulos[i] = crear_modulo
                //     } else {
                //         res.detalles.modulos.push(modulo)
                //     }
                // }

                setEstructuraActual(res.detalles)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);


    const handleChangeStatus = (e,i_movulo) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_movulo].options = e
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const onChangeValue = (data) => {
        setTipoServicioSeleccionado(data)
    }

    const agregarTipoServicio = () => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            const i = actual.tipos_servicio.findIndex(ts => ts._id === tipoServicioSeleccionado._id)
            if(i > -1) return { ...actual }
            actual.tipos_servicio.push({
                _id: tipoServicioSeleccionado._id,
                nombre: tipoServicioSeleccionado.valor,
                emails_adicionales: [],
                moviles_adicionales: []
            })
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const anadirEmailAdicional = (i) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.tipos_servicio[i].emails_adicionales) actual.tipos_servicio[i].emails_adicionales = []
            actual.tipos_servicio[i].emails_adicionales.unshift({
                    email: ''
            })
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        
        })
    }
    
    const anadirMovilAdicional = (i) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.tipos_servicio[i].moviles_adicionales) actual.tipos_servicio[i].moviles_adicionales = []
            actual.tipos_servicio[i].moviles_adicionales.unshift({
                pais_codigo: '',
                movil: ''
            })
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        
        })
    }

    const removerEmail = (i, i_modulo) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].emails_adicionales.splice(i,1)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }
    
    const removerMovil = (i, i_modulo) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].moviles_adicionales.splice(i,1)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const handleChangeEmail = (e, i, i_modulo) => {
        const { name, value } = e.target
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].emails_adicionales[i][name] = value
            actual.tipos_servicio[i_modulo].emails_adicionales[i].valid = validateEmail(value)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }
    
    const handleChangeMovil = (e, i, i_modulo) => {
        const { name, value } = e.target
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio[i_modulo].moviles_adicionales[i][name] = value
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const mostrarEmailsAdicionales = (emails_adicionales, i_modulo) => {
        if(!Array.isArray(emails_adicionales)) return false
        if(!emails_adicionales) return false
        return <div>
            {
                emails_adicionales.map((field,i) => {
                    return <div key={`email-${i}`} className="mt-3">
                                <label className='form-control-label d-block'>Email <b className="text-danger hover" onClick={() => removerEmail(i, i_modulo)}><BsFillTrashFill /> REMOVER</b></label>
                                <input placeholder="Escribe aquí tu email" className={`form-control mb-3 ${field.valid === false ? 'is-invalid' : ''}`} pos={i}  name="email" value={field.email} onChange={(e) => handleChangeEmail(e,i,i_modulo)} />
                    </div>
                })
            }
        </div>
    }

    const mostrarMovilesAdicionales = (moviles_adicionales, i_modulo) => {
        if(!Array.isArray(moviles_adicionales)) return false
        if(!moviles_adicionales) return false
        return <div>
            {
                moviles_adicionales.map((field,i) => {
                    return <div key={`movil-${i}`} className="mt-3">
                    <label className='form-control-label d-block'>Móvil <b className="text-danger hover" onClick={() => removerMovil(i, i_modulo)}><BsFillTrashFill /> REMOVER</b></label>
                    <div className='row'>
                        <div className='col-md-4'>
                            <select className='form-control' defaultValue="" pos={i} name="pais_codigo" value={field.pais_codigo} onChange={(e) => handleChangeMovil(e, i, i_modulo)}>
                            <option value="">Código Pais</option>
                            <option data-countryCode="CL" value="56">Chile (+56)</option>
                            <option data-countryCode="BR" value="55">Brazil (+55)</option>
                            <optgroup label="Otros países">
                                <option data-countryCode="DZ" value="213">Algeria (+213)</option>
                                <option data-countryCode="AD" value="376">Andorra (+376)</option>
                                <option data-countryCode="AO" value="244">Angola (+244)</option>
                                <option data-countryCode="AI" value="1264">Anguilla (+1264)</option>
                                <option data-countryCode="AG" value="1268">Antigua &amp; Barbuda (+1268)</option>
                                <option data-countryCode="AR" value="54">Argentina (+54)</option>
                                <option data-countryCode="AM" value="374">Armenia (+374)</option>
                                <option data-countryCode="AW" value="297">Aruba (+297)</option>
                                <option data-countryCode="AU" value="61">Australia (+61)</option>
                                <option data-countryCode="AT" value="43">Austria (+43)</option>
                                <option data-countryCode="AZ" value="994">Azerbaijan (+994)</option>
                                <option data-countryCode="BS" value="1242">Bahamas (+1242)</option>
                                <option data-countryCode="BH" value="973">Bahrain (+973)</option>
                                <option data-countryCode="BD" value="880">Bangladesh (+880)</option>
                                <option data-countryCode="BB" value="1246">Barbados (+1246)</option>
                                <option data-countryCode="BY" value="375">Belarus (+375)</option>
                                <option data-countryCode="BE" value="32">Belgium (+32)</option>
                                <option data-countryCode="BZ" value="501">Belize (+501)</option>
                                <option data-countryCode="BJ" value="229">Benin (+229)</option>
                                <option data-countryCode="BM" value="1441">Bermuda (+1441)</option>
                                <option data-countryCode="BT" value="975">Bhutan (+975)</option>
                                <option data-countryCode="BO" value="591">Bolivia (+591)</option>
                                <option data-countryCode="BA" value="387">Bosnia Herzegovina (+387)</option>
                                <option data-countryCode="BW" value="267">Botswana (+267)</option>
                                <option data-countryCode="BN" value="673">Brunei (+673)</option>
                                <option data-countryCode="BG" value="359">Bulgaria (+359)</option>
                                <option data-countryCode="BF" value="226">Burkina Faso (+226)</option>
                                <option data-countryCode="BI" value="257">Burundi (+257)</option>
                                <option data-countryCode="KH" value="855">Cambodia (+855)</option>
                                <option data-countryCode="CM" value="237">Cameroon (+237)</option>
                                <option data-countryCode="CA" value="1">Canada (+1)</option>
                                <option data-countryCode="CV" value="238">Cape Verde Islands (+238)</option>
                                <option data-countryCode="KY" value="1345">Cayman Islands (+1345)</option>
                                <option data-countryCode="CF" value="236">Central African Republic (+236)</option>
                                <option data-countryCode="CN" value="86">China (+86)</option>
                                <option data-countryCode="CO" value="57">Colombia (+57)</option>
                                <option data-countryCode="KM" value="269">Comoros (+269)</option>
                                <option data-countryCode="CG" value="242">Congo (+242)</option>
                                <option data-countryCode="CK" value="682">Cook Islands (+682)</option>
                                <option data-countryCode="CR" value="506">Costa Rica (+506)</option>
                                <option data-countryCode="HR" value="385">Croatia (+385)</option>
                                <option data-countryCode="CU" value="53">Cuba (+53)</option>
                                <option data-countryCode="CY" value="90392">Cyprus North (+90392)</option>
                                <option data-countryCode="CY" value="357">Cyprus South (+357)</option>
                                <option data-countryCode="CZ" value="42">Czech Republic (+42)</option>
                                <option data-countryCode="DK" value="45">Denmark (+45)</option>
                                <option data-countryCode="DJ" value="253">Djibouti (+253)</option>
                                <option data-countryCode="DM" value="1809">Dominica (+1809)</option>
                                <option data-countryCode="DO" value="1809">Dominican Republic (+1809)</option>
                                <option data-countryCode="EC" value="593">Ecuador (+593)</option>
                                <option data-countryCode="EG" value="20">Egypt (+20)</option>
                                <option data-countryCode="SV" value="503">El Salvador (+503)</option>
                                <option data-countryCode="GQ" value="240">Equatorial Guinea (+240)</option>
                                <option data-countryCode="ER" value="291">Eritrea (+291)</option>
                                <option data-countryCode="EE" value="372">Estonia (+372)</option>
                                <option data-countryCode="ET" value="251">Ethiopia (+251)</option>
                                <option data-countryCode="FK" value="500">Falkland Islands (+500)</option>
                                <option data-countryCode="FO" value="298">Faroe Islands (+298)</option>
                                <option data-countryCode="FJ" value="679">Fiji (+679)</option>
                                <option data-countryCode="FI" value="358">Finland (+358)</option>
                                <option data-countryCode="FR" value="33">France (+33)</option>
                                <option data-countryCode="GF" value="594">French Guiana (+594)</option>
                                <option data-countryCode="PF" value="689">French Polynesia (+689)</option>
                                <option data-countryCode="GA" value="241">Gabon (+241)</option>
                                <option data-countryCode="GM" value="220">Gambia (+220)</option>
                                <option data-countryCode="GE" value="7880">Georgia (+7880)</option>
                                <option data-countryCode="DE" value="49">Germany (+49)</option>
                                <option data-countryCode="GH" value="233">Ghana (+233)</option>
                                <option data-countryCode="GI" value="350">Gibraltar (+350)</option>
                                <option data-countryCode="GR" value="30">Greece (+30)</option>
                                <option data-countryCode="GL" value="299">Greenland (+299)</option>
                                <option data-countryCode="GD" value="1473">Grenada (+1473)</option>
                                <option data-countryCode="GP" value="590">Guadeloupe (+590)</option>
                                <option data-countryCode="GU" value="671">Guam (+671)</option>
                                <option data-countryCode="GT" value="502">Guatemala (+502)</option>
                                <option data-countryCode="GN" value="224">Guinea (+224)</option>
                                <option data-countryCode="GW" value="245">Guinea - Bissau (+245)</option>
                                <option data-countryCode="GY" value="592">Guyana (+592)</option>
                                <option data-countryCode="HT" value="509">Haiti (+509)</option>
                                <option data-countryCode="HN" value="504">Honduras (+504)</option>
                                <option data-countryCode="HK" value="852">Hong Kong (+852)</option>
                                <option data-countryCode="HU" value="36">Hungary (+36)</option>
                                <option data-countryCode="IS" value="354">Iceland (+354)</option>
                                <option data-countryCode="IN" value="91">India (+91)</option>
                                <option data-countryCode="ID" value="62">Indonesia (+62)</option>
                                <option data-countryCode="IR" value="98">Iran (+98)</option>
                                <option data-countryCode="IQ" value="964">Iraq (+964)</option>
                                <option data-countryCode="IE" value="353">Ireland (+353)</option>
                                <option data-countryCode="IL" value="972">Israel (+972)</option>
                                <option data-countryCode="IT" value="39">Italy (+39)</option>
                                <option data-countryCode="JM" value="1876">Jamaica (+1876)</option>
                                <option data-countryCode="JP" value="81">Japan (+81)</option>
                                <option data-countryCode="JO" value="962">Jordan (+962)</option>
                                <option data-countryCode="KZ" value="7">Kazakhstan (+7)</option>
                                <option data-countryCode="KE" value="254">Kenya (+254)</option>
                                <option data-countryCode="KI" value="686">Kiribati (+686)</option>
                                <option data-countryCode="KP" value="850">Korea North (+850)</option>
                                <option data-countryCode="KR" value="82">Korea South (+82)</option>
                                <option data-countryCode="KW" value="965">Kuwait (+965)</option>
                                <option data-countryCode="KG" value="996">Kyrgyzstan (+996)</option>
                                <option data-countryCode="LA" value="856">Laos (+856)</option>
                                <option data-countryCode="LV" value="371">Latvia (+371)</option>
                                <option data-countryCode="LB" value="961">Lebanon (+961)</option>
                                <option data-countryCode="LS" value="266">Lesotho (+266)</option>
                                <option data-countryCode="LR" value="231">Liberia (+231)</option>
                                <option data-countryCode="LY" value="218">Libya (+218)</option>
                                <option data-countryCode="LI" value="417">Liechtenstein (+417)</option>
                                <option data-countryCode="LT" value="370">Lithuania (+370)</option>
                                <option data-countryCode="LU" value="352">Luxembourg (+352)</option>
                                <option data-countryCode="MO" value="853">Macao (+853)</option>
                                <option data-countryCode="MK" value="389">Macedonia (+389)</option>
                                <option data-countryCode="MG" value="261">Madagascar (+261)</option>
                                <option data-countryCode="MW" value="265">Malawi (+265)</option>
                                <option data-countryCode="MY" value="60">Malaysia (+60)</option>
                                <option data-countryCode="MV" value="960">Maldives (+960)</option>
                                <option data-countryCode="ML" value="223">Mali (+223)</option>
                                <option data-countryCode="MT" value="356">Malta (+356)</option>
                                <option data-countryCode="MH" value="692">Marshall Islands (+692)</option>
                                <option data-countryCode="MQ" value="596">Martinique (+596)</option>
                                <option data-countryCode="MR" value="222">Mauritania (+222)</option>
                                <option data-countryCode="YT" value="269">Mayotte (+269)</option>
                                <option data-countryCode="MX" value="52">Mexico (+52)</option>
                                <option data-countryCode="FM" value="691">Micronesia (+691)</option>
                                <option data-countryCode="MD" value="373">Moldova (+373)</option>
                                <option data-countryCode="MC" value="377">Monaco (+377)</option>
                                <option data-countryCode="MN" value="976">Mongolia (+976)</option>
                                <option data-countryCode="MS" value="1664">Montserrat (+1664)</option>
                                <option data-countryCode="MA" value="212">Morocco (+212)</option>
                                <option data-countryCode="MZ" value="258">Mozambique (+258)</option>
                                <option data-countryCode="MN" value="95">Myanmar (+95)</option>
                                <option data-countryCode="NA" value="264">Namibia (+264)</option>
                                <option data-countryCode="NR" value="674">Nauru (+674)</option>
                                <option data-countryCode="NP" value="977">Nepal (+977)</option>
                                <option data-countryCode="NL" value="31">Netherlands (+31)</option>
                                <option data-countryCode="NC" value="687">New Caledonia (+687)</option>
                                <option data-countryCode="NZ" value="64">New Zealand (+64)</option>
                                <option data-countryCode="NI" value="505">Nicaragua (+505)</option>
                                <option data-countryCode="NE" value="227">Niger (+227)</option>
                                <option data-countryCode="NG" value="234">Nigeria (+234)</option>
                                <option data-countryCode="NU" value="683">Niue (+683)</option>
                                <option data-countryCode="NF" value="672">Norfolk Islands (+672)</option>
                                <option data-countryCode="NP" value="670">Northern Marianas (+670)</option>
                                <option data-countryCode="NO" value="47">Norway (+47)</option>
                                <option data-countryCode="OM" value="968">Oman (+968)</option>
                                <option data-countryCode="PW" value="680">Palau (+680)</option>
                                <option data-countryCode="PA" value="507">Panama (+507)</option>
                                <option data-countryCode="PG" value="675">Papua New Guinea (+675)</option>
                                <option data-countryCode="PY" value="595">Paraguay (+595)</option>
                                <option data-countryCode="PE" value="51">Peru (+51)</option>
                                <option data-countryCode="PH" value="63">Philippines (+63)</option>
                                <option data-countryCode="PL" value="48">Poland (+48)</option>
                                <option data-countryCode="PT" value="351">Portugal (+351)</option>
                                <option data-countryCode="PR" value="1787">Puerto Rico (+1787)</option>
                                <option data-countryCode="QA" value="974">Qatar (+974)</option>
                                <option data-countryCode="RE" value="262">Reunion (+262)</option>
                                <option data-countryCode="RO" value="40">Romania (+40)</option>
                                <option data-countryCode="RU" value="7">Russia (+7)</option>
                                <option data-countryCode="RW" value="250">Rwanda (+250)</option>
                                <option data-countryCode="SM" value="378">San Marino (+378)</option>
                                <option data-countryCode="ST" value="239">Sao Tome &amp; Principe (+239)</option>
                                <option data-countryCode="SA" value="966">Saudi Arabia (+966)</option>
                                <option data-countryCode="SN" value="221">Senegal (+221)</option>
                                <option data-countryCode="CS" value="381">Serbia (+381)</option>
                                <option data-countryCode="SC" value="248">Seychelles (+248)</option>
                                <option data-countryCode="SL" value="232">Sierra Leone (+232)</option>
                                <option data-countryCode="SG" value="65">Singapore (+65)</option>
                                <option data-countryCode="SK" value="421">Slovak Republic (+421)</option>
                                <option data-countryCode="SI" value="386">Slovenia (+386)</option>
                                <option data-countryCode="SB" value="677">Solomon Islands (+677)</option>
                                <option data-countryCode="SO" value="252">Somalia (+252)</option>
                                <option data-countryCode="ZA" value="27">South Africa (+27)</option>
                                <option data-countryCode="ES" value="34">Spain (+34)</option>
                                <option data-countryCode="LK" value="94">Sri Lanka (+94)</option>
                                <option data-countryCode="SH" value="290">St. Helena (+290)</option>
                                <option data-countryCode="KN" value="1869">St. Kitts (+1869)</option>
                                <option data-countryCode="SC" value="1758">St. Lucia (+1758)</option>
                                <option data-countryCode="SD" value="249">Sudan (+249)</option>
                                <option data-countryCode="SR" value="597">Suriname (+597)</option>
                                <option data-countryCode="SZ" value="268">Swaziland (+268)</option>
                                <option data-countryCode="SE" value="46">Sweden (+46)</option>
                                <option data-countryCode="CH" value="41">Switzerland (+41)</option>
                                <option data-countryCode="SI" value="963">Syria (+963)</option>
                                <option data-countryCode="TW" value="886">Taiwan (+886)</option>
                                <option data-countryCode="TJ" value="7">Tajikstan (+7)</option>
                                <option data-countryCode="TH" value="66">Thailand (+66)</option>
                                <option data-countryCode="TG" value="228">Togo (+228)</option>
                                <option data-countryCode="TO" value="676">Tonga (+676)</option>
                                <option data-countryCode="TT" value="1868">Trinidad &amp; Tobago (+1868)</option>
                                <option data-countryCode="TN" value="216">Tunisia (+216)</option>
                                <option data-countryCode="TR" value="90">Turkey (+90)</option>
                                <option data-countryCode="TM" value="7">Turkmenistan (+7)</option>
                                <option data-countryCode="TM" value="993">Turkmenistan (+993)</option>
                                <option data-countryCode="TC" value="1649">Turks &amp; Caicos Islands (+1649)</option>
                                <option data-countryCode="TV" value="688">Tuvalu (+688)</option>
                                <option data-countryCode="UG" value="256">Uganda (+256)</option>
                                <option data-countryCode="UA" value="380">Ukraine (+380)</option>
                                <option data-countryCode="AE" value="971">United Arab Emirates (+971)</option>
                                <option data-countryCode="UY" value="598">Uruguay (+598)</option>
                                <option data-countryCode="UZ" value="7">Uzbekistan (+7)</option>
                                <option data-countryCode="VU" value="678">Vanuatu (+678)</option>
                                <option data-countryCode="VA" value="379">Vatican City (+379)</option>
                                <option data-countryCode="VE" value="58">Venezuela (+58)</option>
                                <option data-countryCode="VN" value="84">Vietnam (+84)</option>
                                <option data-countryCode="VG" value="84">Virgin Islands - British (+1284)</option>
                                <option data-countryCode="VI" value="84">Virgin Islands - US (+1340)</option>
                                <option data-countryCode="WF" value="681">Wallis &amp; Futuna (+681)</option>
                                <option data-countryCode="YE" value="969">Yemen (North)(+969)</option>
                                <option data-countryCode="YE" value="967">Yemen (South)(+967)</option>
                                <option data-countryCode="ZM" value="260">Zambia (+260)</option>
                                <option data-countryCode="ZW" value="263">Zimbabwe (+263)</option>
                            </optgroup>
                            </select>
                        </div>
                        <div className='col-md-8'>
                            <input placeholder="Escribe aquí el número telefónico" className='form-control mb-3' pos={i}  name="movil" value={field.movil} onChange={(e) => handleChangeMovil(e, i, i_modulo)} />
                        </div>
                    </div>
                    </div>
                })
            }
        </div>
    }

    const removerModulo = (i) => {
        setEstructuraActual(prev => {
            let actual = {...prev}
            actual.tipos_servicio.splice(i,1)
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const handleChangeModulo = (e, modulo_slug, opcion_slug, key_search, key_value) => {
        let key_find = key_search ? key_search : "options"
        let keyVal = key_value ? key_value : "email"
        setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.modulos) actual.modulos = modulos_notificaciones

            let i_modulo = actual.modulos.opciones.findIndex(modulo => modulo.slug === modulo_slug)
            if(i_modulo < 0){
                const i_modulo_modelo = modulos_notificaciones.opciones.findIndex(modulo => modulo.slug === modulo_slug)
                actual.modulos.opciones.push(modulos_notificaciones.opciones[i_modulo_modelo])
                i_modulo = actual.modulos.opciones.length - 1
            }

            let iopcion = actual.modulos.opciones[i_modulo].opciones.findIndex(opcion => opcion.slug === opcion_slug)
            if(iopcion < 0){
                const iopcion_modelo = modulos_notificaciones.opciones[i_modulo].opciones.findIndex(opcion => opcion.slug === opcion_slug)
                actual.modulos.opciones[i_modulo].opciones.push(modulos_notificaciones.opciones[i_modulo].opciones[iopcion_modelo])
                iopcion = actual.modulos.opciones[i_modulo].opciones.length - 1
            }
            
            if(!actual.modulos.opciones[i_modulo].opciones[iopcion][key_find]) actual.modulos.opciones[i_modulo].opciones[iopcion][key_find] = []
            actual.modulos.opciones[i_modulo].opciones[iopcion][key_find] = e.map(user => ({ value: user[keyVal], label: `${user[keyVal]} ${user.nombres ? user.nombres : ""}` }))
            actualizar({ _id: id, detalles: actual })
            return { ...actual }
        })
    }

    const findValue = (modulo_slug, opcion_slug, key_search) => {
        const key_find = key_search ? key_search : 'options'
        if(!estructuraActual.modulos) return []
        let i_modulo = estructuraActual.modulos.opciones.findIndex(modulo => modulo.slug === modulo_slug)
        if(i_modulo < 0) return []
        let iopcion = estructuraActual.modulos.opciones[i_modulo].opciones.findIndex(opcion => opcion.slug === opcion_slug)
        if(iopcion < 0) return []
        return estructuraActual.modulos.opciones[i_modulo].opciones[iopcion][key_find]
    }
    
    const mostrarMantenedorModulos = () => {
        return <div>
            <h6 className="mt-3"><b>Toca el módulo y configura los emails correspondientes a la notificación</b></h6>
            <Accordion className="mt-3">
            {
                modulos_notificaciones.opciones.map((modulo,imodulo) => {

                    return <Card className="p-0" key={`accordion-torre-control-${imodulo}`}>
                    <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={modulo.slug}>
                    <div className="pl-3 pr-3 pb-0 hover">
                    <h4 className="mb-0" style={{ fontWeight: "bold" }}><FaAngleDown style={estilo_last_mile.icon} /> {modulo.label} · <b style={{ fontWeight: "lighter", fontSize: 14 }}>{modulo.opciones.length} Notificaciones disponibles</b></h4>
                    </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={modulo.slug}>
                    <Card.Body style={{ backgroundColor:'#f1f1f1' }}>
                        <div className="p-2" >
                        {
                            modulo.opciones.map((opcion, iopcion) => {

                                let defaultValue = []
                                let defaultValuePhone = []
                                const encontrar = findValue(modulo.slug, opcion.slug)
                                const encontrar_phone = findValue(modulo.slug, opcion.slug, 'phones')
                                if(Array.isArray(encontrar)) if(encontrar.length > 0){
                                    defaultValue = encontrar
                                }
                                if(Array.isArray(encontrar_phone)) if(encontrar_phone.length > 0){
                                    defaultValuePhone = encontrar_phone
                                }
                                
                                return <div className="mb-3" key={`${imodulo}-${iopcion}`}>
                                    <Card className="p-2">
                                    <h5 className='d-block mb-2'><FaAngleRight /> <b>{opcion.label}</b></h5>
                                    <Row>
                                        <Col>
                                            <h6 className="mb-2"><b><FaRegEnvelope /> Emails</b></h6>
                                            <BuscadorUsuarios defaultValue={defaultValue} key_identifier="email" key={`modulo-${imodulo}-${iopcion}`} full_payload={true} multi={true} label="Selecciona los usuarios" onChange={(e) => handleChangeModulo(e, modulo.slug, opcion.slug)} />
                                        </Col>
                                        <Col>
                                            <h6 className="mb-2"><b><FaWhatsapp style={estilo_last_mile.icon} /> Whatsapp</b></h6>
                                            <BuscadorUsuarios filterValues="phone" labelType="phone" defaultValue={defaultValuePhone} key_identifier="phone" key={`modulo-${imodulo}-${iopcion}-phone`} full_payload={true} multi={true} label="Selecciona los usuarios" onChange={(e) => handleChangeModulo(e, modulo.slug, opcion.slug, "phones", "phone")} />
                                        </Col>
                                    </Row>
                                    
                                    </Card>
                                </div>
                            })
                        }
                        </div>
                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
                })
            }
            </Accordion>
        </div>
    }

    const mostrarMantenedorTipoServicio = () => {
        return <div>
            <Row>
            {
                estructuraActual.tipos_servicio.map((modulo,imodulo) => {
                    let defaultValue = []
                    if(Array.isArray(modulo.options)) if(modulo.options.length > 0){
                        defaultValue = modulo.options.map(e => e._id)
                    }
                    return <Col xs={12} key={`modulo-${imodulo}`}  className="mb-3">
                        <Card className="p-3">
                        <Row>
                            <Col><h4 className="mb-0" style={{ fontWeight: "bold" }}>{modulo.nombre}</h4></Col>
                            <Col className="text-right"><Button size="sm" variant="outline-danger" onClick={() => removerModulo(imodulo)}>REMOVER</Button> </Col>
                        </Row>
                        <hr className="mt-3 mb-3"/>
                        <LineaTiempoNotificaciones filter_serice_type={modulo.nombre} />

                        <SelectorEstados tipo_dato="subestados" key={`modulo-${imodulo}`} defaultValue={defaultValue} isMulti={true} full_payload={true} onChange={(e) => handleChangeStatus(e, imodulo)} />

                        <h5>Emails adicionales</h5>
                        <label className='form-control-label d-block'><b className="text-success hover" onClick={() => anadirEmailAdicional(imodulo)}><AiFillPlusCircle/> AGREGAR NUEVO</b></label>
                        {mostrarEmailsAdicionales(modulo.emails_adicionales, imodulo)}
                        <hr />
                        <h5>Teléfonos adicionales</h5>
                        <label className='form-control-label d-block'><b className="text-success hover" onClick={() => anadirMovilAdicional(imodulo)}><AiFillPlusCircle /> AGREGAR NUEVO</b></label>
                        {mostrarMovilesAdicionales(modulo.moviles_adicionales, imodulo)}
                        </Card>
                    </Col>
                })
            }
        </Row>
        </div>
    }


    if(loading) return <PantallaCargando />

    return <div>
        <Row>
            <Col><p>Actualiza la visualización por módulo y por campos </p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spinner size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        
        
        <Tabs defaultActiveKey="1" id="uncontrolled-tab-example" className="mt-3">
            <Tab eventKey="1" className="mt-5" title={<h5 className="mb-0"><b>Por tipo de servicio</b></h5>}>
            <div className="p-3">
                        <h5 className="mb-2"><b>Selecciona un tipo de servicio</b></h5>
                        <Row>
                            <Col md={8}><TiposServicioSelector defaultValue={tipoServicioSeleccionado?._id} onChangeValue={(data) => onChangeValue(data)} /></Col>
                            <Col md={4}><Button className="w-100" disabled={tipoServicioSeleccionado ? false : true } size="sm" onClick={() => agregarTipoServicio()}>SELECCIONAR</Button> </Col>
                        </Row>

                {mostrarMantenedorTipoServicio()}
            </div>
            </Tab>
            <Tab eventKey="2" className="mt-5" title={<h5 className="mb-0"><b>Previsualizar</b></h5>}>
                <LineaTiempoNotificaciones />
            </Tab>
            {/* <Tab eventKey="2" title={<h5 className="mb-0"><b>Por módulos</b></h5>}>
            <div className="p-3">
            {mostrarMantenedorModulos()}
            </div>
            </Tab> */}
        </Tabs>
        
    </div>
}

export default ConfiguracionNotificacionesRecurso