import { Form, Table } from "react-bootstrap"
import { getDireccion, getNombreDestinatario } from "../../lib/helpers/pedidos/pedidos"
import SinDatos from "../general/sin_registros"
import RegistrosVacios from "../general/registros_vacios"

const TablaOrdenes = (props) => {
    const {
        ordenes,
        displaySelection
    } = props
    const pedidos = ordenes ? ordenes : []
    if(Array.isArray(pedidos) !== true) return <RegistrosVacios/>
    if(pedidos.length < 1) return <RegistrosVacios/>


    const marcarCheckBoxes = (e) => {
        const value = e.target.checked
        const list = document.querySelectorAll('.delpa-checkpedido input[type="checkbox"]');
            list.forEach((checkbox) => {
                checkbox.checked = value;
            })
        if(props.onChangeAll) props.onChangeAll({value})
        
    }
    
    const verificarCheckBoxes = (e) => {
        const { name } = e.target
        const value = e.target.checked
        console.log(name, value)
        if(props.onChangeSingular) props.onChangeSingular({name,value})
    }

    return <div className="table-responsive mt-3">
        <h5><b>{pedidos.length} Órdenes</b></h5>
        <Table>
            <thead>
                <tr>
                    {displaySelection === true ? <th>
                        <Form.Check
                                                    inline
                                                    type="checkbox"
                                                    onChange={marcarCheckBoxes}
                                                />
                    </th> : false }
                    <th>Referencia</th>
                    <th>Destinatario</th>
                    <th>Dirección</th>
                </tr>
            </thead>
            <tbody>
                {
                    pedidos.map((orden,ir) => {
                        return <tr key={orden._id}>
                            {displaySelection === true ? <th>
                                <Form.Check
                                                    inline
                                                    name={orden._id}
                                                    type="checkbox"
                                                    className={`delpa-checkpedido ruta-${ir}`}
                                                    id={orden._id}
                                                    defaultChecked={orden.seleccionado === true}
                                                    onChange={verificarCheckBoxes}
                                                />
                            </th> : false }
                            <th>{orden.pedido}</th>
                            <th>{getNombreDestinatario(orden)}</th>
                            <th>{getDireccion(orden)}</th>
                        </tr>
                    })
                }
            </tbody>
        </Table>
    </div>
}

export default TablaOrdenes