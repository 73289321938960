import { useRef, useState } from "react"
import { Button, Modal } from "react-bootstrap"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { useDispatch, useSelector } from "react-redux"
import { maps_key, urlapi } from "../../../lib/backend/data"
import { FaRoute, FaTrafficLight } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";

const tipos_direccion = ["geocode","establishment"]

const AgregarPunto = (props) => {
    const {
        height
    } = props
    const pais = useSelector(state => state.pais)
    const sesion = useSelector(state => state.miusuario)
    const [ origen, setOrigen ] = useState(false)
    const [ destino, setDestino ] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const [ routes, setRoutes ] = useState([]) 
    const [ wayPoints, setWayPoints ] = useState([]) 

    const handleClose = () => {
        setOrigen(false)
        setDestino(false)
        setRoutes([])
        setWayPoints([])
        return setShowModal(false)
    }
    
    const generateCircularCoordinates = (latitude, longitude, radiusInMeters) => {
        const center = [longitude, latitude];
        const radiusInKilometers = radiusInMeters / 1000;
        const numPoints = 20
        const coordinates = [];

        for (let i = 0; i < numPoints; i++) {
            const angle = (i / numPoints) * 2 * Math.PI;
            const dx = radiusInKilometers * Math.cos(angle);
            const dy = radiusInKilometers * Math.sin(angle);

            const point = [
            center[0] + (dx / (111.32 * Math.cos(center[1]))),
            center[1] + (dy / 111.32)
            ];

            coordinates.push(point);
        }

        // Cierre de la forma
        coordinates.push(coordinates[0]);

      
        return coordinates;
      };

      
    const generateSquareCoordinates = (latitude, longitude, sideLengthInMeters) => {
        const center = [longitude, latitude];
        const halfSideLengthInKilometers = sideLengthInMeters / 2000;
      
        const coordinates = [
          [center[0] - halfSideLengthInKilometers, center[1] - halfSideLengthInKilometers],
          [center[0] + halfSideLengthInKilometers, center[1] - halfSideLengthInKilometers],
          [center[0] + halfSideLengthInKilometers, center[1] + halfSideLengthInKilometers],
          [center[0] - halfSideLengthInKilometers, center[1] + halfSideLengthInKilometers],
          [center[0] - halfSideLengthInKilometers, center[1] - halfSideLengthInKilometers], // Cierre del cuadrado
        ];
      
        return coordinates;
      };

    
    const formularioIngresoData = () =>{
        return <div>
            <label className="form-control-label d-block"><FiMapPin /> Dirección</label>
            <ReactGoogleAutocomplete
                    className="form-control"
                    options={{
                        types: tipos_direccion,
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          let level_3 = ''

                          const buscar_level_3 = place.address_components.findIndex(comp => {
                            return comp.types.includes('administrative_area_level_3')
                        })
                        if(buscar_level_3 > -1) level_3 = place.address_components[buscar_level_3].long_name

                          const geo_data = {
                              direccion: place.formatted_address,
                              place,
                              administrative_area_level_1_: region,
                              administrative_area_level_2: comuna,
                              administrative_area_level_3: level_3,
                              status: true,
                              location: {
                                type: "Point",
                                coordinates: [
                                    lng, lat
                                ]
                              }
                          }

                          let puntos            = []
                          let coordenadas       = []

                          puntos.push(lng, lat)

                          coordenadas.push({
                                titulo: place.formatted_address,
                                type: "Feature",
                                properties: {},
                                geometry: {
                                    type: "Polygon",
                                    coordinates: [generateSquareCoordinates(lat, lng, 1)]
                                }
                          })

                        if(props.onSelectPoint) props.onSelectPoint({
                            coordenadas,
                            puntos
                        })
                        handleClose()                       
                        //   return setOrigen({...{}, ...geo_data})
                    }}
                    />
                    {/* {mostrarPuntos()} */}
        </div>
    }


    const mostrarFormulario = () => {
        return <div>
            {formularioIngresoData()}
        </div>
    }

    return <div>
            <Button size="sm" variant="light" className="w-100 mb-3" onClick={() => setShowModal(true)}><FaRoute /> AGREGAR PUNTO DE CONTROL</Button>
            <Modal show={showModal} size="xl" onHide={handleClose} centered >
                <Modal.Header closeButton>
                <Modal.Title>Busca la ruta indicada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {mostrarFormulario()}
                </Modal.Body>
            </Modal>
    </div>

}

export default AgregarPunto