import { useCallback, useEffect, useState } from "react"
import { Card, Col, Form, Row, Spinner } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { maps_key, tipos_direccion, urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import { url_images } from "../../lib/global/data"
import { SiTwilio } from "react-icons/si"
import ReactGoogleAutocomplete from "react-google-autocomplete"

const RappiConfiguracion = (props) => {

    const estructura = {
        id_customer: "",
        secret: ""
    }
    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState({
        detalles: estructura
    })
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'

    useEffect(() => {
        obtenerDatos("uber")
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "credenciales",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)
                setEstructuraActual(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const handleChangeExtra = (e, type, campo) => {
        const valor = e.target.value
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 setPendienteGuardar(true)
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].extra = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    const handleCheck = (e, type, campo) => {
        const valor = e.target.checked
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].onlyRead === true) return false
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        } else if(type === "campos"){
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            if( i_ > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.campos[i_].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.detalles) actual.detalles = {}
            actual.detalles[name] = value
            actualizar(actual)
            return { ...actual }
        })
    }

    const mostrarBoton = (type, campo) => {
        if(type === "modulo"){
            let activo = false
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }
            return <div>
                <Row>
                    <Col><h6 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }} className="mb-0">{titulo}</h6></Col>
                    <Col className="text-right">
                    <Form.Group key={`${type}-${campo}`} className="mb-1" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" checked={activo} label={ activo === true ? "Activo" : "Inactivo"} onChange={(e) => handleCheck(e,type,campo)} />
                    </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <label className="form-control-label">Contenido extra al final del módulo</label>
                        <textarea className="form-control" value={extra} onChange={(e) => handleChangeExtra(e,type, campo)}></textarea>
                    </Col>
                </Row>
                
                
            </div>
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Group key={`${type}-${campo}`} className="mb-3" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" checked={activo} label={titulo} onChange={(e) => handleCheck(e,type,campo)} />
                </Form.Group>
            </div>
        }
    }

    if(loading) return <PantallaCargando />

    return <div>
        <Row>
            <Col><p>Configura las credenciales de tu integración a Uber</p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spinner size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                <img src={`${url_images}/uber.png`} style={{ width: "45%", marginBottom: 10 }} />
                {/* <h3><b>Integra PedidosYa</b></h3> */}
                <h5>Para solicitar pickup y delivery</h5>
                <p>Contáctanos para realizar la configuración y puesta en marcha por tí.</p>
                <label className="form-control-label">ID CLIENT <a hreF="https://developer.uber.com/docs/deliveries/api-reference/daas" target="_blank"><b>Documentación</b></a> </label>
                <input placeholder="Ingresa tu ID de cliente" className="form-control mb-3" name="client_id" value={estructuraActual?.detalles.client_id} onChange={handleChange} />

                <label className="form-control-label">ID CUSTOMER</label>
                <input placeholder="Ingresa tu ID Customer" className="form-control mb-3" name="id_customer" value={estructuraActual?.detalles.id_customer} onChange={handleChange} />

                <label className="form-control-label">SECRET</label>
                <input type="password" placeholder="Ingresa tu secreto" className="form-control mb-3" name="secret" value={estructuraActual?.detalles.secret} onChange={handleChange} />

                <label className="form-control-label">Dirección de bodega <b className="text-primary">(Para usar como origen)</b></label>
                <ReactGoogleAutocomplete
                    className="form-control"
                    placeholder="Ingresa una dirección de origen"
                    defaultValue={estructuraActual?.detalles.origen?.direccion ? estructuraActual?.detalles.origen?.direccion : ""}
                    options={{
                        types: tipos_direccion,
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          let level_3 = ''

                          const buscar_level_3 = place.address_components.findIndex(comp => {
                            return comp.types.includes('administrative_area_level_3')
                        })
                        if(buscar_level_3 > -1) level_3 = place.address_components[buscar_level_3].long_name

                          const geo_data = {
                              direccion: place.formatted_address,
                              place,
                              administrative_area_level_1_: region,
                              administrative_area_level_2: comuna,
                              administrative_area_level_3: level_3,
                              status: true,
                              location: {
                                type: "Point",
                                coordinates: [
                                    lng, lat
                                ]
                              }
                          }
                          return setEstructuraActual(prev => {
                            let actual = {...prev}
                            if(!actual.detalles) actual.detalles = {}
                            actual.detalles.origen = geo_data
                            actualizar(actual)
                            return { ...actual }
                        })
                    }}
                    />
            </Col>
        </Row>
    </div>
}

export default RappiConfiguracion