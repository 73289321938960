import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { urlapi } from "../../lib/backend/data";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";
import EstaticoRuta from "./receipt";


const ImprimirManifest = (props) => {
  const {
    ruta,
  } = props;
  const [showModal, setShowModal] = useState(false);
  const [dataResponse, setDataResponse] = useState(null);
  const [loadingEstados, setLoadingEstados] = useState(false)

   //REACT-TO-PRINT
   const Recibo = useRef();
   const pageStyle = `
       @page {
           size: 62mm;
       }
       @media print {
           .page-break {
           page-break-before: always;
           }
           .main {
           padding:20px;
           }
       }  
       `;
   const handlePrint = useReactToPrint({
     content: () => Recibo.current,
     pageStyle
   });



  if (!ruta) return false;

  
  // return <div>
  //   {/* {JSON.stringify(ruta)} */}
  //   <Button className="border-0 bg-transparent text-secondary pt-2 hover" style={{fontSize:14}}>
  //       Imprimir Manifiesto
  //   </Button>
  // </div>;

  return (
    <div>
        {/* ... */}
        <Button className="border-0 bg-transparent text-secondary pt-2 hover" style={{fontSize:14}} onClick={() => { 
            // imprimirPedido(); 
            setShowModal(true);
        }}>
        <i className="fa-solid fa-print"></i> Imprimir Manifiesto
        </Button>

        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
            <Modal.Header closeButton className="pb-0">
                <Modal.Title className="text-primary text-center"><i className="fa-solid fa-print"></i> Impresión de Manifiesto de Rutas
                {/* <p className="text-muted">{ruta.length === 1 ? 'Imprimir recibo de 1 orden' : `Imprimir recibos de ${ruta.length} órdenes`}</p> */}
                </Modal.Title>
            </Modal.Header>
                <div className='d-flex justify-content-end'>
                <hr className="text-primary"/>
                    <Button variant='sm' className="btn btn-success shadow-sm mt-0 mb-2 mr-4" onClick={handlePrint}><i className="fa-solid fa-print"></i> <b>IMPRIMIR</b></Button>
                </div>
            <Modal.Body className="overflow-auto border-top border">
                <div ref={Recibo} className='pt-2 main'>
                    <div style={{ pageBreakAfter: 'always' }}>
                        <EstaticoRuta ruta={ruta} />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>
  )

}

export default ImprimirManifest;