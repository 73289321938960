import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import Chart from "react-google-charts";
import { es } from 'date-fns/locale'
import { toast } from 'react-toastify';
import { urlapi } from '../../../lib/backend/data';
import { formatDate, opcionesfecha } from '../../../lib/helpers/opcionesfecha';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'react-bootstrap';
import { formatoMoneda } from '../../../lib/helpers/main';
import { Bar } from 'react-chartjs-2';
import SelectorRangoFechas from '../../general/selector_rango_fechas';
import { stringByNameModule, stringByTitleCost, unitMeasureByModule } from '../../../lib/helpers/data/facturacion/main';
import GraficoDona from '../graficos/dona';
import HelperModal from '../../general/helperModal';
import { DateTime } from 'luxon';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import { BarChart } from '@mui/x-charts/BarChart';
const theme = createTheme({ })

const procesarGraficosHistorico = (datos) => {
    let datos_procesados = [[ 'Mes', 'Operaciones' ]]
    datos.map(m => {
        return datos_procesados.push([ m.name, m.operaciones ])
    })
    return datos_procesados
}

const InformeGeneral = (props) => {
    const initialDate = {
        startDate: DateTime.now().startOf("day").minus({ weeks: 4 }).toJSDate(),
        endDate: DateTime.now().toJSDate(),
        key: 'selection',
    }
    const [ ordenes, setOrdenes ] = useState( props.ordenes ? props.ordenes : false)
    const [ datos, setDatos ] = useState([])
    const [ graficosOperaciones, setGraficosOperaciones ] = useState([])
    const [ graficosProformas, setGraficosProformas ] = useState([])
    const [ graficosHistorico, setGraficosHistorico ] = useState([])
    const [ graficosCostoSubTipo, setGraficosCostoSubTipo ] = useState([])
    const [ graficosCostoTipo, setGraficosCostoTipo ] = useState([])
    const [ datosGeneral, setDatosGeneral ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ showDatePicker, setShowDatePicker ] = useState(true)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const modo_default = useSelector(state => state.modo)

    useEffect(() => {
        setOrdenes(props.ordenes)
        refrescarRegistros()
    }, [])

    const refrescarRegistros = async () => {
        const desde = formatDate(selectionRange.startDate)
        const hasta = formatDate(selectionRange.endDate)

        const url = `${urlapi}/facturacion/informe-v2`
        setLoading(true)
        return fetch(url, {
            method:'POST',
            body: JSON.stringify({
                desde, hasta, modo: modo_default === "cliente" ? "cliente" : "proveedor"
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            console.log(pros)
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoading(false)
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                return setLoading(false)
            }
            if(pros.empresas){
                if(Array.isArray(pros.empresas) !== false){
                    setDatos(pros.empresas)
                    if(pros.empresas.length > 0) setShowDatePicker(false)
                }
                if(Array.isArray(pros.grafico_costo_subtipo) !== false) setGraficosCostoSubTipo(pros.grafico_costo_subtipo)
                if(Array.isArray(pros.grafico_costo_tipo) !== false) setGraficosCostoTipo(pros.grafico_costo_tipo)
                if(Array.isArray(pros.graficos_operaciones) !== false) setGraficosOperaciones(pros.graficos_operaciones)
                if(Array.isArray(pros.graficos_proformas) !== false) setGraficosProformas(pros.graficos_proformas)
                if(Array.isArray(pros.graficos_tendencia_operacional) !== false) setGraficosHistorico(pros.graficos_tendencia_operacional)
                if(typeof pros.general === 'object') setDatosGeneral(pros.general)
            }
            setLoading(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoading(false)
        })
    }

    const cambiarRangoFecha = (item) => {
        console.log(item.selection)
        return setSelectionRange(item.selection)
    }

    const mostrarRegistros = () => {
        if(datos.length < 1) return <HelperModal tipo="facturacion" style='nodata' />
        const ordenados = datos.sort((a,b)=>{
            return a.facturacion_real < b.facturacion_real
        })

        const options = {
            type: "bar",
            scales: {
              y: {
                beginAtZero: false,
              },
            },
          };
        
        return <div>
            <Row>
                {
                    Object.keys(datosGeneral).map((key,ib) => {
                        return <Col key={`obj-${ib}`} >
                        <div className='card p-2 mb-3'>
                            <span style={{ fontSize: 10 }} className='mb-0 d-block'>{stringByNameModule(key)}</span>
                            <h5>{formatoMoneda(datosGeneral[key])}{unitMeasureByModule(key)}</h5>
                        </div>
                    </Col>
                    })
                }

                <Col md={12} className='mb-3'>
                <div className='card p-3'>
                <h4><b>{datos.length} Empresas</b></h4>
            
                <div className='table-responsive '>
                    <table className='table mb-0'>
                        <thead>
                            <tr>
                                <th>Rut</th>
                                <th>Nombre</th>
                                <th>Operaciones</th>
                                <th>F. esperada</th>
                                <th>Monto en proformas</th>
                                <th>F. real</th>
                                <th>Costo asociado</th>
                            </tr>
                        </thead>
                        <tbody>
                            { ordenados.map(info => {
                                let porcentaje_sin_gestion = info.total_pendiente_proforma/info.facturacion_esperada*100
                                if(isNaN(porcentaje_sin_gestion) !== false) porcentaje_sin_gestion = 0
                                return <tr key={`${info.cliente}`}>
                                    <th>{info.cliente}</th>
                                    <th>{info.logotipo ? <img src={info.logotipo} style={{ width: "100%", maxWidth: 100 }} /> : info.razonsocial}</th>
                                    <th>{formatoMoneda(info.movimientos_facturacion)}</th>
                                    <th>{formatoMoneda(info.facturacion_esperada)}</th>
                                    <th>{formatoMoneda(info.facturacion_proforma)}</th>
                                    <th>{formatoMoneda(info.facturacion_real)}</th>
                                    <th>{formatoMoneda(info.total_costos)}</th>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
                </Col>

                <Col md={12} className='mb-3'>
                    <h4 className='mb-0'>Segmentación de costos</h4>
                    <Row>
                        <Col md={4}>
                        <span className='d-block mb-0' style={{ fontSize: 11 }}>Costos segmentados</span>
                        { graficosCostoTipo.length > 0 ? <GraficoDona datos={graficosCostoTipo} /> : <h5><b>No hay costos asociados</b></h5> }
                        </Col>
                        {
                            graficosCostoTipo.length > 0 ? graficosCostoTipo.map(tipo => {
                                return  <Col xs={4}>
                                        <span className='d-block mb-3' style={{ fontSize: 11 }}>{stringByTitleCost(tipo.titulo)}</span>
                                        <GraficoDona datos={tipo.subtipos} />
                                    </Col>
                            }) : false 
                        }
                        </Row>
                </Col>
                <Col md={12}>
                    <div className='card p-2 mb-3'>
                        {
                            graficosHistorico.map(ano => {
                                const datos_procesados = procesarGraficosHistorico(ano.meses_operaciones)

                                const data = {
                                    labels: datos_procesados.map((d,p) => {
                                        if(!p) return false
                                        return d[0]
                                    }).filter(e => e),
                                    datasets: [
                                      {
                                        label: 'Cantidad de operaciones',
                                        maxBarThickness: 20,
                                        data: datos_procesados.map((d,p) => {
                                            if(!p) return undefined
                                            return d[1]
                                        }).filter(e => typeof e !== "undefined"),
                                        backgroundColor: 'rgb(27, 54, 101)',
                                        //borderColor: 'rgba(75, 192, 192, 1)',
                                        borderWidth: 0,
                                      },
                                    ],
                                  };
                                  
                                return <div style={{ width: "100%" }}>
                                    <h4><b>Ventas mensuales</b></h4>
                                    {/* <Bar data={data} options={options} /> */}
                                    <ThemeProvider theme={theme}>
                                    <BarChart
                                        {...{
                                            width: 700,
                                            height: 200,
                                            xAxis: [{ data: data.labels, scaleType: 'band' }],
                                        }}
                                        series={[
                                            { data: data.datasets[0].data, label: 'Operaciones' },
                                        ]}
                                        />
                                    {/* <SparkLineChart
                                        plotType="bar"
                                        data={data.datasets[0].data}
                                        xAxis={{
                                            scaleType: "band",
                                            data: data.labels,
                                            valueFormatter: (value) => value.toString()
                                        }}
                                        height={200}
                                        showTooltip
                                        showHighlight
                                        slots
                                        area
                                        curve          
                                        /> */}
                                    </ThemeProvider>   
                                </div>
                            })
                        }
                    </div>
                </Col>
                <Col md={12}>
                    <div className='card p-2 mb-3'>
                    {
                        graficosOperaciones.length > 0 ? <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={graficosOperaciones}
                        options={{
                            title: "Cantidad de operaciones",
                            chartArea: { width: "50%" },
                            colors: ["#5e72e4", "#23c86d"],
                            hAxis: {
                              title: "Total de operaciones",
                              minValue: 0,
                            },
                            vAxis: {
                              title: "Empresas",
                            },
                          }}
                        /> : <h4>Sin datos para mostrar</h4>
                    }
                    </div>
                </Col>
                <div className='col-md-12'>
                    <div className='card p-2 mb-3'>
                    {
                        graficosProformas.length > 0 ? <Chart
                        chartType="BarChart"
                        width="100%"
                        height="400px"
                        data={graficosProformas}
                        options={{
                            title: "Total proformas",
                            chartArea: { width: "50%" },
                            colors: ["#5e72e4", "#23c86d"],
                            hAxis: {
                              title: "Total de proformas",
                              minValue: 0,
                            },
                            vAxis: {
                              title: "Empresas",
                            },
                          }}
                        /> : <h4>Sin datos para mostrar</h4>
                    }
                    </div>
                </div>
                <div className='col-md-12'>
                    <div className='card p-2 mb-3'>
                        {
                            graficosOperaciones.length > 0 ? <Chart
                            chartType="PieChart"
                            width="100%"
                            height="400px"
                            data={graficosOperaciones}
                            options={{
                                title: "Porcentaje Operaciones",
                                is3D: true
                              }}
                            /> : <h4>Sin datos para mostrar</h4>
                        }
                    </div>
                </div>
            </Row>
        </div>
    }

    const mostrarDatePicker = () => {
        if(showDatePicker !== true) return <button className='btn btn-sm mb-3 btn-outline-warning' onClick={()=> setShowDatePicker(true) }>Seleccionar fechas</button>

        return <div>
            <button className='btn btn-sm mb-3 btn-outline-warning d-block' onClick={()=> setShowDatePicker(false) }>Ocultar selector fechas</button>
            <DateRangePicker
                locale={es}
                showSelectionPreview={true}
                editableDateInputs={true}
                onChange={item => cambiarRangoFecha(item)}
                moveRangeOnFirstSelection={false}
                ranges={[selectionRange]}
                direction="horizontal"
                scroll={{ enabled: true }}
                months={2}
            />
        </div>
    }

    let rangoactual = ''

    if(selectionRange.startDate){
        const stringdesde = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
        rangoactual += `Desde ${stringdesde}`
    }

    if(selectionRange.endDate){
        const stringhasta = selectionRange.endDate.toLocaleDateString('es-ES', opcionesfecha)
        rangoactual += ` hasta ${stringhasta}`
    }

    if(selectionRange.startDate === selectionRange.endDate){
        const stringunicafecha = selectionRange.startDate.toLocaleDateString('es-ES', opcionesfecha)
        rangoactual = ''
        rangoactual = stringunicafecha
    }

    return <div>
    <Row className='mb-3'>
        <Col md={9}><SelectorRangoFechas default_range_dates={selectionRange} onChange={item => cambiarRangoFecha(item)} /></Col>
        <Col md={3} className='row align-items-end'>
            {/* <label className='form-control-label d-block'>Click para consultar</label> */}
            {loading === true ? <Spinner animation='border' /> : <button className='btn btn-primary shadow-sm' onClick={()=>refrescarRegistros()}><b>CONSULTAR</b></button>}
        </Col>
    </Row>
    {mostrarRegistros()}
    </div>
}

export default InformeGeneral