import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Header from '../Header'
import { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import 'moment/locale/es';
import ConductoresListado from '../../subComponents/conductores/list'
import { cerrarSesion } from '../../redux/actions/session'
import HelperModal from '../../subComponents/general/helperModal'

class Vehiculos extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user))
        }
    }
    render(){
        const { user } = this.state
        return <div className="fluid">
              
              <Row>
                <Col>
                <h4 className='text-primary' style={{fontWeight:900}}>Vehículos</h4>
                </Col>
                <Col><HelperModal tipo="flota" style="modal" /></Col>
              </Row>
              <Row>
                  <Col md={12}>
                    <ConductoresListado token={user.tokenSession} condicion_default={{}} />
                  </Col>
              </Row>
      </div>
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Vehiculos);