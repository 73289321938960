import { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { fechaATextoSimple } from '../../lib/helpers/helpers';
import { useDispatch } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';

const BuscadorExcel = (props) => {
    const {
        defaultValue
    } = props
    const [ formatos, setFormatos ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const token = props.token ? props.token : false
    const dispatch = useDispatch()
    const refSelect = useRef(null)

    const listadoInicial = async (condicion, page) => {
        setLoading(true)
        return fetch(`${urlapi}/excel/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setOptions(res.map(empresa => ({ value: empresa._id, label: empresa.titulo })))
                let respuesta = res.map(empresa => ({ value: empresa._id, label: empresa.titulo }))
                if(defaultValue){
                    const i = respuesta.findIndex(e => e.value === defaultValue)
                    if(i > -1 ){
                        if(refSelect){
                            console.log(respuesta[i])
                            refSelect.current.setValue(respuesta[i])
                        }
                    }
                }
                setFormatos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    } 

    useEffect(() => {
        listadoInicial({},1)
    }, [])

    const buscar = async (value) => {
        if(!token) return []
        return fetch(`${urlapi}/rutas/search`,{
            method:'POST',
            body: JSON.stringify({
                value
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return []
            } else if(res.errorMessage){
                return []
            }
            return res.length > 0 ? res.map(empresa => ({ value: empresa._id, label: `RUTA ${empresa.id} · ${fechaATextoSimple(empresa.fecha_despacho)}` })) : []
        })
        .catch(error => {
            toast.error(error.message)
            return false
        })
    }

    const handleChange = (e) => {
        console.log({e})
        const i = formatos.findIndex(p => p._id === e.value)
        if(i > -1) if(props.onChangeValue) props.onChangeValue(formatos[i])
        return false
    }

    // let default_value = ""
    // if(defaultValue){
    //     const i = options.findIndex(e => e.value === defaultValue)
    //     if(i > -1 ){
    //         if(refSelect){
    //             // default_value = options[i]
    //             console.log(options[i])
    //             refSelect.current.setValue(options[i])
    //         }
    //     }
    // }

    return <div className='mb-3'>
        <label className='form-control-label'>Seleccionar un formato excel precargado</label>
                    <Select  
                    ref={refSelect}
                    placeholder="Buscar formato excel precargado..."
                    options={options}
                    isLoading={loading}
                    noOptionsMessage={()=>"Sin opciones"}
                    loadingMessage={()=>"Cargando información..."}
                    onChange={handleChange}
                    />
    </div>
}

export default BuscadorExcel