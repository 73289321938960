import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { useSelector } from 'react-redux';
import { url_images } from '../../lib/global/data';
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { estilo_last_mile } from '../../lib/global/styles';
import NavigationCentinela from '../centinela/navigation';
import { fechaATexto } from '../../lib/helpers/helpers';

const ListadoReglas = (props) => {
    const {
        default_condition
    } = props
    const [ conductores, setConductores ] = useState([])
    const [ filtro, setFiltro ] = useState('')
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const columnas = props.columnas ? props.columnas : [
        { key: 'titulo', titulo: "Título" },
        { key: 'descripcion', titulo: "Descripción" },
        { key: 'createdAt', titulo: "Fecha creación" },
    ]

    const getConductores = () => {
        const url = `${urlapi}/reglas/list`;
        let options = []
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                condicion: {}
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error("Sin datos")
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
            } else if(Array.isArray(pros) !== false){
                setConductores(pros)
            }
            setLoadingConductores(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingConductores(false)
        })
    }

    const onSelected = (id) => {
        if(props.onSelected) return props.onSelected(id)
    }

    const handleChangeFiltro = (e) => {
        const { name, value } = e.target
        return setFiltro(value)
    }

    useEffect(() => {
        getConductores()
    }, [ default_condition ])

    const nueva = () => {
        if(props.onNew) return props.onNew()
    }

    const mostrarDatos = () => {

        if(conductores.length < 1) return <div>
        <div className='p-5 bg-white'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                    <h4 className="mb-0"><i className="fa-solid fa-eye"></i> CENTINELA</h4>
                    <h1><b>Reglas de negocio</b></h1>
                    <img src={`${url_images}/animations/cargo-list.gif`} style={{ width: "50%" }} />
                    <p>Puedes generar reglas de negocio para cada tipo de operaciones, por ejemplo puedes definir que desde que una orden es creada, no puede pasar más de una día sin que alguien la gestione.</p>
                    <Button size="sm" variant="primary" onClick={() => nueva()}>CREAR NUEVA</Button>
                </Col>
            </Row>
        </div>
    </div>

        const filtrar = filtro.toLowerCase();
        let registros_filtrados = conductores.filter(item => {
        return Object.keys(item).some(key => {
            if(item[key]){
            return item[key].toString().toLowerCase().includes(filtrar)
            }
        }
        )
        })
    
        return <div className='mt-3 mb-3'>
            <NavigationCentinela />
            <Button size="sm" variant="primary" className='mb-3' onClick={() => nueva()}>CREAR NUEVA</Button>
            <h4>{conductores.length} Registros</h4>
            <input name="filtro_conductores" placeholder='Filtrar resultados' className='form-control mt-3 mb-3' onChange={handleChangeFiltro}  />
            { registros_filtrados.length < conductores.length ? <span className='spanguia'>{registros_filtrados.length} Coincidencias</span> : false }
            <table  className="table">
                          <thead className="thead-light">
                            <tr>
                                <th>Título</th>
                                <th>Acción</th>
                                <th>Descripción</th>
                                <th>Fecha creación</th>
                            </tr>
                          </thead>
                          <tbody>
      
              {
                  registros_filtrados.map((conductor, i) => {
                          return <tr className="tablaresumen" key={`tr${i}`}>
                            <th><h3 className='mb-1'>{conductor.titulo}</h3></th>
                            <th><Button size="sm" variant='light' onClick={()=>onSelected(conductor)} >VER DETALLES</Button></th>
                            <th>{conductor.descripcion ? conductor.descripcion : <Badge variant="dark">Sin información</Badge>}</th>
                            <th>{fechaATexto(conductor.createdAt)}</th>
                          </tr>
                      })
              }
                          </tbody>
      
      </table>
        </div>
    
    }

    if(loadingConductores) return <div className='p-5 bg-white'>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                <img src={`${url_images}/animations/Tracking.gif`} style={{ width: "50%" }} />
                <h3 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Cargando información...</h3>
            </Col>
        </Row>
    </div>

    return <div>
        {mostrarDatos()}
    </div>
}

export default ListadoReglas