import { useEffect, useState } from "react"
import { Card, Col, Nav, Navbar, Row, Spinner } from "react-bootstrap"
import { useSelector } from "react-redux"
import socket from '../../../lib/websockets'
import { urlapi } from "../../../lib/backend/data"
import { toast } from "react-toastify"
import ProcesarCanalModal from "../../canales/procesar_modal"
import EscaneoBultoInput from "./escaneo_bulto"
import EscaneoBultoRemoverInput from "./escaneo_remover_bulto"
import LoadingScanWarehouse from "../../general/loadingAnimations/loadingScan"

const ExpedicionesControl = (props) => {
    const session = useSelector(state => state.miusuario)
    const [ conductores, setConductores ] = useState([])
    const [ pedidos, setPedidos ] = useState([])
    const [ bultos, setBultos ] = useState([])
    const [ conductorSeleccionado, setConductorSeleccionado ] = useState(false)
    const [ emitiendoCanal, setEmitiendoCanal ] = useState(false)
    const [ loadingMaster, setLoadingMaster ] = useState(true)

    const obtenerDatos = async () => {
        setLoadingMaster(true)
        return fetch(`${urlapi}/bodega/expediciones/list`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
            console.log(pros)
              if(!pros){
                toast.error('Sin datos del servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros.pedidos){
                if(Array.isArray(pros.pedidos) !== false){
                    setPedidos(pros.pedidos)
                }
                if(Array.isArray(pros.conductores) !== false){
                    setConductores(pros.conductores)
                }
                if(Array.isArray(pros.bultos) !== false){
                    setBultos(pros.bultos)
                }
              }
              return setLoadingMaster(false)
          })
          .catch(async error => {
            console.log(error)
              toast.error("No pudimos consultar la información de este pedido, intente de nuevo recargando la página")
                return setLoadingMaster(false)
          })
    }

    const validarPedido = async (id, idconductor) => {
        return fetch(`${urlapi}/ordenes/details`, {
            method: 'POST',
            body: JSON.stringify({
                id,
                campos: [ 'bultos', 'refrigerado', 'congelado', 'pedido']
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Sin datos del servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros._id){
                const bultos = conteoBultos(pros)
                pedidos.push({
                    _id: pros._id,
                    pedido: pros.pedido,
                    bultos,
                    idconductor
                })
                console.log(pedidos)
                return setPedidos([...[], ...pedidos])
              }
          })
          .catch(async error => {
              console.log(error)
            return toast.error("No pudimos consultar la información de este pedido, intente de nuevo recargando la página")
          })
    }

    const eliminarBulto = (idbulto) => {
        console.log({idbulto,conductores})
        const i = bultos.findIndex(b => b._id === idbulto)
        bultos.splice(i,1)

        conductores.map((c,po) => {
            if(!c.bultos) return false
            c.bultos.map((bu,i) => {
                if(bu.idbulto === idbulto) conductores[po].bultos.splice(i,1)
            })
        })
        setConductores([...[], ...conductores])
        return setBultos([...[], ...bultos])
    }

    const procesarBulto = async (etiqueta) => {
        console.log(etiqueta)
        const ya_existe = bultos.filter(b => b.idbulto === etiqueta.idbulto)
        if(ya_existe.length > 0) return true
        bultos.unshift(etiqueta)
        const i = conductores.findIndex(con => con._id === etiqueta.idconductor)
        if(i > -1){
            if(!conductores[i].bultos) conductores[i].bultos = []
            conductores[i].bultos.push(etiqueta)
            const ipedido = pedidos.findIndex(flete => flete._id === etiqueta.idrecurso)
            if(ipedido < 0) validarPedido(etiqueta.idrecurso, etiqueta.idconductor)
        } else {
            const nuevo_conductor = etiqueta.conductor
            nuevo_conductor.bultos = [etiqueta]
            conductores.unshift(nuevo_conductor)
            const ipedido = pedidos.findIndex(flete => flete._id === etiqueta.idrecurso)
            if(ipedido < 0) validarPedido(etiqueta.idrecurso, etiqueta.idconductor)
        }
        setBultos([...[], ...bultos])
        return setConductores([...[], ...conductores])
    }
    
    useEffect(() => {
        obtenerDatos()
        const identificador_socket_1 = `${session.data.propietario}_escaneo_bultos`
        console.log(identificador_socket_1)
        socket.on(identificador_socket_1, etiqueta => {
            return procesarBulto(etiqueta)
        })
        return () => {
            socket?.off(identificador_socket_1)
        }
    }, [])

    const conteoBultos = (flete) => {
        let cantidad_bultos = 0
        let bultos_secos = parseInt(flete.bultos)
        let bultos_congelado = parseInt(flete.congelado)
        let bultos_refrigerado = parseInt(flete.refrigerado)
        if(isNaN(bultos_secos) !== true) cantidad_bultos = cantidad_bultos + bultos_secos
        if(isNaN(bultos_congelado) !== true) cantidad_bultos = cantidad_bultos + bultos_congelado
        if(isNaN(bultos_refrigerado) !== true) cantidad_bultos = cantidad_bultos + bultos_refrigerado
        cantidad_bultos = cantidad_bultos ? cantidad_bultos : 1
        return cantidad_bultos
    }

    

    const mostrarListadoConductores = () => {

        let seleccionado = ''
        if(conductorSeleccionado) seleccionado = conductorSeleccionado._id
        return <div>
            <h5 className="mb-0">Conductores</h5>
            <h6 className="mb-0">{conductores.length} activos</h6>
            <p className="mb-0">Los conductores a continuación tienen bultos escaneados desde la app pendientes de gestionar</p>
            <hr className="mb-2" />
            {
                conductores.map((conductor,i) => {
                    return <Card className={`p-2 shadow hover ${seleccionado === conductor._id ? 'text-white' : ''}`} bg={seleccionado === conductor._id ? 'primary' : 'light'} key={conductor._id} onClick={() => setConductorSeleccionado(conductor)}>
                        <Row>
                            <Col md={10}>
                            <h5 className="mb-0">{conductor.nombres}</h5>
                            <h6 className="mb-0">BULTOS: {conductor.bultos.length}</h6>
                            </Col>
                            <Col md={2} className="align-middle">
                            <i className="fas fa-chevron-right" style={{ verticalAlign: "-moz-middle-with-baseline" }}></i>
                            </Col>
                        </Row>
                        { (i + 1) === conductores.length ? false : <hr/> }
                    </Card>
                })
            }
        </div>
    }

    const removerPedido = (id) => {
        const i = pedidos.findIndex(p => p._id === id)
        if(i < 0) return false
        pedidos[i].idconductor = false
        return setPedidos([...[], ...pedidos])
    }

    const emitirCanal = async (idcanal, pedidos_conductor) => {
        if(pedidos_conductor.length < 1) return toast.error('Sin pedidos para aprobar')
        let incompletos = []
        for(const pedido of pedidos_conductor){
            const bultos_escaneados = bultos.filter(b => b.idconductor === conductorSeleccionado._id && b.idrecurso === pedido._id)
            if(bultos_escaneados.length !== pedido.bultos) incompletos.push(pedido.pedido)
        }
        if(incompletos.length > 0) return toast.error(`Pedidos presentan discordancias. ${incompletos.join(', ')}`)
        const pedidos_enviar = pedidos_conductor.map(p => p._id)
        const payload_enviar = {
            pedidos: pedidos_enviar,
            idconductor: conductorSeleccionado._id,
            idcanal
        }
        setEmitiendoCanal(true)
        return fetch(`${urlapi}/bodega/canal/emitir`, {
            method: 'POST',
            body: JSON.stringify(payload_enviar),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Sin datos del servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros.success === true){
                toast.success(`Enviado exitosamente órdenes escaneadas por ${pros.conductor}`, { autoClose: false })    
            }
            // this.obtenerExpediciones()
            //this.setState({ [loading]: false, id_conductor_seleccionado: false })
            return setEmitiendoCanal(false)
          })
          .catch(async error => {
            setEmitiendoCanal(false)
            return toast.error("No pudimos consultar la información de este pedido, intente de nuevo recargando la página")
          })
    }
    
    const mostrarDetallesEscaneoConductor = () => {
        if(!conductorSeleccionado) return <div className='p-5'>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                <img src="images/pedidos.png" style={{ width: "50%" }} />
                <h4>Selecciona un conductor para visualizar su operación</h4>
            </Col>
        </Row>
        </div>

        const i_driver = conductores.findIndex(driver => driver._id === conductorSeleccionado._id)
        if(i_driver < 0) return false
        const driver = conductores[i_driver]
        const pedidos_conductor = pedidos.filter(ped => ped.idconductor === conductorSeleccionado._id)
        const bultos_conductor = bultos.filter(ped => ped.idconductor === conductorSeleccionado._id)

        return <div>
            <Card bg="primary" className="text-light mb-3">
            <Row className="p-3">
                <Col md={12}>
                <h3 className="mb-1">{driver.nombres ? driver.nombres : ''} {driver.apellidos ? driver.apellidos : ''}</h3>
                <h5 style={{ padding: "5px 10px", display: "inline-block", border: "1px solid", fontSize:15 }}>PATENTE: {driver.patente}</h5>
                </Col>
                <Col md={2}>
                <h6 className="mb-0">Pedidos</h6>
                <h2 className="mb-0">{pedidos_conductor.length}</h2>
                </Col>
                <Col md={2}>
                <h6 className="mb-0">Bultos</h6>
                <h2 className="mb-0">{bultos_conductor.length}</h2>
                </Col>
            </Row>

            
            {
                emitiendoCanal ? <div className="p-2"><Spinner animation="border" /></div> : <Navbar expand="xl" className='p-0' style={{ backgroundColor: "#145fb1" }}>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav >
                <Nav.Link className="text-light" ><ProcesarCanalModal onClick={(idcanal) => emitirCanal(idcanal, pedidos_conductor)} /> </Nav.Link>
                </Nav>
                </Navbar.Collapse>
                </Navbar>
            }
            
            </Card>
            <h4>Progreso de expedición de {conductorSeleccionado.nombres}</h4>
            <Row>
                
            {
                pedidos_conductor.map(ped => {
                    const bultos_escaneados = bultos.filter(b => b.idconductor === conductorSeleccionado._id && b.idrecurso === ped._id)
                    return <Col md={3}><Card className={`p-3 text-white shadow ${ bultos_escaneados.length ===  ped.bultos ? 'bg-success' : 'bg-danger' }`}>
                        <p className="mb-0" style={{ fontSize: 11 }}>REFERENCIA</p>
                        <h6 className='mb-0'>{ped.pedido}</h6>
                        <h6 className='mb-0'><i className="fas fa-box"></i> {bultos_escaneados.length} de {ped.bultos}</h6>
                        {bultos_escaneados.length === 0 ? <button className='btn btn-outline-white' onClick={()=>removerPedido(ped._id)}>REMOVER PEDIDO</button> : false }
                    </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    if(loadingMaster === true) return <LoadingScanWarehouse />

    return <div>
        <Row>
            <Col md={12}>
                <Row>
                    <Col md={2}>
                    <h4>Bultos</h4>
                    <h1>{bultos.length}</h1>
                    </Col>
                    <Col md={2}>
                    <h4>Pedidos</h4>
                    <h1>{bultos.length}</h1>
                    </Col>
                </Row>
            </Col>
            <Col md={12}>
                <hr className="mb-3"/>
                <Row>
                    <Col><EscaneoBultoInput onScanned={data => procesarBulto(data)}  /></Col>
                    <Col><EscaneoBultoRemoverInput onScanned={idbulto => eliminarBulto(idbulto)}  /></Col>
                </Row>
                
                <hr className="mb-3"/>
            </Col>
            <Col md={3}>
                {mostrarListadoConductores()}
            </Col>
            <Col md={9}>
                {mostrarDetallesEscaneoConductor()}
            </Col>
        </Row>
    </div>
}

export default ExpedicionesControl