import { Card } from "react-bootstrap"
import { useQuery } from "../../lib/helpers/router"
import SinDatos from "../general/sin_registros"
import { RiSecurePaymentLine } from "react-icons/ri"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"

const ResultadoMetodoPago = (props) => {

    const query = useQuery()
    const gateway       = query.get("gateway")
    const status        = query.get("status")
    const session = useSelector(state => state.miusuario)

    const procesarPago = async (id, paymentid) => {
        let settings = {
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        }
        return fetch(`${urlapi}/payments/mercadopago/status?id=${id}&paymentid=${paymentid}`, settings)
        .then(res => res.json())
        .then(res => res)
        .catch(error => error)
    }

    const verifyStatus = () => {
        if(!status || !gateway) return
        if(gateway === "mercado_pago"){
            procesarPago(query.get('preference_id'), query.get('payment_id') )
        }
    }

    useEffect(() => {
        verifyStatus()
    }, [])

    const stringBystatusTransBank = (codigo) => {
        const mensajes = {
            '0': "Transacción aprobada",
            '-1': "Tarjeta inválida",
            '-2': "Error de conexión",
            '-3': "Excede monto máximo",
            '-4': "Fecha de expiración inválida",
            '-5': "Problema en autenticación",
            '-6': "Rechazo general",
            '-7': "Tarjeta bloqueada",
            '-8': "Tarjeta vencida",
            '-9': "Transacción no soportada",
            '-10': "Problema en la transacción",
            '-11': "Excede límite de reintentos de rechazos"
          };
        
          return mensajes[codigo] || "Transacción anulada";        
    }
    
    const stringAuxiliarBystatusMercadoPago = (status) => {
        const mensajes = {
            "in_process": "Te avisaremos en breves minutos cuando haya sido confirmado.",
          };
        
          return mensajes[status] || "Transacción anulada";        
    }

    const stringBystatusMercadoPago = (status) => {
        const mensajes = {
            "in_process": "El pago está siendo revisado.",
          };
        
          return mensajes[status] || "Transacción anulada";        
    }
    
    const analizarResultado = () => {
        switch (gateway) {
            case "transbank":
                const num_code = parseInt(status)
                return <div>
                    {num_code < 0 ? <p className="mb-0 text-danger">El método de pago no pudo ser habilitado</p> : <p className="mb-0 text-success">Tu método de pago fue habilitado exitosamente, si estabas realizando una operación cierra esta pestaña y vuelve a ella</p> }
                    <h3>{stringBystatusTransBank(status)}</h3>
                </div>
            case "mercado_pago":
                return <div>
                    <p className="mb-0 text-primary">{stringAuxiliarBystatusMercadoPago(status)}</p>
                    <h3>{stringBystatusMercadoPago(status)}</h3>
                </div>
            default:
                break;
        }
    }
    

    if(!status || !gateway) return <SinDatos />

    return <div>
        <h4>Resultado del método de pago</h4>
        <Card className="p-3 text-center">
        <p className="mb-3">Resultado de la transacción</p>
        <div className="mb-3">{analizarResultado()}</div>
        <p style={{ color: "gray" }}><RiSecurePaymentLine /> Nuestro sistema está protegido por los más altos estándares de seguridad, trabajamos de la mano con los sistemas de pago más seguros del país</p>
        </Card>
    </div>
}

export default ResultadoMetodoPago