import React, { Component} from 'react'
import { connect } from 'react-redux'
import { userService } from '../../services/user.service'
import { Row, Col, Container, Form, Button } from 'react-bootstrap'
import Spinner from 'react-bootstrap/Spinner'
import { validateEmail } from '../../lib/helpers/helpers'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { cerrarSesion } from '../../redux/actions/session'
import { url_images } from '../../lib/global/data'
import "../Login/login.css"
import { urlapi } from '../../lib/backend/data'

class OlvideContrasena extends Component {
        constructor(props){
            super(props)
            userService.logout();
            this.state = {
                username: '',
                password: '',
                submitted: false,
                success: false,
                loading: false,
                errorLogin: ''
            }

            this.handleChange = this.handleChange.bind(this)
        }

        handleChange(e){
            const { name, value } = e.target
            return this.setState({ [name]: value })
        }

        handleSubmit(){
            const { username } = this.state;

            if(!validateEmail(username)) return toast.error('Email inválido')

            this.setState({ loading: true });
        
            return fetch(`${urlapi}/auth/forgot-password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    accept: 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                body: JSON.stringify({
                    email: username
                })
            })
            .then(response => response.json())
            .then(data => {
                if(!data){
                    toast.error('Error al enviar el correo')
                } else if(data.errorMessage){
                    toast.error(data.errorMessage)
                } else if(data.message){
                    this.setState({ username: '', success: true })
                }
                return this.setState({ loading: false })
            })
            .catch(error => {
                this.setState({ loading: false })
                return toast.error('Error al enviar el correo')
            })
        }
        
    render(){
        const { loading, errorLogin, success } = this.state

        if(success === true){
            return <div className='login-form text-primary'>
            <Row className="justify-content-md-center">
                <Col md={8} className="centerv p-4 justify-content-center text-center">
                    <img className='d-block' src={`${url_images}/animations/Secure Login.gif`} style={{ width: 250, margin: "0 auto" }} />
                    <h2 className='d-block'>Verifica tu bandeja</h2>
                    <p>Hemos enviado un email a tu cuenta con un link de recuperación para que puedas reiniciar tu clave</p>
                    <Link to={`/login`}><i className="fa-solid fa-arrow-left"></i> Volver a Inicio de sesión</Link>
                </Col>
            </Row>
        </div>
        }
        return (
            <div className='login-form text-primary'>
                <Row className="justify-content-md-center">
                    <Col md={8} className="centerv row justify-content-center border px-5 py-4 shadow">
                        <Row className='row justify-content-center'>
                            <img alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
                        </Row>

                        <Container className="text-center">
                            <p className='mt-3 mb-5' style={{ fontWeight: 700, fontSize: 15 }}>Transformamos la experiencia en software</p>
                            <h5 style={{fontWeight:700}}>Ingresa tu email para recuperar tu contraseña</h5>
                                        <Form.Group>
                                            <Form.Control className='shadow' type="email" name="username" placeholder="Email" onChange={this.handleChange} />
                                        </Form.Group>
                                        <Form.Group>
                                            {loading ? <Spinner animation="border" /> : <Button className='shadow-sm' onClick={() => this.handleSubmit()} block variant="primary"><b>ENVIAR</b></Button>}
                                            {errorLogin ? <p className="text-danger">{errorLogin}</p> : false}
                                        </Form.Group>
                                        <h6 className='my-4' style={{fontSize:13}}><Link to={`/login`}><i className="fa-solid fa-arrow-left"></i> Volver a Inicio de sesión</Link> </h6>

                            </Container>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OlvideContrasena);