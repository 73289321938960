import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import ClientesListado from './listado'
import { useState } from 'react'
import HelperModal from '../general/helperModal'

const Clientes = (props) => {
  const session = useSelector(state => state.miusuario)
  const [ condicionDefault, setCondicionDefault ] = useState({  })
      
  const handleCheck = (e, name) => {
    const valor = e.target.checked
    return setCondicionDefault(prev => ({...prev, ...{} }))
    }

        return <div className="fluid">
            <Row>
                        <Col><h4>Clientes</h4></Col>
                        <Col className='text-right'><HelperModal tipo="clientes" style="modal" /></Col>
            </Row>
            <ClientesListado condicion_default={condicionDefault}  />
            </div>
}

export default Clientes