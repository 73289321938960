import React, { useEffect, useRef, useState } from 'react'
import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux'
import { BsBoxArrowInUpRight, BsFillSignStopFill, BsFillSignStopLightsFill, BsFillStopCircleFill } from "react-icons/bs"
import { mapbox_token, urlapi } from '../../../lib/backend/data'
import { rutas } from '../../../lib/routes/routes'
import * as turf from '@turf/turf';
import Moment from 'react-moment';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import 'dayjs/locale/es';
import { Link } from 'react-router-dom';
import ListadoConductoresRastreo from './listado';
import dayjs from 'dayjs';
import { formatDateHoy, obtenerRangos } from '../../../lib/helpers/dates';
import { duracionTiempoString, fechaUTCATexto, fechaUTCATiempo } from '../../../lib/helpers/helpers';
import { estilo_moving_truck } from '../../../lib/estilo_sitio';
import { BsStopCircleFill } from 'react-icons/bs'
import { DateTime } from 'luxon';
import { verificarMismoRadio } from '../../../lib/helpers/maps/geocercas';
import { Button, Card, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { cerrarSesion } from '../../../redux/actions/session';
import { estilo_last_mile } from '../../../lib/global/styles';

mapboxgl.accessToken = mapbox_token

const EstadisticaTrackingConductor = (props) => {
    const sesion = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const [ loading, setLoading] = useState(true)
    const [ contadores, setContadores] = useState({})
    const [ conductorSeleccionado, setConductorSeleccionado ] = useState(false)
    const [ loadingCoordenadas, setLoadingCoordenadas ] = useState(false)
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ horaConsulta, setHoraConsulta ] = useState(false)
    const [ paradas, setParadas ] = useState([])
    const [ fechaConsulta, setFechaConsulta ] = useState(formatDateHoy(new Date()))
    const [ coordenadas, setCoordenadas ] = useState([])
    const [ poligonos, setPoligonos ] = useState([])
    const { data, tokenSession } = useSelector(state => state.miusuario)
    const [ saltarCoordenadas, setSaltarCoordenadas] = useState(0)
    const [ mensajeDetenido, setMensajeDetenido] = useState(false)
    const empresa = data.empresa
    const [ marcadores, setMarcadores ] = useState([])
    const initialViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(initialViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()
    const controls = []

    const handleGeocoderResult = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
          const [lng, lat] = event.result.geometry.coordinates;
          setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
        }
      };

    const geocodificador = () => {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          marker: false, // Desactivar el marcador de ubicación seleccionada
          placeholder: "Busca una dirección",
          countries: pais
        });
        geocoder.on('result', handleGeocoderResult);
        setTimeout(() => {
          if(controls.length < 1) mapRef.current?.addControl(geocoder, "top-right")
          controls.push('geocoder')
        }, 200);
      }

      const handleLoadMap = () => {
        geocodificador()
      }

      const mostrarSeleccionado = () => {
        if(!conductorSeleccionado) return false

        return <Card size="small" style={{ marginTop: 20 }} >
            <Row>
                <Col md={20} xs={18}>
                    <p className="mb-0">Conductor seleccionado</p>
                    <h4 level={4} className="mb-0 mt-0">{conductorSeleccionado.nombres} {conductorSeleccionado.apellido_p} {conductorSeleccionado.apellido_m} <Link target="_blank" to={`${rutas.drivers.slug}/${conductorSeleccionado._id}`}><BsBoxArrowInUpRight /></Link></h4>
                    <p className="mb-0"> {conductorSeleccionado.phone}</p>
                    <Button onClick={() => setConductorSeleccionado(false)}>REMOVER</Button>
                </Col>
                <Col md={4} xs={6} style={{ textAlign: "right" }}>
                <img src={conductorSeleccionado.imagen_perfil} />
                </Col>
            </Row>
        </Card>
    }

    const ajustarCentro = (markers) => {
        console.log(markers)
        if(markers.length < 1) return false
        if(markers.length === 1){
            return centrarMapa({ latitude: markers.coordinates[1], longitude: markers.coordinates[0] }, 6)
          }
        setZoomInit(true)
        const points = markers.map(marker => turf.point([marker[0], marker[1]]));
      const collection = turf.featureCollection(points);
      const bounds = turf.bbox(collection);

      const newViewport = {
        ...viewState,
        latitude: (bounds[1] + bounds[3]) / 2,
        longitude: (bounds[0] + bounds[2]) / 2
      };

      const options = {
        padding: 30 // Ajusta el valor de padding según tus necesidades
      };

      setViewState(newViewport);
      mapRef.current.fitBounds(bounds, options);
    }

    const estaEnMismoLugar = (datos) => {
        const filtrar_validas = datos.filter(d => {
            if(!d) return false
            if(typeof d !== "object") return false
            if(!d.location) return false
            if(typeof d.location !== "object") return false
            if(!d.location.coordinates) return false
            if(Array.isArray(d.location.coordinates) !== true) return false
            if(d.location.coordinates.length < 2) return false
            return true
        }).filter(d => d)
        if(filtrar_validas.length < 1) return false

        const formar = filtrar_validas.map(g => ({ latitude: g.location.coordinates[1], longitude: g.location.coordinates[0] }))
        const verificar_radio = verificarMismoRadio( formar[0], 0.2, formar.filter((g,i) => i) )
        return verificar_radio
    }
  
    const obtenerInformacionCoordenadas = async (skip)=>{
        const { desde, hasta } = obtenerRangos(fechaConsulta, fechaConsulta)
        const inicio    = DateTime.fromFormat(fechaConsulta, 'yyyy-MM-dd').startOf("day")
        const final     = DateTime.fromFormat(fechaConsulta, 'yyyy-MM-dd').endOf("day")
        let fecha = DateTime.fromISO(inicio)
        if(horaConsulta){

            fecha = fecha.startOf("day").plus({ hours: horaConsulta.hora, minute: horaConsulta.minuto, second: 0 })
        }

        const condicion_fechas = {
            createdAt: { $gte: fecha.toUTC().toISO(), $lte: final.toUTC().toISO() }
        }
        
        /*
        */
        if(!conductorSeleccionado) return toast.error("Selecciona un conductor ")
        setLoadingCoordenadas(true)
        return fetch(`${urlapi}/conductores/buscar/coordenadas`,{
            method:'POST',
            body: JSON.stringify({
                id_driver: conductorSeleccionado._id,
                skip: typeof skip !== "undefined" ? skip : saltarCoordenadas,
                condicion: condicion_fechas
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.coordenadas)){
                if(res.coordenadas.length < 1) toast.info("Sin datos encontrados en este rango horario")
                const antes = typeof skip !== "undefined" ? skip : saltarCoordenadas
                const sumar = antes + res.coordenadas.length
                setSaltarCoordenadas(sumar)

                const no_mapear = estaEnMismoLugar(res.coordenadas)
                setMensajeDetenido(no_mapear === true ? `Detenido desde ${fechaUTCATexto(res.coordenadas[0].date)} a ${fechaUTCATexto(res.coordenadas[ (res.coordenadas.length - 1 ) ].date)}` : false)
                if(no_mapear === true){
                    const nuevo_array = [ res.coordenadas[ (res.coordenadas.length - 1 ) ] ]
                    setCoordenadas(prev => [...[], ...nuevo_array])
                } else {
                    setCoordenadas(prev => [...[], ...res.coordenadas])
                }
            }
            return setLoadingCoordenadas(false)
        })
        .catch(error => {
            toast.error("Ocurrió un problema al cargar la información")
            return setLoadingCoordenadas(false)
        })
    }

    const consultarContador = async ()=>{
        return fetch(`${urlapi}/conductores/counter/actives`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.data){
                setContadores(res.data)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Ocurrió un problema al cargar la información")
            return setLoading(false)
        })
    }
    

    useEffect(() => {
        let coordinates = []

        poligonos.map(pol => {
            if(!pol.geometry) return false
            if(typeof pol.geometry !== "object") return false
            if(!pol.geometry.coordinates) return false
            if(Array.isArray(pol.geometry.coordinates) !== true) return false
            coordinates = [...coordinates, ...pol.geometry.coordinates]
        })
        console.log({ coordinates })
        ajustarCentro(coordinates)
    }, [ poligonos ])

    const seleccionarConductor = (driver) => {
        if(!driver){
            setParadas([...[], ...[]])
            return setPoligonos([...[], ...[]])
        }
        if(!driver) return false
        if(typeof driver !== "object") return false
        if(!driver.informe) return false
        if(typeof driver.informe !== "object") return false
        
        let stops = []
        if(driver.informe.paradas){
            if( Array.isArray(driver.informe.paradas) !== false ){
                
                driver.informe.paradas.map(step => {
                    if(step.coordenada_inicial){
                        if(typeof step.coordenada_inicial === "object"){
                            if(step.coordenada_inicial.location){
                                if(typeof step.coordenada_inicial.location === "object" ){
                                    if(step.coordenada_inicial.location.coordinates){
                                        if( Array.isArray(step.coordenada_inicial.location.coordinates) !== false){
                                            if(step.coordenada_inicial.location.coordinates.length > 1){
                                                const validas = step.coordenada_inicial.location.coordinates.filter(e => e)
                                                if(validas.length < 2) return false
                                                stops.push({
                                                    desde: step.coordenada_inicial.createdAt,
                                                    hasta: step.coordenada_final.createdAt,
                                                    coordinates: step.coordenada_inicial.location.coordinates
                                                })
                                            }
                                        }
                                    }
                                }   
                            }
                        }
                    }
                })

            }
        }

        setParadas(stops)
        
        if(driver.informe.poligonos){
            if( Array.isArray(driver.informe.poligonos) !== false ){
                setPoligonos(driver.informe.poligonos)
            }
        }
        
        return
    }


    const mostrarInfoVehiculo = (veh) => {
        if(!veh) return false
        if(typeof veh !== "object") return false
        let modelo      = ''
        let marca       = ''

        if(typeof veh.modelo === "object") if(veh.modelo.titulo) modelo = veh.modelo.titulo
        if(typeof veh.marca === "object") if(veh.marca.titulo) marca = veh.marca.titulo

        return <div>
            <h4 className='mt-0 mb-0' level={5} style={{ fontSize: 13 }}>{marca} {modelo} {veh.patente} <a target='_blank' href={`${rutas.vehicles.slug}/${veh._id}`}><BsBoxArrowInUpRight /></a></h4>
        </div>
    }

    const puntoFinal = (valor) => {
        if((valor+1) === coordenadas.length) return <BsStopCircleFill size={25} />
    }
        
    const centrarMapa = (centro, max_zoom) => {
        const nuevo_zoom = initialViewState.zoom + (max_zoom ? max_zoom : 3)
        mapRef.current?.flyTo({center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000});
        setTimeout(() => {
          setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
        }, 2010);
      }

      const reiniciarReloj = () => {
        setSaltarCoordenadas(0)
        obtenerInformacionCoordenadas(0)
      }

      const handleChangeHorario = (e) => {
        if(!e){
            setHoraConsulta(false)
            return setSaltarCoordenadas(0)
        }
        const setear = {
            hora: e['$H'],
            minuto: e['$m']
        }
        setHoraConsulta(setear)
        setSaltarCoordenadas(0)
      }

      const handleChangeDate = (e) => {
        let valor = ''
        if(e) valor = formatDateHoy(e)
        setFechaConsulta(valor)
        setSaltarCoordenadas(0)
      }

      const lines = coordenadas.map((point) => point.location.coordinates);
    
      return <div style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}>
            
            <Row style={{ height: "100%" }} >
            <Col md={6} xs={12} >

            <Map
                ref={mapRef}
                {...viewState}
                onMove={evt => setViewState(evt.viewState)}
                style={{width: "100%", height: "100vh" }}
                pitch={0}
                mapStyle="mapbox://styles/mapbox/light-v9"
                mapboxAccessToken={mapbox_token}
                onLoad={handleLoadMap}
            >
                <GeolocateControl position="top-left" />
                <FullscreenControl position="top-left" />
                <NavigationControl position="top-left" />
                {
                    paradas.map((marker,pos) => {
                        const inicio = DateTime.fromISO(marker.desde)
                        const fin = DateTime.fromISO(marker.hasta)
                        let diferencia = "Parada"
                        const diferencia_minutos = fin.diff(inicio, "minutes")
                        if(diferencia_minutos.values){
                            if(typeof diferencia_minutos.values === "object"){
                                if(diferencia_minutos.values.minutes) diferencia = `Parada de ${duracionTiempoString(diferencia_minutos.values.minutes)}`
                            }
                        }

                        const content = (<div>
                            <BsFillSignStopLightsFill size={30} />
                            <p className='mb-0'>{`Desde ${fechaUTCATiempo(marker.desde)}`}</p>
                            <p className='mb-0'>{`Hasta ${fechaUTCATiempo(marker.hasta)}`}</p>
                            <a className='text-white' href={`https://www.google.com/maps?q=${marker.coordinates[1]},${marker.coordinates[0]}`} target='_blank'><BsBoxArrowInUpRight /> VER EN GOOGLE</a>
                        </div>)
                        return <Marker
                                key={marker._id}
                                longitude={marker.coordinates[0]}
                                latitude={marker.coordinates[1]}
                            >
                                <div>
                                <OverlayTrigger
            key={'right'}
            trigger="click"
            overlay={
                <Tooltip id={'right'}>{content}</Tooltip>
            }>
                <BsFillStopCircleFill size={20} />
            </OverlayTrigger>
                                </div>
                        </Marker>
                    })
                }
               {
                    poligonos.map( (poligono,i) => {
                        return <Source key={`pol-${i}`} type="geojson" data={poligono}>
                                <Layer
                                id={`pol-${i}`}
                                type="line"
                                source="my-data"
                                layout={{
                                    'line-join': 'round',
                                    'line-cap': 'round',
                                }}
                                paint={{
                                    'line-color': estilo_last_mile.color_secondary,
                                    'line-width': 4,
                                }}
                                />
                            </Source>
                    })
               }
            </Map>
           
            </Col>
            
            <Col md={6} id="box_conductores" className='p-3 bg-white' style={{ height: "100%", overflowY: "scroll", overflowX: "hidden" }}>
                <div>
                    <h4 level={3} className='m-0 text-primary' style={{ fontWeight: 900 }}><i className="fa-solid fa-id-card-clip"></i> Conductores</h4>
                    <p style={{ fontSize: 12 }}><i className="fa-solid fa-circle-info text-warning"></i> Aquí podrás ver los conductores de tu flota y el estado de conexión</p>
                    <ListadoConductoresRastreo fecha={fechaConsulta} button_text="VER INFORME" tituloHoverDriver="Toca para selcecionar y auditar" onSelect={(driver) => seleccionarConductor(driver)} tableSize="small" typeView="funcion" hideExporter={true} condicion_default={{}} hideHeader={true} />
                </div>
            </Col>

            </Row>
        </div>

}

export default EstadisticaTrackingConductor