import { useEffect, useState } from "react"
import { Button, Card, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import Etiquetas from "./"
import { urlapi } from "../../lib/backend/data"
import { cerrarSesion } from "../../redux/actions/session"
import { useDispatch, useSelector } from "react-redux"

const GeneradorEtiquetas = (props) => {
    const [ etiquetas, setEtiquetas ] = useState(props.etiquetas ? props.etiquetas : [])
    const [ loading, setLoading ] = useState(false)
    const idrecurso = props.idrecurso ? props.idrecurso : false
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession


    useEffect(() => {
        if(props.etiquetas) setEtiquetas(props.etiquetas)
    }, [ props ])

    const generarEtiquetas = async () => {
        setLoading(true)
        const url = `${urlapi}/bodega/etiquetas/generate`
        return fetch(url,{
            method:'POST',
            body: JSON.stringify({
                id: idrecurso
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setEtiquetas(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const acciones = () => {
        if(loading) return <Spinner animation="border" />
        return etiquetas.length === 0 ? <Button variant="secondary" size="sm" className="mb-3 shadow-sm" onClick={() => generarEtiquetas()}><b><i className="fa-solid fa-qrcode"></i> GENERAR ETIQUETAS</b></Button> : false
    }

    return <div>
        {acciones()}
        <Etiquetas etiquetas={etiquetas} />
    </div>
}

export default GeneradorEtiquetas