import Map, { FullscreenControl, GeolocateControl, Layer, Marker, NavigationControl, Source } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../../lib/backend/data';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import bbox from '@turf/bbox';
import { obtenerCentroMapaPorPais } from '../../../lib/helpers/data/internationa';
import { calcularPuntoCentral, procesarCoordenadasParaCentroMapa } from '../../../lib/helpers/maps/geocercas';
// import SelectorZonaAvanzado from '../../RadioOperacion/selector_zona_avanzado';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import mapboxgl from 'mapbox-gl';
import { Button, ButtonGroup, Card, Col, Dropdown, Row, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { cerrarSesion } from '../../../redux/actions/session';
import SelectorZonaAvanzado from '../../RadioOperacion/selector_zona_avanzado';
import { BsFillTrashFill, BsSquare } from 'react-icons/bs';
import { FiMapPin } from 'react-icons/fi'
import { BiDotsVerticalRounded, BiTargetLock, BiCheckboxChecked, BiCheckbox } from 'react-icons/bi';
import polyline from '@mapbox/polyline';
import { AiOutlineCheckSquare } from 'react-icons/ai';

mapboxgl.accessToken = mapbox_token

const dropdownStyles = {
  '&::after': {
    display: 'none', // Esta regla oculta el indicador de flecha
  },
};


const MapaZonasDetalle = (props) => {
    const {
        height,
        redzone,
        tipo_operacion_default,
        zona,
        default_geojson,
        geocercas_defecto,
        geometries,
        resize
    } = props

    const [ zonasBD, setZonasBD ] = useState([])
    const [geometrias, setGeometrias] = useState(geometries ? geometries : []);
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [ geocercas, setGeoCercas ] = useState(geocercas_defecto ? geocercas_defecto :[])
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const [ coleccion, setColeccion ] = useState( default_geojson ? default_geojson :{
      type: 'FeatureCollection',
      features: []
    })
    const sesion = useSelector(state => state.miusuario)
    const initialViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(initialViewState);
    const [ zoomInit, setZoomInit ] = useState(false) 
    const [ focusOnMap, setFocusOnMap ] = useState(false)
    const [seleccionadaEliminar, setSeleccionadaEliminar] = useState(0)
    const dispatch = useDispatch()
    const mapRef = useRef()
    const tipo_operacion = tipo_operacion_default ? tipo_operacion_default : "operacion"
    let [ mounted, setMounted ] = useState(false)
    const controls = []
    

    const ajustarMapaAGeocercas = () => {

      if (coleccion.features.length > 0) {

        const instancia_geocercas = JSON.parse( JSON.stringify( coleccion.features ))

        const coordinates = instancia_geocercas.reduce((acc, geofence) => {
          const geofenceCoords = geofence.geometry.coordinates;
          if (geofenceCoords.length === 1) {
            // Polygon
            const filtradas_validas = geofenceCoords[0].filter(g => {
              if(Array.isArray(g) !== true) return false
              if(g.length < 2) return false
              if(g.filter(cc => !cc).length > 0) return false
              return true
            })
            acc.push(filtradas_validas);
          } else {
            // MultiPolygon
            geofenceCoords.forEach((coords) => {
              if(coords.length < 1) return false
              acc.push(coords[0]);
            });
          }
          return acc;
        }, []);
  
        const combinedFeatures = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates,
              },
            },
          ],
        };

          const bounds = bbox(combinedFeatures);
          console.log({ bounds })
          const newViewport = {
            ...viewState,
            latitude: (bounds[1] + bounds[3]) / 2,
            longitude: (bounds[0] + bounds[2]) / 2
          };
    
          const options = {
            padding: 30 // Ajusta el valor de padding según tus necesidades
          };
    
          setViewState(newViewport);
          setTimeout(() => {
            mapRef.current?.fitBounds(bounds, options)
          }, 300);
          
        }
    }


    const mostrarSeleccionadas = () => {
      const seleccionadas = geocercas.filter(geo => geo.seleccionado === true).length
      return setSeleccionadaEliminar(seleccionadas)      
    }

    const handleGeocoderResult = (event) => {
      if (event.result && event.result.geometry && event.result.geometry.coordinates) {
        const [lng, lat] = event.result.geometry.coordinates;
        setViewState({ ...viewState, longitude: lng, latitude: lat, zoom: 12 });
      }
    };

    const geocodificador = () => {
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        marker: false, // Desactivar el marcador de ubicación seleccionada
        placeholder: "Busca una dirección",
        countries: pais
      });
      geocoder.on('result', handleGeocoderResult);
      setTimeout(() => {
        if(controls.length < 1) mapRef.current?.addControl(geocoder, "top-right")
        controls.push('geocoder')
      }, 200);
    }

    const handleLoadMap = () => {
      geocodificador()
    }
    useEffect(() => {
      
      if(mapRef) if(mapRef.current) mapRef.current.resize()

      ajustarMapaAGeocercas()
      
    }, [ coleccion, resize ])

    const resetMapView = () => {
      if(!focusOnMap) return false
      return <Button size="small" onClick={() => {
        setFocusOnMap(false)
        ajustarMapaAGeocercas()
      }}>VOLVER A MAPA COMPLETO</Button>
    }

    const centrarMapa = (centro) => {
      if(!centro) return false
      if(!centro.longitude || !centro.latitude) return toast.info("Geocerca multi polígono está en desarrollo")
      if(isNaN(centro.longitude) || isNaN(centro.latitude)) return toast.info("Geocerca multi polígono está en desarrollo")
      const nuevo_zoom = initialViewState.zoom 
      const nuevo_centro = { center: [centro.longitude, centro.latitude], zoom: nuevo_zoom, duration: 2000}
      mapRef.current?.flyTo(nuevo_centro);
      setTimeout(() => {
        setViewState({ ...viewState, longitude: centro.longitude, latitude: centro.latitude, zoom: nuevo_zoom });
      }, 2010);
    }

    const seleccionar = async (pos) => {
      let actuales = await setGeoCercas(prev => {
        let actual = prev
        actual[pos].seleccionado = actual[pos].seleccionado === true ? false : true 
        // console.log("geocerca_pos", ...actual )
        return [...[], ...actual]
      })
     
       mostrarSeleccionadas()
    
      return actuales
    }

    const mostrarBotonDeEliminarVarios = () => {
      if(geocercas.length < 1 ) return false
      if(!geocercas) return false
      return <Button size="sm" variant='light' className='w-100 mb-3' onClick={() => eliminarGeocercasSeleccionadas()}  ><BsFillTrashFill /> ELIMINAR SELECCIÓN</Button>
    }
    

    const handleChangeGeocerca = (e, id) => {
      const { value } = e.target
      const i = geocercas.findIndex(g => g._id === id)
      if(i < 0 ) return false
      geocercas[i].titulo = value
      if(props.onChangeGeocerca) props.onChangeGeocerca(geocercas)
      return setGeoCercas(geocercas)
    }

    // const seleccionar = (posicion) => {
    //   return setGeoCercas(prev => {
    //       let actual = prev
    //       actual[posicion].seleccionado = actual[posicion].seleccionado === true ? false : true
    //       return [...[], ...actual]
    //   })
    // }

    const borrarTodo= () => {
      return setGeoCercas(prev => {
        const actual = prev
        const nuevas = actual.filter(geo => geo.seleccionado !== true)
        return [...[], ...nuevas]
      })
    }

    const mostrarGeocercas = () => {

      const iconoTarget = <BiTargetLock />

      if(loadingMaster) return <Spinner />
      if(geocercas.length < 1 ) return <div className='mb-3'><h5 className='mb-0 mt-0' level={4}>Sin geocercas cargadas {iconoTarget}</h5></div>

      return <div>
        <div className='mb-3'><h5 className='mb-0 mt-0' level={4}>{geocercas.length} geocercas cargadas {iconoTarget}</h5></div>
        {mostrarBotonDeEliminarVarios()}
        {resetMapView()}
          {
            geocercas.map((geo,pos) => {

              const coords = procesarCoordenadasParaCentroMapa(geo.geometry.coordinates)
              if(coords.length < 1) return false
              const centro = calcularPuntoCentral(coords)
              
              return <div key={geo._id} className='mb-2'>
                <Card className='p-2'>
                <Row className='align-items-center'>
                  <Col md={9}>
                    <span className='d-block' style={{ fontSize:10, color: "gray" }}>Nombre</span>
                    <input style={{ border: "none", width: "100%" }} mb={3} placeholder="Nombre" name="titulo" defaultValue={ geo.titulo ? geo.titulo : `GEO ${pos+1}` } onChange={(e) => handleChangeGeocerca(e,geo._id)} />
                    <input style={{ border: "none", width: "100%", fontSize: 12 }} mb={3} placeholder="Escribe aquí tus observaciones" name="observaciones" value={ geo.observaciones } onChange={(e) => handleChangeGeocerca(e,geo._id)} />
                  </Col>
                  <Col md={1} className='p-0'>
                  <Dropdown style={dropdownStyles}>
                  <Dropdown.Toggle variant="white" style={{ padding:0, lineHeight: 0 }} >{ geo.deleting ? <Spinner animation='border' size="sm" /> : <BiDotsVerticalRounded size={25} />}</Dropdown.Toggle>
                  <Dropdown.Menu style={{ fontSize: 14, fontWeight: 400, color: '#1B3665' }}>
                      <Dropdown.Item eventKey="2" onClick={() => centrarMapa(centro)}><FiMapPin /> VER EN MAPA</Dropdown.Item>
                      <Dropdown.Item onClick={() => eliminarGeocerca(geo._id)} disabled={geo.deleting === true ? true : false} eventKey="1"><BsFillTrashFill /> {geo.deleting === true ? "ESPERA UN MOMENTO..." : "ELIMINAR"}</Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>
                  </Col>
                  <Col md={1} >

                  <Row className='align-items-center'>
                    <div  style={{ border: "none", width: "100%", fontSize: 35 }} className='pb-2 m-0' onClick={() => seleccionar(pos)}>{ geo.seleccionado === true ? <BiCheckboxChecked /> : <BiCheckbox  /> } </div>

                  </Row> 
                        
                 </Col>
                </Row>
                  </Card>
                  </div>
            })
          }
      </div>
    }

    
    const eliminarGeocercasSeleccionadas = async () => {   
  
     setSeleccionadaEliminar(0)
      
     const ids = geocercas.filter(g => g.seleccionado === true)
     const seQuedan = geocercas.filter(g => g.seleccionado !== true)
     for(const element of ids){
     await eliminarGeocerca(element._id)       
     }

     const actuales = setGeoCercas(prev => {
      const actual = prev
      const nuevas = actual.filter(geo => geo.seleccionado !== true)
      return [...[], ...nuevas]
     })
     setColeccion((prevCollection) => ({
      ...prevCollection,
      features: [...[], ...seQuedan ],
    }))
           return actuales
   }   

    const eliminarGeocerca = async (id) => {
      const instancia_geocercas = JSON.parse( JSON.stringify( geocercas ))
      const i = instancia_geocercas.findIndex(g => g._id === id)
      console.log({i})
      if(i > -1){
        geocercas[i].deleting = true
        setGeoCercas(prev => [...[], ...geocercas])
      } else {
        return false
      }
      return fetch(`${urlapi}/geodata/zonas/empresa/geocerca?id=${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              toast.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              toast.error(res.errorMessage)
          } else if(res._id){
            toast.success("Eliminado exitosamente")
              instancia_geocercas.splice(i,1)

              setColeccion((prevCollection) => ({
                ...prevCollection,
                features: [...[], ...instancia_geocercas ],
              }))
              setGeoCercas([...[], ...instancia_geocercas])
          }
          return false
        })
        .catch((error) => {
          toast.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const guardarGeocercaBD = async (id, tipo, nuevo_arreglo) => {
      if(!zona) return false
      if(!zona._id) return false
      return fetch(`${urlapi}/geodata/zonas/asociar-geo-zona?id=${id}&idzona=${zona._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer: ${sesion.tokenSession}`,
          'country': pais,
          'lang': idioma
        },
      })
        .then((res) => {
          if (res.status === 401) return dispatch(cerrarSesion());
          return res.json();
        })
        .then(async (res) => {
          if (!res) {
              toast.error("Sin datos obtenidos")
          } else if (res.errorMessage) {
              toast.error(res.errorMessage)
          } else if(res._id){
            toast.success("Guardado exitosamente")
            const i = nuevo_arreglo.findIndex(geo => geo.id_geocerca === id)
            if(i > -1 ){
              nuevo_arreglo[i]._id = res._id
              setGeoCercas([...[], ...nuevo_arreglo])
            }
          }
          return false
        })
        .catch((error) => {
          toast.error("Error al consultar la información, intente nuevamente")
          return false
        })
    }

    const onAgregarZona = (data) => {

      const buscar = geocercas.findIndex(geo => geo.id_geocerca === data._id)
      if(buscar > -1) return toast.error("Esta geocerca ya fue agregada")
        const nueva = {
            id_geocerca: data._id,
            titulo: data.titulo,
            type: "Feature",
            properties: data.properties,
            geometry: {
              type: data.region.type,
              coordinates: data.region.coordinates
            }
          }
          const nuevo_arreglo = [...geocercas, ...[nueva]]
          setGeoCercas(nuevo_arreglo)
          coleccion.features = nuevo_arreglo
          setColeccion((prevCollection) => ({
            ...prevCollection,
            features: [...prevCollection.features, nueva],
          }))
          guardarGeocercaBD(data._id, tipo_operacion, nuevo_arreglo)
    }

    const condicion_por_pais = (country) => {
      switch (country) {
        case "BR":
          return { local_name: "region" }
        default:
          return { local_name: "region" }
      }
    }

    return <div style={{ height: '100vh', width: '100%' }} className='mt-2'>
        <Row>
          <Col md={4} style={{ height: "90vh", overflowY: "scroll", overflowX: "hidden"  }}>
          <p className='mb-0 mt-0'>Selecciona una geocerca predefinida</p>
          <SelectorZonaAvanzado condicion={condicion_por_pais(pais)} onSelected={(data) => onAgregarZona(data)} />
          {mostrarGeocercas()}
          </Col>
          <Col md={8}>
          <Map
              ref={mapRef}
              {...viewState}
              onMove={evt => setViewState(evt.viewState)}
              style={{width: "100%", height: height ? height : 500}}
              mapStyle="mapbox://styles/mapbox/light-v9"
              mapboxAccessToken={mapbox_token}
              onLoad={handleLoadMap}
            >
              <GeolocateControl position="top-left" />
              <FullscreenControl position="top-left" />
              <NavigationControl position="top-left" />
              {
                  geometrias.map((geometry,ir) => {

                      const decodedCoords = polyline.decode(geometry.geometry)
                      
                      return <Source
                          id={`route-${ir}`}
                          type='geojson'
                          data={{
                              type: 'Feature',
                              properties: {},
                              geometry: {
                                  type: "LineString",
                                  coordinates: decodedCoords.map(co => [co[1], co[0]])
                              }
                            }}
                      >
                          <Layer
                          id={`route-layer-${ir}`}
                          type="line"
                          layout={{
                              'line-join': 'round',
                              'line-cap': 'round',
                            }}                
                          paint={{
                              'line-color': 'black',
                              'line-width': 4,
                              'line-opacity': 0.75
                          }}
                      />
                      </Source>
                  })
              }
                  <Source
                          key={"ajsfkn"}
                          id={"geocerca._id"}
                          type="geojson"
                          data={coleccion}
                          >
                              
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="line"
                                paint={{
                                    'line-width': 4,
                                    'line-color': '#0080ef'
                                  }}                      
                                />
                              }
                              {
                                redzone === true ? false : <Layer
                                id="geocercas-layer"
                                type="fill"
                                paint={{
                                    'fill-color': 'black',
                                    'fill-opacity': 0.5
                                  }}                      
                                />
                              }
                                  {
                                    redzone === true ? <Layer
                                    id="geocercas-layer"
                                    type="fill"
                                    paint={{
                                        'fill-color': 'red',
                                        'fill-opacity': 0.2
                                      }}                      
                                    /> : false
                                  }
                          </Source>
            </Map>
          </Col>
        </Row>
  </div>
}

export default MapaZonasDetalle