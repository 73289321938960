import Skeleton from "react-loading-skeleton"

const PantallaCargando = () => {

    return <div>
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
        <Skeleton height={30} />
    </div>
}

export default PantallaCargando