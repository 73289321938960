import { useState, useEffect } from "react"
import { Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data, { timezone } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import { nombreDNIPorPais } from "../../lib/helpers/data/internationa";
import CamposObligatoriosLeyenda from "../general/campos_obligatorios";
import { formatDateHoyFromDate } from '../../lib/helpers/dates';
import Select from 'react-select';
import { formatoMoneda } from "../../lib/helpers/main";
import { Calendar, DateRangePicker } from "react-date-range";
import { es } from 'date-fns/locale'
import { DateTime } from "luxon";
import { MdDateRange } from "react-icons/md";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
// import DestinatariosBodegasListado from "./destinatarios_bodegas/list";
// import DestinatariosContactosListado from "./destinatarios_contactos/list";

const AgendarRecoleccionModal = (props) => {
    const {
        id_pedidos,
        id_ruta
    } = props
    const initialDate = {
        startDate: null,
        endDate: null,
        key: 'selection',
    }
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [recoleccionPedido, setRecoleccionPedido] = useState(props.recoleccionPedido ? props.recoleccionPedido : false)
    const [ minDate, setMinDate ] = useState(new Date())
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [removiendoPedido, setRemoviendoPedido] = useState(false)
    const [ recoleccionAgendada, setRecoleccionAgendada ] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [ recoleccionSeleccionada, setRecoleccionSeleccionada ] = useState(false)
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [ courriers, setCourriers ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ configuracionActual, setConfiguracionActual ] = useState(false)
    const isMulti = typeof props.isMulti !== "undefined" ? true : false
    const [ showModal, setShowModal ] = useState(false)

    const handleClose = () => {
        setShowModal(false)
    }

    const seleccionar = (i) => {

        let minimo = null
        const data = configuracionActual.tarifas[i]
        
        if(data.minimo_de_recoleccion){
            if(!isNaN(parseInt(data.minimo_de_recoleccion))) minimo = parseInt(data.minimo_de_recoleccion)
        }

        if(minimo){
            const nueva = DateTime.now().plus({ days: minimo }).setZone(timezone).toJSDate()
            console.log(nueva)
            setMinDate( nueva )
        }

        setRecoleccionSeleccionada(i)
    }

    const dispatch = useDispatch()

    const obtenerRecoleccionAsociada =  async () => {
        setLoadingMaster(true)
        return fetch(`${data.urlapi}/recolecciones/ruta?idruta=${id_ruta}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setRecoleccionAgendada(res)
            }
            return setLoadingMaster(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingMaster(false)
        })
    }

    const obtenerCourriers =  async (condicion) => {
        setLoadingProveedores(true)
        return fetch(`${data.urlapi}/recolecciones/list/courrier`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if(Array.isArray(res) !== false){
                setCourriers(res)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }
    


    const crearNuevo = async () => {

        const requeridos = [
            { value: "codigo_courrier", label: "Código Courrier" },
            { value: "fecha", label: "Fecha" },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: "${faltantes.join(', ')}"`)

        
        const payload = {
            id_ruta,
            pedido: id_pedidos.map(id => ({ id_pedido: id })),
            ...nuevoProveedor,
            tarifa_seleccionada: configuracionActual.tarifas[recoleccionSeleccionada]
        }
        setLoading(true)
        return fetch(`${data.urlapi}/recolecciones`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                } else if(pros.errorMessage){
                    toast.error(pros.errorMessage)
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
                    setRecoleccionAgendada(pros)
                    setLoading(false)
                    return setShowModal(false)
              }
              setTimeout(() => {
                window.location = '/recolecciones'
            }, 2500);
              return setLoading(false)
          })
          .catch(error => {
            toast.error(error.errorMessage)
                return setLoading(false)
          })
    } 

    useEffect(() => {
        obtenerRecoleccionAsociada()
        obtenerCourriers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    
    const handleChangeNuevo2 = (e) => {
        const { value } = e
        nuevoProveedor.codigo_courrier = value
        const i = courriers.findIndex(c => c._id === value)
        if(i > -1 ){
            setConfiguracionActual(courriers[i])
        } else {
            setConfiguracionActual(false)
        }
        setRecoleccionSeleccionada(false)
        return setNuevoProveedor(nuevoProveedor)  
    }

    

    const onChangeRecoleccion = (key, value) => {
        return setNuevoProveedor(prev => {
            let actual = {...prev}
            actual[key] = value
            return {...actual}
        })
    }

    const mostrarAgenda = () => {
        if(recoleccionSeleccionada === false) return false
        return <div>
            <Calendar 
                locale={es}
                onChange={(data) => onChangeRecoleccion('fecha',data)}
                date={nuevoProveedor?.fecha}
            />
        </div>
    }

    const mostrarConfiguracion = () => {

        let tiempos = []
        
        if(configuracionActual) if(typeof configuracionActual === "object") if(configuracionActual.tarifas) if(Array.isArray(configuracionActual.tarifas)) tiempos = configuracionActual.tarifas
        
        if(tiempos.length < 1) return <div className="pt-3 pb-3"><h4>Selecciona un courrier</h4></div>
        
        
        
        return <div className="mt-3">
            <h4>Selecciona una configuración para recolección</h4>
            
            {
                tiempos.map((tiempo,iti) => {
                    let deshabilitado = false
                    if(tiempo.minimo_de_recoleccion){
                        if(!isNaN(parseInt(tiempo.minimo_de_recoleccion))){
                            if(parseInt(tiempo.minimo_de_recoleccion) > id_pedidos.length){
                                deshabilitado = true
                            }
                        }
                    }
                    return <Card key={`tiem-${iti}`} className="p-3">
                    <Row>
                    <Col md={3}>
                    
                    <p className="mb-0" style={{ fontSize: 11 }}>Servicio</p>
                    <h5 className="mb-0">{tiempo.nombre}</h5>
                    </Col>
                    <Col md={3}>
                    <p className="mb-0" style={{ fontSize: 11 }}>Mínimo de pedidos</p>
                    <h3 className="mb-0">{tiempo.minimo_de_recoleccion}</h3>
                    </Col>
                    <Col md={3}>
                    <p className="mb-0" style={{ fontSize: 11 }}>Días para recolección</p>
                    <h3 className="mb-0">{tiempo.dias_para_recoleccion}</h3>
                    </Col>
                    <Col md={3}>
                    <p className="mb-0" style={{ fontSize: 11 }}>Tarifa de recolección</p>
                    <h3 className="mb-0">${formatoMoneda(tiempo.tarifa)}</h3>
                    </Col>
                    <Col xs={12}>
                        <hr className="hr" />
                        <Row>
                            {
                                tiempo.dias_de_recoleccion.map((dia, idia) => {
                                    return <Col key={`dia-${idia}`} >
                                        <p className="mb-0" style={{ fontSize: 11, fontWeight: "bold" }}>{dia.dia}</p>
                                        <p className="mb-0" style={{ fontSize: 11 }}>{dia.hora_desde} · {dia.hora_hasta}</p>
                                    </Col>
                                })
                            }
                        </Row>
                        <hr className="hr" />
                    </Col>
                    <Col xs={2} className="mt-2">
                    <Button disabled={deshabilitado} variant={ recoleccionSeleccionada === iti ? "success" : "secondary"} size="sm"  onClick={() => seleccionar(iti)}>{recoleccionSeleccionada === iti ? "SELECCIONADA" : "SELECCIONAR"}</Button>
                    </Col>
                </Row>
                    </Card> 
                })
            }


            {mostrarAgenda()}
        </div>
    }

    const modalFormulario = () => {
        return  <Modal show={showModal} size="xl" onHide={()=>handleClose()} centered >
            <Modal.Header closeButton>
                <Modal.Title>Agendar recolección</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {formularioNuevo()}        
        </Modal.Body>
        </Modal>
    }

    const formularioNuevo = () => {
        return <div>

            <Row>
                <Col md={12}>
                    <p>Las recolecciones son un servicio que ofrecen empresas de transporte para buscar tus pedidos a tu centro de distribucción y llevarlos a otro centro de distribucción</p>
                    <CamposObligatoriosLeyenda />
                    
                </Col>            
                <Col md={12} className="mb-3">
                    <label className="form-control-label d-block">Courrier *</label>
                      <Select 
                        style={{ marginBottom: 10, }}
                        onChange={handleChangeNuevo2}
                        isLoading={loadingProveedores}
                        loadingMessage={() => "Cargando datos"}
                        isMulti={isMulti}
                        options={courriers.map(c => ({ value: c._id, label: c.razon_social }))}
                        defaultValue={''}
                        placeholder="Elige un courrier."
                        noOptionsMessage={()=>'Sin opciones'}
                        />
                        {mostrarConfiguracion()}
                </Col>
                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : typeof recoleccionSeleccionada !== "undefined" ? <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CONFIRMAR</Button> : false
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recolecciones?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setRecoleccionAgendada(false)
            setCrear(true)
            toast.success(`Eliminado exitosamente`)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    
    const confirmarEliminadoRecoleccionPedido = async (id, ir) => {
        setRemoviendoPedido(true)
        return fetch(`${data.urlapi}/recolecciones/pedido?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return  setRemoviendoPedido(false)
            } else if(res){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            // setProveedor(false)
            // setCrear(true)

            setRecoleccionPedido(prev => {
                let actual = prev
                actual.splice(ir, 1)
                return actual
            })
            toast.success(`Eliminado exitosamente`)
            return  setRemoviendoPedido(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendoPedido(false)
        })
    }

    console.log("recoleccionPedido", recoleccionPedido)

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    
    const solicitarEliminarRecoleccionPedido = (id, ir) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminadoRecoleccionPedido(id, ir)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    let default_option = false
    if(proveedor){
        // const i = proveedor.findIndex(ti => ti._id === default_value._id)
        // if(i > -1) default_option = { value: tiposServicio[i]._id, label: tiposServicio[i].valor }
        default_option = { value: proveedor.codigo_courrier, label: proveedor.codigo_courrier }

    }

    const mostrarAcciones = () => {
        if(loadingMaster) return <h5><Spinner size="sm" animation="border" /> Buscando recolecciones agendadas...</h5>
        if(recoleccionAgendada) return <div>
            <Card className="p-3">
                <h4 className="mb-0"><b>Recolección agendada</b></h4>
                <p className="mb-0">{fechaATextoSimple(recoleccionAgendada.fecha)}</p>
                <Button size="sm" disabled={removiendo} variant="danger" onClick={() => solicitarEliminar(recoleccionAgendada._id)}>{ removiendo ? "ESPERE UN MOMENTO..." : "ANULAR" }</Button>
            </Card>
        </div>

        return <Button size="sm" variant="light" className="w-100" onClick={() => setShowModal(true)}><MdDateRange /> AGENDAR RECOLECCIÓN</Button>
    }

    const View = () => {
       return <div>
        {modalFormulario()}
        {mostrarAcciones()}
       </div>
    }

    return <div>
        {View()}
    </div>
}

export default AgendarRecoleccionModal