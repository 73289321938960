import Map, { Marker } from 'react-map-gl';
import { mapbox_token, urlapi } from '../../lib/backend/data';
import { useRef, useState } from 'react';
import Pin from '../../subComponents/mapas/pin';

const MapaDetallePedidoTrackingPage = (props) => {
    const {
        height,
        markers
    } = props
    const [ marcadores, setMarcadores ] = useState(markers)
    let defaultViewState = { longitude: -70.670112, latitude: -33.447471, zoom: 10 }
    const [ zoomInit, setZoomInit ] = useState(false) 
    const mapRef = useRef()

    if(!marcadores) return false
    if(Array.isArray(marcadores) !== true) return false
    if(marcadores.length < 1) return false

    defaultViewState.longitude = marcadores[0].lng
    defaultViewState.latitude = marcadores[0].lat

    return <Map
    ref={mapRef}
    {...defaultViewState}
    scrollZoom={false}
    dragPan={false}
    // onMove={evt => setViewState(evt.viewState)}
    style={{width: "100%", height: height ? height : 500}}
    pitch={25}
    mapStyle="mapbox://styles/mapbox/light-v9"
    mapboxAccessToken={mapbox_token}
  >
    {
        marcadores.map(marker => {
            const content = (
                <div>
                    <p>{marker.fecha_}</p>
                </div>
            )
            return <Marker
                    key={marker._id}
                    longitude={marker.lng}
                    latitude={marker.lat}
                >
                <div>
                    <Pin />
                </div>
            </Marker>
        })
    }
  </Map>
}

export default MapaDetallePedidoTrackingPage