const procesarInformacionParadas = (paradas) => {
    let stops = []
    if(!paradas) return stops

    if(paradas){
        if( Array.isArray(paradas) !== false ){
            
            paradas.map(step => {
                if(step.coordenada_inicial){
                    if(typeof step.coordenada_inicial === "object"){
                        if(step.coordenada_inicial.location){
                            if(typeof step.coordenada_inicial.location === "object" ){
                                if(step.coordenada_inicial.location.coordinates){
                                    if( Array.isArray(step.coordenada_inicial.location.coordinates) !== false){
                                        if(step.coordenada_inicial.location.coordinates.length > 1){
                                            const validas = step.coordenada_inicial.location.coordinates.filter(e => e)
                                            if(validas.length < 2) return stops
                                            if(step.coordenada_inicial.location.coordinates[0] === 0 || step.coordenada_inicial.location.coordinates[1] === 0) return stops
                                            stops.push({
                                                ...step,
                                                desde: step.coordenada_inicial.createdAt,
                                                hasta: step.coordenada_final.createdAt,
                                                coordinates: step.coordenada_inicial.location.coordinates
                                            })
                                        }
                                    }
                                }
                            }   
                        }
                    }
                }
            })

        }
    }
    return stops
}

module.exports = {
    procesarInformacionParadas
}