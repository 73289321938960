const SelectorTipoAsignacion = (props) => {
    const {
        defaultValue
    } = props
    const handleChangeTipoAsignacion = (e) => {
        if(props.onChange) props.onChange(e)
    }

    return <div>
            <label className='form-control-label d-block'>Tipo asignación</label>
            <select className='form-control mb-3' defaultValue={defaultValue ? defaultValue.toString() : ""} name="tipo_asignacion" onChange={handleChangeTipoAsignacion} >
                <option value="vehiculo">Vehículo de mi flota</option>
                <option value="proveedor">Proveedor de transporte</option>
                {/* <option value="proveedor_externo">Proveedor de transporte Externo</option> */}
            </select>
    </div>
}

export default SelectorTipoAsignacion