import { useState } from "react"
import { Card, Col, FormControl, InputGroup, Row } from "react-bootstrap"

const BoxEdicionProducto = props => {
    const {
        product
    } = props
    const [ producto, setProducto ] = useState(product)

    const handleChangeProducto = (e) => {
        const { name, value } = e.target
        return setProducto(prev => {
            let actual = {...prev}
            actual[name] = value
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }

    if(!producto) return false

    return <Card  className="shadow mb-3">
                            <div className="p-3">
                            <Row>
                            <Col md={12}>
                                <h4 className="mb-0" style={{ fontWeight: 700 }}>Descripción</h4>
                                
                                <Row>
                                    <Col className="mb-3" md={6}>
                                        <label className="form-control-label d-block">Nombre</label>
                                        <input className="form-control" value={producto.description} name="description" onChange={handleChangeProducto} />
                                    </Col>
                                    <Col className="mb-3" md={3}>
                                        <label className="form-control-label d-block">Código</label>
                                        <input className="form-control shadow-sm"   name="codigo_item"     onChange={(e) => handleChangeProducto(e)}  value={producto.codigo_item} />
                                    </Col>
                                    <Col className="mb-3" md={3}>
                                        <label className="form-control-label d-block">Tipo de bulto</label>
                                        <select className="form-control d-block">
                                            <option value="caja">Caja</option>
                                            <option value="sobre">Sobre</option>
                                            <option value="paquete">Paquete</option>
                                            <option value="otro">Otro</option>
                                        </select>
                                    </Col>
                                   
                                </Row>
                            </Col>
                            {/* <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Precio</label>
                                <input className="form-control shadow-sm"   name="price"        onChange={(e) => handleChangeProducto(e)}     type="number" value={producto.price} /></Col> */}
                            <Col md={9}>
                                <h4 className="mb-0" style={{ fontWeight: 700 }}>Tamaño</h4>
                                <Row>
                                <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Ancho</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>CM</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="ancho" onChange={(e) => handleChangeProducto(e)}     type="number" value={producto.ancho} />
                                </InputGroup>
                            </Col>
                            <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Alto</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>CM</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="alto" onChange={(e) => handleChangeProducto(e)}     type="number" value={producto.alto} />
                                </InputGroup>
                            </Col>
                            <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Largo</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>CM</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="largo" onChange={(e) => handleChangeProducto(e)}     type="number" value={producto.largo} />
                                </InputGroup>
                            </Col>
                                </Row>
                            </Col>
                            <Col md={3}>
                                <h4 className="mb-0" style={{ fontWeight: 700 }}>Peso</h4>
                                <label className="form-control-label d-block">Peso</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>KG</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="peso" onChange={(e) => handleChangeProducto(e)}   type="number" value={producto.peso} />
                                </InputGroup>
                            </Col>
                            
                        </Row>
                            {/* {pedido.productos.length > 1 ? <Button size="sm" variant="link" className="text-danger" onClick={() => eliminarProducto(i)} >ELIMINAR</Button> : false } */}
                            </div>
                        </Card>
}

export default BoxEdicionProducto