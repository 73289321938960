import { useEffect, useState } from 'react'
import { Row, Col, Button, Card, Spinner, Modal, Pagination, Form, Table, ButtonGroup, OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../lib/helpers/dates';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { AiFillPlusCircle } from "react-icons/ai";
import { IoIosCloseCircle } from "react-icons/io";
import { estilo_last_mile } from '../../lib/global/styles'
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';

const EtiquetasRelaciones = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const id_target = props.id_target ?? ""
    const tipo_destino = props.tipo_destino ?? ""
    const tipo_origen = props.tipo_origen ?? ""
    const session = useSelector(state => state.miusuario)
    const [ pedidos, setPedidos ] = useState([])
    const [ loadingAgregar, setLoadingAgregar ] = useState(false)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [titulo, setTitulo] = useState("")
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const trash = props.trash ? props.trash : false
    const [ total, setTotal ] = useState(0)
    const token = session.tokenSession
    const [loadingModal, setLoadingModal] = useState(false)   


    const obtenerProveedores = async (query)=>{
        setLoadingModal(true)
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/etiquetas/list/relaciones`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                tipo_destino,
                id_destino: id_target
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setPedidos(res.datos)
                setTotal(res.total)
            }
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
    }

    useEffect(() => {
        obtenerProveedores()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitPalabra = async (e) => {
       
        e.preventDefault()
        if(!titulo){
            await obtenerProveedores()
            return 
        }
       await crearEtiqueta()
       await obtenerProveedores()
         return 
    }

    const anadirRelacion = async (p) => {
        if(loadingAgregar) return
       await anadirRelacionEjecutar(p)
        return
    }

    const anadirRelacionEjecutar = async (p) => {
        setLoadingAgregar(true)
        // setLoadingModal(true)
      return fetch(`${data.urlapi}/etiquetas/relaciones`, {
          method: 'POST',
          body: JSON.stringify({id_destino: id_target, tipo_destino, tipo_origen, id_origen: p._id}),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
        })
        .then(pros => pros.json())
        .then(async pros => {
            if(!pros){   
             } else if(pros.errorMessage){                   
                   toast.error(pros.errorMessage)
            } else if(pros._id){
                  if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                  pedidos.forEach(etiqueta => {
                    if (etiqueta._id === p._id) {
                        etiqueta.id_relacion = pros._id
                    }
                    setPedidos(pedidos)
                })
            }
            return setLoadingAgregar(false)
        })
        .catch(async error => {
              toast.error('No se pudo actualizar el registro')
              return setLoadingAgregar(false)
        })

    }

    
    const crearEtiqueta = async () => {
        
      setLoadingModal(true)
      return fetch(`${data.urlapi}/etiquetas/creacion/modulo`, {
          method: 'POST',
          body: JSON.stringify({name: titulo, id_destino: id_target, tipo_destino, tipo_origen}),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
        })
        .then(pros => pros.json())
        .then(async pros => {
            if(!pros){                   
                  return toast.error('Hubo un error de servidor')
            } else if(pros.errorMessage){                   
                   toast.error(pros.errorMessage)
                   return 
            } else if(pros._id){
                  if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                  return setTitulo("")
            }
            return
        })
        .catch(async error => {
              toast.error('No se pudo actualizar el registro')
              return 
        })
  }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setTitulo(value)
    }

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const eliminar = async (p) => {
        if(loadingAgregar) return
        await eliminarRelacion(p)
        return
    }
    
    const eliminarRelacion = async (p) => {  
        setLoadingAgregar(true)
        return fetch(`${data.urlapi}/etiquetas/relaciones`,{
            method:'DELETE',
            body: JSON.stringify({id_origen: p._id, tipo_destino,  id_destino: id_target}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
               pedidos.forEach(etiqueta => {
                if (etiqueta.id_relacion === res._id) {
                    delete etiqueta.id_relacion
                }
                setPedidos(pedidos)
                });
            }
            return setLoadingAgregar(false)
        })
        .catch(error => {
            toast.error("No se pudo realizar esta accion")
            return setLoadingAgregar(false)
        })
    }


    const listadoEtiquetas = () => {
    if(loadingModal) return cargandoScreen()
    return <div className='ml-0' style={{ opacity: loadingAgregar ? 0.4 : 1 }}>
                 {
                    pedidos.map((p) => {                    
                    return  <Badge className='hover mr-2 mb-2' style={{ background: "#ebebeb", padding: '4px 13px',   }} >
                        <h6 style={{ fontSize: 13, textTransform: "uppercase" }} className="m-0"><b className='mr-1'>{p.name}</b> {p.id_relacion? <FaCheckCircle style={{ fontSize: 15, marginBottom: 2, color: "green"}} onClick={() => eliminar(p)}/> :
                        <FaRegCircle style={{fontSize: 15, marginBottom: 2, color:'black'}} onClick={() => anadirRelacion(p)}/>}
                        </h6>
                        </Badge>
                        })
                }
            </div>
    }


    const mostrarEtiquetas = () => {
        return <div className='mt-3 ml-0'>  
                <Col md={12}>
                <h4 style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}>Añadir Etiquetas</h4>
                        <form onSubmit={handleSubmitPalabra}>
                            <label htmlFor="">Escribe un título y presiona enter para añadir mas etiquetas.</label>
                        <input className='mb-3 form-control' placeholder='Título de la etiqueta' onChange={handleChangePalabra} value={titulo} />
                        </form>
                    </Col> 
                    <Col md={12}>                                   
                    {listadoEtiquetas()}
                    </Col> 
        </div>
    }


    return <div>
        {mostrarEtiquetas()}
        </div>

}

export default EtiquetasRelaciones