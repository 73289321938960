import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/session";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import SelectorZonas from "../../subComponents/geodata/selector_zonas";
import { AiOutlinePlus } from "react-icons/ai";
import { BiTargetLock } from "react-icons/bi";


const SelectorZonaAvanzado = (props) => {
    const {
        defaultValue,
        title,
        condicion,
        error
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ value, setValue ] = useState(false)
    const [ geocerca, setGeocerca ] = useState(false)
    const [ geocerca2, setGeocerca2 ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const [ loadingGeocerca, setLoadingGeocerca ] = useState(false)
    const [ valorSeleccionado, setValorSeleccionado ] = useState(false)
    const country = useSelector(state => state.pais)
    const [ pais, setPais ] = useState(country)
    const idioma = useSelector(state => state.idioma)
    const [ condicion_selector, setCondicionSelector ] = useState({})
    const session = useSelector(state => state.miusuario)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [geocercaSeleccionada, setGeocercaSeleccionada] = useState(false);

    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/geodata/zonas/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
              setIsModalOpen(false)
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const obtenerDatosPorId = async () => {
        setLoadingDetalle(true)
        return fetch(`${urlapi}/geodata/zonas?id=${valorSeleccionado._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(res._id){
                if(props.onSelected) props.onSelected(res)
                setValorSeleccionado(false)
                handleCancel()
                setValue(false)
            }
            return setLoadingDetalle(false);
          })
          .catch((error) => {
            console.log(error)
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingDetalle(false);
          })
      }

      const onChangeValue = (e) => {
        const i = opcionesComplete.findIndex(m => m._id === e)
        if(props.onChange) props.onChange(opcionesComplete[i])
        setValorSeleccionado(opcionesComplete[i])
        setValue(e)
      }
      
      const onChangeValueNivel2 = (e) => {
        console.log(e)
        if(props.onSelected) props.onSelected(e)
        handleCancel()
        setValue(false)
        setValorSeleccionado(false)
      }

      useEffect(() => {
        obtenerDatos()
      }, [])

      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const tercerSelector = () => {
        switch (pais) {
          case "CL":
            return false
            case "BR":
              if(!geocerca) return false
              let nueva_condicion_br = { local_name: "municipio" }
              if(geocerca.properties){
                  if(typeof geocerca.properties === "object"){
                    console.log(geocerca.properties)
                      if(geocerca.sigla) nueva_condicion_br['properties.microrregiao.mesorregiao.UF.sigla'] = geocerca.sigla
                  }
              }
              return <SelectorZonas title="Municipio" condicion={nueva_condicion_br} onChange={(data) => setGeocerca2(data)} title_button="AGREGAR ESTA ZONA" onSelected={(data) => onChangeValueNivel2(data)} />
          default:
            break;
        }
      }
      const segundoSelector = () => {
        switch (pais) {
          case "CL":
            if(!valorSeleccionado) return false
            let nueva_condicion = { local_name: "comuna" }
            if(valorSeleccionado.properties){
                if(typeof valorSeleccionado.properties === "object"){
                    if(valorSeleccionado.properties.codregion) nueva_condicion['properties.codregion'] = valorSeleccionado.properties.codregion
                }
            }
            return <SelectorZonas title="Comuna" condicion={nueva_condicion} onChange={(data) => setGeocerca(data)} title_button="AGREGAR ESTA GEO ZONA" onSelected={(data) => onChangeValueNivel2(data)} />
            case "BR":
              if(!valorSeleccionado) return false
              let nueva_condicion_br = { local_name: "estado" }
              console.log(valorSeleccionado.properties.sigla)
              if(valorSeleccionado.properties){
                  if(typeof valorSeleccionado.properties === "object"){
                      if(valorSeleccionado.properties.sigla) nueva_condicion_br['properties.regiao.sigla'] = valorSeleccionado.properties.sigla
                  }
              }
              return <SelectorZonas title="Estado" condicion={nueva_condicion_br} onChange={(data) => setGeocerca(data)} title_button="AGREGAR ESTA GEO ZONA" onSelected={(data) => onChangeValueNivel2(data)} />
          default:
            break;
        }
      }

      const fetchZone = async () => {
        if(!geocercaSeleccionada) return false
        setLoadingGeocerca(true)
        const url = `${urlapi}/geodata/zonas/geocerca?id=${geocercaSeleccionada._id}`
        return fetch(url, {
          method: "GET",
          headers: {
              'Content-type': "application/json",
              'Authorization': `Bearer: ${session.tokenSession}`,
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.json()
      })
      .then(async res => {
          if(!res){
              toast.error("Sin datos obtenidos")
          } else if(res.errorMessage){
              toast.error(res.errorMessage)
          } else if(res._id){
              if( props.onSelected ) props.onSelected(res)
          }
          setIsModalOpen(false)
          return setLoadingGeocerca(false)
      })
      .catch(error => {
          toast.error("No se pudo efectuar la operación")
          setIsModalOpen(false)
          return setLoadingGeocerca(false)
      })
      }
      
      const accionesGeocercas = () => {
        if(!geocercaSeleccionada) return false
  
        return <div>
          <label className='form-control-label d-block'>Click para agregar</label>
          <Button disabled={loadingGeocerca} onClick={() => fetchZone()} >{ loadingGeocerca ? "CARGANDO DATOS..." : "AGREGAR ZONA A LA LISTA" }</Button>
        </div>
      }

      const handleChangePais = (e) => {
        setCondicionSelector({ pais_code: e.target.value })
        return setPais(e.target.value)
      }

      const mostrarContenido = () => {
        return <div>
          <h3 level={2} className="mt-0 mb-0"> Selecciona una zona <BiTargetLock /></h3>
            <Row>
                <Col md={12}>
                {/* <Select
                    value={value}
                    style={ error === true ? {
                        width: "100%",
                        border: "1.5px solid red",
                        borderRadius: 8
                    } : { width: "100%" }}
                    showSearch={true}
                    filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                    loading={loading}
                    options={opciones}
                    onChange={onChangeValue}
                    /> 
                    {segundoSelector()}
                    {tercerSelector()}
                    */
                    }
                    <label className="form-control-label d-block">País</label>
                    <select className="form-control" defaultValue={pais} onChange={handleChangePais} >
                      <option value="CL">Chile</option>
                      <option value="PY">Paraguay</option>
                      <option value="BR">Brasil</option>
                      <option value="MX">México</option>
                      <option value="CO">Colombia</option>
                      <option value="PA">Panamá</option>
                      <option value="GT">Guatemala</option>
                      <option value="PE">Perú</option>
                    </select>
                    <SelectorZonas title="Zonas del país" condicion_default={condicion_selector} full onChangeValue={data => setGeocercaSeleccionada(data)} />
                </Col>
                <Col md={12}>
                   {accionesGeocercas()}
                </Col>
            </Row>
            
        </div>
      }

      const handleClose = () => {
        return setIsModalOpen(false)
      }

      return <div className="mb-3">
        <Button size="sm" className="w-100" onClick={() => setIsModalOpen(true)}><AiOutlinePlus /> AGREGAR GEO ZONA</Button>
        <Modal  className="mb-0 p-3" show={isModalOpen}size="lg" onHide={()=>handleClose()} centered >
        <Modal.Body>
          {mostrarContenido()}
        </Modal.Body>
      </Modal>

        
      </div>
}

export default SelectorZonaAvanzado