import { useEffect, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import Select from "react-select"
import { toast } from 'react-toastify';
import data, { maps_key, tipos_direccion } from '../../../lib/backend/data'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../../redux/actions/session";
import SelectorDestinatariosBodegas from "../../destinatarios/destinatarios_bodegas/selector";
import ReactGoogleAutocomplete from "react-google-autocomplete";

const SelectorBodegaDestino = (props) => {
    const {
        isMulti
    } = props
    const [ tipoSeleccion, setTipoSeleccion ] = useState('mis-bodegas')
    const [ openModal, setOpenModal ] = useState(false)
    const [ proveedores, setProveedores ] = useState([])
    const [ bodegasDatos, setBodegasDatos ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ seleccionProveedores, setSeleccionProveedores ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const seleccionMultiple = typeof isMulti !== "undefined" ? isMulti : false

    const obtenerProveedores = async (condicion)=>{
        setLoadingProveedores(true)
        return fetch(`${data.urlapi}/bodega/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if(Array.isArray(res) !== false){
                const opciones = JSON.parse( JSON.stringify(res) )
                let data = res.map(p => ({ value: p._id, label: p.titulo }))
                setProveedores([...[{ value: "", label: "Seleccione"}], ...data])
                setBodegasDatos(opciones)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    useEffect(()=>{
        obtenerProveedores({})
    },[])

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e) => {
        if(props.onChange) props.onChange(e)
        setSeleccionProveedores(e)
    }

    const handleChangeProveedor = (e) => {
        if(!e) return false
        if(seleccionMultiple === true){
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            if(props.onChange) props.onChange(opciones)
            return setSeleccionProveedores(opciones)
        } else {
            const i = bodegasDatos.findIndex(bodega => bodega._id === e.value)
            let resultado = false
            if(i > -1){
                resultado = bodegasDatos[i]
            }
            if(props.onChange) props.onChange(resultado)
            return setSeleccionProveedores(e)
        }
    }

    const actualizar = async () => {
        if(seleccionProveedores.length > 0){
            if(props.onActualizar) props.onActualizar(seleccionProveedores)
            setOpenModal(false)
        }
        return 
    }

    const handleChangeVista = (e) => {
        return setTipoSeleccion(e.target.value)
    }

    const selectorTipoVista = () => {
        return <div>
            <label className='d-block form-control-label' style={{fontWeight:700}}>Destino de la carga</label>
            <select className="form-control shadow-sm" value={tipoSeleccion} onChange={handleChangeVista}>
                <option value="">Selecciona</option>
                <option value="direccion">Ingresar dirección</option>
                <option value="mis-bodegas">Mis bodegas</option>
                <option value="bodegas-clientes">Bodegas de mis clientes</option>
            </select>
        </div>
    }

    const mostrarSelectorSegunTipoSeleccion = () => {
        if(tipoSeleccion === "direccion"){

            return <div>
                <Row>
                    <Col xs={4}>{selectorTipoVista()}</Col>
                    <Col xs={8}>
                    <label className='d-block form-control-label' style={{fontWeight:700}}>Dirección</label>
                    <ReactGoogleAutocomplete
                    className="form-control"
                    options={{
                        types: tipos_direccion,
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          let level_3 = ''

                          const buscar_level_3 = place.address_components.findIndex(comp => {
                            return comp.types.includes('administrative_area_level_3')
                        })
                        if(buscar_level_3 > -1) level_3 = place.address_components[buscar_level_3].long_name

                          const geo_data = {
                              direccion: place.formatted_address,
                                geodata: place,
                              location: {
                                type: "Point",
                                coordinates: [
                                    lng, lat
                                ]
                              }
                          }
                                                    
                          if(props.onChange) props.onChange(geo_data)
                    }}
                    />
                    </Col>
                </Row>
                
            </div>

        } else if(tipoSeleccion === "mis-bodegas"){

            return <div>
                <Row>
                    <Col xs={4}>{selectorTipoVista()}</Col>
                    <Col xs={8}>
                    <label className='d-block form-control-label' style={{fontWeight:700}}>Seleccione la bodega de destino</label>
                <Select
                                    isLoading={loadingProveedores} 
                                    loadingMessage={()=>'Cargando información'}
                                    placeholder="Selecciona una opción"
                                    noOptionsMessage={()=>'Sin opciones'}
                                    onChange={handleChangeProveedor}
                                    isMulti={seleccionMultiple}
                                    options={proveedores} 
                                    className="shadow-sm"
                                />
                    </Col>
                </Row>
                
                
            </div>
        } else {
            return <div>
                <Row>
                    <Col xs={4}>{selectorTipoVista()}</Col>
                    <Col xs={8}>
                        <SelectorDestinatariosBodegas full_payload={true} onChange={handleChange} titulo="Seleccione la bodega de destino" />
                    </Col>
                </Row>
            </div>
        }
    }

    return <div className="mb-3">
        {mostrarSelectorSegunTipoSeleccion()}
    </div>
}

export default SelectorBodegaDestino