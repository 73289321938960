import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { host } from '../../lib/global/data';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import ProveedoresListadoProveedores from '../proveedores_transporte/enlace/listado_proveedores';
import { cerrarSesion } from '../../redux/actions/session';
import VerifyModelBusiness from '../model_business/verify_model_business';



const CrearRuta = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const [ conductor, setConductor ] = useState('')
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ descripcion, setDescripcion ] = useState('')
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ codigoProveedor, setCodigoProveedor ] = useState('')
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [statusBoton, setStatusBoton ] = useState(false)


    useEffect(() => {
        obtenerProveedores()
    },[])

    const obtenerProveedores = async () => {
        setLoadingConductores(true)
        return fetch(`${data.urlapi}/conductores/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            } else if(Array.isArray(res) !== false){
                setConductores(res.map(c => ({ value: c._id, label: `${c.patente.toUpperCase()} · ${c.modelo ? c.modelo.toUpperCase() : ""}` })))
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingConductores(false)
        })
    }

    const crearNuevaRuta = async () => {
        if(tipoAsignacion === 'vehiculo'){
            // if(!conductor) return toast.error('Vehículo es requerido')
        } else if(tipoAsignacion === 'proveedor'){
            if(!codigoProveedor) return toast.error('Selecciona un proveedor')
        }


        const nuevo = {
            id_conductor: conductor,
            tipo_asignacion: tipoAsignacion,
            fecha_despacho: selectionRange.startDate,
            fecha_asignacion: new Date(),
            entrega_desde: selectionRange.startDate,
            entrega_hasta: selectionRange.endDate,
            codigo_proveedor: codigoProveedor,
            descripcion
        }

        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/rutas`,{
            method:'POST',
            body: JSON.stringify(nuevo),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res._id){
                toast.success('Creada exitosamente')
                return setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    const handleChangeDescripcion = (e) => {
        const { value } = e.target
        return setDescripcion(value)
    }
    
    const handleChangeCodigoProveedor = (e) => {
        const { value } = e.target
        return setCodigoProveedor(value)
    }

    const handleChangeTipoAsignacion = (e) => {
        const { value } = e.target
        setCodigoProveedor("")
        return setTipoAsignacion(value)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const handleChangeSelectConductor = (e) => {
        return setConductor(e.value)
    }

    const onClickEmpresa = (data) => {
        setCodigoProveedor(data.codigo)
    }
    return <div>
    <Row>
        <Col md={4}>
            <h5>Rango de entrega</h5>
            <p>Este es el rango de fechas que tendrán las órdenes para entregarse</p>
            <DateRange
            locale={es}
            editableDateInputs={true}
            onChange={item => cambiarRangoFecha(item)}
            moveRangeOnFirstSelection={false}
            ranges={[selectionRange]}
            direction="vertical"
            scroll={{ enabled: true }}
            months={1}
            />
        </Col>
        <Col md={8}>
            <h5>Datos generales</h5>

            <label className='form-control-label d-block'>Descripción</label>
            <input className='form-control mb-3' name="descripcion" placeholder='Describe la ruta si lo deseas' onChange={handleChangeDescripcion} />

            {/* <label className='form-control-label d-block'>Tipo asignación</label>
            <select className='form-control mb-3' name="tipo_asignacion" onChange={handleChangeTipoAsignacion} >
                <option value="vehiculo">Vehículo de mi flota</option>
                <option value="proveedor">Proveedor de transporte</option>
            </select> */}

            {
                tipoAsignacion === 'vehiculo' ? <div>
                    <label className='form-control-label d-block'>Selecciona el vehículo asignado para esta ruta</label>
                        <Select 
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectConductor}
                        isLoading={loadingConductores}
                        options={conductores}
                        defaultValue={''}
                        placeholder="Seleccionar vehículo..."
                        noOptionsMessage={()=>'Sin opciones'}
                        className="mb-3"
                        />
                </div> : <div>
                    <label className='form-control-label d-block'>Selecciona un proveedor</label>
                    <ProveedoresListadoProveedores hightLightCodigo={codigoProveedor} onClick={(data) => onClickEmpresa(data)} displayAll={true} condicion_default={{ propietario_cliente: session.data.propietario }} />
                </div>
            }
            
            {
                loadingCreacion === true ? <Spinner animation='border' /> : <Button size="sm" variant="success" onClick={()=>crearNuevaRuta()} disabled={statusBoton}>CREAR RUTA</Button>
            }
                     
            <VerifyModelBusiness ruta={true} token={token} handleChangeBoton={(status) => setStatusBoton(status)}/>
        </Col>
    </Row>
</div>
}

export default CrearRuta