import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip } from 'react-bootstrap'
import 'react-confirm-alert/src/react-confirm-alert.css'
import DetailFull from './detalle-full';
import { useDispatch, useSelector } from 'react-redux';

const HorarioLogisticaListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
   
    return <div>
        <Row>
            <Col md={12}>
                <Card className='p-3'>
                 <DetailFull  
                        token={token} 
                        />
                </Card>
            </Col>
        </Row>
    </div>

}

export default HorarioLogisticaListado