const eventos = [
    { value: "nuevo-pedido", label: "Nuevo pedido" },
    { value: "pedido-actualizado", label: "Pedido actualizado" },
    { value: "nueva-ruta", label: "Nueva ruta" },
    { value: "ruta-actualizada", label: "Ruta actualizada" },
    { value: "nuevo-estado", label: "Nuevo estado de pedido" },
    { value: "nuevo-ticket", label: "Nuevo ticket de soporte" },
    { value: "nuevo-mensaje-ticket", label: "Nuevo mensaje de un ticket de soporte" },
]

const eventoToString = (valor) => {
    const i = eventos.findIndex(e => e.value === valor)
    if(i > -1) return eventos[i].label
    return valor
}

module.exports = {
    eventos,
    eventoToString
}