import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Table, ButtonGroup } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import HelperModal from '../general/helperModal';
import { data_module } from './data';
import { fechaATextoSimple } from '../../lib/helpers/helpers';
import { formatoMoneda } from '../../lib/helpers/main';
import SelectorRangoFechas from '../general/selector_rango_fechas';
import { DateTime } from 'luxon';
import { obtenerRangos } from '../../lib/helpers/opcionesfecha';
import { url_images } from '../../lib/global/data';
import Balance from './balance';

const PagosListado = (props) => {
    const initialDate = {
        startDate: DateTime.now().startOf("day").minus({ weeks: 4 }).toJSDate(),
        endDate: DateTime.now().toJSDate(),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ montoTotal, setMontoTotal ] = useState(0)
    const [ montoTotalPendiente, setMontoTotalPendiente ] = useState(0)
    const [ openModal, setOpenModal ] = useState(false)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ crearNuevo, setCrearNuevo ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')

    const stringBystatusTransBank = (codigo) => {
        const mensajes = {
            '0': "Transacción aprobada",
            '-1': "Tarjeta inválida",
            '-2': "Error de conexión",
            '-3': "Excede monto máximo",
            '-4': "Fecha de expiración inválida",
            '-5': "Problema en autenticación",
            '-6': "Rechazo general",
            '-7': "Tarjeta bloqueada",
            '-8': "Tarjeta vencida",
            '-9': "Transacción no soportada",
            '-10': "Problema en la transacción",
            '-11': "Excede límite de reintentos de rechazos"
          };
        
          return mensajes[codigo] || "Transacción anulada";        
    }
    
    const stringAuxiliarBystatusMercadoPago = (status) => {
        const mensajes = {
            "in_process": "Te avisaremos en breves minutos cuando haya sido confirmado.",
          };
        
          return mensajes[status] || "Transacción anulada";        
    }

    const stringBystatusMercadoPago = (status) => {
        const mensajes = {
            "in_process": "El pago está siendo revisado.",
          };
        
          return mensajes[status] || "Transacción anulada";        
    }
    
    const analizarResultado = (gateway, status) => {
        switch (gateway) {
            case "transbank":
                const num_code = parseInt(status)
                return stringBystatusTransBank(status)
            case "mercadopago":
                return stringBystatusMercadoPago(status)
            default:
                return "Pendiente de validación"
        }
    }
    

    const obtenerProveedores = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/${data_module.modulo}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setProveedores(res.datos)
                setTotal(res.total)
                if(!isNaN(res.monto_total)) setMontoTotal(res.monto_total)
                if(!isNaN(res.monto_total_pendiente)) setMontoTotalPendiente(res.monto_total_pendiente)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const onClickProveedor = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        setCrearNuevo(false)
        return fetch(`${data.urlapi}/${data_module.modulo}/detail?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setVehiculoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        const cond = {}
  
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        cond.createdAt = { $gte: desde, $lte: hasta }
        obtenerProveedores(1, cond)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()

        if(registros.length < 1) return  <div className='p-3'>
            <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
            <h3 className='mb-0 text-primary' style={{ fontWeight: "bold" }}>No hay pagos registrados en el período seleccionado</h3>
            <img className='d-block' src={`${url_images}/animations/Payment successful.gif`} style={{ width: 200, maxWidth: "80%", margin: "0 auto" }} />
            <p>Puedes navegar entre los pagos que has realizado y el estado de los mismos</p>
            </Col>
        </Row>
        </div>

        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            <Table bordered hover>
                <thead>
                    <tr>
                        <td>Fecha</td>
                        <td>Pasarela</td>
                        <td>Descripción</td>
                        <td>Valor</td>
                        <td>Estado</td>
                    </tr>
                </thead>
            {
                        registros.map(orden => {
                            return <tr key={orden._id} className="mb-3">
                                <td>{fechaATextoSimple(orden.createdAt)}</td>
                                <td>{orden.gateway}</td>
                                <td>{orden.description}</td>
                                <td>{formatoMoneda(orden.ammount)}</td>
                                <td>{analizarResultado(orden.gateway, orden.status)}</td>
                            </tr>
                        })
                    }
            </Table>
        </div>
    }

    const handleSubmitPalabra = (e) => {
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { $text: { $search: parametroBusqueda }}
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProveedores(1, condicion)
    }
    
    const actualizarResultados = () => {
        const cond = {...filtroBusqueda}
        
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        cond.createdAt = { $gte: desde, $lte: hasta }
        setCondicionBusqueda(cond)
        obtenerProveedores(1, cond)
      }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        setFiltroBusqueda({ ...{}, ...{}})
        obtenerProveedores(1, {})
    }

    const onProveedorGuardado = (data) => {
        console.log(data)
        const i = proveedores.findIndex(a => a._id === data._id)
        if(i < 0) return false
        proveedores[i] = data
        return setProveedores(prev => [...[], ...proveedores])
    }

    const crearProveedor = () => {
        setLoadingDetalle(true)
            setIdSeleccionado(false)
            setVehiculoSeleccionado(false)
            setTimeout(() => {
                setLoadingDetalle(false)
            }, 100);
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const onProveedorNuevo = (data) => {
        proveedores.unshift(data)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const onFieldDeleted = (id) => {
        const i = proveedores.findIndex(c => c._id === id)
        if(i < 0) return false
        proveedores.splice(i,1)
        setVehiculoSeleccionado(false)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const cambiarRangoFecha = (item) => {
        console.log(item.selection)
        return setSelectionRange(item.selection)
    }

    const agregarFiltro = (key, value) => {
        return setFiltroBusqueda(prev => {
            let actual = {...prev}
            if(!value){
                delete actual[key]
            } else {
                actual[key] = value
            }
            return actual
        })
    }
 
    return <div>
        {modalFiltro()}
        <Card className='p-3 mb-3'>

        <Balance />


        <Row>
            <Col md={12}>
            <h4 style={{ fontSize: 23, fontWeight: "bold" }}>Pagos</h4>
                <Row>
                    <Col md={3}>
                        <form onSubmit={handleSubmitPalabra}>
                        <label className='form-control-label d-block'>Búsqueda por palabras clave</label>
                        <input className='mb-3 form-control' placeholder='BUSCAR' onChange={handleChangePalabra} />
                        </form>
                    </Col>
                    <Col md={3}>
                        <label className='form-control-label d-block'>Pasarela de pago</label>
                        <select className='form-control' value={filtroBusqueda.gateway ? filtroBusqueda.gateway : ""} onClick={(e) => agregarFiltro('gateway', e.target.value)}>
                            <option value="">Todos</option>
                            <option value="transbank">Transbank</option>
                            <option value="mercadopago">Mercado Pago</option>
                        </select>
                    </Col>
                    <Col md={6}>
                        <SelectorRangoFechas default_range_dates={selectionRange} onChange={item => cambiarRangoFecha(item)} />
                    </Col>
                    <Col md={12} className='mb-3'>
                        <ButtonGroup>
                        <Button size="sm" variant="success" onClick={()=>actualizarResultados()}>ACTUALIZAR</Button>
                        <Button size="sm" variant="light" onClick={()=>reiniciar()}>BORRAR FILTROS</Button>
                        </ButtonGroup>
                    </Col>
                    <Col md={6} className='mb-3'>
                        <Card className='text-center p-3'>
                            <h5 className='mb-0'>{formatoMoneda(montoTotal)}</h5>
                            <span>Total Transacciones confirmadas</span>
                        </Card>
                    </Col>
                    <Col md={6} className='mb-3'>
                        <Card className='text-center p-3'>
                            <h5 className='mb-0'>{formatoMoneda(montoTotalPendiente)}</h5>
                            <span>Total Transacciones pendientes</span>
                        </Card>
                    </Col>
                </Row>
                {tabla_registros(proveedores)}
            </Col>
            {/* <Col md={9}>
                <Card className='px-4 py-3'>
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull 
                        onFieldDeleted={(id) => onFieldDeleted(id)}
                        crear={crearNuevo} 
                        onProveedorNuevo={(data) => onProveedorNuevo(data)} 
                        token={token} 
                        proveedor={vehiculoSeleccionado} 
                        onProveedorGuardado={(data) => onProveedorGuardado(data)} 
                        />
                    }
                </Card>
            </Col> */}
        </Row>
        </Card>
    </div>

}

export default PagosListado