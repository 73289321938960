import React, { Component, useState} from 'react'
import { useSelector } from 'react-redux'
import { Nav, Tab, Tabs } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ListadoWhatsapp from '../../subComponents/listas-whatsapp/listadoWhatsapp/list'
import { useQuery } from '../../lib/helpers/router'
import WhatsappPlantillasListado from '../../subComponents/ordenes/whatsapp-plantillas/list'
import WhatsappConfiguracion from '../../subComponents/personalizar/whatsapp'
import ListadoWhatsappMasivo from '../../subComponents/whatsapp-masivo/listadoWhatsapp/list'
import WhatsappBotConfiguracion from '../../subComponents/personalizar/bot_configuracion'

const Whatsapp = () => {
  const user = useSelector(state => state.miusuario)
  const [selectedTab, setSelectedTab] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  let default_key = 'configuracion-whatsapp'
  if(query.get("tab")) default_key = query.get("tab")
  

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
          pathname: location.pathname,
          search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }


        return(
              <div className="fluid">
          <h1 className="h2 text-primary" style={{fontWeight:700}}><i className="fa-solid fa-gear"></i> Whatsapp</h1>
          <Tab.Container id="left-tabs-example" defaultActiveKey={default_key}>
        <Row>
          <Col sm={3}>
          <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
              <Nav.Item><Nav.Link eventKey="configuracion-whatsapp">Configuración de whatsapp</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="bot-whatsapp">Bot whatsapp</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="listas-whatsapp">Listas de whatsapp</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="mensajes-whatsapp">Mensajes de whatsapp</Nav.Link></Nav.Item>
              <Nav.Item><Nav.Link eventKey="envios-whatsapp">Envíos masivos de whatsapp</Nav.Link></Nav.Item>
            </Nav> 
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="mensajes-whatsapp" >
                <WhatsappPlantillasListado description={"Aquí podrás definir el mensaje de whatsapp que deseas automatizar, estos deberán ser aprobados por el equipo de whatsapp para que puedan ser enviados de forma masiva"} title="Plantillas de whatsapp" token={user.tokenSession} condicion_default={{ tipo: "logistica", sub_tipo: "capacidad-especial" }} />
              </Tab.Pane>
              <Tab.Pane eventKey="listas-whatsapp" >
                <ListadoWhatsapp />
              </Tab.Pane>
              <Tab.Pane eventKey="bot-whatsapp" >
                <WhatsappBotConfiguracion />
              </Tab.Pane>
              <Tab.Pane eventKey="configuracion-whatsapp" >
                <WhatsappConfiguracion />
              </Tab.Pane>
              <Tab.Pane eventKey="envios-whatsapp" >
                <ListadoWhatsappMasivo />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          </Row>
        </Tab.Container>
      </div>
    )
    }

export default Whatsapp