import { useEffect, useState } from "react"
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify"
import TiposServicioSelector from "../../../ordenes/tipos_servicio/selector"
import SelectorServicios from "../../../servicios/selector"
import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../../lib/backend/data"
import { cerrarSesion } from "../../../../redux/actions/session"
import SelectorZonas from "../../../geodata/selector_zonas"
import * as XLSX from 'xlsx'
import { extraerNumeros, formatoMoneda, sortDynamic } from "../../../../lib/helpers/main"
import VisualizarRangos from "../rangos/visualizar_rangos"
import { BiSolidChevronRight, BiSolidSend } from "react-icons/bi"
import { BsTrash } from "react-icons/bs"
import PantallaError from "../../../general/pantalla_error"
import CargandoFlotante from "../../../general/cargando_flotante"
import { sliceIntoChunks } from "../../../../lib/helpers/helpers"
import { rutas } from "../../../../lib/routes/routes"
import { procesarZonasExcelModelo } from "../../../../lib/helpers/data/internationa"

const FormularioLoteTarifa = (props) => {
    const {
        pais_code,
        propietario,
        rut_cliente
    } = props
    const [ loadingUpload, setLoadingUpload ] = useState(false)
    const [ rangos, setRangos ] = useState([])
    const [ disablePesoDesde, setDisablePesoDesde ] = useState(false)
    const [ peso_desde, setPesoDesde ] = useState(0)
    const [ peso_hasta, setPesoHasta ] = useState('')
    const [ servicio, setServicio ] = useState(false)
    const [ lote, setLote ] = useState({})
    const [ tarifadinamica, setTarifaDinamica ] = useState(false)
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ zonas, setZonas ] = useState([])

    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ procesados, setProcesados ] = useState(0)
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ operacionesListas, setOperacionesListas] = useState(0)

    const [ errores, setErrores ] = useState([])
    const [ tarifasCargadas, setTarifasCargadas ] = useState([])
    const [ loadingGeoData, setLoadingGeoData ] = useState(true)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const reset_array = [...[], ...[]]

    const handleChangeLote = (e) => {
        const { name, value } = e.target
        lote[name] = value
        return setLote(lote)
    }

    const handleInputChange = (event) => {

        const target = event.target
        const name = target.name
        let hojas = []
        setErrores(reset_array)
        setTarifasCargadas(reset_array)
        if (name === 'file') {

            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, {type: 'array'});
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos', )
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos', )
            if(hojas[0].length < 1) return toast.error('No se encontraron datos', )
            
            let problemas = []
            let tarifas = []
            
            const rangos_ordenados = rangos.sort((a, b) => {
                const valor_a = parseInt(extraerNumeros(a.titulo))
                const valor_b = parseInt(extraerNumeros(b.titulo))
                if (valor_a < valor_b) {
                    return -1;
                }
                if (valor_a > valor_b) {
                    return 1;
                }
                return 0;
            })
            
            hojas[0].map((row,irow) => {

                if(!row.destino){
                    problemas.push(`Fila ${(irow+1)} sin destino, se descarta`)
                    return false
                }

                let tarifa_base             = false
                let pesobase                = false
                let tarifa_anterior         = null

                const i_destino = zonas.findIndex(z => z.titulo.toLowerCase() === row.destino.toLowerCase() )
                if(i_destino < 0){
                    problemas.push(`Fila ${(irow+1)} con destino inválido, ${row.destino}`)
                    return false
                }

                rangos_ordenados.map(rango => {
                    const precio = parseFloat(row[rango.titulo])
                    if(isNaN(precio) !== true){

                    let nueva_tarifa = {
                        // id_geocerca_origen: "1", SE GRABA LUEGO PARA PODER CAMBIARLO LUEGO DE LA SUBIDA
                        id_geocerca_destino: zonas[i_destino]._id,
                        tipo: 'simple',
                        tarifa: precio,
                        tarifabase: false,
                        peso_desde: rango.peso_desde,
                        peso_hasta: rango.peso_hasta,
                        propietario,
                        rutcliente: rango.rut
                    }

                    if(tarifadinamica===rango.titulo){
                        const numero = rango.titulo.replace(/\D/g, "")
                        const titulo_rango_anterior = `rango${parseInt(numero)-1}`

                        tarifa_base = row[titulo_rango_anterior] // SE DEFINE LA PRIMERA DEL CICLO

                        const buscar_rango = rangos_ordenados.findIndex(ra => ra.titulo === titulo_rango_anterior)
                        if(buscar_rango > -1){
                            pesobase = rangos_ordenados[buscar_rango].peso_hasta
                        }
                    }

                    if(tarifa_base !== false){
                        nueva_tarifa.tarifabase = parseFloat(tarifa_base)
                        nueva_tarifa.tipo = 'dinamico'
                        nueva_tarifa.pesobase = pesobase

                        /*
                        // DEFINIR 
                        if(tarifa_anterior){
                            const calcular_tarifa_base = (tarifa_anterior.peso_hasta/1000) * tarifa_anterior.tarifa
                            nueva_tarifa.tarifabase = calcular_tarifa_base
                        }

                        tarifa_anterior = nueva_tarifa
                        */
                    }

                    if(lote.porkilo === true) nueva_tarifa.tarifabase = 0
                        tarifas.push(nueva_tarifa)
                    }
                })

            })
            
            if(problemas.length > 0){
                setErrores([...[], ...problemas])
                return toast.error(`Se detectaron problemas en tu archivo`, ) // SI HAY ERRORES DETENER
            }


            return setTarifasCargadas([...[], ...tarifas])
          }
        }
      }

    const onChangeServicio = async (e) => {
        setErrores(reset_array)
        setTarifasCargadas(reset_array)
        setLoadingUpload(true)
        setServicio(e)
        return fetch(`${urlapi}/pricing/rangos?id=${e._id}&rut=${rut_cliente}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setRangos(res)
            }
            return setLoadingUpload(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingUpload(false)
        })
    } 

    const handleChangePesoDesde = (e) => {
        const { value } = e.target
        const numero = value.replace(/[^\d]/g, "");
        return setPesoDesde(parseFloat(numero))
    }
    const handleChangePesoHasta = (e) => {
        const { value } = e.target
        const numero = value.replace(/[^\d]/g, "");
        return setPesoHasta(parseFloat(numero))
    }

    const agregarRango = () => {
        if(peso_desde===0 && peso_hasta === 0) return toast.error(`Valores de rango inválidos`)
        if(peso_desde===peso_hasta) return toast.error(`Valores de rango inválidos`)

        const titulo = `rango${rangos.length+1}`

        const buscar_repetido = rangos.filter(ra => ra.peso_desde === peso_desde && ra.peso_hasta === peso_hasta-1)

        if(buscar_repetido.length > 0) return toast.error(`Este rango que intentas agregar está repetido`)
        setDisablePesoDesde(true)
        rangos.push({
            titulo,
            peso_desde,
            peso_hasta: peso_hasta-1,
            tipo:'simple',
            propietario,
            propietario_cliente: rut_cliente,
            pais_code
        })

        setPesoDesde(peso_hasta)
        setPesoHasta(peso_hasta)
        return setRangos(prev => [...[], ...rangos])
    }

    const guardarRangosPendientes = async () => {
        if(!servicio) return toast.error("Selecciona el tipo de servicio")
        const rangos_preparados = JSON.parse( JSON.stringify(rangos) ).map(ra => ({...ra, id_tipo_servicio: servicio._id}))
        setLoadingUpload(true)
        return fetch(`${urlapi}/pricing/lotes`,{
            method:'POST',
            body:JSON.stringify({
                rangos: rangos_preparados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                if(res.length === rangos_preparados.length){
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000);
                    return toast.success("Creado exitosamente")
                }
            }
            return setLoadingUpload(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingUpload(false)
        })
    }
    
    const crearLote = async () => {
        let nuevo_lote = JSON.parse( JSON.stringify(lote) )
        nuevo_lote.id_servicio = servicio._id
        nuevo_lote.rut_cliente = rut_cliente
        return fetch(`${urlapi}/pricing/lotes`,{
            method:'POST',
            body:JSON.stringify(nuevo_lote),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                return res._id
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    const subirDatos = async (id_lote, datos) => {
        const progreso = procesados + datos.length

        const preparar_datos = datos.map(ta => {
            if(lote.multiplicador === true) ta.multiplicador = "um"
            ta.porvolumen = lote.porvolumen === true
            ta.porkilovolumen = lote.porkilovolumen === true
            ta.fecha_caducidad = lote.fecha_caducidad ? lote.fecha_caducidad : null
            return ta
        })

        return fetch(`${urlapi}/pricing/lotes/tarifas`,{
            method:'POST',
            body: JSON.stringify({
                id_lote,
                create: preparar_datos
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                return toast.error('Sin datos')
            } else if(res.errorMessage){
                return toast.error(res.errorMessage)
            }
            if(Array.isArray(res.invalidos) !== false){
                for( const error of res.invalidos ){
                        errores.push(error)
                }
                setErrores(prev => [...[], ...errores])
            }
            return setProcesados(progreso)
        })
        .catch(error => {
            toast.error(error.message)
            return setProcesados(progreso)
        })
    }

    const guardarTarifas = async () => {
        if(tarifasCargadas.length < 1) return toast.error("No se encontraron tarifas")
        
        const tarifas_preparadas = JSON.parse( JSON.stringify(tarifasCargadas) ).map(tar => {
            tar.rutcliente = rut_cliente
            tar.id_servicio = servicio._id
            return tar
        })
        
        setMensajeLoading('Creando lote de tarifas')
        setLoadingMaster(true)
        setLoadingOperaciones(tarifas_preparadas.length)
        setOperacionesListas(0)
        let total_procesadas = 0
        
        const nuevo_lote = await crearLote()
        if(!nuevo_lote){
            return toast.error("No se pudo crear el lote")
        }
        setMensajeLoading('Creando tarifas')

            const dividir = sliceIntoChunks(tarifas_preparadas, 50)
            for( const array of dividir ){
                await subirDatos(nuevo_lote, array)
                total_procesadas = total_procesadas + array.length
                setOperacionesListas(total_procesadas)
            }
        
        setLoadingMaster(false)
        toast.success("Progreso finalizado")
        
        return setTimeout(() => {
            window.location = `/${rutas.pricing_lotes.slug}/${nuevo_lote}`
        }, 1500);
    }

    const onChangeOrigen = e => {
        lote.id_geocerca_origen = e._id
        return setLote(lote)
    }

    const reiniciarRangos = () => {
        setPesoDesde(0)
        setPesoHasta('')
        setDisablePesoDesde(false)
        return setRangos([...[], ...[]])
    }

    const handleChangeRangoDinamico = e => {
        const { value } = e.target
        setErrores(reset_array)
        setTarifasCargadas(reset_array)
        return setTarifaDinamica(value)
    } 

    const ifRangosSinGuardar = () => {
        if(loadingUpload) return <Spinner animation="border" />

          const pendientes_de_guardar = rangos.filter(ra => !ra._id)
          if(pendientes_de_guardar.length > 0) return <div>
                <Button size="sm" variant="outline-danger" className="mr-3" onClick={() => reiniciarRangos()}>BORRAR TODOS</Button>
              { loadingUpload ? <Spinner animation="border" /> : <Button size="sm" variant="success " onClick={()=>guardarRangosPendientes()}>GUARDAR RANGOS</Button> }
          </div>
    }

    const removerTarifasCargadas = () => {
        setErrores(reset_array)
        return setTarifasCargadas(reset_array)
    }

    const handleClose = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleClose} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    const procesarDatos = (datos) => {
        let sector = []
        for(const reg of datos ){
            const i = sector.findIndex(se => se._id === reg.id_geocerca_destino)
            if(i > -1){
                sector[i].tarifas.push(reg)
            } else {
                const pos_zona = zonas.findIndex(zon => zon._id === reg.id_geocerca_destino)
                if(pos_zona > -1 ) sector.push({...zonas[pos_zona], tarifas: [ reg ] })
            }
        }
        return sector
    }

    const segmentarPorZonaAdministrativaPorPais = (datos) => {
        switch (pais) {
            case "CL":

                let region_iteracion = []

                for( const tar of datos ){
                    let codregion = false

                    if(tar.properties) if(typeof tar.properties === "object") if(tar.properties.codregion) codregion = tar.properties.codregion

                    if( codregion !== false ){
                        const i_region = zonas.findIndex(zo => {
                            const is_region = zo.local_name === "region"
                            if(!zo.properties) return false
                            if(typeof zo.properties !== "object") return false
                            return is_region && zo.properties.codregion === codregion
                        })
                        
                        const i_region_iteracion = region_iteracion.findIndex(re => re._id === zonas[i_region]._id)
                        if(i_region_iteracion > -1){

                            region_iteracion[i_region_iteracion].zonas.push(tar)

                        } else {
                            region_iteracion.push({...zonas[i_region], zonas: [ tar ] })
                        }
                    }
                }

                return region_iteracion
            default:
                return []
        }
    }

    const mostrarTarifasCargadas = () => {
        try {
        const datos =  procesarDatos(tarifasCargadas)
        const segmentar = segmentarPorZonaAdministrativaPorPais(datos)
        
        const rangos_ordenados = rangos.sort((a, b) => {
            const valor_a = parseInt(extraerNumeros(a.titulo))
            const valor_b = parseInt(extraerNumeros(b.titulo))
            if (valor_a < valor_b) {
                return -1;
            }
            if (valor_a > valor_b) {
                return 1;
            }
            return 0;
        })

        return <div>
            {
                segmentar.map((region,irr) => {
                    return <div key={region._id} className="mt-2">
                        <h4>{region.titulo}</h4>
                        <Card className="p-2">
                        <Table className="mb-0">
                            <thead>
                                <tr>
                                    <th>DESTINO</th>
                                    {
                                        rangos_ordenados.map((rango,irango) => {
                                            const desde = (rango.peso_desde/1000).toFixed(0)
                                            const hasta = (rango.peso_hasta/1000).toFixed(0)
                                            return <th style={{ textTransform: "uppercase" }} key={`rang-${irango}`}>{rango.titulo} <b style={{ fontWeight: "lighter", color: "gray" }}>{desde}KG a {hasta}KG</b></th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    region.zonas.map((comuna) => {
                                        return <tr key={`re-${irr}-${comuna._id}`} className="p-2">
                                            <th style={{ textTransform: "uppercase" }}>{comuna.titulo}</th>
                                            {
                                                rangos_ordenados.map((rango,irango) => {
                                                    let valor = ''
                                                    const i_tarifa = tarifasCargadas.findIndex(tar => tar.id_geocerca_destino === comuna._id && tar.peso_desde === rango.peso_desde && tar.peso_hasta === rango.peso_hasta)
                                                    if(i_tarifa > -1) valor =  tarifasCargadas[i_tarifa]
                                                    return <th key={`rang-${irango}`}>{valor.tarifabase ? <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                          <Tooltip >Tarifa base de {formatoMoneda(valor.tarifabase)} más {formatoMoneda(valor.tarifa)} por kilogramo adicional</Tooltip>
                                                        }
                                                        ><b style={{ color: "#c8c7c7", border: "1px solid #c8c7c7", padding: "2px 3px", fontSize:12, borderRadius:3, marginRight: 5 }}>{formatoMoneda(valor.tarifabase)}</b></OverlayTrigger> : false } {formatoMoneda(valor.tarifa)}</th>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        </Card>
                    </div>
                })
            }
        </div>
            
        } catch (error) {
            return <PantallaError mensaje={error.message} />
        }
    }

    const mostrarCargaAcciones = () => {
        if(rangos.length < 1) return false

        if(tarifasCargadas.length > 0){
            return <div>
                <Button disabled={loadingMaster} size="sm" variant="outline-danger" className="mr-3" onClick={() => removerTarifasCargadas()}><BsTrash /> CARGAR TARIFAS DE NUEVO</Button>
                <Button disabled={loadingMaster} size="sm" variant="success" onClick={() => guardarTarifas()}><BiSolidSend /> GUARDAR</Button>
                {mostrarTarifasCargadas()}
            </div>
        }

        return <div>
                    <label className="form-control-label d-block">Subir archivo excel</label>
                    <input  
                            type="file" 
                            name="file" 
                            id="file" 
                            className="form-control"
                            onChange={handleInputChange} 
                            placeholder="Archivo de excel" 
                            />
                    {mostrarErrores()}
        </div>
    }

    const s2ab = (s) => {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i < s.length; i++) {
          view[i] = s.charCodeAt(i) & 0xff;
        }
        return buf;
      };

    const handleDownloadExcel = ({cabeceras,data}) => {
        // Crear el archivo de Excel
        const wb = XLSX.utils.book_new();
        const wsData = [cabeceras]
        const ws = XLSX.utils.aoa_to_sheet(wsData);
        XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
        XLSX.utils.sheet_add_aoa(ws, data, { origin: -1 });
        // Convertir el libro de trabajo a un archivo binario
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        // Crear un Blob con los datos binarios
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        // Generar un objeto URL del Blob
        const url = URL.createObjectURL(blob);
    
        // Crear un enlace de descarga invisible y hacer clic en él
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Modelo Tarifario.xlsx';
        a.click();
    
        // Liberar el objeto URL
        URL.revokeObjectURL(url);
    }
    
    const descargarModelo = () => {
        const estructura_excel = procesarZonasExcelModelo(rangos, zonas, pais)
        console.log({ estructura_excel })
        return handleDownloadExcel({ cabeceras: estructura_excel.cabeceras, data: estructura_excel.data })
    }

    const mostrarDescargaExcelModelo = () => {
        if(zonas.length < 1) return false


        return <div className="mt-3">
            <Button size="sm" variant="light" onClick={() => descargarModelo()}>DESCARGAR EXCEL</Button>
        </div>
    }

    const handleCheckTarifaKilo = (e) => {
        const { name } = e.target
        const valor = e.target.checked
        return setLote(prev => {
            let actual = {...prev}
            actual[name] = valor
            return { ...{}, ...actual }
        })
    }

    return <div>
        <h4>Subir tarifas</h4>
        
        <Row>
            <Col xs={8} className="mb-2">
                <label className="form-control-label d-block">Título del lote</label>
                <input className="form-control" name="nombre" onChange={handleChangeLote} />
            </Col>
            <Col xs={4} className="mb-2">
                <label className="form-control-label d-block">Fecha de cacucidad (Opcional))</label>
                <input className="form-control" type="date" name="fecha_caducidad" onChange={handleChangeLote} />
            </Col>
            <Col md={3} className="mt-2">
            <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip >Si activas esta opción los precios serán multiplicados por la unidad de medida correspondiente</Tooltip>}>
            <Form.Group className="mb-3" controlId="multiplicador">
                <Form.Check disabled={loadingMaster} type="switch" label="Calcular tarifas por unidad de medida" name="multiplicador" onChange={handleCheckTarifaKilo} />
            </Form.Group>
            </OverlayTrigger>
            </Col>
            <Col md={3} className="mt-2">
            <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip >Si activas esta opción los precios dinámicos tendrán una tarifa base de 0 en vez de una tarifa base calculada por el rango anterior</Tooltip>}>
            <Form.Group className="mb-3" controlId="porkilo">
                <Form.Check disabled={loadingMaster} type="switch" label="Desactivar la tarifa base calculada" name="porkilo" onChange={handleCheckTarifaKilo} />
            </Form.Group>
            </OverlayTrigger>
            </Col>
            <Col md={3} className="mt-2">
            <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip >Si activas esta opción el cálculo de la tarifa se hará en base al volumen de la carga, en lugar del peso volumétrico.</Tooltip>}>
            <Form.Group className="mb-3" controlId="porvolumen">
                <Form.Check disabled={loadingMaster} type="switch" label="Calcular en base al volumen cúbico" name="porvolumen" onChange={handleCheckTarifaKilo} />
            </Form.Group>
            </OverlayTrigger>
            </Col>
            <Col md={3} className="mt-2">
            <OverlayTrigger
                placement={'top'}
                overlay={<Tooltip >Si activas esta opción el cálculo de la tarifa se hará en base al kilo volumen.</Tooltip>}>
            <Form.Group className="mb-3" controlId="porkilovolumen">
                <Form.Check disabled={loadingMaster} type="switch" label="Calcular en base al kilo volumen" name="porkilovolumen" onChange={handleCheckTarifaKilo} />
            </Form.Group>
            </OverlayTrigger>
            </Col>
            <Col md={4} className="mb-2">
                <SelectorServicios defaultValue={servicio?._id} full_payload={true} onChange={(data) => onChangeServicio(data)} titulo="Servicio" />
                { loadingUpload ? <div className="d-block"><Spinner animation="border" /></div> : false } 
            </Col>
            <Col md={4} className="mb-2">
                <SelectorZonas onChangeValue={(data) => onChangeOrigen(data)} titulo="Origen de tarifa" onFetchedData={(data) => setZonas(data)} />
            </Col>
            <Col md={4} className="mb-2">
                <label className="form-control-label d-block">Tarifa dinámica comienza en</label>
                <select className="form-control" onChange={handleChangeRangoDinamico}>
                    <option value="">No tiene tarifa dinámica</option>
                    {
                        rangos.map((range,ir) => <option key={`rango-${ir}`} value={range.titulo} >{range.titulo}</option>)
                    }
                </select>
            </Col>

            <Col md={12} className="mb-2">
            <Row><Col md={12} className="mb-3"><VisualizarRangos rangos={rangos} />{rangos.length > 0 ? mostrarDescargaExcelModelo() : false }</Col></Row>
            {mostrarCargaAcciones()}
            </Col>
        </Row>
        <CargandoFlotante visualizar={loadingMaster} mensaje={mensajeLoading} procesando={loadingOperaciones} procesado={operacionesListas}  />
    </div>
}

export default FormularioLoteTarifa