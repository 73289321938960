import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import data, { maps_key, tipos_direccion } from '../../lib/backend/data'
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import { SiAdblock } from "react-icons/si";


const VerifyModelBusiness = (props) => {
  
  const {
  vehiculo,
   orden,
   ruta,
   excelVehiculos,
   excelRutas
} = props

    const [ modeloNegocio, setModeloNegocio ] = useState({})
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const [ showModal, setShowModal ] = useState(true)
 
   
    useEffect(() => {
       obtenerModeloNegocios()
    },[])

    const handleClose = () => {
      if(props.handleChangeBoton) props.handleChangeBoton(true)    
      return setShowModal(false)
  }


    const obtenerModeloNegocios = async () => {
      let condicion = { vehiculo: props.vehiculo ? props.vehiculo : false,
      orden: props.orden ? props.orden : false,
      ruta: props.ruta ? props.ruta : false }  
        
        setLoadingCreacion(true)
        return fetch(`${data.urlapi}/modelo/negocios`, {
            method: 'POST',
            body: JSON.stringify(condicion),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
          .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
            .then(res => {
              console.log("res", res)
                if (!res) {
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingCreacion(false)
                } if (typeof res === 'object') {
                  setModeloNegocio(res) 
                   return setLoadingCreacion(false)           
                }
                return setLoadingCreacion(false)
            })
            .catch(error => {
                toast.error(error.message)
                return setLoadingCreacion(false)
            })
    }

    const modal = () => {
      
      // CREADORES MASIVOS POR EXCEL

      if(((modeloNegocio.vehiculosActuales + excelVehiculos) > modeloNegocio?.maximo_conductores) && excelVehiculos > 0 && modeloNegocio.maximo_conductores > 0) return <Modal show={showModal} size="lg" onHide={handleClose} centered >
      <Modal.Header style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} closeButton>
      <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className='m-0'>
      <Col md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
              <h3  style={{ marginBottom: 10 }}>ADVERTENCIA</h3>        
              <i  style={{ fontSize: 140, fontWeight: 'bold', marginBottom: 20 }}className="fa-solid fa-triangle-exclamation text-danger"></i>       
             <p style={{ fontSize: 18, fontWeight: 'normal', marginBottom: 50, textAlign: 'center' }} > <b>Con esa cantidad de Vehiculos cargados superas el maximo permitido, solo puedes crear {modeloNegocio.maximo_conductores}.</b> </p>         
          </Col>                  
      </Modal.Body>
      </Modal>

      if(((modeloNegocio.rutasActuales + excelRutas) > modeloNegocio?.maximo_ruteos) && excelRutas > 0 && modeloNegocio.maximo_ruteos > 0) return <Modal show={showModal} size="lg" onHide={handleClose} centered >
      <Modal.Header style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} closeButton>
      <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className='m-0'>
      <Col md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
              <h3  style={{ marginBottom: 10 }}>ADVERTENCIA</h3>        
              <i  style={{ fontSize: 140, fontWeight: 'bold', marginBottom: 20 }}className="fa-solid fa-triangle-exclamation text-danger"></i>       
            <p style={{ fontSize: 18, fontWeight: 'normal', marginBottom: 50, textAlign: 'center' }} > <b>Con esa cantidad de Rutas cargadas superas el maximo permitido, solo puedes crear {modeloNegocio.maximo_ruteos}.</b> </p>         
          </Col>                  
      </Modal.Body>
      </Modal>

    // CREADORES INDIVIDUALES

      if(modeloNegocio?.maximo_conductores === modeloNegocio?.vehiculosActuales && modeloNegocio.maximo_conductores > 0 ) return <Modal show={showModal} size="lg" onHide={handleClose} centered >
      <Modal.Header style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }} closeButton>
      <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className='m-0'>
      <Col md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
              <h3  style={{ marginBottom: 10 }}>ADVERTENCIA</h3>        
              <i  style={{ fontSize: 140, fontWeight: 'bold', marginBottom: 20 }}className="fa-solid fa-triangle-exclamation text-danger"></i>       
             <p style={{ fontSize: 18, fontWeight: 'normal', marginBottom: 50, textAlign: 'center' }} > <b>Alcanzaste el maximo de Vehiculos, solo puedes crear {modeloNegocio.maximo_conductores}.</b> </p>         
          </Col>                  
      </Modal.Body>
  </Modal>
      if(modeloNegocio?.maximo_ordenes === modeloNegocio?.ordenesActuales && modeloNegocio.maximo_ordenes > 0) return <Modal show={showModal} size="lg" onHide={handleClose} centered >
      <Modal.Header closeButton>
      <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className='m-0'>
      <Col md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
              <h3  style={{ marginBottom: 10 }}>ADVERTENCIA</h3>        
              <i  style={{ fontSize: 140, fontWeight: 'bold', marginBottom: 20 }}className="fa-solid fa-triangle-exclamation text-danger"></i>       
             <p style={{ fontSize: 18, fontWeight: 'normal', marginBottom: 50, textAlign: 'center' }} > <b>Alcanzaste el maximo de Ordenes, solo puedes crear {modeloNegocio.maximo_ordenes}.</b> </p>         
          </Col>               
      </Modal.Body>
  </Modal>
      if(modeloNegocio?.maximo_ruteos === modeloNegocio?.rutasActuales && modeloNegocio.maximo_ruteos > 0)  return <Modal show={showModal} size="lg" onHide={handleClose} centered >
      <Modal.Header closeButton>
      <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className='m-0'>
      <Col md={12} style={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexDirection: 'column'}}>
              <h3  style={{ marginBottom: 10 }}>ADVERTENCIA</h3>        
              <i  style={{ fontSize: 140, fontWeight: 'bold', marginBottom: 20 }}className="fa-solid fa-triangle-exclamation text-danger"></i>       
             <p style={{ fontSize: 18, fontWeight: 'normal', marginBottom: 50, textAlign: 'center' }} > <b>Alcanzaste el maximo de Rutas, solo puedes crear {modeloNegocio.maximo_ruteos}.</b> </p>         
          </Col>                  
      </Modal.Body>
  </Modal>
    }


    const mostrarAdvertencia = () => {
      if(loadingCreacion) return <div ><Spinner style={{ marginTop: 10 }} animation='border' /></div> 
  
      if(modeloNegocio) {
        // CREACION MASIVA
        if(((modeloNegocio.vehiculosActuales + excelVehiculos) > modeloNegocio?.maximo_conductores) && excelVehiculos > 0 && modeloNegocio.maximo_conductores > 0) return <div style={{background: '#ffd971', padding: 10, marginBottom: 20, borderRadius: 10, textAlign: 'center'}}> <p style={{ fontSize: 18, fontWeight: 'normal', marginTop: 15 }} className='text-danger'><SiAdblock style={{ fontSize: 26, fontWeight: 'bold'}}/><b> Creacion Bloqueada !</b></p>
        <p style={{ fontSize: 18, fontWeight: 'normal', marginBottom: 10 }} className='text-danger'><i  style={{ fontSize: 18 }}className="fa-solid fa-triangle-exclamation"></i> No puedes superar el limite de Vehiculos permitidos, renueva tu plan!</p></div> 

        if(((modeloNegocio.rutasActuales + excelRutas) > modeloNegocio?.maximo_ruteos) && excelRutas > 0 && modeloNegocio.maximo_ruteos > 0) return <div style={{background: '#ffd971', padding: 10, marginBottom: 20, borderRadius: 10, textAlign: 'center'}}> <p style={{ fontSize: 18, fontWeight: 'normal', marginTop: 15 }} className='text-danger'><SiAdblock style={{ fontSize: 26, fontWeight: 'bold'}}/><b> Creacion Bloqueada !</b></p>
        <p style={{ fontSize: 18, fontWeight: 'normal', marginBottom: 10 }} className='text-danger'><i  style={{ fontSize: 18 }}className="fa-solid fa-triangle-exclamation"></i> No puedes superar el limite de Rutas permitidas, renueva tu plan!</p></div> 


        //CREACION INDIVIDUAL 
      if(modeloNegocio?.maximo_conductores === modeloNegocio?.vehiculosActuales && modeloNegocio.maximo_conductores > 0) return <div>  <p style={{ fontSize: 14, fontWeight: 'normal', marginTop: 15 }} className='text-danger'><SiAdblock style={{ fontSize: 22, fontWeight: 'bold'}}/><b> Creacion Bloqueada !</b></p>
      <p style={{ fontSize: 14, fontWeight: 'normal', marginBottom: 10, marginTop: 0 }} className='text-danger'> Has alcanzado el maximo de creacion de Vehiculos permitidos, renueva tu plan!</p></div> 

      if(modeloNegocio?.maximo_ordenes === modeloNegocio?.ordenesActuales && modeloNegocio.maximo_ordenes > 0) return <div>  <p style={{ fontSize: 14, fontWeight: 'normal', marginTop: 15 }} className='text-danger'><SiAdblock style={{ fontSize: 22, fontWeight: 'bold'}}/><b> Creacion Bloqueada !</b></p>
      <p style={{ fontSize: 14, fontWeight: 'normal', marginBottom: 10, marginTop: 0 }} className='text-danger'> Has alcanzado el maximo de creacion de Ordenes permitidas, renueva tu plan!</p></div> 

      if(modeloNegocio?.maximo_ruteos === modeloNegocio?.rutasActuales && modeloNegocio.maximo_ruteos > 0) return <div>  <p style={{ fontSize: 16, fontWeight: 'normal', marginTop: 15 }} className='text-danger'><SiAdblock style={{ fontSize: 22, fontWeight: 'bold'}}/><b> Creacion Bloqueada !</b></p>
       <p style={{ fontSize: 16, fontWeight: 'normal', marginBottom: 10 }} className='text-danger'> Has alcanzado el maximo de creacion de Rutas permitidas, renueva tu plan!</p></div> 
      }}        
      
      
      return <div>
  {mostrarAdvertencia()}
  {modal()}
      </div>
}

export default VerifyModelBusiness