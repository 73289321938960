import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Card, Button, Table, Tabs, Tab, Accordion, ButtonGroup, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap'
import Header from '../Header'
import Spinner from 'react-bootstrap/Spinner'
import { toast } from 'react-toastify';
import data, { key_local_storage_user } from '../../lib/backend/data'
import { Link } from 'react-router-dom'
import Moment from 'react-moment';
import 'moment/locale/es';
import PantallaCargando from '../../subComponents/general/pantalla_cargando'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';
import FlujosEstadosCarga from '../../subComponents/estados_carga/flujos'
import { showByTipoServicio } from '../../lib/helpers/pedidos/pedidos'
import { cerrarSesion } from '../../redux/actions/session'
import { BsArrowRepeat, BsCardChecklist, BsCircle, BsFillTrashFill, BsSendCheckFill, BsWhatsapp } from 'react-icons/bs'
import { AiFillCheckCircle, AiOutlineFileSearch, AiOutlineMail } from 'react-icons/ai'
import { BiSolidHelpCircle } from 'react-icons/bi'
import { MdInventory2, MdOutlineCancel, MdOutlineInventory2, MdPublic, MdSettingsInputAntenna } from 'react-icons/md'
import HelperModal from '../../subComponents/general/helperModal'
import FirmaModal from '../../subComponents/firma/firmaModal'
import { FiSettings } from 'react-icons/fi'
import { url_images } from '../../lib/global/data'
import { FaRegEnvelope, FaWhatsapp } from 'react-icons/fa'
import { IoIosAlert, IoIosSend } from 'react-icons/io'
import { TiFlowSwitch } from "react-icons/ti";
import { types } from '../../subComponents/ordenes/ordenes_tickets/data'
import SelectorFormatos from '../../subComponents/formatos/selector'

const iconSize = 30

class EstadosCarga extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user)),
            loadingEstados: true,
            loadingMore: false,
            total: 0,
            usuarios: [],
            parametro_busqueda: '',
            loadingBusqueda: false,
            filtro_rol: '',
            loadingSubestados: true,
            rutcliente: '',
            loadingUpload: false,
            clienteconsultar: { value: this.props.idconsulta, label: this.props.razonsocial },
            tipovisualizacion: 'estados',
            shoyEmptyText: false,
            estados: [],
            subestados: [],
            nuevo_estado: {},
            nuevo_subestado: {},
            id_estado_crear_subestado: false,
            eliminando: false,
            loadingAcciones: true,
            acciones: [],
            acciones_subestado_actual: [],
            eliminandoAcciones: false,
            tipos_servicio: [],
            filtro_tipo_servicio: '',
            configuracion: {},
            loadingConfiguracionNotificaciones: true,
            loadingConfiguracion: true,
            plantillas_ws: [],
            loadingPlantillasWhatsapp: true,
            // showModal: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.buscarUsuario = this.buscarUsuario.bind(this)
        this.handlechangeStatus = this.handlechangeStatus.bind(this)
        this.handleChangeSelectTipoServicio = this.handleChangeSelectTipoServicio.bind(this)
        this.handleChangeSelectTipoServicioFiltro = this.handleChangeSelectTipoServicioFiltro.bind(this)
        this.handleChangeAccion = this.handleChangeAccion.bind(this)
        this.handleChangeAccionArray = this.handleChangeAccionArray.bind(this)
        this.handlechangeSubestado = this.handlechangeSubestado.bind(this)
        this.handleChangeConfig = this.handleChangeConfig.bind(this)
        this.handleChangeCondicion = this.handleChangeCondicion.bind(this)

    }

    handleChange(e) {
        const { name, value } = e.target
        return this.setState({ [name]: value })
    }

    handleChangeAccion(e) {
        const { name, value, id } = e.target
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i][name] = value
        console.log(acciones_subestado_actual)
        return this.setState({ acciones_subestado_actual })
    }

    handleChangeCondicion(e) {
        const { name, value, id } = e.target
        const pos = e.target.getAttribute('pos')
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].conditions[parseInt(pos)][name] = value
        console.log(acciones_subestado_actual)
        return this.setState({ acciones_subestado_actual })
    }

    eliminarCondicion(id, pos) {
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].conditions.splice(pos, 1)
        return this.setState({ acciones_subestado_actual })
    }

    handleChangeAccionArray(e) {
        const { value, id } = e.target
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].valor = value
        this.setState({ acciones_subestado_actual })
        const tienecoma = value.indexOf(',')
        if (tienecoma < 0) return false
        const dividir = value.split(',')

        if (!acciones_subestado_actual[i].opciones) acciones_subestado_actual[i].opciones = []
        acciones_subestado_actual[i].opciones.push(dividir[0])

        acciones_subestado_actual[i].valor = ''
        return this.setState({ acciones_subestado_actual })
    }

    componentDidMount() {
        this.getEstados()
        this.getConfiguracion('logistica', 'tipos-servicio', 'tipos_servicio')
        this.getAcciones()
        this.getPlantillasWhatsapp()
    }

    getPlantillasWhatsapp() {
        const { user } = this.state
        const loading = 'loadingPlantillasWhatsapp'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/whatsapp/plantillas`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(data => {
                if (!data) {
                    toast.warn('No hay configuración de notificaciones creada para este subestado', this.state.toaststyle)
                } else if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ [loading]: false })
                } else if (Array.isArray(data) !== false) {
                    this.setState({ plantillas_ws: data })
                }
                return this.setState({ [loading]: false })
            })
            .catch(error => {
                toast.error('No pudimos cargar las plantillas de whatsapp', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    async getConfiguracion(tipo, subtipo, estado) {
        const { user } = this.state
        const loading = 'loadingConfiguracion'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/configuracion/tipo?tipo=${tipo}&subtipo=${subtipo}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(data => {
                console.log(data)
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ loadingFormulario: false })
                } else if (Array.isArray(data) !== false) {
                    if (data.length > 0) {
                        const datos = data.map(e => ({ value: e._id, label: e.valor }))
                        this.setState({ [estado]: datos })
                    }
                }
                return this.setState({ [loading]: false })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    getEstados(propietario) {
        const { user } = this.state
        const loading = 'loadingEstados'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/details`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ loadingFormulario: false })
                }
                if (data.estados.length < 1) this.setState({ shoyEmptyText: true })
                this.setState({ [loading]: false, subestados: data.subestados, estados: data.estados })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    buscar() {
        const { parametro_busqueda, user } = this.state
        if (!parametro_busqueda) return toast.error('El valor de búsqueda es requerido')
        if (parametro_busqueda.length < 3) return toast.error('Ingresa un valor de búsqueda válido')
        let loading = 'loadingBusqueda'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
            body: JSON.stringify({ parametro_busqueda })
        })
            .then(res => {
                if (res.status === 401) return this.props.cerrarSesion()
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                    return this.setState({ [loading]: false })
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [loading]: false })
                }
                if (res.length < 1) {
                    toast.warn('No se encontraron resultados')
                    return this.setState({ [loading]: false })
                }
                return this.setState({ usuarios: res, filtro_rol: '', [loading]: false })
            })
            .catch(error => {
                this.setState({ [loading]: false })
                toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
            })
    }

    async getUsuarios(cargarmas) {
        const { user, usuarios } = this.state
        let loading = 'loadingEstados'
        if (cargarmas === true) loading = 'loadingMore'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/usuarios/list?skip=${usuarios.length}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(res => {
                if (res.status === 401) return this.props.cerrarSesion()
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                    return this.setState({ [loading]: false })
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [loading]: false })
                }
                if (cargarmas === true) {
                    if (Array.isArray(res.datos) !== false) {
                        const final_array = usuarios.concat(res.datos)
                        return this.setState({ usuarios: final_array, total: res.total, [loading]: false })
                    } else {
                        return this.setState({ [loading]: false })
                    }
                } else {
                    this.setState({ usuarios: res.datos, total: res.total, [loading]: false })
                }
            })
            .catch(error => {
                this.setState({ [loading]: false })
                toast.error("Error al consultar la información, intente nuevamente", this.state.toaststyle)
            })
    }

    buscarUsuario(e) {
        e.preventDefault()
        return this.buscar()
    }

    sinRegistros() {
        return <div className='p-5'>
            <Row className="justify-content-md-center">
                <Col md={6} className="text-center">
                    <h4>Aún no tienes usuarios</h4>
                    <p>Puedes crear diversos usuarios y asignar permisos a cada uno de ellos</p>
                </Col>
            </Row>
        </div>
    }

    crearEstado() {
        const { nuevo_estado, clienteconsultar, user, estados } = this.state
        if (!nuevo_estado.titulo) return toast.error('Título es requerido', this.state.toaststyle)
        if (!nuevo_estado.color) return toast.error('color es requerido', this.state.toaststyle)
        if (!nuevo_estado.codigo_estado) return toast.error('Código es requerido', this.state.toaststyle)
        if (!nuevo_estado.tipo) return toast.error('tipo es requerido', this.state.toaststyle)
        if (!nuevo_estado.tipos_servicio) return toast.error('tipo servicio es requerido', this.state.toaststyle)
        if (nuevo_estado.tipos_servicio.length < 1) return toast.error('tipo servicio es requerido', this.state.toaststyle)
        if (nuevo_estado.tipo.length < 1) return toast.error('tipo es requerido', this.state.toaststyle)
        nuevo_estado.propietario = clienteconsultar.value
        this.setState({ loadingFormulario: true })
        return fetch(`${data.urlapi}/estadoscarga/create-or-edit-estado`, {
            method: 'POST',
            body: JSON.stringify(nuevo_estado),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                }
                toast.success(`Realizado exitosamente`, this.state.toaststyle)
                if (!nuevo_estado._id) {
                    this.setState({ shoyEmptyText: false })
                    if (pros._id) estados.push(pros)
                }
                return this.setState({ loadingFormulario: false, estados, tipovisualizacion: 'estados' })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ loadingFormulario: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    handlechangeStatus(e) {
        const { name, value } = e.target
        const { nuevo_estado } = this.state
        nuevo_estado[name] = value
        return this.setState({ nuevo_estado })
    }

    handleChangeSelectTipoServicioFiltro(e) {
        return this.setState({ filtro_tipo_servicio: e.value })
    }

    handleChangeSelectTipoServicio(e) {
        const { nuevo_estado } = this.state
        let opciones = []
        if (e) {
            e.map(valor => {
                return opciones.push(valor.value)
            })
        }
        nuevo_estado.tipos_servicio = opciones
        return this.setState({ nuevo_estado })
    }

    formularioCreacion() {
        const { loadingFormulario, loadingConfiguracion, nuevo_estado, tipos_servicio } = this.state

        if (!nuevo_estado) return false
        if (loadingFormulario) return <PantallaCargando />

        let opciones_tipo_servicio_elegidas = []

        if (nuevo_estado.tipos_servicio) {
            if (Array.isArray(nuevo_estado.tipos_servicio) !== false) {
                if (nuevo_estado.tipos_servicio.length > 0) {
                    opciones_tipo_servicio_elegidas = tipos_servicio.filter(op => {
                        return nuevo_estado.tipos_servicio.includes(op.value)
                    })
                }
            }
        }

        return <div >

            <h4>{nuevo_estado._id ? `Editar ${nuevo_estado.titulo}` : `Crear nuevo estado`}</h4>
            <button className="btn btn-sm btn-outline-danger mb-3" onClick={() => this.setState({ tipovisualizacion: 'estados' })} >CANCELAR</button>
            <Row>
                <Col md={5} className="mb-3">
                    <label className='form-control-label'>Título</label>
                    <input className='form-control' name="titulo" defaultValue={nuevo_estado.titulo} onChange={this.handlechangeStatus} />
                </Col>

                <Col md={2} className="mb-3">
                    <label className='form-control-label'>Código</label>
                    <input className='form-control' name="codigo_estado" defaultValue={nuevo_estado.codigo_estado} onChange={this.handlechangeStatus} />
                </Col>

                <Col md={2} className="mb-3">
                    <label className='form-control-label'>Color</label>
                    <input className='form-control' type="color" name="color" defaultValue={nuevo_estado.color} onChange={this.handlechangeStatus} />
                </Col>

                <Col md={2} className="mb-3">
                    <label className='form-control-label'>Tipo</label>
                    <select className='form-control' name="tipo" defaultValue={nuevo_estado.tipo} onChange={this.handlechangeStatus}>
                        <option value="">Selecciona</option>
                        <option value="web">Web</option>
                        <option value="movil">Móvil</option>
                    </select>
                </Col>

                <Col md={6} className="mb-3">
                    <h6 className='hover' onClick={() => this.setBoleanPropertyEstado('mostrar_linea_tiempo')}>{nuevo_estado.mostrar_linea_tiempo === true ?
                        <i className="fas text-success fa-check-square"></i> :
                        <i className="far fa-square"></i>} Mostrar en la línea de tiempo de tracking</h6>
                </Col>
                <Col md={6} className="mb-3">
                    <h6 className='hover' onClick={() => this.setBoleanPropertyEstado('opcional_linea_tiempo')}>{nuevo_estado.opcional_linea_tiempo === true ?
                        <i className="fas text-success fa-check-square"></i> :
                        <i className="far fa-square"></i>} Opcional en la linea de tiempo</h6>
                </Col>

                {
                    nuevo_estado.mostrar_linea_tiempo === true ? <Col md={3} className="mb-3">
                        <label className='form-control-label'>Posición en la línea de tiempo</label>
                        <input className='form-control' name="posicion_linea_tiempo" defaultValue={nuevo_estado.posicion_linea_tiempo} onChange={this.handlechangeStatus} />
                    </Col> : false
                }
                <Col md={6} className="mb-3">
                    <label className="form-control-label d-block">Filtrar por tipo servicio</label>
                    <Select
                        style={{ marginBottom: 10 }}
                        onChange={this.handleChangeSelectTipoServicio}
                        defaultValue={opciones_tipo_servicio_elegidas}
                        options={tipos_servicio}
                        isMulti={true}
                        isLoading={loadingConfiguracion}
                        noOptionsMessage={() => 'Sin opciones'}
                        placeholder="Tipo de servicio..."
                    />
                </Col>

                <Col md={3} className="mb-3">
                    <label className='form-control-label d-block'>Click para </label>
                    <button className='btn btn-success' onClick={() => this.crearEstado()}>{nuevo_estado._id ? 'GUARDAR CAMBIOS' : 'CREAR'}</button>
                </Col>
            </Row>
        </div>
    }

    mostrarUsuarios() {
        const { usuarios, loadingEstados, loadingMore, total, filtro_rol, loadingBusqueda } = this.state
        if (loadingEstados) return <PantallaCargando />
        if (usuarios.length < 1) return this.sinRegistros()

        const filtrados_rol = filtro_rol ? usuarios.filter(user => user.role === filtro_rol) : usuarios
        return <div>

            <form onSubmit={this.buscarUsuario}>
                <Row className='mb-3'>
                    <Col md={2} className='mb-3'>
                        <label className='form-control-label d-block'>Buscar</label>
                        <input className='form-control' name="parametro_busqueda" onChange={this.handleChange} />
                    </Col>
                    <Col md={2} className='mb-3'>
                        <label className='form-control-label d-block'>Click para buscar</label>
                        {
                            loadingBusqueda === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={() => this.buscar()}>BUSCAR</Button>
                        }
                    </Col>
                    <Col md={2}>
                        <label className='form-control-label d-block'>Filtrar por rol</label>
                        <select className='form-control' name="filtro_rol" value={filtro_rol} onChange={this.handleChange} >
                            <option value="" >Seleccione</option>
                            <option value="user" >Usuario</option>
                            <option value="admin" >Administrador</option>
                        </select>
                    </Col>
                </Row>
            </form>

            <Tabs defaultActiveKey="activos" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="activos" title="Activas">
                    <h5 className='mb-3'>Se muestran {filtrados_rol.length} de {total} registros</h5>
                    <div className='table-responsive'>
                        <Table bordered className='table'>
                            <thead>
                                <tr>
                                    <th>Nombres</th>
                                    <th>Apellidos</th>
                                    <th>Email</th>
                                    <th>Teléfono</th>
                                    <th>Estado</th>
                                    <th>Creación</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filtrados_rol.map((usuario, i) => {
                                        return <tr key={`user-${usuario._id}`}>
                                            <th><Link to={`/usuarios/edit-${usuario._id}`}>{usuario.nombres}</Link> </th>
                                            <th>{usuario.apellidos}</th>
                                            <th>{usuario.email}</th>
                                            <th>{usuario.phone}</th>
                                            <th>{usuario.activo === true ? 'Activo' : 'Inactivo'}</th>
                                            <th><Moment locale="ES" fromNow>{usuario.createdAt}</Moment></th>
                                        </tr>
                                    })
                                }
                            </tbody>

                        </Table>
                        {
                            usuarios.length < total ? <div>
                                {
                                    loadingMore === true ? <Spinner animation='border' /> : <Button size="sm" variant="outline-primary" onClick={() => this.getUsuarios(true)}>CARGAR MÁS</Button>
                                }
                            </div> : false

                        }

                    </div>
                </Tab>
                <Tab eventKey="archivados" title="Archivados">

                </Tab>
            </Tabs>


        </div>
    }

    editarEstado(nuevo_estado) {
        return this.setState({ tipovisualizacion: 'nuevoestado', nuevo_estado })
    }

    eliminarRegistro(id) {
        const { estados, user } = this.state
        const estado_loading = 'eliminando'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-estado?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(res => res.json())
            .then(res => {
                if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [estado_loading]: false })
                }
                toast.success('Eliminado exitosamente', this.state.toaststyle)
                const i = estados.findIndex(a => a._id === id)
                if (i > -1) estados.splice(i, 1)
                if(estados.length < 1) this.setState({ shoyEmptyText: true })
                return this.setState({ [estado_loading]: false, estados })
            })
            .catch(error => {
                toast.error("Ocurrió un error de servidor", this.state.toaststyle)
                return this.setState({ [estado_loading]: false })
            })
    }

    solicitarEliminarAccion(id) {
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
                {
                    label: 'CONFIRMAR',
                    onClick: () => this.eliminarAccion(id)
                },
                {
                    label: 'CANCELAR',
                    onClick: () => false
                },
            ],
        })
    }

    solicitarEliminar(id) {
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
                {
                    label: 'CONFIRMAR',
                    onClick: () => this.eliminarRegistro(id)
                },
                {
                    label: 'CANCELAR',
                    onClick: () => false
                },
            ],
        })
    }

    eliminarAccion(id) {
        const { acciones_subestado_actual } = this.state
        const estado_loading = 'eliminandoAcciones'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-accion?id=${id}`)
            .then(res => res.json())
            .then(res => {
                if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [estado_loading]: false })
                }
                toast.success('Eliminado exitosamente', this.state.toaststyle)
                const i = acciones_subestado_actual.findIndex(a => a._id === id)
                if (i > -1) acciones_subestado_actual.splice(i, 1)
                return this.setState({ [estado_loading]: false, acciones_subestado_actual })
            })
            .catch(error => {
                toast.error("Ocurrió un error de servidor", this.state.toaststyle)
                return this.setState({ [estado_loading]: false })
            })
    }

    mostrarTiposServicioConcatenados(tipos) {
        const { tipos_servicio } = this.state
        if (!tipos) return false
        if (Array.isArray(tipos) !== true) return false
        if (tipos.length < 1) return false
        return tipos.map(tipo => <span className='spanguia mr-2' style={{ fontSize: 11 }}>{showByTipoServicio(tipo, tipos_servicio)}</span>)
    }

    nuevoSubEstado(id_estado_crear_subestado) {
        this.getConfiguracionNotificaciones(false)
        return this.setState({ tipovisualizacion: 'nuevosubestado', id_estado_crear_subestado, nuevo_subestado: {} })
    }

    async getConfiguracionNotificaciones(idsubestado) {
        const { user } = this.state
        const loading = 'loadingConfiguracionNotificaciones'
        if (!idsubestado) return this.setState({ [loading]: false })
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/notificacion-por-subestado?idsubestado=${idsubestado}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                if (!data) {
                    toast.warn('No hay configuración de notificaciones creada para este subestado', this.state.toaststyle)
                } else if (data.errorMessage) {
                    toast.error(data.errorMessage)
                }
                return this.setState({ [loading]: false, configuracion: data ? data : {} })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    editarSubEstado(nuevo_subestado) {
        this.setState({
            tipovisualizacion: 'nuevosubestado',
            nuevo_subestado,
            id_estado_crear_subestado: nuevo_subestado.idparent,
            acciones_subestado_actual: []
        })
        this.getConfiguracionNotificaciones(nuevo_subestado._id)
        return this.getAccionesPorSubEstado(nuevo_subestado._id)
    }

    getAccionesPorSubEstado(idsubestado) {
        const { user } = this.state
        const loading = 'loadingAcciones'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/acciones-por-subestado?idsubestado=${idsubestado}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                console.log(data)
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ [loading]: false })
                }
                this.setState({ [loading]: false, acciones_subestado_actual: data })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    getAcciones() {
        const { user } = this.state
        const loading = 'loadingAcciones'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/acciones`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            },
        })
            .then(pros => pros.json())
            .then(data => {
                if (data.errorMessage) {
                    toast.error(data.errorMessage)
                    return this.setState({ [loading]: false })
                }
                if (data.length < 1) toast.warn('No hay acciones disponibles para crear formularios', this.state.toaststyle)
                this.setState({ [loading]: false, acciones: data })
            })
            .catch(error => {
                toast.error('No pudimos cargar la información', this.state.toaststyle)
                this.setState({ [loading]: false })
            })
    }

    guardarCambiosAccion(id) {
        const { acciones_subestado_actual, user } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        const loading = 'loadingAcciones'
        acciones_subestado_actual[i].valor = ''
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/actualizar-accion`, {
            method: 'POST',
            body: JSON.stringify(acciones_subestado_actual[i]),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ [loading]: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ [loading]: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                return this.setState({ [loading]: false })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ [loading]: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    obligatorio(id) {
        const { acciones_subestado_actual } = this.state
        const i = acciones_subestado_actual.findIndex(acc => acc._id === id)
        if (i < 0) return false
        acciones_subestado_actual[i].required = acciones_subestado_actual[i].required === true ? false : true
        return this.setState({ acciones_subestado_actual })
    }

    removerOpcionAccion(_id, i) {
        const { acciones_subestado_actual } = this.state
        const pos = acciones_subestado_actual.findIndex(acc => acc._id === _id)
        if (pos < 0) return false
        acciones_subestado_actual[pos].opciones.splice(i, 1)
        return this.setState({ acciones_subestado_actual })
    }

    mostrarAccionesDisponibles(nuevo_subestado) {
        if (!nuevo_subestado._id) return false
        const { acciones, loadingAcciones } = this.state
        if (loadingAcciones) return <Spinner animation='border' />
        if (acciones.length < 1) return <h4>No hay acciones disponibles para agregar al formulario</h4>

        return <div>
            <p className='mb-2'>Toca la opción para agregar a este subestado</p>
            {
                acciones.map((accion, i) => {
                    return <span key={`accion-${i}`} onClick={() => this.crearAccion(accion.tipo_accion, nuevo_subestado._id)} className='p-2 hover' style={{ backgroundColor: 'white', border: '1px solid #767676', marginRight: 10 }} >{accion.titulo}</span>
                })
            }
        </div>
    }

    eliminarSubEstado(id) {
        const { subestados } = this.state
        const estado_loading = 'eliminando'
        this.setState({ [estado_loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/eliminar-subestado?id=${id}`)
            .then(res => res.json())
            .then(res => {
                if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return this.setState({ [estado_loading]: false })
                }
                toast.success('Eliminado exitosamente', this.state.toaststyle)
                const i = subestados.findIndex(a => a._id === id)
                if (i > -1) subestados.splice(i, 1)
                return this.setState({ [estado_loading]: false, subestados })
            })
            .catch(error => {
                toast.error("Ocurrió un error de servidor", this.state.toaststyle)
                return this.setState({ [estado_loading]: false })
            })
    }

    solicitarEliminarSubEstado(id) {
        return confirmAlert({
            title: '¿Estás seguro?',
            message: `¿Deseas eliminar este item? Esta opción no se puede deshacer`,
            buttons: [
                {
                    label: 'CONFIRMAR',
                    onClick: () => this.eliminarSubEstado(id)
                },
                {
                    label: 'CANCELAR',
                    onClick: () => false
                },
            ],
        })
    }

    crearSubEstado() {
        const { nuevo_subestado, subestados, user, clienteconsultar, id_estado_crear_subestado } = this.state
        if (!nuevo_subestado.titulo) return toast.error('Título es requerido', this.state.toaststyle)
        if (!nuevo_subestado.codigo_estado) return toast.error('Código es requerido', this.state.toaststyle)
        if (!nuevo_subestado.tipo) return toast.error('tipo es requerido', this.state.toaststyle)
        nuevo_subestado.propietario = clienteconsultar.value
        nuevo_subestado.idparent = id_estado_crear_subestado
        this.setState({ loadingFormulario: true })
        return fetch(`${data.urlapi}/estadoscarga/create-or-edit-subestado`, {
            method: 'POST',
            body: JSON.stringify(nuevo_subestado),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${user.tokenSession}`
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                console.log(pros)
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                if (!nuevo_subestado._id) {
                    if (pros._id) subestados.push(pros)
                }
                return this.setState({ loadingFormulario: false, subestados, tipovisualizacion: 'estados' })
            })
            .catch(async error => {
                this.setState({ loadingFormulario: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    setBoleanPropertyEstado(name) {
        const { nuevo_estado } = this.state
        nuevo_estado[name] = nuevo_estado[name] === true ? false : true
        return this.setState({ nuevo_estado })
    }

    setBoleanProperty(name) {
        const { nuevo_subestado } = this.state
        nuevo_subestado[name] = nuevo_subestado[name] === true ? false : true
        return this.setState({ nuevo_subestado })
    }

    handlechangeSubestado(e) {
        const { name, value } = e.target
        const { nuevo_subestado } = this.state
        nuevo_subestado[name] = value
        console.log(nuevo_subestado)
        return this.setState({ nuevo_subestado })
    }
    
    // handleShowModal = () => {
    //     this.setState({ showModal: true });
    // };

    // handleCloseModal = () => {
    //     this.setState({ showModal: false });
    // };

    agregarCondicion(id) {
        const { acciones_subestado_actual } = this.state

        const i = acciones_subestado_actual.findIndex(e => e._id === id)

        if (i < 0) return false

        if (!acciones_subestado_actual[i].conditions) acciones_subestado_actual[i].conditions = []

        acciones_subestado_actual[i].conditions.push({
            accion: '',
            operador: '',
            campo: ''
        })

        return this.setState({ acciones_subestado_actual })
    }

    formularioCreacionSubestado() {
        const { loadingFormulario, loadingAcciones, eliminandoAcciones, acciones_subestado_actual, estados, nuevo_subestado, id_estado_crear_subestado } = this.state

        let titulo_estado = ''
        const iestado = estados.findIndex(es => es._id === id_estado_crear_subestado)
        if (iestado > -1) titulo_estado = estados[iestado].titulo

        const estilo_icono = {
            backgroundColor: "white",
            padding: 15,
            borderRadius: "50%",
            boxShadow: "0px 0px 17px -10px black",
            marginBottom: 10
        }

        const estilo_icono_check = {
            // display: "block",
            // margin: "0 auto",
            // marginTop: -21,
            // marginLeft: 61
        }

        const estilo_texto = {
            fontWeight: "bold",
            fontSize: 11,
            display: "inline",
            backgroundColor: "white",
            borderRadius: 5,
            padding: "5px 15px"
        }

        const propiedades_estado = [
            {
                slug: "notificaciones",
                titulo: "Notificaciones",
                items: [
                    {
                        slug: "email",
                        titulo: "Envía correo electrónico",
                        help_text: "",
                        icon: <AiOutlineMail size={50} style={estilo_icono} />
                    },
                    {
                        slug: "whatsapp",
                        titulo: "Envía whatsapp",
                        help_text: "",
                        icon: <BsWhatsapp size={50} style={estilo_icono} />
                    }
                ]
            },
            {
                slug: "trazabilidad",
                titulo: "Trazabilidad",
                items: [
                    {
                        slug: "allor_repeat",
                        titulo: "Es un punto de control",
                        help_text: "Esto permite repetir el estado múltiples veces y no cambia el estado actual del pedido",
                        icon: <BsArrowRepeat size={50} style={estilo_icono} />
                    },
                    {
                        slug: "enable_tracking_analyse",
                        titulo: "Activa el rastreo",
                        help_text: "Cargas B2B e Internacionales, inicia el análisis de ruta para el informe final",
                        icon: <MdSettingsInputAntenna size={50} style={estilo_icono} />
                    }
                ]
            },
            {
                slug: "control",
                titulo: "Control",
                items: [
                    {
                        slug: "publico",
                        titulo: "Público ",
                        help_text: "Clientes ven este estado en el tracking",
                        icon: <MdPublic size={50} style={estilo_icono} />
                    },
                    {
                        slug: "annulment",
                        titulo: "Estado de anulación",
                        help_text: "",
                        icon: <MdOutlineCancel size={50} style={estilo_icono} />
                    },
                    {
                        slug: "notificar_planel_flota",
                        titulo: "Requiere auditarse por Flota",
                        help_text: "Estos estados llegarán a una pantalla especial para una revisión manual",
                        icon: <AiOutlineFileSearch size={50} style={estilo_icono} />
                    },
                    {
                        slug: "finalizar",
                        titulo: "Estado final ",
                        help_text: "Considerado como finalizado el servicio, necesario para las métricas y los informes de trazabilidad",
                        icon: <BsSendCheckFill size={50} style={estilo_icono} />
                    },
                    {
                        slug: "inventario_entrada",
                        titulo: "Genera entrada de inventario",
                        help_text: "Genera un documento de ingreso de inventario",
                        icon: <MdOutlineInventory2 size={50} style={estilo_icono} />
                    },
                    {
                        slug: "inventario_salida",
                        titulo: "Genera salida de inventario",
                        help_text: "Genera un documento de salida de inventario",
                        icon: <MdInventory2 size={50} style={estilo_icono} />
                    },
                    {
                        slug: "count_as_tried",
                        titulo: "Contabilizar como intento",
                        help_text: "",
                        icon: <IoIosSend size={50} style={estilo_icono} />
                    },
                ]
            },

        ]

        if (loadingFormulario) return <Spinner animation='border' />

        return <div >
            <h6> {nuevo_subestado._id ? `Editar subestado ${nuevo_subestado.titulo} · ${titulo_estado}` : `Crear nuevo subestado dentro de ${titulo_estado}`} </h6>
            <button className="btn btn-sm btn-outline-danger mb-3" onClick={() => this.setState({ tipovisualizacion: 'estados', nuevo_subestado: {}, acciones_subestado_actual: [] })} >CANCELAR</button>
            <div className="row">
                <div className="col-md-5 mb-3">
                    <label className='form-control-label'>Título</label>
                    <input className='form-control' name="titulo" defaultValue={nuevo_subestado.titulo} onChange={this.handlechangeSubestado} />
                </div>

                <div className="col-md-2 mb-3">
                    <label className='form-control-label'>Código</label>
                    <input className='form-control' name="codigo_estado" defaultValue={nuevo_subestado.codigo_estado} onChange={this.handlechangeSubestado} />
                </div>


                <div className="col-md-2 mb-3">
                    <label className='form-control-label'>Tipo</label>
                    <select className='form-control' name="tipo" defaultValue={nuevo_subestado.tipo} onChange={this.handlechangeSubestado}>
                        <option value="">Selecciona</option>
                        <option value="web">Web</option>
                        <option value="movil">Móvil</option>
                    </select>
                </div>

                <div className="col-md-2 mb-3">
                    <label className='form-control-label'>Color</label>
                    <input className='form-control' type="color" name="color" defaultValue={nuevo_subestado.color} onChange={this.handlechangeSubestado} />
                </div>

                <div className="col-md-12 mb-2">
                    <Card className='mb-2 p-3' >
                        <h3 className='mb-0'><FiSettings /> Configuración</h3>
                        <p>Selecciona las opciones a continuación para activarlas</p>
                        <Tabs defaultActiveKey="notificaciones" >
                            {
                                propiedades_estado.map(prop => {



                                    return <Tab eventKey={prop.slug} title={prop.titulo} className='pt-4'>
                                        <Row>
                                            {
                                                prop.items.map((item, i) => {

                                                    const activo = nuevo_subestado[item.slug] === true
                                                    const overlay_item = <OverlayTrigger
                                                        key={item.help_text}
                                                        placement={'bottom'}
                                                        overlay={
                                                            <Tooltip id={item.help_text}>
                                                                {item.help_text}
                                                            </Tooltip>
                                                        }>
                                                        <BiSolidHelpCircle size={13} color='#a7a7a7' />
                                                    </OverlayTrigger>

                                                    return <Col md={3} key={`est-${i}-${prop.slug}`} className='hover p-3'>
                                                        <div className='text-center' onClick={() => this.setBoleanProperty(item.slug)}>
                                                            {item.icon}
                                                            <div></div>

                                                            <h6 className='mt-2' style={{ fontSize: 13 }}>{item.titulo} {item.help_text ? overlay_item : false}</h6>
                                                            {activo ? <h6 style={estilo_texto}><AiFillCheckCircle className='text-success' style={estilo_icono_check} /> ACTIVO</h6> : <h6 style={estilo_texto}><AiFillCheckCircle color='#cbcbcb' style={estilo_icono_check} /> INACTIVO</h6>}
                                                        </div>
                                                    </Col>
                                                })
                                            }
                                        </Row>
                                    </Tab>
                                })
                            }
                            <Tab eventKey="formato" className='pt-4 pb-4' title="Generar documento" >
                            <div>
                                <h5><i className="fas fa-file-alt"></i> Generar un documento</h5>
                                <p>Automatiza este estado para generar un documento con un formato pre establecido</p>
                                <SelectorFormatos titulo="Selecciona un formato" defaultValue={nuevo_subestado.generar_formato} full_payload={true} onChange={(data) => this.handlechangeSubestado({ target: { name: "generar_formato", value: data?._id } })} />
                            </div>
                            </Tab>
                            <Tab eventKey="tickets" className='pt-4 pb-4' title="Tickets de soporte" >
                            <div>
                                <h5><i className="fas fa-file-alt"></i> Tickets de soporte</h5>
                                <p>Puedes generar un ticket de soporte automáticamente al emitir este evento, recibirás tu y el administrador del sistema un email con los detalles</p>

                                <label className='form-control-label'>Selecciona la categoría</label>
                                <select className='form-control' type="number" name="emitir_ticket" defaultValue={nuevo_subestado.emitir_ticket} onChange={this.handlechangeSubestado} >
                                    <option value="">Selecciona</option>
                                    { types.map((type,i) => <option key={`ty-${i}`} value={type.value}>{type.label}</option>) }
                                </select>
                            </div>
                            </Tab>
                            <Tab eventKey="compromiso" className='pt-4 pb-4' title="Compromiso" >
                            <div>
                                <h5><i className="fas fa-file-alt"></i> Compromiso</h5>
                                <p>Puedes modificar automáticamente las fechas de compromiso de una orden al recibir este estado</p>

                                <label className='form-control-label'>Agregar días a la fecha de compromiso</label>
                                <input className='form-control' type="number" name="sumar_dias" defaultValue={nuevo_subestado.sumar_dias} onChange={this.handlechangeSubestado} />
                            </div>
                            </Tab>
                            <Tab eventKey="formulario" className='pt-4 pb-4' title="Formulario" >
                            <div className="col-md-12 mb-3">
                                <h5><i className="fas fa-file-alt"></i> Formulario App Móvil</h5>
                                <hr className='hr' />
                                {this.mostrarAccionesDisponibles(nuevo_subestado)}
                                {acciones_subestado_actual.length > 0 ? <div>
                                    <hr className='hr' />
                                    {
                                        acciones_subestado_actual.map((accion, i) => {
                                            return <div className='card mb-3'>
                                                <div className='p-3'>
                                                    <i className="fas fa-keyboard m-0 d-block" style={{ fontSize: 30 }}></i>
                                                    <span>{accion.tipo_accion}</span>
                                                    <h2 className='form-control-label d-block'>Título</h2>
                                                    <input className='form-control mb-2' name="titulo" defaultValue={accion.titulo} id={accion._id} onChange={this.handleChangeAccion} />
                                                    {accion.tipo_accion === 'selector' ? <div>
                                                        <h2 className='form-control-label d-block'>Opciones (Separa cada valor con coma)</h2>
                                                        <input className='form-control mb-2' name="valor" placeholder='Escribe tu opción y a continuación escribe ,' value={accion.valor} id={accion._id} onChange={this.handleChangeAccionArray} />
                                                        {
                                                            accion.opciones.map((opcion, i) => {
                                                                return <div key={`opcion-${i}`} className="p-2 mr-2" style={{ display: 'inline-block', borderRadius: 6, backgroundColor: '#535353' }}>
                                                                    <h4 className='text-light m-0'>{opcion} <button className='btn btn-sm btn-danger ml-3' onClick={() => this.removerOpcionAccion(accion._id, i)}>REMOVER</button></h4>
                                                                </div>
                                                            })
                                                        }
                                                        <div className='mb-3'></div>
                                                    </div> : false}

                                                    <h6 className='hover' onClick={() => this.obligatorio(accion._id)}>{accion.required === true ?
                                                        <i className="fas text-success fa-check-square"></i> :
                                                        <i className="far fa-square"></i>} Obligatorio</h6>

                                                    {
                                                        accion.conditions ? accion.conditions.map((con, pos) => {
                                                            return <div key={`condicion-${pos}`}>
                                                                <Card className='p-2 mb-2'>
                                                                    <Row>
                                                                        <Col md={1}>
                                                                            <label className='form-control-label d-block'>Borrar</label>
                                                                            <Button size='sm' variant='danger' onClick={() => this.eliminarCondicion(accion._id, pos)} ><BsFillTrashFill /> </Button>
                                                                        </Col>
                                                                        <Col md={2}>
                                                                            <label className='form-control-label d-block'>Accion</label>
                                                                            <select className='form-control' name="action" value={con.action} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} >
                                                                                <option value="">Acción </option>
                                                                                <option value="mostrar">Mostrar</option>
                                                                                <option value="ocultar">Ocultar</option>
                                                                            </select>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <label className='form-control-label d-block'>Campo</label>
                                                                            <select className='form-control' name="field" value={con.field} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} >
                                                                                <option value="">Selecciona un campo </option>
                                                                                {
                                                                                    acciones_subestado_actual.filter(ac => ac._id !== accion._id).map((option, iop) => {
                                                                                        return <option key={`id${accion._id}-${iop}`} value={option._id}>{option.titulo ? option.titulo : "Sin título aún"}</option>
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <label className='form-control-label d-block'>Operador de comparación</label>
                                                                            <select className='form-control' name="operator" value={con.operator} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} >
                                                                                <option value="">Selecciona un operador </option>
                                                                                <option value="=">Igual a {"(=)"}</option>
                                                                                <option value="!=">No igual a {"(!=)"}</option>
                                                                                <option value=">">Mayor que {"(>)"}</option>
                                                                                <option value="<">Menor que {"(<)"}</option>
                                                                                <option value=">=">Mayor o igual que {"(>=)"}</option>
                                                                                <option value="<=">Menor o igual que {"(<=)"}</option>
                                                                            </select>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <label className='form-control-label d-block'>Valor</label>
                                                                            <input className='form-control' name="value" value={con.value} id={accion._id} pos={pos} onChange={this.handleChangeCondicion} /></Col>
                                                                    </Row>
                                                                </Card>
                                                            </div>
                                                        }) : false
                                                    }

                                                    <div className='mb-3'><Button size="sm" variant="light" onClick={() => this.agregarCondicion(accion._id)} >AGREGAR CONDICION</Button></div>

                                                    {/* <div className='mb-3'><Button size="sm" variant="light" onClick={() => this.handleShowModal(accion._id)} >INTRODUCE AQUI TU ARCHIVO DE PRUEBA</Button></div> */}
                                                    <FirmaModal id={accion._id} token={this.state.user.tokenSession}/>
                                                    <ButtonGroup>
                                                        <button disabled={loadingAcciones} className='btn btn-sm btn-success' onClick={() => this.guardarCambiosAccion(accion._id)}>{loadingAcciones ? "ESPERE UN MOMENTO..." : "GUARDAR"}</button>
                                                        <button disabled={eliminandoAcciones} className='btn btn-sm btn-outline-danger' onClick={() => this.solicitarEliminarAccion(accion._id)}>{!eliminandoAcciones ? "ELIMINAR" : "ESPERE UN MOMENTO"}</button>
                                                    </ButtonGroup>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div> : <p className='mt-3'>No hay acciones para este sub estado</p>}
                            </div>
                            </Tab>
                        </Tabs>
                    </Card>
                </div>
                <div className="col-md-12 mb-3">
                    {
                        loadingFormulario === true ? <Spinner animation="border" /> : <button className='btn btn-success' onClick={() => this.crearSubEstado()}>{nuevo_subestado._id ? 'GUARDAR CAMBIOS' : 'CREAR'}</button>
                    }

                </div>
                        <hr className='hr' />
                {this.mostrarNotificacionesConfig()}

            </div>
        </div>
    }

   
    

    setVar(status, texto) {
        const { configuracion } = this.state
        const valora_actual = configuracion[status] ? `${configuracion[status]} ` : ''
        configuracion[status] = `${valora_actual}${texto}`
        this.setState({ configuracion })
    }

    opciones_plantillas(status) {
        return <div>
            <p>Click para agregar una etiqueta de contenido dinámico</p>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{nombre_cliente}}')} >{'nombre_cliente'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{nombre_direccion}}')} >{'nombre_direccion'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{referencia}}')} >{'referencia'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{ot}}')} >{'ot'}</Button>
            <Button size="sm" variant="outline-light" className='mr-3' onClick={() => this.setVar(status, '{{notas}}')} >{'notas'}</Button>
        </div>
    }

    handleChangeConfig(e) {
        const { configuracion } = this.state
        const { name, value } = e.target
        configuracion[name] = value
        return this.setState({ configuracion })
    }

    async guardarCambiosConfiguracion() {
        const { configuracion, nuevo_subestado } = this.state
        const loading = 'loadingConfiguracionNotificaciones'
        configuracion.idsubestado = nuevo_subestado._id
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/notificacion-subestado`, {
            method: 'PUT',
            body: JSON.stringify(configuracion),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ [loading]: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ [loading]: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                return this.setState({ [loading]: false })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ [loading]: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    mostrarNotificacionesConfig() {
        const { loadingConfiguracionNotificaciones, loadingPlantillasWhatsapp, configuracion, plantillas_ws } = this.state
        if (loadingConfiguracionNotificaciones === true) return <Spinner animation="border" />

        return <div className='col-md-12'>
            <Card className='shadow-sm rounded p-3 mb-3'>
                <Row>
                    <Col md={4} className='pr-0'>
                        {/* <div> */}
                        <Card className='d-block text-center h-100' style={{ backgroundColor: '#128c7e', borderRight: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}>
                            <img src={`${url_images}/Customer review.gif`} className='img-fluid w-50' />
                            <img src={`${url_images}/whatsapp-logo.png`} className='img-fluid text-white' style={{ width: 30 }} />
                            <h5 className='text-white text-center mb-0' style={{ fontWeight: "bold" }}>Tiempo real</h5>
                            <p className='text-white text-center'>Integra notificaciones</p>
                        </Card>
                        {/* </div> */}
                    </Col>

                    <Col md={8} className='pl-0'>
                        <Card className='p-0 border-0 h-100' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
                            <div className='d-flex justify-content-between align-items-center w-100 p-2' style={{ backgroundColor: '#128c7e' }}>
                                <div className=''><h5 className='text-white'><BsWhatsapp /><b> Whatsapp</b></h5></div>
                                <div className=' text-right'><HelperModal tipo="whatsapp" style="modal" /></div>
                            </div>

                            <div className='w-100 p-2 h-100' style={{ backgroundImage: `url(${url_images}/fondoWs.png)` }}>
                                <p style={{ fontWeight: 700 }}>Selecciona uno de los siguientes mensajes aprobados por whatsapp</p>
                                {/* <label className='form-control-label d-block' style={{fontWeight:700}}>Mensaje</label> */}
                                <select className='form-control mb-3 shadow-sm' name="whatsapp_message" value={configuracion.whatsapp_message} onChange={this.handleChangeConfig}>
                                    <option value="">{loadingPlantillasWhatsapp ? 'Cargando' : 'Selecciona'}</option>
                                    {
                                        plantillas_ws.map(ws => {
                                            return <option value={ws._id}>{ws.mensaje}</option>
                                        })
                                    }
                                </select>
                                <button className='btn btn-secondary shadow-sm w-100' onClick={() => this.guardarCambiosConfiguracion()}><b>GUARDAR CAMBIOS</b></button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Card>

            <Card className='p-3 text-white mb-3' bg="dark">
                <h5><i className="far fa-envelope"></i> Configuración de notificaciones</h5>
                <hr className='hr' />

                <h6>Asunto</h6>
                {this.opciones_plantillas('subject')}
                <input placeholder='Escribe el asunto de tu email de notificación' className='form-control mb-3 mt-4' name="subject" value={configuracion.subject} onChange={this.handleChangeConfig} />

                <h6>Mensaje</h6>
                {this.opciones_plantillas('message')}
                <input placeholder='Escribe el contenido del mensaje principal' className='form-control mb-3 mt-4' name="message" value={configuracion.message} onChange={this.handleChangeConfig} />

                <button className='btn btn-success' onClick={() => this.guardarCambiosConfiguracion()}>GUARDAR CAMBIOS</button>
            </Card>

        </div>
    }

    crearAccion(tipo_accion, idsubestado) {
        const { acciones_subestado_actual } = this.state
        const loading = 'loadingAcciones'
        this.setState({ [loading]: true })
        return fetch(`${data.urlapi}/estadoscarga/crear-accion`, {
            method: 'POST',
            body: JSON.stringify({ tipo_accion, idsubestado }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(pros => pros.json())
            .then(async pros => {
                console.log(pros)
                if (!pros) {
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ [loading]: false })
                } else if (pros.errorMessage) {
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ [loading]: false })
                }
                toast.success(`Operación realizada exitosamente`, this.state.toaststyle)
                if (pros._id) acciones_subestado_actual.push(pros)
                return this.setState({ [loading]: false, acciones_subestado_actual })
            })
            .catch(async error => {
                console.log(error)
                this.setState({ [loading]: false })
                return toast.error("No pudimos consultar la información, intente de nuevo recargando la página", this.state.toaststyle)
            })
    }

    mostrarDescripcionModulo(){
        return <div>
            <div className='p-5 bg-white text-primary'>
                <Row className="justify-content-md-center">
                    <Col md={6} className="text-center">
                        {/* <h4 className="mb-0">Presentamos</h4> */}
                        <h1><BsCardChecklist style={{ verticalAlign: "middle" }} /> <b>Protocólos y notificaciones</b></h1>
                        <Row className="justify-content-md-center">
                            <Col md={3}>
                                <FaRegEnvelope size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Envía emails</h5>
                            </Col>
                            <Col md={3}>
                                <FaWhatsapp size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Envía whatsapp</h5>
                            </Col>
                            <Col md={3}>
                                <IoIosAlert size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Identifica y audita</h5>
                            </Col>
                            <Col md={3}>
                                <TiFlowSwitch size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Ordena el flujo</h5>
                            </Col>
                        </Row>
                        <img src={`${url_images}/animations/Delivery Box.gif`} style={{ width: "50%" }} />
                        <p>En este módulo podrás configurar y ordenar los eventos relacionados a la trazabilidad de tus entregas, así como las notificaciones de cada una de ellas.</p>
                        <Button size="sm" className='mb-3' onClick={() => this.setState({ tipovisualizacion: 'nuevoestado', nuevo_estado: {} })}>CREAR NUEVO</Button>
                        {/* <Link to={`/${rutas.centinela_nuevo.slug}`}><Button size="sm" variant="primary">MIS REGLAS DE NEGOCIO</Button></Link> */}
                    </Col>
                </Row>
            </div>
        </div>
    }

    tablaEstados(estados, subestados) {
        const { eliminando, shoyEmptyText } = this.state

        if (estados.length < 1) return <div>
            <h2>No hay eventos en el protocolo asociados</h2>
        </div>

        return <div>
            <h4 className='mb-3'>{estados.length} Estados encontrados {subestados.length > 0 ? `y ${subestados.length} Sub-estados` : false }</h4>
            <Accordion >
                {estados.map((estado, iestado) => {

                    const subest = subestados.filter(sub => sub.idparent === estado._id)

                    return <Card className="p-0" >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={estado._id}>
                            <div className="pl-3 pr-3 pb-0">
                                <h6 className='mb-0'>{estado.titulo} · <b>{estado.codigo_estado}</b>
                                    <i style={{ color: estado.color }} className="fas fa-circle ml-3"></i>
                                    <Button size="sm" className='mb-2 ml-3 mr-3' style={{ fontSize: 10 }} onClick={() => this.editarEstado(estado)}>EDITAR</Button>
                                    {eliminando === true ? <Spinner animation='border' /> :
                                        <Button variant="outline-danger" size="sm" className=' mb-2' style={{ fontSize: 10, float: 'right', clear: 'both' }} onClick={() => this.solicitarEliminar(estado._id)}>ELIMINAR</Button>}
                                    {this.mostrarTiposServicioConcatenados(estado.tipos_servicio)}
                                </h6>
                            </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={estado._id}>
                            <Card.Body style={{ backgroundColor: '#f1f1f1' }}>
                                <Button size="sm" className='mb-2' style={{ fontSize: 11 }} onClick={() => this.nuevoSubEstado(estado._id)}>CREAR SUBESTADO</Button>
                                <hr className='hr' />
                                {
                                    subest.length > 0 ? subest.map((subestado, isub) => {
                                        return <div key={`subestado-${subestado._id}`}>
                                            <h6 className='mb-0' >{subestado.titulo} · <b>{subestado.codigo_estado}</b> <i style={{ color: subestado.color }} className="fas fa-circle"></i></h6>
                                            <Button size="sm" variant="link" className='p-0 mr-3 mb-2' style={{ fontSize: 10 }} onClick={() => this.editarSubEstado(subestado)}>EDITAR</Button>
                                            <Button size="sm" variant="link" className='p-0 text-danger mb-2' style={{ fontSize: 10 }} onClick={() => this.solicitarEliminarSubEstado(subestado._id)}>ELIMINAR</Button>
                                            {(isub + 1) === subest.length ? false : <hr className='hr' />}
                                        </div>
                                    }) : <h6>No hay sub estados</h6>
                                }
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                })}
            </Accordion>
        </div>

    }

    mostrarEstados() {

        const { estados, subestados, filtro_tipo_servicio, shoyEmptyText, tipos_servicio, loadingEstados } = this.state

        if (loadingEstados === true) return <PantallaCargando />

        const estados_mostrar = filtro_tipo_servicio ? estados.filter(e => {
            if (e.tipos_servicio) {
                if (Array.isArray(e.tipos_servicio) !== false) {
                    if (e.tipos_servicio.length > 0) {
                        return e.tipos_servicio.includes(filtro_tipo_servicio)
                    }
                }
            }
            return false
        }) : estados

        if(shoyEmptyText === true) return this.mostrarDescripcionModulo()

        return <Row>
            <Col md={3}>
                <Button size="sm" className='mb-3 w-100' onClick={() => this.setState({ tipovisualizacion: 'nuevoestado', nuevo_estado: {} })}>CREAR NUEVO ESTADO</Button>
            </Col>
            <Col md={9}>
                <Select
                    style={{ marginBottom: 10 }}
                    onChange={this.handleChangeSelectTipoServicioFiltro}
                    defaultValue={filtro_tipo_servicio}
                    options={tipos_servicio}
                    noOptionsMessage={() => 'Sin opciones'}
                    placeholder="Filtra por tipo de servicio..."
                    isClearable
                />
            </Col>
            <Col md={12}>
                <hr />
                {this.tablaEstados(estados_mostrar, subestados)}
            </Col>

        </Row>
    }

    mostrarVista() {
        const { tipovisualizacion, owner } = this.state
        if (tipovisualizacion === 'estados') return this.mostrarEstados()
        if (tipovisualizacion === 'nuevoestado') return this.formularioCreacion()
        if (tipovisualizacion === 'nuevosubestado') return this.formularioCreacionSubestado()
        if (tipovisualizacion === 'flujos') return <FlujosEstadosCarga owner={owner} />
    }

    render() {
        return this.mostrarVista()
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstadosCarga);