import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import DetailEstadosCarga from "../estados_carga/detail"
import { toast } from "react-toastify"
import { urlapi } from "../../lib/backend/data"

const EstadosRuta = (props) => {
    const {
        id_ruta
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ estados, setEstados ] = useState([])

    const obtenerDetalles = async () => {
        if(!id_ruta) return setLoading(false)
        return fetch(`${urlapi}/estadoscarga/public/route?id=${id_ruta}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){
                toast.error("Error al procesar la información")
              } else if(Array.isArray(pros) !== false){
                  setEstados(pros)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('Error al obtener los datos')
                return setLoading(false)
          })
    }

    useEffect(() => {
        obtenerDetalles()
    }, [])

    if(loading) return <div>
        <Skeleton height={20} className="mb-3" />
        <Skeleton height={20} className="mb-3" />
        <Skeleton height={20} className="mb-3" />
    </div>

    return <div>
        {estados.map((est) => {
            return <div>
                <DetailEstadosCarga size="sm" estado={est} />
            </div>
        })}
    </div>
}

export default EstadosRuta