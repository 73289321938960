import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { calcularPorcentaje } from '../../lib/helpers/helpers';
import { formatMes } from "../../lib/helpers/opcionesfecha"
import { StringMes, formatDateAno } from "../../lib/helpers/dates"



const GraficoOtiff = (props) => {
  const [ano, setAno] = useState(formatDateAno(new Date()))
  const [mes, setMes] = useState(formatMes(new Date()))


  const {
    data,
    typeView,
    ordenes
  } = props
  if (!data) return false

  const {
    otif,
    total_asignadas,
    total_completadas,
    total_rechazadas,
    total_anuladas
  } = data

  const porcentaje_retrasadas = calcularPorcentaje(total_asignadas, total_rechazadas)
  const porcentaje_completadas = calcularPorcentaje(total_asignadas, total_completadas)
  const porcentaje_pendientes = calcularPorcentaje(total_asignadas, (total_asignadas - total_completadas))
  const tipo_vista = typeView ? typeView : ''

  const validateNumber = (number) => {
    if (isNaN(parseInt(number))) return 0
    return Math.round(number)
  }

  const vistaBarras = (collapsar) => {

    const porcentaje = "100%"
    const fontSize = 12
    return <div>

      <Row>
        <Col md={collapsar === true ? 12 : false} className='mb-1'>
          <h6 className="" style={{ fontSize, fontWeight: 900, color: '#1B3665' }}>OTIF · {total_asignadas} Órdenes {otif}%</h6>
          <div style={{ width: porcentaje, display: "block" }}>
            <ProgressBar variant='warning' now={otif} />
          </div>
        </Col>
        <Col md={collapsar === true ? 12 : false} className='mb-1'>
          <h6 className="" style={{ fontSize, fontWeight: 900, color: '#1B3665' }}>Retrasadas · {total_rechazadas} Órdenes {validateNumber(porcentaje_retrasadas)}%</h6>
          <div style={{ width: porcentaje, display: "block" }}>
            <ProgressBar variant='danger' now={validateNumber(porcentaje_retrasadas)} />
          </div>
        </Col>
        <Col md={collapsar === true ? 12 : false} className='mb-1'>
          <h6 className="" style={{ fontSize, fontWeight: 900, color: '#1B3665' }}>Completada · {total_completadas} Órdenes {validateNumber(porcentaje_completadas)}%</h6>
          <div style={{ width: porcentaje, display: "block" }}>
            <ProgressBar variant='success' now={validateNumber(porcentaje_completadas)} />
          </div>
        </Col>
        <Col md={collapsar === true ? 12 : false} className='mb-1'>
          <h6 className="" style={{ fontSize, fontWeight: 900, color: '#1B3665' }}>En curso · {total_asignadas - total_completadas} Órdenes {validateNumber(porcentaje_pendientes)}</h6>
          <div style={{ width: porcentaje, display: "block" }}>
            <ProgressBar now={validateNumber(porcentaje_pendientes)} />
          </div>
        </Col>
        <Col md={collapsar === true ? 12 : false} className='mb-1'>
          <h6 className="" style={{ fontSize, fontWeight: 900, color: '#1B3665' }}>Anuladas · {total_anuladas} Órdenes 0%</h6>
          <div style={{ width: porcentaje, display: "block" }}>
            <ProgressBar variant='info' now={validateNumber(0)} />
          </div>
        </Col>
      </Row>
    </div>
  }

  const vistaSmall = () => {

    const porcentaje = "30%"
    const fontSize = 12
    return <div>


      {/* <Card className='p-3'> */}
      <Row className='rounded p-3 m-3' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
        <div className="container text-center">
          <div className="row row-cols-3">
            <div className="col py-2"><h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>OTIF · {total_asignadas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto", padding: 2 }}>
                <CircularProgressbar value={validateNumber(otif)} text={`${validateNumber(otif)}%`} />
              </div></div>
            <div className="col py-2"><h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>Retrasadas · {total_rechazadas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto" }}>
                <CircularProgressbar styles={buildStyles({
                  pathColor: 'red',
                  textColor: 'red'
                })} value={validateNumber(porcentaje_retrasadas)} text={`${validateNumber(porcentaje_retrasadas).toFixed(0)}%`} />
              </div></div>
            <div className="col py-2"><h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>Completada · {total_completadas} Órdenes { }</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto" }}>
                <CircularProgressbar styles={buildStyles({
                  pathColor: '#1cb03a',
                  textColor: '#1cb03a'
                })} value={validateNumber(porcentaje_completadas)} text={`${validateNumber(porcentaje_completadas).toFixed(0)}%`} />
              </div></div>
            <div className="col py-2"><h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>En curso · {total_asignadas - total_completadas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto" }}>
                <CircularProgressbar styles={buildStyles({
                })} value={validateNumber(porcentaje_pendientes)} text={`${validateNumber(porcentaje_pendientes).toFixed(0)}%`} />
              </div></div>
            <div className="col py-2"><h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>Anuladas · {total_anuladas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto" }}>
                <CircularProgressbar styles={buildStyles({
                })} value={validateNumber(0)} text={`${validateNumber(0).toFixed(0)}%`} />
              </div></div>
          </div>
        </div>
      </Row>
      {/* </Card> */}
    </div>
  }

  const vistaSimple = () => {

    const porcentaje = "30%"
    const fontSize = 12
    return <div className='p-3'>
      
      <Row className='rounded p-3 text-center' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
            <Col>
              <h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>OTIF · {total_asignadas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto", padding: 2 }}>
                <CircularProgressbar value={validateNumber(otif)} text={`${validateNumber(otif)}%`} />
              </div>
            </Col>
            <Col>
              <h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>Retrasadas · {total_rechazadas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto", padding: 2 }}>
                <CircularProgressbar styles={buildStyles({
                  pathColor: 'red',
                  textColor: 'red'
                })} value={validateNumber(porcentaje_retrasadas)} text={`${validateNumber(porcentaje_retrasadas).toFixed(0)}%`} />
              </div></Col>
            <Col>
              <h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>Completada · {total_completadas} Órdenes { }</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto", padding: 2 }}>
                <CircularProgressbar styles={buildStyles({
                  pathColor: '#1cb03a',
                  textColor: '#1cb03a'
                })} value={validateNumber(porcentaje_completadas)} text={`${validateNumber(porcentaje_completadas).toFixed(0)}%`} />
              </div></Col>
            <Col>
              <h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>En curso · {total_asignadas - total_completadas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto", padding: 2 }}>
                <CircularProgressbar styles={buildStyles({
                })} value={validateNumber(porcentaje_pendientes)} text={`${validateNumber(porcentaje_pendientes).toFixed(0)}%`} />
              </div></Col>
            <Col>
              <h6 className="" style={{ fontSize: 14, color: '#1B3665', fontWeight: 900 }}>Anuladas · {total_anuladas} Órdenes</h6>
              <div style={{ width: '50%', display: "block", margin: "0 auto", padding: 2 }}>
                <CircularProgressbar styles={buildStyles({
                })} value={validateNumber(0)} text={`${validateNumber(0).toFixed(0)}%`} />
              </div>
            </Col>
      </Row>
    </div>
  }



  const vistaNormal = () => {

    const fontSize = 15
    return <div>

      <Card className='border-0'>
          <Row className="row align-items-center">


            <Col md={4} className="justify-content-center  mb-3 align-items-center" >
              <Card className='shadow-sm rounded p-3' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>   
              <Row>
                <Col xs={8}>
                <div className="text-center " style={{ fontWeight: 900, fontSize, color: '#1B3665' }}>OTIF</div>
                <div className="text-center" style={{ fontSize: 17 }}>{total_asignadas} Órdenes</div>
                </Col>
                <Col xs={4}>
                <CircularProgressbar value={validateNumber(otif)} text={`${validateNumber(otif)}%`} styles={{
                  text: {
                    fontSize: 25,
                  },
                }} />
                </Col>
              </Row>
              </Card>
            </Col>

            <Col md={4} className="justify-content-center  mb-3 align-items-center" >
            <Card className='shadow-sm rounded p-3' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>
              <Row>
                <Col xs={8}>
                <div className="text-center " style={{ fontWeight: 900, fontSize, color: '#1B3665' }}>{total_rechazadas} Órdenes</div>
                <div className="text-center" style={{ fontSize: 17 }}>Retrasadas</div>
                </Col>
                <Col xs={4}>
                <CircularProgressbar styles={buildStyles({
                  pathColor: 'red',
                  textColor: 'red',
                  textSize: 25,
                })} value={validateNumber(porcentaje_retrasadas)} text={`${validateNumber(porcentaje_retrasadas).toFixed(0)}%`} />
                </Col>
              </Row>
              </Card>
            </Col>

            <Col md={4} className="justify-content-center  mb-3 align-items-center" >
              <Card className='shadow-sm rounded p-3' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>  
              <Row>
                <Col xs={8}>
                <div className="text-center " style={{ fontWeight: 900, fontSize, color: '#1B3665' }}>{total_completadas} Órdenes</div>
                <div className="text-center" style={{ fontSize: 17 }}>completadas</div>
                </Col>
                <Col xs={4}>
                <CircularProgressbar styles={buildStyles({
                  pathColor: '#1cb03a',
                  textColor: '#1cb03a',
                  textSize: 25,
                })} value={validateNumber(porcentaje_completadas)} text={`${validateNumber(porcentaje_completadas).toFixed(0)}%`} />
                </Col>
              </Row>
              </Card>
            </Col>

            <Col md={4} className="justify-content-center  mb-3 align-items-center" >
              <Card className='shadow-sm rounded p-3' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>  
              <Row>
                <Col xs={8}>
                <div className="text-center " style={{ fontWeight: 900, fontSize, color: '#1B3665' }}>{total_asignadas - total_completadas} Órdenes</div>
                <div className="text-center" style={{ fontSize: 17 }}>En curso</div>
                </Col>
                <Col xs={4}>
                <CircularProgressbar styles={buildStyles({
                  textSize: 25,
                })} value={validateNumber(porcentaje_pendientes)} text={`${validateNumber(porcentaje_pendientes).toFixed(0)}%`} />
                </Col>
              </Row>
              </Card>
            </Col>

            <Col md={4} className="justify-content-center  mb-3 align-items-center" >
              <Card className='shadow-sm rounded p-3' style={{ border: 'solid', borderColor: '#d6d6d6', borderWidth: 4 }}>  
              <Row>
                <Col xs={8}>
                <div className="text-center " style={{ fontWeight: 900, fontSize, color: '#1B3665' }}>{total_anuladas} Órdenes</div>
                <div className="text-center" style={{ fontSize: 17 }}>Anulados</div>                  
                </Col>
                <Col xs={4}>
                <CircularProgressbar styles={buildStyles({
                  textSize: 25,
                })} value={validateNumber(0)} text={`${validateNumber(0).toFixed(0)}%`} />
                </Col>
              </Row>
              </Card>
            </Col>

          </Row>
      </Card>
    </div>
  }

  switch (tipo_vista) {
    case "barras":
      return vistaBarras()
    case "small":
      return vistaSmall()
    case "barras_collapse":
      return vistaBarras(true)
    case "simple":
      return vistaSimple()
    default:
      return vistaNormal()
  }



}

export default GraficoOtiff
