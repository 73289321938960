import { url_images } from "../../lib/global/data";

export const canales = {
    "mercadolibre": {
        title: "Mercado Libre",
        logo: `${url_images}/integraciones/canales/mercado-libre.png`
    },
    "shopify": {
        title: "Shopify",
        logo: `${url_images}/integraciones/canales/shopify.png`
    },
    "woocommerce": {
        title: "WooCommerce",
        logo: `${url_images}/integraciones/canales/woocommerce.png`
    },
    "prestashop": {
        title: "Prestashop",
        logo: `${url_images}/integraciones/canales/prestashop.png`
    },
    "dafiti": {
        title: "Dafiti",
        logo: `${url_images}/integraciones/canales/dafiti.png`
    },
    "falabella": {
        title: "Falabella",
        logo: `${url_images}/integraciones/canales/falabella.png`
    },
    "paris": {
        title: "Paris",
        logo: `${url_images}/integraciones/canales/paris.png`
    },
    "ripley": {
        title: "Ripley",
        logo: `${url_images}/integraciones/canales/ripley.png`
    },
    "walmart": {
        title: "Walmart",
        logo: `${url_images}/integraciones/canales/walmart.png`
    },
    "web": {
        title: "Web",
        logo: ''
    },
}