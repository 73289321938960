import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import '../../components/Login/login.css';
import { url_images } from "../../lib/global/data";
import '../../subComponents/facturacion/invitacion.css';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import CargandoGeneral from '../general/cargando_general';

const InvitacionFacturacion = () => {
    const [isUnrolled, setIsUnrolled] = useState(false);
    const [ invitacion, setInvitacion ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const route = useParams()

    const obtenerDatos = () => {
        const { id } = route
        if(!id) return setLoading(false)
        
        return fetch(`${urlapi}/cuentas-facturacion/check?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
            }
        })
        .then(res => res.json())
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setInvitacion(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }


    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleUnroll = () => {
        setIsUnrolled(true);
    };

    const mostrarFases = () => {
        if(loading)  return <CargandoGeneral />
        if(!invitacion) return <div>
            <img src={`${url_images}/animations/6342141.gif`} style={{ maxHeight: 250 }} />
            <h3 className='mb-0'>No es posible cargar esta invitación</h3>
            <p className='mb-0'>Esta invitación ya no se puede visualizar o no existe</p>
        </div>
        if(!isUnrolled){
            return <div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                            <img alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200, paddingBottom: 20 }} />
                        </div>
                        <hr/>
                        <p style={{ fontSize: '16px', marginBottom: '20px' }}>
                            <strong style={{fontWeight: 900 }}>{invitacion.empresa?.razon_social}</strong> te ha enviado una invitacion.
                        </p>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <Button variant="warning" size="lg" onClick={handleUnroll} style={{fontWeight:700}}>
                        ABRIR INVITACIÓN
                    </Button>
                </div>
            </div>
        }

        return <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
                    <img alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200, paddingBottom: 20 }} />
                    <h4 style={{ marginBottom: '20px', padding: 10, fontWeight: 700 }}>{invitacion.empresa?.razon_social} solicita que utilizes <b className='text-warning'>sin costo</b> nuestra plataforma</h4>
                </div>
                <hr/>
                {/* <p style={{ fontSize: '20px', marginBottom: '20px', backgroundColor: 'rgba(247, 146, 30, 0.8)', padding: '10px', borderRadius: '5px' }}>
                    <strong style={{ fontWeight: 900 }}>NO PAGAS NADA.</strong>
                </p> */}
                <p style={{ fontSize: '16px', marginBottom: '20px', backgroundColor: '#e9ecef', padding: '10px', borderRadius: '5px' }}>
                <h4><b>¿Qué es esto?</b></h4>
                    Las cuentas de facturación son útiles para las empresas que forman parte de nuestro sistema de administración de flotas y cargas. Les permiten compartir recursos y funciones dentro del sistema contigo, podrás acceder a estas funciones dentro de nuestro sistema <b className='text-warning' style={{ fontWeight: "bold" }} >sin costo</b> para ti, la cuenta de facturación rastreará estos recursos y los cobrará a <b>{invitacion.empresa?.razon_social}</b>, solo necesitas crear una cuenta de forma gratuita en el link debajo.
                </p>
                <h4><b>¿Cómo puedo aceptarla?</b></h4>
                <p>Únicamente deberás crear una cuenta y una vez dentro podrás aceptar la invitación</p>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to="/registro">
                        <Button variant="primary" size="sm" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FaUserPlus style={{ marginRight: '10px' }} /> CREAR MI CUENTA GRATUITA
                        </Button>
                    </Link>
                </div>
        </div>
    }

    const mostrarInformacion = () => {
        return <div>
                <Row style={{ textAlign: 'center', maxWidth: '800px', backgroundColor: '#ffffff', padding: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
                    <Col>
                        {mostrarFases()}   
                    </Col>
                </Row>
        </div>
    }

    return (
        <Container fluid className='login-form'>
            {mostrarInformacion()}
        </Container>
    );
}

export default InvitacionFacturacion