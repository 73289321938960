import { Button, Card, Modal } from "react-bootstrap"
import { BiSolidChevronRight } from "react-icons/bi"
import SelectorReglas from "./selector"
import { useState } from "react"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { estilo_last_mile } from "../../lib/global/styles"
import { url_images } from "../../lib/global/data"
import { SiAdguard } from "react-icons/si"

const ModalSelectorReglas = (props) => {
    const [ showModal, setShowModal ] = useState(false)

    const handleClose = () => {
        return setShowModal(false)
    }

    const onChange = (data) => {
        if(props.onChange) props.onChange(data)
    }

    return <div>          
    <Card className="p-2 hover text-center" onClick={() => setShowModal(true)}>
    <h4 className="mb-0"><SiAdguard style={{ verticalAlign: "bottom" }} /> ASIGNAR CENTINELA</h4>
    </Card>            
    <Modal show={showModal} size="lg" onHide={handleClose} centered >
        <Modal.Header closeButton>
        <Modal.Title><b>Asignar centinela</b></Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-0'>
            <img src={`${url_images}/animations/Programming Skills.gif`} style={{ marginTop: -30, width: 100, maxWidth: "80%" }}/>
            <p>El centinela es un sistema avanzado de autoría automatizada, es básicamente un asistente virtual que velará porque los protocolos de tu empresa se cumplan en tiempo y forma <Link target="_blank" to={`/${rutas.centinela.slug}`}><b style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}>VER DETALLES</b></Link> </p>
        <SelectorReglas onChange={(data) => onChange(data)} />
        </Modal.Body>
    </Modal>
</div>
}

export default ModalSelectorReglas