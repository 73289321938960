import { toast } from "react-toastify"
import { urlapi } from "../../lib/backend/data"
import { useState } from "react"
import axios from "axios"
import {  Spinner } from "react-bootstrap"
import { Button as Boton } from '@mui/material';
import { FaFileUpload } from "react-icons/fa"
// import { useSelector } from "react-redux"

const CargaImagenes = (props) => {
    const [uploading, setUploading] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ uploadPorcentaje, setUploadPorcentaje ] = useState(0)
    // const session = useSelector(state => state.miusuario)
    // const token = session.tokenSession

    const obtenerFirmaUrl = async (name,type)=>{
        return fetch(`${urlapi}/medios/presigne-url-public/tracking`,{
            method:'POST',
            body: JSON.stringify({
                name, type
            }),
            headers: {
                'Content-Type':'application/json'
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res.url){
                return res.url
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    const handleSubmit = async (files) => {
        console.log(files[0])
        const f = files[0];
        const name = `${Date.now()}-${f.name}`
        const type = f.type
        const url_firmada = await obtenerFirmaUrl(name, type)
        if(!url_firmada) return false
        setUploading(true)
        setLoading(true)
        try {
            const result = await axios.put(url_firmada, f, {
                headers: {
                  'Content-Type': f.type
                },
                onUploadProgress: (e) => {
                    const { loaded, total } = e
                    let porcentaje = Math.floor(  (loaded*100) / total )
                    setUploadPorcentaje(porcentaje)
                }
                })
                const url_publica = result.config.url.split('?')[0]
                if(props.onUploaded) props.onUploaded(url_publica, f.name)
                setLoading(false)
                return setUploading(false)
        } catch (error) {
            setUploading(false)
            setLoading(false)
            console.log(error.message)
            toast.error("No se pudo cargar este archivo")
            }
        }

        const handleChangeNuevoProducto = async (e) => {
            const files = e.target.files
            if(files.length > 0){
                await handleSubmit(files)
            }
        }

    return <div>
        {/* <input className='form-control mb-3' type="file" name="imagen" onChange={handleChangeNuevoProducto} /> */}
        <Boton disabled={loading} component="label" color="inherit" variant="contained" startIcon={<FaFileUpload />}>
                SUBIR ARCHIVO ADJUNTO
                <input
                    type="file" 
                    hidden
                    name="file" 
                    id="file" 
                    className="form-control mb-2"
                    onChange={handleChangeNuevoProducto} 
                    placeholder="Archivo" 
                />
                </Boton>
        {loading > 0 ? <Spinner animation="border" size="sm" /> : false }
    </div>
}

export default CargaImagenes