import { canales } from "../ordenes/data"
import Select from 'react-select'

const SelectorCanales = (props) => {
    const {
        defaultValue,
        isMulti
    } = props
    const multi = isMulti === true 

    const opcionesCanales = Object.keys(canales).map(e => ({ value: e, label: canales[e].title }))

    const getDefaultValue = () => {
        if(!defaultValue){
            return null
        } else if(Array.isArray(defaultValue)){
            return opcionesCanales.filter(e => defaultValue.map(e => e.value).includes(e.value))
        }
        return opcionesCanales.find(e => e.value === defaultValue)
    }

    const optionsDefaultChannels = getDefaultValue()

    const handleChange = (e) => {
        if(props.onChange) props.onChange(e)
    }

    return <div>
    <Select className='mb-3' isMulti={multi} defaultValue={optionsDefaultChannels} options={opcionesCanales} placeholder="Seleccione" onChange={(data) => handleChange(data)} isClearable />
    </div>
}

export default SelectorCanales