import { DateTime } from "luxon";
import { useEffect, useState } from "react"
import { Accordion, Badge, Button, Card, Col, Form, Row } from "react-bootstrap"
import { FaBoxOpen, FaCircle, FaCircleCheck, FaRegCircle, FaRegClock } from "react-icons/fa6"
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { TiDelete } from "react-icons/ti";
import { toast } from "react-toastify";
import { maps_key, urlapi } from "../../../../lib/backend/data";
import { useSelector } from "react-redux";
import SelectorZonaTremus from "../../../geodata/selector_zona_tremus";
import { FaMoneyBill } from "react-icons/fa";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { url_images } from "../../../../lib/global/data";

const segmentoHorarioEjemplo = '[{"weekday":1,"from":"10:00","to":"19:00","days_out_of_time":2,"days_in_time":1},{"weekday":2,"from":"10:00","to":"19:00","days_out_of_time":2,"days_in_time":1},{"weekday":3,"from":"10:00","to":"19:00","days_out_of_time":2,"days_in_time":1},{"weekday":4,"from":"10:00","to":"19:00","days_out_of_time":1,"days_in_time":1},{"weekday":5,"from":"10:00","to":"19:00","days_out_of_time":1,"days_in_time":1},{"weekday":6,"from":"10:00","to":"15:00","days_out_of_time":2,"days_in_time":2},{"weekday":7,"from":"10:00","to":"15:00","days_out_of_time":1,"days_in_time":1}]'
const imageArray = [
    { id: "flota", src: '', selected: false, title: "Flota propia" },
    { id: "starken", src: `${url_images}/courriers/starkenlogo.png`, selected: false, title: "Starken" },
    { id: "bluexpress", src: `${url_images}/courriers/logo-blue.png`, selected: false, title: "Blue Express" },
    { id: "correos-chile", src: `${url_images}/courriers/correos-chile.png`, selected: false, title: "Correos de Chile" },
    { id: "chilexpress", src: `${url_images}/courriers/logo-chilexpress.png`, selected: false, title: "Chilexpress" },
    { id: "delpa", src: `${url_images}/courriers/delpa-logo-invoice.png`, selected: false, title: "Delpa" },
]

const EdicionTarifa = (props) => {
    const {
        registro,
        zonas
    } = props
    const [ tarifa, setTarifa ] = useState(registro)
    const [images, setImages] = useState(imageArray.map(e => ({ ...e, selected: e.id === registro?.provider })));
    const [ loading, setLoading ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    useEffect(() => {
        setTarifa(registro)
    }, [ registro ])

    const handleChangePriceSegment = (e,o) => {
        console.log({ o })
        const { name, value } = e.target
        return setTarifa(prev => {
            let actual = {...prev}
            actual.price_segments[o][name] = value
            return {...{}, ...actual}
        })
    }
    const onChangeGeofences = (geofences) => {
        return setTarifa(prev => {
            let actual = {...prev}
            actual.geofences = geofences
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }
    
    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${urlapi}/pricing/lotes/grupos`, {
          method: 'PUT',
          body: JSON.stringify(tarifa),
          headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
        })
          .then((res) => {
            if (res.status === 401) return window.location = "/login"
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Guardado exitosamente")
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const eliminarRegistro = async () => {
        setLoading(true)
        return fetch(`${urlapi}/pricing/lotes/grupos?id=${registro?._id}`, {
          method: 'DELETE',
          headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
        })
          .then((res) => {
            if (res.status === 401) return window.location = "/login"
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(res._id){
                if(props.onDelete) props.onDelete(res?._id)
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }
    
    const handleChangeDeliverySegment = (e,o) => {
        console.log({ o })
        const { name, value } = e.target
        return setTarifa(prev => {
            let actual = {...prev}
            actual.delivery_segments[o][name] = value
            return {...{}, ...actual}
        })
    }

    const numeroADia = numero => {
        const dias = ["", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado","Domingo"];
        return dias[numero];
    };

    const eliminar = (pos) => {
        return setTarifa(prev => {
            let actual = {...prev}
            actual.price_segments.splice(pos,1)
            return {...{}, ...actual}
        })
    }

    const agregarSegmentoPrecio = () => {
        return setTarifa(prev => {
            let actual = {...prev}
            if(!actual.price_segments) actual.price_segments = []
            actual.price_segments.unshift({
                price_from: 0,
                price_to:0,
                rate: 0
            }) 
            return {...{}, ...actual}

        })
    }
    const mostrarSegmentos = () => {
        if(!tarifa.price_segments) return false
        if(Array.isArray(tarifa.price_segments) !== true) return false
        return <div>
            <Button size="sm" className="mb-3 mt-2" variant="outline-primary" onClick={() => agregarSegmentoPrecio()} >AGREGAR SEGMENTO</Button>
            {
                tarifa.price_segments.map((price,pos) => {
                    return <div>
                        <Card className="p-2 mb-2 border shadow">
                        <Row>
                            <Col xs={4} >
                                <label className="form-control-label">Monto del carrito desde</label>
                                <input className="form-control" type="number" min="0" name="price_from" value={price.price_from} onChange={(e) => handleChangePriceSegment(e, pos)} />
                            </Col>
                            <Col xs={4} >
                                <label className="form-control-label">Monto del carrito hasta</label>
                                <input className="form-control" type="number" min="0" name="price_to" value={price.price_to} onChange={(e) => handleChangePriceSegment(e, pos)} />
                            </Col>
                            <Col xs={4} >
                                <label className="form-control-label">Tarifa del envío</label>
                                <input className="form-control" type="number" min="0" name="rate" value={price.rate} onChange={(e) => handleChangePriceSegment(e, pos)} />
                            </Col>
                        </Row>
                        <Button size="sm"  variant="text" className="text-danger mt-2" onClick={() => eliminar(pos)}><TiDelete size={25} style={{ verticalAlign: "middle" }} /> ELIMINAR</Button>
                        </Card>
                    </div>
                })
            }
        </div>
    }

    const handleCheck = (e) => {
        const valor = e.target.checked
        console.log({ valor })
        return setTarifa(prev => {
            let actual = {...prev}
            actual.closed = valor
            console.log(actual)
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }
    
    const mostrarSegmentosSemana = () => {
        if(!tarifa.delivery_segments) return false
        if(Array.isArray(tarifa.delivery_segments) !== true) return false
        return <div>
            {tarifa.delivery_segments.length < 1 ? <div>
                <h5>Sin registros</h5>
                <Button size="sm" onClick={() => {
                    const segmentos = JSON.parse(segmentoHorarioEjemplo)
                    setTarifa(prev => {
                        let actual = {...prev}
                        actual.delivery_segments = segmentos
                        return {...{}, ...actual}
                    })
                }} >GENERAR SEGMENTOS DE HORARIO</Button>
            </div> : false }
            <Row>
            {
                tarifa.delivery_segments.map((price,pos) => {
                    return <Col md={6}>
                        <Card className="p-2 mb-4 border shadow">
                            <h4>{numeroADia(price.weekday)}</h4>
                        <Row>
                            <Col xs={6} >
                                <label className="form-control-label">Desde</label>
                                <input className="form-control" type="time" name="from" value={price.from} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                            <Col xs={6} >
                                <label className="form-control-label">Hasta</label>
                                <input className="form-control" type="time" name="to" value={price.to} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                            <Col xs={6} >
                                <label className="form-control-label">Días tránsito dentro del horario</label>
                                <input className="form-control" type="number" name="days_in_time" value={price.days_in_time} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                            <Col xs={6} >
                                <label className="form-control-label">Días de tránsito fuera del horario</label>
                                <input className="form-control" type="number" name="days_out_of_time" value={price.days_out_of_time} onChange={(e) => handleChangeDeliverySegment(e, pos)} />
                            </Col>
                        </Row>
                        </Card>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    const tiempoATextoEntendible = (time, hoy) => {
        if(time > 1){
            const eta = hoy.plus({ day: time }).setLocale('es').toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' });
            return `en ${time} días · ${eta}`
        }
        const eta_hoy = hoy.plus({ day: time }).setLocale('es').toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' })
        return `Mañana ${eta_hoy}`
    }
    

    const mostrarTarifaActual = () => {
        const fecha     = DateTime.now().setZone('America/Santiago')
        const tiempo    = fecha.toSQLTime()

        let texto_      = 'Para visualizar la vista previa debe tener horarios configurados'
        if(tarifa.delivery_segments){
            if(tarifa.delivery_segments.length > 0){
                const encontrar_segmento = tarifa.delivery_segments.findIndex(t => t.weekday === fecha.weekday)
                console.log({ encontrar_segmento, encontrar_segmento, id: tarifa._id.toString() })
                if(encontrar_segmento > -1){
                    let segmento    = tarifa.delivery_segments[encontrar_segmento]
                    if(tiempo >= segmento.from && tiempo <= segmento.to){
                        // EN TIEMPO
                        texto_ = `Llega ${tiempoATextoEntendible(segmento.days_in_time, fecha)}`
                    } else {
                        texto_ = `Llega ${tiempoATextoEntendible(segmento.days_out_of_time, fecha)}`
                    }
                }
            }
        }
            
        return texto_
    }

    const buscar_zonas = Array.isArray(tarifa.geofences) ? tarifa.geofences : []

    const handleSelectImage = (id) => {
        setTarifa(prev => {
            let actual = {...prev}
            actual.provider = id
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
        setImages(prev => {
            let actual = [...prev]
            const updatedImages = actual.map((image) =>
                image.id === id
                  ? { ...image, selected: true }
                  : { ...image, selected: false }
              );
            return [...updatedImages]
        });
      };


    const handleChange = (e) => {
        const { name, value } = e.target
        return setTarifa(prev => {
            let actual = {...prev}
            actual[name] = value
            if(name === "profit_type") actual.profit = 0
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }

    return <div key={tarifa?._id}>
        <Row>
            <Col>
                <label className="form-control-label bold">Título del método de envío</label>
                <input className="form-control" placeholder="Título" value={tarifa.title} name="title" onChange={handleChange} />
            </Col>
            <Col>
                <label className="form-control-label bold">Tarifa</label>
                <input className="form-control" type="number" placeholder="Tarifa" value={tarifa.value} name="value" onChange={handleChange} />
            </Col>
            <Col>
                <label className="form-control-label bold">Impuesto en %</label>
                <input className="form-control" type="number" placeholder="Impuesto en %" value={tarifa.tax} name="tax" onChange={handleChange} />
            </Col>
        </Row>
        <ReactGoogleAutocomplete
                    className="form-control mb-3"
                    style={{ border: "1px solid #e2e8f0", padding:9, borderRadius: 5, width: "100%", marginTop: 10, marginBottom: 10 }}
                    defaultValue={tarifa.origen?.direccion}
                    options={{
                        types: ["address"],
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          const geo_data = {
                              lat, 
                              lng,
                              direccion: place.formatted_address,
                              region,
                              comuna,
                              geodata: place
                          }

                          return setTarifa(prev => {
                                let actual = {...prev}
                                actual.origen = geo_data
                                return {...{}, ...actual}
                          })
                    }}
                    />
        <h4 className="mt-3"><b><FaMoneyBill /> Profit</b></h4>
        <Row>
            <Col xs={3}>
            <label className="form-control-label bold">Tipo</label>
            <select className="form-control" name="profit_type" value={tarifa.profit_type} onChange={handleChange}>
                <option value="">Ninguno</option>
                <option value="percentage">Porcentaje</option>
                <option value="fixed">Fijo</option>
            </select>
            </Col>
            <Col>
            <label className="form-control-label bold">Valor</label>
            <input className="form-control" type="number" placeholder="Profit" value={tarifa.profit} name="profit" onChange={handleChange} />
            </Col>
        </Row>
        {/* {zonas.filter(zo => buscar_zonas.includes(zo._id)).map(zo => <Badge variant="dark" style={{ textTransform: "uppercase" }} className="mr-1 mb-1 mt-1">{zo.name}</Badge>)} */}
        <Form.Group className="mb-3 mt-3" controlId={tarifa._id}>
                                    <Form.Check type="switch" label="Deshabilitar este método de envío" defaultChecked={tarifa.closed === true} onChange={handleCheck} />
        </Form.Group>
        <SelectorZonaTremus title="Zona de cobertura" key={registro._id} condicion={{}} hideButton={true} multi={true} onChange={(data) => onChangeGeofences(data)} defaultValue={buscar_zonas} />
        <Row>
            <Col md={12} className="mt-3">
            <Accordion>

            <Card key={`medidas`} className="p-0" >
            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`medidas`}>
            <div className="hover pl-3 pr-3 pb-0"><h6 className="m-0"><FaBoxOpen /> Medidas</h6></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`medidas`}><div className="p-3">
                <h5>Medida de tu caja</h5>
                    <Row>
                        <Col>
                            <label className="form-control-label d-block">Ancho</label>
                            <input className="form-control" type="number" placeholder="ancho (cm)" value={tarifa.ancho} name="ancho" onChange={handleChange} />
                        </Col>
                        <Col>
                            <label className="form-control-label d-block">Alto</label>
                            <input className="form-control" type="number" placeholder="alto (cm)" value={tarifa.alto} name="alto" onChange={handleChange} />
                        </Col>
                        <Col>
                            <label className="form-control-label d-block">Largo</label>
                            <input className="form-control" type="number" placeholder="largo (cm)" value={tarifa.largo} name="largo" onChange={handleChange} />
                        </Col>
                    </Row>
                </div></Accordion.Collapse>
            </Card>

            <Card key={`segmento`} className="p-0" >
            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`segmento`}>
            <div className="hover pl-3 pr-3 pb-0"><h6 className="m-0"><RiMoneyDollarCircleFill /> Segmentos de precio</h6></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`segmento`}><div className="p-3">{mostrarSegmentos()}</div></Accordion.Collapse>
            </Card>

            <Card key={`segmento-precio`} className="p-0" >
            <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`segmento-precio`}>
            <div className="hover pl-3 pr-3 pb-0"><h6 className="m-0"><FaRegClock /> Segmentos de horario</h6></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`segmento-precio`}>
            <div >
            <div className="p-3 text-center">
                <p className="mb-0" style={{ fontSize: 11 }}><b style={{ fontWeight: "bold" }}>Este es un ejemplo de como aparece en este momento el mensaje del ETA</b></p>
                <h6 className="mb-0"><b style={{ fontWeight: "bold" }}>{mostrarTarifaActual()}</b></h6>
            </div>
            <div className="p-3">{mostrarSegmentosSemana()}</div>
            </div>
            </Accordion.Collapse>
            </Card>
            </Accordion>
            </Col>
        </Row>
        
        <Card className="p-3">
        <Row className="mt-3 mb-3">
            {
                images.map((image,ir) => {
                    return <Col key={`im-${ir}`} md={2} onClick={() => handleSelectImage(image.id)} className="text-center hover">
                                        <div style={{ height: 100 ,               display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center' }}>
                                        {image.src ? <img style={{ width: "80%" }}  src={image.src} alt={`Image ${image.id}`} /> : <p>{image.title}</p>}
                                        </div>
                                        { image.selected ? <FaCircleCheck color="green" style={{ display: "inline-block" }} /> : <FaRegCircle color="black" style={{ display: "inline-block" }} /> }

                    </Col>
                })
            }
        </Row>
        </Card>
        <Button size="sm" variant="success" className="mt-3 mr-3" onClick={() => guardarCambios()} disabled={loading} > {loading ? "GUARDANDO..." : "GUARDAR"}</Button>
        <Button size="sm" variant="outline-danger" className="mt-3 mr-3" onClick={() => eliminarRegistro()} disabled={loading} > {loading ? "ELIMINANDO..." : "ELIMINAR"}</Button>

    </div>
}

export default EdicionTarifa