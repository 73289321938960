import ReglasCotizacion from "../../subComponents/proveedores_transporte/cotizacion/reglas_cotizacion"

const { Row, Col, Button } = require("react-bootstrap")
const { BsCardChecklist } = require("react-icons/bs")
const { default_proveedores } = require("../../subComponents/proveedores_transporte/data")
const { url_images } = require("../../lib/global/data")

const ConfiguracionCourriers = () => {
    return <div className="fluid">
        <div className='p-5 bg-white text-primary'>
                            <Row className="justify-content-md-center">
                                <Col md={6} className="text-center">
                                    <h1><BsCardChecklist style={{ verticalAlign: "middle" }} /> <b>Courriers</b></h1>
                                    <Row>
                                    {
                                        default_proveedores.map((proveedor, index) => {
                                            return <Col xs={2} key={index}>
                                                <img style={{ maxHeight: 100, maxWidth: "100%" }} className="mb-3" src={proveedor.logo} />
                                            </Col>
                                        })
                                    }
                                    </Row>
                                    <img src={`${url_images}/animations/Professional movers.gif`} style={{ width: "70%" }} />
                                    <p>Configura la forma en la cual cotizas tarifas terrestres</p>
                                    <ReglasCotizacion custom_component={<Button size="sm" className='mb-3'>CONFIGURAR</Button>} />
                                </Col>
                            </Row>
                        </div>
    </div>
}

export default ConfiguracionCourriers