import { Provider } from "react-redux";
import RoutesApp from "./components/RoutesApp";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";
import reduxStore from '../src/redux/store.ts';
import 'mapbox-gl/dist/mapbox-gl.css'; 
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';    

import { ToastContainer } from "react-toastify";
import ErrorBoundary from "./subComponents/general/errorBoundary";
const { store, persistor } = reduxStore();

const App = () => {
  return (
    <Provider store={store}>
    <ErrorBoundary>
    <PersistGate persistor={persistor}>
    <ToastContainer
          position="bottom-right"
          theme="colored"
          />
    <BrowserRouter>
      <RoutesApp />
    </BrowserRouter>
    </PersistGate>
    </ErrorBoundary>
    </Provider>
  );
}

export default App;
