import { Tab, Tabs } from "react-bootstrap";
import OrdenesListadoV3 from "../../subComponents/ordenes/listado_V3";
import { CiBarcode } from "react-icons/ci";
import { FaFileAlt, FaPrint } from "react-icons/fa";

const Envios = (props) => {


    return <div>
        <Tabs defaultActiveKey="etiquetas" >
            <Tab eventKey="etiquetas" title={<div><h4><CiBarcode size={30} style={{ verticalAlign: "middle" }} /> Generar etiquetas</h4></div>}>
                    <OrdenesListadoV3 hideTitle={true} flow="generar-etiquetas" condicion_default={{ courrier: true, label_courrier_generated: { $nin: [true] }, label_courrier_printed: { $nin: [true] } }} />
            </Tab>
            <Tab eventKey="imprimir" title={<div><h4><FaPrint size={23} style={{ verticalAlign: "middle" }} /> Imprimir etiquetas</h4></div>}>
                    <OrdenesListadoV3 hideTitle={true} flow="imprimir-etiquetas" condicion_default={{ courrier: true, label_courrier_generated: { $nin: [null,false] }, manifest: { $nin: [ true ] } }} />
            </Tab>
            <Tab eventKey="manifiesto" title={<div><h4><FaFileAlt size={23} style={{ verticalAlign: "middle" }} /> Preparar manifiesto</h4></div>}>
                    <OrdenesListadoV3 hideTitle={true} acciones={['sin-ruta']} flow="preparar-manifiesto" condicion_default={{ courrier: true, label_courrier_generated: true, label_courrier_printed: true, manifest: { $nin: [true] } }} />
            </Tab>
        </Tabs>
    </div>
}

export default Envios;