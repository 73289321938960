import { Badge, Card } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"
import { statuses_invitacion } from "./data"

const BoxList = (props) => {
    const proveedor = props.proveedor ? props.proveedor : false
    const seleccionado = props.seleccionado ? props.seleccionado : false

    if(!proveedor) return false

    const onClickProveedor = (id) => {
        if(props.onClickProveedor) return props.onClickProveedor(id)
        return false
    }

    return <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={() => onClickProveedor(proveedor._id)}>
    <h6 style={{ fontSize: 13 }} className="mb-1">{proveedor.id} · <b>{proveedor.name}</b></h6>
    <h6 style={{ fontSize:11, fontWeight:'lighter' }} className="mb-0">{ fechaATexto(proveedor.createdAt)}</h6>
    <Badge className="mb-0 mt-2" variant="dark" style={{ textTransform: "uppercase" }} >{ statuses_invitacion[proveedor.status]}</Badge>
</Card>
}

export default BoxList