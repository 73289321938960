import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ReactSelect from 'react-select'
import { urlapi } from '../../lib/backend/data'
import { useSelector } from 'react-redux'

const SelectorReglas = (props) => {
    const [ conductores, setConductores ] = useState([])
    const [ loadingConductores, setLoadingConductores ] = useState(true)
    const session = useSelector(state => state.miusuario)

    useEffect(() => {
        getConductores()
    }, [])

    const getConductores = async () => {
        const url = `${urlapi}/reglas/list/select`;
        let options = []
        return fetch(url, {
            method: "POST",
            body: JSON.stringify({
                condicion: {}
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error("Sin datos")
            } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
            } else if(Array.isArray(pros) !== false){
                setConductores(pros)
            }
            setLoadingConductores(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingConductores(false)
        })
    }

    const handleChange = (e) => {
        if(props.onChange) props.onChange(e)
    }

    return <div>
                <label className='form-control-label d-block text-primary' style={{fontWeight:700}}>Selecciona una regla de negocio</label>
                <ReactSelect
                            style={{ marginBottom: 10 }}
                            onChange={handleChange}
                            options={conductores.map(e => ({ value: e._id, label: e.titulo }))}
                            isLoading={loadingConductores}
                            placeholder="Filtrar regla..."
                          />
    </div>
}

export default SelectorReglas