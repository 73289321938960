import { useDispatch, useSelector } from "react-redux"
import data, { urlapi } from "../../lib/backend/data"
import { useEffect, useState } from "react"
import CargandoTabla from "./cargando"
import { calcularPorcentaje, fechaATextoSimple } from "../../lib/helpers/helpers"
import { Form, Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { cerrarSesion } from "../../redux/actions/session"
import { Button, Card, Col, Pagination, Row, Spinner, Table, Tooltip } from "react-bootstrap"
import { toast } from "react-toastify"
import LoadingDeliveryLocation from "../../subComponents/general/loadingAnimations/loadingTruckLocation"

const ListadoZonas = (props)=> {
    const {
        condicion_default,
        tableSize,
        hideSearch,
        hideHeader,
        typeView
    } = props
    const dispatch = useDispatch()
    const sesion = useSelector(state => state.miusuario)
    const [conductores, setConductores] = useState([])
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [filtros, setFiltros] = useState([])
    const [loadingConductores, setLoadingConductores] = useState([])
    const [ pagina, setPagina ] = useState(1)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ total, setTotal ] = useState(0)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const trash = props.trash ? props.trash : false
    const pais = useSelector(state => state.pais)
    const idioma = useSelector(state => state.idioma)
    const tipo = props.tipo ? props.tipo : "link"
    const titulo = props.title ? props.title : 'registros'

    const obtenerConductores = async (page, query)=>{
        setLoadingConductores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        setCondicionBusqueda(condicion)
        return fetch(`${urlapi}/geodata/zonas/empresa/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingConductores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingConductores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setConductores(res.datos)
                setTotal(res.total)
                if(res.filtros) setFiltros(res.filtros)
            }
            return setLoadingConductores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingConductores(false)
        })
    }

    useEffect(() => {
        obtenerConductores(1, false)
    }, [])

    const handleChangePagina = (e) => {
        setPagina(e)
        obtenerConductores(e, condicion_busqueda)
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        if(!cantidad) return false
        return <div>
            <Pagination defaultCurrent={pagina} total={(cantidad*10)} showSizeChanger={false} onChange={handleChangePagina} />
        </div>
    }

    const seleccionarRegistro = data => {
        if(props.onSelect) props.onSelect(data)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <b onClick={() => seleccionarRegistro(data)}>{data.titulo}</b>
            default:
                return <Link to={`/${rutas.zones.slug}/${data._id}`}>{data.titulo}</Link>
        }
    }

    const filtrarRegistros = (filtro) => {
        if(!filtro.condicion) return false
        if(typeof filtro.condicion !== "object") return false
        obtenerConductores(1, filtro.condicion)
    }

    const header = () => {
        if(hideHeader === true) return false
        return <Row gutter={15}>
            <Col md={3}>
                <Card className="mb-3 p-2">
                    <p style={{ fontSize: 12 }} className="m-0">Total de registros filtrados</p>
                    <h3 className="m-0">{total}</h3>
                </Card>
            </Col>
            {
                filtros.map((filtro,i) => {


                    const porcentaje = calcularPorcentaje(total, filtro.cantidad)
                    return <Col className="hover" md={3} key={`filtro-${i}`} onClick={() => filtrarRegistros(filtro)} >
                    <Card className="mb-3 p-2">
                        <p  style={{ fontSize: 12 }} className="m-0">{filtro.label}
                        {
                        //     filtro.help_text ? <Tooltip title={filtro.help_text}>
                        //    ?
                        //   </Tooltip> : false
                        }
                        </p>
                        <Row>
                            <Col xs={12}><h3 className="m-0">{filtro.cantidad}</h3></Col>
                            <Col xs={12} style={{ textAlign: "right" }}> <Spinner size={35} type="circle" percent={porcentaje} /></Col>
                        </Row>
                    </Card>
                </Col>
                })
            }
        </Row>
    }

    const descargarExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reports/drivers`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`,
                'country': pais,
                'lang': idioma
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    const handleChangeCampo = (e) => {
        const { value } = e.target;
        return setCampoBusqueda(value);
      };


      const handleSubmit = (e) => {
        if(e) e.preventDefault()
        if (!campoBusqueda) return toast.error('Escribe alguna palabra clave');
        const condicion = {
          $text: { $search: campoBusqueda.toLowerCase() }
        };
        setCondicionBusqueda(condicion);
        setPagina(1);
        return obtenerConductores(1, condicion);
      };
      
      const buscador = () => {
        if(hideSearch === true) return false
        return <div >
        <Row className="mb-3">
            <Col md={6}>
                <label className="form-control-label d-block">Buscar</label>
                <input className="form-control" defaultValue={campoBusqueda} onChange={handleChangeCampo} placeholder={`Título o palabra clave`} />
            </Col>
            <Col md={6}>
                <label className="form-control-label d-block">Click para buscar</label>
                <Button > BUSCAR</Button>
            </Col>
        </Row>
                </div>
      }

    const mostrarRegistros = () => {
        if(loadingConductores) return <LoadingDeliveryLocation />
        return <div>

            {header()}
            
            {buscador()}
            {paginacion(data.pagina, total)}
            <Table size={tableSize ? tableSize : "middle"} dataSource={conductores} pagination={false} scroll={true} >
                <thead>
                    <tr>
                        <td>Título</td>
                        <td>Fecha de creación</td>
                        <td>Estado</td>
                    </tr>
                </thead>
                <tbody>
                    {
                        conductores.map((data,i) => {
                            return <tr key={`da-${i}`}>
                                <td>{showByType(typeView, data)}</td>
                                <td>{fechaATextoSimple(data.createdAt)}</td>
                                <td>{data.status === "active" ? "ACTIVO" : "INACTIVO"}</td>
                            </tr>
                        })
                    }
                </tbody>
            </Table>
        </div>
    }

    return <div>
        {mostrarRegistros()}
    </div>
}
export default ListadoZonas