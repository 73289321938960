const acciones = [
    { slug:'ver', label: "Ver" },
    { slug:'crear', label: "Crear" },
    { slug:'modificar', label: "Modificar" },
    { slug:'eliminar', label: "Eliminar" },
]

const virtual_tour_settings = {
  locale: { back: 'Anterior', close: 'Cerrar', last: 'Último', next: 'Siguiente', open: 'Abrir el diálogo', skip: 'No mostrar más el tour' },
  estilo: {
    arrowColor: '#fff',
    backgroundColor: '#fff',
    beaconSize: 36,
    overlayColor: 'rgba(0, 0, 0, 0.5)',
    primaryColor: '#007bff',
    spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
    textColor: '#333',
    width: undefined,
    zIndex: 100,
  },
  pasos_ordenes: [
    {
        title: "Buscador de órdenes",
        disableBeacon: true,
        target: ".delpa-buscador",
        content: "A través del buscador podrás encontrar las órdenes"
    },
    {
        title: "Filtro de órdenes",
        target: ".delpa-filtro",
        content: "A través del filtro podrás encontrar justo las órdenes que necesitas visualizar para luego exportarlas a un excel por ejemplo"
    },
    {
        title: "Descargar reportes excel",
        target: ".delpa-reporte-excel",
        content: "Puedes descargar un reporte de las órdenes, este botón actua en combinación del botón FILTRAR que verás en la parte superior de esta zona."
    },
    {
        title: "Listado de órdenes",
        target: ".delpa-ordenes",
        placement: "right",
        content: "Aquí podrás visualizar el listado de tus órdenes, y hacer click sobre ellas para acceder a todos los detalles"
    },
    {
        title: "Crea órdenes",
        target: ".delpa-nueva-orden",
        content: "Aquí podrás una orden nueva uno a uno, o también puedes importarlas por excel en el módulo de RUTAS"
    },
  ],
  actividad: [
    {
        title: "Actividad por operación",
        disableBeacon: true,
        target: ".delpa_lastmile_actividad_operacion",
        content: "A través del monitor de actividad por operación podrás ver los indicadores generales de tu operación, los cuales están basados en el tiempo y el éxitos de tus entregas del mes"
    },
    {
        title: "Actividad general",
        disableBeacon: true,
        target: ".delpa_lastmile_actividad_general",
        content: "En la vista general podrás saber mas a detalle los indicadores del estado de tus cargas, tanto segmentado por las órdenes que gestiona tu flota propia como las órdenes que gestionan tus proveedores de transporte"
    },
    {
        title: "Actividad geográfica",
        disableBeacon: true,
        target: ".delpa_lastmile_actividad_geografico",
        content: "En la vista geográfica podrás acceder a un mapa de calor segmentado por nivel administrativo de tu país, ciudad o región"
    },
    {
        title: "Actividad de eventos de carga",
        disableBeacon: true,
        target: ".delpa_lastmile_actividad_estados",
        content: "En la vista de actualizaciones podrás ver los eventos que envían cada uno de los vehículos que gestionan tus cargas, bien sea de tu flota como los vehículos externos, podrás acceder también a las zonas geográficas desde donde envían estos eventos."
    },
  ],
  pasos_rutas:[
    {
      title: "Buscador de rutas",
      disableBeacon: true,
      target: ".delpa-buscador-ruta",
      content: "A través del buscador podrás encontrar las rutas por su número identificador"
    },
    {
      title: "Filtro de rutas",
      target: ".delpa-filtro",
      content: "A través del filtro podrás encontrar justo las rutas que necesitas visualizar"
    },
    {
      title: "Listado de rutas",
      target: ".delpa-rutas",
      placement: "right",
      content: "Aquí podrás visualizar el listado de tus rutas"
    },
    {
      title: "Crea nueva ruta",
      target: ".delpa-nueva-ruta",
      content: "Aquí podrás una ruta nueva"
    },
    {
      title: "Importa tus rutas",
      target: ".delpa-importar-ruta",
      content: "Aquí podrás importar un excel de órdenes y agruparlos en una o varias rutas por vehículos"
    },
  ]
} 

const logotipo = window.location.protocol + "//" + window.location.host + "/images/logo-light.png"
const host = window.location.protocol + "//" + window.location.host 
const url_images = window.location.protocol + "//" + window.location.host + "/images"
const mercado_pago_public_key = 'APP_USR-6248f20c-6a9a-4010-9da9-98c53a7efb95'

const markerSmall = {
    path:
      "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "#007bff",
    fillOpacity: 0.9,
    scale: 1,
    //strokeColor: "gold",
    strokeWeight: 0,
  }

  const markerSmallPoint = (color) => {
    let color_pint = "#007bff"
    if(color) color_pint = color
    return {
      path: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z",
      fillColor: color_pint,
      fillOpacity: 1,
      scale: 1,
      //strokeColor: "gold",
      strokeWeight: 0,
  }
  }

const comunascoordenadas =  {
  "Santiago":  {
    nombre: "Santiago",
    lat: "-33.4375545",
    lng: "-70.6504896"
  },
  "Cerrillos":  {
    nombre: "Cerrillos",
    lat: "-33.5010107",
    lng: "-70.7098735"
  },
  "Cerro Navia":  {
    nombre: "Cerro Navia",
    lat: "-33.4218958",
    lng: "-70.7404531"
  },
  "Conchalí":  {
    nombre: "Conchalí",
    lat: "-33.3846893",
    lng: "-70.6800213"
  },
  "Estación Central":  {
    nombre: "Estación Central",
    lat: "-33.4619178",
    lng: "-70.6985099"
  },
  "Huechuraba":  {
    nombre: "Huechuraba",
    lat: "-33.3742128",
    lng: "-70.6367425"
  },
  "Independencia":  {
    nombre: "Independencia",
    lat: "-33.4155974",
    lng: "-70.6642603"
  },
  "La Cisterna":  {
    nombre: "La Cisterna",
    lat: "-33.5264495",
    lng: "-70.6613511"
  },
  "La Granja":  {
    nombre: "La Granja",
    lat: "-33.5377931",
    lng: "-70.620678"
  },
  "La Pintana":  {
    nombre: "La Pintana",
    lat: "-33.5855661",
    lng: "-70.6285838"
  },
  "El Bosque":  {
    nombre: "El Bosque",
    lat: "-33.5589761",
    lng: "-70.6787503"
  },
  "San Bernardo":  {
    nombre: "San Bernardo",
    lat: "-33.5854485",
    lng: "-70.6987361"
  },
  "Pedro Aguirre Cerda":  {
    nombre: "Pedro Aguirre Cerda",
    lat: "-33.4940901",
    lng: "-70.6765026"
  },
  "La Florida":  {
    nombre: "La Florida",
    lat: "-33.5226882",
    lng: "-70.5987142"
  },
  "La Reina":  {
    nombre: "La Reina",
    lat: "-33.4411269",
    lng: "-70.5340591"
  },
  "Las Condes":  {
    nombre: "Las Condes",
    lat: "-33.4087844",
    lng: "-70.567069"
  },
  "Lo Barnechea":  {
    nombre: "Lo Barnechea",
    lat: "-33.352669",
    lng: "-70.518517"
  },
  "Lo Espejo":  {
    nombre: "Lo Espejo",
    lat: "-33.5220498",
    lng: "-70.690565"
  },
  "Lo Prado":  {
    nombre: "Lo Prado",
    lat: "-33.4442688",
    lng: "-70.7233493"
  },
  "Macul":  {
    nombre: "Macul",
    lat: "-33.4851471",
    lng: "-70.5992005"
  },
  "Maipú":  {
    nombre: "Maipú",
    lat: "-33.5105866",
    lng: "-70.7572607"
  },
  "Ñuñoa":  {
    nombre: "Ñuñoa",
    lat: "-33.4566678",
    lng: "-70.5978415"
  },
  "Peñalolén":  {
    nombre: "Peñalolén",
    lat: "-33.4719116",
    lng: "-70.5627854"
  },
  "Providencia":  {
    nombre: "Providencia",
    lat: "-33.4314474",
    lng: "-70.6093325"
  },
  "Pudahuel":  {
    nombre: "Pudahuel",
    lat: "-33.4421135",
    lng: "-70.7640644"
  },
  "Quilicura":  {
    nombre: "Quilicura",
    lat: "-33.3576747",
    lng: "-70.7292718"
  },
  "Quinta Normal":  {
    nombre: "Quinta Normal",
    lat: "-33.4317378",
    lng: "-70.6923917"
  },
  "Recoleta":  {
    nombre: "Recoleta",
    lat: "-33.4061916",
    lng: "-70.6336176"
  },
  "Renca":  {
    nombre: "Renca",
    lat: "-33.4063601",
    lng: "-70.7279965"
  },
  "San Joaquín":  {
    nombre: "San Joaquín",
    lat: "-33.496202",
    lng: "-70.6283361"
  },
  "San Miguel":  {
    nombre: "San Miguel",
    lat: "-33.4923545",
    lng: "-70.651797"
  },
  "San Ramón":  {
    nombre: "San Ramón",
    lat: "-33.5510207",
    lng: "-70.6464256"
  },
  "Vitacura":  {
    nombre: "Vitacura",
    lat: "-33.3905211",
    lng: "-70.5724124"
  },
  "Puente Alto":  {
    nombre: "Puente Alto",
    lat: "-33.6186082",
    lng: "-70.5906057"
  },
  "Pirque":  {
    nombre: "Pirque",
    lat: "-33.6874736",
    lng: "-70.5883812"
  },
  "San José de Maipo":  {
    nombre: "San José de Maipo",
    lat: "-33.6462226",
    lng: "-70.3512184"
  },
  "Colina":  {
    nombre: "Colina",
    lat: "-33.2045451",
    lng: "-70.6759654"
  },
  "Lampa":  {
    nombre: "Lampa",
    lat: "-33.2827093",
    lng: "-70.879328"
  },
  "TilVl":  {
    nombre: "TilVl",
    lat: "-33.0838906",
    lng: "-70.9279843"
  },
  "Buin":  {
    nombre: "Buin",
    lat: "-33.7313897",
    lng: "-70.742431"
  },
  "Calera de Tango":  {
    nombre: "Calera de Tango",
    lat: "-33.6291031",
    lng: "-70.7686968"
  },
  "Paine":  {
    nombre: "Paine",
    lat: "-33.8081642",
    lng: "-70.7363707"
  },
  "Melipilla":  {
    nombre: "Melipilla",
    lat: "-33.684539",
    lng: "-71.215733"
  },
  "Alhué":  {
    nombre: "Alhué",
    lat: "-34.0264691",
    lng: "-71.1002881"
  },
  "Curacaví":  {
    nombre: "Curacaví",
    lat: "-33.402157",
    lng: "-71.132102"
  },
  "María Pinto":  {
    nombre: "María Pinto",
    lat: "-33.517104",
    lng: "-71.1235068"
  },
  "San Pedro":  {
    nombre: "San Pedro",
    lat: "-33.8950912",
    lng: "-71.4563779"
  },
  "Talagante":  {
    nombre: "Talagante",
    lat: "-33.6637468",
    lng: "-70.9273937"
  },
  "El Monte":  {
    nombre: "El Monte",
    lat: "-33.6815281",
    lng: "-70.9853968"
  },
  "Isla de Maipo":  {
    nombre: "Isla de Maipo",
    lat: "-33.7464562",
    lng: "-70.9"
  },
  "Padre Hurtado":  {
    nombre: "Padre Hurtado",
    lat: "-33.5695362",
    lng: "-70.8156717"
  },
  "Peñaflor":  {
    nombre: "Peñaflor",
    lat: "-33.6060255",
    lng: "-70.8781837"
  },
  "Valparaíso":  {
    nombre: "Valparaíso",
    lat: "-33.047236",
    lng: "-71.613184"
  },
}

const colores_diversos = [
  "#FF5733", "#33FF57", "#5733FF", "#33FFFF", "#FF33FF",
  "#FFC300", "#FF5733", "#33FF57", "#5733FF", "#33FFFF",
  "#FF33FF", "#FFC300", "#FF5733", "#33FF57", "#5733FF",
  "#33FFFF", "#FF33FF", "#FFC300", "#FF5733", "#33FF57",
  "#5733FF", "#33FFFF", "#FF33FF", "#FFC300", "#FF5733",
  "#33FF57", "#5733FF", "#33FFFF", "#FF33FF", "#FFC300",
  "#FF5733", "#33FF57", "#5733FF", "#33FFFF", "#FF33FF",
  "#FFC300", "#FF5733", "#33FF57", "#5733FF", "#33FFFF",
  "#FF33FF", "#FFC300", "#FF5733", "#33FF57", "#5733FF",
  "#33FFFF", "#FF33FF", "#FFC300", "#FF5733", "#33FF57",
];

const modos_sistema = {
  proveedor: {
    slug: "proveedor"
  },
  cliente: {
    slug: 'cliente'
  },
  courriers: {
    slug: 'courriers'
  }
}

const gps_integrados = [
  { slug: "mavigps", logo: `${url_images}/integraciones/gps/mavigps.png` },
  { slug: "gps-chile", logo: `${url_images}/integraciones/gps/gps-chile.png` },
  { slug: "autotrack", logo: `${url_images}/integraciones/gps/autotrack.png` },
]

module.exports = {
    acciones,
    logotipo,
    host,
    markerSmallPoint,
    colores_diversos,
    comunascoordenadas,
    markerSmall,
    virtual_tour_settings,
    url_images,
    modos_sistema,
    mercado_pago_public_key,
    gps_integrados
}