import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { configuracion_fechas, configuracion_horas, formatDate } from '../../lib/helpers/dates';
import { esimacionFechas, statusColor, statusString } from '../../lib/helpers/logistica';
import { canalEstadosString } from '../../lib/helpers/pedidos/pedidos';
import { rutas } from '../../lib/routes/routes';
import { Link } from 'react-router-dom'
import { Accordion, Badge, Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap';
import { urlapi } from '../../lib/backend/data';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { FaChevronDown } from 'react-icons/fa';
import { esNumero } from '../../lib/helpers/main';
import OrdenTicketsListado from '../ordenes/ordenes_tickets/list';
import DetailFullModalTicket from '../ordenes/ordenes_tickets/modal_ticket';
import { MdOpenInNew } from 'react-icons/md';
import { estilo_last_mile } from '../../lib/global/styles';
import ModalListadoOrdenes from '../ordenes/modal_listado';
import TagSinDatos from '../general/tag_sin_datos';
import data from '../ordenes/ordenes_tickets/data';
import { AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai';
import { DateTime, Interval } from 'luxon';
import { fechaATextoSimple, fechaATextoSimpleCorto } from '../../lib/helpers/helpers';

const columns_default = {
        Estado: true,
        SubEstado: true,
        Creado: true,
        Canal: true,
        Referencia: true,
        Destinatario: true,
        Fecha: true,
        Hora: true,
        Ruta: true,
        RutaProveedor: true,
        Proveedor: true,
        Otif: true,
        Tracking: true,
}

const DetailEstadosCarga = (props) => {
    const {
      typeView,
      size,
      hideMetaData,
      hideCreator,
      showLink,
      columnas
    } = props
    const estado = props.estado ? props.estado : false
    const session = useSelector(state => state.miusuario)
    const [ showModal, setShowModal ] = useState(false)
    const token = session.tokenSession
    const columns = columnas ? columnas : columns_default
    const [ loading, setLoading ] = useState(false)
    let pedido = ''
    if(!estado) return false

    const sizeSpan = () => {
      if(size==="sm") return 10
      return 12
    }

    
    const fonSize = sizeSpan()
    if(estado?.detalles){
      if(typeof estado.detalles === 'object'){
        pedido = estado.detalles?.pedido
      }
    }

    const auditarEstado = async (opcion) => {
      setLoading(true)
      const url = `${urlapi}/flota/auditoria-pedido?id=${estado._id}&opcion=${opcion}`;
      return fetch(url,{
          method:'GET',
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(pros => pros.json())
      .then(pros => {
          if(!pros){
              toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
          } else if(pros.errorMessage){
              toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
          } else if(pros._id){
            if(props.onDelete) props.onDelete(pros._id)
          }
          setLoading(false)
      })
      .catch(error => {
          toast.error('Ocurrió un error inesperado')
          setLoading(false)
      })
  }

  const handleCancel = () => setShowModal(false)

    const mostrarMetaDatosSubEstadoModal = (metadatos) => {
      if(hideMetaData === true) return false
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return <div>
          <Button size="sm" style={{ fontSize: 10 }} onClick={() => setShowModal(true)} variant='outline-dark'>MÁS INFORMACIÓN</Button>
          <Modal width={"80%"} open={showModal} onCancel={handleCancel}>
                <h4 level={2} className="mt-0 mb-0">INFORMACIÓN ADICIONAL</h4>
                { metadatos.map((meta,i) => {
                              return <div key={`meta-${i}`}  className='mb-2'>
                                {showComponentByTipoFormulario(meta)}
                              </div>
                })}
          </Modal>
        </div>
    }

    const mostrarMetaDatosSubEstado = (metadatos) => {
      if(hideMetaData === true) return false
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return <div>
          <Accordion >
                <Card className="p-0" >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={estado._id}>
                            <div className="hover pl-3 pr-3 pb-0"><h6 className='mb-0'><FaChevronDown /> <b>INFORMACIÓN ADICIONAL</b></h6></div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={estado._id}>
                            <Card.Body style={{ backgroundColor: '#f1f1f1' }}>
                            { metadatos.map((meta,i) => {
                              return <div key={`meta-${i}`}  className='mb-2'>
                                {showComponentByTipoFormulario(meta)}
                              </div>
                            })}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
            </Accordion>
        </div>
    }

    const sizeImage = () => {
      if(size==="sm") return 50
      return 100
    }

    const showComponentByTipoFormulario = (campo = Object) => {
      const height = sizeImage()
        switch (campo.tipo_accion) {
          case 'campo-texto':
            if(!campo.valor) return false
            return <div>
              <h6 className='mb-0' style={{ fontSize: 14, fontWeight:900 }}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'selector':
            if(!campo.valor) return false
            return <div>
              <h6 className='mb-0' style={{ fontSize: 14, fontWeight:900 }}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'carga-imagenes':
            if(campo.valores.length < 1) return false
            return <div>
              <h6 className='mb-1' style={{ fontSize: 14, fontWeight:900 }}>{campo.titulo}</h6>
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <a href={foto.url} target="_blank" key={`foto-estado-${i}`} style={{ display:"inline-block", paddingRight: 5}}>
                    <img src={foto.url} style={{  height }} ></img>
                    </a>
                }) : <div className='col-md-12'><p>Sin información</p></div>
              }
            </div>
          case 'firma-digital':
            if(!campo.valor) return false
            const isNumero = esNumero(campo.valor)
            if(isNumero === true) return false
    
            return <div className="mb-3 text-primary">
                <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
                <p className='mb-0'>
                    {campo.valor 
                        ? <img src={campo.valor} alt="Firma digital" style={{maxWidth: '25%'}} /> 
                        : 'Sin información'
                    }
                </p>                  
            </div>
          default:
            break;
        }
      }

      const mostrarCreador = (dato,ref) => {
        if(hideCreator === true) return false

        return <div>
        <hr className='hr' />
        <div className='d-flex justify-content-between align-items-center mx-2'>
            <div className=''><h6 className='mb-0' style={{ fontSize: 12 }}>REF: <b>{ref}</b></h6></div>  
            <div className=''><h6 className='mb-0' style={{ fontSize: 12 }}>{dato.identificador_creador}</h6></div>  
            <div className=''><h6 className='mb-0' style={{ fontSize: 12 }}>{canalEstadosString(dato.canal)}</h6></div>
            <div className=''><DetailFullModalTicket id_orden={dato._id} tamaño={10}/></div>  
        </div>
        <hr className='hr' />
    </div>
      }

    const mostrarSubEstadoHTML = (sub_estado_entrega = String, display) => {
      
        let estilo = { fontSize: fonSize, padding: '2px 10px', borderRadius:5, backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2' }
        if(display) estilo.display = 'block'
        if(sub_estado_entrega){
          if(typeof sub_estado_entrega === 'object'){
            if(sub_estado_entrega.titulo){
              return <span className="spanstatus mr-2 text-white" style={estilo}>{sub_estado_entrega.titulo}</span>
            }
          }
        }
        return false
    }

    const accionesAuditoria = () => {
      if(estado.notificar_planel_flota !== true) return false
      if(estado.auditado !== false) return false
      return <div>
        { loading === true ? <Skeleton height={20} /> : <Button size="sm" style={{ fontSize: 10 }} onClick={() => auditarEstado("1")} variant='outline-dark'>MARCAR AUDITADO</Button> }
      </div>
    }

    const calcularDiferenciaTiempo = (desde, hasta) => {
      const fechaInicio = DateTime.fromISO(desde);
      const fechaFin = DateTime.fromISO(hasta);
    
      // Calcular la diferencia entre las fechas
      const intervalo = Interval.fromDateTimes(fechaInicio, fechaFin);
      const diff = intervalo.toDuration(['years', 'months', 'days', 'hours', 'minutes', 'seconds']);
    

      const partes = [];
      if (diff.years) partes.push(`${diff.years} ${diff.years === 1 ? 'año' : 'años'}`);
      if (diff.months) partes.push(`${diff.months} ${diff.months === 1 ? 'mes' : 'meses'}`);
      if (diff.days) partes.push(`${diff.days} ${diff.days === 1 ? 'día' : 'días'}`);
      if (diff.hours) partes.push(`${diff.hours} ${diff.hours === 1 ? 'hora' : 'horas'}`);
      if (diff.minutes) partes.push(`${diff.minutes} ${diff.minutes === 1 ? 'minuto' : 'minutos'}`);
      // Puedes agregar horas, minutos, y segundos si es necesario
    
      const textoAmigable = `${partes.join(', ')}`
      return textoAmigable
    }

    const mostrarLink = (id) => {
      if(showLink === true) return <a style={{ fontWeight: "bold" }} target="_blank" href={`/${rutas.ordenes.slug}/${id}`}>Ver orden <i className="fas fa-external-link-alt"></i></a>
    }

    if(!estado) return <h6>Sin información</h6>
    
    const fecha = new Date(estado.fecha)

    const vistaSM = () => {
      const fonSize = sizeSpan()
      return <div className='card shadow-sm mb-3'>
      <div className='p-2 text-primary'>
      {accionesAuditoria()}
      <p className='mb-0 d-flex justify-content-between' style={{fontWeight:700}}>{fecha.toLocaleDateString('es-ES', configuracion_fechas )} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b> {mostrarLink(estado.idrecurso)}</p>
      <span className="spanstatus mr-2 text-white" style={{ fontSize: fonSize, padding: '2px 10px', borderRadius:5, backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb' }}>{estado.estado}</span>
      {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color })}
      { /* <button className='btn btn-sm btn-outline-primary' style={{ fontSize: 12 }} onClick={()=>obtenerPedido(estado.idrecurso)} >VER DETALLES DE LA ORDEN</button> */ }
      {mostrarCreador(estado, pedido)}
      {mostrarMetaDatosSubEstado(estado.metadatos)}
      </div>
    </div>
    }
    
    const vistaXL = () => {
      let routes = Array.isArray(estado?.rutas) ? estado.rutas : []
      let routes_external = Array.isArray(estado?.rutas_externas) ? estado.rutas_externas : []
      let tickets = Array.isArray(estado?.tickets) ? estado.tickets : []

      const a_tiempo = esimacionFechas(estado.detalles)
      
      return <tr>
      {columns.Estado && <th>{mostrarSubEstadoHTML({ titulo: estado.estado, color: estado.estado_color }, true)}</th>}
      {columns.SubEstado &&  <th>{mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color }, true)}</th>}
      {columns.Hora && <th>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</th>}
      {columns.Canal &&  <th style={{ textTransform: "uppercase" }}>{canalEstadosString(estado.canal)}</th>}
      {columns.Referencia && <th>{pedido}</th>}
      {columns.Creado && <th>{estado.detalles?.fecha_creacion_orden ? fechaATextoSimpleCorto(estado.detalles?.fecha_creacion_orden) : "Sin información"}</th>}
      {columns.Destinatario && <th>{estado.detalles?.destinatario}</th>}
      {columns.Fecha && <th>{estado.detalles?.fecha_creacion_orden ? <div>
        {calcularDiferenciaTiempo(estado.detalles?.fecha_creacion_orden, estado.createdAt)}
      </div> : "No disponible"}</th>}
      {columns.Ruta && <th>{routes.map((route,i) => <Link className='mr-2' target='_blank' style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }} to={`/${rutas.rutas.slug}/${route._id}`}>{route.id} <MdOpenInNew /></Link> )}</th>}
      {columns.RutaProveedor && <th>{routes_external.map((route,i) => <b className='mr-2' style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }} >{route.id} </b> )}</th>}
      {columns.Proveedor && <th>{estado.proveedor_logo ? <img src={estado.proveedor_logo} style={{ width: "100%", maxWidth: 70, maxHeight:30 }} />  : estado.proveedor}</th>}
      {columns.Otif && <th style={{ color: a_tiempo ? estilo_last_mile.color_success : estilo_last_mile.color_danger }}>{a_tiempo ? <AiFillCheckCircle /> : <AiFillClockCircle />} {a_tiempo ? "A TIEMPO" : "RETRASADA"}</th>}
      {columns.Tracking && <th><ModalListadoOrdenes title="VER MÁS" condicion={{ _id: estado.idrecurso }} /> </th>}
      {columns.Tickets && <th>{tickets.length > 0 ? 
          tickets.map((route,i) => <Link className='mr-2' target='_blank' to={`/${rutas.tickets.slug}?id=${route._id}`}><OverlayTrigger 
            placement="top"
            overlay={
                <Tooltip id={`tooltip-top`}>
                    {data.status[route.status]}
                </Tooltip>
          }>
        <Badge variant="dark" style={{ textTransform: "uppercase", fontSize: 11 }} className="mb-0"><b>{route.id}</b> <MdOpenInNew /></Badge>
        </OverlayTrigger></Link> )
        : <TagSinDatos /> }</th>}
    </tr>
      return <Card className='p-3'>
        {accionesAuditoria()}
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>Estado</th>
              <th>Canal</th>
              <th>Referencia</th>
              <th>Destinatario</th>
              <th>Fecha</th>
              <th>Ruta</th>
              <th>Proveedor</th>
              <th>OTIF</th>
              <th>Tracking</th>
              <th>Ticket</th>
              <th>Estado ticket</th>
            </tr>
          </thead>
          <tbody>
          
          </tbody>
        </Table>
        {/* <Row>
          <Col md={2}><p className='mb-0'> {mostrarLink(estado.idrecurso)}</p></Col>
          <Col md={2}><span className="spanstatus mr-2 text-white" style={{ padding: '2px 10px', borderRadius:5, backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb' }}>{estado.estado}</span></Col>
          <Col md={2}></Col>
          <Col md={2}>{mostrarCreador(estado, pedido)}</Col>
          <Col md={2}></Col>
          <Col md={2}></Col>
        </Row> */}
    </Card>
    }

    if(size === "sm") return vistaSM()
    if(size === "xl") return vistaXL()
                            
    return <div className='card shadow-sm mb-3'>
      <div className='p-3 text-primary'>
      <p className='mb-0'>{fecha.toLocaleDateString('es-ES', configuracion_fechas )} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b> {mostrarLink(estado.idrecurso)}</p>
      {accionesAuditoria()}
      <span className="spanstatus mr-2 text-white" style={{ padding: '2px 10px', borderRadius:5, backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb', fontSize: fonSize }}>{estado.estado}</span>
      {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color })}
      { /* <button className='btn btn-sm btn-outline-primary' style={{ fontSize: 12 }} onClick={()=>obtenerPedido(estado.idrecurso)} >VER DETALLES DE LA ORDEN</button> */ }
      {mostrarCreador(estado, pedido)}
      {mostrarMetaDatosSubEstado(estado.metadatos)}
      </div>
    </div>
}

export default DetailEstadosCarga
