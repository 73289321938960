import React, { useEffect, useState } from 'react';
import { Card, Button, Col, Row, Form } from 'react-bootstrap';
import SinDatos from '../general/sin_registros';
import { FaCcVisa, FaCheck, FaCheckCircle } from 'react-icons/fa';
import AddNewPaymentGateway from './nuevo';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../../lib/websockets';
import { urlapi } from '../../lib/backend/data';
import { cerrarSesion } from '../../redux/actions/session';
import { toast } from 'react-toastify';
import PantallaCargando from '../general/pantalla_cargando';
import CreateNewPaymentGateway from './crear';
import { url_images } from '../../lib/global/data';

const PaymentMethods = (props) => {
    const {
        avoid_create
    } = props
    const [ cash, setCash ] = useState(true)
    const [ seleccionado, setSeleccionado ] = useState(null)
    const [ methods, setMethods ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [selectedPayment, setSelectedPayment] = useState(null);
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    let identificador = `new_payment_${session.data.propietario}`

    useEffect(() => {
        socket.on(identificador, nuevo => {
            return setMethods(prev => {
                let actual = [...prev]
                const i = actual.findIndex(x => x._id === nuevo._id)
                if(i < 0) actual.push(nuevo)
                return [...[], ...actual]
            })
        })
        obtenerDatos()
        return () => {
            socket?.off(identificador)
        }
    }, [])

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/payments/list`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res)){
                setMethods([...[], ...res])
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handlePaymentSelection = (payment) => {
        setSelectedPayment(payment);
    };

    const handleCheck = (e) => {
        setCash(e.target.checked)
    }

    const iconByCardType = (val) => {
        const cardType = val.toString().toLowerCase()
        const tipos = {
            visa: <FaCcVisa size={30} className='mr-2' />
        }
        return tipos[cardType] ? tipos[cardType] : cardType
    }

    const eliminarMetodo = async (id) => {

        if(loading) return false
        setLoading(true)

        let settings = {
            method: "DELETE",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        }

        return fetch(`${urlapi}/payments?id=${id}`, settings)
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                return setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const LoadingPayments = () => {
        return <div>
        <Row className="justify-content-md-center">
        <Col md={6} className="text-center">
        <img className='d-block' src={`${url_images}/animations/Contactless Payment.gif`} style={{ width: 250, maxWidth: "80%", margin: "0 auto" }} />
        <h3 className='mb-0 text-primary' style={{ fontWeight: "bold" }}>Espere un momento...</h3>
        <p>Estamos cargando la información</p>
        </Col>
    </Row>
    </div>
    }

    const seleccionarMetodo = (method) => {
        if(props.onSelect){
            props.onSelect(method)
            setSeleccionado(method?._id)
        }
    }
    const mostrarMetodos = () => {
        if(methods.length < 1) return <div>
            <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
            <h3 className='mb-0 text-primary' style={{ fontWeight: "bold" }}>No tienes métodos de pago agregados</h3>
            <img className='d-block' src={`${url_images}/animations/Contactless Payment.gif`} style={{ width: 250, maxWidth: "80%", margin: "0 auto" }} />
            <p>Agrega tus métodos de pago y optimiza tus pagos a las diferentes operaciones que ofrecemos para tí</p>
            </Col>
        </Row>
        </div>

        return <div>
            <h6 className='mt-3'>Selecciona el método de pago</h6>
            {methods.map((metodo, index) => {
                return <Card
                    key={index}
                    className='p-3 mb-3'
                    // onClick={() => handlePaymentSelection(metodo.slug)}
                >
                    <Row>
                        <Col className='hover' onClick={() => seleccionarMetodo(metodo)}><p className='mb-0'>{iconByCardType(metodo.card_type)} Términa en { metodo.card_number.replace(/x/gi, "") } {seleccionado === metodo?._id ? <FaCheckCircle /> : false } </p></Col>
                        {
                            avoid_create === true ? false : <Col className='text-right'>{  methods.length > 0 ? <Button size="sm" variant="ghost text-danger" onClick={() => eliminarMetodo(metodo._id)}>ELIMINAR</Button> : false }</Col>
                        }
                    </Row>
                    
                    
                </Card>
            })}
        </div>
    }

    if(loading) return <LoadingPayments />

    return (
        <div className='mb-3'>
            { avoid_create === true ? false : <div>
                <h6>Mis métodos de pago guardados</h6>
                <CreateNewPaymentGateway ammount={1000}  />
            </div> }
            {/* 
            <Form.Group className="mb-2" controlId="usar_balance">
                                <Form.Check type="switch" defaultChecked={cash} label={<div>Utilizar mi balance <b style={{ fontWeight: "bold" }}>$0,00</b></div>} onChange={handleCheck} />
                                </Form.Group> */}
            {mostrarMetodos()}
            
        </div>
    );
};

export default PaymentMethods