import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import ListadoZonas from "./listado";
import { Button, Card, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import ListadoAlertas from "../GeoAlertas/listado";
import GeoAlertasMapa from "../GeoAlertas/geo_alertas_mapa";
import { useState } from "react";

const Zonas = (props) => {
  const [ resize, setResize ] = useState(0)
  const handleTabing = () => {
    const nuevo_valor = resize + 1
    return setResize(nuevo_valor)
}
      return <div>
      <h4>Alertas <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Crear nueva</Tooltip>
                            }
                            >
                            <Link to={`/${rutas.zones.slug}/new`} className='mr-2 delpa-nueva-ruta'><i className="fa-solid fa-circle-plus"></i></Link>
                          </OverlayTrigger>
              
              </h4>
      <Tabs defaultActiveKey="alertas"  onSelect={handleTabing}>
            <Tab eventKey="alertas" title="Mis Alertas" className="pt-2">
              <ListadoZonas condicion_default={{ status: "active" }} />
            </Tab>
            <Tab eventKey="notificaciones" title="Notificaciones" className="pt-2">
            {/* <ListadoAlertas condicion_default={{ }} /> */}
            <GeoAlertasMapa resize={resize} />
            </Tab>
      </Tabs>
  </div>
}

export default Zonas