import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useRef, useState } from "react";
import { urlapi } from "../../lib/backend/data";
import { Button, Col, Modal, Row, Spinner } from 'react-bootstrap';
import Estatico from "./receipts";
import { useReactToPrint } from "react-to-print";


const ImprimirMasivo = (props) => {
    const {
        ids_orders,
    } = props;
    const [showModal, setShowModal] = useState(false);
    const [dataResponse, setDataResponse] = useState(null);
    const [loadingEstados, setLoadingEstados] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const imprimirPedido = async () => {
        try {
            setLoadingEstados(true)
            const pros = await fetch(`${urlapi}/ordenes/imprimir`, {
                method: "POST",
                body: JSON.stringify({ ids_orders }),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer: ${token}`
                },
            });
            const data = await pros.json();
            setDataResponse(data);
            console.log(data, "data");
            if (!data) {
            } else if (data.errorMessage) {
                toast.error(data.errorMessage);
            }
            return setLoadingEstados(false);
        } catch (error) {
            toast.error("Error al consultar la información");
            return setLoadingEstados(false);
        }
    }

    //REACT-TO-PRINT
    const Recibo = useRef();
    const pageStyle = `
        @page {
            size: 62mm;
        }
        @media print {
            .page-break {
            page-break-before: always;
            }
            .main {
            padding:20px;
            }
        }  
        `;
    const handlePrint = useReactToPrint({
      content: () => Recibo.current,
      pageStyle
    });


    if(!ids_orders) return false;
    if(!Array.isArray(ids_orders)) return false;
    if(ids_orders.length < 1) return false;
    console.log(ids_orders, "ids");
    

    return (
        <div style={{ display: "inline-block" }}>
            {/* ... */}
            <Button variant="outline-secondary" 
                className="shadow-sm mb-2 mr-2 mt-0 py-1" 
                onClick={() => { 
                    imprimirPedido(); 
                    setShowModal(true);
                }}>
                <i className="fa-solid fa-print"></i> <b>IMPRIMIR</b>
            </Button>
    
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton className="pb-0">
                    <Modal.Title className="text-primary text-center"><i className="fa-solid fa-print"></i> Impresión de órdenes
                    <p className="text-muted">{ids_orders.length === 1 ? 'Imprimir recibo de 1 orden' : `Imprimir recibos de ${ids_orders.length} órdenes`}</p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="overflow-auto border-top border">
                        { loadingEstados ? <Spinner animation="border" /> : <Button variant='sm' className="btn btn-success shadow-sm mt-0 mb-2 mr-4" onClick={handlePrint}><i className="fa-solid fa-print"></i> <b>IMPRIMIR</b></Button>}
                    <div ref={Recibo} className='pt-2 mt-3 main'>
                    {dataResponse && dataResponse.map((orden, index) => (
                        <div key={index} style={{ pageBreakAfter: 'always' }}>
                            <Estatico orden={orden} />
                            {/* <Row className="d-flex justify-content-center align-items-center my-4">
                                <Col xs={1} className="pr-0"><i className="fa-solid fa-scissors"></i></Col>
                                <Col xs={11} className="pl-0"><hr className="border border-danger border-2 opacity-50"/></Col>
                            </Row> */}
                        </div>
                    ))}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ImprimirMasivo;