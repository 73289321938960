import { useEffect, useState } from "react"
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import data, { urlapi } from '../../../lib/backend/data'
import { eventos, eventoToString } from "../../../lib/helpers/integraciones"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { rutas } from "../../../lib/routes/routes"
import { cerrarSesion } from "../../../redux/actions/session"
import LoadingIntegrations from "../../general/loadingAnimations/loadingIntegrations"

const ApiLess = (props) => {
    const payload_default = {
        format: 'json',
        version: '2022-09'
    }
    const [ openModal, setOpenModal ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ total, setTotal ] = useState(0)
    const [ loadingCreando, setLoadingCreando ] = useState(false)
    const [ loadingForm, setLoadingForm ] = useState(false)
    const [ webhooks, setWebhooks ] = useState([])
    const [ newWebhook, setNewWebhook ] = useState(payload_default)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerWebhooks()
    }, [])

    const obtenerWebhooks = () => {
        return fetch(`${data.urlapi}/integraciones/conexiones/list`,{
            method: "POST",
            body: JSON.stringify({
                condicion: {}
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){
                return setLoading(false)
            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data.datos) !== false){
                setWebhooks(data.datos)
                setTotal(data.total)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoading(false)
        })
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        newWebhook[name] = value
        return setNewWebhook(newWebhook)
    }

    const crearConexion = async () => {
        setLoadingCreando(true)
        return fetch(`${data.urlapi}/integraciones/conexiones`,{
            method:'POST',
            body: JSON.stringify({}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingForm(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingForm(false)
            } else if(res._id){
                toast.success('Creada exitosamente')
                webhooks.unshift(res)
                setWebhooks(prev => [...[], ...webhooks])
            }
            return setLoadingCreando(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreando(false)
        })
    }

    const crearWebhook = () => {
        
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Crear nuevo webhook</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p></p>
            <Row>
                
                <Col md={12}>
                    {
                        loadingForm ? <Spinner animation="border" /> : <Button size="sm" className="mr-3" variant="success" onClick={() => false} >CREAR</Button>
                    }
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const onDeleteItem = (id) => {
        const i = webhooks.findIndex(w => w._id === id)
        webhooks.splice(i,1)
        return setWebhooks(prev => [...[], ...webhooks])
    }

    const mostrarWebhooks = () => {
        if( loading ) return <LoadingIntegrations />

        if(webhooks.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>

        return <div>
            <h5>{webhooks.length} Registros creado</h5>
            {
                webhooks.map(w => {
                    return <Card key={w._id} className="p-3 mb-3">
                        <p className="mb-0">PUNTO DE CONEXIÓN</p>
                        <p style={{ color: "gray" }} className="mb-0" ><b>{urlapi}/integraciones/conexiones/hook/{w._id}</b></p>
                        <Link to={`/${rutas.apiless_detail.slug}/${w._id}`}><Button size="small" variant="outline-primary" style={{ fontSize: 10 }}>VER DETALLES</Button> </Link>
                        { /* <BoxWebhook token={token} webhook={w} onDeleteItem={(id) => onDeleteItem(id)} /> */}
                    </Card>
                })
            }
        </div>
    }

    const addButton = () => {
        if(loadingCreando) return <Spinner animation="border" />
        return <i className="fa-solid fa-circle-plus text-primary hover" onClick={() => crearConexion()}></i>
    }

    return <div>
        <h4>Puntos de conexión sin API {addButton()}</h4>
        {crearWebhook()}
        {mostrarWebhooks()}
    </div>
}

export default ApiLess