import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { urlapi } from "../../lib/backend/data";
import { cerrarSesion } from "../../redux/actions/session";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import SelectorZonas from "../../subComponents/geodata/selector_zonas";
import { AiOutlinePlus } from "react-icons/ai";
import { BiTargetLock } from "react-icons/bi";
import { MdOutlinePlace } from "react-icons/md";
import { estilo_last_mile } from "../../lib/global/styles";


const SelectorZonaAvanzadoFiltro = (props) => {
    const {
        defaultValue,
        title,
        condicion,
        error
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ value, setValue ] = useState(false)
    const [ geocerca, setGeocerca ] = useState(false)
    const [ geocerca2, setGeocerca2 ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const [ loadingGeocerca, setLoadingGeocerca ] = useState(false)
    const [ valorSeleccionado, setValorSeleccionado ] = useState(false)
    const country = useSelector(state => state.pais)
    const [ pais, setPais ] = useState(country)
    const idioma = useSelector(state => state.idioma)
    const [ condicion_selector, setCondicionSelector ] = useState({})
    const session = useSelector(state => state.miusuario)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [geocercaSeleccionada, setGeocercaSeleccionada] = useState(false);

    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/geodata/zonas/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return dispatch(cerrarSesion());
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
              setIsModalOpen(false)
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const onChangeValue = (e) => {
        console.log("onChangeValue====>",e);
        const i = opcionesComplete.findIndex(m => m._id === e)
        if(props.onChange) props.onChange(opcionesComplete[i])
        setValorSeleccionado(opcionesComplete[i])
        setValue(e)
      }
      
      const onChangeValueNivel2 = (e) => {
        console.log("onChangeValueNivel2====>",e)
        if(props.onSelected) props.onSelected(e)
        handleCancel()
        setValue(false)
        setValorSeleccionado(false)
      }

      useEffect(() => {
        obtenerDatos()
      }, [])

      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

   
    

      const handleChangePais = (e) => {
        setCondicionSelector({ pais_code: e.target.value })
        return setPais(e.target.value)
      }



    return <div>
      <h5 className='d-block mb-2' style={{fontWeight:"bold", color: estilo_last_mile.color_primary, fontSize: 17 }} ><MdOutlinePlace size={20} style={{ verticalAlign: "middle" }}  /> Buscar por zona</h5>
      <select className="form-control mb-2" defaultValue={pais} onChange={handleChangePais} >
                <option value="CL">Chile</option>
                <option value="PY">Paraguay</option>
                <option value="BR">Brasil</option>
                <option value="MX">México</option>
                <option value="CO">Colombia</option>
                <option value="PA">Panamá</option>
                <option value="GT">Guatemala</option>
                <option value="PE">Perú</option>
                <option value="SV">Salvador</option>
              </select>
              <SelectorZonas hideLabel={true} condicion_default={condicion_selector} full onChangeValue={data => props.onChangeValue(data)} />

  </div>
    

}

export default SelectorZonaAvanzadoFiltro