import { useEffect, useState } from "react";
import { urlapi } from "../../lib/backend/data";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import ReactSelect from "react-select";
import { useSelector } from "react-redux";

const SelectorZonaTremus = (props) => {
    const {
        key,
        defaultValue,
        title,
        title_button,
        hideButton,
        condicion,
        error
    } = props
    const [ loading, setLoading ] = useState(true)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ opcionesComplete, setOpcionesComplete ] = useState([])
    const [ opciones, setOpciones ] = useState([])
    const [ valorSeleccionado, setValorSeleccionado ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const pais = "CL"
    const valores_default = defaultValue ? defaultValue : []

    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/geodata/zonas/list/select`, {
          method: 'POST',
          body: JSON.stringify({
            condicion
          }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer: ${token}`,
            'country': pais
          },
        })
          .then((res) => {
            if (res.status === 401) return window.location = "/login"
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
              setOpcionesComplete(res)
              if(props.onFetched) props.onFetched(res)
              setOpciones(res.filter(m => m.titulo).map(op => ({ value: op._id, label: op.titulo.toUpperCase() })))
            }
            return setLoading(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false);
          })
      }

      const obtenerDatosPorId = async () => {
        setLoadingDetalle(true)
        return fetch(`${urlapi}/tarifas/geocercas/zonas?id=${valorSeleccionado._id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            // Authorization: `Bearer: ${session.tokenSession}`,
            'country': pais,
            // 'lang': idioma
          },
        })
          .then((res) => {
            if (res.status === 401) return window.location = "/login"
            return res.json();
          })
          .then(async (res) => {
            if (!res) {
                toast.error("Sin datos obtenidos")
            } else if (res.errorMessage) {
                toast.error(res.errorMessage)
            } else if(res._id){
                if(props.onSelected) props.onSelected(res)
                setValorSeleccionado(false)
            }
            return setLoadingDetalle(false);
          })
          .catch((error) => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingDetalle(false);
          })
      }

      const onChangeValue = (e) => {
          const geofences = e.map(e => e.value)
          if(props.onChange) props.onChange(geofences)
      }

      useEffect(() => {
        obtenerDatos()
      }, [  ])

      const values = valores_default ? opciones.filter(zo => valores_default.includes(zo.value)) : []

      if(loading) return <Spinner />
      return <div>
            <Row >
                <Col md={ hideButton === true ? 12 : 9}>
                  <label className="form-control-label d-block">{title ? title : "Zona"}</label>
                <ReactSelect
                    key={key}
                    placeholder="Selecciona una comuna"
                    value={values}
                    style={ error === true ? {
                        width: "100%",
                        border: "1.5px solid red",
                        borderRadius: 8
                    } : { width: "100%" }}
                    showSearch={true}
                    isMulti
                    // filterOption={(input, option) => (option?.label.toUpperCase() ?? '').includes(input.toUpperCase())}
                    loading={loading}
                    options={opciones}
                    onChange={onChangeValue}
                    />
                </Col>
                {
                  hideButton ? false : <Col md={3}>
                  <label className="form-control-label d-block">Click para buscar</label>
                    <Button disabled={valorSeleccionado ? false : true } loading={loadingDetalle} type="primary" onClick={() => obtenerDatosPorId()}>{title_button ? title_button : "SELECCIONAR"}</Button>
                </Col>
                }
                
            </Row>
            
      </div>
}

export default SelectorZonaTremus