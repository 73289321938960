import { useCallback, useEffect, useState } from "react"
import PantallaCargando from "../../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../../redux/actions/session"
import { urlapi } from "../../../lib/backend/data"
import { debounce } from "@mui/material"
import { url_images } from "../../../lib/global/data"
import { MdOutlineSecurity } from "react-icons/md"
import { Badge, Button, ButtonGroup, Card, Col, Row, Spinner } from "react-bootstrap"
import { formatoMoneda } from "../../../lib/helpers/main"
import { FaCheckCircle } from "react-icons/fa";

const UberActivar = (props) => {
    const {
        id_orden
    } = props
    const components = {
        resumen: ''
    }
    const estructura = {
        key: "",
    }
    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState({
        detalles: estructura
    })
    const [ quote, setQuote ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ tarifas, setTarifas ] = useState([])
    const [ confirmData, setConfirmData ] = useState(null)
    const [ confirmando, setConfirmando ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const [ cotizando, setCotizando ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerDatos("uber")
        obtenerDatosOrden()
    }, [])

    const obtenerDatosOrden = async ()=>{
        setConfirmando(true)
        return fetch(`${urlapi}/uber/order`,{
            method:'POST',
            body: JSON.stringify({ id_orden }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setConfirmData(res)
            }
            return setConfirmando(false)
        })
        .catch(error => {
            return setConfirmando(false)
        })
    }

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion?tipo=credenciales&subtipo=${modulo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)
                setEstructuraActual(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    const cotizarUber = async ()=>{
        setCotizando(true)
        return fetch(`${urlapi}/uber/quote?id=${id_orden}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.deliveryOffers)){
                if(res.deliveryOffers.length > 0){
                    setTarifas(res.deliveryOffers)
                    setQuote(res)
                } else {
                    toast.info("No se encontraron tarifas")
                }
            }
            return setCotizando(false)
        })
        .catch(error => {
            return setCotizando(false)
        })
    }

    const confirmar = async (payload)=>{
        const {
            canal
        } = payload
        const {
            estimateId
        } = quote
        setConfirmando(true)
        return fetch(`${urlapi}/${canal}/confirm?id=${id_orden}`,{
            method:'POST',
            body: JSON.stringify({ ...payload, estimateId }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res?._id){
                setConfirmData(res)
            }
            return setConfirmando(false)
        })
        .catch(error => {
            return setConfirmando(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const handleChangeExtra = (e, type, campo) => {
        const valor = e.target.value
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 setPendienteGuardar(true)
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].extra = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    const handleCheck = (e, type, campo) => {
        const valor = e.target.checked
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].onlyRead === true) return false
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        } else if(type === "campos"){
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            if( i_ > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.campos[i_].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.detalles) actual.detalles = {}
            actual.detalles[name] = value
            actualizar(actual)
            return { ...actual }
        })
    }

    const confirmDataMessage = () => {
        return <div>
            <h4><FaCheckCircle color="green" /> Servicio confirmado</h4>
            <h6 className="mb-0">CÓDIGO DE CONFIRMACIÓN</h6>
            <Badge variant="dark">{confirmData?.value}</Badge>
        </div>
    }

    const mostrarInformacion = () => {
        if(confirmando) return <Spinner />
        if(confirmData) return  confirmDataMessage()

        if(!estructuraActual?.detalles?.id_customer || !estructuraActual?.detalles?.secret || !estructuraActual?.detalles?.origen) return <div><MdOutlineSecurity style={{ verticalAlign: "middle" }} /> No se ha configurado la llave de acceso</div>

        return <div>
            <ButtonGroup className="mb-3">
            <Button size="sm" disabled={cotizando} variant="light" onClick={() => cotizarUber()}>{ cotizando ? "COTIZANDO..." : "COTIZAR"}</Button>
            {/* <Button size="sm" disabled={cotizando} variant="primary" >CONFIRMAR</Button> */}
            </ButtonGroup>
            { tarifas.length > 0 ? <div>
                <h4>Tarifas encontradas <Badge variant="dark">{formatoMoneda(tarifas.length)}</Badge></h4>
                <p>Toca la tarifa para confirmar el servicio</p>
            </div> : false }
            {
                tarifas.map((tarifa, i) => {
                    return <Card md={6} key={`tarifa-${i}`} className="p-2">
                            <Row>
                                <Col xs={9}><Badge className="w-100" variant="primary">ID {tarifa.deliveryOfferId}</Badge></Col>
                                <Col xs={3}><Badge className="w-100" variant="dark">{tarifa.deliveryMode}</Badge></Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="text-left" ><h4>CLP {formatoMoneda(tarifa?.pricing?.total)}</h4></Col>
                                <Col className="text-right" ><Button size="sm" variant="light" onClick={() => confirmar({ ...tarifa, id_orden, canal: "uber", key: "uber-order-id" })}>CONFIRMAR</Button></Col>
                            </Row>
                            
                        </Card>
                })
            }
        </div>
    }

    if(loading) return <PantallaCargando />

    return <div>
        <img src={`${url_images}/uber.png`} style={{ height: 50, marginBottom: 10 }} />
        {mostrarInformacion()}
    </div>
}

export default UberActivar