import { useState } from "react"
import { Badge, Button, Card, Col, Modal, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
// import { nombreDNIPorPais } from "../../lib/helpers/data/internationa";
// import CamposObligatoriosLeyenda from "../../subComponents/general/campos_obligatorios";
// import { PiWarehouseDuotone } from "react-icons/pi";
// import { CiRoute } from "react-icons/ci";
// import { AiFillPlusCircle, AiOutlineContacts } from "react-icons/ai";
import { fechaATexto, validateEmail } from "../../lib/helpers/helpers";
// import CamposObligatoriosSimbolo from "../../subComponents/general/campo_requerido";
// import { BsAsterisk, BsFillTrashFill } from "react-icons/bs";
// import SelectorZonasEmpresa from "../../components/Zonas/selector_zonas";
import { modulo, status, types } from "../../subComponents/ordenes/ordenes_tickets/data";
import { LuTicket } from "react-icons/lu";
import { FaCheckCircle, FaRegClock } from "react-icons/fa";
import OrdenMensajesListado from "./ticket_mensajes";
import NewMessageTicket from "./crear_mensaje";
// import CargaImagenes from "../../general/carga_imagenes";
// import { IoIosAttach } from "react-icons/io";
// import ModalOrdenesEditar from "../../recolecciones/modal_ordenes_editar";
// import { estilo_last_mile } from "../../../lib/global/styles";
// import ModalListadoOrdenes from "../modal_listado";

const DetailFull = (props) => {
    const {
        id_orden
    } = props
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [ condicionMensajes, setCondicionMensajes ] = useState({ id_ticket: proveedor._id })
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const [ ordenSeleccionada, setOrdenSeleccionada ] = useState(false)
    const [ archivos, setArchivos ] = useState([])
    const pais = useSelector(state => state.pais)
    // const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()
    const [showModal, setShowModal] = useState(false);

    
    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleCloseTicket = (id) => {
        if(props.onCloseTicket) props.onCloseTicket(id)
    }

    const onNewMessage = () => {
        return setCondicionMensajes({ id_ticket: proveedor._id })
    }
    const View = () => {
        if(!proveedor) return <div><h5>Sin Informacion...</h5></div>

        const tipos = {}
        types.map(t => tipos[t.value] = t.label)
        return <div>
            <div className=" mb-2">
            <h4 className="mb-2 text-uppercase" style={{fontWeight:900}}><LuTicket /> {proveedor.titulo}</h4>
            <Row>
                <Col><p className="mb-0" style={{ fontSize: 12 }}><FaRegClock /> <b style={{ fontWeight: "bold"}}>Creado</b> {fechaATexto(proveedor.createdAt)}</p></Col>
                <Col className="text-right"><p className="mb-0" style={{ fontSize: 12 }}><FaRegClock /> <b style={{ fontWeight: "bold"}}>Última actualización</b> {fechaATexto(proveedor.updatedAt)}</p></Col>
            </Row>
            <hr/>
            <Badge variant="secondary" style={{ textTransform: "uppercase" }} className="mb-2 mr-2">{tipos[proveedor.type]}</Badge>
            <Badge variant={proveedor.open ? "success" : "dark"} style={{ textTransform: "uppercase" }} className="mb-2">{proveedor.open ? "ABIERTO" : "CERRADO"}</Badge>
            <p className="mb-0" style={{ fontWeight: "bold" }}><FaRegClock /> {status[proveedor.status]}</p>
            
            
            </div>
            <Card className="mb-3 p-2">
            <label className="form-control-label d-block mb-0" style={{fontWeight:700}}>Descripción del ticket</label>
            <p className="mb-0">{proveedor.titulo}</p>

            </Card>
            {
                     proveedor.open ?
                    <Button size='m' style={{fontSize: "14px"}} className='mb-3 pr-2 pl-2 pt-1 pb-1' onClick={()=>handleCloseTicket(proveedor._id)}>Cerrar Ticket</Button>
                     : false
            }
            {
                     proveedor.open ?
                     <NewMessageTicket id_ticket={proveedor._id} onNewMessage={(data) => onNewMessage(data)} />
                     : false
            }
            <OrdenMensajesListado condicion_default={condicionMensajes} /> 
            <Row>
        {/* <Col md={4} className="mb-3">
                    <SelectorZonasEmpresa defaultValue={proveedor.id_zona} titulo="Zona a asignar" onChange={handleChangeZona} />
                </Col>
                <Col xs={12}>
                <div className="d-flex justify-content-between mb-2">
                <h5 style={{fontWeight:900}}><i className="fa-solid fa-envelope"></i> Emails adicionales</h5>
                <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirEmailAdicionalActual()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                </div>
                    {mostrarEmailsAdicionalesActual()}
                    <div className="d-flex justify-content-between my-2">
                    <h5 style={{fontWeight:900}}><i className="fa-solid fa-phone"></i> Teléfonos adicionales</h5>
                    <Button size='sm' className="py-0 shadow-sm" variant='secondary' onClick={() => anadirMovilAdicionalActual()}><i className="fa-solid fa-plus"></i><b> Agregar nuevo</b></Button>
                    </div>
                    {mostrarMovilesAdicionalesActual()}
                </Col> */}
        {/* <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" className='shadow-sm w-100 my-3' variant="primary" onClick={()=>guardarCambios()} ><b>GUARDAR CAMBIOS</b></Button>
            }
        </Col> */}
        </Row>
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull