import { Card, Col, Row } from "react-bootstrap"
import { MdOutlineChecklistRtl, MdOutlineModeEditOutline } from "react-icons/md"
import { Link, useLocation } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { TbPresentationAnalytics } from "react-icons/tb"
import { estilo_last_mile } from "../../lib/global/styles"

const NavigationCentinela = (props) => {
    let { pathname } = useLocation();
    const iconSize = 17
    const estilo_iconos = {
        style: { fontSize: iconSize, verticalAlign: "middle" }
    }
    
    return <div>
        <Row className="d-flex justify-content-between mx-2">
        <h3 style={{ fontWeight: 900, color: estilo_last_mile.color_primary }}><i className="fa-solid fa-eye"></i> Centinela</h3>
        <Col className="mb-3">
            <Row className="d-flex justify-content-end">
                <Link to={`/${rutas.centinela_rules.slug}`}>
                    <Card className="shadow p-3 text-center hover" style={{ border: pathname === `/${rutas.centinela_rules.slug}` ? `2px solid ${estilo_last_mile.color_primary}` : 'none' }}>
                        <h6 className="mb-0" style={{ fontWeight: "bold" }}><MdOutlineChecklistRtl  {...estilo_iconos} /> Listado de reglas</h6>
                    </Card>
                </Link>

                <Link to={`/${rutas.centinela.slug}`}>
                    <Card className="shadow p-3 text-center hover mx-3" style={{ border: pathname === `/${rutas.centinela.slug}` ? `2px solid ${estilo_last_mile.color_primary}` : 'none' }}>
                        <h6 className="mb-0" style={{ fontWeight: "bold" }}><TbPresentationAnalytics {...estilo_iconos} /> Dashboard Analítico</h6>
                    </Card>
                </Link>

                <Link to={`/${rutas.centinela_nuevo.slug}`}>
                    <Card className="shadow p-3 text-center hover" style={{ border: pathname === `/${rutas.centinela_nuevo.slug}` ? `2px solid ${estilo_last_mile.color_primary}` : 'none' }}>
                        <h6 className="mb-0" style={{ fontWeight: "bold" }}><MdOutlineModeEditOutline {...estilo_iconos} /> Reglas de negocio</h6>
                    </Card>
                </Link>

            </Row>
        </Col>
        </Row>
    </div>
}

export default NavigationCentinela