export const stringByStatusRecolection = (status) => {
    switch (status) {
        case "pending":
            return "Pendiente de pago"
        case "confirmed":
            return "Confirmada"
        case "recolected":
            return "Recolectado"
        case "canceled":
            return "Cancelado"
        default:
            return status
    }
}