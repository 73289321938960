import { useRef, useState } from "react"
import Map, { Layer, Marker, Source } from 'react-map-gl';
import { Accordion, Button, Card, Col, Modal, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { BiCycling, BiSolidChevronRight, BiTime } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"
import { mapbox_token, maps_key, urlapi } from "../../../lib/backend/data"
import { obtenerCentroMapaPorPais } from "../../../lib/helpers/data/internationa"
import { cerrarSesion } from "../../../redux/actions/session";
import { toast } from "react-toastify";
import Pin from "../../../subComponents/mapas/pin";
import * as turf from '@turf/turf';
import { calcularDistancia, procesarCoordenadasParaCentroMapa } from "../../../lib/helpers/maps/geocercas";
import { calcularCentroYZoom } from "../../../lib/helpers/maps";
import polyline from '@mapbox/polyline';
import { FaRoute, FaTrafficLight } from "react-icons/fa";
import { FiMapPin } from "react-icons/fi";
import { formatTime } from "../../../lib/helpers/dates";
import { BsSpeedometer } from "react-icons/bs";
import { formatoMoneda } from "../../../lib/helpers/main";
import { LiaRouteSolid } from "react-icons/lia";
import { url_images } from "../../../lib/global/data";
import { estilo_last_mile } from "../../../lib/global/styles";
import { AiFillCar, AiFillCheckCircle, AiOutlinePlusCircle } from "react-icons/ai";

const tipos_direccion = ["geocode"]

const IngresoDirecciones = (props) => {
    const {
        height
    } = props
    const pais = useSelector(state => state.pais)
    const sesion = useSelector(state => state.miusuario)
    const [ origen, setOrigen ] = useState(false)
    const [ destino, setDestino ] = useState(false)
    const default_points_value = [ { } ]
    const [ puntos, setPuntos ] = useState(default_points_value)
    const [ showModal, setShowModal ] = useState(false)
    const [ distancia_maxima, setDistanciaMaxima ] = useState(1)
    const [ loading, setLoading ] = useState(false)
    const defaultViewState = obtenerCentroMapaPorPais(pais)
    const [viewState, setViewState] = useState(defaultViewState);
    const [ routes, setRoutes ] = useState([]) 
    const [ wayPoints, setWayPoints ] = useState([]) 
    const dispatch = useDispatch()
    const mapRef = useRef()

    const alinearMapa = (markers) => {
        // const coords = procesarCoordenadasParaCentroMapa(markers.map(w => w.location))
        // const centro = calcularCentroYZoom(coords)

        const points = markers.map(marker => turf.point([marker.location[0], marker.location[1]]));
                            const collection = turf.featureCollection(points);
                            const bounds = turf.bbox(collection);
                            console.log({ bounds })
                            const newViewport = {
                                ...viewState,
                                latitude: (bounds[1] + bounds[3]) / 2,
                                longitude: (bounds[0] + bounds[2]) / 2
                            };

                            const options = {
                                padding: 30 // Ajusta el valor de padding según tus necesidades
                            };

                            setViewState(newViewport);
                            mapRef.current?.fitBounds(bounds, options);
    }

    const validarRespuestaRutaMapbox = (sugerencias) => {
        let way_points          = []
        let rutas_consultadas   = []
        let errores_mensajes    = []

        for( const res of sugerencias){
            if(res.uuid){
                if(res.code) if(res.code.toString().toUpperCase() === "OK") {
                    if(res.routes) if(Array.isArray(res.routes) !== false) if(res.routes.length > 0){
                        const adherir_uuid = res.routes.map(r => {
                            r.uuid = res.uuid
                            return r
                        })
                        rutas_consultadas = [...rutas_consultadas, ...res.routes]
                        way_points = [...way_points, ...res.waypoints]
                    }
                } else {
                    errores_mensajes.push(res.message)
                }
            }
        }
        if(errores_mensajes.length > 0) toast.warn(errores_mensajes.join(', '))
        setWayPoints(way_points)
        alinearMapa(way_points)
        setRoutes(rutas_consultadas)
    }

    const buscarDirecciones = async () => {
        setLoading(true)
        return fetch(`${urlapi}/geodata/direcciones`,{
            method:'POST',
            body: JSON.stringify({
                origen,
                puntos
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${sesion.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                validarRespuestaRutaMapbox(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    const mostrarPasos = (legs) => {
        if(!legs) return false
        if(Array.isArray(legs) !== true) return false
        if(legs.length < 1) return false

        return <div>
            {
                legs.map((leg,il) => {
                    
                    let cantidad_puntos = 0
                    if(leg.steps) if(Array.isArray(leg.steps) !== false) cantidad_puntos = leg.steps.length

                    return <div key={leg.summary}>
                        <p style={{ fontSize: 14, fontWeight: "bold" }} className="mb-0">{leg.summary}</p>
                        <p style={{ fontSize: 12 }} className="mb-0">{cantidad_puntos} Puntos de control</p>
                    </div>
                })
            }
        </div>
    }


    const handleMouseLeave = () => {
        const demarcar = routes.map((route) => {
            route.selected = false
            return route
        })
        return setRoutes([...[], ...demarcar])
    }

    const generateCircularCoordinates = (latitude, longitude, radiusInMeters) => {
        const center = [longitude, latitude];
        const radiusInKilometers = radiusInMeters / 1000;
        const numPoints = 20
        const coordinates = [];

        for (let i = 0; i < numPoints; i++) {
            const angle = (i / numPoints) * 2 * Math.PI;
            const dx = radiusInKilometers * Math.cos(angle);
            const dy = radiusInKilometers * Math.sin(angle);

            const point = [
            center[0] + (dx / (111.32 * Math.cos(center[1]))),
            center[1] + (dy / 111.32)
            ];

            coordinates.push(point);
        }

        // Cierre de la forma
        coordinates.push(coordinates[0]);

      
        return coordinates;
      };
      
      const generateSquareCoordinates = (latitude, longitude, sideLengthInMeters) => {
        const center = [longitude, latitude];
        const halfSideLengthInKilometers = sideLengthInMeters / 2000;
      
        const coordinates = [
          [center[0] - halfSideLengthInKilometers, center[1] - halfSideLengthInKilometers],
          [center[0] + halfSideLengthInKilometers, center[1] - halfSideLengthInKilometers],
          [center[0] + halfSideLengthInKilometers, center[1] + halfSideLengthInKilometers],
          [center[0] - halfSideLengthInKilometers, center[1] + halfSideLengthInKilometers],
          [center[0] - halfSideLengthInKilometers, center[1] - halfSideLengthInKilometers], // Cierre del cuadrado
        ];
      
        return coordinates;
      };

      const escogerElemento = (ruta) => {
        const demarcar = routes.map((route) => {
            if(route.uuid !== ruta.uuid) return route
            route.checked = false
            return route
        })
        const pos = routes.findIndex(ro => ro.distance === ruta.distance)
        demarcar[pos].checked = true

        return setRoutes([...[], ...demarcar])
      } 

      const reducirArray = (array, nuevoTamano) => {
        if (nuevoTamano >= array.length) return array; // Si el nuevo tamaño es mayor o igual, devolver el array original
      
        let resultado = [];
        const cadaN = Math.floor(array.length / (nuevoTamano - 1)); // Calcular cada cuántos elementos tomar uno
      
        for (let i = 0; i < array.length; i += cadaN) {
          resultado.push(array[i]);
        }
      
        // Asegurar que el último elemento siempre esté incluido
        if (resultado.length > nuevoTamano) {
          // Si se excede el tamaño, ajustar
          resultado.pop(); // Remover el último elemento antes de agregar el final para evitar duplicados
        }
        if (resultado[resultado.length - 1] !== array[array.length - 1]) {
          resultado.pop(); // Asegurarse de remover un elemento extra si el último ya está presente
          resultado.push(array[array.length - 1]);
        }
      
        return resultado;
      }

    const confirmarSeleccion = (route) => {
        let coordenadas         = []
        let puntos              = []
        let geometries          = []
        let nodos               = []
        let nodos_calculados    = []
        const rutas_seleccionadas = routes.filter(ro => ro.checked)

            for( const route of rutas_seleccionadas ){
                let titulo_ruta = ''
                if(route.legs) if(Array.isArray(route.legs) !== false) if(route.legs.length > 0){
                    let leg = route.legs[0]
                    if(typeof leg === "object") if(leg.summary) titulo_ruta = leg.summary
                }
                if(route.geometry) geometries.push({
                    titulo_ruta,
                    weight: route.weight,
                    duration: route.duration,
                    distance: route.distance,
                    geometry: route.geometry
                })
                if(route.legs) if(Array.isArray(route.legs) !== false) if(route.legs.length > 0){
                    const leg = route.legs[0]
                    let cantidad_puntos = 0
                    if(leg.steps) if(Array.isArray(leg.steps) !== false) cantidad_puntos = leg.steps.length
                    if(cantidad_puntos > 0){
                        for( const item of leg.steps ){
                            if(item.maneuver) if( typeof item.maneuver === "object") if(item.maneuver.location) if(Array.isArray(item.maneuver.location) !== false) if(item.maneuver.location.length > 1){
                                
                                let titulo_geocerca = ''
                                if(item.name) titulo_geocerca = item.name
                                // if(item.name) titulo_geocerca = item.name
                                if(!titulo_geocerca) continue
                                
                                const nueva = {
                                    titulo: titulo_geocerca ? titulo_geocerca : "Punto de control",
                                    type: "Feature",
                                    properties: {},
                                    geometry: {
                                        type: "Polygon",
                                        coordinates: [generateSquareCoordinates(item.maneuver.location[1], item.maneuver.location[0], 1)]
                                    }
                                }
                                const findI = nodos.findIndex(c => c.titulo === nueva.titulo)

                                if(findI < 0) {


                                    nodos.push({
                                        titulo: nueva.titulo,
                                        coordinates: [
                                            item.maneuver.location[1], item.maneuver.location[0]
                                        ]
                                    })
                                    // coordenadas.push(nueva)
                                    // puntos.push(item.maneuver.location[1], item.maneuver.location[0])
                                }
                            }
                        }
                    }
            }
        }
        
        // let coordenadasReducido = reducirArray(coordenadas, 10);
        // let puntosReducido = reducirArray(puntos, 10);
        
        let distancia = 0
        nodos.map((nodo,ino) => {
            if(!ino) return nodos_calculados.push(nodo)
            if(ino === nodos.length - 1) return nodos_calculados.push(nodo)
            const calculo = calcularDistancia({ latitude: nodos[ino-1].coordinates[1], longitude: nodos[ino-1].coordinates[0] }, { latitude: nodo.coordinates[1], longitude: nodo.coordinates[0] })
            const sumado = distancia + (calculo)
            distancia = sumado
            console.log({ calculo, ino, distancia })
            if( sumado >= distancia_maxima ){
                nodos_calculados.push(nodo)
                distancia = 0
            }
        })

        puntos = nodos_calculados.map(n => {
            return (n.coordinates[0], n.coordinates[1])
        })

        coordenadas = nodos_calculados.map(n => {
            return {
                titulo: n.titulo,
                type: "Feature",
                properties: {},
                geometry: {
                    type: "Polygon",
                    coordinates: [generateSquareCoordinates(n.coordinates[0], n.coordinates[1], 1)]
                }
            }
        })

        if(props.onSelectRoute) props.onSelectRoute({
            route,
            coordenadas,
            geometries,
            puntos
        })
        handleClose()
    }

    const handleMouseEnter = (e, ruta) => {
        const demarcar = routes.map((route) => {
            route.selected = false
            return route
        })
        const pos = routes.findIndex(ro => ro.distance === ruta.distance)
        demarcar[pos].selected = true

        return setRoutes([...[], ...demarcar])
    }

    const acciones = () => {
        
        let disabled = false
        if(loading === true) disabled = true
        if(!origen || !destino ) disabled = true

        return <div>
            <Button disabled={loading} size="sm" className="w-100" onClick={() => buscarDirecciones()}>{loading ? "BUSCANDO RUTAS..." : "CONSULTAR"}</Button>
        </div>
    }

    const handleClose = () => {
        setOrigen(false)
        setDestino(false)
        setRoutes([])
        setWayPoints([])
        return setShowModal(false)
    }

    const handleChangePunto = (e) => {
        console.log(e)
    }

    const agregarPunto = () => {
        puntos.push({})
        return setPuntos([...[], ...puntos])
    }

    const eliminarPunto = (i) => {
        if(puntos.length < 2) return false
        puntos.splice(i,1)
        return setPuntos([...[], ...puntos])
    }

    const mostrarPuntos = () => {
        return <div className="mt-2">
            <label className="form-control-label d-block"><FiMapPin /> Destinos</label>
            {
                puntos.map((point,ip) => {
                    return <div key={`p-${ip}`} className="mb-2">
                            <ReactGoogleAutocomplete
                    className="form-control"
                    placeholder="Ingresa una dirección destino"
                    options={{
                        types: tipos_direccion,
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onChange={handleChangePunto}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          let level_3 = ''

                          const buscar_level_3 = place.address_components.findIndex(comp => {
                            return comp.types.includes('administrative_area_level_3')
                        })
                        if(buscar_level_3 > -1) level_3 = place.address_components[buscar_level_3].long_name

                          const geo_data = {
                              direccion: place.formatted_address,
                              place,
                              administrative_area_level_1_: region,
                              administrative_area_level_2: comuna,
                              administrative_area_level_3: level_3,
                              status: true,
                              location: {
                                type: "Point",
                                coordinates: [
                                    lng, lat
                                ]
                              }
                          }

                          puntos[ip] = geo_data
                                                    
                          return setPuntos([...[], ...puntos])
                    }}
                    />
                    <p className="text-danger mt-1 hover" onClick={() => eliminarPunto(ip)} style={{ fontSize: 11, fontWeight: "bold" }}>ELIMINAR PUNTO</p>
                    </div>
                })
            }
            <Button size="sm" variant="light" className="w-100 mb-2" onClick={() => agregarPunto()} ><AiOutlinePlusCircle /> AGREGAR NUEVO</Button>
            {acciones()}
        </div>
    }

    const pantallaVacia = () => {
        return <div className="text-center p-3">
            <h5 className="mb-0" style={{ color: estilo_last_mile.color_primary, fontWeight: "bold" }}>Aquí verás las rutas sugeridas a tus puntos de control</h5>
            <img style={{ maxWidth: 150 }} src={`${url_images}/animations/Holding Map Paper And Finding Navigation.gif`} />
        </div>
    }

    const handleChangeDistanciaMaxima = (e) => {
        const { name, value } = e.target
        setDistanciaMaxima(parseInt(value))
    }

    const accionesConfirmacion = () => {

        let tramos      = []
        let validos     = 0
        let disabled    = true

        for( const route of routes ){
            const i = tramos.findIndex(rou => rou.uuid === route.uuid)
            if(i > -1){
                tramos[i].routes.push(route)
            } else {
                tramos.push({ uuid: route.uuid, routes: [ route ] })
            }
        }

        for( const tramo of tramos ){
            const seleccionados = tramo.routes.filter(r => r.checked).length
            if(seleccionados) validos++
        }

        if(tramos.length <= validos) disabled = false

        return <div>
            <label className="form-control-label d-block">Mínimo de distancia para notificación (KM)</label>
            <input className="form-control mb-3" type="number" defaultValue={distancia_maxima} onChange={handleChangeDistanciaMaxima} />
            <Button disabled={disabled} variant="success" className="w-100" size="sm" onClick={() => confirmarSeleccion()} ><AiFillCheckCircle /> CONFIRMAR</Button>
        </div>   
    }

    const mostrarRutas = () => {

        if(routes.length < 1) return pantallaVacia()

        let tramos = []

        for( const route of routes ){
            const i = tramos.findIndex(rou => rou.uuid === route.uuid)
            if(i > -1){
                tramos[i].routes.push(route)
            } else {
                tramos.push({
                    uuid: route.uuid,
                    routes: [ route ]
                })
            }
        }

        return <div>
            <h6 className="mb-0 mt-3"><LiaRouteSolid /> {routes.length} RUTAS DISPONIBLES</h6>
                <p>Selecciona la que deseas rastrear</p>
                <Accordion defaultActiveKey={''} className="mb-3">
                {
                    tramos.map((tramo,ir) => {
                        // if(!ir) cantidad_segmentos++
                        // let separacion = false
                        // if(ir) if(route.uuid) if(route.uuid !== routes[ ir-1].uuid ){
                        //     cantidad_segmentos++
                        //     separacion = true
                        // }

                        const seleccionados = tramo.routes.filter(r => r.checked).length

                        const titulo = <Accordion.Toggle className="border-0" as={Card.Header} style={{ padding: '0px 5px', fontSize: 12, backgroundColor:'white' }} eventKey={tramo.uuid}>
                                    <div>
                                    <h5 className="mb-0" style={{ fontWeight: "bold" }}><FiMapPin />Tramo {(ir+1)} </h5>
                                    <h6  style={{ fontWeight: "bold" }}>{tramo.routes.length} Alternativas {seleccionados ? <AiFillCheckCircle color={estilo_last_mile.color_success} /> : false}</h6>
                                    </div>
                                </Accordion.Toggle>
                        
                        return <Card className="p-2 mb-2 hover shadow">
                            {titulo}
                            <Accordion.Collapse eventKey={tramo.uuid}>
                            <Card.Body className="p-1">
                                {
                                    tramo.routes.map((route,pos) => {
                                        return <div key={`route${route.uuid}-${pos}`}
                                            onMouseEnter={(e) => handleMouseEnter(e,route)}
                                            onMouseLeave={handleMouseLeave}  
                                        >
                                        {mostrarPasos(route.legs)}
                                        <p className="mb-0" style={{ fontSize: 12 }}><BiTime size={15} /> Tiempo <b style={{ fontWeight: "bold" }}>{formatTime(route.duration / 60)}</b></p>
                                        <p className="mb-0" style={{ fontSize: 12 }}><BsSpeedometer size={13} /> Distancia <b style={{ fontWeight: "bold" }}>{formatoMoneda(parseInt(route.distance / 1000))} Km.</b></p>
                                        <div className="mb-2"></div>
                                        <Button size="sm" disabled={route.checked} variant="light" className="w-100" onClick={() => escogerElemento(route)}>{ route.checked ? <div><AiFillCheckCircle color={estilo_last_mile.color_success} /> SELECCIONADO</div> : "SELECCIONAR"}</Button>
                                        </div>
                                    })
                                }
                            {/* <p className="mb-0" style={{ fontWeight: "bold" }}>Resumen de ruta</p> */}
                            
                           </Card.Body>
                           </Accordion.Collapse>
                        </Card>
                    })
                }
                </Accordion>
                {accionesConfirmacion()}
        </div>
    }
    
    const formularioIngresoData = () =>{
        return <div>
            <Row className="mb-2">
                    <Col className="text-center">
                    <OverlayTrigger key={'right'} overlay={ <Tooltip style={{ fontSize:12 }}>Automóvil</Tooltip> }>
                        <Card className="p-2" style={{ border: `2px solid black` }}><AiFillCar style={{ margin: "0 auto" }} size={25} /> </Card>
                    </OverlayTrigger>
                    </Col>
                    <Col className="text-center">
                    <OverlayTrigger key={'right'} overlay={ <Tooltip style={{ fontSize:12 }}>Automóvil con tráfico <b>no disponible</b></Tooltip> }>
                        <Card className="p-2"><FaTrafficLight color="gray" style={{ margin: "0 auto" }} size={25} /> </Card>
                    </OverlayTrigger>
                    </Col>
                    <Col className="text-center">
                    <OverlayTrigger key={'right'} overlay={ <Tooltip style={{ fontSize:12 }}>Bicicleta <b>no disponible</b></Tooltip> }>
                    <Card className="p-2"><BiCycling color="gray" style={{ margin: "0 auto" }} size={25} /></Card>
                    </OverlayTrigger>
                    </Col>
                </Row>
            <label className="form-control-label d-block"><FiMapPin /> Origen</label>
            <ReactGoogleAutocomplete
                    className="form-control"
                    options={{
                        types: tipos_direccion,
                        // cokmponentRestrictions: { country: pais },
                      }}                    
                    apiKey={maps_key}
                    onPlaceSelected={(place) => {
                        if(!place) return false
                          const lat = place.geometry.location.lat()
                          const lng = place.geometry.location.lng()

                          let region = ''
                          const buscar_region = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_1')
                          })
                          if(buscar_region > -1) region = place.address_components[buscar_region].long_name

                          let comuna = ''
                          const buscar_comuna = place.address_components.findIndex(comp => {
                              return comp.types.includes('administrative_area_level_2')
                          })
                          if(buscar_comuna > -1) comuna = place.address_components[buscar_comuna].long_name

                          let level_3 = ''

                          const buscar_level_3 = place.address_components.findIndex(comp => {
                            return comp.types.includes('administrative_area_level_3')
                        })
                        if(buscar_level_3 > -1) level_3 = place.address_components[buscar_level_3].long_name

                          const geo_data = {
                              direccion: place.formatted_address,
                              place,
                              administrative_area_level_1_: region,
                              administrative_area_level_2: comuna,
                              administrative_area_level_3: level_3,
                              status: true,
                              location: {
                                type: "Point",
                                coordinates: [
                                    lng, lat
                                ]
                              }
                          }
                                                    
                          return setOrigen({...{}, ...geo_data})
                    }}
                    />
                    <hr />
                    {mostrarPuntos()}
        </div>
    }

    const mostrarMapa = () => {
        return <Map
        ref={mapRef}
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        style={{width: "100%", height: height ? height : 450}}
        pitch={25}
        // mapStyle="mapbox://styles/mapbox/light-v9"
        mapStyle="mapbox://styles/mapbox/streets-v9"
        mapboxAccessToken={mapbox_token}
    >
        {
            wayPoints.map(point => {
                return <Marker
                key={point.name}
                longitude={point.location[0]}
                latitude={point.location[1]}
                >
                    <Pin />
                </Marker>
            })
        }
        {
            routes.map((ruta,ir) => {

                let pintar = ruta.selected === true
                const decodedCoords = polyline.decode(ruta.geometry)
                if(ruta.checked !== true){
                    const hay_seleccionado = routes.filter(r => r.selected === true).length
                    if(hay_seleccionado > 0) if(ruta.selected !== true) return false
                } else {
                    pintar = true
                }
                
                return <Source
                    id={`route-${ir}`}
                    type='geojson'
                    data={{
                        type: 'Feature',
                        properties: {},
                        geometry: {
                            type: "LineString",
                            coordinates: decodedCoords.map(co => [co[1], co[0]])
                        }
                      }}
                >
                    <Layer
                    id={`route-layer-${ir}`}
                    type="line"
                    layout={{
                        'line-join': 'round',
                        'line-cap': 'round',
                      }}                
                    paint={{
                        'line-color': pintar ? 'black' :'gray',
                        'line-width': 5,
                        'line-opacity': 0.75
                    }}
                />
                </Source>
            })
        }
    </Map>
    }

    const mostrarFormulario = () => {
        return <div>
        <Row>
            <Col md={3} className="mb-3" style={{ height: height ? height : 450, overflowY: "scroll" }}>{formularioIngresoData()}</Col>
            <Col md={6}>{mostrarMapa()}</Col>
            <Col xs={3} style={{ height: height ? height : 450, overflowY: "scroll" }}>{mostrarRutas()}</Col>
        </Row>
        </div>
    }

    return <div>
            <Button size="sm" variant="light" className="w-100" onClick={() => setShowModal(true)}><FaRoute /> CALCULAR RUTA</Button>
            <Modal show={showModal} size="xl" onHide={handleClose} centered >
                <Modal.Header closeButton>
                <Modal.Title>Busca la ruta indicada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {mostrarFormulario()}
                </Modal.Body>
            </Modal>
    </div>

}

export default IngresoDirecciones