import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"

const VisualizarRangos = (props) => {
    const { rangos } = props
    if(!rangos) return false


    return <div>
        <Row>
        {  rangos.map((rango,irango) => {
            return <Col key={`rango${irango}`} md={2} >
                <OverlayTrigger
                    placement={'top'}
                    overlay={<Tooltip>Peso volumétrico</Tooltip>}
                    >
                    <Card className="p-2 mb-3">
                        {/* <h6 className="mb-3" style={{ textTransform: "uppercase" }}>{rango.titulo}</h6> */}
                            <Row style={{ lineHeight: "15px" }}>
                                <Col className="text-left">{(rango.peso_desde/1000).toFixed(0)}<br/><b style={{ fontSize:10 }}>KG</b></Col>
                                <Col className="text-right">{(rango.peso_hasta/1000).toFixed(0)}<br/><b style={{ fontSize:10 }}>KG</b></Col>
                            </Row>
                        </Card>
                </OverlayTrigger>
            </Col>
        })}
        </Row>
    </div>
}

export default VisualizarRangos