export const modulos_notificaciones = {
    opciones: [
        {
            slug: "tickets",
            label: "Tickets",
            opciones: [
                {
                    slug: "nuevo",
                    label: "Creación de nuevo ticket",
                    descripcion: "Se ha creado un nuevo ticket"
                },
                {
                    slug: "actualizado",
                    label: "Actualización de ticket",
                    descripcion: "Se ha actualizado un ticket"
                },
                {
                    slug: "asignado",
                    label: "Asignación de ticket",
                    descripcion: "Se ha asignado un ticket"
                },
                {
                    slug: "comentario",
                    label: "Nuevo comentario",
                    descripcion: "Se ha agregado un nuevo comentario"
                },
                {
                    slug: "cerrado",
                    label: "Cierre de ticket",
                    descripcion: "Se ha cerrado un ticket"
                }
            ]
        },
        {
            slug: "rutas",
            label: "Rutas",
            opciones: [
                {
                    slug: "nueva",
                    label: "Creación de nueva ruta",
                    descripcion: "Se ha creado una nueva ruta"
                },
                {
                    slug: "actualizada",
                    label: "Actualización de ruta",
                    descripcion: "Se ha actualizado una ruta"
                },
                {
                    slug: "asignada",
                    label: "Asignación de ruta",
                    descripcion: "Se ha asignado una ruta"
                },
                {
                    slug: "completada",
                    label: "Ruta completada",
                    descripcion: "Se ha completado una ruta"
                }
            ]
        },
        {
            slug: "expediciones",
            label: "Expediciones",
            opciones: [
                {
                    slug: "completada",
                    label: "Expedición completada",
                    descripcion: "Se ha completado una expedición"
                }
            ]
        }
    ]
}