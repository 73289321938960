const { DateTime } = require("luxon")

const control = {}

control.tiposServicioLogistica = () => {
    const datos = [
        { value: "23", label: 'Contenedores FCL'},
        { value: "24", label: 'Camiones LTL'},
        { value: "25", label: 'Condolidado Marítimo LCL'},
        { value: "26", label: 'Camiones FTL'},
        { value: "27", label: 'Bodega'},
        { value: "28", label: 'Aeropuerto'},
        { value: "100", label: 'Ecommerce Logística'},
        { value: "101", label: 'Bodega Cross Docking'},
        { value: "102", label: 'Bodegas Fullfilment'},
        { value: "103", label: 'Proyectos'},
    ]
    return datos
}

control.showByTipoServicio = (tipo) => {
    
    switch (tipo) {
        case "23": 
        return 'Contenedores FCL'
        case "24": 
            return 'Camiones LTL'
        case "25": 
            return 'Condolidado Marítimo LCL'
        case "26": 
            return 'Camiones FTL'
        case "27": 
            return 'Bodega'
        case "28": 
            return 'Aeropuerto'
        case "100": 
            return 'Ecommerce Logística'
        case "101": 
            return '>Bodega Cross Docking'
        case "102": 
            return 'Bodegas Fullfilment'
        case "103": 
            return 'Proyectos'
        default:
            return 'Ecommerce Logística'
    }

}

control.statusColor = (level) => {

    let color = "#dbdbde";

if ( level == 1 ) {
color = "#cad004";
} else if( level == 2 ){
color = "#d09d04";
} else if (level == 3) {
color = "#d03e04";
} else if (level == 4 ) {
color = "#5e72e4";
} else if (level == 5) {
color = "#64b612";
} else if (level == 6){
color = "#64b612";
} else if (level == 7){
color = "#64b612";
} else if (level == 9){
color = "#ff71a6";
} else if (level == 18){
color = "#a31be1";
} else if (level == 13){
color = "#000";
} 

return color;

}

control.statusString = (status) => {

let cadena = "";

if (status) {
if ( status == 1 ) {
cadena = "Preparado";
} else if( status == 2 ){
cadena = "Leído en el móvil";
} else if (status == 3) {
cadena = "Retirado en origen";
} else if (status == 4 ) {
cadena = "En reparto";
} else if (status == 5) {
cadena = "Entregado";
} else if (status == 6){
cadena = "Entrega Confirmada";
} else if (status == 7){
cadena = "Entrega Confirmada";
} else if (status == 9){
cadena = "Retiro de bodega";
} else if (status == 18){
cadena = "En CD Delpa";
} else if (status == 13){
cadena = "No entregado";
} else {
cadena = "Sin datos";
}
} else {
cadena = "Sin datos";
}
return cadena;
}

const statusString = (status) => {

    let cadena = "";
    
    if (status) {
    if ( status == 1 ) {
    cadena = "Preparado";
    } else if( status == 2 ){
    cadena = "Leído en el móvil";
    } else if (status == 3) {
    cadena = "Retirado en origen";
    } else if (status == 4 ) {
    cadena = "En reparto";
    } else if (status == 5) {
    cadena = "Entregado";
    } else if (status == 6){
    cadena = "Entrega Confirmada";
    } else if (status == 7){
    cadena = "Entrega Confirmada";
    } else if (status == 9){
    cadena = "Retiro de bodega";
    } else if (status == 18){
    cadena = "En CD Delpa";
    } else if (status == 13){
    cadena = "No entregado";
    } else {
    cadena = "Sin datos";
    }
    } else {
    cadena = "Sin datos";
    }
    return cadena;
}

control.shoyEstadoEntrega = (estadoentrega, estado_entrega) => {

    let estado_final = statusString(estadoentrega)
    if(estado_entrega){
      if(typeof estado_entrega === 'object'){
        if(estado_entrega.titulo){
          estado_final = estado_entrega.titulo
        }
      }
    }
  
    return estado_final
  
}

control.StringByRuleStatus = (status) => {
    switch (status) {
        case "pending":
            return "Pendientes de cumplimiento"
        case "fulfilled":
            return "Con reglas cumplidas"
        case "unfulfilled":
            return "Con reglas incumplidas"
        default:
            return status;
    }
}

control.StringByOptionValue = option => {
    switch (option) {
        case 'status-creation':
            return 'Creación de estado'
        case 'date-creation':
            return 'Fecha de creación'
        default:
            return option
    }
}

control.StringByOptionComparisionOperator = option => {
    switch (option) {
        case '<':
            return 'Menor a'
        case '>':
            return 'Mayor a'
        case '>=':
            return 'Mayor o igual a'
        case '<=':
            return 'Menor o igual a'
        case '===':
            return 'Exactamente igual a'
        default:
            return option
    }
}

control.calcularPesoVolumetricoUnitario = (pro) => {
    let default_weight = 0
    let default_vol = 0
    let ancho       = 0
    let alto        = 0
    let largo       = 0
    let peso        = 0
    let cantidad    = 1

    if(!isNaN( parseFloat(pro.ancho) ))     ancho   = parseFloat(pro.ancho)
    if(!isNaN( parseFloat(pro.alto) ))      alto    = parseFloat(pro.alto)
    if(!isNaN( parseFloat(pro.largo) ))     largo   = parseFloat(pro.largo)
    if(!isNaN( parseFloat(pro.peso) ))      peso    = parseInt(pro.peso)

    const total = (ancho * alto * largo) / 1000000
    default_vol = (default_vol + total) * cantidad
    default_weight = (default_weight + peso) * cantidad
    const peso_vol = Math.max((default_vol / 250), default_weight)

return {
    weight: peso_vol,
    vol: default_vol
}
}

control.esimacionFechas = (orden) => {
    if(!orden) return false

    const minimo    = DateTime.fromISO(orden.fecha_min_entrega).toUTC()
    const maximo    = DateTime.fromISO(orden.fecha_max_entrega).toUTC()
    const hoy       = DateTime.now().toUTC()
    
    if(!orden.fecha_finalizado){
        if(maximo < hoy) return false
    }

    const finalizado = DateTime.fromISO(orden.fecha_finalizado)

    if(finalizado > maximo) return false

    return true
}

module.exports = control