import { useEffect, useState } from 'react'
import { Row, Col, Card, Modal, Button } from 'react-bootstrap'
import data, { urlapi } from '../../../../lib/backend/data'
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { cerrarSesion } from '../../../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import { TbCloudDataConnection, TbRoute } from 'react-icons/tb';
import { MdOutlineBorderOuter } from 'react-icons/md';
import PantallaCargando from '../../../general/pantalla_cargando';
import ApiTarifas from './api_tarifas';
import { url_images } from '../../../../lib/global/data';

const CreacionApiTarifa = (props) => {
    const {
        id_lote,
        welcomed
    } = props
    const dispatch = useDispatch()
    const [ ruta, setRuta ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ welcome, setWelcome ] = useState(!welcomed || false)
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const esquema = [
        { value: 'nombre', label: 'Nombre', optionalUpload: true },
        { value: 'direccion', label: 'direccion', info:"Ejemplo: Avenida manuel antonio matta 747, santiago" },
        { value: 'comuna', label: 'comuna', optionalUpload: true },
        { value: 'ciudad', label: 'ciudad', optionalUpload: true },
        { value: 'region', label: 'Región', optionalUpload: true },
        { value: 'pais', label: 'País', optionalUpload: true },
    ]
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const {
        id
    } = useParams()

    const onCreate = data => {
        if(!data?._id) return false
        return setRegistros(prev => {
            const actual = [...prev]
            actual.unshift(data)
            return [...[], ...actual]
        })
    }

    const onChangeRegistros = (data) => {
        return setRegistros([...[], ...data])
    }


    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }

    useEffect(() => {
        obtenerDatos()
    },[])

    const obtenerDatos = async () => {
        if(!id_lote) return
        setLoading(true)
        return fetch(`${data.urlapi}/pricing/lotes/grupos/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion: { id_lote }
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos de zonas')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setRegistros(res)
                if(res.length > 0) setWelcome(false)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    } 
    
    const pantallaBienvenida = () => {
        const iconSize = 30
        return <Card >
            <div className='p-4'>
            <Row className="justify-content-md-center">
                    <Col md={6} className="text-center">
                        <h4 className="mb-0">Presentamos</h4>
                        <h1><TbCloudDataConnection /> <b>Mi API de tarifas</b></h1>
                        <Row className="justify-content-md-center">
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Genera tu propia API</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Genera tus plugins</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Múltiples integraciones</h5>
                            </Col>
                        </Row>
                        <img src={`${url_images}/animations/Holding Map Paper And Finding Navigation.gif`} style={{ width: "50%" }} />
                        <p>En esta opción puedes personalizar el acceso, visualización y cotización usando las tarifas aquí incluidas</p>
                        <Button size="sm" onClick={() => setWelcome(false)}>COMENZAR</Button>
                    </Col>
                </Row>
            </div>
        </Card>
    }

    const boxExcel = () => {
        if(loading) return <PantallaCargando />
        if(welcome) return pantallaBienvenida()
        return <div>
            <ApiTarifas id_lote={id_lote} datos={registros} onCreate={(data) => onCreate(data)} onChange={(data) => onChangeRegistros(data)} /> 
        </div>
    }
      
    return <div>
        {boxExcel()}
        {mostrarErrores()}
        
</div>
}

export default CreacionApiTarifa