import { Button, Card, Form, OverlayTrigger, Tab, Table, Tabs, Tooltip } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import ProveedoresTransporteListado from './listado'
import { useEffect, useState } from 'react'
import HelperModal from '../general/helperModal'
import SelectorEmpresas from '../empresas/selector'
import SelectorZonas from '../geodata/selector_zonas'
import SelectorServicios from '../servicios/selector'
import { default_proveedores } from '../proveedores_transporte/data'
import { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify'
import { extraerNumeros, formatoMoneda } from '../../lib/helpers/main'
import { cerrarSesion } from '../../redux/actions/session'
import PantallaError from '../general/pantalla_error'
import data from '../ordenes/ordenes_tickets/data'
import Badge from 'react-bootstrap/Badge'
import { url_images } from '../../lib/global/data'
import { handleDownloadExcel } from '../../lib/helpers/excel'
import { FaRegFileExcel } from 'react-icons/fa'
import { mostrarPorUM } from './tarifas/lotes_clientes/data'

const Tarifas = (props) => {
    const session = useSelector(state => state.miusuario)
    const [ condicionDefault, setCondicionDefault ] = useState({ proveedor: true })
    const [ filtros, setFiltros ] = useState({})
    const [ lote, setLote ] = useState({})
    const [ zonas, setZonas ] = useState([])
    const [ rangos, setRangos ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ tarifasCargadas, setTarifasCargadas ] = useState([])
    const pais = useSelector(state => state.pais)
    const providers = default_proveedores
    const dispatch = useDispatch()
    const [showTarifasBox, setShowTarifasBox] = useState(true);


    useEffect(() => {
        listadoInicial({},1)
    }, [])

    const listadoInicial = async (condicion, page) => {
        return fetch(`${urlapi}/geodata/zonas/list/select`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`,
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setZonas(res)
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    } 

      
    const handleChange = (key, data, custom_key) => {
        let val = null
        console.log(data)
        if(data){
            if(custom_key){
                if(data[custom_key]){
                    val = data[custom_key]
                }
            } else {
                if(data._id){
                    val = data.rut
                } else {
                    val = data.value
                }
            }
        } 
        if(!val) return setFiltros(prev => {
            let actual = {...prev}
            if(key === "proveedor") delete actual.id_servicio
            delete actual[key]
            return actual
        })

        return setFiltros(prev => {
            return {...prev, [key]: val }
        })
    }

    const onChangeOrigen = (e) => {
        

    }

    const buscarTarifas = async () => {
        setLoading(true)
        let url_consulta = `${urlapi}/pricing/search-pricing`
        return fetch(url_consulta,{
            method: "POST",
            body: JSON.stringify({
                condicion: filtros
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){
                return tarifasBox()

            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            }  else if(data._id){
                setShowTarifasBox(false);
                setLote(data)
                // if(data.geocercas) if( Array.isArray(data.geocercas) !== false ) setZonas(data.geocercas)
                if(data.tarifas) if( Array.isArray(data.tarifas) !== false ) setTarifasCargadas(data.tarifas)
                if(data.rangos) if( Array.isArray(data.rangos) !== false ) setRangos(data.rangos)
            }
                
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoading(false)
        })
    }

    const procesarDatos = (datos) => {
        let sector = []
        for(const reg of datos ){
            const i = sector.findIndex(se => se._id === reg.id_geocerca_destino)
            if(i > -1){
                sector[i].tarifas.push(reg)
            } else {
                const pos_zona = zonas.findIndex(zon => zon._id === reg.id_geocerca_destino)
                if(pos_zona > -1 ) sector.push({...zonas[pos_zona], tarifas: [ reg ] })
            }
        }
        return sector
    }
    const segmentarPorZonaAdministrativaPorPais = (datos) => {
        switch (pais) {
            case "CL":

                let region_iteracion = []

                const instancia_zonas = JSON.parse( JSON.stringify( zonas ) )

                for( const tar of datos ){
                    let codregion = false

                    if(tar.properties) if(typeof tar.properties === "object") if(tar.properties.codregion) codregion = tar.properties.codregion

                    if( codregion !== false ){
                        const i_region = instancia_zonas.findIndex(zo => {
                            if(zo.local_name !== "region") return false
                            if(!zo.properties) return false
                            if(typeof zo.properties !== "object") return false
                            return zo.properties.codregion === codregion
                        })
                        if(i_region > -1){
                            
                            //continue
                            const i_region_iteracion = region_iteracion.findIndex(re => re._id === instancia_zonas[i_region]._id)
                            if(i_region_iteracion > -1){
    
                                region_iteracion[i_region_iteracion].zonas.push(tar)
    
                            } else {
                                region_iteracion.push({...zonas[i_region], zonas: [ tar ] })
                            }
                        } else {
                            console.log({ tar, i_region, codregion }, instancia_zonas.length)
                        }
                    } else {
                        console.log("no tiene region", tar, instancia_zonas.length)
                    }
                }

                return region_iteracion
            default:
                return []
        }
    }

    const tarifasBox = () => {
        return <div className='p-5'>
        <Row className="justify-content-md-center">
            <Col md={8} className="text-center pt-2">
            <h3 className='mb-0 text-primary' style={{ fontWeight: "bold" }}>Verifica las tarifas para tus traslados en un solo click</h3>
            <img className='d-block' src={`${url_images}/animations/Pricing Strategy.gif`} alt='pricing' style={{ width: 250, maxWidth: "80%", margin: "0 auto" }} />
            <p>Revisa las tarifas para el traslado de tu carga desde su origen según los detalles de tu producto</p>
            {/* <Button disabled={loadingMaster}  onClick={() => cotizarOrdenes()} variant='secondary' >COTIZAR</Button> */}
            </Col>
        </Row>
        
        </div>
    }


    const descargarModelo = (registros, rangos_ordenados) => {

        let datos = []
        
        const iterar_datos = registros.map(region => {
            
            region.zonas.map((comuna) => {
                
                let objeto = {
                    region: region.titulo
                }
                objeto.titulo = comuna.titulo
                rangos_ordenados.map((rango,irango) => {
                    const i_tarifa = tarifasCargadas.findIndex(tar => tar.id_geocerca_destino === comuna._id && tar.peso_desde === rango.peso_desde && tar.peso_hasta === rango.peso_hasta)
                    if(i_tarifa > -1) objeto[rango.titulo] = tarifasCargadas[i_tarifa].tarifa
                })

                datos.push(Object.keys(objeto).map(key => typeof objeto[key] !== null ? objeto[key] : "Sin información"))

            })
            

            return false
        })

        const titulos_rangos = rangos_ordenados.map((rango,irango) => {
            const desde = (rango.peso_desde/1000).toFixed(0)
            const hasta = (rango.peso_hasta/1000).toFixed(0)
            return `Desde ${desde} KG a ${hasta} KG`
        })
        
        const estructura_excel = {
            cabeceras: [
                ...[ "Región", "Comuna"],
                ...titulos_rangos
            ],
            data: datos
        }

        return handleDownloadExcel({ cabeceras: estructura_excel.cabeceras, data: estructura_excel.data })
    }

    const mostrarTarifasCargadas = () => {
        if(tarifasCargadas.length < 1 ) return false
        try {
        const datos =  procesarDatos(tarifasCargadas)
        const segmentar = segmentarPorZonaAdministrativaPorPais(datos)
        const rangos_ordenados = rangos.sort((a, b) => {
            const valor_a = parseInt(extraerNumeros(a.titulo))
            const valor_b = parseInt(extraerNumeros(b.titulo))
            if (valor_a < valor_b) {
                return -1;
            }
            if (valor_a > valor_b) {
                return 1;
            }
            return 0;
        })

        return <div >
            <hr className='mt-4'/>
            <div className="pb-0"></div>
            <h5 style={{fontWeight:700}}>{tarifasCargadas.length} Tarifas cargadas</h5>
            <Button variant="light" size="sm" onClick={() => descargarModelo(segmentar, rangos_ordenados)}><FaRegFileExcel /> DESCARGAR TARIFAS</Button>
            {
                segmentar.map((region,irr) => {
                    return <div key={region._id} className="mt-2">
                        <h5><Badge variant='secondary'><i className="fa-solid fa-map-pin"></i> {region.titulo}</Badge></h5>
                        <Card className="p-0 border-0">
                        <Table striped bordered className="mb-0">
                            <thead>
                                <tr>
                                    <th>DESTINO</th>
                                    <th>POR KILOVOLUMEN</th>
                                    <th>POR VOLUMEN</th>
                                    <th>TIPO</th>
                                    {
                                        rangos_ordenados.map((rango,irango) => {
                                            const desde = (rango.peso_desde/1000).toFixed(0)
                                            const hasta = (rango.peso_hasta/1000).toFixed(0)
                                            return <th style={{ textTransform: "uppercase" }} key={`rang-${irango}`}>
                                                <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                          <Tooltip >Peso volumétrico</Tooltip>
                                                        }
                                                        ><div><b style={{ fontWeight: "lighter", color: "gray" }}>{desde}<b>KG</b> a {hasta}<b>KG</b></b></div></OverlayTrigger>
                                            </th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    region.zonas.map((comuna) => {

                                        let porkilovolumen = ''
                                        let porvolumen = ''
                                        let um = ''

                                        const i_tarifa = tarifasCargadas.findIndex(tar => tar.id_geocerca_destino === comuna._id )
                                        if(i_tarifa > -1 ){
                                            porkilovolumen = tarifasCargadas[i_tarifa].porkilovolumen === true ? "SI" : "NO"
                                            porvolumen = tarifasCargadas[i_tarifa].porvolumen === true ? "SI" : "NO"
                                            um = mostrarPorUM(tarifasCargadas[i_tarifa].um)
                                        }

                                        return <tr key={`re-${irr}-${comuna._id}`} className="p-2">
                                            <th style={{ textTransform: "uppercase" }}>{comuna.titulo}</th>
                                            <th style={{ textTransform: "uppercase" }}>{porkilovolumen}</th>
                                            <th style={{ textTransform: "uppercase" }}>{porvolumen}</th>
                                            <th style={{ textTransform: "uppercase" }}>{um}</th>
                                            {
                                                rangos_ordenados.map((rango,irango) => {
                                                    let valor = ''
                                                    const i_tarifa = tarifasCargadas.findIndex(tar => tar.id_geocerca_destino === comuna._id && tar.peso_desde === rango.peso_desde && tar.peso_hasta === rango.peso_hasta)
                                                    if(i_tarifa > -1) valor =  tarifasCargadas[i_tarifa]
                                                    return <th key={`rang-${irango}`}>{valor.tarifabase ? <OverlayTrigger
                                                        placement={'top'}
                                                        overlay={
                                                          <Tooltip >Tarifa base de {formatoMoneda(valor.tarifabase)} más {formatoMoneda(valor.tarifa)} por kilovolumen adicional</Tooltip>
                                                        }
                                                        ><b style={{ padding: "2px 3px", fontSize:12, borderRadius:3, marginRight: 5 }}>{formatoMoneda(valor.tarifabase)}</b></OverlayTrigger> : false } {formatoMoneda(valor.tarifa)}
                                                        { valor.minimo ? <Badge style={{ fontSize: 10 }} variant="info">MÍNIMO: {formatoMoneda(valor.minimo)}</Badge> : false }
                                                        </th>
                                                })
                                            }
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                        </Card>
                    </div>
                })
            }
        </div>
            
        } catch (error) {
            return <PantallaError mensaje={error.message} />
        }
    }


        return <div className="fluid text-primary">
                <Row>
                        <Col><h4 style={{fontWeight:700}}>Tarifas y cotización</h4></Col>
                        <Col className='text-right'><HelperModal tipo="proveedores" style="modal" /></Col>
                </Row>
                <Row>
                    <Col xs={6}><SelectorEmpresas full_payload={true} extraOptionss={providers.map(pro => ({ value: pro.slug, label: pro.titulo }))} titulo="Proveedor" onChange={(data) => handleChange('proveedor', data)} condicion_default={{ proveedor: true }} /></Col>
                    <Col xs={6}><SelectorServicios titulo="Servicio" rut_proveedor={filtros.proveedor} onChange={(data) => handleChange('id_servicio', data)} /> </Col>
                    <Col xs={6}><SelectorZonas onChangeValue={(data) => onChangeOrigen(data)} titulo="Origen" onFetchedData={(data) => console.log(data)} /></Col>
                    <Col xs={6} className='d-flex align-items-end'>
                    <label className='form-control-label d-block'></label>
                    <Button variant="success" className='w-100 shadow-sm' disabled={loading} onClick={() => buscarTarifas()}><b>FILTRAR</b></Button>
                    </Col>
                </Row>
                {showTarifasBox && tarifasBox()}                
                {mostrarTarifasCargadas()}
            </div>
}

export default Tarifas