import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { urlapi } from "../../../lib/backend/data"
import { Spinner } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

const IngresoCourrier = (props) => {
    const {
        id_pedido,
        propietario
    } = props
    const [ courriers, setCourriers ] = useState([{ nombre: "tracking-avanzado" }])
    const [ loadingCourriersActuales, setLoadingCourriersActuales ] = useState(true)
    const [ courriersActuales, setCourriersActuales ] = useState([])
    const [ relacion, setRelacion ] = useState({
        idrecurso: id_pedido,
        valor: "tracking-avanzado"
    })
    const [ loading, setLoading ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const guardarCodigo = async () => {
        if(!relacion) return false
        if(!relacion.codigo) return toast.error("Falta el proveedor")
        if(!relacion.tracking_number) return toast.error("Falta el tracking number")
        setLoading(true)
        return fetch(`${urlapi}/courriers/suite/crear-conexion-courrier`,{
            method: 'POST',
            body: JSON.stringify({...relacion, tipo: "idrecurso", valor: id_pedido, nombre: "courrier avanzado" }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( res._id){
                toast.success("Guardado exitosamente")
                setRelacion({...{}, ...{ idrecurso: id_pedido }})
                obtenerCourriersActuales()
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoading(false)
        })
    }

    const obtenerDatos = async (condicion) => {
        setLoading(true)
        return fetch(`${urlapi}/configuracion/custom`,{
            method: 'POST',
            body: JSON.stringify({condicion}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( Array.isArray(res) !== false){
                setCourriers(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoading(false)
        })
    }
    
    const eliminarReferencia = async (id) => {
        setLoadingCourriersActuales(true)
        return fetch(`${urlapi}/courriers/courriers?id=${id}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                return obtenerCourriersActuales()
            }
            return setLoadingCourriersActuales(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingCourriersActuales(false)
        })
    }

    const obtenerCourriersActuales = async () => {
        if(!id_pedido) return setLoadingCourriersActuales(false)

        setLoadingCourriersActuales(true)
        return fetch(`${urlapi}/courriers/courriers?id=${id_pedido}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if( Array.isArray(res) !== false){
                setCourriersActuales(res)
            }
            return setLoadingCourriersActuales(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingCourriersActuales(false)
        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        relacion[name] = value
        return setRelacion(relacion)
    }

    const mostrarCourriersActuales = () => {
        if(loadingCourriersActuales) return <Spinner animation="border" />

        return <div className="mt-3">
            <h3>Courriers actualmente asignados </h3>
            <div className="row">
                {
                    courriersActuales.map((cou,ic) => {
                        return <div key={cou._id} className="col-md-3">
                                <div className="card p-3">
                                    <p className="mb-0">{cou.codigo}</p>
                                    <h3 className="mb-0">{cou.tracking_number}</h3>
                                    <button className="btn btn-sm btn-outline-danger" onClick={() => eliminarReferencia(cou._id)}>ELIMINAR CONEXIÓN COURRIER</button>
                                </div>
                        </div>
                    })
                }
            </div>
        </div>
    }
      
    useEffect(() => {
        // obtenerDatos({ $or: [
        //     { propietario, sub_tipo: "integracion_tracking" },
        //     { propietario: "all", sub_tipo: "integracion_tracking" }
        // ] })
        obtenerCourriersActuales()
    }, [])

    const formulario = () => {
        if(loading) return <Spinner animation="border" />
        return <div>
            <div className="row">
                <div className="col-md-3">
                    <label className="form-control-label d-block">Proveedor de transporte</label>
                    <select value={relacion.codigo} name="codigo" className="form-control" onChange={handleChange}>
                        <option value="">Selecciona</option>
                        {
                            courriers.map((op,i) => {
                                return <option key={`co-${i}`} value={op.nombre}>{op.nombre}</option>
                            })
                        }
                    </select>
                </div>
                <div className="col-md-3">
                    <label className="form-control-label d-block">Código de seguimiento</label>
                    <input className="form-control" value={relacion.tracking_number} name="tracking_number" onChange={handleChange} />
                </div>
                <div className="col-md-3">
                    <label className="form-control-label d-block">Click para asignar</label>
                    <button className="btn btn-success" onClick={() => guardarCodigo()}>GUARDAR</button>
                </div>
            </div>
            {mostrarCourriersActuales()}
        </div>
    }

    if(!id_pedido) return false

    return <div>

        {formulario()}
    </div>
}

export default IngresoCourrier