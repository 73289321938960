import { createStore, combineReducers, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import miusuario from '../redux/reducers/miusuario'
import pais from '../redux/reducers/pais'
import idioma from '../redux/reducers/idioma'
import modo from '../redux/reducers/modo'
import esquema_actual from '../redux/reducers/esquema'
import esquema_tablas from '../redux/reducers/esquema_tablas'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import estado from '../redux/reducers/estado'
 
const thunkMiddleware = require('redux-thunk').default

const rootReducer = combineReducers({
    miusuario,
    pais,
    esquema_tablas,
    idioma,
    modo,
    esquema_actual,
    estado,
})

const persistConfig = {
  key: 'root',
  storage,
}
export type RootState = ReturnType<typeof rootReducer>;

const persistedReducer = persistReducer(persistConfig, rootReducer)
 
const empaquetar = () => {
  let store = createStore(persistedReducer, applyMiddleware(thunkMiddleware))
  let persistor = persistStore(store)
  return { store, persistor }
}

export default empaquetar