import * as XLSX from 'xlsx'

const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

export const handleDownloadExcel = ({cabeceras,data}) => {
    // Crear el archivo de Excel
    const wb = XLSX.utils.book_new();
    const wsData = [cabeceras]
    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    XLSX.utils.sheet_add_aoa(ws, data, { origin: -1 });
    // Convertir el libro de trabajo a un archivo binario
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    // Crear un Blob con los datos binarios
    const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

    // Generar un objeto URL del Blob
    const url = URL.createObjectURL(blob);

    // Crear un enlace de descarga invisible y hacer clic en él
    const a = document.createElement('a');
    a.href = url;
    a.download = 'Modelo Tarifario.xlsx';
    a.click();

    // Liberar el objeto URL
    URL.revokeObjectURL(url);
}
