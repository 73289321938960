import { useEffect, useState } from "react"
import data, { maps_key, url_front_produccion, urlapi } from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useParams } from "react-router-dom";
import { Col, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import { url_images } from "../../../lib/global/data";
import MapaRutaAsignacion from "../../../components/Mapas/mapa_ruta_preview";
import { getNombreDestinatario } from "../../../lib/helpers/pedidos/pedidos";
import { obtenerZonasDePedidos } from "../../../lib/helpers/rutas";
import { fechaATextoSimple } from "../../../lib/helpers/helpers";
import { BsBoxSeam, BsCalendarCheck } from "react-icons/bs";
import { FiAlertCircle, FiMapPin } from "react-icons/fi";
import BoxList from "../../ordenes/box-list";
import './ruta.css'
import EstadosRuta from "../estados_ruta";
import { AiOutlineCar } from "react-icons/ai";

const DetailEnlaceRuta = (props) => {
    const [ empresa, setEmpresa ] = useState(false)
    const [ enlace, setEnlace ] = useState(false)
    const [ ruta, setRuta ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ marcadores, setMarcadores ] = useState([])
    const [ error, setError ] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        obtenerDetalles()
    }, [])

    const validarPuntos = (route) => {

        let markers = []
        if(route.pedidos){
            if(Array.isArray(route.pedidos) !== false){
              for( const orden of route.pedidos ){
                if(orden.location){
                    if(Array.isArray(orden.location.coordinates)){
                        if(orden.location.coordinates.length > 0){
                            markers.push({
                                lat: orden.location.coordinates[1],
                                lng: orden.location.coordinates[0],
                                ref: orden.pedido,
                                // destinatario: getNombreDestinatario(pedido)
                            })
                        }
                    }
                }
              }
              return setMarcadores(markers)
            }
          }
    }

    const obtenerDetalles = async () => {
        if(!id) return setLoading(false)
        return fetch(`${data.urlapi}/rutas/enlaces/public/detail?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor')
                setError(true)
              } else if(pros.errorMessage){
                toast.error("Error al procesar la información")
                setError(true)
              } else if(typeof pros === "object"){
                if(pros.ruta){
                    setRuta(pros.ruta)
                    validarPuntos(pros.ruta)
                    setEnlace(pros.enlace)
                    setEmpresa(pros.empresa)
                }
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('Error al obtener los datos')
                setError(true)
                return setLoading(false)
          })
    }

    const cargandoScreen = () => {
        return <div style={{ width: "100%", height: "100vh", alignItems: "center", display: "flex", justifyContent: "center" }}>
            <div className="text-center mb-3">
            <div className="mb-3"><img style={{ width: "100%", maxWidth: 250 }} src={`${url_images}/logoColor.svg`} /></div>
            <Spinner animation='border' />
            </div>
        </div>
    }

    const pantallaVacia = () => {
        return <div style={{ width: "100%", height: "100vh", alignItems: "center", display: "flex", justifyContent: "center" }}>
        <div className="text-center mb-3">
        <div className="mb-3"><img style={{ width: "100%", maxWidth: 250 }} src={`${url_images}/logoColor.svg`} /></div>
        <h4>No hay datos disponibles para mostrar</h4>
        </div>
    </div>
    }

    const mostrarFotoPerfil = (driver) => {

        const default_object = <div style={{ display: "inline-block", verticalAlign: "middle" }}><AiOutlineCar size={35} /></div>
        if(!driver.image_profile) return default_object
      
        return <div className='p-1' style={{ display: "inline-block", width: 40, height: 40, borderRadius: "50%", verticalAlign: "middle", backgroundPosition: "center center", backgroundImage: `url(${driver.image_profile})`, backgroundSize: 'cover' }}></div>
      }

    const mostrarVehiculo = (driver) => {
        if(!driver) return false

        if(enlace) if(enlace.hide_driver === true) return false

        return <div style={{ alignItems: "center" }}>
            <div style={{ display: "inline-block", verticalAlign: "middle" }} className="vertical-align-center px-3">
                {mostrarFotoPerfil(driver)}
            </div>
            <div style={{ display: "inline-block", verticalAlign: "middle" }} className="vertical-align-center">
            <h6 className="mb-0" style={{  }}><b>{driver.nombres}</b> {driver.apellidos}</h6>
            <h6 className="mb-0" style={{  }}><b>{driver.modelo}</b> {driver.ano}</h6>
            <h6 className="mb-0" style={{  fontSize: 13 }}>{driver.phone}</h6>
            <h6 className="mb-0" style={{  }}>PATENTE: <b>{driver.patente ? driver.patente.toUpperCase() : "SIN INFORMACIÓN"}</b></h6>
            </div>
            <hr className="hr" /> 
        </div>
      }

      const mostrarLogotipo = () => {
        const logo_last_mile = <img style={{ width: "100%", maxWidth: 100 }} src={`${url_images}/logoColor.svg`} />
        if(!empresa) return logo_last_mile
        if(typeof empresa !== "object") return logo_last_mile
        if(!empresa.logotipo) return logo_last_mile

        return <img style={{ width: "100%", maxWidth: 100 }} src={empresa.logotipo} />
      }

      const mostrarTracking = () => {
        if(enlace) if(enlace.hide_statuses) return false
        return <Tab eventKey="estados" title={<div><FiAlertCircle /> Eventos</div>} className="mt-3">
                    <EstadosRuta id_ruta={ruta._id} />
                </Tab>
      }

    const View = () => {
        if(loading) return cargandoScreen()
        if(!ruta) return pantallaVacia()

        let zonas = obtenerZonasDePedidos(ruta.pedidos)

        return <div>
            <Row>
                <Col md={4} style={{ height: "100vh", overflowY: "scroll" }}>
                <div className="p-3">
                <div className="mb-3">{mostrarLogotipo()}</div>
                <h4>Ruta {ruta.id}</h4>
                <hr className="hr" />
                <h6 className="mb-0"><BsCalendarCheck /> Entrega</h6>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Desde</b> {fechaATextoSimple(ruta.entrega_desde)}</p>
                <p style={{ fontSize: 11 }} className="mb-0"><b>Hasta</b> {fechaATextoSimple(ruta.entrega_hasta)}</p>
                <hr className="hr" />
                {mostrarVehiculo(ruta.vehiculo)}
                <Tabs defaultActiveKey="ordenes"> 
                    <Tab eventKey="ordenes" title={<div><BsBoxSeam /> Órdenes</div>} className="mt-3">
                        <h6>{ruta.pedidos.length} Órdenes</h6>
                    {
                        ruta.pedidos.map(orden => {
                            let hideAddress = false
                            let partialAddress = false
                            if(enlace) if(enlace.hide_address === true) hideAddress = true
                            if(enlace) if(enlace.partial_address === true) partialAddress = true
                            return <div key={orden._id} className="mb-3">
                            <BoxList typeView="simple" showGeoData={false} partialAddress={partialAddress} hideAddress={hideAddress} pedido={orden} seleccionado={false} />
                            </div>
                        })
                    }
                    </Tab>
                    <Tab eventKey="zonas" title={<div><FiMapPin /> Zonas</div>} className="mt-3">
                        { zonas.map((com,iz) => {
                            return <span style={{ backgroundColor: "white", textTransform: "uppercase", fontWeight: "bold", fontSize: 11, color: "#717171", padding: "2px 10px", display: "inline-block" }} key={`z-${iz}`} className='mr-3 mb-2' >{com}</span>
                        }) }
                    </Tab>
                    {mostrarTracking()}
                    
                </Tabs>

                </div>
                </Col>
                <Col md={8} style={{ height: "100vh" }}>
                    <MapaRutaAsignacion id_conductor={ruta.id_conductor} markers={marcadores} height={"100vh"}  />
                </Col>
            </Row>
        </div>
    }

    return View()
}

export default DetailEnlaceRuta