import { Button, Card, Modal } from "react-bootstrap"
import { useState } from "react"
import OrdenesListadoV2 from "./listadov2"
import { FaBox } from "react-icons/fa"

const ModalSelectorOrdenes = (props) => {
    const {
        typeView,
        title
    } = props
    const titulo = title ? title : "ASIGNAR ÓRDENES"
    const [ showModal, setShowModal ] = useState(false)
    const [ manifiesto, setManifiesto ] = useState(null)

    const handleClose = () => {
        return setShowModal(false)
    }

    const handleConfirmation = () => {
        if(props.onConfirmation) props.onConfirmation(manifiesto)
        handleClose()
    }

    const onSelect = (data) => {
        setShowModal(false)
        if(props.onSelect) props.onSelect(data)
    }

    const mostrarComponente = () => {


        return <div>
            <OrdenesListadoV2 
                hideHeader={true} 
                showExporter={false}
                showSearch={true}
                hideTitle={true}
                modal={true} 
                showAddButton={false} typeView="funcion" onSelect={(id) => onSelect(id)} />
        </div>
    }

    return <div>          
    <Button variant="light" onClick={() => setShowModal(true)}><FaBox /> {titulo}</Button>            
    <Modal show={showModal} size="lg" onHide={handleClose} centered >
        <Modal.Header closeButton>
        <Modal.Title><b>Órdenes</b></Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-0'>
            {mostrarComponente()}
        </Modal.Body>
    </Modal>
</div>
}

export default ModalSelectorOrdenes