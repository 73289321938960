import React, { useEffect, useState } from 'react';
import { Card, Button, Col, Row, Form } from 'react-bootstrap';
import SinDatos from '../general/sin_registros';
import { FaCcVisa, FaCheck, FaCheckCircle } from 'react-icons/fa';
import AddNewPaymentGateway from './nuevo';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../../lib/websockets';
import { urlapi } from '../../lib/backend/data';
import { cerrarSesion } from '../../redux/actions/session';
import { toast } from 'react-toastify';
import PantallaCargando from '../general/pantalla_cargando';

const Saldo = (props) => {
    const {
        ammount
    } = props
    const [ cash, setCash ] = useState(true)
    const [ methods, setMethods ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const [selectedPayment, setSelectedPayment] = useState(null);
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    let identificador = `new_payment_${session.data.propietario}`

    useEffect(() => {
        socket.on(identificador, nuevo => {
            return setMethods(prev => {
                let actual = [...prev]
                const i = actual.findIndex(x => x._id === nuevo._id)
                if(i < 0) actual.push(nuevo)
                return [...[], ...actual]
            })
        })
        obtenerDatos()
        return () => {
            socket?.off(identificador)
        }
    }, [])

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/payments/list`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res)){
                setMethods([...[], ...res])
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handlePaymentSelection = (payment) => {
        setSelectedPayment(payment);
    };

    const handleCheck = (e) => {
        setCash(e.target.checked)
    }

    const iconByCardType = (val) => {
        const cardType = val.toString().toLowerCase()
        const tipos = {
            visa: <FaCcVisa size={30} className='mr-2' />
        }
        return tipos[cardType] ? tipos[cardType] : cardType
    }

    const mostrarMetodos = () => {
        if(methods.length < 1) return <h4>Sin métodos de pago agregados</h4>

        return <div>
            <h6 className='mt-3'>Selecciona el método de pago</h6>
            {methods.map((metodo, index) => {
                return <Card
                    key={index}
                    className='hover p-3 mb-3'
                    onClick={() => handlePaymentSelection(metodo.slug)}
                >
                    <p className='mb-0'>{selectedPayment === metodo.slug ? <FaCheckCircle color='green' className='mr-2' /> : false } {iconByCardType(metodo.card_type)} Términa en {metodo.card_number.replace(/x/gi, "")}</p>
                </Card>
            })}
        </div>
    }

    if(loading) return <PantallaCargando /> 

    return (
        <div className='mb-3'>
            <Card className='shadow-sm p-3 mb-3'>
            <h6>Selecciona tu método de pago</h6>
            <Form.Group className="mb-2" controlId="usar_balance">
                                <Form.Check type="switch" defaultChecked={cash} label={<div>Utilizar mi balance <b style={{ fontWeight: "bold" }}>$0,00</b></div>} onChange={handleCheck} />
                                </Form.Group>
            {mostrarMetodos()}
            <AddNewPaymentGateway ammount={ammount}  />
            </Card>
        </div>
    );
};

export default Saldo;
