import { useEffect, useState } from "react"
import { Accordion, Button, Card, Spinner } from "react-bootstrap"
import EdicionTarifa from "./edicion_tarifa"
import { FaAngleDown, FaCircle } from "react-icons/fa6";
import { urlapi } from "../../../../lib/backend/data";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ApiTarifas = (props) => {
    const {
        id_lote,
        datos
    } = props
    const [ loading, setLoading ] = useState(false)
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ tarifas, setTarifas ] = useState(datos ? datos : [])
    const [ zonas, setZonas ] = useState([])
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    useEffect(() => {
        if(datos) if(Array.isArray(datos) === true){
            setTarifas(prev => {
                let actual = [...prev]
                for( const reg of datos){
                    const i = tarifas.findIndex(ta => ta._id === reg._id)
                    if(i < 0) actual.unshift(reg)
                }
                return [...[], ...actual]
            })
        }
    }, [ datos ])
    
    const crearGrupo = async () => {
        setLoadingCreacion(true)
            return fetch(`${urlapi}/pricing/lotes/grupos`,{
                method:'POST',
                body: JSON.stringify({
                    id_lote
                }),
                headers: {
                    'Content-Type':'application/json',
                    'Authorization': `Bearer: ${token}`
                }
            })
            .then(res => res.json())
            .then(res => {
                if(!res){
                    toast.error('Sin datos')
                } else if(res.errorMessage){
                    toast.error(res.errorMessage)
                } else if(res._id){
                    if(props.onCreate) props.onCreate(res)
                }
                return setLoadingCreacion(false)
            })
            .catch(error => {
                toast.error(error.message)
                return setLoadingCreacion(false)
            })
            
    }

    const onDelete = (id) => {
        return setTarifas(prev => {
            let actual = [...prev]
            const i = actual.findIndex(ta => ta._id === id)
            if( i > -1 ) actual.splice(i, 1)
            if(props.onChange) props.onChange(actual)
            return [...[], ...actual]
        })
    }

    
      const onChangeData = (data) => {
        return setTarifas(prev => {
            let actual = [...prev]
            const i = actual.findIndex(ta => ta._id === data._id)
            if( i > -1 ) actual[i] = data
            return [...[], ...actual]
        })
      }

      const mostrarTarifas = () => {
        if(loading) return <Spinner />
        return <div>
            <Accordion >
            {
                tarifas.map((tarifa,ir) => {

                    return <Card key={`tar-${ir}`} className="p-0" style={{ background: "#e2e2e2"}} >
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={`tar-${ir}`}>
                        <div className="hover pl-3 pr-3 pb-0">
                            <h4 className="mb-0">{tarifa.title} {tarifa.closed === true ? <FaCircle color="red" /> : <FaCircle color="green"  />}</h4>
                            <p className="mb-0"><FaAngleDown /> Toca para ver más información</p>
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`tar-${ir}`}>
                            <div className="p-3">
                            <EdicionTarifa zonas={zonas} registro={tarifa} onChange={(data) => onChangeData(data)} onDelete={(id) => onDelete(id)} />
                            </div>
                        </Accordion.Collapse>
                    </Card>
                })
            }
            </Accordion>
        </div>
      } 

    return <div className="mt-3 mb-3">
        <h1 className="h2">Métodos de envío</h1>
        <p>Por cada método de envío que crees, podrás agrupar zonas, definir precios, horarios, courriers y mucho más, agréga los métodos que necesites para conectarlos a tu carrito de compra</p>
        <Button size="sm" className="mb-3" disabled={loadingCreacion} onClick={() => crearGrupo()}>AGREGAR GRUPO</Button>
        {mostrarTarifas()}
 </div>
}

export default ApiTarifas