import { useState } from "react"
import RangosListado from "./list"
import { Button, Col, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import SelectorServicios from "../../../servicios/selector"
import { toast } from "react-toastify"
import { urlapi } from "../../../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../../../redux/actions/session"
import FormularioLoteTarifa from "./formulario_lote"
import LotesTarifasListado from "./list"
import { BsArrowLeftShort } from "react-icons/bs"

const LotesTarifas = (props) => {
    const {
        condicion_default,
        rut_cliente
    } = props
    const [ tipoVisualizacion, setTipoVisualizacion ] = useState('listado')
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const activarCreacion = () => {
        return setTipoVisualizacion('crear')
    }

    const showByTipoVisualizacion = () => {
        switch (tipoVisualizacion) {
            case "crear":
                return <div className="mt-2">
                    <h6 className="mb-0 hover" onClick={() => setTipoVisualizacion('listado')}><BsArrowLeftShort size={30} /> VOLVER AL LISTADO DE LOTES</h6>
                    <FormularioLoteTarifa rut_cliente={rut_cliente} />
                </div>
            case "listado":
                return <div className="mt-2">
                    <h4 style={{ fontSize: 23 }}>Lotes de tarifas</h4>
                    <Button size="sm" onClick={()=>activarCreacion()} >IMPORTAR LOTES DE TARIFAS EXCEL</Button>
                    <hr className="hr" /> 
                    <LotesTarifasListado rut_cliente={rut_cliente} condicion_default={condicion_default} />
                </div>
            default:
                return <LotesTarifasListado rut_cliente={rut_cliente} condicion_default={condicion_default} />
        }
    }
        return <div>
        {showByTipoVisualizacion()}
    </div>
}

export default LotesTarifas