import { useEffect, useRef, useState } from "react"
import { Accordion, Badge, Button, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { urlapi } from "../../../../lib/backend/data";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { cerrarSesion } from "../../../../redux/actions/session";
import { formatoMoneda } from "../../../../lib/helpers/main";
import { MdExpandMore } from 'react-icons/md'

const Cotizador = (props) => {
    const {
        id_lote,
        geo_zonas,
        resize
    } = props
    const [ tarifas, setTarifas ] = useState([])
    const [ cotizacion, setCotizacion ] = useState({ id_lote })
    const [ loadingTarifas, setLoadingTarifas ] = useState(false)
    const [ geoData , setGeoData ] = useState(false)
    const [ init, setInit ] = useState(false)
    const [ refresh, setRefresh ] = useState(0)
    const pais = useSelector(state => state.pais)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const datos_geograficos = {}
    let geoDatosRef = useRef(datos_geograficos)
    const dispatch = useDispatch()
    let agregado = false

    const handleChange = (e) => {
        const { name, value } = e.target
        cotizacion[name] = value
        return setCotizacion({...{}, ...cotizacion})
    }

    const handleGeocoderResultDestino = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
            geoDatosRef.current.destino = event.result
        }
    };
    const handleGeocoderResult = (event) => {
        if (event.result && event.result.geometry && event.result.geometry.coordinates) {
            geoDatosRef.current.origen = event.result
        }
    }

    const tarificar = async () => {
        if(!cotizacion.peso) return toast.error("Introduce un peso válido")
        if(!cotizacion.volumen) return toast.error("Introduce un volumen válido")
        if(!geoDatosRef.current.origen) return toast.error("Introduce un origen válido")
        if(!geoDatosRef.current.destino) return toast.error("Introduce un destino válido")
        const instancia_cotizacion = JSON.parse( JSON.stringify( cotizacion ) )
        instancia_cotizacion.origen = geoDatosRef.current.origen
        instancia_cotizacion.destino = geoDatosRef.current.destino
        setLoadingTarifas(true)
        setTarifas([...[], ...[]])
        return fetch(`${urlapi}/pricing/quotation`,{
            method: "POST",
            body: JSON.stringify(instancia_cotizacion),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`,
                'pais': pais
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setTarifas(res)
            }
            return setLoadingTarifas(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingTarifas(false)
        })
    }

    const encontrar_elemento = (iddiv) => {
        const geocoderAddressDiv = document.getElementById(iddiv);

        // Verifica si se encontró el elemento con el id
        if (geocoderAddressDiv) {
        // Busca el elemento con la clase "mapboxgl-ctrl-geocoder" dentro del elemento capturado
        const mapboxGeocoderDiv = geocoderAddressDiv.querySelector('.mapboxgl-ctrl-geocoder');

        // Verifica si se encontró el elemento con la clase dentro del elemento capturado
        if (mapboxGeocoderDiv) {
            // Haz algo con el elemento, por ejemplo, modificar su contenido o estilo
            return true
        } else {
            return false
        }
        } else {
            return false
        }
    }

    const agregarGeocoder = () => {
        if(agregado) return false
        agregado = true
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Dirección origen",
            countries: pais,
          });
          geocoder.on('result', handleGeocoderResult);
          
          const findA = encontrar_elemento('geocoderaddress')
          if( !findA ) geocoder.addTo('#geocoderaddress')

          const geocoder_destino = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl: mapboxgl,
            marker: false, // Desactivar el marcador de ubicación seleccionada
            placeholder: "Dirección destino",
            countries: pais,
          });
          geocoder_destino.on('result', handleGeocoderResultDestino);
          const find = encontrar_elemento('geocoderaddressdestino')
          if( !find ) geocoder_destino.addTo('#geocoderaddressdestino')


      }
      
      const mostrarTarifaBase = (tar) => {
        if(!tar.tarifabase) return false
        return <Col md={2}>
            <p className="mb-0">Tarifa base</p>
            <h5 className="mb-0">${formatoMoneda(tar.tarifabase)}</h5>
        </Col>
      }

      const mostrarInformacionZona = (datos) => {
        return <div>
            <p className="mb-0" style={{ textTransform: "uppercase" }}><b>{datos.titulo}</b></p>
            <p className="mb-0">TIPO DE ZONA: <Badge variant="secondary">{datos.local_name}</Badge> </p>
        </div>
      }

      const mostrarTarifas = () => {
        if(tarifas.length < 1) return <div className="mb-3">
            <Card className="p-3">
            <h4 className="m-0">Consulta para obtener tarifas</h4>
            </Card>
        </div>

        return <div>
            <h4>{tarifas.length} Tarifas </h4>
            {
                tarifas.map((tar,i) => {

                    const UM = tar.porvolumen === true ? "M3" : "KG" 
                    const UM_LONG = tar.porvolumen === true ? "Metros cúbicos" : "Peso volumétrico" 
                    const UM_INDICATOR = tar.porvolumen === true ? "Volumen" : "Peso"

                    return <div key={tar._id} className="mb-3">
                        <Card className="p-3">
                        <Row>
                            <Col md={2}>
                                <p className="mb-0">TARIFA</p>
                                <h5 className="text-success bold" style={{ fontWeight: "bold" }}>${formatoMoneda(tar.tarifa_cotizacion)}</h5>
                            </Col>
                            <Col md={2}>
                                <p className="mb-0">TIPO</p>
                                <h5 style={{ textTransform: "uppercase" }}>{tar.tipo}</h5>
                            </Col>
                            {mostrarTarifaBase(tar)}
                            <Col md={2}>
                                <p className="mb-0">TARIFA UNITARIA</p>
                                <h5>${formatoMoneda(tar.tarifa)}</h5>
                            </Col>
                            <Col md={2}>
                                <OverlayTrigger
                                placement={'top'}
                                overlay={<Tooltip >{UM_LONG}</Tooltip>}
                                ><div>
                                <p className="mb-0">{UM_INDICATOR} desde</p>
                                <h5>{formatoMoneda(tar.peso_desde / 1000)} {UM}</h5>
                                </div></OverlayTrigger>
                            </Col>
                            <Col md={2}>
                            <OverlayTrigger
                                placement={'top'}
                                overlay={<Tooltip >{UM_LONG}</Tooltip>}
                                ><div>
                                <p className="mb-0">{UM_INDICATOR} hasta</p>
                                <h5>{formatoMoneda(tar.peso_hasta / 1000)} {UM}</h5>
                                </div></OverlayTrigger>
                                
                            </Col>
                            <Col md={12} className="mt-2" >
                            <Accordion defaultActiveKey={''} >
                                <Card className="p-0">
                                    <Accordion.Toggle as={Card.Header} style={{ padding: 5, fontSize: 12, backgroundColor:'white' }} eventKey={`tarifa-${i+1}`}>
                                    <div className="mb-0 hover"><p className="mb-0"><MdExpandMore size={30} /> MÁS DETALLES</p></div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={`tarifa-${i+1}`}>
                                    <Card.Body>
                                    <Row>
                                    <Col md={4}>
                                        <p className="mb-0">Geo zona origen de la tarifa</p>
                                        {mostrarInformacionZona(tar.detalles_origen)}
                                    </Col>
                                    <Col md={4}>
                                        <p className="mb-0">Geo zona destino de la tarifa</p>
                                        {mostrarInformacionZona(tar.detalles_destino)}
                                    </Col>
                                </Row>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                               
                            </Col>
                        </Row>
                        </Card>
                    </div>
                })
            }
        </div>
      }

    useEffect(() => {
        if(!init) agregarGeocoder()
        setInit(true)
        const valor = refresh + 1 
        console.log({valor})
        setRefresh(valor)
    }, [ geoDatosRef ])

    const formulario = () => {
        return <div className="mb-3">
            <Row>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Dirección origen</label>
                    <div style={{ width: "100%" }} id="geocoderaddress"></div>
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Dirección destino</label>
                    <div style={{ width: "100%" }} id="geocoderaddressdestino"></div>
                </Col>
                <Col md={2} className="mb-3">
                    <label className="form-control-label d-block">Peso (Kg.)</label>
                    <input type="number" className="form-control" name="peso" onChange={handleChange} />
                </Col>
                <Col md={2} className="mb-3">
                    <label className="form-control-label d-block">Volumen (M3.)</label>
                    <input type="number" className="form-control" name="volumen" onChange={handleChange} />
                </Col>
            </Row>
            <Button size="sm" disabled={loadingTarifas} className="w-100" onClick={() => tarificar()}>{ loadingTarifas ? "COTIZANDO, ESPERE UN MOMENTO..." : "COTIZAR" }</Button>
        </div>
    }

    return <div>
        <Row>
            <Col md={12}>
                {formulario()}
                {mostrarTarifas()}
            </Col>
        </Row>
    </div>
}

export default Cotizador