import { useCallback, useEffect, useState } from "react"
import { Card, Col, Form, Row, Spinner } from "react-bootstrap"
import { estilo_last_mile } from "../../lib/global/styles"
import PantallaCargando from "../general/pantalla_cargando"
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { urlapi } from "../../lib/backend/data"
import { debounce } from "@mui/material"
import { url_images } from "../../lib/global/data"
import { SiTwilio } from "react-icons/si"

const RipleyConfiguracion = (props) => {
    const components = {
        resumen: ''
    }
    const estructura = {
        client_id: "",
        client_secret: "",
    }
    const [ id, setId ] = useState("")
    const [ estructuraActual, setEstructuraActual ] = useState({
        detalles: estructura
    })
    const [ loading, setLoading ] = useState(false)
    const [ pendienteGuardar, setPendienteGuardar ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ loadingGuardado, setLoadingGuardado ] = useState(false)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const gris = '#e5e5e5'

    useEffect(() => {
        obtenerDatos("ripley")
    }, [])

    const obtenerDatos = async (modulo)=>{
        setLoading(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'POST',
            body: JSON.stringify({
                tipo: "credenciales",
                sub_tipo: modulo,
                detalles: estructura
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setId(res._id)
                setEstructuraActual(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }
    
    const guardarAutomatico = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/configuracion/personalizar`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            }
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
        .catch(error => {
            setPendienteGuardar(false)
            return setLoadingGuardado(false)
        })
    }
    
    const handleChangeExtra = (e, type, campo) => {
        const valor = e.target.value
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                 setPendienteGuardar(true)
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].extra = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const actualizar = useCallback(debounce((data) => guardarAutomatico(data), 1000), []);

    const handleCheck = (e, type, campo) => {
        const valor = e.target.checked
        if(type === "modulo"){
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            if( i > -1 ){
                if(estructuraActual.modulos[i].onlyRead === true) return false
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.modulos[i].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        } else if(type === "campos"){
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            if( i_ > -1 ){
                 return setEstructuraActual(prev => {
                    let actual = {...prev}
                    actual.campos[i_].activo = valor
                    actualizar({ _id: id, detalles: actual })
                    return { ...actual }
                 })
            }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setEstructuraActual(prev => {
            let actual = {...prev}
            if(!actual.detalles) actual.detalles = {}
            actual.detalles[name] = value
            actualizar(actual)
            return { ...actual }
        })
    }

    const mostrarBoton = (type, campo) => {
        if(type === "modulo"){
            let activo = false
            const i = estructuraActual.modulos.findIndex(modulo => modulo.slug === campo)
            let titulo  = ""
            let extra   = ""
            if(i > -1) {
                titulo      = estructuraActual.modulos[i].nombre
                extra       = estructuraActual.modulos[i].extra
                if(estructuraActual.modulos[i].activo === true){
                    activo = true
                }
            }
            return <div>
                <Row>
                    <Col><h6 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }} className="mb-0">{titulo}</h6></Col>
                    <Col className="text-right">
                    <Form.Group key={`${type}-${campo}`} className="mb-1" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" checked={activo} label={ activo === true ? "Activo" : "Inactivo"} onChange={(e) => handleCheck(e,type,campo)} />
                    </Form.Group>
                    </Col>
                    <Col xs={12}>
                        <label className="form-control-label">Contenido extra al final del módulo</label>
                        <textarea className="form-control" value={extra} onChange={(e) => handleChangeExtra(e,type, campo)}></textarea>
                    </Col>
                </Row>
                
                
            </div>
        } else if(type === "campos"){
            let activo = false
            const i_ = estructuraActual.campos.findIndex(ca => ca.slug === campo)
            let titulo = ""
            if(i_ > -1) {
                titulo = estructuraActual.campos[i_].nombre
                if(estructuraActual.campos[i_].activo === true){
                    activo = true
                }
            }
            return <div>
                <Form.Group key={`${type}-${campo}`} className="mb-3" controlId={`${type}-${campo}`}>
                        <Form.Check type="switch" checked={activo} label={titulo} onChange={(e) => handleCheck(e,type,campo)} />
                </Form.Group>
            </div>
        }
    }

    if(loading) return <PantallaCargando />

    return <div>
        <Row>
            <Col><p>Configura las credenciales de tu integración a Marketplace</p></Col>
            <Col className="text-right text-secondary"><p>{pendienteGuardar ? "Pendiente de guardar cambios" : <b className="text-success" style={{ fontWeight: "bold" }}>¡Guardado!</b> } { loadingGuardado ? <Spinner size="sm" animation="border" variant="primary" /> : false }</p></Col>
        </Row>
        <Row>
            <Col>
                <h6><b><SiTwilio /> Obtén tu Llave de Ripley <a href="https://documenter.getpostman.com/view/206599/api-mercado-ripley/6n4UtAt" target="_blank">Cómo obtengo esto?</a> </b></h6>
                <label className="form-control-label">API ID</label>
                <input type="password" className="form-control mb-3" name="key" value={estructuraActual?.detalles.key} onChange={handleChange} />

                {/* <label className="form-control-label">SECRETO</label>
                <input type="password" className="form-control mb-3" name="client_secret" value={estructuraActual?.detalles.client_secret} onChange={handleChange} /> */}

                {/* <label className="form-control-label">SECRETO</label>
                <input type="password" className="form-control mb-3" name="secret" value={estructuraActual?.detalles.secret} onChange={handleChange} />

                <label className="form-control-label">NÚMERO SENDER</label>
                <input type="text" className="form-control mb-3" placeholder="Ejemplo 56912345678" name="number" value={estructuraActual?.detalles.number} onChange={handleChange} /> */}
            </Col>
            <Col className="text-center">
                <img src={`${url_images}/integraciones/ripley.png`} style={{ width: "60%", marginBottom: 20 }} />
                <h3><b>Configura tu cuenta de Marketplace Ripley</b></h3>
                <h5>Manten sincronizados tus inventarios, recibe tus pedidos y sincroniza tus listas de precios</h5>
                <p>Contáctanos para realizar la configuración y puesta en marcha por tí.</p>
            </Col>
        </Row>
    </div>
}

export default RipleyConfiguracion