const ORDENES = [
    { group: "datos de la orden", key: "pedido", label: "Referencia"},
    { group: "datos de la orden", key: "tipo_servicio", label: "Tipo de servicio"},
    { group: "datos de la orden", key: "picking", label: "Picking"},
    { group: "datos de la orden", key: "despacho", label: "Despacho rápido"},
    { group: "datos del app", key: "status", label: "Estado App"},
    { group: "datos de la orden", key: "campos_personalizados", label: "Mas datos"},
    { group: "datos de la orden", key: "tickets", label: "Tickets"},
    { group: "datos personales", key: "destinatario", label: "Destinatario"},
    { group: "datos personales", key: "phone", label: "Teléfono"},
    { group: "datos personales", key: "email", label: "Email"},
    { group: "datos de ubicación", key: "direccion", label: "Dirección"},
    { group: "datos de la orden", key: "volumen", label: "Volumen"},
    { group: "datos de la orden", key: "peso", label: "Peso"},
    { group: "datos de la orden", key: "bultos", label: "Bultos"},
    { group: "datos de la orden", key: "ruta", label: "Ruta"},
    { group: "datos de la orden", key: "proveedor", label: "Proveedor"},
    { group: "fechas", key: "compromiso", label: "Compromiso"},
    { group: "fechas", key: "fecha_compromiso_desde", label: "Fecha recogida estimada"},
    { group: "fechas", key: "fecha_compromiso_hasta", label: "Fecha entrega estimada"},
    { group: "fechas", key: "fecha_entregado", label: "Fecha entregado"},
    { group: "datos de la orden", key: "conductor", label: "Conductor"},
    { group: "datos de la orden", key: "conductor_asignado", label: "Conductor asignado"},
    { group: "datos del app", key: "visible", label: "Visible en app"},
    { group: "datos del app", key: "finalizado", label: "Finalizado"},
    { group: "datos de ubicación", key: "pais", label: "País"},
    { group: "datos de ubicación", key: "region", label: "Región"},
    { group: "datos de ubicación", key: "ciudad", label: "Ciudad"},
    { group: "datos de ubicación", key: "comuna", label: "Comuna"},
    { group: "datos de ubicación", key: "latitud", label: "Latitud"},
    { group: "datos de ubicación", key: "longitud", label: "Longitud"},
    { group: "datos de la orden", key: "reintentos", label: "Reintentos"},
    { group: "datos de la orden", key: "remitente", label: "Remitente"},
    { group: "datos de la orden", key: "origen_direccion", label: "Dirección recogida"},
    { group: "datos de la orden", key: "origen_pais", label: "País recogida"},
    { group: "datos de la orden", key: "origen_region", label: "Región recogida"},
    { group: "datos de la orden", key: "origen_comuna", label: "Comuna recogida"},
    { group: "fechas", key: "fecha", label: "Fecha creado"},
    { group: "datos de la orden", key: "canal", label: "Canal"},
]


export const Esquema = {
    ORDENES:ORDENES
}