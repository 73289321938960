const formatoMoneda = value => {
    return Intl.NumberFormat("en-DE").format(Math.round(value))
}

const sortDynamic = (key, order = 'asc') => {
    const sortOrder = order === 'asc' ? 1 : -1
    return (a, b) => {
        const A = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        const B = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];
        if ( A < B ){
            return sortOrder * -1
        }else if ( A > B ) {
            return sortOrder * 1
        } else {
            return 0
        }
    }
}

const extraerNumeros = (str) => {
    const resultado = str.match(/\d+/g);
    return resultado ? resultado.map(Number) : [];
}

const esNumero = (cadena) => {
        // Primero, verifica si la cadena representa un número.
        const esNumero = !isNaN(cadena) && !isNaN(parseFloat(cadena));

        // Luego, verifica si el número es menor que 1.
        if (esNumero) {
            const numero = parseFloat(cadena);
            return numero < 0.001;
        }
    
        // Si la cadena no es un número o el número no es menor que 1, retorna false.
        return false;
}

const dimensionesMinimasArreglo = (objetos) => {
    // Inicializar las dimensiones máximas para cada orientación
    let horizontal = { ancho: 0, alto: 0, largo: 0 };
    let vertical = { ancho: 0, alto: 0, largo: 0 };
    let profundidad = { ancho: 0, alto: 0, largo: 0 };

    objetos.forEach(obj => {
        // Calcular las dimensiones totales para este objeto, teniendo en cuenta la cantidad
        let cantidad = obj.quantity || 1;
        let anchoTotal = obj.ancho * cantidad;
        let altoTotal = obj.alto * cantidad;
        let largoTotal = obj.largo * cantidad;

        // Sumar las dimensiones para cada orientación
        horizontal.ancho += anchoTotal;
        vertical.alto += altoTotal;
        profundidad.largo += largoTotal;

        // Tomar el máximo para las otras dos dimensiones
        horizontal.alto = Math.max(horizontal.alto, obj.alto);
        horizontal.largo = Math.max(horizontal.largo, obj.largo);

        vertical.ancho = Math.max(vertical.ancho, obj.ancho);
        vertical.largo = Math.max(vertical.largo, obj.largo);

        profundidad.ancho = Math.max(profundidad.ancho, obj.ancho);
        profundidad.alto = Math.max(profundidad.alto, obj.alto);
    });

    // Función para calcular el volumen
    const calcularVolumen = (dim) => dim.ancho * dim.alto * dim.largo;

    // Comparar los volúmenes para cada orientación y elegir el mínimo
    let minVolumen = calcularVolumen(horizontal);
    let minDimensiones = horizontal;

    if (calcularVolumen(vertical) < minVolumen) {
        minVolumen = calcularVolumen(vertical);
        minDimensiones = vertical;
    }

    if (calcularVolumen(profundidad) < minVolumen) {
        minDimensiones = profundidad;
    }

    return minDimensiones;
}

module.exports = {
    formatoMoneda,
    sortDynamic,
    extraerNumeros,
    esNumero,
    dimensionesMinimasArreglo
}