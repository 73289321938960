import { useEffect, useState } from "react"
import { formatoMoneda } from "../../lib/helpers/main"
import { Button, Card, Col, Modal, Row } from "react-bootstrap"
import AddNewPaymentGateway from "../financiero/nuevo"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"
import { FaCcVisa, FaCheckCircle, FaPlusCircle } from "react-icons/fa"
import { pasarelas, urlapi } from "../../lib/backend/data"
import { toast } from "react-toastify"
import { url_images } from "../../lib/global/data"
import { urlByPaymentGateway } from "../../lib/helpers/helpers"
import { Wallet } from "@mercadopago/sdk-react"

const Balance = (props) => {

    const [ saldo, setSaldo ] = useState(0)
    const [ mPPeference, setMPPreference ] = useState('')
    const [ showAmmount, setShowAmmount ] = useState(false)
    const [ loading, setLoading] = useState(false)
    const [ gateways, setGateways ] = useState(pasarelas)
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [ methods, setMethods ] = useState([])
    const [ tipoMetodoPago, setTipoMetodoPago ] = useState(false)
    const [ ammount, setAmmount ] = useState(10000)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        return fetch(`${urlapi}/payments/list`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res)){
                setMethods([...[], ...res])
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const iconByCardType = (val) => {
        const cardType = val.toString().toLowerCase()
        const tipos = {
            visa: <FaCcVisa size={30} className='mr-2' />
        }
        return tipos[cardType] ? tipos[cardType] : cardType
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleClose = () => {
        return setShowAmmount(false)
    }

    const handlePaymentSelection = (payment) => {
        setSelectedPayment(payment);
    };

    const mostrarMetodos = () => {
        if(loading) return <p>Cargando...</p>

        if(mPPeference) return <div>
        <Wallet initialization={{ preferenceId: mPPeference.id, redirectMode: 'blank' }} locale="es-CL" customization={{ texts:{ valueProp: 'smart_option'}}} />
        </div>

        if(methods.length < 1) return <div className="mb-3">
            <h5 className='mb-0 text-primary' style={{ fontWeight: "bold" }}>No tienes métodos de pago agregados</h5>
            {/* <img className='d-block' src={`${url_images}/animations/Contactless Payment.gif`} style={{ width: 250, maxWidth: "80%", margin: "0 auto" }} /> */}
        </div>

        return <div>
            {methods.map((metodo, index) => {
                return <Card
                    key={index}
                    className='hover p-3 mb-3'
                    onClick={() => handlePaymentSelection(metodo._id)}
                >
                    <p className='mb-0'>{selectedPayment === metodo._id ? <FaCheckCircle color='green' className='mr-2' /> : false } {iconByCardType(metodo.card_type)} Términa en {metodo.card_number.replace(/x/gi, "")}</p>
                </Card>
            })}
        </div>
    }

    const runActionByPaymentGateway = (name, data) => {
        switch (name) {
            case "transbank":
                return false // redireccionarUrl(data.url_webpay, { TBK_TOKEN: data.token })
            case "mercadopago":
                if(data) if(typeof data.id) return setMPPreference(data)
                return 
            default:
                break;
        }
        return 
    }


    const generarConexionPago = async (name) => {

        if(loading) return false
        setSelectedPayment('')
        setLoading(true)

        const config = urlByPaymentGateway(name, session.data.propietario, session.data.email, ammount)

        let settings = {
            method: config.method,
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        }
        if(config.method === "POST") settings.body = config.body
        return fetch(config.url, settings)
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(typeof res === "object"){
                runActionByPaymentGateway(name, res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const handleChangeAmmount = (e) => {
        setAmmount(e.target.value)
    }

    const confirmarPago = async () => {
        if(loading) return false
        setLoading(true)
        return fetch(`${urlapi}/payments/new`,{
            method:'POST',
            body: JSON.stringify({
                ammount: parseInt(ammount),
                id_payment_method: selectedPayment,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success('Operación realizada con éxito')
                setTimeout(() => {
                    window.location.reload()
                }, 1000);
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })

    }

    const mostrarModal = () => {
        return <Modal show={showAmmount} size="lg" onHide={handleClose} centered >
        <Modal.Header closeButton>
        <Modal.Title>Agregar saldo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row className="mb-3">
                <Col>
                <label className="form-control-label d-block">Introduce el monto</label>
                <input className="form-control" step={1000} value={ammount} defaultValue={10000} type="number" min={10000} onChange={handleChangeAmmount} />
                </Col>
            </Row>
            {mostrarMetodos()}
            {
                        gateways.filter(p => p.name !== "transbank").map((pasarela, index) => {
                            return <Card onClick={() => generarConexionPago(pasarela.name)} key={index} style={{ textTransform: "uppercase" }} className='hover bordered shadow-sm p-3 mb-3'>
                                <p style={{ fontSize: 11 }} className="mb-2">Pagar con</p>
                                <h6 className="mb-0">{pasarela.logo ? <img style={{ height: 30 }} src={pasarela.logo} /> : pasarela.description }</h6>
                            </Card>
                        })
            }
            { selectedPayment ? <Button size="sm" disabled={loading} className='w-100' variant="success" onClick={() => confirmarPago()} >{ !loading ? "CONFIRMAR" : "ESPERE UN MOMENTO..."}</Button> : false }

        </Modal.Body>
    </Modal>
    }

    const mostrarCargaSaldo = () => {
        return <div>
            <Button size="sm" variant="secondary" onClick={() => setShowAmmount(true)} >AGREGAR SALDO</Button>
        </div>
    }

    return <div className="mb-3">
        <h4 style={{ fontSize: 23, fontWeight: "bold" }}>Saldo</h4>
        <h2>${formatoMoneda(saldo)}</h2>
        {mostrarCargaSaldo()}
        {mostrarModal()}
        <hr/>
    </div>
}

export default Balance