import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Table, Dropdown } from 'react-bootstrap'
import data from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../../redux/actions/session';
import { PiWarehouseDuotone } from 'react-icons/pi';
import { LiaRouteSolid } from 'react-icons/lia';
import { modulo, submodulo } from './data';
import { IoIosAttach } from 'react-icons/io';
import { fechaATexto } from '../../../lib/helpers/helpers';
import { FaRegClock } from 'react-icons/fa';
import { Switch } from '@mui/material';

const OrdenMensajesListado = (props) => {
    const {
        id_orden,
        condicion_default
    } = props
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ crearNuevo, setCrearNuevo ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    // Boton de crear ruta nueva
    const [show, setShow] = useState(false); 
    const [isActive, setIsActive] = useState(false);
    const [mostrarTabla, setMostrarTabla] = useState(false);




    const obtenerProveedores = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/ordenes/${submodulo}/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setProveedores(res.datos)
                setTotal(res.total)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    const onClickProveedor = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        setCrearNuevo(false)
        setShow(!show)
        setIsActive(true)
        return fetch(`${data.urlapi}/ordenes/${submodulo}/detail?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setVehiculoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    // const onClickEnviarMensaje = (id) => {
    //     console.log('Enviar mensaje', id)
    // }

    // const onClickResponder = (id) => {
    //     console.log('Responder', id)
    // }

    // const onClickAdjuntar = (id) => {
    //     console.log('Adjuntar', id)
    // }

    // const onClickVerTracking = (id) => {
    //     console.log('Ver tracking', id)
    // }

    // const onClickAgregarContactos = (id) => {
    //     console.log('Agregar contactos', id)
    // }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        let cond =  condicion_default ? condicion_default : {}
        if(id_orden) cond.id_orden = id_orden
        obtenerProveedores(1, cond)
    }, [ condicion_default ]) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const tabla_registros = (registros) => {
        if(loadingProveedores===true) return cargandoScreen()
        if(registros.length < 1) return  <div className='text-center'>
            <h5 style={{fontWeight:900}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h6>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h6>
            {paginacion(data.pagina, total)}
            
            {
                mostrarTabla ? (
                    <Table bordered hover className="bg-white mb-3">
                    <thead className='bg-secondary text-white'>
                        <tr>
                        <th>FECHA</th>
                        <th>ENVIADO POR</th>
                        <th>MENSAJE</th>
                        <th>ADJUNTO</th>
                        {/* <th>ACCIONES</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                        registros.map(orden => (
                            <tr key={orden._id}>
                            <td style={{ fontSize: 11, textTransform: "uppercase" }}>{fechaATexto(orden.createdAt)}</td>
                            <td style={{ fontSize: 11, textTransform: "uppercase" }}>{orden.creador?.nombres}</td>
                            <td>{orden.content}</td>
                            <td>
                                {
                                orden.atachment.map((archivo, i) => (
                                    <div key={`ar-${orden._id}-${i}`}>
                                    { archivo.url ? <a href={archivo.url} target="_blank" rel="noreferrer"><h6 style={{fontWeight: "bold", color: "blue" }}><IoIosAttach /> {archivo.name}</h6></a> : <h6><IoIosAttach /> {archivo.name}</h6> }
                                    </div>
                                ))
                                }
                            </td>
                            {/* <td className='text-center p-0'>
                                <Dropdown>
                                <Dropdown.Toggle variant="info" size="sm" className='py-0 shadow-sm'>
                                    <i className="fa-solid fa-eye"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item style={{fontSize:14}} onClick={() => onClickEnviarMensaje(orden._id)}>Enviar mensaje</Dropdown.Item>
                                    <Dropdown.Item style={{fontSize:14}} onClick={() => onClickResponder(orden._id)}>Responder</Dropdown.Item>
                                    <Dropdown.Item style={{fontSize:14}} onClick={() => onClickAdjuntar(orden._id)}>Adjuntar</Dropdown.Item>
                                    <Dropdown.Item style={{fontSize:14}} onClick={() => onClickVerTracking(orden._id)}>Ver tracking</Dropdown.Item>
                                    <Dropdown.Item style={{fontSize:14}} onClick={() => onClickAgregarContactos(orden._id)}>Agregar contactos</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>                            
                            </td> */}
                            </tr>
                        ))
                        }
                    </tbody>
                    </Table>
                ) : (
                    <Card className='bg-light p-3'>
                        {
                        registros.map(orden => {

                            return <div key={orden._id} className="mb-3">
                                <Card className={`p-3 mb-3 ${ orden.admin ? "w-75 ml-auto shadow-sm" : "shadow w-75" }`} style={{ background: orden.admin ? "#e4e4e4" : "" }}>                                <Row>
                                    <Col><p className='m-0' style={{ fontSize: 11, textTransform: "uppercase" }}><FaRegClock /> {fechaATexto(orden.createdAt)}</p></Col>
                                    <Col className='text-right'><p className='m-0' style={{ fontSize: 11, textTransform: "uppercase" }}>ENVIADO POR {orden.creador?.nombres}</p></Col>
                                </Row>
                                <hr/>
                                <p className='mb-0' style={{fontWeight:700}}>{orden.content}</p>
                                {
                                    orden.atachment.map((archivo,i) => {
                                        return <div key={`ar-${orden._id}-${i}`}>
                                            
                                            { archivo.url ? <a href={archivo.url} target="_blank" rel="noreferrer"><h6 style={{fontWeight: "bold", color: "blue" }}><IoIosAttach /> {archivo.name}</h6></a> : <h6S><IoIosAttach /> {archivo.name}</h6S> }
                                        </div>
                                    })
                                }
                                </Card>
                            </div>
                        }
                        
                        )
                        }
                    </Card>
            )
                    }
        </div>
    }

    const handleSubmitPalabra = (e) => {
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { nombre: parametroBusqueda }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProveedores(1, condicion)
    }
    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerProveedores(1, {})
    }

    const onProveedorGuardado = (data) => {
        console.log(data)
        const i = proveedores.findIndex(a => a._id === data._id)
        if(i < 0) return false
        proveedores[i] = data
        return setProveedores(prev => [...[], ...proveedores])
    }

    const crearProveedor = () => {
        setLoadingDetalle(true)
            setIdSeleccionado(false)
            setVehiculoSeleccionado(false)
            setShow(!show)
            setIsActive(false)
            setTimeout(() => {
                setLoadingDetalle(false)
            }, 100);
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const onProveedorNuevo = (data) => {
        proveedores.unshift(data)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const onFieldDeleted = (id) => {
        const i = proveedores.findIndex(c => c._id === id)
        if(i < 0) return false
        proveedores.splice(i,1)
        setVehiculoSeleccionado(false)
        return setProveedores(prev => [...[], ...proveedores])
    }
 
    return <div>
        {modalFiltro()}
        <Row>
            <Col md={12}>
            <div className='d-flex justify-content-between'>
            <h4 className='pt-2' style={{fontWeight:900}}> Mensajes</h4>
                {/* <Row>
                    <Col md={12}>
                        <form onSubmit={handleSubmitPalabra}>
                        <input className='mb-3 form-control shadow-sm' placeholder='BUSCAR' onChange={handleChangePalabra} />
                        </form>
                    </Col>
                </Row> */}
                <div className='d-flex justify-content-end align-items-end'>
                    <div className='d-flex align-items-end'>
                        <p className='mr-1' style={{fontWeight:700}}>{mostrarTabla ? 'Modo Chat' : 'Modo Tabla'}</p>
                    </div>
                    <div>
                        <Switch className='mb-2' checked={mostrarTabla} onChange={() => setMostrarTabla(!mostrarTabla)} />
                    </div>
                </div>
            </div>
                {tabla_registros(proveedores)}

            </Col>
        </Row>
    </div>

}

export default OrdenMensajesListado