import { Button, Card, Form, OverlayTrigger, Tab, Table, Tabs, Tooltip } from 'react-bootstrap'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
// import ProveedoresTransporteListado from './listado'
import { useEffect, useState } from 'react'
import HelperModal from '../general/helperModal'
import SelectorEmpresas from '../empresas/selector'
import SelectorZonas from '../geodata/selector_zonas'
import SelectorServicios from '../servicios/selector'
import { default_proveedores } from '../proveedores_transporte/data'
import { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify'
import { extraerNumeros, formatoMoneda } from '../../lib/helpers/main'
import { cerrarSesion } from '../../redux/actions/session'
import PantallaError from '../general/pantalla_error'
import Tarifas from './tarifas'
import CrearEnvioIndividual from '../rutas/importador/individual'
import CotizarEnvioSimple from '../rutas/importador/solo_cotizador'
import RutasImportador from '../rutas/importador/importador'
import { FaCheck, FaCheckDouble, FaRegBookmark, FaRegFileExcel } from 'react-icons/fa'
import ProveedoresTransporte from "../../subComponents/proveedores_transporte";
import { GrConnectivity } from 'react-icons/gr'
import RutasImportadorAvanzado from '../rutas/importador/importador_avanzado'

const TarifasCotizacion = (props) => {
    const session = useSelector(state => state.miusuario)
    const [ condicionDefault, setCondicionDefault ] = useState({ proveedor: true })
    const [ filtros, setFiltros ] = useState({})
    const [ lote, setLote ] = useState({})
    const [ zonas, setZonas ] = useState([])
    const [ rangos, setRangos ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ tarifasCargadas, setTarifasCargadas ] = useState([])
    const pais = useSelector(state => state.pais)
    const providers = default_proveedores
    const dispatch = useDispatch()


        return <div className="fluid">
                <Tabs defaultActiveKey="cotizacion" id="uncontrolled-tab-example" className="mb-3">
                    <Tab eventKey="cotizacion" title={<div><h5><FaCheck /> Cotización individual</h5></div>}>
                        <CotizarEnvioSimple />
                    </Tab>
                    <Tab eventKey="cotizacion-masiva" title={<div><h5><FaRegFileExcel /> Cotización excel</h5></div>}>
                        <RutasImportador />
                    </Tab>
                    <Tab eventKey="avanzado" title={<div><h5><GrConnectivity /> Cotización desde API</h5></div>}>
                        <RutasImportadorAvanzado />
                    </Tab>
                    <Tab eventKey="tarifas" title={<div><h5><FaRegBookmark /> Tarifarios</h5></div>}>
                        <Tarifas />
                    </Tab>
                    <Tab eventKey="proveedores" title={<div><h5><FaRegBookmark /> Contratos</h5></div>}>
                        <ProveedoresTransporte />
                    </Tab>
                    {/* 
                    <Tab eventKey="cotizacion-creacion" title={<div><h5><FaCheckDouble /> Cotización y creacion</h5></div>}>
                        <CrearEnvioIndividual />
                    </Tab>
                    
                     */}
                </Tabs>
            </div>
}

export default TarifasCotizacion