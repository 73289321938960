import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { statusColor, statusString } from '../../lib/helpers/logistica';
import DetailEstadosCarga from './detail';
import data from '../../lib/backend/data'
import { Spinner } from 'react-bootstrap';
import PantallaCargando from '../general/pantalla_cargando';
import { StringMes } from '../../lib/helpers/dates';
import { useSelector } from 'react-redux';

const ListadoEstadosCarga = (props) => {
    const {
        size,
        hideMetaData,
        hideCreator,
        hideTitle,
        showLink
    } = props
    const [ estados, setEstados ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const [ cargadorMas, setCargarMas ] = useState(false)
    const hide_title = typeof hideTitle !== "undefined" ? hideTitle : false
    const [ conductores, setConductores ] = useState(props.conductores ? props.conductores : [] ) 
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const mes = props.mes ? props.mes : false
    const ano = props.ano ? props.ano : false
    
    const getEstados = (drivers, cantidad) => {

        const enviar_cantidad = typeof cantidad !== "undefined" ? cantidad : estados.length
        if(!drivers){
            setLoadingEstados(false)
            return false
        }
        setLoadingEstados(true)
        const url = `${data.urlapi}/estadisticas/list-by-driver`;
        return fetch(url,{
            method:'POST',
            body: JSON.stringify({
                conductores: drivers,
                mes, ano,
                skip: enviar_cantidad
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setLoadingEstados(false)
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                return setLoadingEstados(false)
            }
            const datos = pros ? pros : []
            if(datos){
                if(datos.estados){
                    if(Array.isArray(datos.estados) !== false){
                        setEstados(datos.estados)
                        setTotal(datos.total ? datos.total : 0)
                    }
                }
            }
            setLoadingEstados(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setLoadingEstados(false)
        })
    }

    const cargarMas = async () => {
        setCargarMas(true)
        const url = `${data.urlapi}/estadisticas/list-by-driver`;
        let options = []
        return fetch(url,{
            method:'POST',
            body: JSON.stringify({
                conductores,
                skip: estados.length
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(pros => pros.json())
        .then(pros => {
            if(!pros){
                toast.error('Ocurrió un error inesperado, Sin respuesta del servidor')
                return setCargarMas(false)
            } else if(pros.errorMessage){
                toast.error(`Ocurrió un error inesperado: ${pros.errorMessage}`, )
                return setCargarMas(false)
            }
            if(pros){
                if(pros.estados){
                    if(Array.isArray(pros.estados) !== false){
                        const nuevo_array = estados.concat(pros.estados)
                        setEstados(nuevo_array)
                    }
                }
            }
            setCargarMas(false)
        })
        .catch(error => {
            toast.error('Ocurrió un error inesperado')
            setCargarMas(false)
        })
    }

    useEffect(() => {
        const datos = props.conductores ? props.conductores : conductores
        getEstados(datos, 0)
    },[ props.conductores])


    const obtenerPedido = (id) => {
        if(props.obtenerPedido) return props.obtenerPedido(id)
        return false
    }

    const mostrarSubEstadoHTML = (sub_estado_entrega) => {
        if(sub_estado_entrega){
          if(typeof sub_estado_entrega === 'object'){
            if(sub_estado_entrega.titulo){
              return <span className="spanstatus mr-2" style={{ backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2' }}>{sub_estado_entrega.titulo}</span>
            }
          }
        }
        return false
      }
      
      const shoyEstadoEntregaHTML = (estadoentrega, estado_entrega, sub_estado_entrega) => {
        if(estado_entrega){
          if(typeof estado_entrega === 'object'){
            if(estado_entrega.titulo){
              return <div>
                <span className="spanstatus mr-2" style={{backgroundColor: estado_entrega.color ? estado_entrega.color : '#babbbb' }}>{estado_entrega.titulo}</span>
                {mostrarSubEstadoHTML(sub_estado_entrega)}
              </div>
            }
          }
        }
        return <span className="spanstatus" style={{backgroundColor: statusColor(estadoentrega)}}>{statusString(estadoentrega)}</span>
      }

      if(loadingEstados) return <PantallaCargando />

      const titular = hide_title === true ? false : <div>
        <h4>Estados de carga {mes ? `generados en ${StringMes(mes)}` : false }</h4>
        <h6>Se muestran {estados.length} de {total}</h6>
      </div>

    return <div >
    {titular}
    { estados.map(estado => {
            return <DetailEstadosCarga size={size} hideMetaData={hideMetaData} hideCreator={hideCreator} showLink={showLink} estado={estado} obtenerPedido={(id)=>obtenerPedido(id)} />
        }) }

    { estados.length < total ? <div>
                {cargadorMas ? <Spinner animation='border' /> : <button className='btn btn-sm btn-outline-primary' onClick={()=>cargarMas()}>CARGAR MÁS</button> }
    </div> : false }

    </div>
}

export default ListadoEstadosCarga