import { useEffect, useRef, useState } from "react"
import { urlapi } from "../../lib/backend/data"
import Select from 'react-select';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const SelectorEstados = (props) => {
    const {
        propietario,
        defaultValue,
        key,
        tipo_dato,
        hideLabel
    } = props
    const [ estados, setEstados ] = useState([])
    const [ estadosCarga, setEstadosCarga ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const isMulti = typeof props.isMulti !== "undefined" ? true : false
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const titulo = props.titulo ? props.titulo : 'Estado de la carga'
    const full_payload = typeof props.full_payload !== "undefined" ? true : false
    const modulo = tipo_dato === "subestados" ? "subestados" : "estados"
    const selectRef = useRef(null)

    const handleChangeSelectEstado = (e) => {
        
        if(isMulti === true){
            let opciones = []
            if(e){
                e.map(valor => {
                    if(full_payload === true){
                        const i = estadosCarga.findIndex(es => es.codigo_estado === valor.value)
                        if(i > -1) opciones.push(estadosCarga[i])
                    } else {
                        opciones.push(valor.value)
                    }
                })
            }
            if(props.onChange) props.onChange(opciones)
            return setFiltroEstado(opciones)
        } else {
            let data = e
            if(data){
                if(full_payload === true){
                    
                    const i = estadosCarga.findIndex(es => es.codigo_estado === e.value)
                    if(i > -1) data = estadosCarga[i]
                }
            }
            if(props.onChange) props.onChange(data)
            return e
        }
    }

    const obtenerEstados = (rut) => {
        setLoadingEstados(true)
        const enviar_valor = rut ? rut : ""
        return fetch(`${urlapi}/estadoscarga/details?rut=${enviar_valor}`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){

            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data[modulo]) !== false){
                setEstadosCarga(data[modulo])
                let opciones = data[modulo].map(e => ({ value: e.codigo_estado, label: e.titulo }))
                let todas_los_estados = []
                if(modulo === "subestados"){
                    let opciones_agrupadas = []
                    for( const statuse of data.estados ){
                        const opcion = {
                            label: statuse.titulo,
                            options: data[modulo].filter(est => est.idparent === statuse._id).map(e => ({ value: e.codigo_estado, label: e.titulo }))
                        }
                        opciones_agrupadas.unshift(opcion)
                        todas_los_estados = [...todas_los_estados, ...opcion.options]
                    }
                    opciones = opciones_agrupadas
                }
                console.log({todas_los_estados})
                setEstados(opciones)
                setTimeout(() => {
                    if(defaultValue){
                        if(selectRef.current){
                            if(Array.isArray(defaultValue)){
                                const buscar_codigos = data[modulo].filter(e => defaultValue.includes(e._id)).map(e => e.codigo_estado)
                                const valores = todas_los_estados.filter(e => buscar_codigos.includes(e.value))
                                console.log({buscar_codigos,valores,defaultValue})
                                if(selectRef?.current) selectRef.current?.setValue(valores)
                            } else {
                                if(modulo === "subestados"){
                                    const pos = data[modulo].findIndex(e => e._id === defaultValue)
                                    console.log({ pos, todas_los_estados })
                                    if(pos > -1){
                                        const value_search = data[modulo][pos].codigo_estado
                                        const i = todas_los_estados.findIndex(e => e.value === value_search)
                                        console.log({ posi: i })
                                        if( i > -1 ){
                                            if(selectRef?.current) selectRef?.current.setValue(todas_los_estados[i])
                                        }
                                    }
                                } else {
                                    const pos = data[modulo].findIndex(e => e._id === defaultValue)
                                    console.log({ pos, todas_los_estados })
                                    if(pos > -1){
                                        const value_search = data[modulo][pos].codigo_estado
                                        const i = todas_los_estados.findIndex(e => e.value === value_search)
                                        if( i > -1 ){
                                            if(selectRef?.current) selectRef?.current.setValue(todas_los_estados[i])
                                        }
                                    }
                                    
                                }
                            }
                        }
                    }
                }, 100);
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    useEffect(() => {
        obtenerEstados(propietario)
    }, [ propietario ])

    return <div className="mb-3">
        { hideLabel === true ? false : <label className='d-block form-control-label'>{titulo}</label> }
                    <Select 
                        key={`estado-${key}`}
                        isClearable
                        ref={selectRef}
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectEstado}
                        isLoading={loadingEstados}
                        isDisabled={loadingEstados}
                        loadingMessage={() => "Cargando datos"}
                        isMulti={isMulti}
                        options={[...[{ value: "", label: "Cualquiera" }], ...estados]}
                        // defaultValue={}
                        placeholder="Filtrar estado..."
                        noOptionsMessage={()=>'Sin opciones'}
                        />
    </div>
}

export default SelectorEstados