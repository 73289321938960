import { Button, Col, Row } from "react-bootstrap"
import SelectorListasWhatsapp from "../../listas-whatsapp/listadoWhatsapp/selector"
import { IoSendSharp } from "react-icons/io5"
import { useState } from "react"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"

const EnviadorListaWhatsapp = (props) => {
    const {
        id_target
    } = props
    const [ listaWhatsapp, setListaWhatsapp ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    const handleChange = (e) => {
        setListaWhatsapp(e?.value)
    }

    const enviarWhatsapp = async () => {
        if(!listaWhatsapp) return toast.error("Introduce el código país")
        setLoading(true)
        return fetch(`${urlapi}/rutas/enlaces/ws-enviar`, {
            method: 'POST',
            body: JSON.stringify({
                id_target,
                lista: listaWhatsapp
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
              } else if(pros.sid){
                  toast.success(`Procesado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }


    return <div>
        <Row>
            <Col md={6}><SelectorListasWhatsapp titulo="Seleccione la lista de whatsapp" onChange={handleChange} /></Col>
            { listaWhatsapp ? <Col>
                <label className="form-control-label d-block">Click para enviar</label>
                <Button disabled={loading} onClick={() => enviarWhatsapp()}><IoSendSharp /> { !loading ? "ENVIAR" : "ENVIANDO..."}</Button>
            </Col> : null }
        </Row>
    </div>
}

export default EnviadorListaWhatsapp