import React, { Component} from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import Header from '../Header'
import { key_local_storage_user } from '../../lib/backend/data'
import 'moment/locale/es';
import CrearCanal from '../../subComponents/canales/crear'
import ListadoCanales from '../../subComponents/canales/listado'
import { cerrarSesion } from '../../redux/actions/session'

class Canales extends Component {
    constructor(props){
        super(props)
        this.state = {
            user: JSON.parse(localStorage.getItem(key_local_storage_user))
        }
    }
    render(){
        const { user } = this.state
        return(<ListadoCanales />)
    }
}

const mapStateToProps = (state) => {
    return {
        miusuario: state.miusuario
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        cerrarSesion: () => dispatch(cerrarSesion())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Canales);