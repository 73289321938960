import { useState, useEffect, useCallback } from "react"
import { Badge, Button, Card, Col, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import { nombreDNIPorPais } from "../../lib/helpers/data/internationa";
import CamposObligatoriosLeyenda from "../general/campos_obligatorios";
import { formatoMoneda } from "../../lib/helpers/main";
import { PiWarehouse } from "react-icons/pi";
import { debounce } from "@mui/material";
import LogsListado from "../integraciones/logs/listado";
import LogsSincronizacionListado from "../integraciones/logs/listado_sincronizacion";
import { canales } from "../ordenes/data";
import IntegracionesProducto from "./integraciones_producto";
// import DestinatariosBodegasListado from "./destinatarios_bodegas/list";
// import DestinatariosContactosListado from "./destinatarios_contactos/list";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [ loadingInventario, setLoadingInventario ] = useState(false)
    const [ loadingPrecios, setLoadingPrecios ] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [codigoValido, setCodigoValido] = useState(false)
    const [estadoCodigo, setEstadoCodigo] = useState('')
    const [loadingEstadoCodigo, setLoadingEstadoCodigo] = useState(false)
    const cm = <Badge variant="dark" >cm</Badge>

    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        await validarReferencia()
        if(codigoValido === false) return toast.error(`Codigo Item "${nuevoProveedor.codigo_item}" ya existe, o esta vacio`)
        const requeridos = [
            { value:'description', label: 'Descripcion'},
            { value: "codigo_item", label: "Código Item" },
            // { value: "peso", label: "Peso" },
            // { value: "alto", label: "Alto" },
            // { value: "ancho", label: "Ancho" },
            // { value: "largo", label: "Largo" },
            // { value: "price", label: "Precio" }
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: "${faltantes.join(', ')}". No deben haber letras en campos numericos.`)
        setLoading(true)
        return fetch(`${data.urlapi}/productos`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              
              return setLoading(false)
          })
          .catch(error => {
            toast.error("No se pudo crear el producto es probable que un codigo item ya exista, revisalo")
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        await validarReferenciaEditar()
        if(codigoValido === false){
            setLoading(false)
            return toast.error(`Codigo Item "${proveedor.codigo_item}" ya existe, o esta vacio`)
        } 
        return fetch(`${data.urlapi}/productos`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.success){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Guardado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro, No deben haber letras en campos numericos, ni campos vacios.')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        return setNuevoProveedor(nuevoProveedor)
    }

    const mostrarTextoEstadoCodigo = () => {
        if (codigoValido === true) return <p style={{ fontSize: 12, fontWeight: 'normal', marginBottom: 10 }} className='text-success'><i className="fa-solid fa-circle-check"></i>Codigo Item * {estadoCodigo}</p>
        if (estadoCodigo) return <p style={{ fontSize: 12, fontWeight: 'normal', marginBottom: 10 }} className='text-danger'><i className="fa-solid fa-triangle-exclamation"></i>Codigo Item * {estadoCodigo}</p>
        return <label className='form-control-label d-block'>Codigo Item *</label>
    }

    const mostrarEstadoCodigo = () => {
        if (loadingEstadoCodigo === true) return <p style={{ position: 'absolute', right: 30, bottom: 13, fontSize: 10 }}><Spinner size="sm" animation='border' /></p>
        return false
    }

    const validarReferencia = async () => {
        if(nuevoProveedor.codigo_item === ""){
            setCodigoValido(false)
            return setEstadoCodigo('Campo vacio')
        }
        if (!nuevoProveedor.codigo_item) {
            setCodigoValido(false)
            return setEstadoCodigo('Codigo inválido')
        }
        setLoadingEstadoCodigo(true)
        return fetch(`${data.urlapi}/productos/validar/codigo?codigo=${nuevoProveedor.codigo_item}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                console.log(res)
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingEstadoCodigo(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingEstadoCodigo(false)
                } else if (typeof res === 'object') {
                    setCodigoValido(res.valido === true ? true : false)
                    setEstadoCodigo(res.valido === true ? 'Válido' : 'Inválido')
                }
                return setLoadingEstadoCodigo(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingEstadoCodigo(false)
            })
    }

    const validarReferenciaEditar = async () => {
        if(proveedor.codigo_item === ""){
            setCodigoValido(false)
            return setEstadoCodigo('Campo vacio')
        }
        if (!proveedor.codigo_item) {
            setCodigoValido(false)
            return setEstadoCodigo('Codigo inválido')
        }
        setLoadingEstadoCodigo(true)
        return fetch(`${data.urlapi}/productos/validar/codigo/editar?codigo=${proveedor.codigo_item}&id=${proveedor._id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                console.log(res)
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingEstadoCodigo(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingEstadoCodigo(false)
                } else if (typeof res === 'object') {
                    setCodigoValido(res.valido === true ? true : false)
                    setEstadoCodigo(res.valido === true ? 'Válido' : 'Inválido')
                }
                return setLoadingEstadoCodigo(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingEstadoCodigo(false)
            })
    }

    const handleChangeOrden = (e) => {
        const { name, value } = e.target
        return setNuevoProveedor(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }


    const formularioNuevo = () => {

        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo Producto</h4>
                    <p>Los productos forman parte de tu empresa, están relacionados directamente a la rentabilización de la misma en esta plataforma.</p>
                    <CamposObligatoriosLeyenda />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Nombre *</label>
                    <input className="form-control" name="description" defaultValue={nuevoProveedor.description} onChange={handleChangeNuevo} />
                </Col>
                {/* <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Código Item</label>
                    <input className="form-control" name="codigo_item" value={nuevoProveedor.codigo_item} onChange={handleChangeNuevo} />
                </Col> */}
                <Col md={3} style={{fontWeight:500}}>
                        {mostrarTextoEstadoCodigo()}
                        {mostrarEstadoCodigo()}
                        <input className='form-control mb-3 shadow-sm' autoComplete='off' style={{ paddingRight: 10 }} name="codigo_item" onBlur={() => setTimeout(() => {
                            return validarReferencia()
                        }, 500)} onChange={handleChangeOrden} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Peso <Badge variant="dark" >Kg</Badge> </label>
                    <input className="form-control" name="peso" type="number" defaultValue={nuevoProveedor.peso} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Alto {cm}</label>
                    <input className="form-control" name="alto" type="number" defaultValue={nuevoProveedor.alto} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Ancho {cm}</label>
                    <input className="form-control" name="ancho" type="number" defaultValue={nuevoProveedor.ancho} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Largo {cm}</label>
                    <input className="form-control" name="largo" type="number" defaultValue={nuevoProveedor.largo} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Precio</label>
                    <input className="form-control" name="price" type="number" defaultValue={nuevoProveedor.price} onChange={handleChangeNuevo} />
                </Col>
                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/productos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            toast.success(`Eliminado exitosamente`)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const actualizarInventario = async (payload) => {
        setLoadingInventario(true)
        return fetch(`${data.urlapi}/productos/inventario`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(res._id){
                setLoadingInventario(false)
                return true
            }
            return setLoadingInventario(false)
        })
        .catch(error => setLoadingInventario(false))
    }

    const actualizarPrecios = async (payload) => {
        setLoadingPrecios(true)
        return fetch(`${data.urlapi}/productos/precios`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(res._id){
                setLoadingPrecios(false)
                return true
            }
            return setLoadingPrecios(false)
        })
        .catch(error => setLoadingPrecios(false))
    }

    const actualizar = useCallback(debounce((data) => actualizarInventario(data), 500), []);
    const actualizar2 = useCallback(debounce((data) => actualizarPrecios(data), 500), []);

    const handleChangeInventario = (e, i) => {
        const { value } = e.target
        const inv = { ...proveedor.inventarios[i] }
        const objeto = {
            id_bodega: inv._id,
            id_producto: proveedor._id,
            cantidad: parseInt(value)
        }
        if(isNaN( parseInt(objeto.cantidad) )) return
        actualizar(objeto)
    }
    
    const handleChangePrecio = (e, i) => {
        const { value } = e.target
        const inv = { ...proveedor.precios[i] }
        const objeto = {
            id_bodega: inv._id,
            id_producto: proveedor._id,
            cantidad: parseInt(value)
        }
        if(isNaN( parseInt(objeto.cantidad) )) return
        actualizar2(objeto)
    }

    const mostrarInventarios = () => {
        if(!proveedor.inventarios) return false
        if(!Array.isArray(proveedor.inventarios)) return false

        return <div>
            {/* <h4 className='mb-3'><PiWarehouse /> Inventarios</h4> */}
            { loadingInventario ? <p><Spinner animation="border" size="sm" /> Guardando cambios de inventario </p> : <p className="text-success">Inventarios guardados</p> }
            <Row>
                {
                    proveedor.inventarios.map((inv,i) => {

                        let inventarios = []
                        if(inv.warehouse_integrated) if(Array.isArray(inv.warehouse_integrated)) inventarios = inv.warehouse_integrated.map(e => e.value)

                        return <Col md={4} key={`inv-${i}`} className='mb-3'>
                            <Card>
                                <Card.Body>
                                    <h5>{inv.titulo}</h5>
                                    { inventarios.length > 0 ? <div>
                                        <p className="mb-0">Sincroniza con { inventarios.map((item, it) => <img key={canales[item] ? canales[item].title : it} src={canales[item] ? canales[item].logo : ""} style={{ display: "inline-block", height: 15 }} />)  }</p>
                                    </div> : false }
                                    <p className='mb-0'><strong>Stock</strong></p>
                                    <input className='form-control' disabled={loadingInventario} type="number" name="stock" defaultValue={inv.stock ? formatoMoneda(inv.stock) : 0} onChange={(e) => handleChangeInventario(e,i)} />
                                </Card.Body>
                            </Card>
                        </Col>
                    })
                }
            </Row>
        </div>
    }

    const mostrarPrecios = () => {
        if(!proveedor.precios) return false
        if(!Array.isArray(proveedor.precios)) return false

        return <div>
            {/* <h4 className='mb-3'><PiWarehouse /> Inventarios</h4> */}
            { loadingInventario ? <p><Spinner animation="border" size="sm" /> Guardando cambios de inventario </p> : <p className="text-success">Inventarios guardados</p> }
            <Row>
                {
                    proveedor.precios.map((inv,i) => {

                        let inventarios = []
                        if(inv.warehouse_integrated) if(Array.isArray(inv.warehouse_integrated)) inventarios = inv.warehouse_integrated.map(e => e.value)


                        return <Col md={4} key={`inv-${i}`} className='mb-3'>
                            <Card>
                                <Card.Body>
                                    <h5>{inv.titulo}</h5>
                                    { inventarios.length > 0 ? <div>
                                        <p className="mb-0">Sincroniza con { inventarios.map((item, it) => <img key={canales[item].title} src={canales[item].logo} style={{ display: "inline-block", height: 15 }} />)  }</p>
                                    </div> : false }
                                    <p className='mb-0'><strong>Precio</strong></p>
                                    <input className='form-control' disabled={loadingPrecios} type="number" name="stock" defaultValue={inv.stock ? inv.stock : 0} onChange={(e) => handleChangePrecio(e,i)} />
                                </Card.Body>
                            </Card>
                        </Col>
                    })
                }
            </Row>
        </div>
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <div>
            <h4 className="mb-0">Detalles del producto <b>{proveedor.description}</b></h4>
            <Button variant="link" disabled={removiendo} className="text-danger p-0 d-block mb-3" onClick={()=>confirmarEliminado(proveedor._id)} >{ removiendo ? <Spinner animation="border" /> : "Eliminar"}</Button>
            <Tabs defaultActiveKey="editar" >
            <Tab eventKey="editar" className="mt-4" title="Ficha informativa ">
                <Row>
                <Col className="mb-3">
            {/* <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>Esta es la forma en que debes escribirlo en el documento excel de importación o API</Tooltip>
                            }
                            >
                                <h5 style={{ backgroundColor: '#c7c7c7', borderRadius: 5, color: 'white', fontSize: 13, display: 'inline', padding: '3px 5px' }}><b style={{ color:'black' }}>SLUG </b> { proveedor.description ? proveedor.description.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'').normalize("NFD").replace(/[\u0300-\u036f]/g, "") : '' }</h5>
                          </OverlayTrigger> */}
        </Col>
        <Col md={12} className="mb-3">
            <label className="form-control-label d-block">Nombre</label>
            <input className="form-control" name="description" defaultValue={proveedor.description} onChange={handleChange} />
        </Col>
        <Col md={4} style={{fontWeight:500}}>
                        {mostrarTextoEstadoCodigo()}
                        {mostrarEstadoCodigo()}
                        <input className='form-control mb-3 shadow-sm' autoComplete='off' style={{ paddingRight: 10 }} name="codigo_item" defaultValue={proveedor.codigo_item} onBlur={() => setTimeout(() => {
                            return validarReferenciaEditar()
                        }, 500)} onChange={handleChange} />
                </Col>
        {/* <Col md={3} className="mb-3">
        <label className="form-control-label d-block">Código Item</label>
            <input className="form-control" name="codigo_item" defaultValue={proveedor.codigo_item}  onChange={handleChange}/>
                </Col> */}
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Peso <Badge variant="dark" >Kg</Badge></label>
                    <input className="form-control" name="peso"  type="number" defaultValue={proveedor.peso} onChange={handleChange} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Alto {cm}</label>
                    <input className="form-control" name="alto" type="number" defaultValue={proveedor.alto} onChange={handleChange} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Ancho {cm}</label>
                    <input className="form-control" name="ancho" type="number" defaultValue={proveedor.ancho} onChange={handleChange} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Largo {cm}</label>
                    <input className="form-control" name="largo" type="number" defaultValue={proveedor.largo} onChange={handleChange} />
                </Col>
                <Col md={4} className="mb-3">
                    <label className="form-control-label d-block">Precio</label>
                    <input className="form-control" name="price" type="number" defaultValue={proveedor.price} onChange={handleChange} />
                </Col>
        <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
        </Row>
            </Tab>
            <Tab eventKey={"inventarios"} className="mt-4" title="Inventarios" >
            {mostrarInventarios()} 
            </Tab>
            <Tab eventKey={"productos"} className="mt-4" title="Lista de precios" >
            {mostrarPrecios()} 
            </Tab>
            
            <Tab eventKey={"sync-inventory"} className="mt-4" title="Sincronización inventarios" >
                <Tabs defaultActiveKey={Object.keys(canales)[0]}>
                {
                    Object.keys(canales).map((canal, i) => {
                        return <Tab eventKey={canal} className="mt-4" title={<div><img src={canales[canal].logo} style={{ height: 15 }} /></div>} key={`canal-${i}`} >
                            <LogsSincronizacionListado condicion_default={{ id_recurso: proveedor._id, tipo: "sync-inventory", nombre: canal }} />
                        </Tab>
                    })
                }
                </Tabs>
            </Tab>
            <Tab eventKey={"sync-prices"} className="mt-4" title="Sincronización de precios" >
                <Tabs defaultActiveKey={Object.keys(canales)[0]}>
                {
                    Object.keys(canales).map((canal, i) => {
                        return <Tab eventKey={canal} className="mt-4" title={<div><img src={canales[canal].logo} style={{ height: 15 }} /></div>} key={`canal-${i}`} >
                            <LogsSincronizacionListado condicion_default={{ id_recurso: proveedor._id, tipo: "sync-prices", nombre: canal }} />
                        </Tab>
                    })
                }
                </Tabs>
            </Tab>
            <Tab eventKey="integraciones" className="mt-4" title="Integraciones" >
                <IntegracionesProducto id={proveedor._id}/>
            </Tab>
           {/* 
            <Tab eventKey="contactos" className="mt-4" title="Contactos" >
                <DestinatariosContactosListado id_cliente={proveedor._id} condicion_default={{ id_cliente: proveedor._id }} />
            </Tab> */}
        </Tabs>
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull