import { url_images } from "../../../lib/global/data"
import { estilo_last_mile } from "../../../lib/global/styles"

const LoadingLabelBoxes = (props) => {
    const {
        titulo 
    } = props
    const title = titulo ? titulo : "Cargando información..."
    return <div className="p-3 text-center">
    <img style={{ maxWidth: "100%", width: 200 }} src={`${url_images}/animations/scanner.gif`} />
    <h4 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>{title}</h4>
    </div>
}

export default LoadingLabelBoxes