import 'moment/locale/es';
import { Accordion, Alert, Button, Card, Col, Dropdown, Modal, Nav, Navbar, Row, Spinner, Tab, Table, Tabs } from 'react-bootstrap'
import { useState } from "react";
import { getDireccion, getNombreDestinatario } from "../../lib/helpers/pedidos/pedidos";
import BoxList from "../ordenes/box-list";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { urlapi } from "../../lib/backend/data";
import Etiquetas from "../etiquetas";
import { colorByStatusAsignacionRuta, detectarPedidosSinGeoDatos, obtenerZonasDePedidos, stringByStatusAsignacionRuta } from "../../lib/helpers/rutas";
import BoxEditarGeoDatosOrden from "../ordenes/box_editar_geodata";
import EmitirEstadoModalMasivoRuta from "../estados_carga/emitir_modal_masivo_ruta";
import ContadorEstadoV2 from "../ordenes/contador_estados_v2";
import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates";
import MapaRutaAsignacion from "../../components/Mapas/mapa_ruta_preview";
import { GrCalendar, GrCircleAlert } from 'react-icons/gr'
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import EtiquetaCourrier from '../ordenes/courriers/etiqueta_courriers';
import { FaCheckCircle, FaFileInvoice, FaRegFileExcel } from 'react-icons/fa';
import { AiOutlineBarcode } from 'react-icons/ai';
import { rutas } from '../../lib/routes/routes';
import ImprimirManifest from './imprimirManifest';
import { url_images } from '../../lib/global/data';



const EstaticoRuta = (props) => {
    const { 
        ruta 
    } = props;

    let pedido = false
    const estados = props.estados ? props.estados : []
    const [ loadingMaster, setLoadingMaster ] = useState(false)
    const [ loadingEtiquetas, setLoadingEtiquetas ] = useState(false)
    const [ filtradoEmpresa, setFiltradoEmpresa ] = useState('')
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ resize, setResize ] = useState(0)
    const [ loadingStatus, setLoadingStatus ] = useState(false)
    const [ modalShow, setModalShow ] = useState(false) //Modal de Etiquetas
    const dispatch = useDispatch()
    let marcadores = []
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    
    const handleChangeTab = (e) => {
      const nuevo = resize +1 
      return setResize(nuevo)
    }
    

    if(ruta.pedidos){
      if(Array.isArray(ruta.pedidos) !== false){
        for( const orden of ruta.pedidos ){
          if(orden.location){
              if(Array.isArray(orden.location.coordinates)){
                  if(orden.location.coordinates.length > 0){
                      marcadores.push({
                          lat: orden.location.coordinates[1],
                          lng: orden.location.coordinates[0],
                          ref: orden.pedido,
                          _id: orden._id,
                          destinatario: getNombreDestinatario(pedido)
                      })
                  }
              }
          }
        }
      }
    }

    const generarEtiquetas = () => {
      setLoadingEtiquetas(true)
      return fetch(`${urlapi}/bodega/etiquetas/multiples-por-idruta`,{
        method:'POST',
        body: JSON.stringify({
            id_ruta: ruta._id
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
      console.log(res)
      if(!res){
        toast.error("Sin respuesta")
      } else if(res.errorMessage){
        toast.error(res.errorMessage)
      } else if(Array.isArray(res) !== false){
        setModalShow(true)
        setEtiquetas(res)
      }
      return setLoadingEtiquetas(false)
    })
    .catch(error => {
      toast.error(error.message)
      return setLoadingEtiquetas(false)
    })
    }

    const descargarRuta = async () => {
      setLoadingMaster(true)
      return fetch(`${urlapi}/reporte/ordenes/ruta`,{
          method:'POST',
          body: JSON.stringify({
              id_ruta: ruta._id
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.blob()
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          const fecha = obtenerFechaHoraZonaHorariaLocal()
          a.download = `ruta-${ruta.id}-${fecha}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();
          return setLoadingMaster(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
      })
  }
    const descargarRutaProductos = async () => {
      setLoadingMaster(true)
      return fetch(`${urlapi}/reporte/ordenes/ruta-productos`,{
          method:'POST',
          body: JSON.stringify({
              id_ruta: ruta._id
          }),
          headers: {
              'Content-Type':'application/json',
              'Authorization': `Bearer: ${token}`
          }
      })
      .then(res => {
          if(res.status === 401) return dispatch(cerrarSesion())
          return res.blob()
      })
      .then(blob => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          const fecha = obtenerFechaHoraZonaHorariaLocal()
          a.download = `ruta-${ruta.id}-${fecha}.xlsx`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();
          return setLoadingMaster(false)
      })
      .catch(error => {
          toast.error("Error al consultar la información, intente nuevamente")
          return setLoadingMaster(false)
      })
  }

  const EtiquetasModal = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { etiquetas.length > 0 ? <div>
                {/* <Button variant="outline-primary" className="mb-3" size="sm" onClick={() => setEtiquetas(prev => [...[], ...[]]) }>CERRAR VISUALIZACIÓN ETIQUETAS</Button> */}
                <Etiquetas etiquetas={etiquetas} />
              </div> : false }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  
  const acciones = (asignacion) => {
    if(loadingStatus===true) return <Spinner animation="border" />

    if(["rechazada-cliente"].includes(asignacion.status)) return false
    if(["pendiente-cliente"].includes(asignacion.status)) {
        return <div>
          <Button variant="success" className="w-100 mt-3 shadow-sm" size="sm" onClick={() => performarAccion('aceptada-cliente', asignacion._id)} ><b>APROBAR</b></Button>
          <Button variant="danger" className="w-100 mt-3 shadow-sm" size="sm" onClick={() => performarAccion('rechazada-cliente', asignacion._id)} ><b>ANULAR INVITACIÓN</b></Button>
        </div>
    }
    
    return <div>
      <Button variant="danger" className="w-100 mt-3 shadow-sm" size="sm" onClick={() => performarAccion('rechazada-cliente', asignacion._id)} ><b>ANULAR INVITACIÓN</b></Button>
    </div>
  }

  const alertaValoresDistintos = (asignacion) => {
    if(!asignacion.propuesta_entrega_proveedor) return false
    if(asignacion.propuesta_entrega.entrega_desde !== asignacion.propuesta_entrega_proveedor.entrega_desde || asignacion.propuesta_entrega.entrega_hasta !== asignacion.propuesta_entrega_proveedor.entrega_hasta){
      return <div>
        <Alert variant="warning"><h6 className="mb-0" style={{ fontSize: 15, fontSize:700 }}><GrCircleAlert /> Las fechas cambiaron</h6></Alert>
      </div>
    }
  }

  const mostrarFechaPropuesta = (asignacion_) => {

    const desde = fechaATextoSimple(asignacion_.propuesta_entrega.entrega_desde)
    const hasta = fechaATextoSimple(asignacion_.propuesta_entrega.entrega_hasta)
    if(!asignacion_) return false
    if(!asignacion_.propuesta_entrega_proveedor) return false
    if( typeof asignacion_.propuesta_entrega_proveedor !== "object") return false

    const desde_proveedor = fechaATextoSimple(asignacion_.propuesta_entrega_proveedor ? asignacion_.propuesta_entrega_proveedor.entrega_desde :  asignacion_.propuesta_entrega.entrega_desde)
    const hasta_proveedor = fechaATextoSimple(asignacion_.propuesta_entrega_proveedor ? asignacion_.propuesta_entrega_proveedor.entrega_hasta :  asignacion_.propuesta_entrega.entrega_hasta)

    if( desde_proveedor === desde && hasta_proveedor === hasta ) return false

    return <div>
      <hr className="hr" />
            <p className="mb-0"><b><GrCalendar /> Fecha propuesta por proveedor</b></p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Desde</b> {desde_proveedor}</p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Hasta</b> {hasta_proveedor}</p>
    </div>
  }
    
  const mostrarInvitaciones = () => {
    if(!ruta.asignaciones) return false

    return <div className='mb-3'>
      <h6 className="mb-0" style={{fontWeight:700}}>Asignaciones: {ruta.asignaciones.length}</h6>
      <Accordion defaultActiveKey={0}>
      {
        ruta.asignaciones.map((asignacion,ia) => {
          return <div key={asignacion._id}>
          <Card className="text-primary mt-3">
            <Accordion.Toggle as={Card.Header} eventKey={ia.toString()}>
            <p className="mb-0" style={{ fontSize: 10 }}><b>CREADA:</b> {fechaATexto(asignacion.createdAt)}</p>
            <p className="mb-0" style={{ fontSize: 10 }}><b>ACTUALIZADA:</b> {fechaATexto(asignacion.updatedAt)}</p>
            <h6 className='mb-0' style={{ color: colorByStatusAsignacionRuta(asignacion.status), fontWeight:700 }}><i className="fa-solid fa-circle-exclamation text-warning"></i> {stringByStatusAsignacionRuta(asignacion.status)}</h6>
            <div className='d-flex justify-content-end'><i className="fa-solid fa-caret-down"></i></div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={ia.toString()}>
              <Card.Body>
                {alertaValoresDistintos(asignacion)}
            <p className="mb-0" style={{fontWeight:700}}><b><i className="fa-solid fa-calendar-days"></i> Fecha propuesta</b></p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Desde</b> {fechaATextoSimple(asignacion.propuesta_entrega.entrega_desde)}</p>
            <p className="mb-0" style={{ fontSize: 11 }}><b>Hasta</b> {fechaATextoSimple(asignacion.propuesta_entrega.entrega_hasta)}</p>
            {mostrarFechaPropuesta(asignacion)}
              {acciones(asignacion)}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
            
          </div>
        })
      }
      </Accordion>
      {/* <hr className="hr" />  */}
    </div>
  }

  const performarAccion = (status,idasignacion) => {
    setLoadingStatus(true)
    return fetch(`${urlapi}/rutas/asignaciones`,{
        method:'PUT',
        body: JSON.stringify({
            idasignacion,
            update: { status }
        }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return dispatch(cerrarSesion())
        return res.json()
    })
    .then(res => {
        if(!res){
            toast.error('Sin datos')
            return setLoadingStatus(false)
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return setLoadingStatus(false)
        } else if(res._id){
            toast.success('Operación realizada exitosamente')
            setTimeout(() => {
                window.location.reload()
            }, 500);
        }
        return setLoadingStatus(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información, intente nuevamente")
        return setLoadingStatus(false)
    })
}

const verificarMismaEmpresa = (empresas) => {
  if(!session.data.empresa) return empresas
  return empresas.filter(emp => ![ session.data.empresa.rut ].includes(emp.rut) )
}

const mostrarEmpresas = () => {
  if(!ruta) return false
  if(!ruta.empresas) return false
  if(Array.isArray(ruta.empresas) !== true ) return false
  const empresas = verificarMismaEmpresa(ruta.empresas)
  if(empresas.length < 1) return false

  return <div>
    <h4>Empresas en este manifiesto</h4>
    <Row>
    {
      empresas.map((business,ib) => {
        return <Col md={3} key={`business-${ib}`} className='mb-3' >
          <Card className='p-3 hover' style={{ border: filtradoEmpresa === business.rut ? "2px solid blue" : false }} onClick={() => {
            let valor = business.rut
            if(filtradoEmpresa === business.rut) valor = ''
            return setFiltradoEmpresa(valor)
          }}>
          <h3 className='mb-0' style={{ fontWeight: "bold" }}>{business.razon_social}</h3>
          <p className='mb-0' >{business.rut}</p>
          </Card>
        </Col>
      })
    }
    </Row>
  </div>
}

    const mostrarVehiculo = (driver) => {
      if(!driver) return false
      return <div className='d-flex flex-column flex-md-row justify-content-between px-2'>
            <p className="mb-0" style={{ fontWeight:700, fontSize:12 }}><b>MODELO:</b> {driver.modelo}</p>
            <p className="mb-0" style={{ fontWeight:700, fontSize:12 }}><b>AÑO:</b> {driver.ano}</p>
            <p className="mb-0" style={{ fontWeight:700, fontSize:12 }}><b>PATENTE:</b> {driver.patente ? driver.patente.toUpperCase() : "SIN INFORMACIÓN"}</p>
            <hr className="hr w-100 d-md-none" /> 
        </div>
    }

    const mostrarInformacionFlujoCourriers = (externos) => {
      let courriers = externos.map(p => p.courrier) 
      return <div>
        <Card className='p-3'>
        <EtiquetaCourrier id_ruta={ruta._id} recoleccion={ruta?.recoleccion} id_pedido={externos.map(pe => pe._id)} />
        </Card>
      </div>
    }

    // if(!ruta) return <div className='p-5'>
    // <Row className="justify-content-md-center">
    //     <Col md={6} className="text-center">
    //       <img alt="pedidos" src="images/pedidos.png" style={{ width: "50%" }} />
    //     <h4>Selecciona una ruta para visualizarla</h4>
    //     </Col>
    // </Row>
    // <hr className="hr" /> 
    // </div>

    const copiarDirecciones = () => {
      const texto = ruta.pedidos.map(p => {
        const direccion = getDireccion(p)
        return direccion ? direccion : false
      })
      const string = texto.join(`\n\n`)
      navigator.clipboard.writeText(string)
      return toast.info("Texto copiado")
    }

    let zonas = obtenerZonasDePedidos(ruta.pedidos)
    let invalidData = detectarPedidosSinGeoDatos(ruta.pedidos)

    const registros = filtradoEmpresa ? ruta.pedidos.filter(or => or.propietario === filtradoEmpresa) : ruta.pedidos

    const externos = ruta.pedidos.filter(p => p.recoleccion === true)


    return <div className='text-primary '>

            <Row className='justify-content-left my-3'>
                    <img className='mb-3' alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
            </Row>

            <div className='d-flex justify-content-between'>
                <div>
                    <h3 className="mb-0" style={{fontWeight:900}}>Ruta {ruta.id}</h3>
                    <p className="mb-3">{ruta.descripcion}</p>
                </div>
                <div className='text-right'>
                    <p style={{ fontSize: 11 }} className="mb-2"><b>Creado</b> {fechaATexto(ruta.createdAt)}</p>
                    <h6 className="mb-0" style={{fontWeight:700}}>Entrega</h6>
                    <p style={{ fontSize: 11 }} className="mb-0"><b>Desde</b> {fechaATextoSimple(ruta.entrega_desde)}</p>
                    <p style={{ fontSize: 11 }} className="mb-0"><b>Hasta</b> {fechaATextoSimple(ruta.entrega_hasta)}</p>
                </div>
            </div>
                <hr className="hr" />

            <Row>
            <Col md={12}>
                {/* {mostrarInvitaciones()} */}
                <h5 className="mb-2 pt-2" style={{fontWeight:700}}>Datos del Vehículo:</h5>
                {mostrarVehiculo(ruta.vehiculo)}
                <hr className="pt-2"/>
            </Col>
            <Col md={12} >
                <h5 className="mb-2" style={{fontWeight:700}}>Zonas detectadas:</h5>
                <Row>
                    { zonas.map((com,iz) => {
                        return (
                            <Col xs={12} sm={6} md={4} lg={3} xl={2} key={`z-${iz}`}>
                                <div style={{ fontSize:12 }} className='mb-2 mx-0 px-1'>
                                    {com} <i className="fa-regular fa-square-full"></i>
                                </div>
                            </Col>
                        )
                    })} 
                </Row>
                <hr className="pt-2"/>
                {/* { externos.length > 0 ? mostrarInformacionFlujoCourriers(externos) : false } */}
            </Col>


                <Col md={12}>
                    {/* {mostrarEmpresas()} */}
                    <h5 className="mb-2" style={{fontWeight:700}}>Listado de {registros.length} Pedidos: </h5>
                    <Row>
                    {
                        registros.map(orden => {
                            return (
                                <Table striped bordered size="sm" className='mx-2'>
                                    <tbody>
                                        <tr key={orden._id}>
                                        <td className="text-center align-middle">
                                            <i className="fa-regular fa-square-full"></i>
                                        </td>
                                        <td>
                                            <BoxList showGeoData={false} pedido={orden} seleccionado={false} typeView="manifest" />
                                        </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            )
                        })
                    }       
                   </Row>
                </Col>
                </Row> 


            <EtiquetasModal
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
    </div>



}

export default EstaticoRuta;