import { Button, Card, Modal } from "react-bootstrap"
import { useState } from "react"
import RutasListado from "./listado"
import BuscadorRutas from "./buscador"
import RutasListadoV2 from "./list/list-v2"
import { IoDocumentTextSharp } from "react-icons/io5"

const ModalSelectorRutas = (props) => {
    const {
        typeView,
        title
    } = props
    const titulo = title ? title : "ASIGNAR MANIFIESTO"
    const [ showModal, setShowModal ] = useState(false)
    const [ manifiesto, setManifiesto ] = useState(null)

    const handleClose = () => {
        return setShowModal(false)
    }

    const handleConfirmation = () => {
        if(props.onConfirmation) props.onConfirmation(manifiesto)
        handleClose()
    }

    const onChangeRuta = (data) => {
        setManifiesto(data)
        setShowModal(false)
        if(props.onChange) props.onChange(data)
    }

    const mostrarComponente = () => {

        if(typeView === 'selector'){
            return <div>
             <BuscadorRutas  labelTitle="Selecciona un manifiesto" onChangeValue={(e) => onChangeRuta(e)} />
             { manifiesto ? <Button onClick={handleConfirmation} className="w-100 mt-3">CONFIRMAR</Button> : null }
            </div>
        }

        return <div>
            <RutasListadoV2 typeView="funcion" onSelect={(id) => onChangeRuta(id)} />
        </div>
    }

    return <div>          
    <Button variant="light" onClick={() => setShowModal(true)}><IoDocumentTextSharp /> {titulo}</Button>            
    <Modal show={showModal} size="lg" onHide={handleClose} centered >
        <Modal.Header closeButton>
        <Modal.Title><b>Manifiestos</b></Modal.Title>
        </Modal.Header>
        <Modal.Body className='m-0'>
            <p>Si no posees un manifiesto debes crearlo en la sección de Manifiestos</p>
            {mostrarComponente()}
        </Modal.Body>
    </Modal>
</div>
}

export default ModalSelectorRutas