import { useState } from "react"
import { Alert, Badge, Button, Card, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import data, { url_front_produccion } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import { module_, statuses_invitacion } from "./data";
import { FaUnlockAlt } from "react-icons/fa";
import { estilo_last_mile } from "../../lib/global/styles";
import AuditarConsumo from "./auditarConsumo";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const [ loadingRevoke, setLoadingRevoke ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()
    const requreidos = [
        { value: "name", label: "Título" },
        { value: "email", label: "Email" },
        { value: "matriz", label: "Matriz de permisos" },
    ]
    const matriz = [
        {
            name: "Conductores",
            slug: "conductores",
            actions: [
                { name: "Crear conductores", slug: "crear", limite: 0, tipo_limite: "total", helpText: "Si dejas en cero, no tendrá límite" },
            ]
        },
        {
            name: "Planificador",
            slug: "planificador",
            actions: [
                { name: "Ejecutar algoritmo de planificación de ruta", slug: "crear", limite: 0, tipo_limite: "mensual", helpText: "Si dejas en cero, no tendrá límite" },
            ]
        },
        {
            name: "Órdenes",
            slug: "ordenes",
            actions: [
                { name: "Crear órdenes", slug: "crear", limite: 0, tipo_limite: "mensual", helpText: "Si dejas en cero, no tendrá límite" },
            ]
        },
    ]

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        let faltantes = []
        requreidos.map(req => { if(!nuevoProveedor[req.value]) faltantes.push(req.label) })
        if(faltantes.length > 0) return toast.error(`Faltan campos requeridos: ${faltantes.join(', ')}`)
        
        if(nuevoProveedor.matriz?.filter(e => e.actions.filter(d => d.active === true).length > 0 ) < 1) return toast.error('Debes activar al menos un permiso')
        
        setLoading(true)
        return fetch(`${data.urlapi}/${module_}`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){                   
                    return toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){                   
                     toast.error(pros.errorMessage)
                     return setLoading(false)
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const revocar = async (id, pos_module, pos_action) => {
        setLoadingRevoke(true)
        return fetch(`${data.urlapi}/${module_}/revoke`, {
            method: 'PUT',
            body: JSON.stringify({
                id,
                pos_module, pos_action
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){                   
                toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){                   
                toast.error(pros.errorMessage)
              } else if(pros._id){
                setProveedor(pros)
              }
              return setLoadingRevoke(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoadingRevoke(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/${module_}`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        console.log("name", nuevoProveedor)
        return setNuevoProveedor(nuevoProveedor)
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nuevo</h4>
                    <p>Las cuentas de facturación sirven para disponibilizar recursos a otras cuentas, estos recuross serán utilizados para la operación de tu empresa y los costos por la activación y/o uso de estos, serán cargados a tu cuenta.</p>
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Título</label>
                    <input className="form-control" name="name" defaultValue={nuevoProveedor.name} onChange={handleChangeNuevo} />
                </Col>
                <Col className="mb-3">
                    <label className="form-control-label d-block">Notificar a email</label>
                    <input className="form-control" name="email" placeholder="Escribe un email para enviar el link" defaultValue={nuevoProveedor.email} onChange={handleChangeNuevo} />
                </Col>
                <Col md={12}>{mostrarMatrizPermisos(nuevoProveedor, setNuevoProveedor)}</Col>

                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/${module_}?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
                    toast.success("Realizado Exitosamente")
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const copiarTexto = (id) => {
        toast.info("Copiado")
        return navigator.clipboard.writeText(id)
    }

    const handleChangeChecked = (e, i, j, setState) => {
        return setState(prev => {
            let actual = {...prev}
            if(!actual.matriz) actual.matriz = matriz
            actual.matriz[i].actions[j].active = e.target.checked
            return actual
        })
    }

    const handleChangeCustom = (e, i, j, setState) => {
        const { name, value } = e.target

        return setState(prev => {
            let actual = {...prev}
            if(!actual.matriz) actual.matriz = matriz
            actual.matriz[i].actions[j][name] = value ? Math.max(parseInt(value), 0) : ''
            return { ...actual }
        })
    }

    const mostrarCreaciones = (slug, action, showResources, pos_module, pos_action) => {
        if(!showResources) return false
        if(slug === "conductores" && action === "crear") return <div>
            <Card className="p-2 mb-3">
            <label className="form-control-label d-block mb-0">Creados</label>
            <h2 className="mb-0">{proveedor.drivers}</h2>
            {proveedor.drivers > 0 ? <Button variant="outline-danger" size="sm" style={{ fontSize: 11}} disabled={loadingRevoke} onClick={() => revocar(proveedor._id, pos_module, pos_action)}>REVOCAR ACCESO Y DESACTIVAR MÓDULO</Button> : false }
            </Card>
        </div>
        
        if(slug === "planificador" && action === "crear") return <div>
            <Card className="p-2 mb-3">
            <label className="form-control-label d-block mb-0">Ejecutados este mes</label>
            <h2 className="mb-0">{proveedor.ruteos_creadas}</h2>
            <AuditarConsumo modulo={slug} action={action} account_id={proveedor._id} />
            </Card>
        </div>
        if(slug === "ordenes" && action === "crear") return <div>
            <Card className="p-2 mb-3">
            <label className="form-control-label d-block mb-0">Creadas este mes</label>
            <h2 className="mb-0">{proveedor.ordenes_creadas}</h2>
            <AuditarConsumo modulo={slug} action={action} account_id={proveedor._id} />
            </Card>
        </div>
    }
    
    const mostrarMatrizPermisos = (state, setState, showResources) => {
        let permisos = state.permisos ? state.permisos : []
        return <div>
            <h4 style={{ color: estilo_last_mile.color_primary }}><FaUnlockAlt size={estilo_last_mile.icon} size={19} /> Activa los permisos</h4>
            {
                matriz.map((item,i) => {

                    
                    return <div key={`te-${i}-${item.slug}`}>
                        <Card className="p-3 mb-3 shadow" >
                        <h5><b>{item.name}</b></h5>
                        {
                            item.actions.map((action,j) => {
                                const data = state.matriz ? state.matriz : [ ]
                                const item_valor = data.length > 0 ? data[i].actions[j].limite : ''
                                const default_cheked = data.length > 0 ? data[i].actions[j].active : false

                                return <div key={`${i}-${j}`}>
                                        <Form.Group key={action.slug} className="mb-3" controlId={`${action.slug}-${action.name}`}>
                                        <Form.Check type="switch" checked={default_cheked} permiso={action.slug} label={action.name} onChange={(e) => handleChangeChecked(e, i, j, setState)} />
                                        </Form.Group>
                                        {mostrarCreaciones(item.slug, action.slug, showResources, i, j)}
                                    <label className="form-control-label d-block">Límite <Badge variant="dark" >{action.tipo_limite}</Badge> { action.helpText ? <Badge variant="warning">{action.helpText}</Badge> : false }</label>
                                    <input className="form-control" value={item_valor} name="limite" type="number" onChange={(e) => handleChangeCustom(e, i, j, setState)} />
                                    
                                    </div>
                            })
                        }
                        </Card>
                        </div>
                })
            }
        </div>
    }


    const View = () => {
        if(!proveedor) return formularioNuevo()

        const string_copy = `${url_front_produccion}/invitation/account-share/${proveedor._id}`
        return <Row>
        <Col md={12} className="mb-3">
            <h4 className="mb-0">{proveedor.id} · {proveedor.name}</h4>
            {
                // removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
                            <Badge className="mb-0 mt-2" variant="dark" style={{ textTransform: "uppercase" }} >{ statuses_invitacion[proveedor.status]}</Badge>
            {
                !["accepted","rejected"].includes(proveedor.status) ? <OverlayTrigger placement="top" overlay={<Tooltip>Has click para copiar</Tooltip>}>
                    <h3 className='hover mt-2' style={{ padding: 10, fontSize: 12, fontWeight:'lighter', backgroundColor: '#e9e9e9', borderRadius: 7 }} onClick={() => copiarTexto(string_copy)} ><b style={{ fontWeight: "bold" }}>LINK PARA COMPARTIR</b><br/>{string_copy}</h3>
                </OverlayTrigger> : false
            }
        </Col>
        
        <Col className="mb-3">
            <label className="form-control-label mb-0 d-block">Email enviado a</label>
            <h5><b>{proveedor.email}</b></h5>
        </Col>
        <Col md={12}>{mostrarMatrizPermisos(proveedor, setProveedor, true)}</Col>
        {/* <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col> */}
    </Row>
}

    return <div>
        {View()}
    </div>
}

export default DetailFull