import { useEffect, useState } from 'react'
import { Row, Col, Button, Card, Spinner, Modal, Pagination, Form, Table, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../lib/helpers/dates';
import { esquema_productos } from '../../lib/esquemas';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import { cerrarSesion } from '../../redux/actions/session';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { AiOutlineDelete } from 'react-icons/ai';
import { AiFillPlusCircle } from "react-icons/ai";
import { Ir } from 'react-flags-select';

const ModalOrdenes = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema_productos)
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ procesados, setProcesados ] = useState(0)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ conductorSeleccionado, setConductorSeleccinoado ] = useState(false)
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ duplicados, setDuplicados] = useState([])
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ showModal, setShowModal ] = useState(false)
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const session = useSelector(state => state.miusuario)
    const [ omitirEstados, setOmitirEstados ] = useState(false)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const [ pedidos, setPedidos ] = useState([])
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    const trash = props.trash ? props.trash : false
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const token = session.tokenSession
    const [seleccionado, setSeleccionado ] = useState(false)
    const [loadingModal, setLoadingModal] = useState(false)
    const [loadingSeleccionados, setLoadingSeleccionados] = useState(false)
    const [seleccionados, setSeleccionados] = useState([])
    


    const obtenerProveedores = async (page, query)=>{
        setLoadingModal(true)
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log("RES:", res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setPedidos(res.datos)
                setTotal(res.total)
            }
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
    }

    useEffect(() => {
        obtenerProveedores(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitPalabra = (e) => {
       
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { $text: { $search: parametroBusqueda }}
        setCondicionBusqueda(condicion)
        setPagina(1)
        setLoadingModal(false)
        return obtenerProveedores(1, condicion)
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const handleClose = () => {
        return setShowModal(false)
    }

    const onClickOrden = (seleccionados) => {
        console.log("Ordenes:", seleccionados)
        setShowModal(false)
        if(props.onClickOrden) return props.onClickOrden(seleccionados)
        return false
    }

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }


    const anadirPedidos = (p, ir) => {
        if(setSeleccionado) setSeleccionado(false)
        setLoadingSeleccionados(true)
        const { pedido, _id } = p
        const existe = seleccionados.filter(p => p.pedido === pedido);
        console.log("existe", existe)
      console.log("seleccionados", seleccionados)
      if(existe.length > 0){
       return toast.error("Ya seleccionaste este pedido")
    } else {       
        setLoadingSeleccionados(false)       
            return setSeleccionados(prev => {
                let actual = prev
                actual.push({id_pedido: _id, pedido: pedido })
                return ([...actual])
            })
        }  
        
    }

    
    const eliminar = (ir) => {  
        return setSeleccionados(prev => {
            let actual = prev
            actual.splice(ir, 1)
            return ([...actual])
        })
    }

    
    const mostrarSeleccionados = () => {
        if(seleccionados.length < 1) return <div>
            <p style={{ fontSize: 14, fontWeight: 'bold', marginBottom: 10 }} className='text-danger'><i className="fa-solid fa-triangle-exclamation"></i> No has seleccionado ningun pedido</p>
        </div>       
        return <div>
             <h6 className='text-success'>Pedidos Seleccionados <b>{seleccionados.length}</b></h6> 
            {
             seleccionados.map((p, ir) => {                    
            return      <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 mb-3 hover ${seleccionado === true ? 'text-white' : 'text-dark'}`}>
                    <div >
                    <tr key={p._id} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <th>
                    <p style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 10 }} className='text-success'><i className="fa-solid fa-circle-check"></i> has Seleccionado el pedido <b>{p.pedido}</b></p>
                    </th>
                    <th>
                    <AiOutlineDelete style={{fontSize: 32, fontWeight: 'bold'}} size={18} onClick={()=>eliminar(ir)} />
                    </th>
                    </tr>
                    </div>
                </Card>
                  })
                }            
        </div>
       
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const paginacion = (ciclo, total, registros) => {

        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Row>
                <Col xs={12}><h5 className='m-0' style={{ fontSize:13 }}>{(pedidos.length * pagina) - pedidos.length + 1} - {pedidos.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5></Col>
                <Col xs={12}>
                <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
                </Col>
            </Row>
        </div>
    }


    const listadoRecolecciones = () => {
    if(loadingModal) return cargandoScreen()
    return <div>
            <Row>

            <Col md={6}>
                <h6><b>*Total: {total}</b>, se muestran <b>{pedidos.length}</b> de la página <b>{pagina}</b>.</h6>
                {paginacion(data.pagina, total, registros)}
                 {    
            pedidos.map((p, ir) => {                    
                return  <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 mb-3 hover ${seleccionado === true ? 'text-white' : 'text-dark'}`} >
                    <div >
                    <tr key={p._id} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <th>
                    <h6 style={{ fontSize: 17 }} className="mb-1">Pedido # <b>{p.pedido}</b></h6>
                    </th>
                    <th>
                    <AiFillPlusCircle style={{fontSize: 24, marginBottom: 2}} onClick={() => anadirPedidos(p, ir)}/>
                    </th>
                    </tr>
                    </div>
                    </Card>
                    })
                    }
                     {paginacion(data.pagina, total, registros)}
            </Col>
            <Col md={6}>                                    
            {mostrarSeleccionados()}                  
            </Col>
            </Row>
            </div>
    }


    const mostrarRecolecciones = () => {
        return <div>          
            <Button size='sm' md={12} variant='light' onClick={() => setShowModal(true)}> <BiSolidChevronRight size={20} />  <b> VER PEDIDOS  </b></Button>            
            <Modal show={showModal} size="lg" onHide={handleClose} centered >
                <Modal.Header closeButton>
                <Modal.Title><b>Pedidos</b></Modal.Title>
                </Modal.Header>
                <Modal.Body className='m-0'>
                <Col md={12}>
                        <form onSubmit={handleSubmitPalabra}>
                        <input className='mb-3 form-control' placeholder='BUSCAR' onChange={handleChangePalabra} />
                        </form>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button size='sm mb-3' md={12} variant='light' onClick={() => onClickOrden(seleccionados)}> <BiSolidChevronRight size={20} />  <b> Confirmar seleccionados  </b></Button>
                        </div>
                    </Col>                  
                    {listadoRecolecciones()}
                </Modal.Body>
            </Modal>
        </div>
    }


    return <div>
        {mostrarRecolecciones()}
        </div>

}

export default ModalOrdenes