import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { procesarDatosGraficosEstadisticaConductorLastMile, procesarOTIFParaGraficos } from "../../../lib/helpers/conductores"
import { Spinner } from "react-bootstrap"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { formatMes } from "../../../lib/helpers/opcionesfecha"
import GraficoOtiff from "../../estadisticas/grafico_otif"
import { cerrarSesion } from "../../../redux/actions/session"
import { url_images } from "../../../lib/global/data"
import { estilo_last_mile } from "../../../lib/global/styles"
import LoadingReports from "../../general/loadingAnimations/loadingReports"

const GraficoVehiculo = (props) => {
    const {
        condicion_default,
        tipoVistaOtif,
        propietario,
        typeView
    } = props
    const tipo_vista_otif = typeof tipoVistaOtif !== "undefined" ? tipoVistaOtif : 'small'
    const [ loadingOrdenes, setLoadingOrdenes ] = useState(true)
    const [ datosGraficosHistoricoV2, setDatosGraficosHistoricosV2 ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ vehiculosSeleccionados, setVehiculosSeleccionados] = useState([])
    const [ otif, setOtif ] = useState(false)
    const [ mes, setMes] = useState(formatMes(new Date()))
    const [ pedidos, setPedidos ] = useState([])
    const token = session.tokenSession
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerOrdenes(vehiculosSeleccionados, mes)
    }, [ condicion_default ])

    const obtenerOrdenes = async (drivers, month) => {
        setLoadingOrdenes(true)
        return fetch(`${urlapi}/estadisticas/ordenes-avanzada`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_default,
                propietario,
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingOrdenes(false)

            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingOrdenes(false)

            } else if(Array.isArray(res) !== false){
                setPedidos(res)
                const graphs_v2 = procesarDatosGraficosEstadisticaConductorLastMile(res)
                const otif_mes = procesarOTIFParaGraficos(graphs_v2)
                setOtif(otif_mes)
                setDatosGraficosHistoricosV2(graphs_v2)
            }
            return setLoadingOrdenes(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingOrdenes(false)
        })
    }

    if(loadingOrdenes===true) return <LoadingReports />

    let puntos = []

            if(pedidos){
                if(Array.isArray(pedidos) !== false){
                    for( const pedido of pedidos ){
                        if(pedido.location){
                            if(pedido.location.coordinates){
                                if(Array.isArray(pedido.location.coordinates) !== false){
                                    if(pedido.location.coordinates.length > 1){
                                        puntos.push({
                                            lat: pedido.location.coordinates[1],
                                            lng: pedido.location.coordinates[0],
                                            detalles: pedido
                                        })
                                    }
                                }
                            }
                        }
                    }
                }
            }

    if(props.typeView==='barras') return <div>
        <GraficoOtiff typeView="barras" data={otif} />
    </div>

    return <div>
            <GraficoOtiff typeView={tipo_vista_otif} data={otif} />
    </div>
}

export default GraficoVehiculo