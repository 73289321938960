import { useEffect, useState } from "react"
import { Alert, Badge, Button, Card, Col, Form, Modal, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap"
import data, { url_front_produccion } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import { module_, statuses_invitacion } from "./data";
import { FaUnlockAlt } from "react-icons/fa";
import { estilo_last_mile } from "../../lib/global/styles";
import { DateTime } from "luxon";
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { StringMes } from "../../lib/helpers/dates";
import { BarChart } from "@mui/x-charts";
import { ThemeProvider, createTheme } from "@mui/material";
const theme = createTheme({ })

const AuditarConsumoAno = (props) => {
    const {
        modulo,
        action,
        account_id
    } = props
    const [ loading, setLoading] = useState(false)
    const [ operacion, setOperacion ] = useState([])
    const [ showModal, setShowModal ] = useState(false)
    const [ ano, setAno ] = useState(DateTime.now().year)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    
    const consultar = async () => {
        if(!ano) return toast.error("Debes seleccionar un año")
        setLoading(true)
        return fetch(`${data.urlapi}/${module_}/audit-year`, {
            method: 'POST',
            body: JSON.stringify({
                modulo,
                action,
                account_id,
                ano
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){                   
                    return toast.error('Hubo un error de servidor')
              } else if(pros.errorMessage){                   
                     toast.error(pros.errorMessage)
                     return setLoading(false)
              } else if(Array.isArray(pros.informacion)){
                setOperacion(pros.informacion)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    useEffect(() => {
        consultar()
    }, [])

    const mostrarDatos = () => {
        if(loading) return <div className="mt-3"><Spinner animation="border" variant="primary" /></div>
        const chartSetting = {
            yAxis: [
              {
                label: 'Cantidad de operaciones',
              },
            ],
            width: document.getElementById('item-grafico') ? document.getElementById('item-grafico').offsetWidth : 700,
            height: 300,
            sx: {
              [`.${axisClasses.left} .${axisClasses.label}`]: {
                transform: 'translate(-20px, 0)',
              },
            },
          };
          
        return <div className="mt-3">
            <Card className="shadow p-3" >
            <ThemeProvider theme={theme}>
                                    <BarChart
                                        dataset={operacion.map(e => ({
                                            month: StringMes(e.month),
                                            operaciones: e.total_operaciones
                                        }))}
                                        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                                        series={[
                                          { dataKey: 'operaciones', label: 'Operaciones' },
                                        ]}
                                        {...chartSetting}
                                        />
                                    </ThemeProvider>  
            </Card>
        </div>
    }

    const abrirModal = () => {
        consultar()
        setShowModal(true)
    }

    const View = () => {
        return <div>
            <h4><b>Auditar por año</b></h4>
            <Row>
                <Col>
                <input className="form-control" type="number" value={ano} onChange={(e) => setAno(e.target.value)} />
                </Col>
                <Col><Button className="w-100" variant="light" onClick={() => consultar()} disabled={loading}>CONSULTAR CONSUMO AÑO</Button> </Col>
            </Row>
            {mostrarDatos()}

        </div>
        
}

    return <div className="w-100" id="item-grafico">
        {View()}
    </div>
}

export default AuditarConsumoAno