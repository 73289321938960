import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import Cargando from "./cargando"
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { formatoMoneda } from "../../../lib/helpers/main"
import { cerrarSesion } from "../../../redux/actions/session"

const Filtros = (props) => {
    const [ filtros, setFiltros ] = useState([])
    const [ loading, setLoading ] = useState(true)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    const obtenerDatos = async () => {
        setLoading(true)
        return fetch(`${urlapi}/ordenes/filtros`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setFiltros(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const filtrar = (filtro) => {
        if(filtro.condicion) if(typeof filtro.condicion === "object") if(props.onFilter) props.onFilter(filtro.condicion, filtro.tipo_condicion)
    }

    const mostrarDatos = () => {
        if(loading) return <Cargando />

        return <div className="mb-3">
            <Row>
            {
                filtros.map((filtro,i) => {
                    return <Col key={`col-${i}`} className="d-flex align-items-stretch">
                        <OverlayTrigger
                            placement={'bottom'}
                            overlay={
                              <Tooltip>{filtro.condicion ? "Toca para filtrar" : "Filtro no disponible"}</Tooltip>
                            }
                            >
                        <Card className="p-2 w-100 hover" onClick={() => filtrar(filtro)}>
                            <h3 className="mb-0">{formatoMoneda(filtro.cantidad)}</h3>
                            <p className="mb-0" style={{ fontSize: 11 }}>{filtro.titulo}</p>
                        </Card>
                        </OverlayTrigger>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    return <div>
        {mostrarDatos()}
    </div>

}
export default Filtros