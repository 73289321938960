import { useEffect, useRef, useState } from "react"
import { Button, Col, Modal, Row } from "react-bootstrap"
import Select from "react-select"
import { toast } from 'react-toastify';
import data from '../../../lib/backend/data'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../../redux/actions/session";

const SelectorBodegaSimple = (props) => {
    const {
        defaultValue
    } = props
    const [ openModal, setOpenModal ] = useState(false)
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ seleccionProveedores, setSeleccionProveedores ] = useState([])
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()
    const selectRef = useRef(null)


    const obtenerProveedores = async (condicion)=>{
        setLoadingProveedores(true)
        return fetch(`${data.urlapi}/bodega/list-full`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if(Array.isArray(res) !== false){
                let items = res.map(p => ({ value: p._id, label: p.titulo }))
                setProveedores(items)
                setTimeout(() => {
                    if(defaultValue){
                        if(selectRef.current){
                            if(Array.isArray(defaultValue)){
                                const default_values = items.filter(e => defaultValue.includes(e.value))
                                selectRef.current?.setValue(default_values)
                            } else {
                                const pos = items.findIndex(e => e.value === defaultValue)
                                if(pos > -1){
                                    const value_search = items[pos]
                                    selectRef.current?.setValue(value_search)
                                }
                            }

                        }
                    }
                }, 100);
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }

    useEffect(()=>{
        obtenerProveedores({})
    },[])

    

    const handleChangeProveedor = (e) => {
        if(Array.isArray(e)){
            let opciones = []
            if(e){
                e.map(valor => {
                    opciones.push(valor.value)
                })
            }
            return setSeleccionProveedores(opciones)
        } else {
            if(props.onChange) props.onChange(e)
        }
    }

    return <div>
                    <Select
                                    ref={selectRef}
                                    isLoading={loadingProveedores} 
                                    loadingMessage={()=>'Cargando información'}
                                    placeholder="Selecciona una opción"
                                    noOptionsMessage={()=>'Sin opciones'}
                                    onChange={handleChangeProveedor}
                                    isClearable
                                    options={proveedores} 
                                />
    </div>
}

export default SelectorBodegaSimple