import { useEffect, useState } from "react"
import { maps_key, urlapi } from "../../lib/backend/data"
import { toast } from "react-toastify"
import { useParams } from 'react-router-dom'
import { Button, Card, Col, Row, Spinner } from "react-bootstrap"
import TrackingPage from "../Tracking/tracking_public"
import { useDispatch } from "react-redux"
import { cerrarSesion } from "../../redux/actions/session"

const Widget = (props) => {
    const [ loadingWidget, setLoadingWidget ] = useState(true)
    const [ loadingSearch, setLoadingSearch ] = useState(false)
    const [ orden, setOrden ] = useState(false)
    const [ markers, setMarkers ] = useState([])
    const [ value, setValue ] = useState(false)
    const [ widget, setWidget ] = useState(false)
    const [ markerToShow, setMarkerToShow ] = useState(false)
    const [ lineaTiempo, setLineaTiempo ] = useState([])

    const route = useParams()
    const dispatch = useDispatch()

    const buscarRegistro = () => {
        if(!value) return toast.error("Escribe un valor de búsqueda")
        setLoadingSearch(true)
        return fetch(`${urlapi}/integraciones/widget/search`,{
            method:'POST',
            body: JSON.stringify({
                id_widget: widget._id,
                value
            }),
            headers: {
                'Content-Type':'application/json',
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.orden){
                setOrden(res)
                if(Array.isArray(res?.orden?.location?.coordinates)) if(res?.orden?.location?.coordinates.length > 1) setMarkers([{ lat: res?.orden?.location?.coordinates[1], lng: res?.orden?.location?.coordinates[0] }])
                if(res.orden?.linea_tiempo) setLineaTiempo(res.orden?.linea_tiempo)
            }
            return setLoadingSearch(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingSearch(false)
        })
    }

    const obtenerDatos = () => {
        const { id } = route
        return fetch(`${urlapi}/integraciones/widget?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setWidget(res)
            }
            return setLoadingWidget(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingWidget(false)
        })
    }

    useEffect(() => {
        obtenerDatos()
    }, [])

    const handleChangeValue = (e) => {
        const { value } = e.target
        return setValue(value)
    }


    const mostrarOrden = () => {
        if(!orden || loadingSearch) return false 

        return <div>
            {mostrarLineaTiempo()}
            <TrackingPage orden={orden} interfaz={orden?.configuracion_interfaz} markers={markers} />
            </div>
        return <div>
            { /** <MapTrackingPage 
           zoom={zoom}
           centro={centroMapa}
           googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing&key=${maps_key}`}
            markers={this.state.coordinates_history} 
            showMarkerInfoWindow={markerToShow}
            markerToShow={(mark) => setMarkerToShow(mark)}
            isMarkerShown
            loadingElement={<div style={{ height: `100%` }} >
                        <div className="loadingbox">
                    <img alt="loading"  src="img/cargando.gif" />
                        <h3>Cargando mapa...</h3>
                    </div>
                    </div> }
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    className="mt-3"
                    
    /> **/}
        </div>
    }

    const mostrarLineaTiempo = () => {
        if(!lineaTiempo) return false
        if(!lineaTiempo.hitos) return false
      

        return <div className='mb-3 text-center'>
            <Card className="mt-3 mb-3 p-3">
            <Row>
            {
                lineaTiempo.hitos.map((evento,i) => {
                  const emitidos = lineaTiempo.hitos.filter((hito,pos) => hito.choosen === true && pos > i).length > 0
                    // if(evento.opcional_linea_tiempo === true && !evento.emitido) return false
                    return <Col key={evento._id}>
                        {evento.choosen === true || emitidos ? <i className="fa-solid fa-circle-check text-success" style={{fontSize:20}}></i> : <i className="fa-solid fa-clock text-secondary" style={{fontSize:20}}></i>}
                        <h6 className='mb-0' style={{fontWeight:900}}>{evento.titulo}</h6>
                    </Col>
                })
            }
            </Row>
            </Card>
            </div>
      }


    const mostarrDatos = () => {
        if(loadingWidget) return <div className="loadingbox text-center mb-3">
        <Spinner animation='border' />
        <h4>Cargando...</h4>
        </div>

        return <div>
            <Card className="p-3">
                { widget.logo ? <img style={{ width: 250, margin: "0 auto" }} src={widget.logo} /> : false }
                <h3 className="mb-0">{widget.titulo}</h3>
                { widget.descripcion ? <p className="mb-0">{widget.descripcion}</p> : false }
            <Row>
                <Col md={8} xs={6}>
                    <input style={{ height: "47px !important" }} className="form-control" onChange={handleChangeValue} placeholder={widget.ayuda} />
                </Col>
                <Col md={4} xs={6}>
                    { loadingSearch ? <Spinner animation="border" />  : <Button className="w-100" size="lg" style={{ background: widget.color_boton ? widget.color_boton : "black", border: "none" }} onClick={() => buscarRegistro()}>BUSCAR</Button> }
                </Col>
            </Row>
            </Card>
            {mostrarOrden()}
        </div>
    }
    return <div style={{ padding: 20 }}>
        {mostarrDatos()}
    </div>
}

export default Widget