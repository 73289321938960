import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Spinner, Card, Form } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import BoxList from './box-list';
import CargandoDetalleFull from './cargando_detalle_full';
import DetailFull from './detalle-full';
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import { Link } from 'react-router-dom';
import { addMoreDays, formatDateHoy, obtenerFechaHoraZonaHorariaLocal, obtenerRangos } from '../../lib/helpers/dates';
import Joyride from 'react-joyride';
import { virtual_tour_settings } from '../../lib/global/data';
import BuscadorVehiculos from '../conductores/buscador';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../lib/helpers/router';
import { cerrarSesion } from '../../redux/actions/session';

const OrdenesListado = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const {
        force
    } = props
    const [ equipos, setEquipos ] = useState([])
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const [ patente, setPatente ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ pedidoSeleccionado, setPedidoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ pedidoBusqueda, setPedidoBusqueda ] = useState('')
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const [ estados, setEstados ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const [ incluyeEstadoExcel, setIncluyeEstadoExcel ] = useState(false)
    const [ conductor, setConductor ] = useState(false)
    const showFilter = typeof props.showFilter !== "undefined" ? props.showFilter : true
    const showSearch = typeof props.showSearch !== "undefined" ? props.showSearch : true
    const showExporter = typeof props.showExporter !== "undefined" ? props.showExporter : true
    const showAddButton = typeof props.showAddButton !== "undefined" ? props.showAddButton : true
    const upWhenClick = typeof props.upWhenClick !== "undefined" ? props.upWhenClick : true
    const titulo = typeof props.titulo !== "undefined" ? props.titulo : "Órdenes"
    const [ openedFirst, setOpenedFirst ] = useState(false)
    let query = useQuery();

    const obtenerOrdenes = async (page, query)=>{
        setLoadingEquipos(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page,
                force
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                if(res.datos.length > 0) if(!openedFirst){
                    setOpenedFirst(true)
                    obtenerPedido(res.datos[0]._id)
                }
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    const obtenerPedido = async (id) => {
        setLoadingDetalle(true)
        if(upWhenClick === true) window.scrollTo({top: 0, behavior: 'smooth'})
        setIdSeleccionado(id)
        return fetch(`${data.urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
                setPedidoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerOrdenes(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const handleChangeFechaBusqueda = (e) => {
        const { value } = e.target
        return setTipoFechaBusqueda(value)
    }

    const filtrar = () => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        
        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: hasta }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: hasta }, status: 'active' }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'maxima-entrega'){
            const condicion = { $or:[
                { fecha_min_entrega: { $gte: desde, $lte: hasta } },
                { fecha_max_entrega: { $lte: hasta, $gte: desde } },
                { fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde } },
            ]
            }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'todos'){
            const condicion = {  }
            if(filtroEstado.length > 0) condicion["estado_entrega.codigo_estado"] = filtroEstado
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion)
            return setOpenModal(false)
        }
    }

    const handleCheck = (e) => {
        const valor = e.target.checked
        return setIncluyeEstadoExcel(valor)
    }
    
    const onChangeConductor = (e) => {
        setConductor(e)
    }

    const modalFiltro = () => {
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control' value={tipoFechaBusqueda} onChange={handleChangeFechaBusqueda} >
                        <option value="creacion">Creación</option>
                        <option value="actualizacion">Actualización</option>
                        <option value="maxima-entrega">Máxima fecha entrega</option>
                        <option value="todos">Cualquiera</option>
                    </select>
                </Col>
                <Col xs={12} className="mb-3">
                    <label className='d-block form-control-label'>Estado de la carga</label>
                    <Select 
                        style={{ marginBottom: 10 }}
                        onChange={handleChangeSelectEstado}
                        isLoading={loadingEstados}
                        isMulti={true}
                        options={estados}
                        defaultValue={''}
                        placeholder="Filtrar estado..."
                        noOptionsMessage={()=>'Sin opciones'}
                        />
                </Col>
                {
                    tipoFechaBusqueda !== "todos" ? <Col xs={12} className="mb-2">
                    <DateRangePicker
                        locale={es}
                        showSelectionPreview={true}
                        editableDateInputs={true}
                        onChange={item => cambiarRangoFecha(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        direction="vertical"
                        scroll={{ enabled: true }}
                        months={1}
                    />
                    </Col> : false
                }
                <Col xs={12} className="mb-3">
                    <BuscadorVehiculos isMulti={true} token={token} onChange={(data) => onChangeConductor(data)} />
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success" onClick={()=>filtrar()} >ACTUALIZAR DATOS</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    const obtenerEstados = () => {
        return fetch(`${data.urlapi}/estadoscarga/details`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){

            } else if(data.errorMessage){
                toast.error(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data.estados) !== false){
                setEstados(data.estados.map(e => ({ value: e.codigo_estado, label: e.titulo })))
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    useEffect(() => {
        obtenerOrdenes(1, false)
        obtenerEstados()
        const id_pedido = query.get("id")
        if(id_pedido){
            obtenerPedido(id_pedido)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const descargarReporteExcel = async () => {
        setLoadingEquipos(true)
        return fetch(`${data.urlapi}/reporte/ordenes`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda,
                incluir_estados: incluyeEstadoExcel
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingEquipos(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    const tabla_registros = (registros) => {
        if(loadingEquipos===true) return cargandoScreen()

        return <div>
            { /** <Joyride styles={{options:virtual_tour_settings.estilo}} continuous={true} showProgress={true} steps={virtual_tour_settings.pasos_ordenes} locale={virtual_tour_settings.locale} /> */ }
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            {
                showExporter === true ? <Card className='p-2 mb-3 delpa-reporte-excel'>
                <h6><i className="fa-solid fa-file-excel"></i> Reporte Excel</h6>
                    <Form.Group className="mb-1" controlId={`incluyeEstadoExcel`}>
                            <Form.Check type="switch" defaultChecked={incluyeEstadoExcel} label="Incluir estados en excel" onChange={handleCheck} />
                        </Form.Group>
                    <Button size="sm" variant='outline-success' className='mb-3' onClick={() => descargarReporteExcel()} >DESCARGAR</Button>

                </Card> : false
            }
            
            <div className='delpa-ordenes'>
            {
                        registros.length > 0 ? registros.map(orden => {
                            let seleccionado = false
                            if(idSeleccionado === orden._id) seleccionado = true
                            return <div key={orden._id} className="mb-3">
                                <BoxList pedido={orden} seleccionado={seleccionado} onClickPedido={(id) => obtenerPedido(id)} />
                            </div>
                        }) : <div>
                                <h4>Nada por aquí</h4>
                                <p>No hay registros en esta sección</p>
                            </div>
            }
            </div>
        </div>
    }

    const restaurarRegistro = async (id,status) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recursos/equipos/status?id=${id}&status=${status}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/equipos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }
    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/recursos/equipos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                const i = equipos.findIndex(e => e._id === id)
                equipos.splice(i,1)
                setEquipos(prev => equipos)
                setTimeout(() => {
                    window.location = '/equipos'
                }, 200);
            }
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const handleSubmitReferencia = (e) => {
        e.preventDefault()
        if(!pedidoBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerOrdenes(1, condicion)
        }
        const condicion = { pedido: pedidoBusqueda.toLowerCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const handleSubmitNumeroInterno = (e) => {
        e.preventDefault()
        if(!numeroInterno) return toast.error('Selecciona una patente')
        const condicion = { numero_interno: numeroInterno.toUpperCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerOrdenes(1, {})
    }

    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleChangePedido = (e) => {
        const { value } = e.target
        return setPedidoBusqueda(value)
    }

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {
        const i = equipos.findIndex(pe => pe._id === pedidoSeleccionado.orden._id)
        console.log({i,equipos})
        if(i < 0) return 
        equipos[i].estado_entrega = estado_entrega
        equipos[i].sub_estado_entrega = sub_estado_entrega
        return setEquipos(prev => [...[], ...equipos])
    }
 
    return <div>
        {modalFiltro()}
        <Row>
            <Col md={3} style={{height: '100vh', overflowY: 'auto'}}>
            <h4>{titulo} { showAddButton === true ? <Link to="/ordenes/nuevo" className='delpa-nueva-orden'><i className="fa-solid fa-circle-plus"></i></Link> : false } </h4>
                { showSearch === true ? <Row>
                    <Col md={8}>
                        <form onSubmit={handleSubmitReferencia}>
                        <input className='mb-3 form-control delpa-buscador' placeholder='BUSCAR POR REFERENCIA' onChange={handleChangePedido} />
                        </form>
                    </Col>
                    { showFilter === true ? <Col md={4} className="text-right"><Button  variant="outline-primary delpa-filtro" onClick={()=>abrirFiltro()}>FILTRAR</Button> </Col> : false }
                </Row> : false}
                {tabla_registros(equipos)}
            </Col>
            <Col md={9} style={{height: '100vh', overflowY: 'auto'}}>
                <Card className='p-3'>
                    {
                        loadingDetalle === true ? <CargandoDetalleFull /> : <DetailFull actualizarEstadoListado={(estado_entrega, sub_estado_entrega) => actualizarEstadoListado(estado_entrega, sub_estado_entrega)} token={token} pedido={pedidoSeleccionado} />
                    }
                </Card>
            </Col>
        </Row>
    </div>

}

export default OrdenesListado