import { toast } from "react-toastify"
import React from 'react'
import { StringByOptionValue, StringByRuleStatus, StringByOptionComparisionOperator } from "../../lib/helpers/logistica"
import { statusColor, statusString } from "../../lib/helpers/logistica"
import data, { url_front_produccion, urlapi } from "../../lib/backend/data"
import { useSelector } from "react-redux"
import { Accordion, Badge, Button, Card, Col, Nav, Navbar, OverlayTrigger, Pagination, Row, Spinner, Tooltip } from "react-bootstrap"
import { fechaATexto } from "../../lib/helpers/helpers"
import { stringByStatusRule } from "./helper"
import SelectorEstadosCentinela from "./selector_estatus"
import { url_images } from "../../lib/global/data"
import { estilo_last_mile } from "../../lib/global/styles"
import { Link } from "react-router-dom"
import { rutas } from "../../lib/routes/routes"
import { IoMdNotificationsOutline } from "react-icons/io"
import { TbPresentationAnalytics } from "react-icons/tb"
import { MdOutlineModeEditOutline } from "react-icons/md"
import { AiOutlineAudit } from "react-icons/ai"
import NavigationCentinela from "./navigation"
import SelectorRangoFechas from "../general/selector_rango_fechas"
import { DateTime } from "luxon"
import { obtenerRangos } from "../../lib/helpers/opcionesfecha"
import SelectorRegla from "../reglas-negocio/selector-regla"
const { useEffect, useState } = require("react")

const CentinelaListado = (props) => {
    const {
        rango_fechas,
        propietario
    } = props
    const initialDate = {
        startDate: DateTime.now().startOf("day").minus({ weeks: 4 }).toJSDate(),
        endDate: DateTime.now().toJSDate(),
        key: 'selection',
    }
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ datos, setDatos ] = useState([])
    const [ total, setTotal ] = useState(0)
    const [ filtroBusqueda, setFiltroBusqueda ] = useState({})
    const [ pagina, setPagina ] = useState(1)
    const [ loading, setLoadingDatos ] = useState(true)
    const [ loadingDatos, setLoading ] = useState(true)
    const [ showTable, setShowTable ] = useState(false)
    const [ loadingGraficos, setLoadingGraficos ] = useState(false)
    const [ visualizationStatus, setVisualization ] = useState('')
    const session = useSelector(state => state.miusuario)
    const iconSize = 30

    const getDatosGraficos = async (query) => {
        const cond = query ? query : filtroBusqueda
        setLoadingGraficos(true)
          return fetch(`${urlapi}/ordenes/reglas/listado-graficos`, {
              method: 'POST',
              body: JSON.stringify({
                condicion: cond
              }),
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer: ${session.tokenSession}`
              }
            })
            .then(pros => pros.json())
            .then(pros => {
                if(!pros){
                    toast.error("Sin datos del servidor para gráficos")
                } else if(pros.errorMessage){
                    toast.error(pros.errorMessage)
                } else if(Array.isArray(pros.datos) !== false){
                    setDatos(pros.datos)
                    if(pros.datos.length > 0) setShowTable(true)
                    setTotal(pros.total)
                }
                return setLoadingGraficos(false)
            })
            .catch(error => {
                setLoadingGraficos(false)
                return toast.error("No pudimos obtener la información completa")
            })
    }

    const getDatos = async (page, query) => {
        const cond = query ? query : filtroBusqueda
        // getDatosGraficos(cond)
        setLoading(true)
          return fetch(`${urlapi}/ordenes/reglas/listado`, {
              method: 'POST',
              body: JSON.stringify({
                condicion: cond,
                pagina: page,
              }),
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer: ${session.tokenSession}`
              }
            })
            .then(pros => pros.json())
            .then(pros => {
                if(!pros){
                    toast.error("Sin datos del servidor para gráficos")
                } else if(pros.errorMessage){
                    toast.error(pros.errorMessage)
                } else if(Array.isArray(pros.datos) !== false){
                    setDatos(pros.datos)
                    if(pros.datos.length > 0) setShowTable(true)
                    setTotal(pros.total)
                }
                setLoading(false)
                return setLoadingDatos(false)
            })
            .catch(error => {
                setLoading(false)
                setLoadingDatos(false)
                return toast.error("No pudimos obtener la información completa")
            })
    }

    useEffect(() => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        filtrar('createdAt', { $gte: desde, $lte: hasta })
    }, [ ])

    const mostrarSubEstadoHTML = (sub_estado_entrega) => {
        if(sub_estado_entrega){
          if(typeof sub_estado_entrega === 'object'){
            if(sub_estado_entrega.titulo){
              return <span className="spanstatus mr-2" style={{ display: "inline-block", fontSize: 10, padding: "3px 5px", backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2', color: "#e0e0e0", textTransform: "uppercase" }}>{sub_estado_entrega.titulo}</span>
            }
          }
        }
        return false
    }

    const shoyEstadoEntregaHTML = (estadoentrega, estado_entrega, sub_estado_entrega) => {
        if(estado_entrega){
          if(typeof estado_entrega === 'object'){
            if(estado_entrega.titulo){
              return <div className="mb-2">
                <span className="spanstatus mr-2 " style={{ display: "inline-block", fontSize: 10, backgroundColor: estado_entrega.color ? estado_entrega.color : '#babbbb', padding: "3px 5px", color: "#e0e0e0", textTransform: "uppercase" }}>{estado_entrega.titulo}</span>
                {mostrarSubEstadoHTML(sub_estado_entrega)}
              </div>
            }
          }
        }
        return <span className="spanstatus" >SIN DATOS</span>
    }

    const MostrarReglas = (props) => {
        const {
            reglas,
            i
        } = props
        const pos = i
        if(!reglas) return false
        if(Array.isArray(reglas) !== true) return false

        const llave = `estado-${pos}`
        return <div className="">
                <Accordion className="shadow-sm">
                <Card className="p-0" key={`aafsaf`} >
                    <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor: 'white' }} eventKey={llave}>
                        <div className="pl-3 pr-3 pb-0 hover text-center">
                            <p className='mb-0' style={{fontSize:12, fontWeight:700}}>VER {reglas.length} REGLAS</p>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={llave}>
                        <Card.Body style={{ backgroundColor: '#f3f3f3' }}>
                            {
                                reglas.map((rule,ir) => {
                                    return <div key={`reg-${ir}`}>
                                        <Card className="p-3 mb-3">
                                        { rule.required === true ? <Badge variant="light"><i className="fa-solid fa-triangle-exclamation text-danger"></i> ESTA REGLA DEBE CUMPLIRSE</Badge> : false }
                                        <p className="mb-0" style={{}}><b style={{ fontWeight: 700, fontSize: 12 }}>ESTADO:</b> <Badge variant="dark">{stringByStatusRule(rule.status)}</Badge></p>
                                        <p className="mb-0" style={{fontSize:11}}><b style={{ fontWeight: 700, fontSize: 12 }}>DESDE:</b> {StringByOptionValue(rule.start_value)}: {rule.start_option.join(', ')}</p>
                                        <p className="mb-0" style={{fontSize:11}}><b style={{ fontWeight: 700, fontSize: 12 }}>HASTA:</b> {StringByOptionValue(rule.end_value)}: {rule.end_option.join(', ')}</p>
                                        <p className="mb-0" style={{fontSize:11}}><b style={{ fontWeight: 700, fontSize: 12 }}>DÍAS TRANSCURRIDOS:</b> {StringByOptionComparisionOperator(rule.compare)} {rule.value}</p>
                                        </Card>
                                    </div>
                                })
                            }
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
                </Accordion>
        </div>
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        getDatos(page)
    }

    const paginacion = (ciclo, total, registros) => {

        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Row>
                <Col xs={12}><h5 className='m-0' style={{ fontSize:13 }}>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5></Col>
                <Col xs={12}>
                <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
                </Col>
            </Row>
        </div>
    }

    const filtrar = (key, value) => {
        return setFiltroBusqueda(prev => {
            let actual = {...prev}
            if(Array.isArray(value)){
                if(value.length < 1){
                    delete actual[key]
                } else {
                    actual[key] = { $in: value }
                }
            } else {
                if(!value){
                    delete actual[key]
                } else {
                    actual[key] = value
                }
            }
            getDatos(1, actual)
            setPagina(1)
            return actual
        })
    } 

    const cambiarRangoFecha = (item) => {
        console.log(item.selection)
        setSelectionRange(item.selection)
        const { desde, hasta } = obtenerRangos(item.selection.startDate, item.selection.endDate)
        return filtrar('createdAt', { $gte: desde, $lte: hasta })
    }

    const filtros = () => {
        return <div>
            <Row>
                <Col md={6}>
                    <SelectorRangoFechas default_range_dates={selectionRange} onChange={item => cambiarRangoFecha(item)} />
                </Col>
                <Col md={3}>
                    <SelectorEstadosCentinela disabled={loadingDatos} isMulti={true} onChange={(data) => filtrar('status', data)} />
                </Col>
                <Col md={3} className='d-block justify-content-center'>
                    <SelectorRegla disabled={loadingDatos} isMulti={true} onChange={(data) => filtrar('titulo', data)}/>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col md={12}>
                    <Button className='mt-3 w-100 shadow-sm' disabled={loadingDatos} size="sm" variant="primary" onClick={() => getDatos(1)}>{loadingDatos ? <div><Spinner size="sm" animation="border" /> CARGANDO...</div> : <b><i className="fa-solid fa-rotate-right"></i>  ACTUALIZAR</b>}</Button>
                </Col>
            </Row>
        </div>
    }

    const mostrarDatos = () => {

        if(datos.length < 1 && !showTable) return <div>
            <div className='p-5 bg-white text-primary'>
                <Row className="justify-content-md-center">
                    <Col md={6} className="text-center">
                        <h4 className="mb-0">Presentamos</h4>
                        <h1><i className="fa-solid fa-eye"></i> <b>Centinela</b></h1>
                        <Row className="justify-content-md-center">
                            <Col md={3}>
                                <IoMdNotificationsOutline size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Notificaciones email</h5>
                            </Col>
                            <Col md={3}>
                                <TbPresentationAnalytics size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Dashboard Analítico</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineModeEditOutline size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Reglas de negocio a medida</h5>
                            </Col>
                            <Col md={3}>
                                <AiOutlineAudit size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Auditoria automatizada</h5>
                            </Col>
                        </Row>
                        <img src={`${url_images}/animations/Programming Skills.gif`} style={{ width: "50%" }} />
                        <p>Este novedoso sistema te permitirá generar reglas de negocio cuyo cumplimiento serán analizado diariamente por un cuidador virtual de tus protocolos al que llamamos <b style={{ fontWeight: "bold" }}>CENTINELA</b></p>
                        <Link to={`/${rutas.centinela_nuevo.slug}`}><Button size="sm" variant="primary">MIS REGLAS DE NEGOCIO</Button></Link>
                    </Col>
                </Row>
            </div>
        </div>

        return <div>
            <NavigationCentinela />
            {filtros()}
            {paginacion(data.pagina, total, datos)}
            <Row>
            {
                datos.length > 0 ? datos.map((order,i) => {
                    return  <Col md={4} key={`order-${i}`}>
                        <Card className="p-3 mb-3 d-block shadow-sm">
                        <Row>
                            <Col>
                            <Row className="justify-content-between">
                            <Col md={8}>
                                <span style={{ color: "gray", fontSize: 11 }}>{fechaATexto(order.createdAt)}</span>
                                <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                        Haz clic para ver los detalles
                                    </Tooltip>
                                }
                            >
                                <h5 className="mb-0 mr-2" style={{fontWeight:900}}>
                                    <Link to={`/ordenes/${order._id}`}>{order.pedido}</Link>
                                </h5>
                            </OverlayTrigger>
                                {shoyEstadoEntregaHTML(order.estadoentrega, order.estado_entrega, order.sub_estado_entrega)}
                            </Col>
                            <Col md={4}>
                                <Button variant="light" size="sm" style={{fontSize: 10}} className="shadow-sm"><a href={`${url_front_produccion}/tracking/${order._id}`} target="_blank"> <b>VER TRACKING</b> <i className="fa-solid fa-location-dot"></i></a></Button>
                            </Col>
                            </Row>
                                <MostrarReglas reglas={order.reglas} i={i} />
                            </Col>
                        </Row>
                        </Card>
                    </Col>
                }) : <div>
                <Row className='p-5 bg-white'>
                        <Col className="text-center">
                            <img src={`${url_images}/animations/6342141.gif`} style={{ width: "50%" }} />
                            <h4 className="mb-0" style={{ fontWeight: "bold" }}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Sin coincidencias encontradas</h4>
                        </Col>
                </Row>
            </div>
            }
            </Row>
        </div>
    }

    const mostrarGraficos = () => {
        if(loading) return <div className='p-5 bg-white'>
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
                <img src={`${url_images}/animations/Tracking.gif`} style={{ width: "50%" }} />
                <h3 style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Cargando información...</h3>
            </Col>
        </Row>
    </div>

        return <div>
            {mostrarDatos()}
            </div>

}

    return <div>
        {mostrarGraficos()}
    </div>
}

export default CentinelaListado