const selectedOrders = [];

const reducer = (state = selectedOrders, action) => {
    switch (action.type) {
        case 'guardarSelectedOrders':
            return {
                ...state,
                selectedOrders: action.payload
            };
        default:
            return state;
    }
};


export default reducer;