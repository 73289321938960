import React, { Component, useState} from 'react'
import { useSelector } from 'react-redux'
import { Nav, Tab, Tabs } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { Row, Col } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import ListadoWhatsapp from '../../subComponents/listas-whatsapp/listadoWhatsapp/list'
import { useQuery } from '../../lib/helpers/router'
import WhatsappPlantillasListado from '../../subComponents/ordenes/whatsapp-plantillas/list'
import WhatsappConfiguracion from '../../subComponents/personalizar/whatsapp'
import Personalizar from '../../subComponents/personalizar/personalizar'
import ConfiguracionNotificaciones from '../../subComponents/notificaciones/configuracion_notificaciones'
import FormatosListado from '../../subComponents/formatos/list'
import LineaTiempo from '../LineaTiempo'

const Notificaciones = () => {
  const user = useSelector(state => state.miusuario)
  const [selectedTab, setSelectedTab] = useState('')
  const navigate = useNavigate()
  const location = useLocation()
  const query = useQuery()
  let default_key = 'notificaciones'
  if(query.get("tab")) default_key = query.get("tab")
  

    const handleChangeTab = (e) => {
      const newTab = e;
      const queryParams = new URLSearchParams();
      queryParams.set('tab', newTab);
      navigate({
          pathname: location.pathname,
          search: `?${queryParams.toString()}`,
      });
      return setSelectedTab(newTab);
    }


        return(
              <div className="fluid">
          <h1 className="h2 text-primary" style={{fontWeight:700}}><i className="fa-solid fa-gear"></i> Configuración de notificaciones</h1>
          <Tab.Container id="left-tabs-example" defaultActiveKey={default_key}>
        <Row>
          <Col sm={3}>
          <Nav variant="pills" className="flex-column" onSelect={handleChangeTab}>
            <Nav.Item><Nav.Link eventKey="notificaciones">Notificaciones</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link eventKey="tracking-page">Personalización</Nav.Link></Nav.Item>
            <Nav.Item><Nav.Link eventKey="formatos">Formatos</Nav.Link></Nav.Item>
            {/* <Nav.Item><Nav.Link eventKey="linea-tiempo">Línea de tiempo</Nav.Link></Nav.Item> */}
</Nav> 
          </Col>
          <Col sm={9}>
            <Tab.Content>
            <Tab.Pane eventKey="tracking-page" >
                <Personalizar filtrarItems={["email_estado"]} />
              </Tab.Pane>
                <Tab.Pane eventKey="notificaciones" >
                <ConfiguracionNotificaciones />
              </Tab.Pane>
              <Tab.Pane eventKey="formatos" >
                <FormatosListado />
              </Tab.Pane>
              <Tab.Pane eventKey="linea-tiempo" >
                <LineaTiempo />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          </Row>
        </Tab.Container>
      </div>
    )
    }

export default Notificaciones