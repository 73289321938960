import React, { useEffect, useState} from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { urlapi } from '../../lib/backend/data'
import { configuracion_fechas, configuracion_horas } from '../../lib/helpers/dates'
import { fechaATexto } from '../../lib/helpers/helpers'
import MapaEstados from '../../subComponents/mapas/mapa_estados'
import { useDispatch } from 'react-redux'
import { cerrarSesion } from '../../redux/actions/session'
import { esNumero } from '../../lib/helpers/main'
import TablaProductos from '../../subComponents/ordenes/tabla_productos'
import MapaDetallePedidoTrackingPage from './mapa_tracking_page'

const TrackingPage = (props) => {
    const {
        orden,
        interfaz,
        markers
    } = props
    const [ loadingPedido, setLoadingPedido ] = useState(false)
    const [ marcadores, setMarcadores ] = useState(markers ? markers : [])
    const [ detalle_pedido, setDetallePedido ] = useState(orden)
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const personalizacionTracking = interfaz ? interfaz : {}
    const route = useParams()
    const dispatch = useDispatch()


    useEffect(() => {
        // obtenerPedido()
    }, [])

    const showField = (show, element) => {
      if(show === false) return false
      if(typeof show === "undefined" ) return element
      return element
  }

    const getConfigModuleFromSlug = (slug) => {
      if(!personalizacionTracking) return {}
      if(typeof personalizacionTracking !== "object") return {}
      if(!personalizacionTracking.detalles) return {}
      if(!personalizacionTracking.detalles?.modulos) return {}
      const i = personalizacionTracking.detalles.modulos.findIndex(c => c.slug === slug)
      if( i < 0 ) return {}
      const config = personalizacionTracking.detalles.modulos[i]
      if(typeof config !== "object") return {}
      return config
  }


    const getConfigFromFieldSlug = (slug) => {
      if(!personalizacionTracking) return true
      if(typeof personalizacionTracking !== "object") return true
      if(!personalizacionTracking.detalles) return true
      if(!personalizacionTracking.detalles?.campos) return true
      const i = personalizacionTracking.detalles.campos.findIndex(c => c.slug === slug)
      if( i < 0 ) return true
      const config = personalizacionTracking.detalles.campos[i]
      if(typeof config !== "object") return true
      return config.activo === true
  }


    const getConfigFromModuleSlug = (slug) => {
      if(!personalizacionTracking) return true
      if(typeof personalizacionTracking !== "object") return true
      if(!personalizacionTracking.detalles) return true
      if(!personalizacionTracking.detalles?.modulos) return true
      const i = personalizacionTracking.detalles.modulos.findIndex(c => c.slug === slug)
      if( i < 0 ) return true
      const config = personalizacionTracking.detalles.modulos[i]
      if(typeof config !== "object") return true
      return config.activo === true
  }

    const mostrarSubEstadoHTML = (sub_estado_entrega) => {
        if(sub_estado_entrega){
            if(typeof sub_estado_entrega === 'object'){
            if(sub_estado_entrega.titulo){
                return <span className="spanstatus mr-2" style={{ fontSize: 11, backgroundColor: sub_estado_entrega.color ? sub_estado_entrega.color : '#a2a2a2', padding: "1px 10px", borderRadius: 5, color: "white" }}>{sub_estado_entrega.titulo}</span>
            }
            }
        }
        return false
        }

    const statusString = (status) => {

        let cadena = "";
        
        if (status) {
        if ( status == 1 ) {
        cadena = "Preparado";
        } else if( status == 2 ){
        cadena = "Leído en el móvil";
        } else if (status == 3) {
        cadena = "Retirado en origen";
        } else if (status == 4 ) {
        cadena = "En reparto";
        } else if (status == 5) {
        cadena = "Entregado";
        } else if (status == 6){
        cadena = "Entrega Confirmada";
        } else if (status == 7){
        cadena = "Entrega Confirmada";
        } else if (status == 9){
        cadena = "Retiro de bodega";
        } else if (status == 18){
        cadena = "En CD Delpa";
        } else if (status == 13){
        cadena = "No entregado";
        } else {
        cadena = "Sin datos";
        }
        } else {
        cadena = "Sin datos";
        }
        return cadena;
        }

    const statusColor = (level) => {

        let color = "#dbdbde";

    if ( level == 1 ) {
    color = "#cad004";
    } else if( level == 2 ){
    color = "#d09d04";
    } else if (level == 3) {
    color = "#d03e04";
    } else if (level == 4 ) {
    color = "#5e72e4";
    } else if (level == 5) {
    color = "#64b612";
    } else if (level == 6){
    color = "#64b612";
    } else if (level == 7){
    color = "#64b612";
    } else if (level == 9){
    color = "#ff71a6";
    } else if (level == 18){
    color = "#a31be1";
    } else if (level == 13){
    color = "#000";
    } 

    return color;

    }

    const sinRegistrosHTML = (palabra,archivo) => {
        return <div className='text-center p-3'>
          <img src={`/images/${archivo}`} style={{ width: 70 }} />
          <h3>No hay {palabra} registrados</h3>
        </div>
      }

      const showComponentByTipoFormulario = (campo) =>{
        switch (campo.tipo_accion) {
          case 'campo-texto':
            if(!campo.valor) return false
            return <div className="mb-3">
              <h6 className='mb-0'>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'selector':
            if(!campo.valor) return false
            return <div className="mb-3">
              <h6 className='mb-0'>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'carga-imagenes':
            if(campo.valores.length < 1) return false
            return <div className="mb-3">
              <h6 className='mb-2'>{campo.titulo}</h6>
              
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <div md={3} key={`foto-estado-${i}`} style={{ display: "inline-block" }}>
                    <a href={foto.url} target="_blank" className='pr-2'>
                    <img src={foto.url} style={{  height: 70 }} ></img>
                    </a>
                    </div>
                }) : <Col md={12}><p>Sin información</p></Col>
              }

            </div>
          case 'firma-digital':
            if(!campo.valor) return false
            const isNumero = esNumero(campo.valor)
            if(isNumero === true) return false
    
            return <div className="mb-3 text-primary">
                <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
                <p className='mb-0'>
                    {campo.valor 
                        ? <img src={campo.valor} alt="Firma digital" style={{maxWidth: '25%'}} /> 
                        : 'Sin información'
                    }
                </p>                  
            </div>
          default:
            break;
        }
    }

      const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {
          return <div key={`meta-${i}`}>
            {showComponentByTipoFormulario(meta)}
          </div>
        })
      }

    const mostrarEstados = (estados) => {
        if(!estados) return sinRegistrosHTML('estados', 'tracking-estados.png')
        if(Array.isArray(estados) !== true) return sinRegistrosHTML('estados', 'tracking-estados.png')
        if(estados.length < 1) return sinRegistrosHTML('estados', 'tracking-estados.png')
  
        return <div className='row' style={{ backgroundColor: '#eee', paddingTop: 15, marginBottom: 20 }}>
          {
            estados.map((estado,im) => {
              const fecha = new Date(estado.fecha)
              return <div className='col-md-12 mb-3' key={`estado-${im}`}>
                <div className='card shadow'>
                  <div className='p-3'>
                  <span style={{ fontSize: 13 }} className='mb-2 d-block'>{fecha.toLocaleDateString('es-ES', configuracion_fechas )} · <b>{fecha.toLocaleTimeString('en-US', configuracion_horas)}</b></span>
                  <span className="spanstatus mr-2" style={{ fontSize: 11, backgroundColor: estado.estado_color ? estado.estado_color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado.estado}</span>
                  {mostrarSubEstadoHTML({ titulo: estado.subestado, color: estado.subestado_color, })}
                  <hr className='hr' />
                  {mostrarMetaDatosSubEstado(estado.metadatos)}
                  </div>
                </div>
                </div>
            })
          }
        </div>
      }

    const shoyEstadoEntregaHTML = (estadoentrega, estado_entrega, sub_estado_entrega) => {
        if(estado_entrega){
            if(typeof estado_entrega === 'object'){
            if(estado_entrega.titulo){
                return <div>
                <span className="spanstatus mr-2" style={{backgroundColor: estado_entrega.color ? estado_entrega.color : '#babbbb', padding: "1px 10px", borderRadius: 5, color: "white" }}>{estado_entrega.titulo}</span>
                {mostrarSubEstadoHTML(sub_estado_entrega)}
                </div>
            }
            }
        }
        return <span className="spanstatus" style={{backgroundColor: statusColor(estadoentrega)}}>{statusString(estadoentrega)}</span>
        }


    const Header = () => {
        return <div>
            <img src="https://ultimamilla.delpasystem.com/images/logo.png" style={{ width: 200 }} />
        </div>
    }

    const mostrarReferencia = (orden, conductor) => {
      
      let show_module = getConfigFromModuleSlug('resumen')
      const configuracion = getConfigModuleFromSlug('resumen')
      if(show_module === false) return false

      return  <div className='shadow-sm border pt-2 px-3 w-100 bg-white'>
      {/* <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Referencia</label> */}
      <div className='d-flex justify-content-between align-items-center mt-2'>
      <div className='d-flex'>
        <h4 className=' text-primary' style={{ fontWeight: 900 }}><i className="fa-solid fa-tag text-primary"></i> Orden:</h4>
        <h4 className=' ml-2 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.pedido}</h4>
      </div>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Orden de transporte</label>
        <p className=' text-primary' style={{ fontWeight: 700 }}>{detalle_pedido.orden.ot ? detalle_pedido.orden.ot : <p><i style={{ fontSize: 14, fontWeight: 400 }}>- sin Informacion -</i></p>} </p>
      </div>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Fecha Creación</label>
        <p className=' text-primary'>{orden.createdAt ? orden.createdAt : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
        {configuracion.extra}        
      </div>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Estado entrega</label>
        <p className='' style={{fontWeight:700}}>{shoyEstadoEntregaHTML(detalle_pedido.orden.estadoentrega, detalle_pedido.orden.estado_entrega, detalle_pedido.orden.sub_estado_entrega)}</p>
      </div>
      </div>

      <hr className='mt-0' />
      <h5 className='mb-2 d-block text-primary' style={{ fontWeight: 900 }}><i className="fa-regular fa-id-card"></i> Conductor:</h5>
      <div className='d-flex mb-2 mx-4 justify-content-between'>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Nombre:</label>
        <p className='mb-0 text-primary'>{conductor?.nombres ? conductor?.nombres : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
      </div>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Apellido:</label>
        <p className='mb-0 text-primary'>{conductor?.apellidos ? conductor?.apellidos : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
      </div>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Teléfono:</label>
        <p className='mb-0 text-primary'>{conductor?.phone ? conductor?.phone : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
      </div>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Vehículo:</label>
        <div className='d-flex justify-content-between'>
        <p className='mb-0 text-primary'>{conductor?.modelo ? conductor?.modelo : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
        <p className='mb-0 ml-2 text-primary'>{conductor?.patente ? conductor?.patente : false}</p>
        </div>
      </div>
      <div>
        <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Año:</label>
        <p className='mb-0 text-primary'>{conductor?.ano ? conductor?.ano : <i style={{ fontSize: 14, fontWeight: 400 }}>- n/a -</i>}</p>
      </div>
    </div>
    </div>

    } 

    const mostrarMapa = () => {
      let show_module = getConfigFromModuleSlug('mapa')
      const configuracion = getConfigModuleFromSlug('mapa')
      if(show_module === false) return false
    
    return <div className='mt-2'>
      <MapaDetallePedidoTrackingPage markers={marcadores} height={300} />
      {configuracion.extra}
    </div> 
    } 

  const mostrarEstadosCarga = () => {
      let show_module = getConfigFromModuleSlug('estados_carga')
      const configuracion = getConfigModuleFromSlug('estados_carga')
      if(show_module === false) return false

      return <div>
          <div className='mt-2'>{mostrarEstados(detalle_pedido.estados, 'accordion')}</div>
          {configuracion.extra}
        </div> 
  }


    const mostrarMasInfo = (orden, facturacion) => {

      let show_module = getConfigFromModuleSlug('mas_info')
      const configuracion = getConfigModuleFromSlug('mas_info')
      if(show_module === false) return false
      
      const elemento_notas = <Col md={12} className='mb-1'>
          <hr className='mt-0' />
          <h6 className='mb-0 d-block text-primary' style={{ fontWeight: 900 }}><i class="fa-regular fa-clipboard"></i> Notas:</h6>
          {configuracion.extra}
      </Col>


      const elemento_refrigerados = <div className='d-flex align-items-center'>
      <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Refrigerados:</label>
      <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.refrigerado}</h6>
      </div>


      const elemento_bultos = <div className='d-flex align-items-center'>
      <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Secos:</label>
      <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.bultos}</h6>
      </div>


      const bultos_congelados = <div className='d-flex align-items-center'>
      <label className="form-control-label text-primary" style={{ fontWeight: 700 }}>Congelados:</label>
      <h6 className='ml-1 text-primary' style={{ fontWeight: 900 }}>{detalle_pedido.orden.congelado}</h6>
      </div >


      const elemento_direccion = <Col>
      <label className='form-control-label mb-0 text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Detalles dirección</label>
      <p className='text-primary'>{facturacion.address_1}</p>
      <p className='text-primary'>{facturacion.address_2}</p>
      </Col>




      const elemento_nombre = <Col>
      <label className='form-control-label text-primary mb-0' style={{ fontWeight: 700, fontSize: 14 }}>Nombre</label>
      <p className='text-primary'>{facturacion.first_name}</p>
      </Col>



      const elemento_email = <Col>
      <label className='form-control-label mb-0 d-block text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Email</label>
      <p className='text-primary'>{facturacion.email}</p>
      </Col>


      const elemento_movil = <Col>
      <label className='form-control-label mb-0 d-block text-primary' style={{ fontWeight: 700, fontSize: 14 }} >Móvil</label>
      <p className='text-primary'>{facturacion.phone}</p>
      </Col>


      // <div className='shadow-sm border pt-2 px-3 w-100 bg-white' >
      // <Row className='px-3 mb-2 justify-content-between'>
      //   <div>
      //   <h5 className='mb-0 text-primary' style={{ fontWeight: 900, fontSize: 20 }} ><i className="fa-solid fa-truck-ramp-box"></i> Productos</h5>
      //   </div>
      //   <div className='d-flex' style={{ gap: '2rem' }}>

        

        
      //     </div>
      // </Row>
      //   <TablaProductos productos={detalle_pedido.orden.productos} viewType='simple' />
      // </div>

      const show_notas                  = showField( getConfigFromFieldSlug("notas"), elemento_notas  )
      const show_refrigerados           = showField( getConfigFromFieldSlug("bultos_refrigerados"), elemento_refrigerados  )
      const show_bultos                 = showField( getConfigFromFieldSlug("bultos"), elemento_bultos  )
      const show_congelados             = showField( getConfigFromFieldSlug("bultos_congelados"), bultos_congelados  )
      const show_direccion              = showField( getConfigFromFieldSlug("direccion"), elemento_direccion  )
      const show_nombre                 = showField( getConfigFromFieldSlug("destinatario"), elemento_nombre  )
      const show_email                  = showField( getConfigFromFieldSlug("email"), elemento_email  )
      const show_movil                  = showField( getConfigFromFieldSlug("movil"), elemento_movil  )
      
      return <div className='shadow-sm border pt-2 px-3 w-100 bg-white'>
        { !show_nombre && !show_email && !show_movil && !show_direccion ? false : <h5 className='mb-0 d-block text-primary' style={{ fontWeight: 900 }} ><i className="fa-solid fa-street-view text-primary"></i> Destinatario</h5> }
        <div className='d-flex pt-2 mx-2 w-100' >
        {show_nombre}
        {show_email}
        {show_movil}     
        {show_direccion}
        </div>

        <hr className='mt-0' />
        <div className='d-flex justify-content-between mb-2 mr-4' style={{ gap: '2rem' }}>
        { !show_bultos && !show_congelados && ! show_refrigerados ? false : <h5 className='mb-0 d-block text-primary' style={{ fontWeight: 900}} ><i className="fa-solid fa-truck-ramp-box"></i> Productos</h5> }
        {show_bultos}
        {show_congelados}
        {show_refrigerados}
        </div>
        <TablaProductos productos={detalle_pedido.orden.productos} viewType='ecommerce' />
        
        {show_notas}
      </div>
    }

    const mostrarDatos = () => {
   
      if (loadingPedido) return <div className="loadingbox text-center mb-3">
      <Header />
      <Spinner animation='border' />
      <h4 className='my-2' style={{fontWeight:900}}>Cargando información...</h4>
    </div>

    if (!detalle_pedido) return <div className="loadingbox text-center">
      <Header />
      <h2 className='mb-2' style={{fontWeight:900}}>Sin información</h2>
    </div>

    const orden = typeof detalle_pedido.orden.orden === 'object' ? detalle_pedido.orden.orden : {}
    const conductor = typeof detalle_pedido.orden.conductor === 'object' ? detalle_pedido.orden.conductor : {}
    const facturacion = typeof orden.billing === 'object' ? orden.billing : {}

    return <div>

      <h3 className='text-primary mb-3' style={{ fontWeight: 900 }}><i className="fa-solid fa-location-dot text-primary"></i> Tracking</h3>

      <Row className=''>
        <Col md={12} className=''>
          {mostrarReferencia(orden, conductor)}
          {mostrarMasInfo(orden, facturacion)}
        {/* <hr />    */}
        {mostrarMapa()} 
        {mostrarEstadosCarga()} 
        {/* {mostrarRelacionadas()} */}
        </Col>
      </Row>
    </div >
    }

        return(
            <div className="fluid p-3">
                {mostrarDatos()}
                <div className='text-center'>
                  <h6>Powered By</h6>
                  <img src='https://clientes.tulogistica.cl/img/logo-delpa-gris.png' style={{ width: 140 }} alt="logo-delpa" />
              </div>
            </div>
        )
}

export default TrackingPage