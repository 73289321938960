import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../lib/backend/data"
import { procesarDatosGraficosEstadisticaConductorLastMile, procesarOTIFParaGraficos } from "../../lib/helpers/conductores"
import { StringMes, formatDateAno } from "../../lib/helpers/dates"
import GraficosMesLastMile from "../graficos/graficos_last_mile"
import { Accordion, Badge, Button, Card, Col, Container, Form, Modal, ProgressBar, Row, Spinner, Tab, Tabs } from "react-bootstrap"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { formatMes } from "../../lib/helpers/opcionesfecha"
import SinDatos from "../general/sin_registros"
import GraficoOtiff from "./grafico_otif"
import OrdenesListado from "../ordenes/listado"
import ConteoEstatico from "../actividad/conteo_estatico"
import SelectorDestinatarios from "../destinatarios/selector"
import { cerrarSesion } from "../../redux/actions/session"
import { url_images } from "../../lib/global/data"
import { estilo_last_mile } from "../../lib/global/styles"
import LoadingReports from "../general/loadingAnimations/loadingReports"
import { calcularCompromiso, calcularOrdenRetrasada } from "../../lib/helpers/pedidos/pedidos"
import { formatoMoneda } from "../../lib/helpers/main"
import { FaCheckCircle, FaChevronDown, FaCircle, FaRegClock } from "react-icons/fa"
import { calcularPorcentajeSobreCantidad, formatYMD } from "../../lib/helpers/helpers"
import { BsFileEarmarkBarGraph } from "react-icons/bs"
import { HiCursorClick } from "react-icons/hi"
import Switch from "react-bootstrap/esm/Switch"
import { FaBoxesPacking } from "react-icons/fa6"
import { MdOutlineContentPasteSearch } from "react-icons/md"
import CalendarioMensualOrdenes from "../graficos/grafico_calendario"
import { DateTime } from "luxon"


const EstadisticaMensual = (props) => {
    const [condicionBuquedaOrdenes, setCondicionBusquedaOrdenes] = useState(false)
    const [loadingOrdenes, setLoadingOrdenes] = useState(true)
    const [tipoVisualizacion, setTipoVisualizacion] = useState('asignadas')
    const [datosGraficosHistoricoV2, setDatosGraficosHistoricosV2] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [showModal, setShowModal] = useState(false)
    const [clienteSeleccionado, setClienteSeleccionado] = useState('')
    const [ condicion_busqueda, setCondicionBusqueda ] = useState({})
    const [ano, setAno] = useState(formatDateAno(new Date()))
    const [vehiculosSeleccionados, setVehiculosSeleccionados] = useState([])
    const [otif, setOtif] = useState(false)
    const [ordenes, setOrdenes] = useState([])
    const [mes, setMes] = useState(formatMes(new Date()))
    const [ visualizationType, setVisualizationType ] = useState("compacta")
    const border_color = "1px solid black"
    const token = session.tokenSession
    const estilo_seleccionado = { border: "4px solid #1cb03a" }
    const dispatch = useDispatch()

    useEffect(() => {
        obtenerOrdenes(vehiculosSeleccionados, mes)
    }, [])

    const obtenerOrdenes = async (drivers, month) => {
        setLoadingOrdenes(true)
        let cond = JSON.parse( JSON.stringify( condicion_busqueda ) )
        if(clienteSeleccionado) cond.id_cliente = clienteSeleccionado
        return fetch(`${urlapi}/estadisticas/ordenes`, {
            method: 'POST',
            body: JSON.stringify({
                drivers,
                mes: month,
                ano,
                condicion: cond
            }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingOrdenes(false)

                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingOrdenes(false)

                } else if (Array.isArray(res) !== false) {
                    setOrdenes(res)
                    const graphs_v2 = procesarDatosGraficosEstadisticaConductorLastMile(res)
                    console.log({ graphs_v2 })
                    const otif_mes = procesarOTIFParaGraficos(graphs_v2)
                    setOtif(otif_mes)
                    setDatosGraficosHistoricosV2(graphs_v2)
                }
                return setLoadingOrdenes(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingOrdenes(false)
            })
    }

    const refrescarDatos = () => {
        obtenerOrdenes(vehiculosSeleccionados, mes)
    }

    const handleChangeMes = (e) => {
        const { value } = e.target
        setMes(value)
        obtenerOrdenes(vehiculosSeleccionados, value)
    }

    const handleChangeCliente = (e) => {
        return setClienteSeleccionado(e?.value ? e?.value : "")
    }

    const handleChangeAno = (e) => {
        const { value } = e.target
        return setAno(value)
    }

    const handleChangeTipo = (e) => {
        const { value } = e.target
        return setTipoVisualizacion(value)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const seleccionarDia = (data) => {

        const dia = data.dia < 10 ? `${0}${data.dia}` : data.dia

        const fecha = `${ano}-${mes}-${dia}`
        let ids_query = data.datos.map(o => o._id)
        /*
        if (tipoVisualizacion === "asignadas") {
            for (const info_dia of datosGraficosHistoricoV2.asignadas.dias) {
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia

                if (dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if (tipoVisualizacion === "completadas") {
            for (const info_dia of datosGraficosHistoricoV2.completadas.dias) {
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if (dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...ids]
            }
        } else if (tipoVisualizacion === "retrasadas") {
            for (const info_dia of datosGraficosHistoricoV2.rechazadas.dias) {
                const dia_iteracion = info_dia.dia < 10 ? `${0}${info_dia.dia}` : info_dia.dia
                if (dia.toString() !== dia_iteracion.toString()) continue
                const ids = info_dia.datos.map(d => d._id)
                ids_query = [...ids_query, ...data.datos.map(o => o._id)]
            }
        }
        */
        if (ids_query.length > 0) {
            setCondicionBusquedaOrdenes({ _id: { $in: ids_query } })
            setShowModal(true)
        }
    }
    
    const visualizarRetrasadasEstado = ( ids ) => {
        setCondicionBusquedaOrdenes({ _id: { $in: ids } })
        setShowModal(true)
    }

    const agruparPorTipoServicio = (datos) => {
        if(!Array.isArray(datos)) return <SinDatos />
        let tiposServicio = []
        for( const dato of datos ){
            let tipoServicio = 'Sin tipo de servicio'
            if(dato.tiposervicio) tipoServicio = dato.tiposervicio
            const i = tiposServicio.findIndex(e => e.titulo === tipoServicio)
            if(i > -1){
                tiposServicio[i].ordenes.push(dato)
            } else {
                tiposServicio.push({
                    titulo: tipoServicio,
                    ordenes: [dato]
                })
            }
        }
        return tiposServicio
    }

    const MostrarEsquemaEstadosTabs = (props) => {
        const {
            esquema_estados
        } = props
        let valor_defecto = ''
        if(esquema_estados) if(Array.isArray(esquema_estados)) if(esquema_estados.length > 0) valor_defecto = `${esquema_estados[0].codigo_estado}-0`
        let [ defaultVal, setDefaultVal ] = useState(valor_defecto)
        let [ activeKey, setActiveKey ] = useState(defaultVal)


        const handleChangeTab = (e) => {
            setActiveKey(e)
        } 

        useEffect(() => {
            if(esquema_estados) if(Array.isArray(esquema_estados)) if(esquema_estados.length > 0) setActiveKey(`${esquema_estados[0].codigo_estado}-0`)
        }, [ esquema_estados ])

        if(!esquema_estados) return false
        if(!Array.isArray(esquema_estados)) return false
        if(esquema_estados.length < 1) return <SinDatos />

        return <div>
            <Tabs activeKey={activeKey} className="m-auto" onSelect={handleChangeTab} >
                {
                    esquema_estados.map((estado, i) => {
                        return <Tab eventKey={`${estado.codigo_estado}-${i}`} title={<p className="mb-0" style={{...estilo_last_mile.bold, textTransform: "uppercase" }}><FaCircle style={estilo_last_mile.icon} color={estado.color} /> {estado.title} {formatoMoneda(estado.ordenes.length)}</p>} >
                            <div className="p-3">
                            <CalendarioMensualOrdenes 
                                className='border border-start-0'
                                titulo={"creadas"} 
                                onClick={data => seleccionarDia(data)} 
                                datos={estado.ordenes} height={'200px'} />
                            </div>
                        </Tab>
                    })
                }
            </Tabs>
        </div>
    }


    const MostrarSegmentacionTipoServicioCalendario = (props) => {
        const {
            datos
        } = props
        const [ tipoBusqueda, setTipoBusqueda ] = useState('estado_entrega')
        if(!datos) return false
        if(!Array.isArray(datos)) return false
        if(datos.length < 1) return <SinDatos />

        const segmentacion = agruparPorTipoServicio(datos)

        
        return <div>

            <Accordion defaultActiveKey={ segmentacion.length === 1 ? `${segmentacion[0].titulo}-0` : '' }>
                {
                    segmentacion.map((tipo, i) => {
                        let key = `${tipo.titulo}-${i}`

                        const esquema_estados = segmentarEstadosOrdenes(tipo.ordenes, tipoBusqueda)
                        const ordenes_vencen_hoy = tipo.ordenes.filter(o => o.fecha_max_entrega && formatYMD(o.fecha_max_entrega) === formatYMD(new Date()))

                        return <Card className="p-0" key={`accordion-torre-control-${i}`}>
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={key}>
                        <div className="pl-3 pr-3 pb-0 hover">
                                    <h6 className="mb-0" style={{ ...estilo_last_mile.bold, fontSize: 13, textTransform: "uppercase"}}>{tipo.titulo}</h6>
                                    <h3 className="mb-0">{formatoMoneda(tipo.ordenes.length)}</h3>
                                    { ordenes_vencen_hoy.length > 0 ? <Badge variant="warning" className="mb-0" style={{ fontSize: 12 }}>{ordenes_vencen_hoy.length} {ordenes_vencen_hoy.length > 1 ? "entregas tienen" : "entrega tiene"} plazo hasta hoy</Badge> : false }
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={key}>
                        <Card className="p-3" style={{ backgroundColor:'#f1f1f1' }}>
                            <div className="p-2" >
                            <select className="form-control mb-3" value={tipoBusqueda} onChange={e => setTipoBusqueda(e.target.value)} >
                                <option value="estado_entrega">Ver por estado</option>
                                <option value="sub_estado_entrega">Ver por sub estado</option>
                            </select>
                                {/* {JSON.stringify(esquema_estados.map((est => ({ titulo: est.title, color: est.color }))))} */}
                                <MostrarEsquemaEstadosTabs esquema_estados={esquema_estados} />
                            {/* <ContabilizarEstados datos={tipo.ordenes} onSelect={(ids) => console.log(ids)} />  */}
                            </div>
                        </Card>
                        </Accordion.Collapse>
                    </Card>
                    })
                }
            </Accordion>
        </div>
    }

    const calcularOtif = (datos, a_tiempo, retrasadas, reintentos, showOtif, tab_selected_default) => {
        const defaul_tab = tab_selected_default ? tab_selected_default : "a_tiempo"

        const contar_reintentos = reintentos.reduce((prev, next) => {
            const reintento = next.intentos - 1
            return prev + reintento
        }, 0)

        const todos = datos
        const todos_agrupados = agruparPorTipoServicio(todos)

        const otif = calcularPorcentajeSobreCantidad(datos.length, a_tiempo.length)

        return <div className="mb-3">
            <Tabs defaultActiveKey={defaul_tab} className="m-auto" >
                <Tab eventKey="a_tiempo" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><FaCheckCircle style={estilo_last_mile.icon} /> {formatoMoneda(a_tiempo.length)} Entrega en tiempo</h5>} >
                    <div className="p-3">
                    {/* {mostrarSegmentacionTipoServicioCalendario(a_tiempo)} */}
                    <MostrarSegmentacionTipoServicioCalendario datos={a_tiempo} />
                    </div>
                </Tab>
                <Tab eventKey="retrasadas" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><FaRegClock style={estilo_last_mile.icon} /> {formatoMoneda(retrasadas.length)} Entrega fuera de tiempo</h5>} >
                    <div className="p-3">
                    {/* {mostrarSegmentacionTipoServicioCalendario(retrasadas)} */}
                    <MostrarSegmentacionTipoServicioCalendario datos={retrasadas} />
                    </div>
                </Tab>
                <Tab eventKey="reintentos" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><FaBoxesPacking style={estilo_last_mile.icon} /> {formatoMoneda(contar_reintentos)} Reintentos {contar_reintentos > 0 ? `(${Math.round((contar_reintentos/todos.length)*100)}%)` : null }
                </h5>} >
                    <div className="p-3">
                    <p className="" style={{ fontWeight: "bold", fontSize: 12 }}>Se muestra la cantidad de órdenes que tuvieron reintentos según su tipo de servicio</p>
                    {/* {mostrarSegmentacionTipoServicioCalendario(retrasadas)} */}
                    <MostrarSegmentacionTipoServicioCalendario datos={reintentos} />
                    </div>
                </Tab>
                <Tab eventKey="avanzado" style={{ border: "1px solid #c6c6c6" }} title={<h5 style={{...estilo_last_mile.bold}}><MdOutlineContentPasteSearch style={estilo_last_mile.icon} /> Vista detallada</h5>} >
                    <div className="p-3">
                    <SegmentarTipoServicio datos={datos} />
                    </div>
                </Tab>
                { showOtif === true ? <Tab style={{ border: "1px solid #c6c6c6" }} eventKey="otif" title={<h5 style={estilo_last_mile.bold}><BsFileEarmarkBarGraph style={estilo_last_mile.icon} /> OTIF {formatoMoneda(Math.ceil(otif))}%</h5>} >
                        <div className="p-3">
                        <Row>
                        {
                            todos_agrupados.map((tipo, i) => {
                                const ordenes_vencen_hoy = tipo.ordenes.filter(o => o.fecha_max_entrega && formatYMD(o.fecha_max_entrega) === formatYMD(DateTime.now().toISO()))
                                return <Col key={`tipo-${i}-${tipo.titulo}`} className="mb-3">
                                    <h6 className="mb-0" style={{ ...estilo_last_mile.bold, fontSize: 13, textTransform: "uppercase"}}>{tipo.titulo}</h6>
                                    <h3 className="mb-0">{formatoMoneda(tipo.ordenes.length)}</h3>
                                    { ordenes_vencen_hoy.length > 0 ? <Badge variant="warning" className="mb-0" style={{ fontSize: 12 }}>{ordenes_vencen_hoy.length} {ordenes_vencen_hoy.length > 1 ? "entregas tienen" : "entrega tiene"} plazo hasta hoy</Badge> : false }
                                    </Col>
                            })
                        }
                        </Row>
                        </div>
                </Tab> : false  }
                
            </Tabs>
        </div>
    }

    const mostrarIcono = (color) => {
        return <FaCircle style={{ fontSize: 11, color }} />
    }

    const SegmentarTipoServicio = (props) => {
        const {
            datos
        } = props
        const [tipoServicio, setTipoServicio] = useState('')
        const [ tiposServicio, setTiposServicio ] = useState([])
        const [ showModalOrdenes, setShowModalOrdenes ] = useState(false)
        const [ condicionOrdenes, setCondicionOrdenes ] = useState(false)

        useEffect(() => {
            let tipos = []
            for( const dato of datos ){
                let tipo_servicio = 'Sin tipo de servicio'
                if(dato.tiposervicio) tipo_servicio = dato.tiposervicio
                const i = tipos.findIndex(e => e === tipo_servicio)
                if(i < 0) tipos.push(tipo_servicio)
            }
            if(tipos.length > 0) {
                setTiposServicio(tipos)
                setTipoServicio(tipos[0])
            }
        }, [  ])

        const obtenerOrdenesConsultadas = (ids) => {
            setCondicionOrdenes({ _id: { $in: ids } })
            setShowModalOrdenes(true)
        }

        const selectorTipoServicio = () => {
            if(!tipoServicio) return false
            return <div>
                <label className="form-control-label d-block" style={{ fontWeight: "bold" }}><HiCursorClick style={estilo_last_mile.icon} /> Selecciona el tipo de servicio que deseas visualizar</label>
                <select className="form-control mb-3" style={{ textTransform: "uppercase"}} value={tipoServicio} onChange={e => setTipoServicio(e.target.value)} >
                    {
                        tiposServicio.map((tipo, i) => <option key={`${tipo}-${i}`} value={tipo}>{tipo}</option>)
                    }
                </select>
            </div>
        }

        const informacion = tipoServicio ? datos.filter(d => d.tiposervicio === tipoServicio) : datos

        return <div>
            <Modal show={showModalOrdenes} size="xl" onHide={() => setShowModalOrdenes(false)} centered >
                <Modal.Header closeButton>
                    <Modal.Title>Órdenes relacionadas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <OrdenesListado upWhenClick={false} showSearch={false} showAddButton={false} showFilter={false} showExporter={false} condicion_default={condicionOrdenes} />
            </Modal.Body>
            </Modal>
            {selectorTipoServicio()}
            <ContabilizarEstados datos={informacion} onSelect={(ids) => obtenerOrdenesConsultadas(ids)} /> 
        </div>
    }

    const showByVisualizationTypeSelector = () => {
        return false
        return <div>
            <Card className="p-2 mb-3">
                <Form.Group className="mb-0" key="activate_weight" controlId="activate_weight">
                    <Form.Check type="switch" defaultChecked={visualizationType !== "compacta"} name="activate_weight" label={visualizationType === "compacta" ? 'Visualización compacta' : 'Visualización completa'} onChange={(val) => setVisualizationType(prev => prev === "compacta" ? "completa" : "compacta")} />
                </Form.Group>
            </Card>
        </div>
        return <div>
            <label className="form-control-label" style={{ fontWeight: "bold" }}><HiCursorClick style={estilo_last_mile.icon} /> Selecciona el tipo de vista</label>
            <select className="form-control mb-3" value={visualizationType} onChange={e => setVisualizationType(e.target.value)} >
                <option value="compacta">Compacta</option>
                <option value="detallada">Detallada</option>
            </select>
        </div>
    }

    const segmentarEstadosOrdenes = (datos, key_val) => {
        let key = key_val ? key_val : 'estado_entrega'
        if(!Array.isArray(datos)) return []
        let estados = []
        for( const dato of datos ){
            let codigo_estado = ''
            let titulo = 'Sin gestión'
            let color = 'gray'

            if(dato[key]) if(typeof dato[key] === 'object'){
                codigo_estado = dato[key].codigo_estado
                titulo = dato[key].titulo
                color = dato[key].color
            }

                if(!codigo_estado){
                    const i = estados.findIndex(e => e.codigo_estado === 'sin_estado')
                    if(i > -1){
                        estados[i].ordenes.push(dato)
                    } else {
                        estados.push({
                            color: "gray",
                            title: "Sin gestión",
                            codigo_estado: 'sin_estado',
                            // tipo_servicio: dato.tiposervicio,
                            ordenes: [dato]
                        })
                    }
                } else {
                    const i = estados.findIndex(e => e.codigo_estado === codigo_estado)
                    if(i > -1){
                        estados[i].ordenes.push(dato)
                    } else {
                        estados.push({
                            codigo_estado,
                            title: titulo,
                            color: color,
                            // tipo_servicio: dato.tiposervicio,
                            ordenes: [dato]
                        })
                    }
                }
            
        }

        

        for(let estado of estados){
        const tiposServicio = estado.ordenes.map(item => item.tiposervicio).filter(tipo => tipo)
        const tiposServicioUnicos = [...new Set(tiposServicio)];
        estado.tiposervicio =  tiposServicioUnicos.join(', ');
        }

        return estados
    }

    const ContabilizarEstados = (props) => {
        const {
            datos
        } = props


        const seleccionarOrdenes = (ids) => {
            if(props.onSelect) props.onSelect(ids)
        }

        if(!Array.isArray(datos)) return <SinDatos />
        let estados = []
        for( const dato of datos ){
            let codigo_estado = ''
            let titulo = 'Sin gestión'
            let color = 'gray'

            if(dato.estado_entrega) if(typeof dato.estado_entrega === 'object'){
                codigo_estado = dato.estado_entrega.codigo_estado
                titulo = dato.estado_entrega.titulo
                color = dato.estado_entrega.color
            }

                if(!codigo_estado){
                    const i = estados.findIndex(e => e.codigo_estado === 'sin_estado')
                    if(i > -1){
                        estados[i].ordenes.push(dato)
                    } else {
                        estados.push({
                            color: "gray",
                            title: "Sin gestión",
                            codigo_estado: 'sin_estado',
                            // tipo_servicio: dato.tiposervicio,
                            ordenes: [dato]
                        })
                    }
                } else {
                    const i = estados.findIndex(e => e.codigo_estado === codigo_estado)
                    if(i > -1){
                        estados[i].ordenes.push(dato)
                    } else {
                        estados.push({
                            codigo_estado,
                            title: titulo,
                            color: color,
                            // tipo_servicio: dato.tiposervicio,
                            ordenes: [dato]
                        })
                    }
                }
            
        }

        
            // const tiposServicio = estados.ordenes.map(item => {
            // item.tipoServicio})
        //     const tiposServicioUnicos = [...new Set(tiposServicio)];
        //     let stringServiciosUnicos = tiposServicioUnicos.join(', ');
        
        
        // const string = obtenerTiposServicioUnicos(data);


        for(let estado of estados){
        const tiposServicio = estado.ordenes.map(item => item.tiposervicio).filter(tipo => tipo)
        const tiposServicioUnicos = [...new Set(tiposServicio)];
        estado.tiposervicio =  tiposServicioUnicos.join(', ');
        }
        
        return <div className="" style={{ background: "transparent" }}>
            {/* <h5>Agrupados por su último estado</h5> */}
            <Row>
            {
                estados.map((estado, i) => {
                    return <Col md={3} key={`${estado.titulo}`} >
                        <p className="hover" onClick={() => seleccionarOrdenes(estado.ordenes.map(o => o._id))} style={{ borderRadius:4, borderBottom: `5px solid ${estado.color}`, padding: "5px 10px", borderRight: border_color, borderLeft: border_color, borderTop: border_color, fontWeight: "bold", color: "black" }}>  {estado.ordenes.length} {estado.title} {mostrarIcono(estado.color)}</p>
                    </Col>
                })
            }
            </Row>
        </div>
    }

    // const mostrarGraficos = () => {


    //     if (!datosGraficosHistoricoV2) return <SinDatos />
    //     switch (tipoVisualizacion) {
    //         case 'asignadas':
    //             return datosGraficosHistoricoV2.asignadas ? <GraficosMesLastMile titulo={tipoVisualizacion} onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.asignadas} height={'200px'} /> : <SinDatos />
    //         case 'completadas':
    //             return datosGraficosHistoricoV2.completadas ? <GraficosMesLastMile titulo={tipoVisualizacion} onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.completadas} height={'200px'} color="green" /> : <SinDatos />
    //         case 'retrasadas':
    //             return datosGraficosHistoricoV2.rechazadas ? <GraficosMesLastMile titulo={tipoVisualizacion} onClick={data => seleccionarDia(data)} datos={datosGraficosHistoricoV2.rechazadas} height={'200px'} color='#c50f0f' colors={['#c50f0f']} /> : <SinDatos />
    //         default:
    //             break;
    //     }
    // }

    if (loadingOrdenes === true) return <LoadingReports />

    if(!datosGraficosHistoricoV2) return <SinDatos />

    const ordenes_asignadas                     = datosGraficosHistoricoV2.asignadas.dias.reduce((prev, next) => [...prev, ...next.datos], [])
    const ordenes_en_curso                      = datosGraficosHistoricoV2.en_curso.dias.reduce((prev, next) => [...prev, ...next.datos], [])
    const ordenes_completadas                   = datosGraficosHistoricoV2.completadas.dias.reduce((prev, next) => [...prev, ...next.datos], [])
    const ordenes_retrasadas                    = datosGraficosHistoricoV2._anuladas.dias.reduce((prev, next) => [...prev, ...next.datos], [])

    const estilo_titulo_mes                     = { fontWeight: 700, fontSize: 15, color: '#1B3665' }

    const datos_iterar = [
        {
            key: "orden_asignada",
            ordenes: ordenes_asignadas,
            data: datosGraficosHistoricoV2.asignadas,
            titulo: "total general",
            showOtif: true,
            showProgress: true
        },
        {
            key: "orden_completa",
            ordenes: ordenes_completadas,
            data: datosGraficosHistoricoV2.completadas,
            titulo: "entrega finalizada",
            showProgress: true
        },
        {
            key: "en_curso",
            ordenes: ordenes_en_curso,
            data: datosGraficosHistoricoV2.en_curso,
            titulo: "pendientes de entrega",
            showProgress: true
        },
        {
            key: "orden_retrasada",
            ordenes: ordenes_retrasadas,
            data: datosGraficosHistoricoV2._anuladas,
            titulo: "anuladas",
        },
    ]

    return (
        <>
        <Modal show={showModal} size="xl" onHide={() => handleClose()} centered >
            <Modal.Header closeButton>
                <Modal.Title>Órdenes relacionadas</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <OrdenesListado upWhenClick={false} showSearch={false} showAddButton={false} showFilter={false} showExporter={false} condicion_default={condicionBuquedaOrdenes} />
        </Modal.Body>
        </Modal>
            <Row  style={{ height: '100%' }}>
                <Col md={12} >
                <h4 className='text-primary text-left mb-3' style={{ fontWeight: 900, textTransform: "uppercase" }}>GRÁFICOS DEL MES {StringMes(mes) + " " + (ano)}</h4>
                    <div>
                        <Row>
                            <Col>
                                <SelectorDestinatarios defaultValue={clienteSeleccionado} onChange={handleChangeCliente} titulo="Cliente" />
                            </Col>
                            <Col>
                            <div md={3} className="mb-3 d-block">
                                <label className='form-control-label'>Mes</label>
                                <select className='form-control w-100 shadow-sm' value={mes} onChange={handleChangeMes} >
                                    <option value="01">Enero</option>
                                    <option value="02">Febrero</option>
                                    <option value="03">Marzo</option>
                                    <option value="04">Abril</option>
                                    <option value="05">Mayo</option>
                                    <option value="06">Junio</option>
                                    <option value="07">Julio</option>
                                    <option value="08">Agosto</option>
                                    <option value="09">Septiembre</option>
                                    <option value="10">Octubre</option>
                                    <option value="11">Noviembre</option>
                                    <option value="12">Diciembre</option>
                                </select>
                            </div>
                            </Col>
                            <Col>
                                <div className="mb-2 d-block">
                                    <label className='form-control-label'>Año</label>
                                    <input className='form-control w-100 shadow-sm' type="number" value={ano} name="ano" onChange={handleChangeAno} />
                                </div>
                            </Col>
                            <Col>
                            <label className="form-control-label d-block">Toca para actualizar</label>
                            <Button className='w-100' variant="primary" onClick={() => refrescarDatos()}><b>ACTUALIZAR</b></Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <div className="py-3">
            { showByVisualizationTypeSelector() }
            {
                visualizationType === "compacta" ? <Accordion className="m-auto">

                {
                    datos_iterar.map((dato, i) => {

                        const { a_tiempo, retrasadas } = calcularCompromiso(dato.ordenes)
                        const otif_ = calcularPorcentajeSobreCantidad(dato.ordenes.length, a_tiempo.length)
                        const otif_negativa = calcularPorcentajeSobreCantidad(dato.ordenes.length, retrasadas.length)
                        const ordenes_reintentadas = dato.ordenes.filter(o => {
                            const intentos = o.intentos ? o.intentos : 0
                            return intentos > 1
                        })

                        const contar_reintentos = ordenes_reintentadas.reduce((prev, next) => {
                            const reintento = next.intentos - 1
                            return prev + reintento
                        }, 0)

                        const titulo = <h6 style={{...estilo_titulo_mes, textTransform: "uppercase"}}><FaChevronDown style={estilo_last_mile.icon} /> {formatoMoneda(dato.ordenes.length)} {dato.titulo}</h6>
                        const titulo_a_tiempo = <h6 style={{...estilo_last_mile.bold}}><FaCheckCircle style={estilo_last_mile.icon} /> {formatoMoneda(a_tiempo.length)} ENTREGA EN TIEMPO</h6>
                        const titulo_retrasada = <h6 style={{...estilo_last_mile.bold}}><FaRegClock style={estilo_last_mile.icon} /> {formatoMoneda(retrasadas.length)} ENTREGA FUERA DE TIEMPO</h6>
                        const titulo_reintento = <h6 style={{...estilo_last_mile.bold}}><FaBoxesPacking style={estilo_last_mile.icon} /> {formatoMoneda(contar_reintentos)} REINTENTOS {contar_reintentos > 0 ? `(${Math.round((contar_reintentos/dato.ordenes.length)*100)}%)` : null }</h6>

                        return <Card className="p-0" key={`accordion-torre-control-${i}`}>
                        <Accordion.Toggle as={Card.Header} style={{ padding: '8px 0px', fontSize: 12, backgroundColor:'white' }} eventKey={dato.key}>
                        <div className="pl-3 pr-3 pb-0 hover">
                            {
                                (i+1) === datos_iterar.length ? titulo : <Row>
                                <Col>{titulo}</Col>
                                <Col>
                                    {titulo_a_tiempo}
                                    { dato.showProgress === true ? <b style={{ color: estilo_last_mile.color_primary }}> <ProgressBar size={13} variant="success" label={`${Math.ceil(otif_)}%`} now={Math.ceil(otif_)} /></b> : false  }
                                </Col>
                                <Col>
                                    {titulo_retrasada}
                                    { dato.showProgress === true ? <b style={{ color: estilo_last_mile.color_primary }}> <ProgressBar size={13} variant="danger" label={`${Math.ceil(otif_negativa)}%`} now={Math.ceil(otif_negativa)} /></b> : false  }
                                </Col>
                                <Col>{titulo_reintento}</Col>
                            </Row>
                            }
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={dato.key}>
                        <Card.Body style={{ backgroundColor:'#f1f1f1' }}>
                            <div className="p-2" >
                            {calcularOtif(dato.ordenes, a_tiempo, retrasadas, ordenes_reintentadas, dato.showOtif, dato.default_tab)}
                            {/* <SegmentarTipoServicio datos={dato.ordenes} /> */}
                            {/* <GraficosMesLastMile 
                                className='border border-start-0'
                                titulo={"creadas"} 
                                onClick={data => seleccionarDia(data)} 
                                datos={dato.data} height={'200px'} /> */}

                            </div>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    })
                }
                </Accordion> : <Tabs defaultActiveKey="orden_asignada" className="m-auto">
                    {
                        datos_iterar.map((dato, i) => {
                            const { a_tiempo, retrasadas } = calcularCompromiso(dato.ordenes)
                            const ordenes_reintentadas = dato.ordenes.filter(o => {
                                const intentos = o.intentos ? o.intentos : 0
                                return intentos > 1
                            })
    
                            const contar_reintentos = ordenes_reintentadas.reduce((prev, next) => {
                                const reintento = next.intentos - 1
                                return prev + reintento
                            }, 0)
                            return <Tab style={{ border: "1px solid #c6c6c6" }} eventKey={dato.key} title={ <div><h4 style={{...estilo_titulo_mes, textTransform: "uppercase" }}>{formatoMoneda(dato.ordenes.length)} {dato.titulo}</h4></div> }>
                            <div className="p-3" >
                            {calcularOtif(dato.ordenes, a_tiempo, retrasadas, ordenes_reintentadas, dato.showOtif, dato.default_tab)}
                            {/* <GraficosMesLastMile 
                            className='border border-start-0'
                            titulo={"creadas"} 
                            onClick={data => seleccionarDia(data)} 
                            datos={dato.data} height={'200px'} /> */}
                            </div>
                        </Tab>
                        })
                    }
                </Tabs> 
                }
            </div>
            {/* {mostrarGraficos()} */}
        </>
        // <Col className="hover"><Card style={tipoVisualizacion==="asignadas"   ? estilo_seleccionado : {}} onClick={() => setTipoVisualizacion("asignadas")} className="p-3 text-center"><p className="m-0">{StringMes(mes)}</p><h3 className="p-0 m-0">ASIGNADAS</h3></Card></Col>
        // <Col className="hover"><Card style={tipoVisualizacion==="completadas" ? estilo_seleccionado : {}} onClick={() => setTipoVisualizacion("completadas")} className="p-3 text-center"><p className="m-0">{StringMes(mes)}</p><h3 className="p-0 m-0">COMPLETADAS</h3></Card></Col>
        // <Col className="hover"><Card style={tipoVisualizacion==="retrasadas"  ? estilo_seleccionado : {}} onClick={() => setTipoVisualizacion("retrasadas")} className="p-3 text-center"><p className="m-0">{StringMes(mes)}</p><h3 className="p-0 m-0">RETRASADAS</h3></Card></Col>
    )
}

export default EstadisticaMensual
