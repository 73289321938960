import 'react-toastify/dist/ReactToastify.css'
import { Row, Col, Tab, Nav, Card } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Header from '../../components/Header'
import { useState } from 'react'
import InformeGeneral from './informe_estilo_suite'
import FacturacionPendiente from './facturacion_pendiente'
import CostosListado from '../costos-tipos/list'
import SubTipoCostosListado from '../costos-sub-tipo/list'
import CostoListado from '../costos/list'
import HelperModal from '../general/helperModal'

const Facturacion = (props) => {
  const session = useSelector(state => state.miusuario)
  const [ condicionDefault, setCondicionDefault ] = useState({ proveedor: true })
      
  const handleCheck = (e, name) => {
    const valor = e.target.checked
    return setCondicionDefault(prev => ({...prev, ...{} }))
    }

        return <div className="fluid text-primary">
                    <div className='d-flex justify-content-between'>
                    <h4 style={{fontWeight:900}}><i className="fa-solid fa-file-invoice-dollar"></i> Facturación</h4>
                    <HelperModal tipo='facturacion' style='modal'/>
                    </div>
                    <Tab.Container defaultActiveKey="informe">
                    <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column" style={{fontWeight:700}}>
                        <Nav.Item><Nav.Link eventKey="informe">Informe de facturación</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="movimientos_pendientes">Facturación Pendiente</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="tipo_costos">Categorías de costos</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="subtipo_costos">Sub categorías de costos</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="costos">Costos</Nav.Link></Nav.Item>
                    </Nav>
                    </Col>
                    <Col md={9}>
                    <Card className="shadow-sm p-3">
                    <Tab.Content>
                        <Tab.Pane eventKey="informe"><InformeGeneral /></Tab.Pane>
                        <Tab.Pane eventKey="movimientos_pendientes"><FacturacionPendiente /></Tab.Pane>
                        <Tab.Pane eventKey="tipo_costos"><CostosListado /></Tab.Pane>
                        <Tab.Pane eventKey="subtipo_costos"><SubTipoCostosListado /></Tab.Pane>
                        <Tab.Pane eventKey="costos"><CostoListado /></Tab.Pane>
                    </Tab.Content>
                    </Card>
                    </Col>
                    </Row>
                    </Tab.Container>
            </div>
}

export default Facturacion