import { useEffect, useState } from 'react'
import { Row, Col, Spinner, Tabs, Tab, Card, Modal, Button } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { DateRange } from 'react-date-range';
import { es } from 'date-fns/locale'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays } from '../../lib/helpers/dates';
import { useParams } from 'react-router-dom';
import { cerrarSesion } from '../../redux/actions/session';
import { useDispatch, useSelector } from 'react-redux';
import * as XLSX from 'xlsx'
import RuteoCargasEstatico from './ruteo_simple_estatico';
import { FaAngleLeft, FaFileUpload } from 'react-icons/fa';
import { Button as Boton } from '@mui/material';
import { url_images } from '../../lib/global/data';
import { DateTime } from 'luxon';
import { BiSolidChevronRight } from 'react-icons/bi';
import { v4 as uuid } from "uuid";
import { estilo_last_mile } from '../../lib/global/styles';
import { TbRoute } from 'react-icons/tb';
import { MdOutlineBorderOuter } from 'react-icons/md';
import InformacionEsquema from '../general/descarga_modal_excel_esquema';
import { obtenerGeoDatosGoogle } from '../../lib/helpers/pedidos/pedidos';

const RuteoSimple = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const esquema_ordenes = [
        'direccion'
    ]
    const imgExcel = `${url_images}/animations/Man transfer data from cloud.gif`
    const dispatch = useDispatch()
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ ruta, setRuta ] = useState({})
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const esquema = [
        { value: 'nombre', label: 'Nombre', optionalUpload: true },
        { value: 'direccion', label: 'direccion', info:"Ejemplo: Avenida manuel antonio matta 747, santiago" },
        { value: 'comuna', label: 'comuna', optionalUpload: true },
        { value: 'ciudad', label: 'ciudad', optionalUpload: true },
        { value: 'region', label: 'Región', optionalUpload: true },
        { value: 'pais', label: 'País', optionalUpload: true },
        // { value: 'destinos', label: 'Destinos', info: "Utiliza la barra (/) para separar varios puntos de entrega" },
        // { value: 'unidad_medida', label: 'Unidad medida', producto: true, optionalUpload: true, info: "Solo usar: MTR, INCH, CM, SQFT, SQM" , validation: { type: "string", key: "", values: unidades_medida.map(e => e.code) } },
        // { value: "rutcliente", label: "Rut cliente", optionalUpload: true },
        // { value: "productos", label: "Productos", denieUpload: true },
    ]
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema.map(e => e.value))
    const [ loadingCreacion, setLoadingCreacion ] = useState(false)
    const [ capacidad, setCapacidad ] = useState(30)
    const [ resize, setResize ] = useState(1)
    const [ pedidos, setPedidos ] = useState([])
    const [ comunascoordenadas, setComunasCoordenadas ] = useState([])
    const [ checlBoxIds, setCheckBoxIds ] = useState([])
    const [ loadingZonas, setLoadingZonas ] = useState([])
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [ propietarioBusqueda, setPropietarioBusqueda ] = useState(session.data.empresa)
    const {
        id
    } = useParams()
    const idruta = id

    const geolocalizarDireccion = async (direccion) => {
        return fetch(`${urlapi}/ordenes/geolocalizar-direccion`,{
            method:'POST',
            body: JSON.stringify({
                direccion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res.geometry){
                return res
            }
            return false
        })
        .catch(error => false)
    }

    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }

    const mostrarErrores = () => {
        if(errores.length < 1) return false
        return <div className="mt-3">
            <Card bg="danger" className="p-2 text-white">
            <h6 className="mb-0">Hay {errores.length} errores detectados <Button size="sm" variant="link text-white" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} /> <b>VER PROBLEMAS</b></Button></h6>
            </Card>
            <Modal show={showModalErrores} size="lg" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title>Errores detectados</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {
                    errores.map((error,irr) => {
                        return <p className="mb-1" key={`error-${irr}`}><BiSolidChevronRight size={20} /> {error}</p>
                    })
                   }
                </Modal.Body>
            </Modal>
        </div>
    }


    const onConfirm = (data) => {
        if(!data) return
        if(!data.routes) return
        if(!Array.isArray(data.routes)) return
        if(data.routes.length < 1) return toast.error('Sin rutas')
        

        const rutasDividir = data.routes.map(route => {
            const entregas = route.stops.filter(pa => pa.type === "dropoff")
            const enviar = {
                id_conductor: route.vehicle,
                pedidos: entregas.map(o => o.location),
                stops: route.stops,
                geometrias: route.geometrias
            }
            return enviar
        })

        setLoadingCreacion(true)
        return fetch(`${urlapi}/rutas/reasignar`,{
            method:'POST',
            body: JSON.stringify({
                id_ruta: ruta._id,
                rutas: rutasDividir
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingCreacion(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingCreacion(false)
            } else if(res.success === true){
                toast.success('Proceso realizado exitosamente')
                return setTimeout(() => {
                    window.location = '/rutas'
                }, 1000);
            }
            return setLoadingCreacion(false)
        })
        .catch(error => {
            toast.error(error.message)
            return setLoadingCreacion(false)
        })
    }

    

    useEffect(() => {
        // obtenerRuta(idruta)
        // obtenerProveedores()
        obtenerZonas("CL")
    },[])

    const obtenerZonas = async (codigopais) => {
        setLoadingZonas(true)
        return fetch(`${data.urlapi}/rutas/zonas?codigopais=${codigopais}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos de zonas')
                return setLoadingZonas(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingZonas(false)
            } else if(Array.isArray(res) !== false){
                setComunasCoordenadas(res)
            }
            return setLoadingZonas(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingZonas(false)
        })
    } 

    const handleInputChange = async (e) => {
        const target = e.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        let hojas = []
        setErrores(prev => [])
        if (name === 'file') {
            setLoadingExcel(true)
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = async (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates:true, dateNF:'dd.mm.yyyy' });
    
            workbook.SheetNames.forEach(function(sheetName) {
              var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
              hojas.push(XL_row_object)
            })

            if(!hojas[0]) return toast.error('No se encontraron datos')
            if(Array.isArray(hojas[0]) !== true) return toast.error('No se encontraron datos')
            if(hojas[0].length < 1) return toast.error('No se encontraron datos')
            if(!hojas[0][0]) return toast.error('No se encontraron datos')
            
            let errores = []
            let registros = []
            
            if(errores.length > 0) return toast.error(errores.join(', ')) // SI HAY ERRORES DETENER

            let columnas_buscar = columnasrequeridas


            let puntos = await Promise.all( hojas[0].map( async (row,irow) => {
                
                let campos_faltantes = []
                columnas_buscar.map(nombre => {
                    const i = esquema.findIndex(e => e.value === nombre)
                    if( i > -1){
                        const dato = esquema[i]
                        if(!row[nombre]) if(!dato.optionalUpload) campos_faltantes.push(nombre)
                    }
                })

                if(campos_faltantes.length > 0){
                    errores.push(`Fila ${irow+2} le faltan campos obligatorios. ${campos_faltantes.join(', ')}`)
                    return false
                }

                // const email = validateEmail(row[reemplazarLlave("email_contacto")] ? row[reemplazarLlave("email_contacto")].toString().trim() : '')
                // if(!email){
                //     console.log({ email, excel_email: row[reemplazarLlave("email_contacto")]  })
                //     errores.push(`Fila ${irow+2} tiene un email inválido.`)
                //     return false
                // }

                
                let nuevo_registro = {
                    _id: uuid(),
                    direccion: row["direccion"] ? row["direccion"].toString().trim() : '',
                    nombre: row["nombre"] ? row["nombre"].toString().trim() : '',
                }
                const geo = await geolocalizarDireccion(nuevo_registro.direccion)
                if(!geo || !geo?.formatted_address){
                    errores.push(`La dirección ${nuevo_registro.direccion} no se pudo geolocalizar`)
                    return false
                }

                // const geo_datos = obtenerGeoDatosGoogle(geo)

                if(geo) if(geo.geometry) if(geo.geometry.location){
                    nuevo_registro.longitude = geo.geometry.location.lng
                    nuevo_registro.latitude = geo.geometry.location.lat
                    nuevo_registro.location = {
                        type: "Point",
                        coordinates: [ geo.geometry.location.lng, geo.geometry.location.lat ]
                    }
                    // nuevo_registro.geo_datos
                }
                
                console.log(nuevo_registro, geo)
                return nuevo_registro
            }) )

            puntos = puntos.filter(d => d)

            if(errores.length > 0){
                setErrores(errores)
                setLoadingExcel(false)
                return toast.error(`Hay errores en el archivo, corrijelos e intenta de nuevo`)
            }
            setLoadingExcel(false)
            setPedidos(puntos)
          }
        }
    }

    const boxExcel = () => {
        if(loadingExcel) return <Spinner animation="border" variant="primary" />

        if(pedidos.length > 0) return false
        if(pedidos.length > 0) return <Button variant="light" onClick={() => setPedidos([])} className='mb-3 mt-3'><FaAngleLeft /> VOLVER A CARGAR EXCEL</Button>
        const iconSize = 30
        return <Card >
            <div className='p-4'>
            <Row className="justify-content-md-center">
                    <Col md={6} className="text-center">
                        <h4 className="mb-0">Presentamos</h4>
                        <h1><TbRoute /> <b>Planificador</b></h1>
                        <Row className="justify-content-md-center">
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Horarios personalizados</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Capacidades especiales</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineBorderOuter size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Límites a medida</h5>
                            </Col>
                            {/* <Col md={3}>
                                <TbPresentationAnalytics size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Dashboard Analítico</h5>
                            </Col>
                            <Col md={3}>
                                <MdOutlineModeEditOutline size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Reglas de negocio a medida</h5>
                            </Col>
                            <Col md={3}>
                                <AiOutlineAudit size={iconSize} />
                                <h5 className="mb-0" style={{ fontSize: 13, fontWeight: "bold" }}>Auditoria automatizada</h5>
                            </Col> */}
                        </Row>
                        <img src={`${url_images}/animations/Holding Map Paper And Finding Navigation.gif`} style={{ width: "50%" }} />
                        <p>Este novedoso sistema te permitirá optimizar tiempos de entrega o visitas, ahorrar costos al maximizar la eficiencia en las rutas y mejorar el cumplimiento en las entregas o visitas complejas.</p>
                        <InformacionEsquema esquema={esquema} />
                        <Boton component="label" color="success" variant="contained" className='mb-3 mt-3' startIcon={<FaFileUpload />}>
                        SUBIR EXCEL AQUÍ
                        <input
                            type="file" 
                            hidden
                            name="file" 
                            id="file" 
                            className="form-control mb-2"
                            onChange={handleInputChange} 
                            placeholder="Archivo de excel" 
                        />
                        </Boton>
                    </Col>
                </Row>
            </div>
        </Card>
    }
      
    return <div>
        {/* <h2 className='mb-0' style={{ fontWeight: "bold", color: estilo_last_mile.color_primary }}>Planificador de rutas</h2> */}
        {boxExcel()}
        {mostrarErrores()}
        {
            pedidos.length > 0 ? <RuteoCargasEstatico onVolver={() => setPedidos([])} resize={resize} puntos={pedidos.map(pe => {
                if(pe.location) if(pe.location.coordinates){
                    pe.location = {
                        longitude: pe.location.coordinates[0],
                        latitude: pe.location.coordinates[1]
                    }
                }
                return pe
            })} onConfirm={data => onConfirm(data)} /> : false 
        }
        
        {/* <Tabs
            defaultActiveKey="editar_ruta"
            className="mb-3"
            onSelect={() => setResize(prev => prev+1)}
        >
            <Tab eventKey="editar_ruta" title="Rutear">
                <Row>
                <Col md={12}>
                    
                </Col>
                </Row>
            </Tab>
        </Tabs> */}
</div>
}

export default RuteoSimple