import { obtenerFechaHoraZonaHorariaLocal } from "../../lib/helpers/dates"
import { formatoMoneda } from "../../lib/helpers/main"
import socket from "../../lib/websockets"
import { cerrarSesion } from "../../redux/actions/session"

const { useState, useEffect } = require("react")
const { Spinner, Button } = require("react-bootstrap")
const { useSelector, useDispatch } = require("react-redux")
const { urlapi } = require("../../lib/backend/data")
const { toast } = require("react-toastify")

const GestorProceso = (props) => {
    const {
        tipo,
        sub_tipo,
        component,
        data
    } = props
    const [loading, setLoading] = useState(false)
    const [ loadingProceso, setLoadingProceso ] = useState(false)
    const [ proceso, setProceso ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const dispatch = useDispatch()

    const descargarArchivo = url => {
        const link = document.createElement('a');
        link.href = url;
        const fecha = obtenerFechaHoraZonaHorariaLocal()
        link.setAttribute('download', fecha + 'reporte'); // Puedes ajustar 'filename' según tus necesidades
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    useEffect(() => {
        obtenerDatos()
        let identificador = "operacion_actualizada"
        let identificador2 = "refrescar_operacion"
        socket.on(identificador, nuevo => {
            setProceso(prev => {
                let actual = {...prev}
                if(nuevo?._id !== actual?._id) return actual
                actual = { ...actual, ...nuevo }
                if(nuevo.url) descargarArchivo(nuevo.url)
                return actual
            })
        })
        socket.on(identificador2, nuevo => {
            setProceso(prev => {
                let actual = {...prev}
                if(nuevo?.id_operacion !== nuevo?._id) return actual
                actual = { ...actual, ...nuevo }
                return actual
            })
        })
        obtenerDatos()
        return () => {
            socket?.off(identificador)
            socket?.off(identificador2)
        }
    }, [])

    const crearOperacion = async ()=>{
        setLoadingProceso(true)
        return fetch(`${urlapi}/procesos`,{
            method:'POST',
            body: JSON.stringify({
                tipo, 
                sub_tipo, 
                data
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error("Sin datos")
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                setProceso(res)
            }
            return setLoadingProceso(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProceso(false)
        })
    }

    const obtenerDatos = async ()=>{
        setLoading(true)
        return fetch(`${urlapi}/procesos?tipo=${tipo}&subtipo=${sub_tipo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){

            } else if(res.errorMessage){

            } else if(res._id){
                setProceso(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const cancelarOperacion = async ()=> {
        setLoadingProceso(true)
        return fetch(`${urlapi}/procesos?tipo=${tipo}&subtipo=${sub_tipo}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){

            } else if(res.errorMessage){

            } else if(res.acknowledged){
                setProceso(false)
            }
            return setLoadingProceso(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProceso(false)
        })
    }

    const showByStatus = () => {
        const content = <div onClick={() => crearOperacion()}>{component}</div>
        let operacion = ''
        if(proceso.total_operaciones_procesadas && proceso.total_operaciones) operacion = `${formatoMoneda(proceso.total_operaciones_procesadas)} operaciones procesadas de ${formatoMoneda(proceso.total_operaciones)}`
        if(proceso.status === "loading") return <div style={{ fontSize: 17 }}>
            <Spinner size="sm" animation="border" /> Procesando... <Button onClick={() => cancelarOperacion()} variant="outline-danger" style={{ fontSize: 13 }} >CANCELAR</Button>
            { operacion ? <p className="mb-0" style={{ fontWeight: "bold" }}>{operacion}</p> : false }
            { proceso.tipo === "descarga-excel" ? <p style={{ fontSize: 10, fontWeight: "bold" }} className="mb-0">Recibirás una copia de este reporte en tu email</p> : false }
        </div>
        if(proceso.status === "error") return <div className="text-danger"><h4 className="mb-0">Error en la operación <Button variant="outline-danger" size="sm" style={{ fontSize:11 }}>REINTENTAR</Button></h4></div>
        if(proceso.status === "done" ) return content

        return content
    }

    const mostrarComponente = () => {
        if(loading) return <Spinner size="sm" animation="border" variant="primary" />
        if(loadingProceso) return <div style={{ fontSize: 17 }}><Spinner size="sm" animation="border" /> Enviando solicitud...</div>
        
        if(proceso) return <div>
            {showByStatus()}
        </div>
        return <div>
            <div onClick={() => crearOperacion()}>{component}</div>
        </div>
    }

    return <div>
        {mostrarComponente()}
    </div>
}

export default GestorProceso