import { useEffect, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import data from '../../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import { confirmAlert } from 'react-confirm-alert'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { rutas } from '../../../lib/routes/routes';
import SinDatos from '../../general/sin_registros';
import { estilo_last_mile } from '../../../lib/global/styles';
import { cerrarSesion } from '../../../redux/actions/session';

const ProveedoresListadoProveedores = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const {
        condicion_default,
        displayAll,
        hightLightCodigo
    } = props
    const dispatch = useDispatch()
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ idEnlazando, setIdEnlazando ] = useState(false)
    const [ patente, setPatente ] = useState('')
    const [ pedidoBusqueda, setPedidoBusqueda ] = useState('')
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ vehiculoSeleccionado, setVehiculoSeleccionado ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState(false)
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : {})
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ crearNuevo, setCrearNuevo ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')

    const obtenerProveedores = async (page, query)=>{
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/empresas/clientes/proveedor/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                pagina: page
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res.datos) !== false){
                setProveedores(res.datos)
                setTotal(res.total)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }
    
    const enlazarEmpresa = async (id)=>{
        setIdEnlazando(id)
        return fetch(`${data.urlapi}/empresas/enlazar`,{
            method:'POST',
            body: JSON.stringify({
                id
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingProveedores(false)
            } else if( res._id ){
                return window.location = `/${rutas.logistic_providers_link.slug}/${res._id}`
            }
            return setIdEnlazando(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setIdEnlazando(false)
        })
    }

    const onClickProveedor = async (id) => {
        setLoadingDetalle(true)
        setIdSeleccionado(id)
        setCrearNuevo(false)
        return fetch(`${data.urlapi}/proveedores?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res._id){
                setVehiculoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            toast.error(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page,ignorar) => {
        if(ignorar) return false
        setPagina(page)
        obtenerProveedores(page)
    }

    const handleClose = () => {
        setOpenModal(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        return setSelectionRange(item.selection)
    }

    const modalFiltro = () => {
        const estados = []
        const sub_estados = []
        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xs={12} className="mb-2">
                    <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control'>
                        <option value="createdAt">Creación</option>
                        <option value="updatedAt">Actualización</option>
                        <option value="compromiso">Compromiso</option>
                        <option value="ruta">Fecha ruta</option>
                    </select>
                </Col>
                <Col md={12}>
                    <Button size="sm" variant="success">FILTRAR</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total) => {
        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
        </div>
    }

    useEffect(() => {
        obtenerProveedores(1, false)
    }, [ ]) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <Row>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
                <Col md={12} className="mb-3"><Skeleton height={40} /></Col>
            </Row>
    }

    const onClickEmpresa = (data) => {
        if(props.onClick) props.onClick(data)
    }

    const tabla_registros = (todos) => {
        if(loadingProveedores===true) return cargandoScreen()

        const registros = todos.filter(orden => {
            if(displayAll !== true){
                if(orden.rut === session.data.propietario) return false
            }
            return true
        })

        if(registros.length < 1) return  <div>
            <h5>Nada por aquí</h5>
            <p>No hay registros en esta sección</p>
        </div>
        return <div>
            <h5>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5>
            {paginacion(data.pagina, total)}
            <Row>

            {
                registros.map(orden => {

                    let estilo_activo = {}
                    if(hightLightCodigo) if(orden.codigo === hightLightCodigo) estilo_activo = { border: `2px solid ${estilo_last_mile.color_primary}` }
                    return <Col md={2} xs={6} key={orden._id} className="mb-2 hover">
                                <Card className='p-1 mb-3 text-center centrador' style={{...estilo_activo, minHeight: 100, textTransform: "uppercase" }} >
                                    <div onClick={() => onClickEmpresa(orden)}>
                                        { orden.logotipo ? <img src={orden.logotipo} style={{ maxHeight: 100, maxWidth: "70%", margin: "0 auto", paddingBottom: 10 }} />  : <h5 className='mb-0'>{orden.razon_social}</h5>  }
                                    </div>
                                </Card>
                            </Col>
                        })
                    }
                    </Row>
        </div>
    }

    
    const handleChangeBuscador = (e) => {
        const { name, value } = e.target
        switch (name) {
            case 'patente':
                return setPatente(value)
            case 'numero':
                return setNumeroInterno(value)
            default:
                break;
        }
    }

    const reiniciar = () => {
        setPagina(1)
        setCondicionBusqueda({})
        obtenerProveedores(1, {})
    }

    const onProveedorGuardado = (data) => {
        console.log(data)
        const i = proveedores.findIndex(a => a._id === data._id)
        if(i < 0) return false
        proveedores[i] = data
        return setProveedores(prev => [...[], ...proveedores])
    }

    const crearProveedor = () => {
        setLoadingDetalle(true)
            setIdSeleccionado(false)
            setVehiculoSeleccionado(false)
            setTimeout(() => {
                setLoadingDetalle(false)
            }, 100);
    }

    const onProveedorNuevo = (data) => {
        proveedores.unshift(data)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const handleChangePedido = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const onFieldDeleted = (id) => {
        const i = proveedores.findIndex(c => c._id === id)
        if(i < 0) return false
        proveedores.splice(i,1)
        setVehiculoSeleccionado(false)
        return setProveedores(prev => [...[], ...proveedores])
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = condicion_default
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = {...condicion_default, $text: { $search: parametroBusqueda } }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerProveedores(1, condicion)
    }
 
    return <div>
        {modalFiltro()}
        <Row>
            <Col md={12}>
                {tabla_registros(proveedores)}
            </Col>
        </Row>
    </div>

}

export default ProveedoresListadoProveedores