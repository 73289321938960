const data = {
    modulo: "tickets",
    submodulo: "tickets/mensajes",
    types: [
        { value: "retraso", label: "Retraso en la entrega" },
        { value: "cambio direccion", label: "Cambio de dirección" },
        { value: "otro", label: "Otro" },
    ],
    status: {
        "awating_admin": "Esperando por el administrador",
        "awating_client": "Esperando por el cliente",
    }
}

module.exports = data