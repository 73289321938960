import { useEffect, useState } from 'react'
import { Row, Col, Button, Table, Card, Spinner, Form, Modal } from 'react-bootstrap'
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addMoreDays, formatDateHoy } from '../../lib/helpers/dates';
import { esquema_productos } from '../../lib/esquemas';
import { useDispatch, useSelector } from 'react-redux';
import { BiSolidChevronRight } from 'react-icons/bi';
import { cerrarSesion } from '../../redux/actions/session';
import { AiOutlineFileExcel } from 'react-icons/ai';
import PantallaCargando from '../general/pantalla_cargando';

const ModalProductos = (props) => {
    const {
        title
    } = props
    const initialDate = {
        startDate: new Date(),
        endDate: addMoreDays(new Date(),1),
        key: 'selection',
    }
    const dispatch = useDispatch()
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ errores, setErrores ] = useState([])
    const [ registros, setRegistros ] = useState([])
    const [ columnasrequeridas, setColumnasRequeridas ] = useState(esquema_productos)
    const [ loadingCarga, setLoadingCarga ] = useState(false)
    const [ procesados, setProcesados ] = useState(0)
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ loadingMaster, setLoadingMaster] = useState(false)
    const [ conductorSeleccionado, setConductorSeleccinoado ] = useState(false)
    const [ tipoAsignacion, setTipoAsignacion ] = useState('vehiculo')
    const [ loadingOperaciones, setLoadingOperaciones] = useState(0)
    const [ duplicados, setDuplicados] = useState([])
    const [ mensajeLoading, setMensajeLoading ] = useState('')
    const [ showModalErrores, setShowModalErrores ] = useState(false)
    const [ operacionesListas, setOperacionesListas] = useState(0)
    const session = useSelector(state => state.miusuario)
    const [ omitirEstados, setOmitirEstados ] = useState(false)
    const [ formatoSeleccionado, setFormatoSeleccionado ] = useState(false)
    const [ productos, setProductos ] = useState([])
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(props.condicion_default ? props.condicion_default : {})
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ parametroBusqueda, setParametroBusqueda ] = useState('')
    const trash = props.trash ? props.trash : false
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const token = session.tokenSession
    const seleccionado = props.seleccionado ? props.seleccionado : false
    const [loadingModal, setLoadingModal] = useState(false)
    const titulo = title ? title : 'MAESTRO DE PRODUCTOS'


    const obtenerProveedores = async (page, query)=>{
        setLoadingModal(true)
        setLoadingProveedores(true)
        if(query) if(trash === true) query.status = 'trash'
        const condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/productos/list/modal`,{
            method:'POST',
            body: JSON.stringify({
                condicion
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log("RES:", res)
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(Array.isArray(res.datos) !== false){
                setProductos(res.datos)
                setTotal(res.total)
            }
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            setLoadingModal(false)
            return setLoadingProveedores(false)
        })
    }

    useEffect(() => {
        obtenerProveedores(1, false)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitPalabra = (e) => {
       
        e.preventDefault()
        if(!parametroBusqueda){
            const condicion = {}
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerProveedores(1, condicion)
        }
        const condicion = { $text: { $search: parametroBusqueda }}
        setCondicionBusqueda(condicion)
        setPagina(1)
        setLoadingModal(false)
        return obtenerProveedores(1, condicion)
    }

    const handleChangePalabra = (e) => {
        const { value } = e.target
        return setParametroBusqueda(value)
    }

    const handleCloseErrores = () => {
        return setShowModalErrores(false)
    }

    const cargandoScreen = () => {
        return <div>
            <PantallaCargando />
        </div>
    }

    const seleccinoarProducto = (producto) => {
        if(props.onSelected) props.onSelected(producto)
        return setShowModalErrores(false)
    }

    const listadoProductos = () => {
        if(loadingModal) return cargandoScreen()
        return <div>
            <h6>Se encontraron {productos.length} productos en tu busqueda.</h6>
            {
                productos.map((p) => {                        
                    return   <Card bg={ seleccionado === true ? 'primary' : 'white' } className={`p-3 mb-3  ${seleccionado === true ? 'text-white' : 'text-dark'}`} onClick={()=> seleccinoarProducto(p)}>
                            <div className='hover'>
                            <p className="mb-1 text-white" style={{ padding: "1px 17px", fontSize: 10,  width: "fit-content", backgroundColor: "#848484", borderLeft:"3px solid #d96720" }}>{p.codigo_item}</p>
                            <h6 style={{ fontSize: 17 }} className="mb-1">{p.description}</h6>
                            </div>
                    </Card>
                })
            }
        </div>
    }

    const mostrarProductos = () => {
        return <div>
            <Button size="sm" className='mb-3' variant="dark" onClick={() => setShowModalErrores(true)}><BiSolidChevronRight size={20} />{titulo}</Button>
            <Modal show={showModalErrores}  size="xl" onHide={handleCloseErrores} centered >
                <Modal.Header closeButton>
                <Modal.Title><b></b>Productos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Col md={12}>
                        <form onSubmit={handleSubmitPalabra}>
                        <input className='mb-3 form-control' placeholder='BUSCAR' onChange={handleChangePalabra} />
                        </form>
                    </Col>
                   {listadoProductos()}
                </Modal.Body>
            </Modal>
        </div>
    }


    return <div>
      {
        productos ?
        <div>
            {mostrarProductos()}
        </div>
        :
        <div><h4>Sin datos</h4></div>
      }
    </div>

}

export default ModalProductos